import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./custom.css";
import PrivateRoute from "./Components/PrivateRoute";
import Login from "./Pages/Login";
import Error from "./Pages/Error";
import AppContextProvider from "./Contexts/AppContextProvider";
import Dashboard from "./Pages/Patient/Dashboard";
import ManageAbstractors from "./Pages/Abstractor/ManageAbstractors";
import { ManageCenters } from "./Pages/Center/ManageCenters";
import CenterInformation from "./Pages/Center/CenterInformation";
import ResetPassword from "./Pages/ResetPassword";
import PasswordRecovery from "./Pages/PasswordRecovery";
import { AddLocalStorageData, GetLocalStorageData } from "./Helpers/Utilities";
import packageJson from "../package.json";

import PatientScreening from "./Pages/Patient/PatientScreening";
import ChartSelection from "./Pages/Patient/ChartSelection";

import GeneralInformation from "./Pages/Patient/GeneralInformation";
import NDMMCriteria from "./Pages/Patient/NDMMCriteria";
import RRMMCriteria from "./Pages/Patient/RRMMCriteria";
import ExclusionCriteria from "./Pages/Patient/ExclusionCriteria";
import TransitionToChartAbstraction from "./Pages/Patient/TransitionToChartAbstraction";
import BispecificsCartTreatmentCohort from "./Pages/Patient/CohortTreatmentCharacteristics/BispecificsCartTreatmentCohort";

import Demographics from "./Pages/Patient/Demographics";
import DiseaseHistory from "./Pages/Patient/DiseaseHistory";
import Comorbidities from "./Pages/Patient/Comorbidities";
import { ManageRounds } from "./Pages/Center/ManageRounds";
import CohortDefiningTreatment from "./Pages/Patient/CohortTreatmentCharacteristics/CohortDefiningTreatment";
import NDMMSCTCharacteristics from "./Pages/Patient/AdditionalTreatmentCharacteristics/NDMMSCTCharacteristics ";
import NDMMTreatmentCohort from "./Pages/Patient/CohortTreatmentCharacteristics/NDMMTreatmentCohort";
import TeclistamabAndTalquetamabCharacteristics from "./Pages/Patient/AdditionalTreatmentCharacteristics/TTCharacteristics";
import TTCharacteristics from "./Pages/Patient/AdditionalTreatmentCharacteristics/TTCharacteristics";
import NonCohortTreatment from "./Pages/Patient/NonCohortTreatment";
import AEReporting from "./Pages/StaticPages/AEReporting";
import IMWGReporting from "./Pages/StaticPages/IMWGReporting";
import LineOfTherapy from "./Pages/StaticPages/LineOfTherapy";
import CarTCharacteristics from "./Pages/Patient/AdditionalTreatmentCharacteristics/CarTCharacteristics";
import AdverseEvents from "./Pages/Patient/AdverseEvents";

import TreatmentResponseProgression from "./Pages/Patient/ClinicalOutcomes/TreatmentResponseProgression";
import ValidateSubmit from "./Pages/Patient/ValidateSubmit";
const App = () => {
  const location = useLocation();

  /* <summary>
      date: 06-04-2024
      Name: AP
      description: Effect to clear the cache
      <summary>*/
  useEffect(() => {
    const Caching = () => {
      const version = GetLocalStorageData("version");
      //console.log("Package version", version);
      if (version !== packageJson.version) {
        //console.log("Clearing cache");
        AddLocalStorageData("version", packageJson.version);
        if ("caches" in window) {
          caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach((name) => {
              caches.delete(name);
            });
          });
        }

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.replace(window.location.href);
      }
    };

    Caching();

    return () => {};
  }, []);

  return (
    <AppContextProvider>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Login />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/PasswordRecovery" element={<PasswordRecovery />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/ManagePatients" element={<Dashboard />} />
          <Route path="/ManageAbstractors" element={<ManageAbstractors />} />
          <Route path="/ManageCenters" element={<ManageCenters />} />
          <Route path="/CenterInformation" element={<CenterInformation />} />
          <Route path="/ManageRounds" element={<ManageRounds />} />

          <Route path="/PatientScreening" element={<PatientScreening />} />
          <Route path="/ChartSelection" element={<ChartSelection />} />

          <Route path="/GeneralInformation" element={<GeneralInformation />} />
          <Route path="/NDMMCriteria" element={<NDMMCriteria />} />
          <Route path="/RRMMCriteria" element={<RRMMCriteria />} />
          <Route path="/ExclusionCriteria" element={<ExclusionCriteria />} />
          <Route
            path="/TransitionToChartAbstraction"
            element={<TransitionToChartAbstraction />}
          />

          <Route path="/Demographics" element={<Demographics />} />
          <Route path="/DiseaseHistory" element={<DiseaseHistory />} />
          <Route path="/Comorbidities" element={<Comorbidities />} />
          <Route
            path="/CohortDefiningTreatment"
            element={<CohortDefiningTreatment />}
          />
          <Route
            path="/NDMMSCTCharacteristics"
            element={<NDMMSCTCharacteristics />}
          />
          <Route
            path="/NDMMTreatmentCohort"
            element={<NDMMTreatmentCohort />}
                  />
                  <Route
                      path="/BispecificsCartTreatmentCohort"
                      element={<BispecificsCartTreatmentCohort />}
                  />

          <Route
            path="/CohortDefiningTreatment"
            element={<CohortDefiningTreatment />}
          />
          <Route path="/TTCharacteristics" element={<TTCharacteristics />} />
          <Route path="/NonCohortDefiningTreatment" element={<NonCohortTreatment />} />
          <Route path="/AEReporting" element={<AEReporting />} />
          <Route path="/IMWGReporting" element={<IMWGReporting />} />
          <Route path="/LineOfTherapy" element={<LineOfTherapy />} />
          <Route path="/AdverseEvents" element={<AdverseEvents />} />
          <Route path="/TreatmentResponseProgression" element={<TreatmentResponseProgression />} />
                    <Route path="/NonCohortDefiningTreatment" element={<NonCohortTreatment />} />
                    <Route path="/AEReporting" element={<AEReporting />} />
                    <Route path="/IMWGReporting" element={<IMWGReporting />} />
                    <Route path="/LineOfTherapy" element={<LineOfTherapy />} />




          <Route
            path="/CarTCharacteristics"
            element={<CarTCharacteristics />}
          />

        <Route path="/ValidateSubmit" element={<ValidateSubmit />} />



        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </AppContextProvider>
  );
};
export default App;
