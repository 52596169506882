import React, { useContext, useEffect, useState } from "react";
import { AddLocalStorageData, GetLocalStorageData } from "../Helpers/Utilities";
import { useNavigate } from "react-router";
import { Role, TopMenuItems } from "../Helpers/Enums";
import { AppContext } from "../Contexts/AppContextProvider";
import { UserLoginService } from "../WebApiServices/UserLogin.service";

const TopMenu = (props) => {
  // const activeTab = props.activeTab;
  const navigate = useNavigate();

  const { appState } = useContext(AppContext);
  const activeTab = appState.activeTab;
  const [showDeletePopUp, setShowDeletePopUp] = useState(false); //

  let userDetails = GetLocalStorageData("user")
  userDetails = userDetails && JSON.parse(userDetails);

  const roleId = userDetails?.userRole ?? 0;
  const isAdmin = roleId === Role.Admin ?? false;
  const centerId = userDetails?.centerId ?? 0;


  const OpenDashboard = (tabId) => {
    if (tabId === TopMenuItems.Patients) navigate("/ManagePatients");
    else if (tabId === TopMenuItems.Centers) {
      if (isAdmin) {
        navigate("/ManageCenters");
      } else {
        AddLocalStorageData("editCenterId", centerId)//Required for round and center info 
        navigate("/CenterInformation");
      }
    }
    else if (tabId === TopMenuItems.Abstractors) navigate("/ManageAbstractors");
  };

  const LogoutUser = async () => {
    await UserLoginService.UserLogout();
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <section className="menu-section">
        <div className="navbar">
          <a
            onClick={() => {
              OpenDashboard(TopMenuItems.Patients);
            }}
            className={
              activeTab == TopMenuItems.Patients ? "active" : "undefined"
            }
          >
            PATIENTS
          </a>
          <>
            <a
              onClick={() => {
                OpenDashboard(TopMenuItems.Centers);
              }}
              className={
                activeTab == TopMenuItems.Centers ? "active" : "undefined"
              }
            >
              {isAdmin ? 'CENTERS' : 'CENTER INFO'}
            </a>
            {roleId === Role.Admin && (
              <a
                onClick={() => {
                  OpenDashboard(TopMenuItems.Abstractors);
                }}
                className={
                  activeTab == TopMenuItems.Abstractors ? "active" : "undefined"
                }
              >
                ABSTRACTORS
              </a>
            )}
          </>
          <a
            onClick={() => {
              setShowDeletePopUp(true);
            }} //{LogoutUser}
            className=""
          >
            LOGOUT
          </a>
        </div>
      </section>

      {/* Logout Modal */}
      {showDeletePopUp && (
        <section className="delete-modal">
          <div
            className="modal"
            id="logoutModal"
            tabIndex="-1"
            aria-labelledby="ModalFormLabel"
            aria-hidden="true"
            style={{
              display: "block",
              paddingRight: "17px",
              backgroundColor: "#00000094",
            }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="container">
                    <div className="delete-modal-main">
                      <div className="text-input-main">
                        <div>
                          <p>Are you sure you want to logout?</p>
                        </div>
                        <div className="confirm-btn-div">
                          <button
                            className="modal-close-btn"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              setShowDeletePopUp(false);
                            }}
                          >
                            No
                          </button>
                          <button
                            className="modal-yes-btn"
                            onClick={LogoutUser}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default TopMenu;
