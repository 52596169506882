import React, { useContext, useEffect, useState } from 'react'
import SaveAndNavigate from '../../Components/SaveAndNavigate';
import { RRMMCohortTreatment, SideMenuItems } from '../../Helpers/Enums';
import SideBarMenu from '../../Components/SideBarMenu';
import PatientDetails from '../../Components/PatientInformation';
import { GetLocalStorageData } from '../../Helpers/Utilities';
import { AppContext } from '../../Contexts/AppContextProvider';




const LineOfTherapy = () => {
    
    const [navigatePaths, setNavigatePaths] = useState({
        prevPage: "/BispecificsCartTreatmentCohort",
        currPage: "/LineOfTherapy",
        nextPage: "/NonCohortDefiningTreatment",

    })
    const { isSideMenuActive,setIsSideMenuActive } = useContext(AppContext);
    
    const SetNavPaths = () => {
        const storedData = GetLocalStorageData("CohortTreatment");
        let cohortTreatmentModelListt = [];
        let newPaths = { ...navigatePaths };
    
        if (storedData) {
          cohortTreatmentModelListt = JSON.parse(storedData);
        }
    
        let eligibleCohorts = cohortTreatmentModelListt.filter(
          (c) => c.isEligible === true
        );
        let ndmmCohorts = eligibleCohorts.filter((c) => c.ndmm);
        let rrmmCohorts = eligibleCohorts.filter((c) => !c.ndmm);
    
        const isAbeOrCar = rrmmCohorts.some(
          (a) =>
            a.cohortTreatmentID === RRMMCohortTreatment.ABECMA ||
            a.cohortTreatmentID === RRMMCohortTreatment.CARVYKTI
        );
    
        const isTecOrTal = rrmmCohorts.some(
          (a) =>
            a.cohortTreatmentID === RRMMCohortTreatment.TECVAYLI ||
            a.cohortTreatmentID === RRMMCohortTreatment.TALVEY
        );
    
        if (rrmmCohorts.length > 0) {
            newPaths.prevPage = "/BispecificsCartTreatmentCohort";
        } else {
            newPaths.prevPage = "/NDMMTreatmentCohort";
        }
        setNavigatePaths(newPaths);
    };

    const SavePage  =  () => {
        // SetNavPaths();
        return true;
    }

    const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");

    const toggleNav = () => {

    
      if (isSideMenuActive === true  ) { 
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
        setIsSideMenuActive(false)
      } else if( isSideMenuActive===false ){
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
        setIsSideMenuActive(true)
      }
  }

  useEffect(() => {
        SetNavPaths(); 
 const   sidebar = document.getElementById("mySidebar");
  const  main = document.getElementById("main");


    if (isSideMenuActive === true) {
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
      } else {
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
      }
  });
  
    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                         <SaveAndNavigate paths={navigatePaths} Save={SavePage} /> 
                    </div>
                </div>
            </section>
            <section className="main-survey-section">
                <div >
                
                    <SideBarMenu activePageId={SideMenuItems.LineOfTherapy} />

                    <>
                    <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
                    </>

                    <div className="col-pad-left" id='main'>
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>Line Of Therapy</h4>
                            </div>
                            <div className="survey-question-section">
                                <div className="survey-question-content">

                                    <div className="question-answer">
                                        <div className="question-bot-sapce">
                                        <div className="question question-weight">
                                            {/* static content */}
                                            <div>
    
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            A line of therapy (LOT) can be determined or inferred based on information available in the chart. For sites that do not have LOT information, the following method may be used as reference.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            <b>Defining Regimens and LOTs</b>
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            Each regimen will be defined by a start date, an end date, and a distinct regimen, made up of one or more drugs. A patient may receive multiple treatment regimens in sequence during the course of their treatment.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            <b>Regimen:</b> A regimen is formed by one or several NCCN-recommended medications for MM (see Table below for MM Drugs) identified from both structured and unstructured EMR during the first 60 days following the regimen initiation.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            Each regimen will end on either the discontinuation date of all drugs within the regimen (except for corticosteroids), or the date of treatment change (including augmentation and switching), whichever happens first. The date of treatment change will be the initiation date of the next line of therapy regimen as defined below. Changes in any agent (‘targeted’ or chemo) within 60 days of first treatment is not considered a line change.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            Corticosteroids do not affect LOT increments, start dates, or end dates. A corticosteroid will be considered part of a regimen if there is at least 1 day overlap with the regimen’s first 60 days.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            If a regimen is discontinued and then resumed within 90 days, then this will be treated as the same regimen.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            <b>Gap:</b> Gap is calculated as the days elapsed between the last day of current dispensing/administration and its subsequent dispensing/administration.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            <b>Discontinuation of individual medication:</b> Discontinuation of an individual medication will be defined as the failure to refill the medication of interest within the maximum allowed gap (MAG) of 90 days after the expiration of the last day of dispensing/administration. For belantamab, MAG is 183 days instead of 90 days due to adverse event management. The discontinuation date of the medication will be calculated as the last day of dispensing/administration.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            If a stem cell transplant (SCT) occurred between two drug segments, then they will be treated as the same drug segment if all of the following criteria are met: (1) the SCT occurred on or after the last service date within the prior drug segment, (2) the SCT occurred on or before the end of the MAG after the prior drug segment, and (3) the next drug segment began within 6 months (183 days) after the SCT.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            Melphalan and cyclophosphamide drug segments with duration &lt; 28 days will be excluded as preparation for SCT, so as not to advance the line.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            <b>Augmentation:</b> An augmentation will be defined as receiving a new medication of interest at least 60 days prior to discontinuing any of the medications included in the current regimen. The date initiating the new medication will be considered as the augmentation date. Changing between daratumumab and daratumumab/hyaluronidase does not count as augmentation.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            <b>Switch:</b> A switch will be defined as receiving a new medication of interest with at least one medication of the current regimen discontinued prior to or within 60 days after the initiation of the new medication. The initiation date of the new medication will be considered as the switch date. Changing between daratumumab and daratumumab/hyaluronidase does not count as a switch.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            <b>Maintenance therapies:</b> Three types of maintenance therapies (MTs) are allowed.
                                            </p>
                                            <ol style={{ listStyleType: 'none', textAlign: 'left', fontWeight: 'normal' }}>
                                            <li>(1) If a regimen is followed by SCT*, then regimens (excluding CAR-T) initiated within 90 days after SCT and with a duration ≤ 4 cycles (≤112 days) will be considered as MTs and will not advance the line.</li>
                                            <li>(2) If a regimen lasting at least 4 cycles (≥112 days) is followed by a regimen within 90 days, and all drugs (excluding corticosteroids) in the subsequent regimen were used within the prior regimen, then the regimen will be considered as MT, and the line will not advance. If the main regimen is followed by SCT*, the allowed gap between regimens is extended to 183 days.</li>
                                            <li>(3) Within a regimen, if an individual medication is discontinued, it will not advance the line.</li>
                                            </ol>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            * Regimen followed by SCT is defined as the SCT occurred after the last service date of any drug within the regimen (excluding corticosteroids), and the SCT occurred before (regimen end date + 90 days).
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            <b>Chemotherapy:</b> The addition of chemotherapy agents (see Table below for MM Drugs) initiates a new regimen.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            <b>Discontinuation of a regimen:</b> Discontinuation of a regimen is defined as discontinuation of all medications in the regimen (excluding corticosteroids), or treatment change (including augmentation and switching), whichever occurs first. For example, if no treatment change happens, discontinuation of the regimen will be the latest discontinuation date of all non-corticosteroid medications included in the regimen. If only a corticosteroid remains after discontinuation of other therapies in the regimen, that will be considered a regimen discontinuation (corticosteroids do not extend the regimen end date). Whereas if a treatment change occurs prior to the latest discontinuation date, the date of treatment change will be considered as the discontinuation date of the regimen.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            There is an exception that if a patient used a maintenance therapy (see definition above) within 6 months after a SCT, regardless of whether the MT drugs were part of the initial regimen or not, such “switching” will NOT be considered as switching and thus will NOT trigger LOT increase. The discontinuation date for the regimen will be extended to the discontinuation of the MT.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            <b>CAR-T LOT:</b> For CAR-T patients, therapy between apheresis and CAR-T infusion is considered bridging therapy. If a LOT starts on or after apheresis and CAR-T infusion occurs &gt;60 days later, the LOTs will be combined to include both bridging and CAR-T infusion.
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            <b>Line of Therapy Increase:</b> Induction therapy preceding SCT is a new LOT, however SCT itself is not a new LOT. Multiple SCTs are permitted with minimum 6-month gap between SCTs per patient. A treatment change (including augmentation and switching) or initiation of a new regimen after discontinuation of a previous regimen will increase the LOT (with the exception of maintenance therapy and CAR-T LOT, as described above).
                                            </p>
                                            <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            <b>MM Drugs: </b>
                                            </p>


                                            {/* Static Table Start */}

                                            <table border="1" style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }} className='line-of-therapy'>
    <thead>
        <tr>
            <td style={{ border: '1px solid black', textAlign: 'center' }}><b>Drug Class</b></td>
            <td style={{ border: '1px solid black', textAlign: 'center' }}><b>Drug Name</b></td>
            <td style={{ border: '1px solid black', textAlign: 'center' }}><b>Drug Molecule</b></td>
            <td style={{ border: '1px solid black', textAlign: 'center' }}><b>ROA</b></td>
            <td style={{ border: '1px solid black', textAlign: 'center' }}><b>Imputed Days Supply*</b></td>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td rowSpan="3" style={{ border: '1px solid black', wordWrap: 'break-word', padding: "10px",textAlign:"center", fontWeight: 'normal' }}>
                Immunomodulatory (iMiD)
            </td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Thalidomide (Synovir, Thalomid)</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Oral</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>28 days</td>
        </tr>
        <tr>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Lenalidomide (Revlimid)</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Oral</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>28 days</td>
        </tr>
        <tr>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Pomalidomide (Pomalyst)</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Oral</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>28 days</td>
        </tr>
        <tr>
        <td rowSpan="3" style={{ border: '1px solid black', wordWrap: 'break-word', padding: "10px",textAlign:"center", fontWeight: 'normal' }}>
                Proteasome Inhibitor (PI)
        </td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Bortezomib (Velcade)</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>SQ, IV</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>14 days</td>
        </tr>
        <tr>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Carfilzomib (Kyprolis)</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>7 days</td>
        </tr>
        <tr>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Ixazomib (Ninlaro)</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Oral</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>28 days</td>
        </tr>
        <tr>
            <td rowSpan="3" style={{ border: '1px solid black', wordWrap: 'break-word', padding: "10px",textAlign:"center", fontWeight: 'normal' }}>
                Anti-CD38 Monoclonal Antibody (MAB)
            </td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Daratumumab (Darzalex)</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Biologics</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>14 days</td>
        </tr>
        <tr>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Daratumumab (Darzalex Faspro)</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Biologics</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>SQ</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>14 days</td>
        </tr>
        <tr>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Isatuximab (Sarclisa)</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Biologics</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>28 days</td>
        </tr>
        <tr>
            <td style={{ border: '1px solid black', wordWrap: 'break-word', padding: "10px",textAlign:"center", fontWeight: 'normal' }}>
                Other Monoclonal Antibody (MAB)
            </td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Elotuzumab (Empliciti)</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Biologics</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV</td>
            <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>14 days</td>
        </tr>
        <tr>
            <td style={{ border: '1px solid black', textAlign: 'center', fontWeight: 'normal' }}>Histone Deacetylase (HDAC) Inhibitor</td>
            <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>Panobinostat (Farydak)</td>
            <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>Small Molecule</td>
            <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>Oral</td>
            <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>21 days</td>
        </tr>
        
        <tr>
    <td style={{ border: '1px solid black', textAlign: 'center', fontWeight: 'normal' }}>Nuclear Export Inhibitor (INE)</td>
    <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>Selinexor (Xpovio)</td>
    <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>Small Molecule</td>
    <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>Oral</td>
    <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>7 days</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', textAlign: 'center', fontWeight: 'normal' }}>B-cell Lymphoma 2 <br /> (BCL-2) Inhibitor</td>
    <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>Venetoclax (Venclexta)</td>
    <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>Small Molecule</td>
    <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>Oral</td>
    <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>1 day</td>
</tr>

<tr>
    <td rowSpan="8" style={{ border: '1px solid black', wordWrap: 'break-word', textAlign: 'center', fontWeight: 'normal' }}>
        Chemotherapy (Chemo)
    </td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Cyclophosphamide (Cytoxan)</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV, Oral</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>14 days</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Melphalan (Alkeran)</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV, Oral</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>14 days</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Doxorubicin (Adriamycin, Doxil)</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>21 days</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Etoposide (Toposar, VePesid)</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV, Oral</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>5 days</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Cisplatin (Platinol)</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>5 days</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Bendamustine (Treanda, Belrapzo, Bendeka, generic)</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>28 days</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Cytarabine/Pf</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>14 days</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Vincristine (rarely used)</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV, Oral</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>7 days</td>
</tr>

<tr>
    <td rowSpan="3" style={{ border: '1px solid black', wordWrap: 'break-word', textAlign: 'center', fontWeight: 'normal' }}>
        
        
            <div>
                <span>Corticosteroid</span>
            </div> 
    </td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Prednisone</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Oral</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>1 day</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Prednisolone</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Oral</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>1 day</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Dexamethasone</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Small Molecule</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV, Oral</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>1 day</td>
</tr>

<tr>
    <td rowSpan="6" style={{ border: '1px solid black', wordWrap: 'break-word', textAlign: 'center', fontWeight: 'normal' }}>

            <div>
            <span>B-cell maturation antigen (BCMA) targeted therapy</span>
            </div>
    </td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Belantamab mafodotin-blmf (Blenrep)</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Biologics</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>21 days</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Idecabtagene vicleucel (Abecma)</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Car-T Cell Therapy</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>1 day</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Ciltacabtagene autoleucel (Carvykti)</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Car-T Cell Therapy</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>IV</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>1 day</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Teclistamab (Tecvayli)</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Biologics</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>SQ</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>7 days</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Elranatamab (Elrexfio)</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Biologics</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>SQ</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>14 days</td>
</tr>

<tr>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Linvoseltamab</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>Biologics</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>SQ</td>
    <td style={{ border: '1px solid black', fontWeight: 'normal', wordWrap: 'break-word' }}>TBD</td>
</tr>



<tr>
    <td style={{ border: '1px solid black', textAlign: 'center', textAlign: 'center', fontWeight: 'normal' }}>
        G protein–coupled receptor, class C, group 5, member D (GPRC5D) targeted therapy
    </td>
    <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>Talquetamab (Talvey)</td>
    <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>Biologics</td>
    <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>SQ</td>
    <td style={{ border: '1px solid black',  fontWeight: 'normal' }}>14 days</td>
</tr>

    </tbody>
</table>

                                            
                                            {/* Static Table End */}




                                            {/* <p style={{ textAlign: 'left', fontWeight: 'normal' }}>
                                            <i>*Except where specified, all days’ supply is imputed from the NCCN compendium of drugs and biologics.</i>
                                            </p> */}
                                        </div>

                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default LineOfTherapy