import React, { useContext, useEffect, useState } from "react";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import { HTTPResponse, PageDesignConstants, RRMMCohortTreatment, SideMenuItems, ToastMessages, ToastMessageType } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import InfoPopUp from "../../Components/InfoPopUp";
import { AdverseEvents_Msgs, CommonError_Msgs, hyperlinkTextAEReporting } from "../../Helpers/HelperText";
import DateControl from "../../Components/DateControl";
import PatientDetails from '../../Components/PatientInformation';
import { AppContext } from "../../Contexts/AppContextProvider";
import ErrorField from "../../Components/ErrorField";
import { DateDisplayFormat, GetEarliestDate, GetLocalStorageData, GetLoggedInUserID, GetOrdinal, IsDateAfter, IsDateEqual, isStringEmpty } from "../../Helpers/Utilities";
import { AdverseEventsService } from "../../WebApiServices/AdverseEvents.service";

const AdverseEvents = () => {
  const navigatePaths = {
    prevPage: "/TreatmentResponseProgression",
    currPage: "/AdverseEvents",
    nextPage: "/AEReporting",
  };

  const pid = GetLocalStorageData("patientId") ?? 0;
  const { ShowToast, ToggleLoader, HandleSessionTimeout, isSideMenuActive, setIsSideMenuActive } = useContext(AppContext);
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);
  const [showHideHyperlinkModalAEReporting, setShowHideHyperlinkModalAEReporting] = useState(false);

  const [rrmmSelection, setRRMMSelection] = useState(
    {
      isTECVAYLISelected: false,
      isTALVEYSelected: false,
      isELREXFIOSelected: false,
      isLinvoseltamabSelected: false,
      isCARVYKTISelected: false,
      isABECMASelected: false,
    }
  );
  const q53_crs_row = {
    crsId: null,
    rbl_B: null,
    date_B: null,
    rbl_C: null,

    B_empty_err: false,
    B_date_empty_err: false,
    B_date_before_err: false,//Date before Index
    B_date_after_err: false,//Date after AE End
    B_date_equal_prev_ep_err: false,//Date same as previous episode
    C_empty_err: false
  };
  const q54_icans_row = {
    icansId: null,
    rbl_B: null,
    date_B: null,

    B_empty_err: false,
    B_date_empty_err: false,
    B_date_before_err: false,
    B_date_after_err: false,
    B_date_equal_prev_ep_err: false,
  };
  const q55_neutropenia_row = {
    neutropeniaId: null,
    rbl_B: null,
    date_B: null,
    rbl_C: null,
    date_C: null,

    B_empty_err: false,
    B_date_empty_err: false,
    B_date_before_err: false,
    B_date_after_err: false,
    B_date_equal_prev_ep_err: false,
    C_empty_err: false,
    C_date_empty_err: false,
    C_date_before_index_err: false,//Date before Index Date
    C_date_before_onset_err: false,//Date before Onset Date
    C_date_after_err: false, //Date after AE End Date 
  };
  const q56_cytopenia_row = {
    cytopeniaId: null,
    rbl_B: null,
    date_B: null,
    rbl_C: null,
    date_C: null,

    B_empty_err: false,
    B_date_empty_err: false,
    B_date_before_err: false,
    B_date_after_err: false,
    B_date_equal_prev_ep_err: false,
    C_empty_err: false,
    C_date_empty_err: false,
    C_date_before_index_err: false,//Date before Index Date
    C_date_before_onset_err: false,//Date before Onset Date
    C_date_after_err: false, //Date after AE End Date 
  };
  const q57_infection_row = {
    infectionId: null,
    rbl_B: null,
    date_B: null,
    chk_C_1: false,
    chk_C_2: false,
    chk_C_3: false,
    rb_C: false,
    chk_D_1: false,
    chk_D_2: false,
    chk_D_3: false,
    chk_D_4: false,
    chk_D_5: false,
    chk_D_6: false,
    chk_D_7: false,
    chk_D_8: false,
    chk_D_9: false,
    chk_D_10: false,
    chk_D_11: false,
    chk_D_12: false,
    chk_D_13: false,
    chk_D_14: false,
    chk_D_15: false,
    txt_D_oth: "",
    rb_D: false,
    rbl_E: null,
    rbl_F: null,

    B_empty_err: false,
    B_date_empty_err: false,
    B_date_before_err: false,
    B_date_after_err: false,
    B_date_equal_prev_ep_err: false,
    C_empty_err: false,
    D_empty_err: false,
    D_txt_empty_err: false,
    E_empty_err: false,
    F_empty_err: false,
  };
  const q58_weightLoss_row = {
    weightLossId: null,
    rbl_B: null,
    date_B: null,
    rbl_C: null,
    date_C: null,
    rbl_D: null,
    date_D: null,

    B_empty_err: false,
    B_date_empty_err: false,
    B_date_before_err: false,
    B_date_after_err: false,
    B_date_equal_prev_ep_err: false,
    C_empty_err: false,
    C_date_empty_err: false,
    C_date_before_index_err: false,
    C_date_before_onset_err: false,
    C_date_after_err: false,
    D_empty_err: false,
    D_date_empty_err: false,
    D_date_before_index_err: false,
    D_date_before_onset_err: false,
    D_date_before_res_err: false,
    D_date_after_err: false,
  };
  const q59_dysgeusia_row = {
    dysgeusiaId: null,
    rbl_B: null,
    date_B: null,
    rbl_C: null,
    date_C: null,

    chk_CC_1: false,
    chk_CC_1_P: false,
    chk_CC_1_T: false,
    chk_CC_2: false,
    chk_CC_2_P: false,
    chk_CC_2_T: false,
    chk_CC_3: false,
    chk_CC_3_P: false,
    chk_CC_3_T: false,
    chk_CC_4: false,
    chk_CC_4_P: false,
    chk_CC_4_T: false,
    chk_CC_5: false,
    chk_CC_5_P: false,
    chk_CC_5_T: false,
    chk_CC_6: false,
    chk_CC_6_P: false,
    chk_CC_6_T: false,
    chk_CC_7: false,
    chk_CC_7_P: false,
    chk_CC_7_T: false,
    txt_CC_oth: "",
    rb_CC: false,

    rbl_D: null,
    date_D: null,

    B_empty_err: false,
    B_date_empty_err: false,
    B_date_before_err: false,
    B_date_after_err: false,
    B_date_equal_prev_ep_err: false,

    CC_1_empty_err: false,
    CC_2_empty_err: false,
    CC_3_empty_err: false,
    CC_4_empty_err: false,
    CC_5_empty_err: false,
    CC_6_empty_err: false,
    CC_7_empty_err: false,
    CC_empty_err: false,
    CC_txt_empty_err: false,

    C_empty_err: false,
    C_date_empty_err: false,
    C_date_before_index_err: false,
    C_date_before_onset_err: false,
    C_date_after_err: false,
    D_empty_err: false,
    D_date_empty_err: false,
    D_date_before_index_err: false,
    D_date_before_onset_err: false,
    D_date_before_impr_err: false,
    D_date_after_err: false,
  };
  const q60_nail_row = {
    nailDisorderId: null,
    rbl_B: null,
    date_B: null,
    rbl_C: null,
    date_C: null,
    rbl_D: null,
    date_D: null,

    B_empty_err: false,
    B_date_empty_err: false,
    B_date_before_err: false,
    B_date_after_err: false,
    B_date_equal_prev_ep_err: false,
    C_empty_err: false,
    C_date_empty_err: false,
    C_date_before_index_err: false,
    C_date_before_onset_err: false,
    C_date_after_err: false,
    D_empty_err: false,
    D_date_empty_err: false,
    D_date_before_index_err: false,
    D_date_before_onset_err: false,
    D_date_before_impr_err: false,
    D_date_after_err: false,
  };
  const q61_skin_row = {
    skinDisorderId: null,
    rbl_B: null,
    date_B: null,
    rbl_C: null,
    date_C: null,
    rbl_D: null,
    date_D: null,

    B_empty_err: false,
    B_date_empty_err: false,
    B_date_before_err: false,
    B_date_after_err: false,
    B_date_equal_prev_ep_err: false,

    C_empty_err: false,
    C_date_empty_err: false,
    C_date_before_index_err: false,
    C_date_before_onset_err: false,
    C_date_after_err: false,
    D_empty_err: false,
    D_date_empty_err: false,
    D_date_before_index_err: false,
    D_date_before_onset_err: false,
    D_date_before_impr_err: false,
    D_date_after_err: false,
  };
  const q62_spm_row = {
    spmId: null,
    rbl_B: null,
    date_B: null,

    B_empty_err: false,
    B_date_empty_err: false,
    B_date_before_err: false,
    B_date_after_err: false,
    B_date_equal_prev_ep_err: false,
  };
  const q63_neurotoxicity_row = {
    neurotoxicityId: null,
    rbl_B: null,
    date_B: null,
    rbl_C: null,
    date_C: null,
    rbl_D: null,
    rbl_E: null,
    chk_F_1: false,
    chk_F_2: false,
    rb_F: false,
    chk_G_1: false,
    chk_G_2: false,
    chk_G_3: false,
    chk_G_4: false,
    chk_G_5: false,
    rb_G: false,

    B_empty_err: false,
    B_date_empty_err: false,
    B_date_before_err: false,
    B_date_after_err: false,
    B_date_equal_prev_ep_err: false,
    C_empty_err: false,
    C_date_empty_err: false,
    C_date_before_index_err: false,//Date before Index Date
    C_date_before_onset_err: false,//Date before Onset Date
    C_date_after_err: false, //Date after AE End Date 
    D_empty_err: false,
    E_empty_err: false,
    F_empty_err: false,
    G_empty_err: false,
  };

  const AERow = {
    cohortTreatmentId: null,
    cohortTreatmentName: "[Bispecifics/CAR-T]",
    superScriptText: "",
    cohortIndexDate: "[Bispecifics/CAR-T index date]",
    aeEndDate: "[AE end date]",

    q53_crs_list: [q53_crs_row],//Remove
    q54_icans_list: [q54_icans_row],//Remove
    q55_neutropenia_list: [q55_neutropenia_row],//Remove
    q56_cytopenia_list: [q56_cytopenia_row],//Remove
    q57_infection_list: [q57_infection_row],//Remove
    q58_weightLoss_list: [q58_weightLoss_row],//Remove
    q59_dysgeusia_list: [q59_dysgeusia_row],//Remove
    q60_nail_list: [q60_nail_row],//Remove
    q61_skin_list: [q61_skin_row],//Remove
    q62_spm_list: [q62_spm_row],//Secondary primary malignancy  //Remove
    q63_neurotoxicity_list: [q63_neurotoxicity_row],//Remove
  }
  const [aeDetails, setAEDetails] = useState([AERow]);

  const HandleRepeaterFieldChange = (e) => {

    const { name, id, value } = e.target;
    const value_int = Number(value);

    const lastUnderscoreIndex = name.lastIndexOf('_')
    const parentIndex = name.substring(lastUnderscoreIndex + 1);

    let updatedArray = [...aeDetails];

    switch (name) {
      //Q53
      case "q53_rbl_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q53_crs_list];
          let rbl_C = innerArr[id].rbl_C;
          let C_empty_err = innerArr[id].C_empty_err;

          //Delete rows if "The patient did not experience CRS" is selected and reset column C of current row
          if (value_int === 1) {
            rbl_C = null;
            C_empty_err = false;

            const deleteStartIndex = Number(id) + 1;
            if (deleteStartIndex >= 0 && deleteStartIndex < innerArr.length) {
              innerArr.splice(deleteStartIndex, innerArr.length - deleteStartIndex);
            }
          }

          // Update the specific item in the inner array
          innerArr[id] = {
            ...innerArr[id],
            rbl_B: value_int,
            date_B: null,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
            rbl_C,
            C_empty_err
          };

          // Assign the updated inner array back to the outer array
          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q53_crs_list: innerArr
          };
        }
        break;
      case "q53_rbl_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q53_crs_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: value_int,
            C_empty_err: false
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q53_crs_list: innerArr
          };
        }
        break;
      //Q54
      case "q54_rbl_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q54_icans_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_B: value_int,
            date_B: null,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          //Delete rows if "The patient did not experience ICANS" is selected
          if (value_int === 1) {
            const deleteStartIndex = Number(id) + 1;
            if (deleteStartIndex >= 0 && deleteStartIndex < innerArr.length) {
              innerArr.splice(deleteStartIndex, innerArr.length - deleteStartIndex);
            }
          }

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q54_icans_list: innerArr
          };
        }
        break;
      //Q55
      case "q55_rbl_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q55_neutropenia_list];
          let row = innerArr[id];

          //Delete rows if "The patient did not experience neutropenia" is selected and reset column C of current row
          if (value_int === 1) {
            row = {
              ...row,
              rbl_C: null,
              date_C: null,
              C_empty_err: false,
              C_date_empty_err: false,
              C_date_before_index_err: false,
              C_date_before_onset_err: false,
              C_date_after_err: false,
            }

            const deleteStartIndex = Number(id) + 1;
            if (deleteStartIndex >= 0 && deleteStartIndex < innerArr.length) {
              innerArr.splice(deleteStartIndex, innerArr.length - deleteStartIndex);
            }
          }

          innerArr[id] = {
            ...row,
            rbl_B: value_int,
            date_B: null,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q55_neutropenia_list: innerArr
          };
        }
        break;
      case "q55_rbl_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q55_neutropenia_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: value_int,
            date_C: null,
            C_empty_err: false,
            C_date_empty_err: false,
            C_date_before_index_err: false,
            C_date_before_onset_err: false,
            C_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q55_neutropenia_list: innerArr
          };
        }
        break;
      //Q56
      case "q56_rbl_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q56_cytopenia_list];
          let row = innerArr[id];

          //Delete rows if "The patient did not experience cytopenia" is selected and reset column C of current row
          if (value_int === 1) {
            row = {
              ...row,
              rbl_C: null,
              date_C: null,
              C_empty_err: false,
              C_date_empty_err: false,
              C_date_before_index_err: false,
              C_date_before_onset_err: false,
              C_date_after_err: false,
            };

            const deleteStartIndex = Number(id) + 1;
            if (deleteStartIndex >= 0 && deleteStartIndex < innerArr.length) {
              innerArr.splice(deleteStartIndex, innerArr.length - deleteStartIndex);
            }
          }

          innerArr[id] = {
            ...row,
            rbl_B: value_int,
            date_B: null,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q56_cytopenia_list: innerArr
          };
        }
        break;
      case "q56_rbl_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q56_cytopenia_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: value_int,
            date_C: null,
            C_empty_err: false,
            C_date_empty_err: false,
            C_date_before_index_err: false,
            C_date_before_onset_err: false,
            C_date_after_err: false,

          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q56_cytopenia_list: innerArr
          };
        }
        break;
      //Q57
      case "q57_rbl_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q57_infection_list];
          let row = innerArr[id];

          //Delete rows if "The patient did not experience neurotoxicity" is selected and reset column C, D of current row
          if (value_int === 1) {
            row = {
              ...row,
              chk_C_1: false,
              chk_C_2: false,
              chk_C_3: false,
              rb_C: false,
              C_empty_err: false,
              chk_D_1: false,
              chk_D_2: false,
              chk_D_3: false,
              chk_D_4: false,
              chk_D_5: false,
              chk_D_6: false,
              chk_D_7: false,
              chk_D_8: false,
              chk_D_9: false,
              chk_D_10: false,
              chk_D_11: false,
              chk_D_12: false,
              chk_D_13: false,
              chk_D_14: false,
              chk_D_15: false,
              txt_D_oth: "",
              rb_D: false,
              D_empty_err: false,
              D_txt_empty_err: false,
              rbl_E: null,
              E_empty_err: false,
              rbl_F: null,
              F_empty_err: false,
            };

            const deleteStartIndex = Number(id) + 1;
            if (deleteStartIndex >= 0 && deleteStartIndex < innerArr.length) {
              innerArr.splice(deleteStartIndex, innerArr.length - deleteStartIndex);
            }
          }

          innerArr[id] = {
            ...row,
            rbl_B: value_int,
            date_B: null,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q57_infection_list: innerArr
          };
        }
        break;
      case "q57_chk_C_1_" + id + "_" + parentIndex:
      case "q57_chk_C_2_" + id + "_" + parentIndex:
      case "q57_chk_C_3_" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q57_infection_list];
          // Extract the Check box state name
          const parts = name.split("_");
          const ctrlId = "chk_C_" + parts[3];

          innerArr[id] = {
            ...innerArr[id],

            [ctrlId]: !innerArr[id][ctrlId],
            rb_C: false,
            C_empty_err: false
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q57_infection_list: innerArr
          };
        }
        break;
      case "q57_rb_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q57_infection_list];

          innerArr[id] = {
            ...innerArr[id],

            rb_C: true,
            chk_C_1: false,
            chk_C_2: false,
            chk_C_3: false,
            C_empty_err: false
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q57_infection_list: innerArr
          };
        }
        break;
      case "q57_chk_D_1_" + id + "_" + parentIndex:
      case "q57_chk_D_2_" + id + "_" + parentIndex:
      case "q57_chk_D_3_" + id + "_" + parentIndex:
      case "q57_chk_D_4_" + id + "_" + parentIndex:
      case "q57_chk_D_5_" + id + "_" + parentIndex:
      case "q57_chk_D_6_" + id + "_" + parentIndex:
      case "q57_chk_D_7_" + id + "_" + parentIndex:
      case "q57_chk_D_8_" + id + "_" + parentIndex:
      case "q57_chk_D_9_" + id + "_" + parentIndex:
      case "q57_chk_D_10_" + id + "_" + parentIndex:
      case "q57_chk_D_11_" + id + "_" + parentIndex:
      case "q57_chk_D_12_" + id + "_" + parentIndex:
      case "q57_chk_D_13_" + id + "_" + parentIndex:
      case "q57_chk_D_14_" + id + "_" + parentIndex:
      case "q57_chk_D_15_" + id + "_" + parentIndex:
        {

          let innerArr = [...updatedArray[parentIndex].q57_infection_list];
          // Extract the Check box state name
          const parts = name.split("_");
          const ctrlId = "chk_D_" + parts[3];

          innerArr[id] = {
            ...innerArr[id],

            [ctrlId]: !innerArr[id][ctrlId],
            txt_D_oth: ctrlId === "chk_D_15" ? "" : innerArr[id].txt_D_oth,
            rb_D: false,
            D_empty_err: false,
            D_txt_empty_err: false,// ctrlId === "chk_D_15" ? false : innerArr[id].D_txt_empty_err
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q57_infection_list: innerArr
          };
        }
        break;
      case "q57_txt_D_oth" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q57_infection_list];

          innerArr[id] = {
            ...innerArr[id],
            txt_D_oth: value,
            chk_D_15: true,
            D_empty_err: false,
            D_txt_empty_err: false
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q57_infection_list: innerArr
          };
        }
        break;
      case "q57_rb_D" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q57_infection_list];

          innerArr[id] = {
            ...innerArr[id],

            rb_D: true,
            chk_D_1: false,
            chk_D_2: false,
            chk_D_3: false,
            chk_D_4: false,
            chk_D_5: false,
            chk_D_6: false,
            chk_D_7: false,
            chk_D_8: false,
            chk_D_9: false,
            chk_D_10: false,
            chk_D_11: false,
            chk_D_12: false,
            chk_D_13: false,
            chk_D_14: false,
            chk_D_15: false,
            txt_D_oth: "",
            D_empty_err: false,
            D_txt_empty_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q57_infection_list: innerArr
          };
        }
        break;
      case "q57_rbl_E" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q57_infection_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_E: value_int,
            E_empty_err: false
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q57_infection_list: innerArr
          };
        }
        break;
      case "q57_rbl_F" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q57_infection_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_F: value_int,
            F_empty_err: false
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q57_infection_list: innerArr
          };
        }
        break;

      //Q58
      case "q58_rbl_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q58_weightLoss_list];
          let row = innerArr[id];

          //Delete rows if "The patient did not experience weight loss" is selected and reset column C n D of current row
          if (value_int === 1) {
            row = {
              ...row,
              rbl_C: null,
              date_C: null,
              C_empty_err: false,
              C_date_empty_err: false,
              C_date_before_index_err: false,
              C_date_before_onset_err: false,
              C_date_after_err: false,
              rbl_D: null,
              date_D: null,
              D_empty_err: false,
              D_date_empty_err: false,
              D_date_before_index_err: false,
              D_date_before_onset_err: false,
              D_date_before_res_err: false,
              D_date_after_err: false,
            }

            const deleteStartIndex = Number(id) + 1;
            if (deleteStartIndex >= 0 && deleteStartIndex < innerArr.length) {
              innerArr.splice(deleteStartIndex, innerArr.length - deleteStartIndex);
            }
          }

          innerArr[id] = {
            ...row,
            rbl_B: value_int,
            date_B: null,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q58_weightLoss_list: innerArr
          };
        }
        break;
      case "q58_rbl_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q58_weightLoss_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: value_int,
            date_C: null,
            C_empty_err: false,
            C_date_empty_err: false,
            C_date_before_index_err: false,
            C_date_before_onset_err: false,
            C_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q58_weightLoss_list: innerArr
          };
        }
        break;
      case "q58_rbl_D" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q58_weightLoss_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_D: value_int,
            date_D: null,
            D_empty_err: false,
            D_date_empty_err: false,
            D_date_before_index_err: false,
            D_date_before_onset_err: false,
            D_date_before_res_err: false,
            D_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q58_weightLoss_list: innerArr
          };
        }
        break;
      //Q59
      case "q59_rbl_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q59_dysgeusia_list];
          let row = innerArr[id];

          //Delete rows if "The patient did not experience dysgeusia" is selected and reset column C, D of current row
          if (value_int === 1) {
            row = {
              ...row,
              chk_CC_1: false,
              chk_CC_1_P: false,
              chk_CC_1_T: false,
              chk_CC_2: false,
              chk_CC_2_P: false,
              chk_CC_2_T: false,
              chk_CC_3: false,
              chk_CC_3_P: false,
              chk_CC_3_T: false,
              chk_CC_4: false,
              chk_CC_4_P: false,
              chk_CC_4_T: false,
              chk_CC_5: false,
              chk_CC_5_P: false,
              chk_CC_5_T: false,
              chk_CC_6: false,
              chk_CC_6_P: false,
              chk_CC_6_T: false,
              chk_CC_7: false,
              chk_CC_7_P: false,
              chk_CC_7_T: false,
              txt_CC_oth: "",
              rb_CC: false,
              CC_1_empty_err: false,
              CC_2_empty_err: false,
              CC_3_empty_err: false,
              CC_4_empty_err: false,
              CC_5_empty_err: false,
              CC_6_empty_err: false,
              CC_7_empty_err: false,
              CC_empty_err: false,
              CC_txt_empty_err: false,

              rbl_C: null,
              date_C: null,
              C_empty_err: false,
              C_date_empty_err: false,
              C_date_before_index_err: false,
              C_date_before_onset_err: false,
              C_date_after_err: false,

              rbl_D: null,
              date_D: null,
              D_empty_err: false,
              D_date_empty_err: false,
              D_date_before_index_err: false,
              D_date_before_onset_err: false,
              D_date_before_impr_err: false,
              D_date_after_err: false,
            }

            const deleteStartIndex = Number(id) + 1;
            if (deleteStartIndex >= 0 && deleteStartIndex < innerArr.length) {
              innerArr.splice(deleteStartIndex, innerArr.length - deleteStartIndex);
            }
          }

          innerArr[id] = {
            ...row,
            rbl_B: value_int,
            date_B: null,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q59_dysgeusia_list: innerArr
          };
        }
        break;
      case "q59_chk_CC_1_" + id + "_" + parentIndex:
      case "q59_chk_CC_2_" + id + "_" + parentIndex:
      case "q59_chk_CC_3_" + id + "_" + parentIndex:
      case "q59_chk_CC_4_" + id + "_" + parentIndex:
      case "q59_chk_CC_5_" + id + "_" + parentIndex:
      case "q59_chk_CC_6_" + id + "_" + parentIndex:
      case "q59_chk_CC_7_" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q59_dysgeusia_list];
          // Extract the Check box state name
          const parts = name.split("_");
          const ctrlId = "chk_CC_" + parts[3];

          innerArr[id] = {
            ...innerArr[id],

            [ctrlId]: !innerArr[id][ctrlId],
            [ctrlId + "_P"]: false,
            [ctrlId + "_T"]: false,

            txt_CC_oth: ctrlId === "chk_CC_7" ? "" : innerArr[id].txt_CC_oth,
            rb_CC: false,
            CC_empty_err: false,
            CC_txt_empty_err: false,//ctrlId === "chk_CC_7" ? false : innerArr[id].CC_txt_empty_err,
            ["CC_" + parts[3] + "_empty_err"]: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q59_dysgeusia_list: innerArr
          };
        }
        break;
      case "q59_txt_CC_oth" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q59_dysgeusia_list];

          innerArr[id] = {
            ...innerArr[id],
            txt_CC_oth: value,
            chk_CC_7: true,
            CC_empty_err: false,
            CC_txt_empty_err: false
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q59_dysgeusia_list: innerArr
          };
        }
        break;
      case "q59_rb_CC" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q59_dysgeusia_list];

          innerArr[id] = {
            ...innerArr[id],

            rb_CC: true,
            chk_CC_1: false,
            chk_CC_1_P: false,
            chk_CC_1_T: false,
            chk_CC_2: false,
            chk_CC_2_P: false,
            chk_CC_2_T: false,
            chk_CC_3: false,
            chk_CC_3_P: false,
            chk_CC_3_T: false,
            chk_CC_4: false,
            chk_CC_4_P: false,
            chk_CC_4_T: false,
            chk_CC_5: false,
            chk_CC_5_P: false,
            chk_CC_5_T: false,
            chk_CC_6: false,
            chk_CC_6_P: false,
            chk_CC_6_T: false,
            chk_CC_7: false,
            chk_CC_7_P: false,
            chk_CC_7_T: false,
            txt_CC_oth: "",

            CC_1_empty_err: false,
            CC_2_empty_err: false,
            CC_3_empty_err: false,
            CC_4_empty_err: false,
            CC_5_empty_err: false,
            CC_6_empty_err: false,
            CC_7_empty_err: false,
            CC_empty_err: false,
            CC_txt_empty_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q59_dysgeusia_list: innerArr
          };
        }
        break;

      case "q59_chk_CC_1_P_" + id + "_" + parentIndex:
      case "q59_chk_CC_2_P_" + id + "_" + parentIndex:
      case "q59_chk_CC_3_P_" + id + "_" + parentIndex:
      case "q59_chk_CC_4_P_" + id + "_" + parentIndex:
      case "q59_chk_CC_5_P_" + id + "_" + parentIndex:
      case "q59_chk_CC_6_P_" + id + "_" + parentIndex:
      case "q59_chk_CC_7_P_" + id + "_" + parentIndex:

      case "q59_chk_CC_1_T_" + id + "_" + parentIndex:
      case "q59_chk_CC_2_T_" + id + "_" + parentIndex:
      case "q59_chk_CC_3_T_" + id + "_" + parentIndex:
      case "q59_chk_CC_4_T_" + id + "_" + parentIndex:
      case "q59_chk_CC_5_T_" + id + "_" + parentIndex:
      case "q59_chk_CC_6_T_" + id + "_" + parentIndex:
      case "q59_chk_CC_7_T_" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q59_dysgeusia_list];
          // Extract the Check box state name
          const parts = name.split("_");
          const ctrlId = "chk_CC_" + parts[3] + "_" + parts[4];

          innerArr[id] = {
            ...innerArr[id],

            [ctrlId]: !innerArr[id][ctrlId],
            ["CC_" + parts[3] + "_empty_err"]: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q59_dysgeusia_list: innerArr
          };
        }
        break;


      case "q59_rbl_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q59_dysgeusia_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: value_int,
            date_C: null,
            C_empty_err: false,
            C_date_empty_err: false,
            C_date_before_index_err: false,
            C_date_before_onset_err: false,
            C_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q59_dysgeusia_list: innerArr
          };
        }
        break;
      case "q59_rbl_D" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q59_dysgeusia_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_D: value_int,
            date_D: null,
            D_empty_err: false,
            D_date_empty_err: false,
            D_date_before_index_err: false,
            D_date_before_onset_err: false,
            D_date_before_impr_err: false,
            D_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q59_dysgeusia_list: innerArr
          };
        }
        break;
      //60
      case "q60_rbl_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q60_nail_list];
          let row = innerArr[id];

          //Delete rows if "The patient did not experience nail disorder" is selected and reset column C, D of current row
          if (value_int === 1) {
            row = {
              ...row,
              rbl_C: null,
              date_C: null,
              C_empty_err: false,
              C_date_empty_err: false,
              C_date_before_index_err: false,
              C_date_before_onset_err: false,
              C_date_after_err: false,

              rbl_D: null,
              date_D: null,
              D_empty_err: false,
              D_date_empty_err: false,
              D_date_before_index_err: false,
              D_date_before_onset_err: false,
              D_date_before_impr_err: false,
              D_date_after_err: false,
            }

            const deleteStartIndex = Number(id) + 1;
            if (deleteStartIndex >= 0 && deleteStartIndex < innerArr.length) {
              innerArr.splice(deleteStartIndex, innerArr.length - deleteStartIndex);
            }
          }

          innerArr[id] = {
            ...row,
            rbl_B: value_int,
            date_B: null,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q60_nail_list: innerArr
          };
        }
        break;
      case "q60_rbl_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q60_nail_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: value_int,
            date_C: null,
            C_empty_err: false,
            C_date_empty_err: false,
            C_date_before_index_err: false,
            C_date_before_onset_err: false,
            C_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q60_nail_list: innerArr
          };
        }
        break;
      case "q60_rbl_D" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q60_nail_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_D: value_int,
            date_D: null,
            D_empty_err: false,
            D_date_empty_err: false,
            D_date_before_index_err: false,
            D_date_before_onset_err: false,
            D_date_before_impr_err: false,
            D_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q60_nail_list: innerArr
          };
        }
        break;
      //61
      case "q61_rbl_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q61_skin_list];
          let row = innerArr[id];

          //Delete rows if "The patient did not experience skin disorder" is selected and reset column C, D of current row
          if (value_int === 1) {
            row = {
              ...row,
              rbl_C: null,
              date_C: null,
              C_empty_err: false,
              C_date_empty_err: false,
              C_date_before_index_err: false,
              C_date_before_onset_err: false,
              C_date_after_err: false,

              rbl_D: null,
              date_D: null,
              D_empty_err: false,
              D_date_empty_err: false,
              D_date_before_index_err: false,
              D_date_before_onset_err: false,
              D_date_before_impr_err: false,
              D_date_after_err: false,
            }
            const deleteStartIndex = Number(id) + 1;
            if (deleteStartIndex >= 0 && deleteStartIndex < innerArr.length) {
              innerArr.splice(deleteStartIndex, innerArr.length - deleteStartIndex);
            }
          }

          innerArr[id] = {
            ...row,
            rbl_B: value_int,
            date_B: null,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q61_skin_list: innerArr
          };
        }
        break;
      case "q61_rbl_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q61_skin_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: value_int,
            date_C: null,
            C_empty_err: false,
            C_date_empty_err: false,
            C_date_before_index_err: false,
            C_date_before_onset_err: false,
            C_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q61_skin_list: innerArr
          };
        }
        break;
      case "q61_rbl_D" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q61_skin_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_D: value_int,
            date_D: null,
            D_empty_err: false,
            D_date_empty_err: false,
            D_date_before_index_err: false,
            D_date_before_onset_err: false,
            D_date_before_impr_err: false,
            D_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q61_skin_list: innerArr
          };
        }
        break;
      //Q62
      case "q62_rbl_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q62_spm_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_B: value_int,
            date_B: null,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          //Delete rows if "The patient did not experience ICANS" is selected
          if (value_int === 1) {
            const deleteStartIndex = Number(id) + 1;
            if (deleteStartIndex >= 0 && deleteStartIndex < innerArr.length) {
              innerArr.splice(deleteStartIndex, innerArr.length - deleteStartIndex);
            }
          }

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q62_spm_list: innerArr
          };
        }
        break;
      //63
      case "q63_rbl_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q63_neurotoxicity_list];
          let row = innerArr[id];

          //Delete rows if "The patient did not experience neurotoxicity" is selected and reset column C, D of current row
          if (value_int === 1) {
            row = {
              ...row,
              rbl_C: null,
              date_C: null,
              C_empty_err: false,
              C_date_empty_err: false,
              C_date_before_index_err: false,
              C_date_before_onset_err: false,
              C_date_after_err: false,
              rbl_D: null,
              D_empty_err: false,
              rbl_E: null,
              E_empty_err: false,
              chk_F_1: false,
              chk_F_2: false,
              rb_F: false,
              F_empty_err: false,
              chk_G_1: false,
              chk_G_2: false,
              chk_G_3: false,
              chk_G_4: false,
              chk_G_5: false,
              rb_G: false,
              G_empty_err: false,
            };

            const deleteStartIndex = Number(id) + 1;
            if (deleteStartIndex >= 0 && deleteStartIndex < innerArr.length) {
              innerArr.splice(deleteStartIndex, innerArr.length - deleteStartIndex);
            }
          }

          innerArr[id] = {
            ...row,
            rbl_B: value_int,
            date_B: null,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q63_neurotoxicity_list: innerArr
          };
        }
        break;
      case "q63_rbl_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q63_neurotoxicity_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: value_int,
            date_C: null,
            C_empty_err: false,
            C_date_empty_err: false,
            C_date_before_index_err: false,
            C_date_before_onset_err: false,
            C_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q63_neurotoxicity_list: innerArr
          };
        }
        break;
      case "q63_rbl_D" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q63_neurotoxicity_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_D: value_int,
            D_empty_err: false
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q63_neurotoxicity_list: innerArr
          };
        }
        break;
      case "q63_rbl_E" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q63_neurotoxicity_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_E: value_int,
            E_empty_err: false
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q63_neurotoxicity_list: innerArr
          };
        }
        break;
      case "q63_chk_F_1_" + id + "_" + parentIndex:
      case "q63_chk_F_2_" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q63_neurotoxicity_list];
          // Extract the Check box state name
          const parts = name.split("_");
          const ctrlId = "chk_F_" + parts[3];

          innerArr[id] = {
            ...innerArr[id],

            [ctrlId]: !innerArr[id][ctrlId],
            rb_F: false,
            F_empty_err: false
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q63_neurotoxicity_list: innerArr
          };
        }
        break;
      case "q63_rb_F" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q63_neurotoxicity_list];

          innerArr[id] = {
            ...innerArr[id],

            rb_F: true,
            chk_F_1: false,
            chk_F_2: false,
            F_empty_err: false
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q63_neurotoxicity_list: innerArr
          };
        }
        break;
      case "q63_chk_G_1_" + id + "_" + parentIndex:
      case "q63_chk_G_2_" + id + "_" + parentIndex:
      case "q63_chk_G_3_" + id + "_" + parentIndex:
      case "q63_chk_G_4_" + id + "_" + parentIndex:
      case "q63_chk_G_5_" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q63_neurotoxicity_list];
          // Extract the Check box state name
          const parts = name.split("_");
          const ctrlId = "chk_G_" + parts[3];

          innerArr[id] = {
            ...innerArr[id],

            [ctrlId]: !innerArr[id][ctrlId],
            rb_G: false,
            G_empty_err: false
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q63_neurotoxicity_list: innerArr
          };
        }
        break;
      case "q63_rb_G" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q63_neurotoxicity_list];

          innerArr[id] = {
            ...innerArr[id],

            rb_G: true,
            chk_G_1: false,
            chk_G_2: false,
            chk_G_3: false,
            chk_G_4: false,
            chk_G_5: false,
            G_empty_err: false
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q63_neurotoxicity_list: innerArr
          };
        }
        break;
      default: break;
    }
    setAEDetails(updatedArray);
  }

  const HandleRepeaterDateChange = (name, date, id) => {

    const lastUnderscoreIndex = name.lastIndexOf('_')
    const parentIndex = name.substring(lastUnderscoreIndex + 1);

    let updatedArray = [...aeDetails];
    switch (name) {
      case "q53_date_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q53_crs_list];

          // Update the specific item in the inner array
          innerArr[id] = {
            ...innerArr[id],
            rbl_B: 2,
            date_B: date,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          // Assign the updated inner array back to the outer array
          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q53_crs_list: innerArr
          };
        }
        break;
      case "q54_date_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q54_icans_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_B: 2,
            date_B: date,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q54_icans_list: innerArr
          };
        }
        break;
      case "q55_date_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q55_neutropenia_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_B: 2,
            date_B: date,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q55_neutropenia_list: innerArr
          };
        }
        break;
      case "q55_date_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q55_neutropenia_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: 2,
            date_C: date,
            C_empty_err: false,
            C_date_empty_err: false,
            C_date_before_index_err: false,
            C_date_before_onset_err: false,
            C_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q55_neutropenia_list: innerArr
          };
        }
        break;
      case "q56_date_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q56_cytopenia_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_B: 2,
            date_B: date,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q56_cytopenia_list: innerArr
          };
        }
        break;
      case "q56_date_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q56_cytopenia_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: 2,
            date_C: date,
            C_empty_err: false,
            C_date_empty_err: false,
            C_date_before_index_err: false,
            C_date_before_onset_err: false,
            C_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q56_cytopenia_list: innerArr
          };
        }
        break;
      case "q57_date_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q57_infection_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_B: 2,
            date_B: date,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q57_infection_list: innerArr
          };
        }
        break;
      case "q58_date_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q58_weightLoss_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_B: 2,
            date_B: date,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q58_weightLoss_list: innerArr
          };
        }
        break;
      case "q58_date_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q58_weightLoss_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: 2,
            date_C: date,
            C_empty_err: false,
            C_date_empty_err: false,
            C_date_before_index_err: false,
            C_date_before_onset_err: false,
            C_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q58_weightLoss_list: innerArr
          };
        }
        break;
      case "q58_date_D" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q58_weightLoss_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_D: 2,
            date_D: date,
            D_empty_err: false,
            D_date_empty_err: false,
            D_date_before_index_err: false,
            D_date_before_onset_err: false,
            D_date_before_res_err: false,
            D_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q58_weightLoss_list: innerArr
          };
        }
        break;
      case "q59_date_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q59_dysgeusia_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_B: 2,
            date_B: date,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q59_dysgeusia_list: innerArr
          };
        }
        break;
      case "q59_date_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q59_dysgeusia_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: 2,
            date_C: date,
            C_empty_err: false,
            C_date_empty_err: false,
            C_date_before_index_err: false,
            C_date_before_onset_err: false,
            C_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q59_dysgeusia_list: innerArr
          };
        }
        break;
      case "q59_date_D" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q59_dysgeusia_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_D: 2,
            date_D: date,
            D_empty_err: false,
            D_date_empty_err: false,
            D_date_before_index_err: false,
            D_date_before_onset_err: false,
            D_date_before_impr_err: false,
            D_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q59_dysgeusia_list: innerArr
          };
        }
        break;
      case "q60_date_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q60_nail_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_B: 2,
            date_B: date,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q60_nail_list: innerArr
          };
        }
        break;
      case "q60_date_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q60_nail_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: 2,
            date_C: date,
            C_empty_err: false,
            C_date_empty_err: false,
            C_date_before_index_err: false,
            C_date_before_onset_err: false,
            C_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q60_nail_list: innerArr
          };
        }
        break;
      case "q60_date_D" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q60_nail_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_D: 2,
            date_D: date,
            D_empty_err: false,
            D_date_empty_err: false,
            D_date_before_index_err: false,
            D_date_before_onset_err: false,
            D_date_before_impr_err: false,
            D_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q60_nail_list: innerArr
          };
        }
        break;
      case "q61_date_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q61_skin_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_B: 2,
            date_B: date,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q61_skin_list: innerArr
          };
        }
        break;
      case "q61_date_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q61_skin_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: 2,
            date_C: date,
            C_empty_err: false,
            C_date_empty_err: false,
            C_date_before_index_err: false,
            C_date_before_onset_err: false,
            C_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q61_skin_list: innerArr
          };
        }
        break;
      case "q61_date_D" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q61_skin_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_D: 2,
            date_D: date,
            D_empty_err: false,
            D_date_empty_err: false,
            D_date_before_index_err: false,
            D_date_before_onset_err: false,
            D_date_before_impr_err: false,
            D_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q61_skin_list: innerArr
          };
        }
        break;
      case "q62_date_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q62_spm_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_B: 2,
            date_B: date,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q62_spm_list: innerArr
          };
        }
        break;
      case "q63_date_B" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q63_neurotoxicity_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_B: 2,
            date_B: date,
            B_empty_err: false,
            B_date_empty_err: false,
            B_date_before_err: false,
            B_date_after_err: false,
            B_date_equal_prev_ep_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q63_neurotoxicity_list: innerArr
          };
        }
        break;
      case "q63_date_C" + id + "_" + parentIndex:
        {
          let innerArr = [...updatedArray[parentIndex].q63_neurotoxicity_list];

          innerArr[id] = {
            ...innerArr[id],
            rbl_C: 2,
            date_C: date,
            C_empty_err: false,
            C_date_empty_err: false,
            C_date_before_index_err: false,
            C_date_before_onset_err: false,
            C_date_after_err: false,
          };

          updatedArray[parentIndex] = {
            ...updatedArray[parentIndex],
            q63_neurotoxicity_list: innerArr
          };
        }
        break;

      default: break;
    }
    setAEDetails(updatedArray);
  };

  //Adding Deleting rows 
  const DeleteQ53Row = (cohortIndex, childIdx) => {
    const finalArray = [...aeDetails];
    const q53Array = [...aeDetails[cohortIndex].q53_crs_list];
    q53Array.splice(childIdx, 1);
    finalArray[cohortIndex].q53_crs_list = q53Array;

    setAEDetails(finalArray);
  }
  const AddQ53Row = (cohortIndex) => {//outer array index
    let newArray = [...aeDetails];
    // newArray[cohortIndex].q53_crs_list = [...newArray[cohortIndex].q53_crs_list, q53_crs_row];

    newArray[cohortIndex] = {
      ...newArray[cohortIndex],
      q53_crs_list: [
        ...newArray[cohortIndex].q53_crs_list,
        q53_crs_row
      ]
    };
    setAEDetails(newArray);
  }
  const DeleteQ54Row = (cohortIndex, childIdx) => {
    const finalArray = [...aeDetails];
    const q54Array = [...aeDetails[cohortIndex].q54_icans_list];
    q54Array.splice(childIdx, 1);
    finalArray[cohortIndex].q54_icans_list = q54Array;

    setAEDetails(finalArray);
  }
  const AddQ54Row = (cohortIndex) => {
    let newArray = [...aeDetails];

    newArray[cohortIndex] = {
      ...newArray[cohortIndex],
      q54_icans_list: [
        ...newArray[cohortIndex].q54_icans_list,
        q54_icans_row
      ]
    };
    setAEDetails(newArray);
  }
  const DeleteQ55Row = (cohortIndex, childIdx) => {
    const finalArray = [...aeDetails];
    const q55Array = [...aeDetails[cohortIndex].q55_neutropenia_list];
    q55Array.splice(childIdx, 1);
    finalArray[cohortIndex].q55_neutropenia_list = q55Array;

    setAEDetails(finalArray);
  }
  const AddQ55Row = (cohortIndex) => {
    let newArray = [...aeDetails];

    newArray[cohortIndex] = {
      ...newArray[cohortIndex],
      q55_neutropenia_list: [
        ...newArray[cohortIndex].q55_neutropenia_list,
        q55_neutropenia_row
      ]
    };
    setAEDetails(newArray);
  }
  const DeleteQ56Row = (cohortIndex, childIdx) => {
    const finalArray = [...aeDetails];
    const q56Array = [...aeDetails[cohortIndex].q56_cytopenia_list];
    q56Array.splice(childIdx, 1);
    finalArray[cohortIndex].q56_cytopenia_list = q56Array;

    setAEDetails(finalArray);
  }
  const AddQ56Row = (cohortIndex) => {
    let newArray = [...aeDetails];

    newArray[cohortIndex] = {
      ...newArray[cohortIndex],
      q56_cytopenia_list: [
        ...newArray[cohortIndex].q56_cytopenia_list,
        q56_cytopenia_row
      ]
    };
    setAEDetails(newArray);
  }
  const DeleteQ57Row = (cohortIndex, childIdx) => {
    const finalArray = [...aeDetails];
    const q57Array = [...aeDetails[cohortIndex].q57_infection_list];
    q57Array.splice(childIdx, 1);
    finalArray[cohortIndex].q57_infection_list = q57Array;

    setAEDetails(finalArray);
  }
  const AddQ57Row = (cohortIndex) => {
    let newArray = [...aeDetails];

    newArray[cohortIndex] = {
      ...newArray[cohortIndex],
      q57_infection_list: [
        ...newArray[cohortIndex].q57_infection_list,
        q57_infection_row
      ]
    };
    setAEDetails(newArray);
  }
  const DeleteQ58Row = (cohortIndex, childIdx) => {
    const finalArray = [...aeDetails];
    const q58Array = [...aeDetails[cohortIndex].q58_weightLoss_list];
    q58Array.splice(childIdx, 1);
    finalArray[cohortIndex].q58_weightLoss_list = q58Array;

    setAEDetails(finalArray);
  }
  const AddQ58Row = (cohortIndex) => {
    let newArray = [...aeDetails];

    newArray[cohortIndex] = {
      ...newArray[cohortIndex],
      q58_weightLoss_list: [
        ...newArray[cohortIndex].q58_weightLoss_list,
        q58_weightLoss_row
      ]
    };
    setAEDetails(newArray);
  }
  const DeleteQ59Row = (cohortIndex, childIdx) => {
    const finalArray = [...aeDetails];
    const q59Array = [...aeDetails[cohortIndex].q59_dysgeusia_list];
    q59Array.splice(childIdx, 1);
    finalArray[cohortIndex].q59_dysgeusia_list = q59Array;

    setAEDetails(finalArray);
  }
  const AddQ59Row = (cohortIndex) => {
    let newArray = [...aeDetails];

    newArray[cohortIndex] = {
      ...newArray[cohortIndex],
      q59_dysgeusia_list: [
        ...newArray[cohortIndex].q59_dysgeusia_list,
        q59_dysgeusia_row
      ]
    };
    setAEDetails(newArray);
  }
  const DeleteQ60Row = (cohortIndex, childIdx) => {
    const finalArray = [...aeDetails];
    const q60Array = [...aeDetails[cohortIndex].q60_nail_list];
    q60Array.splice(childIdx, 1);
    finalArray[cohortIndex].q60_nail_list = q60Array;

    setAEDetails(finalArray);
  }
  const AddQ60Row = (cohortIndex) => {
    let newArray = [...aeDetails];

    newArray[cohortIndex] = {
      ...newArray[cohortIndex],
      q60_nail_list: [
        ...newArray[cohortIndex].q60_nail_list,
        q60_nail_row
      ]
    };
    setAEDetails(newArray);
  }
  const DeleteQ61Row = (cohortIndex, childIdx) => {
    const finalArray = [...aeDetails];
    const q61Array = [...aeDetails[cohortIndex].q61_skin_list];
    q61Array.splice(childIdx, 1);
    finalArray[cohortIndex].q61_skin_list = q61Array;

    setAEDetails(finalArray);
  }
  const AddQ61Row = (cohortIndex) => {
    let newArray = [...aeDetails];

    newArray[cohortIndex] = {
      ...newArray[cohortIndex],
      q61_skin_list: [
        ...newArray[cohortIndex].q61_skin_list,
        q61_skin_row
      ]
    };
    setAEDetails(newArray);
  }
  const DeleteQ62Row = (cohortIndex, childIdx) => {
    const finalArray = [...aeDetails];
    const q62Array = [...aeDetails[cohortIndex].q62_spm_list];
    q62Array.splice(childIdx, 1);
    finalArray[cohortIndex].q62_spm_list = q62Array;

    setAEDetails(finalArray);
  }
  const AddQ62Row = (cohortIndex) => {
    let newArray = [...aeDetails];

    newArray[cohortIndex] = {
      ...newArray[cohortIndex],
      q62_spm_list: [
        ...newArray[cohortIndex].q62_spm_list,
        q62_spm_row
      ]
    };
    setAEDetails(newArray);
  }
  const DeleteQ63Row = (cohortIndex, childIdx) => {
    const finalArray = [...aeDetails];
    const q63Array = [...aeDetails[cohortIndex].q63_neurotoxicity_list];
    q63Array.splice(childIdx, 1);
    finalArray[cohortIndex].q63_neurotoxicity_list = q63Array;

    setAEDetails(finalArray);
  }
  const AddQ63Row = (cohortIndex) => {
    let newArray = [...aeDetails];

    newArray[cohortIndex] = {
      ...newArray[cohortIndex],
      q63_neurotoxicity_list: [
        ...newArray[cohortIndex].q63_neurotoxicity_list,
        q63_neurotoxicity_row
      ]
    };
    setAEDetails(newArray);
  }
  useEffect(() => {
    async function FetchData() {
      if (!isNaN(pid) && Number(pid) !== 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);
  async function LoadData() {
    try {
      if (pid > 0) {
        const response = await AdverseEventsService.GetAdverseEventsData(pid);
        if (response?.status !== HTTPResponse.OK) {
          if (response?.status === HTTPResponse.Unauthorized)
            HandleSessionTimeout();
          else if (response?.status === HTTPResponse.BadRequest)
            throw response.error;
        } else {
          const storedData = GetLocalStorageData("CohortTreatment");

          if (storedData) {
            const cohortTreatmentModelListt = JSON.parse(storedData);
            const rrmmTmtList = cohortTreatmentModelListt.filter(c => c.isEligible == true && !c.ndmm);

            if (rrmmTmtList && rrmmTmtList.length > 0) {

              let list = [];
              const aeDateList = response?.data?.aE_DateList;


              list = rrmmTmtList.map(ct => {

                const cohortTreatmentId = ct.cohortTreatmentID;
                const cohortTreatmentName = ct.cohortTreatmentName;
                const superScriptText = ct.cohortTreatmentNameSuperscript;
                const cohortIndexDate = ct.cohortIndexDate;
                let aeEndDate = "[AE end date]";

                const dateArray = aeDateList?.find(a => a.cohortTreatmentId === ct.cohortTreatmentID)?.dateArray;

                if (dateArray && dateArray.length > 0) {

                  const earliestDate = GetEarliestDate(dateArray);
                  if (earliestDate) {
                    aeEndDate = DateDisplayFormat(earliestDate);
                  }
                }

                const isTECVAYLI = ct.cohortTreatmentID === RRMMCohortTreatment.TECVAYLI;
                const isTALVEY = ct.cohortTreatmentID === RRMMCohortTreatment.TALVEY;
                const isELREXFIO = ct.cohortTreatmentID === RRMMCohortTreatment.ELREXFIO;
                const isLinvoseltamab = ct.cohortTreatmentID === RRMMCohortTreatment.Linvoseltamab;
                const isCARVYKTI = ct.cohortTreatmentID === RRMMCohortTreatment.CARVYKTI;
                const isABECMA = ct.cohortTreatmentID === RRMMCohortTreatment.ABECMA;

                //Check if data is present for a cohort tmt
                const dbRow = response?.data?.adverseEventsData?.find(a => a.cohortTreatmentId === ct.cohortTreatmentID)

                let q53_crs_list = [q53_crs_row];//[]; //show for all cohorts 
                let q54_icans_list = [q54_icans_row];//show for all cohorts 
                let q55_neutropenia_list = isTECVAYLI || isELREXFIO || isLinvoseltamab || isCARVYKTI || isABECMA ? [q55_neutropenia_row] : null//“Tec”, “Elran”, “Linvo”, “Cilta-cel” or “Ide-cel”
                let q56_cytopenia_list = isTECVAYLI || isELREXFIO || isLinvoseltamab || isCARVYKTI || isABECMA ? [q56_cytopenia_row] : null;// “Tec”, “Elran”, “Linvo”, “Cilta-cel” or “Ide-cel”
                let q57_infection_list = isTECVAYLI || isTALVEY || isELREXFIO || isLinvoseltamab ? [q57_infection_row] : null;//“Tec”, “Tal”, “Elran”, or “Linvo”
                let q58_weightLoss_list = isTALVEY ? [q58_weightLoss_row] : null;//“Tal”
                let q59_dysgeusia_list = isTALVEY ? [q59_dysgeusia_row] : null;//“Tal”
                let q60_nail_list = isTALVEY ? [q60_nail_row] : null;//“Tal”
                let q61_skin_list = isTALVEY ? [q61_skin_row] : null;//“Tal”
                let q62_spm_list = isCARVYKTI || isABECMA ? [q62_spm_row] : null;//“Cilta-cel” or “Ide-cel”
                let q63_neurotoxicity_list = isCARVYKTI || isABECMA ? [q63_neurotoxicity_row] : null;//“Cilta-cel” or “Ide-cel”
                if (dbRow) {
                  if (dbRow.aE_CRSList && dbRow.aE_CRSList.length > 0) {
                    q53_crs_list = dbRow.aE_CRSList.map(b => ({
                      ...q53_crs_row,
                      crsId: b.aE_CRSId,
                      rbl_B: b.crsNotExperienced ? 1 : b.csrDateUnk ? 99 : !isStringEmpty(b.csrDate) ? 2 : null,
                      date_B: b.csrDate,
                      rbl_C: b.severity
                    }))
                  }
                  if (dbRow.aE_IcansList && dbRow.aE_IcansList.length > 0) {
                    q54_icans_list = dbRow.aE_IcansList.map(b => ({
                      ...q54_icans_row,
                      icansId: b.aE_ICANSId,
                      rbl_B: b.icansNotExperienced ? 1 : b.icansOccuredDateUnk ? 99 : !isStringEmpty(b.icansOccuredDate) ? 2 : null,
                      date_B: b.icansOccuredDate
                    }))
                  }
                  if (dbRow.aE_NeutropeniumList && dbRow.aE_NeutropeniumList.length > 0) {
                    q55_neutropenia_list = dbRow.aE_NeutropeniumList.map(b => ({
                      ...q55_neutropenia_row,
                      neutropeniaId: b.aE_NeutropeniaId,
                      rbl_B: b.neutropeniaNotExperienced ? 1 : b.neutropeniaOnsetDateUnk ? 99 : !isStringEmpty(b.neutropeniaOnsetDate) ? 2 : null,
                      date_B: b.neutropeniaOnsetDate,
                      rbl_C: b.neutropeniaNotResolved ? 1 : b.neutropeniaResolutionDateUnk ? 3 : b.neutropeniaResolvedUnk ? 99 : !isStringEmpty(b.neutropeniaResolutionDate) ? 2 : null,
                      date_C: b.neutropeniaResolutionDate
                    }))
                  }
                  if (dbRow.aE_CytopeniumList && dbRow.aE_CytopeniumList.length > 0) {
                    q56_cytopenia_list = dbRow.aE_CytopeniumList.map(b => ({
                      ...q56_cytopenia_row,
                      cytopeniaId: b.aE_CytopeniaId,
                      rbl_B: b.cytopeniaNotExperienced ? 1 : b.cytopeniaOnsetDateUnk ? 99 : !isStringEmpty(b.cytopeniaOnsetDate) ? 2 : null,
                      date_B: b.cytopeniaOnsetDate,
                      rbl_C: b.cytopeniaNotResolved ? 1 : b.cytopeniaResolutionDateUnk ? 3 : b.cytopeniaResolvedUnk ? 99 : !isStringEmpty(b.cytopeniaResolutionDate) ? 2 : null,
                      date_C: b.cytopeniaResolutionDate
                    }))
                  }
                  if (dbRow.aE_InfectionList && dbRow.aE_InfectionList.length > 0) {
                    q57_infection_list = dbRow.aE_InfectionList.map(b => ({
                      ...q57_infection_row,
                      infectionId: b.aE_InfectionId,
                      rbl_B: b.infectionNotExperienced ? 1 : b.infectionOccuredDateUnk ? 99 : !isStringEmpty(b.infectionOccuredDate) ? 2 : null,
                      date_B: b.infectionOccuredDate,
                      chk_C_1: b.infectionTypeBacterial,
                      chk_C_2: b.infectionTypeViral,
                      chk_C_3: b.infectionTypeFungal,
                      rb_C: b.infectionTypeUnk,
                      chk_D_1: b.infectionOrganismAdenovirus,
                      chk_D_2: b.infectionOrganismCandida,
                      chk_D_3: b.infectionOrganismCovid19,
                      chk_D_4: b.infectionOrganismCytomegalovirus,
                      chk_D_5: b.infectionOrganismEscherichia,
                      chk_D_6: b.infectionOrganismHelicobacter,
                      chk_D_7: b.infectionOrganismHepatitis,
                      chk_D_8: b.infectionOrganismHerpes,
                      chk_D_9: b.infectionOrganismInfluenza,
                      chk_D_10: b.infectionOrganismPjp,
                      chk_D_11: b.infectionOrganismProgressive,
                      chk_D_12: b.infectionOrganismRsv,
                      chk_D_13: b.infectionOrganismStreptococcus,
                      chk_D_14: b.infectionOrganismVaricella,
                      chk_D_15: b.infectionOrganismOth,
                      txt_D_oth: b.infectionOrganismOthTxt || "",
                      rb_D: b.infectionOrganismUnk,
                      rbl_E: b.severity,
                      rbl_F: b.ledErVisit,
                    }))
                  }
                  if (dbRow.aE_WeightLossList && dbRow.aE_WeightLossList.length > 0) {
                    q58_weightLoss_list = dbRow.aE_WeightLossList.map(b => ({
                      ...q58_weightLoss_row,
                      weightLossId: b.aE_WeightLossId,
                      rbl_B: b.noWeightLoss ? 1 : b.weightLossOnsetDateUnk ? 99 : !isStringEmpty(b.weightLossOnsetDate) ? 2 : null,
                      date_B: b.weightLossOnsetDate,
                      rbl_C: b.weightLossNotResolve ? 1 : b.resolutionDateUnknown ? 3 : b.weightLossResolveUnknown ? 99 : !isStringEmpty(b.weightLossResolutionDate) ? 2 : null,
                      date_C: b.weightLossResolutionDate,
                      rbl_D: b.weightNotStabilize ? 1 : b.stabilizationDateUnknown ? 3 : b.weightStabilizeUnknown ? 99 : !isStringEmpty(b.weightStabilizationDate) ? 2 : null,
                      date_D: b.weightStabilizationDate
                    }))
                  }
                  if (dbRow.aE_DysgeusiumList && dbRow.aE_DysgeusiumList.length > 0) {
                    q59_dysgeusia_list = dbRow.aE_DysgeusiumList.map(b => ({
                      ...q59_dysgeusia_row,
                      dysgeusiaId: b.aE_DysgeusiaId,
                      rbl_B: b.dysgeusiaNotexperienced ? 1 : b.dysgeusiaOnsetDateUnk ? 99 : !isStringEmpty(b.dysgeusiaOnsetOcurredDate) ? 2 : null,
                      date_B: b.dysgeusiaOnsetOcurredDate,

                      chk_CC_1: b.interventionDexamethasoneMouthwash,
                      chk_CC_1_P: b.dexamethasoneMouthwashProphylactic,
                      chk_CC_1_T: b.dexamethasoneMouthwashTherapeutic,
                      chk_CC_2: b.interventionNystatinMouthwash,
                      chk_CC_2_P: b.nystatinMouthwashProphylactic,
                      chk_CC_2_T: b.nystatinMouthwashTherapeutic,
                      chk_CC_3: b.interventionSalineMouthwash,
                      chk_CC_3_P: b.salineMouthwashProphylactic,
                      chk_CC_3_T: b.salineMouthwashTherapeutic,
                      chk_CC_4: b.interventionBioteneMouthwash,
                      chk_CC_4_P: b.bioteneMouthwashProphylactic,
                      chk_CC_4_T: b.bioteneMouthwashTherapeutic,
                      chk_CC_5: b.interventionZincMouthwash,
                      chk_CC_5_P: b.zincMouthwashProphylactic,
                      chk_CC_5_T: b.zincMouthwashTherapeutic,
                      chk_CC_6: b.interventionVitaminbMouthwash,
                      chk_CC_6_P: b.vitaminbMouthwashProphylactic,
                      chk_CC_6_T: b.vitaminbMouthwashTherapeutic,
                      chk_CC_7: b.interventionOtherMouthwash,
                      txt_CC_oth: b.interventionOthertextMouthwash,
                      chk_CC_7_P: b.otherMouthwashProphylactic,
                      chk_CC_7_T: b.otherMouthwashTherapeutic,
                      rb_CC: b.interventionUnknown,

                      rbl_C: b.noImprovement ? 1 : b.improvementDateUnk ? 3 : b.improvementUnk ? 99 : !isStringEmpty(b.improvementDate) ? 2 : null,
                      date_C: b.improvementDate,
                      rbl_D: b.dysgeusiaNotResolved ? 1 : b.resolutionOccuredDateUnk ? 3 : b.dysgeusiaResolvedUnk ? 99 : !isStringEmpty(b.resolutionOccuredDate) ? 2 : null,
                      date_D: b.resolutionOccuredDate
                    }))
                  }
                  if (dbRow.aE_NaildisorderList && dbRow.aE_NaildisorderList.length > 0) {
                    q60_nail_list = dbRow.aE_NaildisorderList.map(b => ({
                      ...q60_nail_row,
                      nailDisorderId: b.aE_NailDisorderId,
                      rbl_B: b.naildisorderNotexperienced ? 1 : b.naildisorderOnsetDateUnk ? 99 : !isStringEmpty(b.naildisorderOnsetOcurredDate) ? 2 : null,
                      date_B: b.naildisorderOnsetOcurredDate,
                      rbl_C: b.noImprovement ? 1 : b.improvementDateUnk ? 3 : b.improvementUnk ? 99 : !isStringEmpty(b.improvementDate) ? 2 : null,
                      date_C: b.improvementDate,

                      rbl_D: b.naildisorderNotResolved ? 1 : b.naildisorderOccuredDateUnk ? 3 : b.naildisorderResolvedUnk ? 99 : !isStringEmpty(b.naildisorderOccuredDate) ? 2 : null,
                      date_D: b.naildisorderOccuredDate
                    }))
                  }
                  if (dbRow.aE_SkinDisorderList && dbRow.aE_SkinDisorderList.length > 0) {
                    q61_skin_list = dbRow.aE_SkinDisorderList.map(b => ({
                      ...q61_skin_row,
                      skinDisorderId: b.aE_SkinDisorderId,
                      rbl_B: b.skindisorderNotexperienced ? 1 : b.skindisorderOnsetDateUnk ? 99 : !isStringEmpty(b.skindisorderOnsetOcurredDate) ? 2 : null,
                      date_B: b.skindisorderOnsetOcurredDate,
                      rbl_C: b.noImprovement ? 1 : b.improvementDateUnk ? 3 : b.improvementUnk ? 99 : !isStringEmpty(b.improvementDate) ? 2 : null,
                      date_C: b.improvementDate,

                      rbl_D: b.skindisorderNotResolved ? 1 : b.skindisorderOccuredDateUnk ? 3 : b.skindisorderResolvedUnk ? 99 : !isStringEmpty(b.skindisorderOccuredDate) ? 2 : null,
                      date_D: b.skindisorderOccuredDate
                    }))
                  }
                  if (dbRow.aE_SecondaryPrimaryMalignancyList && dbRow.aE_SecondaryPrimaryMalignancyList.length > 0) {
                    q62_spm_list = dbRow.aE_SecondaryPrimaryMalignancyList.map(b => ({
                      ...q62_spm_row,
                      spmId: b.aE_SecondaryPrimaryMalignancyId,
                      rbl_B: b.secondMalignancyNotReceived ? 1 : b.secondMalignancyOccuredDateUnk ? 99 : !isStringEmpty(b.secondMalignancyOccuredDate) ? 2 : null,
                      date_B: b.secondMalignancyOccuredDate
                    }))
                  }
                  if (dbRow.aE_NeurotoxicityList && dbRow.aE_NeurotoxicityList.length > 0) {
                    q63_neurotoxicity_list = dbRow.aE_NeurotoxicityList.map(b => ({
                      ...q63_neurotoxicity_row,
                      neurotoxicityId: b.aE_NeurotoxicityId,
                      rbl_B: b.neurotoxicityNotExperienced ? 1 : b.neurotoxicityOnsetOccuredDateUnk ? 99 : !isStringEmpty(b.neurotoxicityOnsetOccuredDate) ? 2 : null,
                      date_B: b.neurotoxicityOnsetOccuredDate,
                      rbl_C: b.neurotoxicityNotResolved ? 1 : b.resolutionOccuredDateUnk ? 3 : b.neurotoxicityResolvedUnk ? 99 : !isStringEmpty(b.resolutionOccuredDate) ? 2 : null,
                      date_C: b.resolutionOccuredDate,
                      rbl_D: b.neurotoxicitySubtype,
                      rbl_E: b.severity,
                      chk_F_1: b.managementSettingOutpatient,
                      chk_F_2: b.managementSettingHospital,
                      rb_F: b.managementSettingUnk,
                      chk_G_1: b.interventionsCorticosteroids,
                      chk_G_2: b.interventionsAnakinra,
                      chk_G_3: b.interventionsTocilizumab,
                      chk_G_4: b.interventionsSiltuximab,
                      chk_G_5: b.interventionsOth,
                      rb_G: b.interventionsUnk,
                    }))
                  }

                  return {
                    ...AERow,
                    cohortTreatmentId,
                    cohortTreatmentName,
                    superScriptText,
                    cohortIndexDate,
                    aeEndDate,
                    q53_crs_list,
                    q54_icans_list,
                    q55_neutropenia_list,
                    q56_cytopenia_list,
                    q57_infection_list,
                    q58_weightLoss_list,
                    q59_dysgeusia_list,
                    q60_nail_list,
                    q61_skin_list,
                    q62_spm_list,
                    q63_neurotoxicity_list,

                  };
                } else {
                  return {
                    ...AERow,
                    cohortTreatmentId,
                    cohortTreatmentName,
                    superScriptText,
                    cohortIndexDate,
                    aeEndDate,

                    q53_crs_list,
                    q54_icans_list,
                    q55_neutropenia_list,
                    q56_cytopenia_list,
                    q57_infection_list,
                    q58_weightLoss_list,
                    q59_dysgeusia_list,
                    q60_nail_list,
                    q61_skin_list,
                    q62_spm_list,
                    q63_neurotoxicity_list,
                  }
                }
              })
              setAEDetails(list);
            }
          }

        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }
  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);
      let isValid = validate ? Validate() : true;

      if (isValid) {
        setShowTopErrMsg(false)

        let payload = {
          AdverseEventsData: [],
          PatientId: pid,
          UserId: GetLoggedInUserID(),
          IsValidated: validate
        }
        if (aeDetails && aeDetails.length > 0) {
          payload.AdverseEventsData = aeDetails.map(a => {

            let AE_CRSList = [];
            let AE_IcansList = [];
            let AE_NeutropeniumList = [];
            let AE_CytopeniumList = [];
            let AE_InfectionList = [];
            let AE_WeightLossList = [];
            let AE_DysgeusiumList = [];
            let AE_NaildisorderList = [];
            let AE_SkinDisorderList = [];
            let AE_SecondaryPrimaryMalignancyList = [];
            let AE_NeurotoxicityList = [];

            //ADD CONDITIONALS ALSO incase Q is not shown for certain cohorts not reuired
            if (a.q53_crs_list && a.q53_crs_list.length > 0) {
              AE_CRSList = a.q53_crs_list.map(b => ({
                AE_CRSId: b.crsId || 0,
                CrsNotExperienced: b.rbl_B === 1,
                CsrDate: b.date_B,
                CsrDateUnk: b.rbl_B === 99,
                Severity: b.rbl_C
              }))
            }
            if (a.q54_icans_list && a.q54_icans_list.length > 0) {
              AE_IcansList = a.q54_icans_list.map(b => ({
                AE_ICANSId: b.icansId || 0,
                IcansNotExperienced: b.rbl_B === 1,
                IcansOccuredDate: b.date_B,
                IcansOccuredDateUnk: b.rbl_B === 99
              }))
            }
            if (a.q55_neutropenia_list && a.q55_neutropenia_list.length > 0) {
              AE_NeutropeniumList = a.q55_neutropenia_list.map(b => ({
                AE_NeutropeniaId: b.neutropeniaId || 0,
                NeutropeniaNotExperienced: b.rbl_B === 1,
                NeutropeniaOnsetDate: b.date_B,
                NeutropeniaOnsetDateUnk: b.rbl_B === 99,
                NeutropeniaNotResolved: b.rbl_C === 1,
                NeutropeniaResolutionDate: b.date_C,
                NeutropeniaResolutionDateUnk: b.rbl_C === 3,
                NeutropeniaResolvedUnk: b.rbl_C === 99,
              }))
            }
            if (a.q56_cytopenia_list && a.q56_cytopenia_list.length > 0) {
              AE_CytopeniumList = a.q56_cytopenia_list.map(b => ({
                AE_CytopeniaId: b.cytopeniaId || 0,
                CytopeniaNotExperienced: b.rbl_B === 1,
                CytopeniaOnsetDate: b.date_B,
                CytopeniaOnsetDateUnk: b.rbl_B === 99,
                CytopeniaNotResolved: b.rbl_C === 1,
                CytopeniaResolutionDate: b.date_C,
                CytopeniaResolutionDateUnk: b.rbl_C === 3,
                CytopeniaResolvedUnk: b.rbl_C === 99,
              }))
            }
            if (a.q57_infection_list && a.q57_infection_list.length > 0) {
              AE_InfectionList = a.q57_infection_list.map(b => ({
                AE_InfectionId: b.infectionId || 0,

                InfectionNotExperienced: b.rbl_B === 1,
                InfectionOccuredDate: b.date_B,
                InfectionOccuredDateUnk: b.rbl_B === 99,

                InfectionTypeBacterial: b.chk_C_1,
                InfectionTypeViral: b.chk_C_2,
                InfectionTypeFungal: b.chk_C_3,
                InfectionTypeUnk: b.rb_C,

                InfectionOrganismAdenovirus: b.chk_D_1,
                InfectionOrganismCandida: b.chk_D_2,
                InfectionOrganismCovid19: b.chk_D_3,
                InfectionOrganismCytomegalovirus: b.chk_D_4,
                InfectionOrganismEscherichia: b.chk_D_5,
                InfectionOrganismHelicobacter: b.chk_D_6,
                InfectionOrganismHepatitis: b.chk_D_7,
                InfectionOrganismHerpes: b.chk_D_8,
                InfectionOrganismInfluenza: b.chk_D_9,
                InfectionOrganismPjp: b.chk_D_10,
                InfectionOrganismProgressive: b.chk_D_11,
                InfectionOrganismRsv: b.chk_D_12,
                InfectionOrganismStreptococcus: b.chk_D_13,
                InfectionOrganismVaricella: b.chk_D_14,
                InfectionOrganismOth: b.chk_D_15,
                InfectionOrganismOthTxt: b.txt_D_oth,
                InfectionOrganismUnk: b.rb_D,

                Severity: b.rbl_E,

                LedErVisit: b.rbl_F
              }))
            }
            if (a.q58_weightLoss_list && a.q58_weightLoss_list.length > 0) {
              AE_WeightLossList = a.q58_weightLoss_list.map(b => ({
                AE_WeightLossId: b.weightLossId || 0,
                NoWeightLoss: b.rbl_B === 1,
                WeightLossOnsetDate: b.date_B,
                WeightLossOnsetDateUnk: b.rbl_B === 99,
                WeightLossNotResolve: b.rbl_C === 1,
                WeightLossResolutionDate: b.date_C,
                ResolutionDateUnknown: b.rbl_C === 3,
                WeightLossResolveUnknown: b.rbl_C === 99,
                WeightNotStabilize: b.rbl_D === 1,
                WeightStabilizationDate: b.date_D,
                StabilizationDateUnknown: b.rbl_D === 3,
                WeightStabilizeUnknown: b.rbl_D === 99,
              }))
            }
            if (a.q59_dysgeusia_list && a.q59_dysgeusia_list.length > 0) {
              AE_DysgeusiumList = a.q59_dysgeusia_list.map(b => ({
                AE_DysgeusiaId: b.dysgeusiaId || 0,
                DysgeusiaNotexperienced: b.rbl_B === 1,
                DysgeusiaOnsetOcurredDate: b.date_B,
                DysgeusiaOnsetDateUnk: b.rbl_B === 99,
                InterventionDexamethasoneMouthwash: b.chk_CC_1,
                DexamethasoneMouthwashProphylactic: b.chk_CC_1_P,
                DexamethasoneMouthwashTherapeutic: b.chk_CC_1_T,
                InterventionNystatinMouthwash: b.chk_CC_2,
                NystatinMouthwashProphylactic: b.chk_CC_2_P,
                NystatinMouthwashTherapeutic: b.chk_CC_2_T,
                InterventionSalineMouthwash: b.chk_CC_3,
                SalineMouthwashProphylactic: b.chk_CC_3_P,
                SalineMouthwashTherapeutic: b.chk_CC_3_T,
                InterventionBioteneMouthwash: b.chk_CC_4,
                BioteneMouthwashProphylactic: b.chk_CC_4_P,
                BioteneMouthwashTherapeutic: b.chk_CC_4_T,
                InterventionZincMouthwash: b.chk_CC_5,
                ZincMouthwashProphylactic: b.chk_CC_5_P,
                ZincMouthwashTherapeutic: b.chk_CC_5_T,
                InterventionVitaminbMouthwash: b.chk_CC_6,
                VitaminbMouthwashProphylactic: b.chk_CC_6_P,
                VitaminbMouthwashTherapeutic: b.chk_CC_6_T,
                InterventionOtherMouthwash: b.chk_CC_7,
                InterventionOthertextMouthwash: b.txt_CC_oth,
                OtherMouthwashProphylactic: b.chk_CC_7_P,
                OtherMouthwashTherapeutic: b.chk_CC_7_T,
                InterventionUnknown: b.rb_CC,
                NoImprovement: b.rbl_C === 1,
                ImprovementDate: b.date_C,
                ImprovementDateUnk: b.rbl_C === 3,
                ImprovementUnk: b.rbl_C === 99,
                DysgeusiaNotResolved: b.rbl_D === 1,
                ResolutionOccuredDate: b.date_D,
                ResolutionOccuredDateUnk: b.rbl_D === 3,
                DysgeusiaResolvedUnk: b.rbl_D === 99,
              }))
            }
            if (a.q60_nail_list && a.q60_nail_list.length > 0) {
              AE_NaildisorderList = a.q60_nail_list.map(b => ({
                AE_NailDisorderId: b.nailDisorderId || 0,
                NaildisorderNotexperienced: b.rbl_B === 1,
                NaildisorderOnsetOcurredDate: b.date_B,
                NaildisorderOnsetDateUnk: b.rbl_B === 99,
                NoImprovement: b.rbl_C === 1,
                ImprovementDate: b.date_C,
                ImprovementDateUnk: b.rbl_C === 3,
                ImprovementUnk: b.rbl_C === 99,
                NaildisorderNotResolved: b.rbl_D === 1,
                NaildisorderOccuredDate: b.date_D,
                NaildisorderOccuredDateUnk: b.rbl_D === 3,
                NaildisorderResolvedUnk: b.rbl_D === 99,
              }))
            }
            if (a.q61_skin_list && a.q61_skin_list.length > 0) {
              AE_SkinDisorderList = a.q61_skin_list.map(b => ({
                AE_SkinDisorderId: b.skinDisorderId || 0,
                SkindisorderNotexperienced: b.rbl_B === 1,
                SkindisorderOnsetOcurredDate: b.date_B,
                SkindisorderOnsetDateUnk: b.rbl_B === 99,
                NoImprovement: b.rbl_C === 1,
                ImprovementDate: b.date_C,
                ImprovementDateUnk: b.rbl_C === 3,
                ImprovementUnk: b.rbl_C === 99,
                SkindisorderNotResolved: b.rbl_D === 1,
                SkindisorderOccuredDate: b.date_D,
                SkindisorderOccuredDateUnk: b.rbl_D === 3,
                SkindisorderResolvedUnk: b.rbl_D === 99,
              }))
            }
            if (a.q62_spm_list && a.q62_spm_list.length > 0) {
              AE_SecondaryPrimaryMalignancyList = a.q62_spm_list.map(b => ({
                AE_SecondaryPrimaryMalignancyId: b.spmId || 0,
                SecondMalignancyNotReceived: b.rbl_B === 1,
                SecondMalignancyOccuredDate: b.date_B,
                SecondMalignancyOccuredDateUnk: b.rbl_B === 99
              }))
            }
            if (a.q63_neurotoxicity_list && a.q63_neurotoxicity_list.length > 0) {
              AE_NeurotoxicityList = a.q63_neurotoxicity_list.map(b => ({
                AE_NeurotoxicityId: b.neurotoxicityId || 0,
                NeurotoxicityNotExperienced: b.rbl_B === 1,
                NeurotoxicityOnsetOccuredDate: b.date_B,
                NeurotoxicityOnsetOccuredDateUnk: b.rbl_B === 99,
                NeurotoxicityNotResolved: b.rbl_C === 1,
                ResolutionOccuredDate: b.date_C,
                ResolutionOccuredDateUnk: b.rbl_C === 3,
                NeurotoxicityResolvedUnk: b.rbl_C === 99,
                NeurotoxicitySubtype: b.rbl_D,
                Severity: b.rbl_E,
                ManagementSettingOutpatient: b.chk_F_1,
                ManagementSettingHospital: b.chk_F_2,
                ManagementSettingUnk: b.rb_F,
                InterventionsCorticosteroids: b.chk_G_1,
                InterventionsAnakinra: b.chk_G_2,
                InterventionsTocilizumab: b.chk_G_3,
                InterventionsSiltuximab: b.chk_G_4,
                InterventionsOth: b.chk_G_5,
                InterventionsUnk: b.rb_G
              }))
            }
            return {
              CohortTreatmentId: a.cohortTreatmentId,
              AE_CRSList,
              AE_IcansList,
              AE_NeutropeniumList,
              AE_CytopeniumList,
              AE_InfectionList,
              AE_WeightLossList,
              AE_DysgeusiumList,
              AE_NaildisorderList,
              AE_SkinDisorderList,
              AE_SecondaryPrimaryMalignancyList,
              AE_NeurotoxicityList
            }
          })
        }
        if (pid > 0) {
          const response = await AdverseEventsService.SaveAdverseEventsData(payload);
          ToggleLoader(false);
          if (response.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            }
            else
              throw response.error;
          }
          else {
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            await LoadData();
          }
        }
        return true;
      }
      else {
        ToggleLoader(false);
        setShowTopErrMsg(true)
        return false;
      }

    } catch (error) {
      ToggleLoader(false);
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
    }
  }
  const Validate = () => {
    let isValid = true;

    //Check if onset date is before index date
    function IsOnsetBeforeIndex(onsetDt, indexDt) {
      if (!isStringEmpty(onsetDt) && !isStringEmpty(indexDt)) {
        return IsDateAfter(indexDt, onsetDt);
      }
      return false;
    }
    //Check if onset date is after AE end date
    function IsDateAfterFunction(onsetDt, aeEndDt) {
      if (!isStringEmpty(onsetDt) && !isStringEmpty(aeEndDt)) {
        return IsDateAfter(onsetDt, aeEndDt);
      }
      return false;
    }

    if (aeDetails && aeDetails.length > 0) {
      const updatedArr = aeDetails.map(p => {
        let innerArrQ53 = null;
        let innerArrQ54 = null;
        let innerArrQ55 = null;
        let innerArrQ56 = null;
        let innerArrQ57 = null;
        let innerArrQ58 = null;
        let innerArrQ59 = null;
        let innerArrQ60 = null;
        let innerArrQ61 = null;
        let innerArrQ62 = null;
        let innerArrQ63 = null;

        if (p.q53_crs_list && p.q53_crs_list.length > 0) {
          innerArrQ53 = p.q53_crs_list.map((c, cIndex) => {
            const B_empty_err = c.rbl_B == null;
            const B_date_empty_err = c.rbl_B === 2 && isStringEmpty(c.date_B);
            const B_date_before_err = IsOnsetBeforeIndex(c.date_B, p.cohortIndexDate);
            const B_date_after_err = IsDateAfterFunction(c.date_B, p.aeEndDate);
            let B_date_equal_prev_ep_err = false;
            if (cIndex > 0 && c.date_B) {
              // Filter out the current index and get only the previous rows that have a date
              const previousDates = p.q53_crs_list
                .slice(0, cIndex) // Exclude the current index by slicing up to it
                .filter(
                  (_, idx) =>
                    idx !== cIndex && p.q53_crs_list[idx].date_B
                ) // Ensure that previous indices have a date
                .map(crs => crs.date_B); // Map to get the dates

              // // Get the most recent previous date if any
              // if (previousDates.length > 0) {
              //   const mostRecentPrevDate = previousDates[previousDates.length - 1];

              //   // Check if the current date is less than or equal to the most recent previous date
              //   if (IsDateEqual(mostRecentPrevDate, c.date_B)) {
              //     B_date_equal_prev_ep_err = true;
              //   }
              // }
              // Check if the current date is equal to any of the previous dates
              if (previousDates.length > 0) {
                if (previousDates.includes(c.date_B)) {
                  B_date_equal_prev_ep_err = true;
                }
              }
            }

            const C_empty_err = c.rbl_B != null && c.rbl_B !== 1 && c.rbl_C == null;

            if (B_empty_err || B_date_empty_err || B_date_before_err || B_date_after_err || B_date_equal_prev_ep_err || C_empty_err) {
              isValid = false;
            }

            return {
              ...c,
              B_empty_err,
              B_date_empty_err,
              B_date_before_err,
              B_date_after_err,
              B_date_equal_prev_ep_err,
              C_empty_err
            };
          });
        }
        if (p.q54_icans_list && p.q54_icans_list.length > 0) {
          innerArrQ54 = p.q54_icans_list.map((c, cIndex) => {
            const B_empty_err = c.rbl_B == null;
            const B_date_empty_err = c.rbl_B === 2 && isStringEmpty(c.date_B);
            const B_date_before_err = IsOnsetBeforeIndex(c.date_B, p.cohortIndexDate);
            const B_date_after_err = IsDateAfterFunction(c.date_B, p.aeEndDate);
            let B_date_equal_prev_ep_err = false;
            if (cIndex > 0 && c.date_B) {
              const previousDates = p.q54_icans_list
                .slice(0, cIndex)
                .filter(
                  (_, idx) =>
                    idx !== cIndex && p.q54_icans_list[idx].date_B
                )
                .map(crs => crs.date_B);

              if (previousDates.length > 0) {
                if (previousDates.includes(c.date_B)) {
                  B_date_equal_prev_ep_err = true;
                }
              }
            }

            if (B_empty_err || B_date_empty_err || B_date_before_err || B_date_after_err || B_date_equal_prev_ep_err) {
              isValid = false;
            }

            return {
              ...c,
              B_empty_err,
              B_date_empty_err,
              B_date_before_err,
              B_date_after_err,
              B_date_equal_prev_ep_err
            };
          });
        }
        if (p.q55_neutropenia_list && p.q55_neutropenia_list.length > 0) {
          innerArrQ55 = p.q55_neutropenia_list.map((c, cIndex) => {
            const B_empty_err = c.rbl_B == null;
            const B_date_empty_err = c.rbl_B === 2 && isStringEmpty(c.date_B);
            const B_date_before_err = IsOnsetBeforeIndex(c.date_B, p.cohortIndexDate);
            const B_date_after_err = IsDateAfterFunction(c.date_B, p.aeEndDate);
            let B_date_equal_prev_ep_err = false;
            if (cIndex > 0 && c.date_B) {
              const previousDates = p.q55_neutropenia_list
                .slice(0, cIndex)
                .filter(
                  (_, idx) =>
                    idx !== cIndex && p.q55_neutropenia_list[idx].date_B
                )
                .map(crs => crs.date_B);

              if (previousDates.length > 0) {
                if (previousDates.includes(c.date_B)) {
                  B_date_equal_prev_ep_err = true;
                }
              }
            }

            let C_empty_err = false;
            let C_date_empty_err = false;
            let C_date_before_index_err = false;
            let C_date_before_onset_err = false;
            let C_date_after_err = false;

            if (c.rbl_B != null && c.rbl_B !== 1) {
              C_empty_err = c.rbl_C == null;
              C_date_empty_err = c.rbl_C === 2 && isStringEmpty(c.date_C);
              C_date_before_index_err = IsOnsetBeforeIndex(c.date_C, p.cohortIndexDate);
              C_date_before_onset_err = IsOnsetBeforeIndex(c.date_C, c.date_B);
              C_date_after_err = IsDateAfterFunction(c.date_C, p.aeEndDate);
            }
            if (B_empty_err || B_date_empty_err || B_date_before_err || B_date_after_err || B_date_equal_prev_ep_err ||
              C_empty_err || C_date_empty_err || C_date_before_index_err || C_date_before_onset_err || C_date_after_err) {
              isValid = false;
            }

            return {
              ...c,
              B_empty_err,
              B_date_empty_err,
              B_date_before_err,
              B_date_after_err,
              B_date_equal_prev_ep_err,
              C_empty_err,
              C_date_empty_err,
              C_date_before_index_err,
              C_date_before_onset_err,
              C_date_after_err
            };
          });
        }
        if (p.q56_cytopenia_list && p.q56_cytopenia_list.length > 0) {
          innerArrQ56 = p.q56_cytopenia_list.map((c, cIndex) => {
            const B_empty_err = c.rbl_B == null;
            const B_date_empty_err = c.rbl_B === 2 && isStringEmpty(c.date_B);
            const B_date_before_err = IsOnsetBeforeIndex(c.date_B, p.cohortIndexDate);
            const B_date_after_err = IsDateAfterFunction(c.date_B, p.aeEndDate);
            let B_date_equal_prev_ep_err = false;
            if (cIndex > 0 && c.date_B) {
              const previousDates = p.q56_cytopenia_list
                .slice(0, cIndex)
                .filter(
                  (_, idx) =>
                    idx !== cIndex && p.q56_cytopenia_list[idx].date_B
                )
                .map(crs => crs.date_B);

              if (previousDates.length > 0) {
                if (previousDates.includes(c.date_B)) {
                  B_date_equal_prev_ep_err = true;
                }
              }
            }

            let C_empty_err = false;
            let C_date_empty_err = false;
            let C_date_before_index_err = false;
            let C_date_before_onset_err = false;
            let C_date_after_err = false;

            if (c.rbl_B != null && c.rbl_B !== 1) {
              C_empty_err = c.rbl_C == null;
              C_date_empty_err = c.rbl_C === 2 && isStringEmpty(c.date_C);
              C_date_before_index_err = IsOnsetBeforeIndex(c.date_C, p.cohortIndexDate);
              C_date_before_onset_err = IsOnsetBeforeIndex(c.date_C, c.date_B);
              C_date_after_err = IsDateAfterFunction(c.date_C, p.aeEndDate);
            }
            if (B_empty_err || B_date_empty_err || B_date_before_err || B_date_after_err || B_date_equal_prev_ep_err ||
              C_empty_err || C_date_empty_err || C_date_before_index_err || C_date_before_onset_err || C_date_after_err) {
              isValid = false;
            }

            return {
              ...c,
              B_empty_err,
              B_date_empty_err,
              B_date_before_err,
              B_date_after_err,
              B_date_equal_prev_ep_err,
              C_empty_err,
              C_date_empty_err,
              C_date_before_index_err,
              C_date_before_onset_err,
              C_date_after_err
            };
          });
        }
        if (p.q57_infection_list && p.q57_infection_list.length > 0) {
          innerArrQ57 = p.q57_infection_list.map((c, cIndex) => {
            const B_empty_err = c.rbl_B == null;
            const B_date_empty_err = c.rbl_B === 2 && isStringEmpty(c.date_B);
            const B_date_before_err = IsOnsetBeforeIndex(c.date_B, p.cohortIndexDate);
            const B_date_after_err = IsDateAfterFunction(c.date_B, p.aeEndDate);
            let B_date_equal_prev_ep_err = false;
            if (cIndex > 0 && c.date_B) {
              const previousDates = p.q57_infection_list
                .slice(0, cIndex)
                .filter(
                  (_, idx) =>
                    idx !== cIndex && p.q57_infection_list[idx].date_B
                )
                .map(crs => crs.date_B);

              if (previousDates.length > 0) {
                if (previousDates.includes(c.date_B)) {
                  B_date_equal_prev_ep_err = true;
                }
              }
            }
            let C_empty_err = false;
            let D_empty_err = false;
            let D_txt_empty_err = false;
            let E_empty_err = false;
            let F_empty_err = false;

            if (c.rbl_B != null && c.rbl_B !== 1) {
              C_empty_err = !c.chk_C_1 && !c.chk_C_2 && !c.chk_C_3 && !c.rb_C;
              D_empty_err = !c.chk_D_1 && !c.chk_D_2 && !c.chk_D_3 && !c.chk_D_4 && !c.chk_D_5 &&
                !c.chk_D_6 && !c.chk_D_7 && !c.chk_D_8 && !c.chk_D_9 && !c.chk_D_10 &&
                !c.chk_D_11 && !c.chk_D_12 && !c.chk_D_13 && !c.chk_D_14 && !c.chk_D_15 &&
                !c.txt_D_oth && !c.rb_D;
              D_txt_empty_err = c.chk_D_15 && isStringEmpty(c.txt_D_oth);
              E_empty_err = c.rbl_E == null;
              F_empty_err = c.rbl_F == null;
            }

            if (B_empty_err || B_date_empty_err || B_date_before_err || B_date_after_err || B_date_equal_prev_ep_err || C_empty_err || D_empty_err || D_txt_empty_err || E_empty_err || F_empty_err) {
              isValid = false;
            }

            return {
              ...c,
              B_empty_err,
              B_date_empty_err,
              B_date_before_err,
              B_date_after_err,
              B_date_equal_prev_ep_err,
              C_empty_err,
              D_empty_err,
              D_txt_empty_err,
              E_empty_err,
              F_empty_err,
            };
          });
        }
        if (p.q58_weightLoss_list && p.q58_weightLoss_list.length > 0) {
          innerArrQ58 = p.q58_weightLoss_list.map((c, cIndex) => {
            const B_empty_err = c.rbl_B == null;
            const B_date_empty_err = c.rbl_B === 2 && isStringEmpty(c.date_B);
            const B_date_before_err = IsOnsetBeforeIndex(c.date_B, p.cohortIndexDate);
            const B_date_after_err = IsDateAfterFunction(c.date_B, p.aeEndDate);
            let B_date_equal_prev_ep_err = false;
            if (cIndex > 0 && c.date_B) {
              const previousDates = p.q58_weightLoss_list
                .slice(0, cIndex)
                .filter(
                  (_, idx) =>
                    idx !== cIndex && p.q58_weightLoss_list[idx].date_B
                )
                .map(crs => crs.date_B);

              if (previousDates.length > 0) {
                if (previousDates.includes(c.date_B)) {
                  B_date_equal_prev_ep_err = true;
                }
              }
            }

            let C_empty_err = false;
            let C_date_empty_err = false;
            let C_date_before_index_err = false;
            let C_date_before_onset_err = false;
            let C_date_after_err = false;
            let D_empty_err = false;
            let D_date_empty_err = false;
            let D_date_before_index_err = false;
            let D_date_before_onset_err = false;
            let D_date_before_res_err = false;
            let D_date_after_err = false;

            if (c.rbl_B != null && c.rbl_B !== 1) {
              C_empty_err = c.rbl_C == null;
              C_date_empty_err = c.rbl_C === 2 && isStringEmpty(c.date_C);
              C_date_before_index_err = IsOnsetBeforeIndex(c.date_C, p.cohortIndexDate);
              C_date_before_onset_err = IsOnsetBeforeIndex(c.date_C, c.date_B);
              C_date_after_err = IsDateAfterFunction(c.date_C, p.aeEndDate);

              D_empty_err = c.rbl_D == null;
              D_date_empty_err = c.rbl_D === 2 && isStringEmpty(c.date_D);
              D_date_before_index_err = IsOnsetBeforeIndex(c.date_D, p.cohortIndexDate);
              D_date_before_onset_err = IsOnsetBeforeIndex(c.date_D, c.date_B);
              D_date_before_res_err = IsOnsetBeforeIndex(c.date_D, c.date_C);
              D_date_after_err = IsDateAfterFunction(c.date_D, p.aeEndDate);
            }

            if (B_empty_err || B_date_empty_err || B_date_before_err || B_date_after_err || B_date_equal_prev_ep_err ||
              C_empty_err || C_date_empty_err || C_date_before_index_err || C_date_before_onset_err || C_date_after_err ||
              D_empty_err || D_date_empty_err || D_date_before_index_err || D_date_before_onset_err || D_date_before_res_err || D_date_after_err) {
              isValid = false;
            }

            return {
              ...c,
              B_empty_err,
              B_date_empty_err,
              B_date_before_err,
              B_date_after_err,
              B_date_equal_prev_ep_err,
              C_empty_err,
              C_date_empty_err,
              C_date_before_index_err,
              C_date_before_onset_err,
              C_date_after_err,
              D_empty_err,
              D_date_empty_err,
              D_date_before_index_err,
              D_date_before_onset_err,
              D_date_before_res_err,
              D_date_after_err,
            };
          });
        }
        if (p.q59_dysgeusia_list && p.q59_dysgeusia_list.length > 0) {
          innerArrQ59 = p.q59_dysgeusia_list.map((c, cIndex) => {
            const B_empty_err = c.rbl_B == null;
            const B_date_empty_err = c.rbl_B === 2 && isStringEmpty(c.date_B);
            const B_date_before_err = IsOnsetBeforeIndex(c.date_B, p.cohortIndexDate);
            const B_date_after_err = IsDateAfterFunction(c.date_B, p.aeEndDate);
            let B_date_equal_prev_ep_err = false;
            if (cIndex > 0 && c.date_B) {
              const previousDates = p.q59_dysgeusia_list
                .slice(0, cIndex)
                .filter(
                  (_, idx) =>
                    idx !== cIndex && p.q59_dysgeusia_list[idx].date_B
                )
                .map(crs => crs.date_B);

              if (previousDates.length > 0) {
                if (previousDates.includes(c.date_B)) {
                  B_date_equal_prev_ep_err = true;
                }
              }
            }

            let CC_1_empty_err = false;
            let CC_2_empty_err = false;
            let CC_3_empty_err = false;
            let CC_4_empty_err = false;
            let CC_5_empty_err = false;
            let CC_6_empty_err = false;
            let CC_7_empty_err = false;
            let CC_empty_err = false;
            let CC_txt_empty_err = false;

            let C_empty_err = false;
            let C_date_empty_err = false;
            let C_date_before_index_err = false;
            let C_date_before_onset_err = false;
            let C_date_after_err = false;

            let D_empty_err = false;
            let D_date_empty_err = false;
            let D_date_before_index_err = false;
            let D_date_before_onset_err = false;
            let D_date_before_impr_err = false;
            let D_date_after_err = false;

            if (c.rbl_B != null && c.rbl_B !== 1) {
              CC_1_empty_err = c.chk_CC_1 && !c.chk_CC_1_P && !c.chk_CC_1_T;
              CC_2_empty_err = c.chk_CC_2 && !c.chk_CC_2_P && !c.chk_CC_2_T;
              CC_3_empty_err = c.chk_CC_3 && !c.chk_CC_3_P && !c.chk_CC_3_T;
              CC_4_empty_err = c.chk_CC_4 && !c.chk_CC_4_P && !c.chk_CC_4_T;
              CC_5_empty_err = c.chk_CC_5 && !c.chk_CC_5_P && !c.chk_CC_5_T;
              CC_6_empty_err = c.chk_CC_6 && !c.chk_CC_6_P && !c.chk_CC_6_T;
              CC_7_empty_err = c.chk_CC_7 && !c.chk_CC_7_P && !c.chk_CC_7_T;
              CC_empty_err = !c.chk_CC_1 && !c.chk_CC_2 && !c.chk_CC_3 && !c.chk_CC_4 && !c.chk_CC_5 && !c.chk_CC_6 && !c.chk_CC_7 && !c.rb_CC;
              CC_txt_empty_err = c.chk_CC_7 && isStringEmpty(c.txt_CC_oth);

              C_empty_err = c.rbl_C == null;
              C_date_empty_err = c.rbl_C === 2 && isStringEmpty(c.date_C);
              C_date_before_index_err = IsOnsetBeforeIndex(c.date_C, p.cohortIndexDate);
              C_date_before_onset_err = IsOnsetBeforeIndex(c.date_C, c.date_B);
              C_date_after_err = IsDateAfterFunction(c.date_C, p.aeEndDate);

              D_empty_err = c.rbl_D == null;
              D_date_empty_err = c.rbl_D === 2 && isStringEmpty(c.date_D);
              D_date_before_index_err = IsOnsetBeforeIndex(c.date_D, p.cohortIndexDate);
              D_date_before_onset_err = IsOnsetBeforeIndex(c.date_D, c.date_B);
              D_date_before_impr_err = IsOnsetBeforeIndex(c.date_D, c.date_C);
              D_date_after_err = IsDateAfterFunction(c.date_D, p.aeEndDate);
            }
            if (B_empty_err || B_date_empty_err || B_date_before_err || B_date_after_err || B_date_equal_prev_ep_err ||
              CC_1_empty_err || CC_2_empty_err || CC_3_empty_err || CC_4_empty_err || CC_5_empty_err || CC_6_empty_err || CC_7_empty_err || CC_empty_err || CC_txt_empty_err ||
              C_empty_err || C_date_empty_err || C_date_before_index_err || C_date_before_onset_err || C_date_after_err ||
              D_empty_err || D_date_empty_err || D_date_before_index_err || D_date_before_onset_err || D_date_before_impr_err || D_date_after_err) {
              isValid = false;
            }

            return {
              ...c,
              B_empty_err,
              B_date_empty_err,
              B_date_before_err,
              B_date_after_err,
              B_date_equal_prev_ep_err,
              CC_1_empty_err,
              CC_2_empty_err,
              CC_3_empty_err,
              CC_4_empty_err,
              CC_5_empty_err,
              CC_6_empty_err,
              CC_7_empty_err,
              CC_empty_err,
              CC_txt_empty_err,
              C_empty_err,
              C_date_empty_err,
              C_date_before_index_err,
              C_date_before_onset_err,
              C_date_after_err,
              D_empty_err,
              D_date_empty_err,
              D_date_before_index_err,
              D_date_before_onset_err,
              D_date_before_impr_err,
              D_date_after_err
            };
          });
        }
        if (p.q60_nail_list && p.q60_nail_list.length > 0) {
          innerArrQ60 = p.q60_nail_list.map((c, cIndex) => {
            const B_empty_err = c.rbl_B == null;
            const B_date_empty_err = c.rbl_B === 2 && isStringEmpty(c.date_B);
            const B_date_before_err = IsOnsetBeforeIndex(c.date_B, p.cohortIndexDate);
            const B_date_after_err = IsDateAfterFunction(c.date_B, p.aeEndDate);
            let B_date_equal_prev_ep_err = false;
            if (cIndex > 0 && c.date_B) {
              const previousDates = p.q60_nail_list
                .slice(0, cIndex)
                .filter(
                  (_, idx) =>
                    idx !== cIndex && p.q60_nail_list[idx].date_B
                )
                .map(crs => crs.date_B);

              if (previousDates.length > 0) {
                if (previousDates.includes(c.date_B)) {
                  B_date_equal_prev_ep_err = true;
                }
              }
            }

            let C_empty_err = false;
            let C_date_empty_err = false;
            let C_date_before_index_err = false;
            let C_date_before_onset_err = false;
            let C_date_after_err = false;

            let D_empty_err = false;
            let D_date_empty_err = false;
            let D_date_before_index_err = false;
            let D_date_before_onset_err = false;
            let D_date_before_impr_err = false;
            let D_date_after_err = false;

            if (c.rbl_B != null && c.rbl_B !== 1) {
              C_empty_err = c.rbl_C == null;
              C_date_empty_err = c.rbl_C === 2 && isStringEmpty(c.date_C);
              C_date_before_index_err = IsOnsetBeforeIndex(c.date_C, p.cohortIndexDate);
              C_date_before_onset_err = IsOnsetBeforeIndex(c.date_C, c.date_B);
              C_date_after_err = IsDateAfterFunction(c.date_C, p.aeEndDate);

              D_empty_err = c.rbl_D == null;
              D_date_empty_err = c.rbl_D === 2 && isStringEmpty(c.date_D);
              D_date_before_index_err = IsOnsetBeforeIndex(c.date_D, p.cohortIndexDate);
              D_date_before_onset_err = IsOnsetBeforeIndex(c.date_D, c.date_B);
              D_date_before_impr_err = IsOnsetBeforeIndex(c.date_D, c.date_C);
              D_date_after_err = IsDateAfterFunction(c.date_D, p.aeEndDate);
            }
            if (B_empty_err || B_date_empty_err || B_date_before_err || B_date_after_err || B_date_equal_prev_ep_err ||
              C_empty_err || C_date_empty_err || C_date_before_index_err || C_date_before_onset_err || C_date_after_err ||
              D_empty_err || D_date_empty_err || D_date_before_index_err || D_date_before_onset_err || D_date_before_impr_err || D_date_after_err) {
              isValid = false;
            }

            return {
              ...c,
              B_empty_err,
              B_date_empty_err,
              B_date_before_err,
              B_date_after_err,
              B_date_equal_prev_ep_err,
              C_empty_err,
              C_date_empty_err,
              C_date_before_index_err,
              C_date_before_onset_err,
              C_date_after_err,
              D_empty_err,
              D_date_empty_err,
              D_date_before_index_err,
              D_date_before_onset_err,
              D_date_before_impr_err,
              D_date_after_err
            };
          });
        }
        if (p.q61_skin_list && p.q61_skin_list.length > 0) {
          innerArrQ61 = p.q61_skin_list.map((c, cIndex) => {
            const B_empty_err = c.rbl_B == null;
            const B_date_empty_err = c.rbl_B === 2 && isStringEmpty(c.date_B);
            const B_date_before_err = IsOnsetBeforeIndex(c.date_B, p.cohortIndexDate);
            const B_date_after_err = IsDateAfterFunction(c.date_B, p.aeEndDate);
            let B_date_equal_prev_ep_err = false;
            if (cIndex > 0 && c.date_B) {
              const previousDates = p.q61_skin_list
                .slice(0, cIndex)
                .filter(
                  (_, idx) =>
                    idx !== cIndex && p.q61_skin_list[idx].date_B
                )
                .map(crs => crs.date_B);

              if (previousDates.length > 0) {
                if (previousDates.includes(c.date_B)) {
                  B_date_equal_prev_ep_err = true;
                }
              }
            }

            let C_empty_err = false;
            let C_date_empty_err = false;
            let C_date_before_index_err = false;
            let C_date_before_onset_err = false;
            let C_date_after_err = false;

            let D_empty_err = false;
            let D_date_empty_err = false;
            let D_date_before_index_err = false;
            let D_date_before_onset_err = false;
            let D_date_before_impr_err = false;
            let D_date_after_err = false;

            if (c.rbl_B != null && c.rbl_B !== 1) {
              C_empty_err = c.rbl_C == null;
              C_date_empty_err = c.rbl_C === 2 && isStringEmpty(c.date_C);
              C_date_before_index_err = IsOnsetBeforeIndex(c.date_C, p.cohortIndexDate);
              C_date_before_onset_err = IsOnsetBeforeIndex(c.date_C, c.date_B);
              C_date_after_err = IsDateAfterFunction(c.date_C, p.aeEndDate);

              D_empty_err = c.rbl_D == null;
              D_date_empty_err = c.rbl_D === 2 && isStringEmpty(c.date_D);
              D_date_before_index_err = IsOnsetBeforeIndex(c.date_D, p.cohortIndexDate);
              D_date_before_onset_err = IsOnsetBeforeIndex(c.date_D, c.date_B);
              D_date_before_impr_err = IsOnsetBeforeIndex(c.date_D, c.date_C);
              D_date_after_err = IsDateAfterFunction(c.date_D, p.aeEndDate);
            }
            if (B_empty_err || B_date_empty_err || B_date_before_err || B_date_after_err || B_date_equal_prev_ep_err ||
              C_empty_err || C_date_empty_err || C_date_before_index_err || C_date_before_onset_err || C_date_after_err ||
              D_empty_err || D_date_empty_err || D_date_before_index_err || D_date_before_onset_err || D_date_before_impr_err || D_date_after_err) {
              isValid = false;
            }

            return {
              ...c,
              B_empty_err,
              B_date_empty_err,
              B_date_before_err,
              B_date_after_err,
              B_date_equal_prev_ep_err,
              C_empty_err,
              C_date_empty_err,
              C_date_before_index_err,
              C_date_before_onset_err,
              C_date_after_err,
              D_empty_err,
              D_date_empty_err,
              D_date_before_index_err,
              D_date_before_onset_err,
              D_date_before_impr_err,
              D_date_after_err
            };
          });
        }
        if (p.q62_spm_list && p.q62_spm_list.length > 0) {
          innerArrQ62 = p.q62_spm_list.map((c, cIndex) => {
            const B_empty_err = c.rbl_B == null;
            const B_date_empty_err = c.rbl_B === 2 && isStringEmpty(c.date_B);
            const B_date_before_err = IsOnsetBeforeIndex(c.date_B, p.cohortIndexDate);
            const B_date_after_err = IsDateAfterFunction(c.date_B, p.aeEndDate);
            let B_date_equal_prev_ep_err = false;
            if (cIndex > 0 && c.date_B) {
              const previousDates = p.q62_spm_list
                .slice(0, cIndex)
                .filter(
                  (_, idx) =>
                    idx !== cIndex && p.q62_spm_list[idx].date_B
                )
                .map(crs => crs.date_B);

              if (previousDates.length > 0) {
                if (previousDates.includes(c.date_B)) {
                  B_date_equal_prev_ep_err = true;
                }
              }
            }

            if (B_empty_err || B_date_empty_err || B_date_before_err || B_date_after_err || B_date_equal_prev_ep_err) {
              isValid = false;
            }

            return {
              ...c,
              B_empty_err,
              B_date_empty_err,
              B_date_before_err,
              B_date_after_err,
              B_date_equal_prev_ep_err
            };
          });
        }
        if (p.q63_neurotoxicity_list && p.q63_neurotoxicity_list.length > 0) {
          innerArrQ63 = p.q63_neurotoxicity_list.map((c, cIndex) => {
            const B_empty_err = c.rbl_B == null;
            const B_date_empty_err = c.rbl_B === 2 && isStringEmpty(c.date_B);
            const B_date_before_err = IsOnsetBeforeIndex(c.date_B, p.cohortIndexDate);
            const B_date_after_err = IsDateAfterFunction(c.date_B, p.aeEndDate);
            let B_date_equal_prev_ep_err = false;
            if (cIndex > 0 && c.date_B) {
              const previousDates = p.q63_neurotoxicity_list
                .slice(0, cIndex)
                .filter(
                  (_, idx) =>
                    idx !== cIndex && p.q63_neurotoxicity_list[idx].date_B
                )
                .map(crs => crs.date_B);

              if (previousDates.length > 0) {
                if (previousDates.includes(c.date_B)) {
                  B_date_equal_prev_ep_err = true;
                }
              }
            }

            let C_empty_err = false;
            let C_date_empty_err = false;
            let C_date_before_index_err = false;
            let C_date_before_onset_err = false;
            let C_date_after_err = false;

            let D_empty_err = false;
            let E_empty_err = false;
            let F_empty_err = false;
            let G_empty_err = false;

            if (c.rbl_B != null && c.rbl_B !== 1) {
              C_empty_err = c.rbl_C == null;
              C_date_empty_err = c.rbl_C === 2 && isStringEmpty(c.date_C);
              C_date_before_index_err = IsOnsetBeforeIndex(c.date_C, p.cohortIndexDate);
              C_date_before_onset_err = IsOnsetBeforeIndex(c.date_C, c.date_B);
              C_date_after_err = IsDateAfterFunction(c.date_C, p.aeEndDate);

              D_empty_err = c.rbl_D == null;
              E_empty_err = c.rbl_E == null;
              F_empty_err = !c.chk_F_1 && !c.chk_F_2 && !c.rb_F;
              G_empty_err = !c.chk_G_1 && !c.chk_G_2 && !c.chk_G_3 && !c.chk_G_4 && !c.chk_G_5 && !c.rb_G;
            }
            if (B_empty_err || B_date_empty_err || B_date_before_err || B_date_after_err || B_date_equal_prev_ep_err ||
              C_empty_err || C_date_empty_err || C_date_before_index_err || C_date_before_onset_err || C_date_after_err ||
              D_empty_err || E_empty_err || F_empty_err || G_empty_err) {
              isValid = false;
            }

            return {
              ...c,
              B_empty_err,
              B_date_empty_err,
              B_date_before_err,
              B_date_after_err,
              B_date_equal_prev_ep_err,
              C_empty_err,
              C_date_empty_err,
              C_date_before_index_err,
              C_date_before_onset_err,
              C_date_after_err,
              D_empty_err,
              E_empty_err,
              F_empty_err,
              G_empty_err,
            };
          });
        }

        return {
          ...p,
          q53_crs_list: innerArrQ53,
          q54_icans_list: innerArrQ54,

          q55_neutropenia_list: innerArrQ55,
          q56_cytopenia_list: innerArrQ56,
          q57_infection_list: innerArrQ57,
          q58_weightLoss_list: innerArrQ58,
          q59_dysgeusia_list: innerArrQ59,
          q60_nail_list: innerArrQ60,
          q61_skin_list: innerArrQ61,
          q62_spm_list: innerArrQ62,
          q63_neurotoxicity_list: innerArrQ63,

        };
      });

      setAEDetails(updatedArr);
    }

    return isValid;
  };
  const sidebar = document.getElementById("mySidebar");
  const main = document.getElementById("main");

  const toggleNav = () => {



    if (isSideMenuActive === true) {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
      setIsSideMenuActive(false)
    } else if (isSideMenuActive === false) {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
      setIsSideMenuActive(true)
    }
  }

  useEffect(() => {
    const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");


    if (isSideMenuActive === true) {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
    } else {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
    }
  });
  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div >
          <SideBarMenu activePageId={SideMenuItems.AdverseEvents} />
          <>
            <button className={isSideMenuActive ? "openbtn" : "openbtn active"}
              // onclick="toggleNav()" 
              onClick={() => toggleNav()}
            >
              <i
                id="arrowNavigation"
                class="fa fa-arrow-left"
                aria-hidden="true"
              ></i>
            </button>
          </>
          <div className="col-pad-left" id="main">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>ADVERSE EVENTS (AEs)</h4>
              </div>
              {showTopErrMsg && <div className="clean-error ">
                <ErrorField show={showTopErrMsg} message={CommonError_Msgs.Hard_Empty} />
              </div>
              }
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div className="question-answer">
                    <div style={{ paddingBottom: "10px" }}> {/* style={PageDesignConstants.PageDescriptionStyle} */}
                      As you review and abstract the patient’s chart, please
                      remember the requirements on AE reporting according to
                      the Study Protocol, Section 5. See{" "}
                      <span
                        data-tooltip-id="encounterTip"
                        className="modal-popup"
                        onClick={() =>
                          setShowHideHyperlinkModalAEReporting(true)
                        }
                      >
                        AE Reporting
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        {showHideHyperlinkModalAEReporting && (
                          <InfoPopUp
                            description={hyperlinkTextAEReporting}
                            OkClick={() =>
                              setShowHideHyperlinkModalAEReporting(false)
                            }
                          />
                        )}{" "}
                      </span>
                      for more information.
                    </div>
                    {
                      aeDetails && aeDetails.length > 0 && (
                        aeDetails.map((row, idx) => (
                          <div className="dependent-section" key={idx}>
                            <div className="cohort-header">
                              <span  >{/* style={{ color: PageDesignConstants.fontColor }} */}
                                {row.cohortTreatmentName}
                                {!isStringEmpty(row.superScriptText) && (
                                  <>
                                    <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                    {row.superScriptText.slice(2)}
                                  </>
                                )}
                              </span>
                            </div>
                            <div className="question-bot-sapce">
                              <span>
                                Provide information for each adverse event experienced by the patient during treatment with{" "}
                                <span style={{ color: "steelblue", fontWeight: "bolder" }}>{/* style={{ color: PageDesignConstants.fontColor }} */}
                                  {row.cohortTreatmentName}
                                  {!isStringEmpty(row.superScriptText) && (
                                    <>
                                      <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                      {row.superScriptText.slice(2)}
                                    </>
                                  )}
                                </span>{" "}
                                from{" "}
                                <span style={{ color: "steelblue", fontWeight: "bolder" }}>
                                  {row.cohortIndexDate}
                                </span>
                                {" "}to{" "}
                                <span style={{ color: "steelblue", fontWeight: "bolder" }}>
                                  {row.aeEndDate}
                                </span>
                                .
                                <b>
                                  {" "}<u>If the patient experienced &gt;1 episode of the same type of adverse event, please add more rows to enter the data for each episode.
                                  </u></b>
                              </span>
                            </div>

                            {/* Q53. */}
                            {row.q53_crs_list && row.q53_crs_list.length > 0 && (<>
                              {/* {row.q53_crs_list && row.q53_crs_list.length > 0 && */}
                              <div className="question question-weight">
                                <span>Q53.</span>
                                <span className="quest-text-pad">
                                  Cytokine release syndrome (CRS)
                                </span>
                              </div>
                              {/* } */}
                              <div> {/*className="question-bot-sapce" */}
                                <div className="dependent-section">
                                  {/* row.q53_crs_list && row.q53_crs_list.length > 0 && ( */}
                                  {
                                    row.q53_crs_list.map((childRow, childIdx) => (
                                      <div className="loop-section-ul" key={childIdx}>
                                        <div className="loop-head">
                                          <span className="question-weight">{GetOrdinal(childIdx + 1)} Episode</span>
                                          {childIdx != 0 && <div className="delete-btn-div">
                                            <img
                                              width="16px"
                                              src="../Assets/images/Icon-material-delete.png"
                                              alt=""
                                              onClick={() => DeleteQ53Row(idx, childIdx)}
                                            />
                                          </div>}
                                        </div>

                                        <div className="">{/* question-bot-sapce */}
                                          <div className="sub-question">
                                            <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                                              <thead>
                                                <tr>
                                                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Treatment</th>
                                                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of onset</th>
                                                  <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Severity</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    <span>  {/* className="quest-text-pad" */}
                                                      {row.cohortTreatmentName}
                                                      {!isStringEmpty(row.superScriptText) && (
                                                        <>
                                                          <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                                          {row.superScriptText.slice(2)}
                                                        </>
                                                      )}
                                                    </span>
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    <div className="">{/* double-dig-answer */}
                                                      <div>
                                                        <label className="long-text-option">
                                                          <input
                                                            type="radio"
                                                            id={childIdx}
                                                            name={"q53_rbl_B" + childIdx + "_" + idx}
                                                            value={1}
                                                            checked={childRow.rbl_B === 1}
                                                            onChange={HandleRepeaterFieldChange}
                                                          />
                                                          <span className="radio-text-padding">The patient did not experience CRS</span>
                                                        </label>
                                                      </div>
                                                      <div className="long-text-option">
                                                        <label className="long-text-option">
                                                          <input
                                                            type="radio"
                                                            id={childIdx}
                                                            name={"q53_rbl_B" + childIdx + "_" + idx}
                                                            value={2}
                                                            checked={childRow.rbl_B === 2}
                                                            onChange={HandleRepeaterFieldChange}
                                                          />
                                                          <span className="radio-text-padding">
                                                            CRS occurred on:{" "}
                                                          </span>
                                                        </label>
                                                        <span  >
                                                          <DateControl
                                                            ctrlId={"q53_date_B" + childIdx + "_" + idx}
                                                            HandleDateChange={HandleRepeaterDateChange}
                                                            optionId={childIdx}
                                                            date={childRow.date_B}
                                                          />
                                                        </span>
                                                      </div>
                                                      <div>
                                                        <label className="long-text-option">
                                                          <input
                                                            type="radio"
                                                            id={childIdx}
                                                            name={"q53_rbl_B" + childIdx + "_" + idx}
                                                            value={99}
                                                            checked={childRow.rbl_B === 99}
                                                            onChange={HandleRepeaterFieldChange}
                                                          />
                                                          <span className="radio-text-padding">CRS occurred but date of onset unknown</span>
                                                        </label>
                                                      </div>
                                                    </div>
                                                    <ErrorField show={childRow.B_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                    <ErrorField show={childRow.B_date_empty_err} message={AdverseEvents_Msgs.OnsetDtEmpty} style={{ paddingLeft: '0px' }} />
                                                    <ErrorField show={childRow.B_date_before_err} message={AdverseEvents_Msgs.OnsetDtBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                    <ErrorField show={childRow.B_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                    <ErrorField show={childRow.B_date_equal_prev_ep_err} message={AdverseEvents_Msgs.DtSameAsPrevEpisodes} style={{ paddingLeft: "0px" }} />
                                                  </td>
                                                  {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                    (<td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >

                                                      <>
                                                        <div className="">{/* double-dig-answer */}
                                                          <div>
                                                            <label className="long-text-option">
                                                              <input
                                                                type="radio"
                                                                id={childIdx}
                                                                name={"q53_rbl_C" + childIdx + "_" + idx}
                                                                value={1}
                                                                checked={childRow.rbl_C === 1}
                                                                onChange={HandleRepeaterFieldChange}
                                                              />
                                                              <span className="radio-text-padding">Grade 1</span>
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <label className="long-text-option">
                                                              <input
                                                                type="radio"
                                                                id={childIdx}
                                                                name={"q53_rbl_C" + childIdx + "_" + idx}
                                                                value={2}
                                                                checked={childRow.rbl_C === 2}
                                                                onChange={HandleRepeaterFieldChange}
                                                              />
                                                              <span className="radio-text-padding">Grade 2</span>
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <label className="long-text-option">
                                                              <input
                                                                type="radio"
                                                                id={childIdx}
                                                                name={"q53_rbl_C" + childIdx + "_" + idx}
                                                                value={3}
                                                                checked={childRow.rbl_C === 3}
                                                                onChange={HandleRepeaterFieldChange}
                                                              />
                                                              <span className="radio-text-padding">Grade 3</span>
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <label className="long-text-option">
                                                              <input
                                                                type="radio"
                                                                id={childIdx}
                                                                name={"q53_rbl_C" + childIdx + "_" + idx}
                                                                value={4}
                                                                checked={childRow.rbl_C === 4}
                                                                onChange={HandleRepeaterFieldChange}
                                                              />
                                                              <span className="radio-text-padding">Grade 4</span>
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <label className="long-text-option">
                                                              <input
                                                                type="radio"
                                                                id={childIdx}
                                                                name={"q53_rbl_C" + childIdx + "_" + idx}
                                                                value={5}
                                                                checked={childRow.rbl_C === 5}
                                                                onChange={HandleRepeaterFieldChange}
                                                              />
                                                              <span className="radio-text-padding">Grade 5</span>
                                                            </label>
                                                          </div>
                                                          <div>
                                                            <label className="long-text-option">
                                                              <input
                                                                type="radio"
                                                                id={childIdx}
                                                                name={"q53_rbl_C" + childIdx + "_" + idx}
                                                                value={99}
                                                                checked={childRow.rbl_C === 99}
                                                                onChange={HandleRepeaterFieldChange}
                                                              />
                                                              <span className="radio-text-padding">Unknown</span>
                                                            </label>
                                                          </div>
                                                        </div>
                                                        <ErrorField show={childRow.C_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                      </>

                                                    </td>) : (<td
                                                      style={{
                                                        border: "1px solid black",
                                                        // alignContent: "flex-start",
                                                        textAlign: "center",
                                                        padding: "8px",
                                                        //textAlign: 'start'
                                                      }}
                                                    >
                                                      <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                    </td>)
                                                  }
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>

                                      </div>
                                    ))}
                                  {/* )} */}

                                  {/* {row.q53_crs_list && row.q53_crs_list.length < 500 && row.q53_crs_list[row.q53_crs_list.length - 1].rbl_B != null && row.q53_crs_list[row.q53_crs_list.length - 1].rbl_B !== 1 && */}
                                  {row.q53_crs_list.length < 500 && row.q53_crs_list[row.q53_crs_list.length - 1].rbl_B != null && row.q53_crs_list[row.q53_crs_list.length - 1].rbl_B !== 1 &&
                                    < div className="sub-question-bot-sapce-ul mt-2">
                                      <div className="answer-list-text" onClick={() => AddQ53Row(idx)}>
                                        <a>
                                          <img width="18px" src="../Assets/images/plus.png" alt="" />
                                        </a>
                                        <a className="radio-text-padding">
                                          Click here to add another episode
                                        </a>
                                      </div>
                                    </div>
                                  }
                                </div>
                              </div>
                            </>)}
                            {/* Q54. */}
                            {row.q54_icans_list && row.q54_icans_list.length > 0 && (
                              <>
                                <div className="question question-weight">
                                  <span>Q54.</span>
                                  <span className="quest-text-pad">
                                    Immune effector cell-associated neurotoxicity syndrome (ICANS)
                                  </span>
                                </div>
                                <div> {/*className="question-bot-sapce" */}
                                  <div className="dependent-section">
                                    {
                                      row.q54_icans_list.map((childRow, childIdx) => (
                                        <div className="loop-section-ul" key={childIdx}>
                                          <div className="loop-head">
                                            <span className="question-weight">{GetOrdinal(childIdx + 1)} Episode</span>
                                            {childIdx != 0 && <div className="delete-btn-div">
                                              <img
                                                width="16px"
                                                src="../Assets/images/Icon-material-delete.png"
                                                alt=""
                                                onClick={() => DeleteQ54Row(idx, childIdx)}
                                              />
                                            </div>}
                                          </div>

                                          <div className="">{/* question-bot-sapce */}
                                            <div className="sub-question">
                                              <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Treatment</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of onset</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <span>  {/* className="quest-text-pad" */}
                                                        {row.cohortTreatmentName}
                                                        {!isStringEmpty(row.superScriptText) && (
                                                          <>
                                                            <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                                            {row.superScriptText.slice(2)}
                                                          </>
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div className="">{/* double-dig-answer */}
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q54_rbl_B" + childIdx + "_" + idx}
                                                              value={1}
                                                              checked={childRow.rbl_B === 1}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">The patient did not experience ICANS</span>
                                                          </label>
                                                        </div>
                                                        <div className="long-text-option">
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q54_rbl_B" + childIdx + "_" + idx}
                                                              value={2}
                                                              checked={childRow.rbl_B === 2}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                              ICANS occurred on:{" "}
                                                            </span>
                                                          </label>
                                                          <span  >
                                                            <DateControl
                                                              ctrlId={"q54_date_B" + childIdx + "_" + idx}
                                                              HandleDateChange={HandleRepeaterDateChange}
                                                              optionId={childIdx}
                                                              date={childRow.date_B}
                                                            />
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q54_rbl_B" + childIdx + "_" + idx}
                                                              value={99}
                                                              checked={childRow.rbl_B === 99}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">ICANS occurred but date of onset unknown</span>
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <ErrorField show={childRow.B_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_empty_err} message={AdverseEvents_Msgs.OnsetDtEmpty} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_before_err} message={AdverseEvents_Msgs.OnsetDtBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_equal_prev_ep_err} message={AdverseEvents_Msgs.DtSameAsPrevEpisodes} style={{ paddingLeft: "0px" }} />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                    {row.q54_icans_list.length < 500 && row.q54_icans_list[row.q54_icans_list.length - 1].rbl_B != null && row.q54_icans_list[row.q54_icans_list.length - 1].rbl_B !== 1 &&
                                      < div className="sub-question-bot-sapce-ul mt-2">
                                        <div className="answer-list-text" onClick={() => AddQ54Row(idx)}>
                                          <a>
                                            <img width="18px" src="../Assets/images/plus.png" alt="" />
                                          </a>
                                          <a className="radio-text-padding">
                                            Click here to add another episode
                                          </a>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </>
                            )}
                            {/* Q55. */}
                            {row.q55_neutropenia_list && row.q55_neutropenia_list.length > 0 && (
                              <>
                                <div className="question question-weight">
                                  <span>Q55.</span>
                                  <span className="quest-text-pad">
                                    Neutropenia
                                  </span>
                                </div>
                                <div> {/*className="question-bot-sapce" */}
                                  <div className="dependent-section">
                                    {
                                      row.q55_neutropenia_list.map((childRow, childIdx) => (
                                        <div className="loop-section-ul" key={childIdx}>
                                          <div className="loop-head">
                                            <span className="question-weight">{GetOrdinal(childIdx + 1)} Episode</span>
                                            {childIdx != 0 && <div className="delete-btn-div">
                                              <img
                                                width="16px"
                                                src="../Assets/images/Icon-material-delete.png"
                                                alt=""
                                                onClick={() => DeleteQ55Row(idx, childIdx)}
                                              />
                                            </div>}
                                          </div>

                                          <div className="">{/* question-bot-sapce */}
                                            <div className="sub-question">
                                              <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Treatment</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of neutropenia onset</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of neutropenia resolution</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <span>  {/* className="quest-text-pad" */}
                                                        {row.cohortTreatmentName}
                                                        {!isStringEmpty(row.superScriptText) && (
                                                          <>
                                                            <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                                            {row.superScriptText.slice(2)}
                                                          </>
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div className="">{/* double-dig-answer */}
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q55_rbl_B" + childIdx + "_" + idx}
                                                              value={1}
                                                              checked={childRow.rbl_B === 1}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">The patient did not experience neutropenia</span>
                                                          </label>
                                                        </div>
                                                        <div> {/* className="long-text-option"*/}
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q55_rbl_B" + childIdx + "_" + idx}
                                                              value={2}
                                                              checked={childRow.rbl_B === 2}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                              Neutropenia onset occurred on:{" "}
                                                            </span>
                                                          </label>
                                                          <span className="radio-text-padding" >
                                                            <DateControl
                                                              ctrlId={"q55_date_B" + childIdx + "_" + idx}
                                                              HandleDateChange={HandleRepeaterDateChange}
                                                              optionId={childIdx}
                                                              date={childRow.date_B}
                                                            />
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q55_rbl_B" + childIdx + "_" + idx}
                                                              value={99}
                                                              checked={childRow.rbl_B === 99}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">Neutropenia occurred but date of onset unknown</span>
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <ErrorField show={childRow.B_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_empty_err} message={AdverseEvents_Msgs.OnsetDtEmpty} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_before_err} message={AdverseEvents_Msgs.OnsetDtBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_equal_prev_ep_err} message={AdverseEvents_Msgs.DtSameAsPrevEpisodes} style={{ paddingLeft: "0px" }} />
                                                    </td>

                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q55_rbl_C" + childIdx + "_" + idx}
                                                                  value={1}
                                                                  checked={childRow.rbl_C === 1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">The patient’s neutropenia did not resolve</span>
                                                              </label>
                                                            </div>
                                                            <div> {/* className="long-text-option"*/}
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q55_rbl_C" + childIdx + "_" + idx}
                                                                  value={2}
                                                                  checked={childRow.rbl_C === 2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Neutropenia resolution occurred on:{" "}
                                                                </span>
                                                              </label>
                                                              <span className="radio-text-padding">
                                                                <DateControl
                                                                  ctrlId={"q55_date_C" + childIdx + "_" + idx}
                                                                  HandleDateChange={HandleRepeaterDateChange}
                                                                  optionId={childIdx}
                                                                  date={childRow.date_C}
                                                                />
                                                              </span>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q55_rbl_C" + childIdx + "_" + idx}
                                                                  value={3}
                                                                  checked={childRow.rbl_C === 3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Neutropenia resolution occurred but date of resolution unknown</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q55_rbl_C" + childIdx + "_" + idx}
                                                                  value={99}
                                                                  checked={childRow.rbl_C === 99}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown whether the neutropenia event resolved or not</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.C_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_empty_err} message={AdverseEvents_Msgs.ResolutionDtEmpty} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_before_index_err} message={AdverseEvents_Msgs.DateBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_before_onset_err} message={AdverseEvents_Msgs.NeutroResolutionBeforeOnset} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                    {row.q55_neutropenia_list.length < 500 && row.q55_neutropenia_list[row.q55_neutropenia_list.length - 1].rbl_B != null && row.q55_neutropenia_list[row.q55_neutropenia_list.length - 1].rbl_B !== 1 &&
                                      < div className="sub-question-bot-sapce-ul mt-2">
                                        <div className="answer-list-text" onClick={() => AddQ55Row(idx)}>
                                          <a>
                                            <img width="18px" src="../Assets/images/plus.png" alt="" />
                                          </a>
                                          <a className="radio-text-padding">
                                            Click here to add another episode
                                          </a>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </>
                            )}
                            {/* Q56. */}
                            {row.q56_cytopenia_list && row.q56_cytopenia_list.length > 0 && (
                              <>
                                <div className="question question-weight">
                                  <span>Q56.</span>
                                  <span className="quest-text-pad">
                                    Cytopenia
                                  </span>
                                </div>
                                <div> {/*className="question-bot-sapce" */}
                                  <div className="dependent-section">
                                    {
                                      row.q56_cytopenia_list.map((childRow, childIdx) => (
                                        <div className="loop-section-ul" key={childIdx}>
                                          <div className="loop-head">
                                            <span className="question-weight">{GetOrdinal(childIdx + 1)} Episode</span>
                                            {childIdx != 0 && <div className="delete-btn-div">
                                              <img
                                                width="16px"
                                                src="../Assets/images/Icon-material-delete.png"
                                                alt=""
                                                onClick={() => DeleteQ56Row(idx, childIdx)}
                                              />
                                            </div>}
                                          </div>

                                          <div className="">{/* question-bot-sapce */}
                                            <div className="sub-question">
                                              <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Treatment</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of cytopenia onset</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of cytopenia resolution</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <span>  {/* className="quest-text-pad" */}
                                                        {row.cohortTreatmentName}
                                                        {!isStringEmpty(row.superScriptText) && (
                                                          <>
                                                            <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                                            {row.superScriptText.slice(2)}
                                                          </>
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div className="">{/* double-dig-answer */}
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q56_rbl_B" + childIdx + "_" + idx}
                                                              value={1}
                                                              checked={childRow.rbl_B === 1}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">The patient did not experience cytopenia</span>
                                                          </label>
                                                        </div>
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q56_rbl_B" + childIdx + "_" + idx}
                                                              value={2}
                                                              checked={childRow.rbl_B === 2}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                              Cytopenia onset occurred on:{" "}
                                                            </span>
                                                          </label>
                                                          <span className="radio-text-padding" >
                                                            <DateControl
                                                              ctrlId={"q56_date_B" + childIdx + "_" + idx}
                                                              HandleDateChange={HandleRepeaterDateChange}
                                                              optionId={childIdx}
                                                              date={childRow.date_B}
                                                            />
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q56_rbl_B" + childIdx + "_" + idx}
                                                              value={99}
                                                              checked={childRow.rbl_B === 99}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">Cytopenia occurred but date of onset unknown</span>
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <ErrorField show={childRow.B_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_empty_err} message={AdverseEvents_Msgs.OnsetDtEmpty} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_before_err} message={AdverseEvents_Msgs.OnsetDtBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_equal_prev_ep_err} message={AdverseEvents_Msgs.DtSameAsPrevEpisodes} style={{ paddingLeft: "0px" }} />
                                                    </td>

                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q56_rbl_C" + childIdx + "_" + idx}
                                                                  value={1}
                                                                  checked={childRow.rbl_C === 1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">The patient’s cytopenia did not resolve</span>
                                                              </label>
                                                            </div>
                                                            <div >
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q56_rbl_C" + childIdx + "_" + idx}
                                                                  value={2}
                                                                  checked={childRow.rbl_C === 2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Cytopenia resolution occurred on:{" "}
                                                                </span>
                                                              </label>
                                                              <span className="radio-text-padding" >
                                                                <DateControl
                                                                  ctrlId={"q56_date_C" + childIdx + "_" + idx}
                                                                  HandleDateChange={HandleRepeaterDateChange}
                                                                  optionId={childIdx}
                                                                  date={childRow.date_C}
                                                                />
                                                              </span>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q56_rbl_C" + childIdx + "_" + idx}
                                                                  value={3}
                                                                  checked={childRow.rbl_C === 3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Cytopenia resolution occurred but date of resolution unknown </span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q56_rbl_C" + childIdx + "_" + idx}
                                                                  value={99}
                                                                  checked={childRow.rbl_C === 99}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown whether the cytopenia event resolved or not</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.C_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_empty_err} message={AdverseEvents_Msgs.ResolutionDtEmpty} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_before_index_err} message={AdverseEvents_Msgs.DateBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_before_onset_err} message={AdverseEvents_Msgs.CytoResolutionBeforeOnset} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                    {row.q56_cytopenia_list.length < 500 && row.q56_cytopenia_list[row.q56_cytopenia_list.length - 1].rbl_B != null && row.q56_cytopenia_list[row.q56_cytopenia_list.length - 1].rbl_B !== 1 &&
                                      < div className="sub-question-bot-sapce-ul mt-2">
                                        <div className="answer-list-text" onClick={() => AddQ56Row(idx)}>
                                          <a>
                                            <img width="18px" src="../Assets/images/plus.png" alt="" />
                                          </a>
                                          <a className="radio-text-padding">
                                            Click here to add another episode
                                          </a>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </>
                            )}
                            {/* Q57. */}
                            {row.q57_infection_list && row.q57_infection_list.length > 0 && (
                              <>
                                <div className="question question-weight calender-datefix-survey">
                                  <span>Q57.</span>
                                  <span className="quest-text-pad">
                                    Infection
                                  </span>
                                </div>
                                <div> {/*className="question-bot-sapce" */}
                                  <div className="dependent-section">
                                    {
                                      row.q57_infection_list.map((childRow, childIdx) => (
                                        <div className="loop-section-ul" key={childIdx}>
                                          <div className="loop-head">
                                            <span className="question-weight">{GetOrdinal(childIdx + 1)} Episode</span>
                                            {childIdx != 0 && <div className="delete-btn-div">
                                              <img
                                                width="16px"
                                                src="../Assets/images/Icon-material-delete.png"
                                                alt=""
                                                onClick={() => DeleteQ57Row(idx, childIdx)}
                                              />
                                            </div>}
                                          </div>

                                          <div className="">{/* question-bot-sapce */}
                                            <div className="sub-question" style={{ overflowX: 'auto' }}>
                                              <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Treatment</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of onset</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Type of infection</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Infection organism</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Severity</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Did infection lead to hospital or ER visit?</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <span>  {/* className="quest-text-pad" */}
                                                        {row.cohortTreatmentName}
                                                        {!isStringEmpty(row.superScriptText) && (
                                                          <>
                                                            <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                                            {row.superScriptText.slice(2)}
                                                          </>
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div className="">{/* double-dig-answer */}
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q57_rbl_B" + childIdx + "_" + idx}
                                                              value={1}
                                                              checked={childRow.rbl_B === 1}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">The patient did not experience infection</span>
                                                          </label>
                                                        </div>
                                                        <div >
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q57_rbl_B" + childIdx + "_" + idx}
                                                              value={2}
                                                              checked={childRow.rbl_B === 2}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                              Infection onset occurred on:{" "}
                                                            </span>
                                                          </label>
                                                          <span className="radio-text-padding calender-datefix" >
                                                            <DateControl
                                                              ctrlId={"q57_date_B" + childIdx + "_" + idx}
                                                              HandleDateChange={HandleRepeaterDateChange}
                                                              optionId={childIdx}
                                                              date={childRow.date_B}
                                                            />
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q57_rbl_B" + childIdx + "_" + idx}
                                                              value={99}
                                                              checked={childRow.rbl_B === 99}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">Infection occurred but date of onset unknown</span>
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <ErrorField show={childRow.B_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_empty_err} message={AdverseEvents_Msgs.OnsetDtEmpty} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_before_err} message={AdverseEvents_Msgs.OnsetDtBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_equal_prev_ep_err} message={AdverseEvents_Msgs.DtSameAsPrevEpisodes} style={{ paddingLeft: "0px" }} />
                                                    </td>
                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_C_1_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_C_1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Bacterial</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_C_2_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_C_2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Viral</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_C_3_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_C_3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Fungal</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q57_rb_C" + childIdx + "_" + idx}
                                                                  checked={childRow.rb_C}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.C_empty_err} message={AdverseEvents_Msgs.ChkBoxRadNoSelection} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }
                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_1_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Adenovirus</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_2_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Candida (thrush)</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_3_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">COVID-19</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_4_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_4}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Cytomegalovirus (CMV)</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_5_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_5}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Escherichia Coli (E coli)</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_6_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_6}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Helicobacter pylori</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_7_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_7}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Hepatitis B</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_8_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_8}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Herpes simplex</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_9_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_9}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Influenza</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_10_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_10}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Pneumocystis jiroveci pneumonia [PJP]</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_11_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_11}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Progressive multifocal leukoencephalopathy</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_12_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_12}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Respiratory syncytial virus (RSV)</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_13_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_13}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Streptococcus pneumoniae</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_14_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_14}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Varicella zoster</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q57_chk_D_15_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_D_15}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Others - please specify:{" "}
                                                                  <textarea
                                                                    id={childIdx}
                                                                    name={"q57_txt_D_oth" + childIdx + "_" + idx}
                                                                    type="text"
                                                                    value={childRow.txt_D_oth}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                    maxLength={300}
                                                                    className="input-dash"
                                                                    style={{ width: '200px' }}
                                                                  />
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q57_rb_D" + childIdx + "_" + idx}
                                                                  checked={childRow.rb_D}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.D_empty_err} message={AdverseEvents_Msgs.ChkBoxRadNoSelection} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.D_txt_empty_err} message={AdverseEvents_Msgs.CheckedNoText} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }

                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q57_rbl_E" + childIdx + "_" + idx}
                                                                  value={1}
                                                                  checked={childRow.rbl_E === 1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Grade 1</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q57_rbl_E" + childIdx + "_" + idx}
                                                                  value={2}
                                                                  checked={childRow.rbl_E === 2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Grade 2</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q57_rbl_E" + childIdx + "_" + idx}
                                                                  value={3}
                                                                  checked={childRow.rbl_E === 3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Grade 3</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q57_rbl_E" + childIdx + "_" + idx}
                                                                  value={4}
                                                                  checked={childRow.rbl_E === 4}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Grade 4</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q57_rbl_E" + childIdx + "_" + idx}
                                                                  value={5}
                                                                  checked={childRow.rbl_E === 5}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Grade 5</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q57_rbl_E" + childIdx + "_" + idx}
                                                                  value={99}
                                                                  checked={childRow.rbl_E === 99}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.E_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }
                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q57_rbl_F" + childIdx + "_" + idx}
                                                                  value={1}
                                                                  checked={childRow.rbl_F === 1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Yes</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q57_rbl_F" + childIdx + "_" + idx}
                                                                  value={0}
                                                                  checked={childRow.rbl_F === 0}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">No</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q57_rbl_F" + childIdx + "_" + idx}
                                                                  value={99}
                                                                  checked={childRow.rbl_F === 99}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.F_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                    {row.q57_infection_list.length < 500 && row.q57_infection_list[row.q57_infection_list.length - 1].rbl_B != null && row.q57_infection_list[row.q57_infection_list.length - 1].rbl_B !== 1 &&
                                      < div className="sub-question-bot-sapce-ul mt-2">
                                        <div className="answer-list-text" onClick={() => AddQ57Row(idx)}>
                                          <a>
                                            <img width="18px" src="../Assets/images/plus.png" alt="" />
                                          </a>
                                          <a className="radio-text-padding">
                                            Click here to add another episode
                                          </a>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </>
                            )}
                            {/* Q58. */}
                            {row.q58_weightLoss_list && row.q58_weightLoss_list.length > 0 && (
                              <>
                                <div className="question question-weight">
                                  <span>Q58.</span>
                                  <span className="quest-text-pad">
                                    Weight loss
                                  </span>
                                </div>
                                <div> {/*className="question-bot-sapce" */}
                                  <div className="dependent-section">
                                    {
                                      row.q58_weightLoss_list.map((childRow, childIdx) => (
                                        <div className="loop-section-ul" key={childIdx}>
                                          <div className="loop-head">
                                            <span className="question-weight">{GetOrdinal(childIdx + 1)} Episode</span>
                                            {childIdx != 0 && <div className="delete-btn-div">
                                              <img
                                                width="16px"
                                                src="../Assets/images/Icon-material-delete.png"
                                                alt=""
                                                onClick={() => DeleteQ58Row(idx, childIdx)}
                                              />
                                            </div>}
                                          </div>

                                          <div className="">{/* question-bot-sapce */}
                                            <div className="sub-question">
                                              <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Treatment</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of onset</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of resolution</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of weight becoming stable</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <span>  {/* className="quest-text-pad" */}
                                                        {row.cohortTreatmentName}
                                                        {!isStringEmpty(row.superScriptText) && (
                                                          <>
                                                            <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                                            {row.superScriptText.slice(2)}
                                                          </>
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div className="">{/* double-dig-answer */}
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q58_rbl_B" + childIdx + "_" + idx}
                                                              value={1}
                                                              checked={childRow.rbl_B === 1}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">The patient did not experience weight loss</span>
                                                          </label>
                                                        </div>
                                                        <div className="long-text-option">
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q58_rbl_B" + childIdx + "_" + idx}
                                                              value={2}
                                                              checked={childRow.rbl_B === 2}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                              Weight loss onset occurred on:{" "}
                                                            </span>
                                                          </label>
                                                          <span  >
                                                            <DateControl
                                                              ctrlId={"q58_date_B" + childIdx + "_" + idx}
                                                              HandleDateChange={HandleRepeaterDateChange}
                                                              optionId={childIdx}
                                                              date={childRow.date_B}
                                                            />
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q58_rbl_B" + childIdx + "_" + idx}
                                                              value={99}
                                                              checked={childRow.rbl_B === 99}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">Weight loss occurred but date of onset unknown</span>
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <ErrorField show={childRow.B_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_empty_err} message={AdverseEvents_Msgs.OnsetDtEmpty} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_before_err} message={AdverseEvents_Msgs.OnsetDtBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_equal_prev_ep_err} message={AdverseEvents_Msgs.DtSameAsPrevEpisodes} style={{ paddingLeft: "0px" }} />
                                                    </td>
                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (
                                                        <>
                                                          <td
                                                            style={{
                                                              border: "1px solid black",
                                                              padding: "8px",
                                                            }}
                                                          >
                                                            <div className="">{/* double-dig-answer */}
                                                              <div>
                                                                <label className="long-text-option">
                                                                  <input
                                                                    type="radio"
                                                                    id={childIdx}
                                                                    name={"q58_rbl_C" + childIdx + "_" + idx}
                                                                    value={1}
                                                                    checked={childRow.rbl_C === 1}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                  />
                                                                  <span className="radio-text-padding">The patient’s weight loss did not resolve</span>
                                                                </label>
                                                              </div>
                                                              <div> {/* className="long-text-option"*/}
                                                                <label className="long-text-option">
                                                                  <input
                                                                    type="radio"
                                                                    id={childIdx}
                                                                    name={"q58_rbl_C" + childIdx + "_" + idx}
                                                                    value={2}
                                                                    checked={childRow.rbl_C === 2}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                  />
                                                                  <span className="radio-text-padding">
                                                                    Resolution occurred on:{" "}
                                                                  </span>
                                                                </label>
                                                                <span className="radio-text-padding">
                                                                  <DateControl
                                                                    ctrlId={"q58_date_C" + childIdx + "_" + idx}
                                                                    HandleDateChange={HandleRepeaterDateChange}
                                                                    optionId={childIdx}
                                                                    date={childRow.date_C}
                                                                  />
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <label className="long-text-option">
                                                                  <input
                                                                    type="radio"
                                                                    id={childIdx}
                                                                    name={"q58_rbl_C" + childIdx + "_" + idx}
                                                                    value={3}
                                                                    checked={childRow.rbl_C === 3}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                  />
                                                                  <span className="radio-text-padding">Resolution occurred but date unknown</span>
                                                                </label>
                                                              </div>
                                                              <div>
                                                                <label className="long-text-option">
                                                                  <input
                                                                    type="radio"
                                                                    id={childIdx}
                                                                    name={"q58_rbl_C" + childIdx + "_" + idx}
                                                                    value={99}
                                                                    checked={childRow.rbl_C === 99}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                  />
                                                                  <span className="radio-text-padding">Unknown whether the weight loss resolved or not</span>
                                                                </label>
                                                              </div>
                                                            </div>
                                                            <ErrorField show={childRow.C_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                            <ErrorField show={childRow.C_date_empty_err} message={AdverseEvents_Msgs.ResolutionDtEmpty} style={{ paddingLeft: '0px' }} />
                                                            <ErrorField show={childRow.C_date_before_index_err} message={AdverseEvents_Msgs.DateBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                            <ErrorField show={childRow.C_date_before_onset_err} message={AdverseEvents_Msgs.WtLossResolutionBeforeOnset} style={{ paddingLeft: '0px' }} />
                                                            <ErrorField show={childRow.C_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                          </td>
                                                          <td
                                                            style={{
                                                              border: "1px solid black",
                                                              padding: "8px",
                                                            }}
                                                          >
                                                            <div className="">{/* double-dig-answer */}
                                                              <div>
                                                                <label className="long-text-option">
                                                                  <input
                                                                    type="radio"
                                                                    id={childIdx}
                                                                    name={"q58_rbl_D" + childIdx + "_" + idx}
                                                                    value={1}
                                                                    checked={childRow.rbl_D === 1}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                  />
                                                                  <span className="radio-text-padding">The patient’s weight did not stabilize</span>
                                                                </label>
                                                              </div>
                                                              <div> {/* className="long-text-option"*/}
                                                                <label className="long-text-option">
                                                                  <input
                                                                    type="radio"
                                                                    id={childIdx}
                                                                    name={"q58_rbl_D" + childIdx + "_" + idx}
                                                                    value={2}
                                                                    checked={childRow.rbl_D === 2}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                  />
                                                                  <span className="radio-text-padding">
                                                                    Weight stabilization occurred on:{" "}
                                                                  </span>
                                                                </label>
                                                                <span className="radio-text-padding">
                                                                  <DateControl
                                                                    ctrlId={"q58_date_D" + childIdx + "_" + idx}
                                                                    HandleDateChange={HandleRepeaterDateChange}
                                                                    optionId={childIdx}
                                                                    date={childRow.date_D}
                                                                  />
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <label className="long-text-option">
                                                                  <input
                                                                    type="radio"
                                                                    id={childIdx}
                                                                    name={"q58_rbl_D" + childIdx + "_" + idx}
                                                                    value={3}
                                                                    checked={childRow.rbl_D === 3}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                  />
                                                                  <span className="radio-text-padding">Weight stabilization occurred but date unknown</span>
                                                                </label>
                                                              </div>
                                                              <div>
                                                                <label className="long-text-option">
                                                                  <input
                                                                    type="radio"
                                                                    id={childIdx}
                                                                    name={"q58_rbl_D" + childIdx + "_" + idx}
                                                                    value={99}
                                                                    checked={childRow.rbl_D === 99}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                  />
                                                                  <span className="radio-text-padding">Unknown whether the patient’s weight stabilized or not</span>
                                                                </label>
                                                              </div>
                                                            </div>
                                                            <ErrorField show={childRow.D_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                            <ErrorField show={childRow.D_date_empty_err} message={AdverseEvents_Msgs.WtLossStabilizationDtEmpty} style={{ paddingLeft: '0px' }} />

                                                            <ErrorField show={childRow.D_date_before_index_err} message={AdverseEvents_Msgs.DateBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                            <ErrorField show={childRow.D_date_before_onset_err} message={AdverseEvents_Msgs.WtLossResolutionBeforeOnset} style={{ paddingLeft: '0px' }} />
                                                            <ErrorField show={childRow.D_date_before_res_err} message={AdverseEvents_Msgs.WtLossBeforeResolution} style={{ paddingLeft: '0px' }} />
                                                            <ErrorField show={childRow.D_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                          </td>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <td
                                                            style={{
                                                              border: "1px solid black",
                                                              // alignContent: "flex-start",
                                                              textAlign: "center",
                                                              padding: "8px",
                                                              //textAlign: 'start'
                                                            }}
                                                          >
                                                            <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                          </td>
                                                          <td
                                                            style={{
                                                              border: "1px solid black",
                                                              // alignContent: "flex-start",
                                                              textAlign: "center",
                                                              padding: "8px",
                                                              //textAlign: 'start'
                                                            }}
                                                          >
                                                            <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                          </td>
                                                        </>
                                                      )
                                                    }
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                    {row.q58_weightLoss_list.length < 500 && row.q58_weightLoss_list[row.q58_weightLoss_list.length - 1].rbl_B != null && row.q58_weightLoss_list[row.q58_weightLoss_list.length - 1].rbl_B !== 1 &&
                                      < div className="sub-question-bot-sapce-ul mt-2">
                                        <div className="answer-list-text" onClick={() => AddQ58Row(idx)}>
                                          <a>
                                            <img width="18px" src="../Assets/images/plus.png" alt="" />
                                          </a>
                                          <a className="radio-text-padding">
                                            Click here to add another episode
                                          </a>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </>
                            )}
                            {/* Q59. */}
                            {row.q59_dysgeusia_list && row.q59_dysgeusia_list.length > 0 && (
                              <>
                                <div className="question question-weight">
                                  <span>Q59.</span>
                                  <span className="quest-text-pad">
                                    Dysgeusia (including ageusia, dysgeusia, hypogeusia, and other taste disorders)
                                  </span>
                                </div>
                                <div> {/*className="question-bot-sapce" */}
                                  <div className="dependent-section">
                                    {
                                      row.q59_dysgeusia_list.map((childRow, childIdx) => (
                                        <div className="loop-section-ul" key={childIdx}>
                                          <div className="loop-head">
                                            <span className="question-weight">{GetOrdinal(childIdx + 1)} Episode</span>
                                            {childIdx != 0 && <div className="delete-btn-div">
                                              <img
                                                width="16px"
                                                src="../Assets/images/Icon-material-delete.png"
                                                alt=""
                                                onClick={() => DeleteQ59Row(idx, childIdx)}
                                              />
                                            </div>}
                                          </div>

                                          <div className="">{/* question-bot-sapce */}
                                            <div className="sub-question">
                                              <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Treatment</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of first occurrence</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Intervention</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Earliest date of improvement</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of resolution</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <span>  {/* className="quest-text-pad" */}
                                                        {row.cohortTreatmentName}
                                                        {!isStringEmpty(row.superScriptText) && (
                                                          <>
                                                            <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                                            {row.superScriptText.slice(2)}
                                                          </>
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div className="">{/* double-dig-answer */}
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q59_rbl_B" + childIdx + "_" + idx}
                                                              value={1}
                                                              checked={childRow.rbl_B === 1}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">The patient did not experience dysgeusia</span>
                                                          </label>
                                                        </div>
                                                        <div >
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q59_rbl_B" + childIdx + "_" + idx}
                                                              value={2}
                                                              checked={childRow.rbl_B === 2}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                              Dysgeusia onset occurred on:{" "}
                                                            </span>
                                                          </label>
                                                          <span className="radio-text-padding">
                                                            <DateControl
                                                              ctrlId={"q59_date_B" + childIdx + "_" + idx}
                                                              HandleDateChange={HandleRepeaterDateChange}
                                                              optionId={childIdx}
                                                              date={childRow.date_B}
                                                            />
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q59_rbl_B" + childIdx + "_" + idx}
                                                              value={99}
                                                              checked={childRow.rbl_B === 99}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">Dysgeusia occurred but date of onset unknown</span>
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <ErrorField show={childRow.B_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_empty_err} message={AdverseEvents_Msgs.OnsetDtEmpty} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_before_err} message={AdverseEvents_Msgs.OnsetDtBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_equal_prev_ep_err} message={AdverseEvents_Msgs.DtSameAsPrevEpisodes} style={{ paddingLeft: "0px" }} />
                                                    </td>
                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <label className="long-text-option">
                                                              <input
                                                                type="checkbox"
                                                                id={childIdx}
                                                                name={"q59_chk_CC_1_" + childIdx + "_" + idx}
                                                                checked={childRow.chk_CC_1}
                                                                onChange={HandleRepeaterFieldChange}
                                                              />
                                                              <span className="radio-text-padding">Dexamethasone mouthwash</span>
                                                            </label>
                                                            {childRow.chk_CC_1 &&
                                                              <Q59ProphylacticTherapeutic
                                                                parentIndex={idx}
                                                                childIdx={childIdx}
                                                                name="q59_chk_CC_1_"
                                                                pChecked={childRow.chk_CC_1_P}
                                                                sChecked={childRow.chk_CC_1_T}
                                                                HandleRepeaterFieldChange={HandleRepeaterFieldChange}
                                                                error={childRow.CC_1_empty_err}
                                                              />
                                                            }
                                                            <label className="long-text-option">
                                                              <input
                                                                type="checkbox"
                                                                id={childIdx}
                                                                name={"q59_chk_CC_2_" + childIdx + "_" + idx}
                                                                checked={childRow.chk_CC_2}
                                                                onChange={HandleRepeaterFieldChange}
                                                              />
                                                              <span className="radio-text-padding">Nystatin mouthwash</span>
                                                            </label>
                                                            {childRow.chk_CC_2 &&
                                                              <Q59ProphylacticTherapeutic
                                                                parentIndex={idx}
                                                                childIdx={childIdx}
                                                                name="q59_chk_CC_2_"
                                                                pChecked={childRow.chk_CC_2_P}
                                                                sChecked={childRow.chk_CC_2_T}
                                                                HandleRepeaterFieldChange={HandleRepeaterFieldChange}
                                                                error={childRow.CC_2_empty_err}
                                                              />
                                                            }
                                                            <label className="long-text-option">
                                                              <input
                                                                type="checkbox"
                                                                id={childIdx}
                                                                name={"q59_chk_CC_3_" + childIdx + "_" + idx}
                                                                checked={childRow.chk_CC_3}
                                                                onChange={HandleRepeaterFieldChange}
                                                              />
                                                              <span className="radio-text-padding">Saline mouthwash</span>
                                                            </label>
                                                            {childRow.chk_CC_3 &&
                                                              <Q59ProphylacticTherapeutic
                                                                parentIndex={idx}
                                                                childIdx={childIdx}
                                                                name="q59_chk_CC_3_"
                                                                pChecked={childRow.chk_CC_3_P}
                                                                sChecked={childRow.chk_CC_3_T}
                                                                HandleRepeaterFieldChange={HandleRepeaterFieldChange}
                                                                error={childRow.CC_3_empty_err}
                                                              />
                                                            }
                                                            <label className="long-text-option">
                                                              <input
                                                                type="checkbox"
                                                                id={childIdx}
                                                                name={"q59_chk_CC_4_" + childIdx + "_" + idx}
                                                                checked={childRow.chk_CC_4}
                                                                onChange={HandleRepeaterFieldChange}
                                                              />
                                                              <span className="radio-text-padding">Biotene mouthwash/spray</span>
                                                            </label>
                                                            {childRow.chk_CC_4 &&
                                                              <Q59ProphylacticTherapeutic
                                                                parentIndex={idx}
                                                                childIdx={childIdx}
                                                                name="q59_chk_CC_4_"
                                                                pChecked={childRow.chk_CC_4_P}
                                                                sChecked={childRow.chk_CC_4_T}
                                                                HandleRepeaterFieldChange={HandleRepeaterFieldChange}
                                                                error={childRow.CC_4_empty_err}
                                                              />
                                                            }
                                                            <label className="long-text-option">
                                                              <input
                                                                type="checkbox"
                                                                id={childIdx}
                                                                name={"q59_chk_CC_5_" + childIdx + "_" + idx}
                                                                checked={childRow.chk_CC_5}
                                                                onChange={HandleRepeaterFieldChange}
                                                              />
                                                              <span className="radio-text-padding">Zinc</span>
                                                            </label>
                                                            {childRow.chk_CC_5 &&
                                                              <Q59ProphylacticTherapeutic
                                                                parentIndex={idx}
                                                                childIdx={childIdx}
                                                                name="q59_chk_CC_5_"
                                                                pChecked={childRow.chk_CC_5_P}
                                                                sChecked={childRow.chk_CC_5_T}
                                                                HandleRepeaterFieldChange={HandleRepeaterFieldChange}
                                                                error={childRow.CC_5_empty_err}
                                                              />
                                                            }
                                                            <label className="long-text-option">
                                                              <input
                                                                type="checkbox"
                                                                id={childIdx}
                                                                name={"q59_chk_CC_6_" + childIdx + "_" + idx}
                                                                checked={childRow.chk_CC_6}
                                                                onChange={HandleRepeaterFieldChange}
                                                              />
                                                              <span className="radio-text-padding">Vitamin B</span>
                                                            </label>
                                                            {childRow.chk_CC_6 &&
                                                              <Q59ProphylacticTherapeutic
                                                                parentIndex={idx}
                                                                childIdx={childIdx}
                                                                name="q59_chk_CC_6_"
                                                                pChecked={childRow.chk_CC_6_P}
                                                                sChecked={childRow.chk_CC_6_T}
                                                                HandleRepeaterFieldChange={HandleRepeaterFieldChange}
                                                                error={childRow.CC_6_empty_err}
                                                              />
                                                            }
                                                            <label className="long-text-option">
                                                              <input
                                                                type="checkbox"
                                                                id={childIdx}
                                                                name={"q59_chk_CC_7_" + childIdx + "_" + idx}
                                                                checked={childRow.chk_CC_7}
                                                                onChange={HandleRepeaterFieldChange}
                                                              />
                                                              <span className="radio-text-padding">
                                                                Other, please specify:{" "}
                                                                <textarea
                                                                  id={childIdx}
                                                                  name={"q59_txt_CC_oth" + childIdx + "_" + idx}
                                                                  type="text"
                                                                  value={childRow.txt_CC_oth}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                  maxLength={300}
                                                                  className="input-dash"
                                                                  style={{ width: '200px' }}
                                                                />
                                                              </span>
                                                            </label>
                                                            {childRow.chk_CC_7 &&
                                                              <Q59ProphylacticTherapeutic
                                                                parentIndex={idx}
                                                                childIdx={childIdx}
                                                                name="q59_chk_CC_7_"
                                                                pChecked={childRow.chk_CC_7_P}
                                                                sChecked={childRow.chk_CC_7_T}
                                                                HandleRepeaterFieldChange={HandleRepeaterFieldChange}
                                                                error={childRow.CC_7_empty_err}
                                                              />
                                                            }
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q59_rb_CC" + childIdx + "_" + idx}
                                                                  checked={childRow.rb_CC}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.CC_empty_err} message={AdverseEvents_Msgs.ChkBoxRadNoSelection} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.CC_txt_empty_err} message={AdverseEvents_Msgs.q59CheckedNoText} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }



                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q59_rbl_C" + childIdx + "_" + idx}
                                                                  value={1}
                                                                  checked={childRow.rbl_C === 1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">The patient did not experience improvement</span>
                                                              </label>
                                                            </div>
                                                            <div >
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q59_rbl_C" + childIdx + "_" + idx}
                                                                  value={2}
                                                                  checked={childRow.rbl_C === 2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Earliest improvement occurred on:{" "}
                                                                </span>
                                                              </label>
                                                              <span className="radio-text-padding">
                                                                <DateControl
                                                                  ctrlId={"q59_date_C" + childIdx + "_" + idx}
                                                                  HandleDateChange={HandleRepeaterDateChange}
                                                                  optionId={childIdx}
                                                                  date={childRow.date_C}
                                                                />
                                                              </span>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q59_rbl_C" + childIdx + "_" + idx}
                                                                  value={3}
                                                                  checked={childRow.rbl_C === 3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Improvement occurred but date unknown</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q59_rbl_C" + childIdx + "_" + idx}
                                                                  value={99}
                                                                  checked={childRow.rbl_C === 99}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown whether the patient experienced improvement or not</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.C_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_empty_err} message={AdverseEvents_Msgs.EarlyImpDtEmpty} style={{ paddingLeft: '0px' }} />

                                                          <ErrorField show={childRow.C_date_before_index_err} message={AdverseEvents_Msgs.DateBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_before_onset_err} message={AdverseEvents_Msgs.DysResolutionBeforeOnset} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }
                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q59_rbl_D" + childIdx + "_" + idx}
                                                                  value={1}
                                                                  checked={childRow.rbl_D === 1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">The patient’s dysgeusia did not resolve</span>
                                                              </label>
                                                            </div>
                                                            <div >
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q59_rbl_D" + childIdx + "_" + idx}
                                                                  value={2}
                                                                  checked={childRow.rbl_D === 2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Resolution occurred on:{" "}
                                                                </span>
                                                              </label>
                                                              <span className="radio-text-padding">
                                                                <DateControl
                                                                  ctrlId={"q59_date_D" + childIdx + "_" + idx}
                                                                  HandleDateChange={HandleRepeaterDateChange}
                                                                  optionId={childIdx}
                                                                  date={childRow.date_D}
                                                                />
                                                              </span>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q59_rbl_D" + childIdx + "_" + idx}
                                                                  value={3}
                                                                  checked={childRow.rbl_D === 3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Resolution occurred but date unknown</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q59_rbl_D" + childIdx + "_" + idx}
                                                                  value={99}
                                                                  checked={childRow.rbl_D === 99}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown whether the dysgeusia event resolved or not</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.D_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.D_date_empty_err} message={AdverseEvents_Msgs.ResolutionDtEmpty} style={{ paddingLeft: '0px' }} />

                                                          <ErrorField show={childRow.D_date_before_index_err} message={AdverseEvents_Msgs.DateBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.D_date_before_onset_err} message={AdverseEvents_Msgs.DysResolutionBeforeOnset} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.D_date_before_impr_err} message={AdverseEvents_Msgs.DysResBeforeEarlyImp} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.D_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />

                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                    {row.q59_dysgeusia_list.length < 500 && row.q59_dysgeusia_list[row.q59_dysgeusia_list.length - 1].rbl_B != null && row.q59_dysgeusia_list[row.q59_dysgeusia_list.length - 1].rbl_B !== 1 &&
                                      < div className="sub-question-bot-sapce-ul mt-2">
                                        <div className="answer-list-text" onClick={() => AddQ59Row(idx)}>
                                          <a>
                                            <img width="18px" src="../Assets/images/plus.png" alt="" />
                                          </a>
                                          <a className="radio-text-padding">
                                            Click here to add another episode
                                          </a>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </>
                            )}
                            {/* Q60. */}
                            {row.q60_nail_list && row.q60_nail_list.length > 0 && (
                              <>
                                <div className="question question-weight">
                                  <span>Q60.</span>
                                  <span className="quest-text-pad">
                                    Nail disorder
                                  </span>
                                </div>
                                <div> {/*className="question-bot-sapce" */}
                                  <div className="dependent-section">
                                    {
                                      row.q60_nail_list.map((childRow, childIdx) => (
                                        <div className="loop-section-ul" key={childIdx}>
                                          <div className="loop-head">
                                            <span className="question-weight">{GetOrdinal(childIdx + 1)} Episode</span>
                                            {childIdx != 0 && <div className="delete-btn-div">
                                              <img
                                                width="16px"
                                                src="../Assets/images/Icon-material-delete.png"
                                                alt=""
                                                onClick={() => DeleteQ60Row(idx, childIdx)}
                                              />
                                            </div>}
                                          </div>

                                          <div className="">{/* question-bot-sapce */}
                                            <div className="sub-question">
                                              <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Treatment</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of first occurrence</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Earliest date of improvement</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of resolution</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <span>  {/* className="quest-text-pad" */}
                                                        {row.cohortTreatmentName}
                                                        {!isStringEmpty(row.superScriptText) && (
                                                          <>
                                                            <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                                            {row.superScriptText.slice(2)}
                                                          </>
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div className="">{/* double-dig-answer */}
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q60_rbl_B" + childIdx + "_" + idx}
                                                              value={1}
                                                              checked={childRow.rbl_B === 1}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">The patient did not experience nail disorder</span>
                                                          </label>
                                                        </div>
                                                        <div >
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q60_rbl_B" + childIdx + "_" + idx}
                                                              value={2}
                                                              checked={childRow.rbl_B === 2}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                              Nail disorder onset occurred on:{" "}
                                                            </span>
                                                          </label>
                                                          <span className="radio-text-padding" >
                                                            <DateControl
                                                              ctrlId={"q60_date_B" + childIdx + "_" + idx}
                                                              HandleDateChange={HandleRepeaterDateChange}
                                                              optionId={childIdx}
                                                              date={childRow.date_B}
                                                            />
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q60_rbl_B" + childIdx + "_" + idx}
                                                              value={99}
                                                              checked={childRow.rbl_B === 99}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">Nail disorder occurred but date of onset unknown</span>
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <ErrorField show={childRow.B_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_empty_err} message={AdverseEvents_Msgs.OnsetDtEmpty} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_before_err} message={AdverseEvents_Msgs.OnsetDtBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_equal_prev_ep_err} message={AdverseEvents_Msgs.DtSameAsPrevEpisodes} style={{ paddingLeft: "0px" }} />
                                                    </td>
                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q60_rbl_C" + childIdx + "_" + idx}
                                                                  value={1}
                                                                  checked={childRow.rbl_C === 1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">The patient did not experience improvement</span>
                                                              </label>
                                                            </div>
                                                            <div >
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q60_rbl_C" + childIdx + "_" + idx}
                                                                  value={2}
                                                                  checked={childRow.rbl_C === 2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Earliest improvement occurred on:{" "}
                                                                </span>
                                                              </label>
                                                              <span className="radio-text-padding">
                                                                <DateControl
                                                                  ctrlId={"q60_date_C" + childIdx + "_" + idx}
                                                                  HandleDateChange={HandleRepeaterDateChange}
                                                                  optionId={childIdx}
                                                                  date={childRow.date_C}
                                                                />
                                                              </span>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q60_rbl_C" + childIdx + "_" + idx}
                                                                  value={3}
                                                                  checked={childRow.rbl_C === 3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Improvement occurred but date unknown</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q60_rbl_C" + childIdx + "_" + idx}
                                                                  value={99}
                                                                  checked={childRow.rbl_C === 99}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown whether the patient experienced improvement or not</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.C_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_empty_err} message={AdverseEvents_Msgs.EarlyImpDtEmpty} style={{ paddingLeft: '0px' }} />

                                                          <ErrorField show={childRow.C_date_before_index_err} message={AdverseEvents_Msgs.DateBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_before_onset_err} message={AdverseEvents_Msgs.NailResolutionBeforeOnset} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }
                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q60_rbl_D" + childIdx + "_" + idx}
                                                                  value={1}
                                                                  checked={childRow.rbl_D === 1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">The patient’s nail disorder did not resolve</span>
                                                              </label>
                                                            </div>
                                                            <div >
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q60_rbl_D" + childIdx + "_" + idx}
                                                                  value={2}
                                                                  checked={childRow.rbl_D === 2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Resolution occurred on:{" "}
                                                                </span>
                                                              </label>
                                                              <span className="radio-text-padding">
                                                                <DateControl
                                                                  ctrlId={"q60_date_D" + childIdx + "_" + idx}
                                                                  HandleDateChange={HandleRepeaterDateChange}
                                                                  optionId={childIdx}
                                                                  date={childRow.date_D}
                                                                />
                                                              </span>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q60_rbl_D" + childIdx + "_" + idx}
                                                                  value={3}
                                                                  checked={childRow.rbl_D === 3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Resolution occurred but date unknown</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q60_rbl_D" + childIdx + "_" + idx}
                                                                  value={99}
                                                                  checked={childRow.rbl_D === 99}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown whether the nail disorder event resolved or not</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.D_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.D_date_empty_err} message={AdverseEvents_Msgs.ResolutionDtEmpty} style={{ paddingLeft: '0px' }} />

                                                          <ErrorField show={childRow.D_date_before_index_err} message={AdverseEvents_Msgs.DateBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.D_date_before_onset_err} message={AdverseEvents_Msgs.NailResolutionBeforeOnset} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.D_date_before_impr_err} message={AdverseEvents_Msgs.NailResBeforeEarlyImp} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.D_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                    {row.q60_nail_list.length < 500 && row.q60_nail_list[row.q60_nail_list.length - 1].rbl_B != null && row.q60_nail_list[row.q60_nail_list.length - 1].rbl_B !== 1 &&
                                      < div className="sub-question-bot-sapce-ul mt-2">
                                        <div className="answer-list-text" onClick={() => AddQ60Row(idx)}>
                                          <a>
                                            <img width="18px" src="../Assets/images/plus.png" alt="" />
                                          </a>
                                          <a className="radio-text-padding">
                                            Click here to add another episode
                                          </a>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </>
                            )}
                            {/* Q61. */}
                            {row.q61_skin_list && row.q61_skin_list.length > 0 && (
                              <>
                                <div className="question question-weight">
                                  <span>Q61.</span>
                                  <span className="quest-text-pad">
                                    Skin disorder
                                  </span>
                                </div>
                                <div> {/*className="question-bot-sapce" */}
                                  <div className="dependent-section">
                                    {
                                      row.q61_skin_list.map((childRow, childIdx) => (
                                        <div className="loop-section-ul" key={childIdx}>
                                          <div className="loop-head">
                                            <span className="question-weight">{GetOrdinal(childIdx + 1)} Episode</span>
                                            {childIdx != 0 && <div className="delete-btn-div">
                                              <img
                                                width="16px"
                                                src="../Assets/images/Icon-material-delete.png"
                                                alt=""
                                                onClick={() => DeleteQ61Row(idx, childIdx)}
                                              />
                                            </div>}
                                          </div>

                                          <div className="">{/* question-bot-sapce */}
                                            <div className="sub-question">
                                              <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Treatment</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of first occurrence</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Earliest date of improvement</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of resolution</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <span>  {/* className="quest-text-pad" */}
                                                        {row.cohortTreatmentName}
                                                        {!isStringEmpty(row.superScriptText) && (
                                                          <>
                                                            <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                                            {row.superScriptText.slice(2)}
                                                          </>
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div className="">{/* double-dig-answer */}
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q61_rbl_B" + childIdx + "_" + idx}
                                                              value={1}
                                                              checked={childRow.rbl_B === 1}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">The patient did not experience skin disorder</span>
                                                          </label>
                                                        </div>
                                                        <div >
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q61_rbl_B" + childIdx + "_" + idx}
                                                              value={2}
                                                              checked={childRow.rbl_B === 2}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                              Skin disorder onset occurred on:{" "}
                                                            </span>
                                                          </label>
                                                          <span className="radio-text-padding">
                                                            <DateControl
                                                              ctrlId={"q61_date_B" + childIdx + "_" + idx}
                                                              HandleDateChange={HandleRepeaterDateChange}
                                                              optionId={childIdx}
                                                              date={childRow.date_B}
                                                            />
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q61_rbl_B" + childIdx + "_" + idx}
                                                              value={99}
                                                              checked={childRow.rbl_B === 99}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">Skin disorder occurred but date of onset unknown</span>
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <ErrorField show={childRow.B_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_empty_err} message={AdverseEvents_Msgs.OnsetDtEmpty} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_before_err} message={AdverseEvents_Msgs.OnsetDtBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_equal_prev_ep_err} message={AdverseEvents_Msgs.DtSameAsPrevEpisodes} style={{ paddingLeft: "0px" }} />
                                                    </td>
                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q61_rbl_C" + childIdx + "_" + idx}
                                                                  value={1}
                                                                  checked={childRow.rbl_C === 1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">The patient did not experience improvement</span>
                                                              </label>
                                                            </div>
                                                            <div >
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q61_rbl_C" + childIdx + "_" + idx}
                                                                  value={2}
                                                                  checked={childRow.rbl_C === 2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Earliest improvement occurred on:{" "}
                                                                </span>
                                                              </label>
                                                              <span className="radio-text-padding">
                                                                <DateControl
                                                                  ctrlId={"q61_date_C" + childIdx + "_" + idx}
                                                                  HandleDateChange={HandleRepeaterDateChange}
                                                                  optionId={childIdx}
                                                                  date={childRow.date_C}
                                                                />
                                                              </span>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q61_rbl_C" + childIdx + "_" + idx}
                                                                  value={3}
                                                                  checked={childRow.rbl_C === 3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Improvement occurred but date unknown</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q61_rbl_C" + childIdx + "_" + idx}
                                                                  value={99}
                                                                  checked={childRow.rbl_C === 99}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown whether the patient experienced improvement or not</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.C_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_empty_err} message={AdverseEvents_Msgs.EarlyImpDtEmpty} style={{ paddingLeft: '0px' }} />

                                                          <ErrorField show={childRow.C_date_before_index_err} message={AdverseEvents_Msgs.DateBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_before_onset_err} message={AdverseEvents_Msgs.SkinResolutionBeforeOnset} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }
                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q61_rbl_D" + childIdx + "_" + idx}
                                                                  value={1}
                                                                  checked={childRow.rbl_D === 1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">The patient’s skin disorder did not resolve</span>
                                                              </label>
                                                            </div>
                                                            <div >
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q61_rbl_D" + childIdx + "_" + idx}
                                                                  value={2}
                                                                  checked={childRow.rbl_D === 2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Resolution occurred on:{" "}
                                                                </span>
                                                              </label>
                                                              <span className="radio-text-padding">
                                                                <DateControl
                                                                  ctrlId={"q61_date_D" + childIdx + "_" + idx}
                                                                  HandleDateChange={HandleRepeaterDateChange}
                                                                  optionId={childIdx}
                                                                  date={childRow.date_D}
                                                                />
                                                              </span>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q61_rbl_D" + childIdx + "_" + idx}
                                                                  value={3}
                                                                  checked={childRow.rbl_D === 3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Resolution occurred but date unknown</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q61_rbl_D" + childIdx + "_" + idx}
                                                                  value={99}
                                                                  checked={childRow.rbl_D === 99}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown whether the skin disorder event resolved or not</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.D_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.D_date_empty_err} message={AdverseEvents_Msgs.ResolutionDtEmpty} style={{ paddingLeft: '0px' }} />

                                                          <ErrorField show={childRow.D_date_before_index_err} message={AdverseEvents_Msgs.DateBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.D_date_before_onset_err} message={AdverseEvents_Msgs.SkinResolutionBeforeOnset} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.D_date_before_impr_err} message={AdverseEvents_Msgs.SkinResBeforeEarlyImp} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.D_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                    {row.q61_skin_list.length < 500 && row.q61_skin_list[row.q61_skin_list.length - 1].rbl_B != null && row.q61_skin_list[row.q61_skin_list.length - 1].rbl_B !== 1 &&
                                      < div className="sub-question-bot-sapce-ul mt-2">
                                        <div className="answer-list-text" onClick={() => AddQ61Row(idx)}>
                                          <a>
                                            <img width="18px" src="../Assets/images/plus.png" alt="" />
                                          </a>
                                          <a className="radio-text-padding">
                                            Click here to add another episode
                                          </a>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </>
                            )}
                            {/* Q62. */}
                            {row.q62_spm_list && row.q62_spm_list.length > 0 && (
                              <>
                                <div className="question question-weight">
                                  <span>Q62.</span>
                                  <span className="quest-text-pad">
                                    Secondary primary malignancy (other than MM)
                                  </span>
                                </div>
                                <div> {/*className="question-bot-sapce" */}
                                  <div className="dependent-section">
                                    {
                                      row.q62_spm_list.map((childRow, childIdx) => (
                                        <div className="loop-section-ul" key={childIdx}>
                                          <div className="loop-head">
                                            <span className="question-weight">{GetOrdinal(childIdx + 1)} Episode</span>
                                            {childIdx != 0 && <div className="delete-btn-div">
                                              <img
                                                width="16px"
                                                src="../Assets/images/Icon-material-delete.png"
                                                alt=""
                                                onClick={() => DeleteQ62Row(idx, childIdx)}
                                              />
                                            </div>}
                                          </div>

                                          <div className="">{/* question-bot-sapce */}
                                            <div className="sub-question">
                                              <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', width: "40%" }}>Treatment</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', width: "60%" }}>Date of onset</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <span>  {/* className="quest-text-pad" */}
                                                        {row.cohortTreatmentName}
                                                        {!isStringEmpty(row.superScriptText) && (
                                                          <>
                                                            <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                                            {row.superScriptText.slice(2)}
                                                          </>
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div className="">{/* double-dig-answer */}
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q62_rbl_B" + childIdx + "_" + idx}
                                                              value={1}
                                                              checked={childRow.rbl_B === 1}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">The patient did not experience second primary malignancy other than MM</span>
                                                          </label>
                                                        </div>

                                                        <div >
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q62_rbl_B" + childIdx + "_" + idx}
                                                              value={2}
                                                              checked={childRow.rbl_B === 2}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                              Second primary malignancy (other than MM) onset occurred on:{" "}
                                                            </span>
                                                          </label>
                                                          <span className="radio-text-padding">
                                                            <DateControl
                                                              ctrlId={"q62_date_B" + childIdx + "_" + idx}
                                                              HandleDateChange={HandleRepeaterDateChange}
                                                              optionId={childIdx}
                                                              date={childRow.date_B}
                                                            />
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q62_rbl_B" + childIdx + "_" + idx}
                                                              value={99}
                                                              checked={childRow.rbl_B === 99}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">Second primary malignancy (other than MM) occurred but date of onset unknown</span>
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <ErrorField show={childRow.B_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_empty_err} message={AdverseEvents_Msgs.OnsetDtEmpty} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_before_err} message={AdverseEvents_Msgs.OnsetDtBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_equal_prev_ep_err} message={AdverseEvents_Msgs.DtSameAsPrevEpisodes} style={{ paddingLeft: "0px" }} />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                    {row.q62_spm_list.length < 500 && row.q62_spm_list[row.q62_spm_list.length - 1].rbl_B != null && row.q62_spm_list[row.q62_spm_list.length - 1].rbl_B !== 1 &&
                                      < div className="sub-question-bot-sapce-ul mt-2">
                                        <div className="answer-list-text" onClick={() => AddQ62Row(idx)}>
                                          <a>
                                            <img width="18px" src="../Assets/images/plus.png" alt="" />
                                          </a>
                                          <a className="radio-text-padding">
                                            Click here to add another episode
                                          </a>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </>
                            )}
                            {/* Q63. */}
                            {row.q63_neurotoxicity_list && row.q63_neurotoxicity_list.length > 0 && (
                              <>
                                <div className="question question-weight">
                                  <span>Q63.</span>
                                  <span className="quest-text-pad">
                                    Neurotoxicity
                                  </span>
                                </div>
                                <div> {/*className="question-bot-sapce" */}
                                  <div className="dependent-section">
                                    {
                                      row.q63_neurotoxicity_list.map((childRow, childIdx) => (
                                        <div className="loop-section-ul" key={childIdx}>
                                          <div className="loop-head">
                                            <span className="question-weight">{GetOrdinal(childIdx + 1)} Episode</span>
                                            {childIdx != 0 && <div className="delete-btn-div">
                                              <img
                                                width="16px"
                                                src="../Assets/images/Icon-material-delete.png"
                                                alt=""
                                                onClick={() => DeleteQ63Row(idx, childIdx)}
                                              />
                                            </div>}
                                          </div>

                                          <div className="">{/* question-bot-sapce */}
                                            <div className="sub-question" style={{ overflowX: 'auto' }}>
                                              <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Treatment</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of neurotoxicity onset</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Date of neurotoxicity resolution</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Subtype of neurotoxicity</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Severity</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Management setting of neurotoxicity</th>
                                                    <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>Interventions for managing neurotoxicity</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <span>  {/* className="quest-text-pad" */}
                                                        {row.cohortTreatmentName}
                                                        {!isStringEmpty(row.superScriptText) && (
                                                          <>
                                                            <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                                            {row.superScriptText.slice(2)}
                                                          </>
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        border: "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div className="">{/* double-dig-answer */}
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q63_rbl_B" + childIdx + "_" + idx}
                                                              value={1}
                                                              checked={childRow.rbl_B === 1}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">The patient did not experience neurotoxicity</span>
                                                          </label>
                                                        </div>
                                                        <div >
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q63_rbl_B" + childIdx + "_" + idx}
                                                              value={2}
                                                              checked={childRow.rbl_B === 2}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                              Neurotoxicity onset occurred on:{" "}
                                                            </span>
                                                          </label>
                                                          <span className="radio-text-padding">
                                                            <DateControl
                                                              ctrlId={"q63_date_B" + childIdx + "_" + idx}
                                                              HandleDateChange={HandleRepeaterDateChange}
                                                              optionId={childIdx}
                                                              date={childRow.date_B}
                                                            />
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <label className="long-text-option">
                                                            <input
                                                              type="radio"
                                                              id={childIdx}
                                                              name={"q63_rbl_B" + childIdx + "_" + idx}
                                                              value={99}
                                                              checked={childRow.rbl_B === 99}
                                                              onChange={HandleRepeaterFieldChange}
                                                            />
                                                            <span className="radio-text-padding">Neurotoxicity occurred but date of onset unknown</span>
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <ErrorField show={childRow.B_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_empty_err} message={AdverseEvents_Msgs.OnsetDtEmpty} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_before_err} message={AdverseEvents_Msgs.OnsetDtBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                      <ErrorField show={childRow.B_date_equal_prev_ep_err} message={AdverseEvents_Msgs.DtSameAsPrevEpisodes} style={{ paddingLeft: "0px" }} />
                                                    </td>

                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_C" + childIdx + "_" + idx}
                                                                  value={1}
                                                                  checked={childRow.rbl_C === 1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">The patient’s neurotoxicity did not resolve</span>
                                                              </label>
                                                            </div>
                                                            <div >
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_C" + childIdx + "_" + idx}
                                                                  value={2}
                                                                  checked={childRow.rbl_C === 2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Resolution occurred on:{" "}
                                                                </span>
                                                              </label>
                                                              <span className="radio-text-padding">
                                                                <DateControl
                                                                  ctrlId={"q63_date_C" + childIdx + "_" + idx}
                                                                  HandleDateChange={HandleRepeaterDateChange}
                                                                  optionId={childIdx}
                                                                  date={childRow.date_C}
                                                                />
                                                              </span>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_C" + childIdx + "_" + idx}
                                                                  value={3}
                                                                  checked={childRow.rbl_C === 3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Resolution occurred but date unknown</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_C" + childIdx + "_" + idx}
                                                                  value={99}
                                                                  checked={childRow.rbl_C === 99}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown whether the neurotoxicity event resolved or not</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.C_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_empty_err} message={AdverseEvents_Msgs.ResolutionDtEmpty} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_before_index_err} message={AdverseEvents_Msgs.DateBeforeIndex} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_before_onset_err} message={AdverseEvents_Msgs.NeuroResolutionBeforeOnset} style={{ paddingLeft: '0px' }} />
                                                          <ErrorField show={childRow.C_date_after_err} message={AdverseEvents_Msgs.DtAfterAEEnd} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }

                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_D" + childIdx + "_" + idx}
                                                                  value={1}
                                                                  checked={childRow.rbl_D === 1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Peripheral neuropathy</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_D" + childIdx + "_" + idx}
                                                                  value={2}
                                                                  checked={childRow.rbl_D === 2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Immune effector cell-associated neurotoxicity syndrome (ICANS)</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_D" + childIdx + "_" + idx}
                                                                  value={3}
                                                                  checked={childRow.rbl_D === 3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Fever</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_D" + childIdx + "_" + idx}
                                                                  value={4}
                                                                  checked={childRow.rbl_D === 4}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Delayed neurotoxicity</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_D" + childIdx + "_" + idx}
                                                                  value={5}
                                                                  checked={childRow.rbl_D === 5}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Parkinsonism</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_D" + childIdx + "_" + idx}
                                                                  value={6}
                                                                  checked={childRow.rbl_D === 6}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Cranial nerve palsy</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_D" + childIdx + "_" + idx}
                                                                  value={7}
                                                                  checked={childRow.rbl_D === 7}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Other</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_D" + childIdx + "_" + idx}
                                                                  value={99}
                                                                  checked={childRow.rbl_D === 99}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.D_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }

                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_E" + childIdx + "_" + idx}
                                                                  value={1}
                                                                  checked={childRow.rbl_E === 1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Grade 1</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_E" + childIdx + "_" + idx}
                                                                  value={2}
                                                                  checked={childRow.rbl_E === 2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Grade 2</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_E" + childIdx + "_" + idx}
                                                                  value={3}
                                                                  checked={childRow.rbl_E === 3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Grade 3</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_E" + childIdx + "_" + idx}
                                                                  value={4}
                                                                  checked={childRow.rbl_E === 4}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Grade 4</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_E" + childIdx + "_" + idx}
                                                                  value={5}
                                                                  checked={childRow.rbl_E === 5}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Grade 5</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rbl_E" + childIdx + "_" + idx}
                                                                  value={99}
                                                                  checked={childRow.rbl_E === 99}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.E_empty_err} message={AdverseEvents_Msgs.RadioNoSelection} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }

                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q63_chk_F_1_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_F_1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Outpatient</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q63_chk_F_2_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_F_2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Hospital</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rb_F" + childIdx + "_" + idx}
                                                                  checked={childRow.rb_F}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.F_empty_err} message={AdverseEvents_Msgs.ChkBoxRadNoSelection} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }


                                                    {(childRow.rbl_B != null && childRow.rbl_B !== 1) ?
                                                      (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >

                                                        <>
                                                          <div className="">{/* double-dig-answer */}
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q63_chk_G_1_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_G_1}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Corticosteroids</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q63_chk_G_2_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_G_2}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Anakinra</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q63_chk_G_3_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_G_3}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Tocilizumab</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q63_chk_G_4_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_G_4}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Siltuximab</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={childIdx}
                                                                  name={"q63_chk_G_5_" + childIdx + "_" + idx}
                                                                  checked={childRow.chk_G_5}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Other</span>
                                                              </label>
                                                            </div>
                                                            <div>
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={childIdx}
                                                                  name={"q63_rb_G" + childIdx + "_" + idx}
                                                                  checked={childRow.rb_G}
                                                                  onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Unknown</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <ErrorField show={childRow.G_empty_err} message={AdverseEvents_Msgs.ChkBoxRadNoSelection} style={{ paddingLeft: '0px' }} />
                                                        </>

                                                      </td>) : (<td
                                                        style={{
                                                          border: "1px solid black",
                                                          // alignContent: "flex-start",
                                                          textAlign: "center",
                                                          padding: "8px",
                                                          //textAlign: 'start'
                                                        }}
                                                      >
                                                        <span style={{ textAlign: "center" }} className="not-applicable"><b>Not applicable</b></span>

                                                      </td>)
                                                    }
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                    {row.q63_neurotoxicity_list.length < 500 && row.q63_neurotoxicity_list[row.q63_neurotoxicity_list.length - 1].rbl_B != null && row.q63_neurotoxicity_list[row.q63_neurotoxicity_list.length - 1].rbl_B !== 1 &&
                                      < div className="sub-question-bot-sapce-ul mt-2">
                                        <div className="answer-list-text" onClick={() => AddQ63Row(idx)}>
                                          <a>
                                            <img width="18px" src="../Assets/images/plus.png" alt="" />
                                          </a>
                                          <a className="radio-text-padding">
                                            Click here to add another episode
                                          </a>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </>
                            )}

                          </div>
                        )))}
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default AdverseEvents;

const Q59ProphylacticTherapeutic = ({ parentIndex, childIdx, name, pChecked, sChecked, HandleRepeaterFieldChange, error }) => {
  return (
    <div className="radio-text-padding marginLeft15">
      <label className="long-text-option">
        <input
          type="checkbox"
          id={childIdx}
          name={name + "P_" + childIdx + "_" + parentIndex}
          checked={pChecked}
          onChange={HandleRepeaterFieldChange}
        />
        <span className="radio-text-padding">Prophylactic</span>
      </label>
      <label className="long-text-option">
        <input
          type="checkbox"
          id={childIdx}
          name={name + "T_" + childIdx + "_" + parentIndex}
          checked={sChecked}
          onChange={HandleRepeaterFieldChange}
        />
        <span className="radio-text-padding">Therapeutic</span>
      </label>
      <ErrorField show={error} message={AdverseEvents_Msgs.ChkBoxRadNoSelection} style={{ paddingLeft: '0px' }} />
    </div>

  )
}