import SaveAndNavigate from "../../Components/SaveAndNavigate";
import SideBarMenu from "../../Components/SideBarMenu";
import { HTTPResponse, PageDesignConstants, SideMenuItems, ToastMessageType } from "../../Helpers/Enums";
import PatientDetails from "../../Components/PatientInformation";
import { AddLocalStorageData, DecryptUsingAES256, GetLocalStorageData, GetLoggedInUserID, GetRandomAlphaNumber } from "../../Helpers/Utilities";
import { PatientService } from "../../WebApiServices/Patient.service";
import { AppContext } from "../../Contexts/AppContextProvider";
import { CommonError_Msgs } from "../../Helpers/HelperText";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CenterService } from "../../WebApiServices/Center.service";

const PatientScreening = () => {

    const userId = GetLoggedInUserID() ?? 0;
    let patientData = GetLocalStorageData("patient")
    patientData = patientData && JSON.parse(patientData);

    const patientID = patientData?.patientId ?? 0;
    const centerId = patientData?.ptCenterId ?? 0;
    const abstractorId = patientData?.abstractorId ?? 0;
    const uniqueEncryptedPatientId = patientData?.uniqueEncryptedPatientId ?? 0;
    const surveyDate = patientData?.surveyDate ?? "";
    const rId = patientData?.roundId ?? 0;
    const cRId = patientData?.centerRoundId ?? 0;
    const { ShowToast, ToggleLoader,
    isSideMenuActive,
    setIsSideMenuActive, } = useContext(AppContext)

  const navigatePaths = {
   
     currPage: "/PatientScreening",
     nextPage: "/ChartSelection",
    };

    useEffect(() => {

        async function FetchData() {
            if (!isNaN(patientID) && Number(patientID) !== 0) {

                ToggleLoader(true);
                await LoadData();
                ToggleLoader(false);
            }
        }
        FetchData()
    }, [])


    async function LoadData() {
        try {


            const response = await CenterService.GetCohortTreatmentAndTargets(rId, cRId, patientID);//,pid
            if (response?.status === HTTPResponse.OK) {
                const { data } = response;
                if (data != null) {
                    const { item1, item2 } = data;//item1-> Chart Selection list, item2-> CohortTreatmentList
                    const chartselection = item1.centerTreatmentRoundList;
                    //const cohorttreatment = item2;
                    AddLocalStorageData("ChartSelection", JSON.stringify(chartselection))
                    //AddLocalStorageData("CohortTreatment", JSON.stringify(cohorttreatment))
                }
            }
        } catch (er) {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    }




    const SaveForm = async () => {
        try {
            if (patientID === 0)
                return await AddNewPatient();
            else {
                return true;
            }
        } catch {

        }
    }  /* <summary>
date: 11-07-2024
Name: BV
description: Save New Patient 
<summary>*/
    const AddNewPatient = async () => {
        try {

            ToggleLoader(true)
            const payload = {
                AbstractorId: userId
            }
            const response = await PatientService.AddNewPatient(payload);
            if (response?.status === HTTPResponse.OK) {              
                AddLocalStorageData("patientId", response?.data?.patientId)
                AddLocalStorageData("roundId", response?.data?.roundId)
                    
                const ptArr = {
                    patientId: response?.data?.patientId ?? 0,
                    uniqueEncryptedPatientId: response?.data?.uniqueEncryptedPatientId ?? 0,
                    surveyDate: response?.data?.abstractionDate ?? "",
                    locked: false,
                    ptCenterId: response?.data?.siteId ?? 0,
                    abstractorId: response?.data?.abstractorId ?? 0,
                    roundId: response?.data?.roundId??0,
                    activeRound: response?.data?.activeRound ?? 0,
                    crossWalk: response?.data?.activeRound ?? false,
                    centerRoundId: response?.data?.centerRoundId ?? 0,
                    canEdit: true
                };
                AddLocalStorageData("patient", JSON.stringify(ptArr))

                ToggleLoader(false)
                ShowToast("Patient has been saved successfully", ToastMessageType.Success);
                return true;
            } else {
                throw response.error;
            }
        } catch {
            ToggleLoader(false)
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
        }
    }

    const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");

    const toggleNav = () => {
      if (isSideMenuActive === true  ) { 
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
        setIsSideMenuActive(false)
      } else if( isSideMenuActive===false ){
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
        setIsSideMenuActive(true)
      }
  }

  useEffect(() => {
 const   sidebar = document.getElementById("mySidebar");
  const  main = document.getElementById("main");


    if (isSideMenuActive === true) {
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
      } else {
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
      }
  });
    return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SaveForm} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div >
          <SideBarMenu activePageId={SideMenuItems.PatientScreening} />

          <>
         <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
         </>

          <div className="col-pad-left" id="main">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>PATIENT SCREENING</h4>
                            </div>  

                            <div className="survey-introduction-section">
                                <div className="survey-introduction-content">

                                    {/*<div className="instructions">*/}
                                    {/*    <ul>*/}
                                    {/*        <li className="font-style-spacing">*/}
                                    {/*            Patient ID (Scrambled Unique Identifier):{" "}*/}
                                    {/*            <label className="font-style-value" htmlFor="">*/}
                                    {/*                {uniqueEncryptedPatientId !== 0 && uniqueEncryptedPatientId}*/}
                                    {/*            </label>*/}
                                    {/*        </li>*/}
                                    {/*        <li className="font-style-spacing">*/}
                                    {/*            Center ID (Center Identification Number):{" "}*/}
                                    {/*            <label className="font-style-value" htmlFor="">*/}
                                    {/*                {centerId !== 0 && centerId}*/}
                                    {/*            </label>*/}
                                    {/*        </li>*/}
                                    {/*        <li className="font-style-spacing">*/}
                                    {/*            Abstractor ID (Numeric Identity of Abstractor):{" "}*/}
                                    {/*            <label className="font-style-value" htmlFor="">*/}
                                    {/*                {abstractorId !== 0 && abstractorId}*/}
                                    {/*            </label>*/}
                                    {/*        </li>*/}
                                    {/*        <li className="font-style-spacing">*/}
                                    {/*            Date of Chart Data Abstraction Initiation:{" "}*/}
                                    {/*            <label className="font-style-value" htmlFor="">*/}
                                    {/*                {surveyDate !== 0 && surveyDate}*/}

                                    {/*            </label>*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}
<br/>
                                    <div >
                                        <div>
                                            <p>
                                                Please note that the <b>patient does <u>not</u> have to be alive to be included in the study.</b>{" "}
                                                Patients who are alive or dead can potentially be eligible.{" "}
                                                To be eligible for data extraction, a patient’s chart needs to satisfy all the eligibility criteria listed below: {" "}
                                                <br/><br/>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="note-border-box note-border-box-sec">
                                        <div className="important-note">
                                            <div>
                                                <h5>
                                                    Inclusion Criteria 
                                                </h5>
                                                
                                                <div className="note-list-sec">
                                                    <div className="note-list">
                                                        <span>1.</span>
                                                        <span className="instructon-point-spacing">
                                                            Had a confirmed diagnosis of MM
                                                        </span>
                                                    </div>
                                                    
                                                    <div className="note-list">
                                                        <span>2.</span>
                                                        <span className="instructon-point-spacing">
                                                        <p>
                                                        Treated at the site (or a site within your network) with one of the following treatments for MM:
                                                                DRd, DVRd, DKRd, VRd, or IsaVRd in first-line (1L), or TECVAYLI® (teclistamab-cqyv), TALVEY<sup>TM</sup> (talquetamab-tgvs), ELREXFIO<sup>TM</sup> (elranatamab-bcmm), Linvoseltamab (BCMAxCD3), CARVYKTI® (ciltacabtagene autoleucel), or ABECMA® (idecabtagene vicleucel)  in later lines
                                                        (i.e., not in the 1L setting).{" "}
                                                        Please note that patients should receive the line of therapy (by the time of data abstraction) all in the real world.{" "}
                                                        Those who received any part of these regimens as part of a clinical trial or expanded access program (EAP) do not qualify for the corresponding cohort.{" "}
                                                        For example, patients receiving DVRd induction followed by a maintenance in a clinical trial are ineligible:
                                                        </p>
                                                       
                                                           
                                                            <div className="note-list" style={{ marginLeft:'30px' }}>
                                                            <span>a)</span>
                                                            <span className="instructon-point-spacing">
                                                                For the 1L cohorts (DRd, DVRd, DKRd, VRd, IsaVRd), patients will still be considered as treated with the cohort treatment
                                                                if they received all other drugs except for dexamethasone (Note: dexamethasone may be replaced by prednisone or prednisolone)
                                                            </span>
                                                            </div>

                                                            <div className="note-list" style={{ marginLeft: '30px' }}>
                                                                <span>b)</span>
                                                                <span className="instructon-point-spacing">
                                                            For those in the DRd, DVRd, VRd, or IsaVRD 1L cohorts, patients will still qualify for study inclusion if having received up to one cycle of CyBorD (cyclophosphamide + bortezomib + dexamethasone) before initiating the 1L cohort (Note: the index date will be the start date of DRd, DVRd, VRd, or IsaVRD treatment).
                                                                </span>
                                                            </div>

                                                            
                                                            <div className="note-list" style={{ marginLeft: '30px' }}>
                                                            <span>c)</span>
                                                            <span className="instructon-point-spacing">
                                                                For bispecific and CAR-T cohorts, treatment can include monotherapy or be part of a combination therapy
                                                            </span>
                                                        </div>
                                                            
                                                            <div className="note-list" style={{ marginLeft: '30px' }}>
                                                            <span>d)</span>
                                                            <span className="instructon-point-spacing">
                                                                For the CAR-T cohorts (CARVYKTI® (ciltacabtagene autoleucel) and ABECMA® (idecabtagene vicleucel)), patients are required to have received CAR-T infusion.{" "}
                                                                Patients who only received apheresis but did not receive infusion do not meet this criterion
                                                            </span>
                                                        </div>
                                                            
                                                            <div className="note-list" style={{ marginLeft: '30px' }}>
                                                            <span>e)</span>
                                                            <span className="instructon-point-spacing">
                                                            For DRd (FDA approval date: June 27, 2019), TECVAYLI® (teclistamab-cqyv) (FDA approval date: October 25, 2022),
                                                                    TALVEY<sup>TM</sup> (talquetamab-tgvs) (FDA approval date: August 9, 2023), ELREXFIO<sup>TM</sup> (elranatamab-bcmm) (FDA approval date: August 14, 2023),
                                                                CARVYKTI® (ciltacabtagene autoleucel) (FDA approval date: February 28, 2022), and ABECMA® (idecabtagene vicleucel) (FDA approval date: March 26, 2021),
                                                                patients will be required to initiate the treatment regimen on or after the approval date by the FDA.{" "}
                                                                For DVRd, DKRd, and VRd, patients are required to have initiated these regimens on or after January 1, 2020.{" "}
                                                                For IsaVRd and Linvoseltamab (BCMAxCD3), patients will be required to initiate the treatment regimen on or after the approval date by the FDA in the future,
                                                                and the data collection for those cohorts will not start until these regimens are approved by the FDA.{" "}
                                                            </span>
                                                            </div>
                                                                

                                                        </span>
                                                    </div>
                                                    <div className="note-list">
                                                        <span>3.</span>
                                                        <span className="instructon-point-spacing">
                                                        Had the required transplant stem cell transplant (SCT) eligibility status prior to the receipt of 1L treatment (applicable to 1L cohorts only).{" "}
                                                        Transplant eligibility status is required to be documented in the chart or be able to be inferred by the chart abstractor.{" "}
                                                        Transplant eligible (TE) patients include any patient who received autologous or allogeneic SCT while on 1L treatment or who had stem cells collected and stored after initiating and while on 1L treatment. {" "}
                                                        </span>
                                                        </div>
                                                    
                                                    <div className="note-list">
                                                        <span>4.</span>
                                                        <span className="instructon-point-spacing">
                                                            Age ≥18 on the date of initiation of the patient’s cohort treatment with therapy (<b>index date</b>)
                                                        </span>
                                                    </div>
                                                    
                                                    <div className="note-list">
                                                        <span>5.</span>
                                                        <span className="instructon-point-spacing">
                                                            Had ≥1 follow up assessment after the index date
                                                        </span>
                                                    </div>
                                                    
                                                </div> 
                                                <br />
                                                <h5>Exclusion Criteria </h5>
                                                
                                                <div className="note-list-sec">
                                                    <div className="note-list">
                                                        <span>1.</span>
                                                        <span className="instructon-point-spacing">
                                                            Received index treatment in a clinical trial or expanded access program (EAP)
                                                        </span>
                                                    </div>
                                                    
                                                    <div className="note-list">
                                                        <span>2.</span>
                                                        <span className="instructon-point-spacing">
                                                         Treated with any systemic treatments for MM (excluding steroids, one-time monotherapy of cyclophosphamide,
                                                            local radiotherapy to stabilize bone lesions, etc.) within 6 months prior to the index date (applicable to 1L cohorts only)
                                                        </span>
                                                    </div>
                                                    
                                                    <div className="note-list">
                                                        <span>3.</span>
                                                        <span className="instructon-point-spacing">
                                                            Received bone marrow transplant such as autologous or allogeneic SCT prior to index date (applicable to 1L cohorts only)
                                                        </span>
                                                    </div>
                                                    
                                                    <div className="note-list">
                                                        <span>4.</span>
                                                        <span className="instructon-point-spacing">
                                                            Diagnosed with amyloid light-chain amyloidosis any time on or prior to the index date (applicable to 1L cohorts only)
                                                        </span>
                                                    </div>
                                                    
                                                    <div className="note-list">
                                                        <span>5.</span>
                                                        <span className="instructon-point-spacing">
                                                        Had any invasive malignancy other than MM prior to the index date (except basal and squamous carcinomas as well as carcinomas in situ [e.g., breast, cervical, prostate, etc.])
                                                            (applicable to 1L cohorts only)
                                                        </span>
                                                    </div>
                                                    
                                                    <div className="note-list">
                                                        <span>6.</span>
                                                        <span className="instructon-point-spacing">
                                                            Diagnosed with smoldering MM only
                                                        </span>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SaveForm} />
                            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PatientScreening;