import React, { useState, useContext, useRef, useEffect } from "react";
import { AppContext } from "../Contexts/AppContextProvider";
import { HTTPResponse, ToastMessageType } from "../Helpers/Enums";
import { UserLoginService } from "../WebApiServices/UserLogin.service";
import { ResetPasswordMessages, UserLoginMessage } from "../Helpers/HelperText";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    AddLocalStorageData,
    IsEmailValid,
    IsPasswordValid,
    isStringEmpty,
} from "../Helpers/Utilities";
import ErrorField from "../Components/ErrorField";

const ResetPassword = () => {
    const { ShowToast, ToggleLoader } = useContext(AppContext);
    const navigate = useNavigate();

    const [fields, setFields] = useState({
        password: "",
        confirmPassword: "",
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [name, setName] = useState("");
    const [searchParams] = useSearchParams();
    const gUID = searchParams.get("GUID");

    const INITIAL_FIELDERROR = {
        passwordEmpty: false,
        confirmPasswordEmpty: false,
        passwordMismatch: false,
        passwordInvalid: false,
    };
    const [fieldError, setFieldError] = useState(INITIAL_FIELDERROR);
    const [linkInactive, setLinkInactive] = useState(false);
    const emailRef = useRef("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const FetchData = async () => {
            ToggleLoader(true)
            await GetEmployeeInfo();
            ToggleLoader(false)
            setIsLoading(false)
        }
        FetchData();
    }, []);



    const GetEmployeeInfo = async () => {
        try {
            const { status, data } = await UserLoginService.AuthenticateUserForPasswordReset(gUID);
            if (status === HTTPResponse.OK) {
                setName(data?.employeeName ?? "");
                emailRef.current = data?.emailAddress
            } else {
                setLinkInactive(true)
            }
        } catch (error) {
            setLinkInactive(true)
        }
    };
    const HandleFieldChange = (e) => {

        const { name, value } = e.target;
        switch (name) {
            case "password":
            case "confirmPassword": {
                setFields((prev) => ({
                    ...prev,
                    [name]: value,
                }));
                setFieldError(INITIAL_FIELDERROR);
                break;
            }
            default:
                break;
        }
    };
    const Validate = () => {
        let isValid = true;
        const { password, confirmPassword } = fields;
        //Returns true if field does not hold validation
        const validateField = (field, value) => {
            //CHK IF EMPTY
            if (isStringEmpty(value)) {
                setFieldError((prev) => ({
                    ...prev,
                    [field + "Empty"]: true,
                }));
                isValid = false;
            } else {
                //CHK password validity
                if (field === "password") {
                    if (!IsPasswordValid(value)) {
                        setFieldError((prev) => ({
                            ...prev,
                            passwordInvalid: true,
                        }));
                        isValid = false;
                    }
                } else if (field === "confirmPassword") {
                    //CHK MATCH
                    if (password !== confirmPassword) {
                        setFieldError((prev) => ({
                            ...prev,
                            passwordMismatch: true,
                        }));
                        isValid = false;
                    }
                }
            }
        };

        //Validate EACH field
        validateField("password", password);
        validateField("confirmPassword", confirmPassword);

        //if (!validateField("password", password)) isValid = false;
        return isValid;
    };
    const OnResetButtonClicked = (e) => {
        e.preventDefault();
        if (Validate()) {
            HandleReset();
        }
    };
    const HandleReset = async () => {
        try {
            ToggleLoader(true)
            const { status } = await UserLoginService.ResetPassword({
                gUID: gUID,
                newPassword: fields.password,
                email: emailRef.current,
            });
            ToggleLoader(false)
            if (status === HTTPResponse.OK) {
                navigate("/"); //redirect to login page
            } else {
                throw ResetPasswordMessages.EMAIL_NOTEXISTS;
            }
        } catch (error) {
            ShowToast(error, ToastMessageType.Failed);
        }
    };

    return (
        isLoading ? <></> :
            <div className="gradient-custom reset-password">
                {linkInactive ? (
                    <div className="clean-error">
                        {ResetPasswordMessages.LINK_EXPIRED}
                    </div>
                ) : (
                    <div className="container py-5 custom-container">

                        <div className="row justify-content-center align-items-center h-100">
                            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                                <div
                                    className="card bg-white text-black"
                                    style={{ borderRadius: "1rem", minHeight: 100 }}
                                >
                                    <div className="card-body p-4">
                                        <div className="mb-md-2 mt-md-2 pb-2">
                                            <h6 className="fw-bold mb-4 login-heading">
                                                Welcome <span>{name}</span>
                                            </h6>
                                            <div className="form-outline form-white mb-4">
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    className="form-control"
                                                    name="password"
                                                    required=""
                                                    id="password-id"
                                                    placeholder="Enter New Password"
                                                    value={fields.password}
                                                    onChange={HandleFieldChange}
                                                />
                                                <div
                                                    className="toggle-password"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? (
                                                        <i className="fa fa-eye" id="togglePassword"></i>
                                                    ) : (
                                                        <i className="fa fa-eye fa-eye-slash"></i>
                                                    )}
                                                </div>
                                               <div>   {/*style={{ textIndent: "-15px" }} */}
                                                    <ErrorField
                                                        show={
                                                            fieldError.passwordEmpty || fieldError.passwordInvalid
                                                        }
                                                        message={
                                                            fieldError.passwordInvalid
                                                                ? ResetPasswordMessages.PASSWORD_INVALID
                                                                : fieldError.passwordEmpty
                                                                    ? ResetPasswordMessages.PASSWORD_BLANK
                                                                    : ""
                                                        }
                                                        style={{ paddingLeft: '0px' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-outline form-white margin-bottom-36">
                                                <input
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    className="form-control"
                                                    name="confirmPassword"
                                                    required
                                                    id="confirm-password-id"
                                                    placeholder="Re-Enter New Password"
                                                    value={fields.confirmPassword}
                                                    onChange={HandleFieldChange}
                                                />
                                                <div
                                                    className="toggle-password"
                                                    onClick={() =>
                                                        setShowConfirmPassword(!showConfirmPassword)
                                                    }
                                                >
                                                    {showConfirmPassword ? (
                                                        <i className="fa fa-eye" id="togglePassword"></i>
                                                    ) : (
                                                        <i className="fa fa-eye fa-eye-slash"></i>
                                                    )}
                                                </div>
                                                <div>{/* style={{ marginLeft: "-15px" }} */}
                                                    <ErrorField
                                                        show={
                                                            fieldError.confirmPasswordEmpty ||
                                                            fieldError.passwordMismatch
                                                        }
                                                        message={
                                                            fieldError.passwordMismatch
                                                                ? ResetPasswordMessages.PASSWORD_MISMATCH
                                                                : fieldError.confirmPasswordEmpty
                                                                    ? ResetPasswordMessages.CONFIRM_PASSWORD_BLANK
                                                                    : ""
                                                        }
                                                        style={{ paddingLeft: '0px' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-center mb-2">
                                                <center>
                                                    <button disabled={!fields.password || !fields.confirmPassword}
                                                        className="btn btn-outline-light btn-lg px-5 login-button"
                                                        style={{ backgroundColor: "#3A8AC7", fontSize: "15px" }}
                                                        type="submit"
                                                        onClick={OnResetButtonClicked}
                                                    >
                                                        Reset Password
                                                    </button>
                                                </center>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
    );
};

export default ResetPassword;
