import React, { useContext, useEffect, useState } from 'react'
import PatientDetails from '../../Components/PatientInformation';
import SaveAndNavigate from '../../Components/SaveAndNavigate';
import { PageDesignConstants, SideMenuItems, HTTPResponse, ToastMessageType, ToastMessages, LOT, RRMMCohortTreatment } from '../../Helpers/Enums';
import SideBarMenu from '../../Components/SideBarMenu';
import ErrorField from "../../Components/ErrorField";
import { CommonError_Msgs, hyperlinkTextLineOfTherapy, NonCohortTreatment_Msgs } from "../../Helpers/HelperText";
import { AppContext } from "../../Contexts/AppContextProvider";
import { GetLocalStorageData, GetLoggedInUserID, GetOrdinal, IsDateAfter, isStringEmpty } from "../../Helpers/Utilities";
import DateControl from "../../Components/DateControl";
import { NonCohortTreatmentService } from "../../WebApiServices/NonCohortTreatment.service";
import InfoPopUp from '../../Components/InfoPopUp';

const NonCohortTreatment = () => {
    const [navigatePaths, setNavigatePaths] = useState({
        prevPage: "/LineOfTherapy",
        currPage: "/NonCohortDefiningTreatment",
        nextPage: "/NDMMSCTCharacteristics"
    });
    const storedData = GetLocalStorageData("CohortTreatment");
    let cohortTreatmentModelListt = [];

    if (storedData) {
        cohortTreatmentModelListt = JSON.parse(storedData);
        cohortTreatmentModelListt = cohortTreatmentModelListt.filter(c => c.isEligible);
    }

    const SetNavPaths = () => {
        let ndmmCohorts = cohortTreatmentModelListt.filter(c => c.ndmm);
        let rrmmCohorts = cohortTreatmentModelListt.filter(c => !c.ndmm);

        //navigatePaths.prevPage = rrmmCohorts.length > 0 ? "/LineOfTherapy" : "/NDMMTreatmentCohort";

        let newPaths = { ...navigatePaths };

        if (ndmmCohorts.length > 0) {
            newPaths.nextPage = "/NDMMSCTCharacteristics";
        } else if (rrmmCohorts.length > 0) {

            const isTacOrTal = rrmmCohorts.some(a =>
                a.cohortTreatmentID === RRMMCohortTreatment.TALVEY || a.cohortTreatmentID === RRMMCohortTreatment.TECVAYLI
            );
            if (isTacOrTal) {
                newPaths.nextPage = "/TTCharacteristics";
            } else {
                const isAbeOrCar = rrmmCohorts.some(a =>
                    a.cohortTreatmentID === RRMMCohortTreatment.ABECMA ||
                    a.cohortTreatmentID === RRMMCohortTreatment.CARVYKTI
                );
                if (isAbeOrCar) {
                    newPaths.nextPage = "/CarTCharacteristics";
                } else {
                    newPaths.nextPage = "/IMWGReporting";
                }
            }
        }
        setNavigatePaths(newPaths);
    }

    const pid = GetLocalStorageData("patientId") ?? 0;
    const { ShowToast, ToggleLoader, HandleSessionTimeout,ShowActionPopUp, isSideMenuActive, setIsSideMenuActive } = useContext(AppContext);
    const [showTopErrMsg, setShowTopErrMsg] = useState(false);
    const [NDMM, setNDMM] = useState({
        cohortTreatmentId: 0,
        cohortTreatmentName: "NDMM",
        superScriptText: "",
        // lineOfTherapy: null,
        cohortIndexDate: "",
        lastAdminDate: null,
    });
    const [isNdmmSelected, setIsNdmmSelected] = useState(false);
    const [showHideHyperlinkModal, setShowHideHyperlinkModal] = useState(false);

    const Q27Row = {
        // nonCohortCharacteristicsId: null,
        cohortTreatmentId: null,//not req
        lotId: null,
        lotName: "",//not req
        //A
        rbl_lot_num_select: null,
        //B
        rbl_lot_start_date: null,
        date_lot_start: null,
        //C
        rbl_received_clinical_trial: null,
        //D
        selectedPrescribedTmtList: [],
        // txt_prescribed_tmt_oth: "",
        rb_prescribed_tmt_unk: false,

        //errors
        A_empty: false,
        B_empty: false,
        B_DateBeforeLot2: false,
        B_DateBeforeIndex: false,
        B_DateBeyondEOF: false,
        C_empty: false,
        D_empty: false,
        D_txt_empty: false,
    }
    const [q27AddInfoDetails, setQ27AddInfoDetails] = useState();//[Q27Row]
    const [nonCohortDetails, setNonCohortDetails] = useState({
        q28_rbl_B: null,
        q28_B_empty_err: false,//Error
        q28_B_invalid_combo_err: false,//Error
        q28_date_C: null,
        q28_rbl_C: null,
        q28_C_before_q27_B_err: false,//Error q28_C_beyond_ndmm_2rd_LOT_err
        q28_C_beyond_ndmm_3rd_LOT_err: false,//Error
        q28_C_beyond_eof_err: false,//Error
        q28_C_empty_err: false,//Error
        q28_rbl_D: null,
        q28_D_empty_err: false,//Error  
        q28_chk_E_1: false,
        q28_chk_E_2: false,
        q28_chk_E_3: false,
        q28_chk_E_4: false,
        q28_chk_E_5: false,
        q28_rb_E_unk: false,
        q28_E_empty_err: false,//Error
        q28_chk_F_1: false,
        q28_chk_F_2: false,
        q28_chk_F_3: false,
        q28_chk_F_4: false,
        q28_chk_F_5: false,
        q28_chk_F_6: false,
        q28_chk_F_7: false,
        q28_rb_F_unk: false,
        q28_F_empty_err: false,//Error

        q29_rbl_B: null,
        q29_B_empty_err: false,//Error
        q29_date_C: null,
        q29_rbl_C: null,
        q29_C_before_q27_B_err: false,//Error
        q29_C_beyond_eof_err: false,//Error
        q29_C_empty_err: false,//Error
        q29_rbl_D: null,
        q29_D_empty_err: false,//Error   
        q29_chk_E_1: false,
        q29_chk_E_2: false,
        q29_chk_E_3: false,
        q29_chk_E_4: false,
        q29_chk_E_5: false,
        q29_rb_E_unk: false,
        q29_E_empty_err: false,//Error
        q29_rbl_F: null,
        q29_F_empty_err: false,//Error
        // q29_F_invalid_combo1_err: false,//Error
        // q29_F_invalid_combo2_err: false,//Error

        q30_chk_1: false,
        q30_chk_2: false,
        q30_chk_3: false,
        q30_chk_4: false,
        q30_chk_5: false,
        q30_chk_6: false,
        q30_chk_7: false,
        q30_rb_unk: false,
        q30_empty_err: false,//Error
    });
    const [endOfFollowUpDate, setEndOfFollowUpDate] = useState(null);//Date from Cohort Defining page Q20

    //Q31,32,33 loop
    const RepeaterRow = {
        cohortTreatmentId: 0,
        cohortTreatmentName: "Bispecifics/CAR-T",
        superScriptText: "",
        lineOfTherapy: null,
        cohortIndexDate: "",

        bispecificsLotPlus1: null,// "Bispecifics/CAR-T LOT + 1",
        bispecificsLotPlus2: null,//"Bispecifics/CAR-T LOT + 2",
        // endOfFollowUpDate: null,

        q31_rbl_A: null,
        q31_date_B: null,
        q31_rbl_B: null,
        q31_rbl_C: null,
        q31_D_selectedPrescribedTmtList: [],
        q31_D_rb_unk: false,
        //Errors
        q31_A_empty_err: false,
        q31_B_empty_err: false,
        q31_B_beyond_eof_err: false,
        q31_B_before_index_err: false,
        q31_C_empty_err: false,
        q31_D_empty_err: false,
        q31_D_txt_empty_err: false,


        q32_rbl_B: null,
        q32_B_empty_err: false,//Error

        q32_date_C: null,
        q32_rbl_C: null,
        q32_C_before_q31_B_err: false,//Error
        q32_C_beyond_eof_err: false,//Error
        q32_C_empty_err: false,//Error

        q32_rbl_D: null,
        q32_D_empty_err: false,//Error

        q32_chk_E_1: false,
        q32_chk_E_2: false,
        q32_chk_E_3: false,
        q32_chk_E_4: false,
        q32_chk_E_5: false,
        q32_rb_E_unk: false,
        q32_E_empty_err: false,//Error

        q32_rbl_F: null,
        q32_F_empty_err: false,//Error
        // q32_F_invalid_combo1_err: false,//Error
        // q32_F_invalid_combo2_err: false,//Error

        q33_chk_1: false,
        q33_chk_2: false,
        q33_chk_3: false,
        q33_chk_4: false,
        q33_chk_5: false,
        q33_chk_6: false,
        q33_chk_7: false,
        q33_rb_unk: false,
        q33_empty_err: false,//Error

    };

    const [bispecificsRptList, setBispecificsRptList] = useState([]);




    const [prescribedTreatments, setPrescribedTreatments] = useState([]);//static list of prescribed treatments
    const [prescribedTmtOtherID, setPrescribedTmtOtherID] = useState(null);


    useEffect(() => {
        async function FetchData() {
            SetNavPaths();

            if (!isNaN(pid) && Number(pid) !== 0) {
                ToggleLoader(true);
                await LoadData();
                ToggleLoader(false);
            }
        }
        FetchData();
    }, [])

    const LoadData = async () => {
        try {
            if (pid > 0) {
                const response = await NonCohortTreatmentService.GetNonCohortTreatmentData(pid);
                if (response?.status !== HTTPResponse.OK) {
                    if (response?.status === HTTPResponse.Unauthorized)
                        HandleSessionTimeout();
                    else if (response?.status === HTTPResponse.BadRequest)
                        throw response.error;
                } else {
                    const { data } = response;
                    if (data != null) {
                        const { nonCohortNdmmModel, nonCohortBispecificList, prescribedTreatmentList, cohortTreatmentList, endOfFollowUpDate, lastAdminDate } = data;
                        if (prescribedTreatmentList != null && prescribedTreatmentList.length > 0) {

                            setPrescribedTreatments(prescribedTreatmentList);

                            //Get the ID of "Other" checkbox
                            const othTmt = prescribedTreatmentList.find(item => item.category === null)
                                              ?.treatments.find(tmt => tmt.name === "Other, please specify");
                            if (othTmt) {
                                setPrescribedTmtOtherID(othTmt.prescribedTreatmentId);
                            }
                        }

                        if (cohortTreatmentList && cohortTreatmentList.length > 0) {

                            setEndOfFollowUpDate(endOfFollowUpDate);

                            const ndmmTmt = cohortTreatmentList.find(a => a.ndmm);// there'll be only 1 ndmm
                            const rrmmTmtList = cohortTreatmentList.filter(a => !a.ndmm);

                            //Ndmm Cohort present
                            if (ndmmTmt) {

                                setIsNdmmSelected(true);
                                setNDMM({
                                    cohortTreatmentName: ndmmTmt.cohortTreatmentName,
                                    superScriptText: ndmmTmt.cohortTreatmentNameSuperscript,
                                    cohortTreatmentId: ndmmTmt.cohortTreatmentID,
                                    // lineOfTherapy: ndmmTmt.lineOfTherapy,
                                    cohortIndexDate: cohortTreatmentModelListt?.find(a => a.cohortTreatmentID === ndmmTmt.cohortTreatmentID)?.cohortIndexDate,
                                    lastAdminDate: lastAdminDate,
                                });
                                const additionalInfoList = nonCohortNdmmModel.additionalInfoList;//data in db

                                let Q27List = [];

                                //data present in db
                                if (additionalInfoList && additionalInfoList.length > 0) {

                                    // const additionalInfoList_filter = additionalInfoList.filter(a => a.cohortTreatmentId === ndmmTmt.cohortTreatmentID);
                                    Q27List = additionalInfoList.map(a => {

                                        return {
                                            ...Q27Row,
                                            // nonCohortCharacteristicsId: a.nonCohortCharacteristicsId || 0,
                                            lotId: a.lotNumberId || 0,
                                            lotName: a.lotName,//not reqd
                                            cohortTreatmentId: ndmmTmt.cohortTreatmentID,//not reqd

                                            //A
                                            rbl_lot_num_select: a.notReceivedLot ? 2 : a.notReceivedLot === false ? 1 : null,
                                            //B
                                            rbl_lot_start_date: a.lotStartDateUk ? 99 : !isStringEmpty(a.lotStartDate) ? 1 : null,
                                            date_lot_start: a.lotStartDate,
                                            //C
                                            rbl_received_clinical_trial: a.receivedClinicalTrial,
                                            //D
                                            selectedPrescribedTmtList: a.selectedPrescribedTreatmentList ?? [],
                                            rb_prescribed_tmt_unk: a.prescribedTreamentUnk
                                        };
                                    })
                                    setQ27AddInfoDetails(Q27List);
                                    //Set Q28, 29 n 30
                                    const lotTwo = additionalInfoList.find(a => a.lotNumberId === LOT.LOT2);
                                    const lotThree = additionalInfoList.find(a => a.lotNumberId === LOT.LOT3);

                                    if (lotTwo) {
                                        //Q28 B, //Q28 C, //Q28 D, //Q28 E, //Q28 F
                                        setNonCohortDetails(prev => ({
                                            ...prev,
                                            q28_rbl_B: lotTwo.discontinuedLottreament,

                                            q28_date_C: lotTwo.lotDiscontinuedDate,//??null 
                                            q28_rbl_C: lotTwo.lotDiscontinuedDateUk ? 99 : !isStringEmpty(lotTwo.lotDiscontinuedDate) ? 1 : null,

                                            q28_rbl_D: lotTwo.refactoryAgents,

                                            q28_chk_E_1: lotTwo.agentRevlimid,
                                            q28_chk_E_2: lotTwo.agentPi,
                                            q28_chk_E_3: lotTwo.agentMabs,
                                            q28_chk_E_4: lotTwo.agentBcma,
                                            q28_chk_E_5: lotTwo.agentOther,
                                            q28_rb_E_unk: lotTwo.agentUnk,

                                            q28_chk_F_1: lotTwo.advancementReasonAdverseevent,
                                            q28_chk_F_2: lotTwo.advancementReasonDiseaseprogression,
                                            q28_chk_F_3: lotTwo.advancementReasonWithoutprogression,
                                            q28_chk_F_4: lotTwo.advancementReasonInsurance,
                                            q28_chk_F_5: lotTwo.advancementReasonPatientdecision,
                                            q28_chk_F_6: lotTwo.advancementReasonPhysiciandecision,
                                            q28_chk_F_7: lotTwo.advancementReasonOther,
                                            q28_rb_F_unk: lotTwo.advancementReasonUnknown,

                                            //Reset errors Sifter 11900
                                            q28_B_empty_err: false,//Error
                                            q28_B_invalid_combo_err: false,//Error
                                            q28_C_before_q27_B_err: false,//Error q28_C_beyond_ndmm_2rd_LOT_err
                                            q28_C_beyond_ndmm_3rd_LOT_err: false,//Error
                                            q28_C_beyond_eof_err: false,//Error
                                            q28_C_empty_err: false,//Error
                                            q28_D_empty_err: false,//Error 
                                            q28_E_empty_err: false,//Error
                                            q28_F_empty_err: false,//Error
                                        }))
                                    }
                                    if (lotThree) {
                                        //Q29 B, //Q29 C, //Q29 D, //Q29 E,//Q29 F, Q30
                                        setNonCohortDetails(prev => ({
                                            ...prev,
                                            q29_rbl_B: lotThree.discontinuedLottreament,
                                            q29_date_C: lotThree.lotDiscontinuedDate,//??null,
                                            q29_rbl_C: lotThree.lotDiscontinuedDateUk ? 99 : !isStringEmpty(lotThree.lotDiscontinuedDate) ? 1 : null,
                                            q29_rbl_D: lotThree.refactoryAgents,
                                            q29_chk_E_1: lotThree.agentRevlimid,
                                            q29_chk_E_2: lotThree.agentPi,
                                            q29_chk_E_3: lotThree.agentMabs,
                                            q29_chk_E_4: lotThree.agentBcma,
                                            q29_chk_E_5: lotThree.agentOther,
                                            q29_rb_E_unk: lotThree.agentUnk,

                                            q29_rbl_F: lotThree.advanceToNextlineTherapy,

                                            q30_chk_1: lotThree.advancementReasonAdverseevent,
                                            q30_chk_2: lotThree.advancementReasonDiseaseprogression,
                                            q30_chk_3: lotThree.advancementReasonWithoutprogression,
                                            q30_chk_4: lotThree.advancementReasonInsurance,
                                            q30_chk_5: lotThree.advancementReasonPatientdecision,
                                            q30_chk_6: lotThree.advancementReasonPhysiciandecision,
                                            q30_chk_7: lotThree.advancementReasonOther,
                                            q30_rb_unk: lotThree.advancementReasonUnknown,

                                            //Reset errors Sifter 11900                                            
                                            q28_F_empty_err: false,//Error
                                            q29_B_empty_err: false,//Error
                                            q29_C_before_q27_B_err: false,//Error
                                            q29_C_beyond_eof_err: false,//Error
                                            q29_C_empty_err: false,//Error
                                            q29_D_empty_err: false,//Error 
                                            q29_E_empty_err: false,//Error
                                            q29_F_empty_err: false,//Error
                                            // q29_F_invalid_combo1_err: false,//Error
                                            // q29_F_invalid_combo2_err: false,//Error
                                            q30_empty_err: false,//Error
                                        }))
                                    }
                                } else {
                                    Q27List = [{
                                        ...Q27Row,
                                        cohortTreatmentId: ndmmTmt.cohortTreatmentID,//not reqd
                                        lotId: LOT.LOT2,
                                    }];
                                    setQ27AddInfoDetails(Q27List);
                                }
                            }
                            //Rrmm Cohorts present
                            if (rrmmTmtList && rrmmTmtList.length > 0) {

                                let list = [];
                                list = rrmmTmtList.map(ct => {

                                    const bispecificsLotPlus1 = !isStringEmpty(ct.lineOfTherapy) ? ct.lineOfTherapy + 1 : null;
                                    const bispecificsLotPlus2 = !isStringEmpty(ct.lineOfTherapy) ? ct.lineOfTherapy + 2 : null;

                                    //Check if data is present for a cohort tmt
                                    const dbRow = //nonCohortBispecificList && nonCohortBispecificList.length > 0 &&
                                        nonCohortBispecificList?.find(a => a.cohorttreatmentid === ct.cohortTreatmentID)

                                    if (dbRow) {
                                        return {
                                            ...RepeaterRow,
                                            cohortTreatmentId: ct.cohortTreatmentID,
                                            cohortTreatmentName: ct.cohortTreatmentName,
                                            superScriptText: ct.cohortTreatmentNameSuperscript,
                                            lineOfTherapy: ct.lineOfTherapy,
                                            cohortIndexDate: cohortTreatmentModelListt?.find(a => a.cohortTreatmentID === ct.cohortTreatmentID)?.cohortIndexDate,

                                            bispecificsLotPlus1,
                                            bispecificsLotPlus2,
                                            // endOfFollowUpDate: null,

                                            q31_rbl_A: dbRow.additionalLotnumber ? 1 : dbRow.additionallotNotreceived ? 2 : null,
                                            q31_date_B: dbRow.lotStartdate,//??null
                                            q31_rbl_B: dbRow.lotStartdateUk ? 99 : !isStringEmpty(dbRow.lotStartdate) ? 1 : null,
                                            q31_rbl_C: dbRow.receivedClinicaltrial,
                                            q31_D_selectedPrescribedTmtList: dbRow.selectedPrescribedTreatmentList ?? [],
                                            q31_D_rb_unk: dbRow.prescribedTreamentUnk,

                                            q32_rbl_B: dbRow.discontinuedLottreament,

                                            q32_date_C: dbRow.lotDiscontinuedDate,//??null
                                            q32_rbl_C: dbRow.lotDiscontinuedDateUk ? 99 : !isStringEmpty(dbRow.lotDiscontinuedDate) ? 1 : null,

                                            q32_rbl_D: dbRow.refactoryAgents,

                                            q32_chk_E_1: dbRow.agentRevlimid,
                                            q32_chk_E_2: dbRow.agentPi,
                                            q32_chk_E_3: dbRow.agentMabs,
                                            q32_chk_E_4: dbRow.agentBcma,
                                            q32_chk_E_5: dbRow.agentOther,
                                            q32_rb_E_unk: dbRow.agentUnk,

                                            q32_rbl_F: dbRow.advanceNexttherapyOngoing ? 1 :
                                                dbRow.advanceNexttherapyPatientSubsequentlot ? 2 :
                                                    dbRow.advanceNexttherapyNoSubsequentlot ? 3 :
                                                        dbRow.advanceNexttherapyUnknown ? 99 : null,

                                            q33_chk_1: dbRow.advancementReasonAdverseevent,
                                            q33_chk_2: dbRow.advancementReasonDiseaseprogression,
                                            q33_chk_3: dbRow.advancementReasonWithoutprogression,
                                            q33_chk_4: dbRow.advancementReasonInsurance,
                                            q33_chk_5: dbRow.advancementReasonPatientdecision,
                                            q33_chk_6: dbRow.advancementReasonPhysiciandecision,
                                            q33_chk_7: dbRow.advancementReasonOther,
                                            q33_rb_unk: dbRow.advancementReasonUnknown,
                                        };
                                    } else {
                                        return {
                                            ...RepeaterRow,
                                            cohortTreatmentId: ct.cohortTreatmentID,
                                            cohortTreatmentName: ct.cohortTreatmentName,
                                            superScriptText: ct.cohortTreatmentNameSuperscript,
                                            lineOfTherapy: ct.lineOfTherapy,
                                            cohortIndexDate: cohortTreatmentModelListt?.find(a => a.cohortTreatmentID === a.cohortTreatmentID)?.cohortIndexDate,

                                            bispecificsLotPlus1,
                                            bispecificsLotPlus2,
                                        }
                                    }
                                })
                                setBispecificsRptList(list);
                            }
                        }
                    }

                }
            }
        } catch (er) {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    }
    const SavePage = async (validate) => {
        try {

            ToggleLoader(true);

            let isValid = validate ? Validate() : true;

            if (isValid) {
                setShowTopErrMsg(false)

                let payload = {
                    patientId: pid,
                    NonCohortNdmmModel: {
                        CohortTreatmentId: NDMM.cohortTreatmentId,
                        AdditionalInfoList: []
                    },
                    NonCohortBispecificList: [],

                    UserId: GetLoggedInUserID(),
                    IsValidated: validate
                }

                if (q27AddInfoDetails && q27AddInfoDetails.length > 0) {

                    payload.NonCohortNdmmModel.AdditionalInfoList = q27AddInfoDetails.map(a => ({

                        // NonCohortCharacteristicsId: a.nonCohortCharacteristicsId,
                        // CohortTreatmentId: a.cohortTreatmentId,
                        LotNumberId: a.lotId,
                        NotReceivedLot: a.rbl_lot_num_select === 2 ? true : a.rbl_lot_num_select === 1 ? false : null,
                        LotStartDate: a.date_lot_start,
                        LotStartDateUk: a.rbl_lot_start_date === 99,
                        ReceivedClinicaltrial: a.rbl_received_clinical_trial,

                        PrescribedTreamentUnk: a.rb_prescribed_tmt_unk,
                        SelectedPrescribedTreatmentList: a.selectedPrescribedTmtList.map(x => ({
                            ...x,
                            PrescribedTreatmentId: x.prescribedTreatmentId,
                            PrescribedTreatmentOthTxt: x.PrescribedTreatmentOthTxt,
                        })),
                        //Q28 B, Q29 B
                        DiscontinuedLottreament: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_rbl_B : a.lotId == LOT.LOT3 ? nonCohortDetails.q29_rbl_B : null,
                        //Q28 B, Q29 C
                        LotDiscontinuedDate: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_date_C : a.lotId == LOT.LOT3 ? nonCohortDetails.q29_date_C : null,
                        LotDiscontinuedDateUk: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_rbl_C === 99 : a.lotId == LOT.LOT3 ? nonCohortDetails.q29_rbl_C === 99 : null,
                        //Q28 D, Q29 D 
                        RefactoryAgents: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_rbl_D : a.lotId == LOT.LOT3 ? nonCohortDetails.q29_rbl_D : null,
                        //Q28 E, Q29 E
                        AgentRevlimid: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_chk_E_1 : a.lotId == LOT.LOT3 ? nonCohortDetails.q29_chk_E_1 : null,
                        AgentPi: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_chk_E_2 : a.lotId == LOT.LOT3 ? nonCohortDetails.q29_chk_E_2 : null,
                        AgentMabs: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_chk_E_3 : a.lotId == LOT.LOT3 ? nonCohortDetails.q29_chk_E_3 : null,
                        AgentBcma: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_chk_E_4 : a.lotId == LOT.LOT3 ? nonCohortDetails.q29_chk_E_4 : null,
                        AgentOther: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_chk_E_5 : a.lotId == LOT.LOT3 ? nonCohortDetails.q29_chk_E_5 : null,
                        AgentUnk: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_rb_E_unk : a.lotId == LOT.LOT3 ? nonCohortDetails.q29_rb_E_unk : null,
                        //Q28 F, Q30
                        AdvancementReasonAdverseevent: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_chk_F_1 : a.lotId == LOT.LOT3 ? nonCohortDetails.q30_chk_1 : null,
                        AdvancementReasonDiseaseprogression: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_chk_F_2 : a.lotId == LOT.LOT3 ? nonCohortDetails.q30_chk_2 : null,
                        AdvancementReasonWithoutprogression: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_chk_F_3 : a.lotId == LOT.LOT3 ? nonCohortDetails.q30_chk_3 : null,
                        AdvancementReasonInsurance: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_chk_F_4 : a.lotId == LOT.LOT3 ? nonCohortDetails.q30_chk_4 : null,
                        AdvancementReasonPatientdecision: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_chk_F_5 : a.lotId == LOT.LOT3 ? nonCohortDetails.q30_chk_5 : null,
                        AdvancementReasonPhysiciandecision: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_chk_F_6 : a.lotId == LOT.LOT3 ? nonCohortDetails.q30_chk_6 : null,
                        AdvancementReasonOther: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_chk_F_7 : a.lotId == LOT.LOT3 ? nonCohortDetails.q30_chk_7 : null,
                        AdvancementReasonUnknown: a.lotId == LOT.LOT2 ? nonCohortDetails.q28_rb_F_unk : a.lotId == LOT.LOT3 ? nonCohortDetails.q30_rb_unk : null,
                        //Q29 F
                        AdvanceToNextlineTherapy: a.lotId == LOT.LOT3 ? nonCohortDetails.q29_rbl_F : null,
                    }));
                }

                if (bispecificsRptList && bispecificsRptList.length > 0) {
                    payload.NonCohortBispecificList = bispecificsRptList.map(a => ({

                        Cohorttreatmentid: a.cohortTreatmentId,
                        //Q31 A
                        AdditionalLotnumberTxt: a.bispecificsLotPlus1,
                        AdditionalLotnumber: a.q31_rbl_A === 1,
                        AdditionallotNotreceived: a.q31_rbl_A === 2,
                        //Q31 B
                        LotStartdate: a.q31_rbl_B === 1 ? a.q31_date_B : null,
                        LotStartdateUk: a.q31_rbl_B === 99,
                        //Q31 C
                        ReceivedClinicaltrial: a.q31_rbl_C,
                        //Q31 D
                        PrescribedTreamentUnk: a.q31_D_rb_unk,


                        SelectedPrescribedTreatmentList: a.q31_D_selectedPrescribedTmtList.map(x => ({
                            ...x,
                            PrescribedTreatmentId: x.prescribedTreatmentId,
                            PrescribedTreatmentOthTxt: x.PrescribedTreatmentOthTxt,
                        })),

                        //Q32 B
                        DiscontinuedLottreament: a.q32_rbl_B,
                        //Q32 C
                        LotDiscontinuedDate: a.q32_rbl_C === 1 ? a.q32_date_C : null,
                        LotDiscontinuedDateUk: a.q32_rbl_C === 99,
                        //Q32 D
                        RefactoryAgents: a.q32_rbl_D,
                        //Q32 E 
                        AgentRevlimid: a.q32_chk_E_1,
                        AgentPi: a.q32_chk_E_2,
                        AgentMabs: a.q32_chk_E_3,
                        AgentBcma: a.q32_chk_E_4,
                        AgentOther: a.q32_chk_E_5,
                        AgentUnk: a.q32_rb_E_unk,
                        //Q32 F
                        AdvanceNexttherapyOngoing: a.q32_rbl_F === 1,
                        AdvanceNexttherapyPatientSubsequentlot: a.q32_rbl_F === 2,
                        AdvanceNexttherapyNoSubsequentlot: a.q32_rbl_F === 3,
                        AdvanceNexttherapyUnknown: a.q32_rbl_F === 99,
                        //Q33
                        AdvancementReasonAdverseEvent: a.q33_chk_1,
                        AdvancementReasonDiseaseProgression: a.q33_chk_2,
                        AdvancementReasonWithoutProgression: a.q33_chk_3,
                        AdvancementReasonInsurance: a.q33_chk_4,
                        AdvancementReasonPatientDecision: a.q33_chk_5,
                        AdvancementReasonPhysicianDecision: a.q33_chk_6,
                        AdvancementReasonOther: a.q33_chk_7,
                        AdvancementReasonUnknown: a.q33_rb_unk,
                    }))
                }

                if (pid > 0) {
                    const response = await NonCohortTreatmentService.SaveNonCohortTreatmentData(payload);
                    ToggleLoader(false);
                    if (response.status !== HTTPResponse.OK) {
                        if (response?.status === HTTPResponse.Unauthorized) {
                            HandleSessionTimeout();
                        }
                        else
                            throw response.error;
                    }
                    else {
                        ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                        await LoadData();
                        //SetNavPaths();
                    }

             
              if (response?.data?.showDateChangeAffectAlert) {
                ShowAlertBox();
            }
                }
                return true;
            }
            else {
                ToggleLoader(false);
                setShowTopErrMsg(true)
                return false;
            }
        } catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
        }
    }

        /* <summary>
        date: 27-08-2024
        Name: BV
        description: Show an alert message when other page(end of follow up) could be affected
        <summary>*/
        const ShowAlertBox = async () => {
            debugger
            const choice = await ShowActionPopUp(
                "Alert", //title
                CommonError_Msgs.DateChangeAlert, //message
                "Ok", //Confirm button text
                true
            );
        };
    
    const Validate = () => {
        let isValid = true;

        if (q27AddInfoDetails && q27AddInfoDetails.length > 0) {

            const updatedArr = q27AddInfoDetails.map(row => {

                const A_empty = row.rbl_lot_num_select == null;
                let B_empty = false;
                let B_DateBeforeLot2 = false;
                let B_DateBeforeIndex = false;
                let B_DateBeyondEOF = false;
                let C_empty = false;
                let D_empty = false;
                let D_txt_empty = false;

                if (row.rbl_lot_num_select === 1) {
                    if (row.rbl_lot_start_date == null || (row.rbl_lot_start_date === 1 && isStringEmpty(row.date_lot_start))) {
                        B_empty = true;
                    } else {
                        B_DateBeyondEOF = IsDateAfter(row.date_lot_start, endOfFollowUpDate);

                        let latterDate = NDMM.cohortIndexDate;
                        if (IsDateAfter(NDMM.lastAdminDate, NDMM.cohortIndexDate)) {
                            latterDate = NDMM.lastAdminDate;
                        }
                        if (latterDate === NDMM.cohortIndexDate) {
                            B_DateBeforeIndex = latterDate === row.date_lot_start || IsDateAfter(latterDate, row.date_lot_start);
                        } else {
                            B_DateBeforeIndex = IsDateAfter(latterDate, row.date_lot_start);
                        }
                    }

                    if (row.lotId == LOT.LOT3) {
                        {
                            if (row.rbl_lot_num_select === 1) {
                                const lot2DtartDt = q27AddInfoDetails.find(a => a.lotId == LOT.LOT2 && a.rbl_lot_num_select === 1)?.date_lot_start;
                                B_DateBeforeLot2 = IsDateAfter(lot2DtartDt, row.date_lot_start);
                            }
                        }
                    }


                    C_empty = row.rbl_received_clinical_trial == null;

                    if (!row.rb_prescribed_tmt_unk && (!row.selectedPrescribedTmtList || row.selectedPrescribedTmtList.length == 0)) {
                        D_empty = true
                    } else {
                        if (row.selectedPrescribedTmtList && row.selectedPrescribedTmtList.length > 0) {
                            //Check if Other is checked
                            const othTmt = row.selectedPrescribedTmtList.find(item => item.prescribedTreatmentId === prescribedTmtOtherID);
                            if (othTmt) {
                                D_txt_empty = isStringEmpty(othTmt.prescribedTreatmentOthTxt)
                            }
                        }
                    }
                }

                if (A_empty || B_empty || B_DateBeforeLot2 || B_DateBeforeIndex || B_DateBeyondEOF ||
                    C_empty || D_empty || D_txt_empty
                ) {
                    isValid = false;
                }
                return {
                    ...row,
                    A_empty,
                    B_empty,
                    B_DateBeforeLot2,
                    B_DateBeforeIndex,
                    B_DateBeyondEOF,
                    C_empty,
                    D_empty,
                    D_txt_empty,
                }
            });
            setQ27AddInfoDetails(updatedArr)

            //Q28
            if (q27AddInfoDetails.find(a => a.lotId == LOT.LOT2)?.rbl_lot_num_select === 1) {
                //Q28 B
                if (nonCohortDetails.q28_rbl_B == null) {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q28_B_empty_err: true
                    }))
                    isValid = false;
                } else {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q28_B_empty_err: false
                    }))
                }
                if (nonCohortDetails.q28_B_invalid_combo_err) {
                    isValid = false;
                }
                //Q28 C
                if (nonCohortDetails.q28_rbl_B === 1) {
                    if (nonCohortDetails.q28_rbl_C == null || (nonCohortDetails.q28_rbl_C === 1 && isStringEmpty(nonCohortDetails.q28_date_C))) {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q28_C_empty_err: true
                        }))
                        isValid = false;
                    } else {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q28_C_empty_err: false
                        }))
                    }
                }
                if (nonCohortDetails.q28_C_before_q27_B_err || nonCohortDetails.q28_C_beyond_ndmm_3rd_LOT_err || nonCohortDetails.q28_C_beyond_eof_err || nonCohortDetails.q28_C_empty_err) {
                    isValid = false;
                }
                //Q28 D
                if (nonCohortDetails.q28_rbl_D == null) {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q28_D_empty_err: true
                    }))
                    isValid = false;
                } else {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q28_D_empty_err: false
                    }))
                }
                //Q28 E
                if (nonCohortDetails.q28_rbl_D === 1) {
                    if (!nonCohortDetails.q28_chk_E_1 && !nonCohortDetails.q28_chk_E_2 && !nonCohortDetails.q28_chk_E_3 && !nonCohortDetails.q28_chk_E_4
                        && !nonCohortDetails.q28_chk_E_5 && !nonCohortDetails.q28_rb_E_unk) {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q28_E_empty_err: true
                        }))
                        isValid = false;
                    } else {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q28_E_empty_err: false
                        }))
                    }
                }
                //Q28F
                if (q27AddInfoDetails?.find(a => a.lotId == LOT.LOT3)?.rbl_lot_num_select === 1) {
                    if (!nonCohortDetails.q28_chk_F_1 && !nonCohortDetails.q28_chk_F_2 && !nonCohortDetails.q28_chk_F_3 && !nonCohortDetails.q28_chk_F_4
                        && !nonCohortDetails.q28_chk_F_5 && !nonCohortDetails.q28_chk_F_6 && !nonCohortDetails.q28_chk_F_7 && !nonCohortDetails.q28_rb_F_unk) {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q28_F_empty_err: true
                        }))
                        isValid = false;
                    } else {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q28_F_empty_err: false
                        }))
                    }
                }
            }
            else {
                setNonCohortDetails(prev => ({
                    ...prev,
                    q28_B_empty_err: false,//Error
                    q28_B_invalid_combo_err: false,//Error
                    q28_C_before_q27_B_err: false,//Error
                    q28_C_beyond_ndmm_3rd_LOT_err: false,//Error
                    q28_C_beyond_eof_err: false,//Error
                    q28_C_empty_err: false,//Error
                    q28_D_empty_err: false,//Error 
                    q28_E_empty_err: false,//Error
                    q28_F_empty_err: false,//Error
                }))
            }

            // validate 29, 30 if lot 3 present
            if (q27AddInfoDetails.find(a => a.lotId == LOT.LOT3)?.rbl_lot_num_select === 1) {

                //Q29 B
                if (nonCohortDetails.q29_rbl_B == null) {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q29_B_empty_err: true
                    }))
                    isValid = false;
                } else {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q29_B_empty_err: false
                    }))
                }
                //Q29 C
                if (nonCohortDetails.q29_rbl_B === 1) {
                    if (nonCohortDetails.q29_rbl_C == null || (nonCohortDetails.q29_rbl_C === 1 && isStringEmpty(nonCohortDetails.q29_date_C))) {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q29_C_empty_err: true
                        }))
                        isValid = false;
                    } else {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q29_C_empty_err: false
                        }))
                    }
                }
                if (nonCohortDetails.q29_C_before_q27_B_err || nonCohortDetails.q29_C_beyond_eof_err || nonCohortDetails.q29_C_empty_err) {
                    isValid = false;
                }
                //Q29 D
                if (nonCohortDetails.q29_rbl_D == null) {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q29_D_empty_err: true
                    }))
                    isValid = false;
                } else {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q29_D_empty_err: false
                    }))
                }
                //Q29 E
                if (nonCohortDetails.q29_rbl_D === 1) {
                    if (!nonCohortDetails.q29_chk_E_1 && !nonCohortDetails.q29_chk_E_2 && !nonCohortDetails.q29_chk_E_3 && !nonCohortDetails.q29_chk_E_4
                        && !nonCohortDetails.q29_chk_E_5 && !nonCohortDetails.q29_rb_E_unk) {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q29_E_empty_err: true
                        }))
                        isValid = false;
                    } else {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q29_E_empty_err: false
                        }))
                    }
                }
                //Q29 F 
                if (!(nonCohortDetails.q29_rbl_B == null || nonCohortDetails.q29_rbl_B === 99)) {
                    if (nonCohortDetails.q29_rbl_F == null) {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q29_F_empty_err: true
                        }))
                        isValid = false;
                    } else {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q29_F_empty_err: false
                        }))
                    }
                }
                if (nonCohortDetails.q29_F_empty_err) {//nonCohortDetails.q29_F_invalid_combo1_err || nonCohortDetails.q29_F_invalid_combo2_err 
                    isValid = false;
                }
                //Q30
                if (nonCohortDetails.q29_rbl_F === 2) {
                    if (!nonCohortDetails.q30_chk_1 && !nonCohortDetails.q30_chk_2 && !nonCohortDetails.q30_chk_3 && !nonCohortDetails.q30_chk_4 && !nonCohortDetails.q30_chk_5
                        && !nonCohortDetails.q30_chk_6 && !nonCohortDetails.q30_chk_7 && !nonCohortDetails.q30_rb_unk) {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q30_empty_err: true
                        }))
                        isValid = false;
                    } else {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q30_empty_err: false
                        }))
                    }
                }
            } else {
                setNonCohortDetails(prev => ({
                    ...prev,
                    q28_F_empty_err: false,//Error
                    q29_B_empty_err: false,//Error
                    q29_C_before_q27_B_err: false,//Error
                    q29_C_beyond_eof_err: false,//Error
                    q29_C_empty_err: false,//Error
                    q29_D_empty_err: false,//Error 
                    q29_E_empty_err: false,//Error
                    q29_F_empty_err: false,//Error
                    // q29_F_invalid_combo1_err: false,//Error
                    // q29_F_invalid_combo2_err: false,//Error
                    q30_empty_err: false,//Error
                }))
            }
        }


        if (bispecificsRptList && bispecificsRptList.length > 0) {

            const updatedArr = bispecificsRptList.map(row => {

                const q31_A_empty_err = row.q31_rbl_A == null;
                let q31_B_empty_err = false;
                let q31_B_beyond_eof_err = false;;
                let q31_B_before_index_err = false;
                let q31_C_empty_err = false;
                let q31_D_empty_err = false;
                let q31_D_txt_empty_err = false;

                let q32_B_empty_err = false;
                let q32_C_before_q31_B_err = false;
                let q32_C_beyond_eof_err = false;
                let q32_C_empty_err = false;
                let q32_D_empty_err = false;
                let q32_E_empty_err = false;
                let q32_F_empty_err = false;
                // let q32_F_invalid_combo1_err = false;
                // let q32_F_invalid_combo2_err = false;

                let q33_empty_err = false;

                if (row.q31_rbl_A === 1) {
                    //31 B
                    if (row.q31_rbl_B == null || (row.q31_rbl_B === 1 && isStringEmpty(row.q31_date_B))) {
                        q31_B_empty_err = true;
                    } else {
                        q31_B_beyond_eof_err = IsDateAfter(row.q31_date_B, endOfFollowUpDate);
                        q31_B_before_index_err = row.cohortIndexDate === row.q31_date_B || IsDateAfter(row.cohortIndexDate, row.q31_date_B);
                    }
                    //31 C
                    q31_C_empty_err = row.q31_rbl_C == null;

                    //31 D
                    if (!row.q31_D_rb_unk && (!row.q31_D_selectedPrescribedTmtList || row.q31_D_selectedPrescribedTmtList.length == 0)) {
                        q31_D_empty_err = true
                    } else {
                        if (row.q31_D_selectedPrescribedTmtList && row.q31_D_selectedPrescribedTmtList.length > 0) {
                            //Check if Other is checked
                            const othTmt = row.q31_D_selectedPrescribedTmtList.find(item => item.prescribedTreatmentId === prescribedTmtOtherID);
                            if (othTmt) {
                                q31_D_txt_empty_err = isStringEmpty(othTmt.prescribedTreatmentOthTxt)
                            }
                        }
                    }
                    ////Q32   
                    //Q32 B
                    if (row.q32_rbl_B == null) {
                        q32_B_empty_err = true
                    }
                    //Q32 C
                    if (row.q32_rbl_B === 1) {
                        if (row.q32_rbl_C == null || (row.q32_rbl_C === 1 && isStringEmpty(row.q32_date_C))) {
                            q32_C_empty_err = true
                        }
                        const nxtLotStartDt = row.q31_rbl_A === 1 ? row.q31_date_B : null;
                        if (!isStringEmpty(nxtLotStartDt)) {
                            q32_C_before_q31_B_err = IsDateAfter(nxtLotStartDt, row.q32_date_C)
                    }
                        if (!isStringEmpty(endOfFollowUpDate)) {
                            q32_C_beyond_eof_err = IsDateAfter(row.q32_date_C, endOfFollowUpDate)
                        }
                    }
                    //Q32 D
                    if (row.q32_rbl_D == null) {
                        q32_D_empty_err = true
                    }
                    //Q32 E
                    if (row.q32_rbl_D === 1) {
                        if (!row.q32_chk_E_1 && !row.q32_chk_E_2 && !row.q32_chk_E_3 && !row.q32_chk_E_4 && !row.q32_chk_E_5 && !row.q32_rb_E_unk) {
                            q32_E_empty_err = true
                        }
                    }
                    //Q32 F 
                    if (!(row.q32_rbl_B == null || row.q32_rbl_B === 99)) {
                        if (row.q32_rbl_F == null) {
                            q32_F_empty_err = true
                        }
                    }

                    //Q33
                    if (row.q32_rbl_F === 2) {
                        if (!row.q33_chk_1 && !row.q33_chk_2 && !row.q33_chk_3 && !row.q33_chk_4 && !row.q33_chk_5
                            && !row.q33_chk_6 && !row.q33_chk_7 && !row.q33_rb_unk) {
                            q33_empty_err = true;
                        }
                    }
                }
                if (q31_A_empty_err || q31_B_empty_err || q31_B_beyond_eof_err || q31_B_before_index_err || q31_C_empty_err || q31_D_empty_err || q31_D_txt_empty_err ||
                    q32_B_empty_err || q32_C_before_q31_B_err || q32_C_beyond_eof_err || q32_C_empty_err || q32_D_empty_err || q32_E_empty_err || q32_F_empty_err ||
                    //row.q32_F_invalid_combo1_err || row.q32_F_invalid_combo2_err 
                    q33_empty_err
                ) {
                    isValid = false;
                }

                return {
                    ...row,
                    q31_A_empty_err,
                    q31_B_empty_err,
                    q31_B_beyond_eof_err,
                    q31_B_before_index_err,
                    q31_C_empty_err,
                    q31_D_empty_err,
                    q31_D_txt_empty_err,

                    q32_B_empty_err,
                    q32_C_before_q31_B_err,
                    q32_C_beyond_eof_err,
                    q32_C_empty_err,
                    q32_D_empty_err,
                    q32_E_empty_err,
                    q32_F_empty_err,

                    q33_empty_err
                }
            });
            setBispecificsRptList(updatedArr)
        }
        return isValid;
    }
    const HandleQ27FieldChange = (e) => {
        const { name, id, value } = e.target;
        const value_int = Number(value);

        let updatedArray = [...q27AddInfoDetails];

        switch (name) {
            //Q27
            case "rbl_lot_num_select" + id:
                {
                    updatedArray[id].rbl_lot_num_select = value_int;
                    updatedArray[id].A_empty = false;

                    //Reset Dependent fields (B, C, D)
                    //B
                    updatedArray[id].date_lot_start = null;
                    updatedArray[id].rbl_lot_start_date = null;
                    updatedArray[id].B_empty = false;
                    updatedArray[id].B_DateBeforeLot2 = false;
                    updatedArray[id].B_DateBeforeIndex = false;
                    updatedArray[id].B_DateBeyondEOF = false;
                    //C
                    updatedArray[id].rbl_received_clinical_trial = null;
                    updatedArray[id].C_empty = false;
                    //D
                    updatedArray[id].selectedPrescribedTmtList = [];
                    // updatedArray[id].txt_prescribed_tmt_oth = "";
                    updatedArray[id].rb_prescribed_tmt_unk = false;
                    updatedArray[id].D_empty = false;
                    updatedArray[id].D_txt_empty = false;

                    //Add 3rd LOT if 2nd Lot is selected
                    if (updatedArray[id].lotId === 2) {
                        if (updatedArray[id].rbl_lot_num_select === 1) {
                            updatedArray.push(
                                {
                                    ...Q27Row,
                                    cohortTreatmentId: NDMM.cohortTreatmentId,
                                    lotId: LOT.LOT3,
                                })
                        } else {
                            //Remove 3rd Lot row
                            // const lot3Index = updatedArray.findIndex(item => item.lotId === LOT.LOT3);
                            // updatedArray.splice(lot3Index, 1);
                            updatedArray.splice(1);
                        }
                        //Reset Q28
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q28_rbl_B: null,
                            q28_B_empty_err: false,//Error
                            q28_B_invalid_combo_err: false,//Error
                            q28_date_C: null,
                            q28_rbl_C: null,
                            q28_C_before_q27_B_err: false,//Error q28_C_beyond_ndmm_2rd_LOT_err
                            q28_C_beyond_ndmm_3rd_LOT_err: false,//Error
                            q28_C_beyond_eof_err: false,//Error
                            q28_C_empty_err: false,//Error
                            q28_rbl_D: null,
                            q28_D_empty_err: false,//Error  
                            q28_chk_E_1: false,
                            q28_chk_E_2: false,
                            q28_chk_E_3: false,
                            q28_chk_E_4: false,
                            q28_chk_E_5: false,
                            q28_rb_E_unk: false,
                            q28_E_empty_err: false,//Error
                            q28_chk_F_1: false,
                            q28_chk_F_2: false,
                            q28_chk_F_3: false,
                            q28_chk_F_4: false,
                            q28_chk_F_5: false,
                            q28_chk_F_6: false,
                            q28_chk_F_7: false,
                            q28_rb_F_unk: false,
                            q28_F_empty_err: false,//Error
                        }))
                    }

                    if (updatedArray[id].lotId === 3) {
                        let q28_B_invalid_combo_err = false;
                        if (nonCohortDetails.q28_rbl_B != null) {
                            q28_B_invalid_combo_err = updatedArray[id].rbl_lot_num_select === 1 && nonCohortDetails.q28_rbl_B !== 1;
                            setNonCohortDetails(prev => ({
                                ...prev,
                                q28_B_invalid_combo_err,
                            }));
                        }
                    }
                    //Reset Q29 and Q30(30 is dependent on 29) Q28
                    setNonCohortDetails(prev => ({
                        ...prev,
                        // Reset Q29
                        q29_rbl_B: null,
                        q29_B_empty_err: false,
                        q29_date_C: null,
                        q29_rbl_C: null,
                        q29_C_before_q27_B_err: false,
                        q29_C_beyond_eof_err: false,
                        q29_C_empty_err: false,
                        q29_rbl_D: null,
                        q29_D_empty_err: false,
                        q29_chk_E_1: false,
                        q29_chk_E_2: false,
                        q29_chk_E_3: false,
                        q29_chk_E_4: false,
                        q29_chk_E_5: false,
                        q29_rb_E_unk: false,
                        q29_E_empty_err: false,
                        q29_rbl_F: null,
                        q29_F_empty_err: false,
                        // q29_F_invalid_combo1_err: false,
                        // q29_F_invalid_combo2_err: false,
                        //Reset Q30
                        q30_chk_1: false,
                        q30_chk_2: false,
                        q30_chk_3: false,
                        q30_chk_4: false,
                        q30_chk_5: false,
                        q30_chk_6: false,
                        q30_chk_7: false,
                        q30_rb_unk: false,
                        q30_empty_err: false,
                        // Reset Q28 F                        
                        q28_chk_F_1: false,
                        q28_chk_F_2: false,
                        q28_chk_F_3: false,
                        q28_chk_F_4: false,
                        q28_chk_F_5: false,
                        q28_chk_F_6: false,
                        q28_chk_F_7: false,
                        q28_rb_F_unk: false,
                        q28_F_empty_err: false,
                        //Reset Q28 C Error
                        q28_C_beyond_ndmm_3rd_LOT_err: false,//Error
                    }))
                }
                break;
            case "rbl_lot_start_date" + id:
                {
                    updatedArray[id].rbl_lot_start_date = value_int;
                    updatedArray[id].date_lot_start = null;

                    updatedArray[id].B_empty = false;
                    updatedArray[id].B_DateBeforeLot2 = false;
                    updatedArray[id].B_DateBeforeIndex = false;
                    updatedArray[id].B_DateBeyondEOF = false;
                    //Reset 28 date error
                    if (updatedArray[id].lotId === 2) {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q28_C_before_q27_B_err: false
                        }))
                    }
                    else if (updatedArray[id].lotId === 3) {
                        setNonCohortDetails(prev => ({
                            ...prev,
                            q28_C_beyond_ndmm_3rd_LOT_err: false,
                        }))
                    }
                }
                break;
            case "rbl_received_clinical_trial" + id:
                {
                    updatedArray[id].rbl_received_clinical_trial = value_int;

                    updatedArray[id].C_empty = false;
                }
                break;
            case "rb_prescribed_tmt_unk" + id:
                {
                    updatedArray[id].rb_prescribed_tmt_unk = !updatedArray[id].rb_prescribed_tmt_unk;
                    updatedArray[id].selectedPrescribedTmtList = [];
                    // updatedArray[id].txt_prescribed_tmt_oth = "";

                    updatedArray[id].D_empty = false;
                    updatedArray[id].D_txt_empty = false;
                }
                break;
            case "chk_q27_prescribed_tmt" + id:

                {
                    const index = id.split('_')[0];
                    const tmtID = id.split('_').pop(); // Extract the selected prescribed tmt id
                    const selTmtList = updatedArray[index].selectedPrescribedTmtList;
                    // const tmtIndex = selTmtList.indexOf(Number(tmtID));
                    const tmtIndex = selTmtList.findIndex(item => item.prescribedTreatmentId === Number(tmtID));
                    // const selTmt = selTmtList.find(item => item.prescribedTreatmentId === Number(tmtID));
                    // const isOthSelected = selTmt?.name === "Other";
                    const isOthSelected = tmtID == prescribedTmtOtherID;

                    if (tmtIndex >= 0) {
                        // Checkbox unselect
                        selTmtList.splice(tmtIndex, 1);
                    } else {
                        // Checkbox select
                        selTmtList.push({ prescribedTreatmentId: Number(tmtID) });
                    }

                    updatedArray[index].selectedPrescribedTmtList = selTmtList;
                    updatedArray[index].rb_prescribed_tmt_unk = false;

                    //if (isOthSelected) {
                        updatedArray[index].D_txt_empty = false;
                        // updatedArray[index].txt_prescribed_tmt_oth = "";
                    //}

                    updatedArray[index].D_empty = false;

                }
                break;
            case "txt_prescribed_tmt_oth":
                {
                    const index = id.split('_')[0];
                    const tmtID = id.split('_').pop();
                    const selTmtList = updatedArray[index].selectedPrescribedTmtList;

                    // updatedArray[index].txt_prescribed_tmt_oth = value;

                    // Check if the item with the same prescribedTreatmentId already exists
                    const existingItemIndex = selTmtList.findIndex(item => item.prescribedTreatmentId === Number(tmtID));

                    if (existingItemIndex >= 0) {
                        selTmtList[existingItemIndex] = {
                            ...selTmtList[existingItemIndex],
                            prescribedTreatmentOthTxt: value
                        };
                    } else {
                        selTmtList.push({ prescribedTreatmentId: Number(tmtID), prescribedTreatmentOthTxt: value });
                    }
                    updatedArray[index].selectedPrescribedTmtList = selTmtList;

                    updatedArray[index].rb_prescribed_tmt_unk = false;

                    updatedArray[index].D_empty = false;
                    updatedArray[index].D_txt_empty = false;
                }
                break;


            default: break;
        }
        setQ27AddInfoDetails(updatedArray);
    }
    const HandleQ27DateChange = (name, date, id) => {

        let updatedArray = [...q27AddInfoDetails];

        switch (name) {
            case "date_lot_start" + id:
                {

                    updatedArray[id].date_lot_start = date;
                    updatedArray[id].rbl_lot_start_date = 1;

                    updatedArray[id].B_empty = false;
                    updatedArray[id].B_DateBeforeLot2 = false;
                    updatedArray[id].B_DateBeforeIndex = false;
                    updatedArray[id].B_DateBeyondEOF = false;
                    setQ27AddInfoDetails(updatedArray);

                    if (updatedArray[id].lotId === 3) {
                        let isDateBeyondLot3Start = false;
                        if (!isStringEmpty(nonCohortDetails.q28_date_C)) {
                            isDateBeyondLot3Start = IsDateAfter(nonCohortDetails.q28_date_C, date)
                        }

                        setNonCohortDetails(prev => ({
                            ...prev,
                            q28_C_beyond_ndmm_3rd_LOT_err: isDateBeyondLot3Start
                        }))
                    }
                    if (updatedArray[id].lotId === 2) {
                        let isDateBeforeLot2Start = false;
                        if (!isStringEmpty(nonCohortDetails.q28_date_C)) {
                            isDateBeforeLot2Start = IsDateAfter(date, nonCohortDetails.q28_date_C)
                        }

                        setNonCohortDetails(prev => ({
                            ...prev,
                            q28_C_before_q27_B_err: isDateBeforeLot2Start,
                        }))
                    }
                }
                break;

            default: break;
        }
    };
    // const Validate_F_B_Combo = (f, b, combo) => {

    //     if (!(f == null || b == null)) {
    //         if (combo === 1) {
    //             if (b === 0 && f !== 1) {
    //                 return true;
    //             }
    //             else {
    //                 return false;
    //             }
    //         }
    //         else if (combo === 2) {
    //             if (b === 1 && f === 3) {
    //                 return true;
    //             }
    //             else {
    //                 return false;
    //             }
    //         }
    //     }
    //     return false;
    // }
    const HandleFieldChange = (e) => {
        const { name, id, value } = e.target;
        const value_int = Number(value);

        switch (name) {
            //Q28
            case "q28_rbl_C":
                {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q28_rbl_C: value_int,
                        q28_date_C: null,
                        q28_C_empty_err: false,
                        q28_C_before_q27_B_err: false,
                        q28_C_beyond_ndmm_3rd_LOT_err: false,
                        q28_C_beyond_eof_err: false,
                    }))
                }
                break;
            case "q28_rbl_B":
                {
                    let q28_B_invalid_combo_err = false;
                    const lot3Row = q27AddInfoDetails?.find(a => a.lotId == LOT.LOT3);
                    if (lot3Row) {
                        q28_B_invalid_combo_err = lot3Row.rbl_lot_num_select === 1 && value_int !== 1;
                    }

                    setNonCohortDetails(prev => ({
                        ...prev,
                        q28_rbl_B: value_int,
                        q28_B_empty_err: false,
                        q28_B_invalid_combo_err,
                        //Reset col C
                        q28_date_C: null,
                        q28_rbl_C: null,
                        q28_C_before_q27_B_err: false,
                        q28_C_beyond_ndmm_3rd_LOT_err: false,
                        q28_C_beyond_eof_err: false,
                        q28_C_empty_err: false,
                    }))
                }
                break;
            case "q28_rbl_D":
                {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q28_rbl_D: value_int,
                        q28_D_empty_err: false,
                        //Reset col E
                        q28_chk_E_1: false,
                        q28_chk_E_2: false,
                        q28_chk_E_3: false,
                        q28_chk_E_4: false,
                        q28_chk_E_5: false,
                        q28_rb_E_unk: false,
                        q28_E_empty_err: false,
                    }))
                }
                break;
            case "q28_chk_E_1":
            case "q28_chk_E_2":
            case "q28_chk_E_3":
            case "q28_chk_E_4":
            case "q28_chk_E_5":
                {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        [name]: !prev[name],
                        q28_rb_E_unk: false,
                        q28_E_empty_err: false,
                    }))
                }
                break;
            case "q28_rb_E_unk":
                {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q28_rb_E_unk: true,
                        q28_chk_E_1: false,
                        q28_chk_E_2: false,
                        q28_chk_E_3: false,
                        q28_chk_E_4: false,
                        q28_chk_E_5: false,
                        q28_E_empty_err: false,
                    }))
                }
                break;
            case "q28_chk_F_1":
            case "q28_chk_F_2":
            case "q28_chk_F_3":
            case "q28_chk_F_4":
            case "q28_chk_F_5":
            case "q28_chk_F_6":
            case "q28_chk_F_7":
                {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        [name]: !prev[name],
                        q28_rb_F_unk: false,
                        q28_F_empty_err: false,
                    }))
                }
                break;
            case "q28_rb_F_unk":
                {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q28_rb_F_unk: true,
                        q28_chk_F_1: false,
                        q28_chk_F_2: false,
                        q28_chk_F_3: false,
                        q28_chk_F_4: false,
                        q28_chk_F_5: false,
                        q28_chk_F_6: false,
                        q28_chk_F_7: false,
                        q28_F_empty_err: false,
                    }))
                }
                break;

            //Q29
            case "q29_rbl_C":
                {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q29_rbl_C: value_int,
                        q29_date_C: null,
                        q29_C_empty_err: false,
                        q29_C_before_q27_B_err: false,
                        q29_C_beyond_eof_err: false,
                    }))
                }
                break;
            case "q29_rbl_B":
                {
                    // const q29_F_invalid_combo1_err = Validate_F_B_Combo(nonCohortDetails.q29_rbl_F, value_int, 1);
                    // const q29_F_invalid_combo2_err = Validate_F_B_Combo(nonCohortDetails.q29_rbl_F, value_int, 2);
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q29_rbl_B: value_int,
                        q29_B_empty_err: false,
                        //Reset col C
                        q29_date_C: null,
                        q29_rbl_C: null,
                        q29_C_before_q31_B_err: false,
                        q29_C_before_q27_B_err: false,
                        q29_C_beyond_eof_err: false,
                        q29_C_empty_err: false,
                        //Set F states
                        q29_rbl_F: null,
                        q29_F_empty_err: false,
                        // q29_F_invalid_combo1_err: false,
                        // q29_F_invalid_combo2_err: false,
                    }))
                }
                break;
            case "q29_rbl_D":
                {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q29_rbl_D: value_int,
                        q29_D_empty_err: false,
                        //Reset col E
                        q29_chk_E_1: false,
                        q29_chk_E_2: false,
                        q29_chk_E_3: false,
                        q29_chk_E_4: false,
                        q29_chk_E_5: false,
                        q29_rb_E_unk: false,
                        q29_E_empty_err: false,
                    }))
                }
                break;
            case "q29_chk_E_1":
            case "q29_chk_E_2":
            case "q29_chk_E_3":
            case "q29_chk_E_4":
            case "q29_chk_E_5":
                {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        [name]: !prev[name],
                        q29_rb_E_unk: false,
                        q29_E_empty_err: false,
                    }))
                }
                break;
            case "q29_rb_E_unk":
                {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q29_rb_E_unk: true,
                        q29_chk_E_1: false,
                        q29_chk_E_2: false,
                        q29_chk_E_3: false,
                        q29_chk_E_4: false,
                        q29_chk_E_5: false,
                        q29_E_empty_err: false,
                    }))
                }
                break;
            case "q29_rbl_F":
                {
                    // const q29_F_invalid_combo1_err = Validate_F_B_Combo(value_int, nonCohortDetails.q29_rbl_B, 1);
                    // const q29_F_invalid_combo2_err = Validate_F_B_Combo(value_int, nonCohortDetails.q29_rbl_B, 2);

                    setNonCohortDetails(prev => ({
                        ...prev,
                        q29_rbl_F: value_int,
                        q29_F_empty_err: false,
                        // q29_F_invalid_combo1_err,
                        // q29_F_invalid_combo2_err,
                        //Reset Q30    
                        q30_chk_1: false,
                        q30_chk_2: false,
                        q30_chk_3: false,
                        q30_chk_4: false,
                        q30_chk_5: false,
                        q30_chk_6: false,
                        q30_chk_7: false,
                        q30_rb_unk: false,
                        q30_empty_err: false,
                    }))
                }
                break;
            //Q30
            case "q30_chk_1":
            case "q30_chk_2":
            case "q30_chk_3":
            case "q30_chk_4":
            case "q30_chk_5":
            case "q30_chk_6":
            case "q30_chk_7":
                {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        [name]: !prev[name],
                        q30_rb_unk: false,
                        q30_empty_err: false,
                    }))
                }
                break;
            case "q30_rb_unk":
                {
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q30_rb_unk: true,
                        q30_chk_1: false,
                        q30_chk_2: false,
                        q30_chk_3: false,
                        q30_chk_4: false,
                        q30_chk_5: false,
                        q30_chk_6: false,
                        q30_chk_7: false,
                        q30_empty_err: false,
                    }))
                }
                break;

            default: break;
        }
    }
    const HandleDateChange = (name, date, id) => {

        switch (name) {
            case "q28_date_C":
                {
                    let isDateBeyondEof = false;
                    let isDateBeforeLot2Start = false;
                    let isDateBeyondLot3Start = false;
                    let lot2StartDt = null;
                    let lot3StartDt = null;

                    const lot2Row = q27AddInfoDetails?.find(a => a.lotId == LOT.LOT2);
                    if (lot2Row) {
                        lot2StartDt = lot2Row.rbl_lot_num_select === 1 ? lot2Row.date_lot_start : null;
                    }
                    if (!isStringEmpty(lot2StartDt)) {
                        isDateBeforeLot2Start = IsDateAfter(lot2StartDt, date)
                    }
                    const lot3Row = q27AddInfoDetails?.find(a => a.lotId == LOT.LOT3);
                    if (lot3Row) {
                        lot3StartDt = lot3Row.rbl_lot_num_select === 1 ? lot3Row.date_lot_start : null;
                    }
                    if (!isStringEmpty(lot3StartDt)) {
                        isDateBeyondLot3Start = IsDateAfter(date, lot3StartDt)
                    }

                    if (!isStringEmpty(endOfFollowUpDate)) {
                        isDateBeyondEof = IsDateAfter(date, endOfFollowUpDate)
                    }
                    //“NDMM 3rd LOT start” = date in [B] of [R2] if “3rd LOT” is selected in [A] of [R2] and date is known
                    //NDMM 2nd LOT start (if available) ≤ “NDMM 2nd LOT end” (if available) ≤ “NDMM 3rd LOT” (if available) ≤ “end of follow up”
                    setNonCohortDetails(prev => ({
                        ...prev,
                        q28_date_C: date,
                        q28_rbl_C: 1,

                        q28_C_empty_err: false,
                        q28_C_beyond_eof_err: isDateBeyondEof,
                        q28_C_before_q27_B_err: isDateBeforeLot2Start,
                        q28_C_beyond_ndmm_3rd_LOT_err: isDateBeyondLot3Start,
                    }))
                }
                break;
            case "q29_date_C":
                {
                    let isDateBeyondEof = false;
                    let isDateBeforeNxtLotStart = false;
                    let nxtLotStartDt = null;
                    let q29_C_before_q27_B_err = false;
                    const lot3Row = q27AddInfoDetails?.find(a => a.lotId == LOT.LOT3);
                    if (lot3Row) {
                        nxtLotStartDt = lot3Row.rbl_lot_num_select === 1 ? lot3Row.date_lot_start : null;
                    }
                    if (!isStringEmpty(endOfFollowUpDate)) {
                        isDateBeyondEof = IsDateAfter(date, endOfFollowUpDate)
                    }
                    if (!isStringEmpty(nxtLotStartDt)) {
                        isDateBeforeNxtLotStart = IsDateAfter(nxtLotStartDt, date)
                        q29_C_before_q27_B_err = IsDateAfter(nxtLotStartDt, date)
                    }

                    setNonCohortDetails(prev => ({
                        ...prev,
                        q29_date_C: date,
                        q29_rbl_C: 1,

                        q29_C_empty_err: false,
                        q29_C_before_q27_B_err,
                        q29_C_beyond_eof_err: isDateBeyondEof,
                        q29_C_before_q31_B_err: isDateBeforeNxtLotStart,
                    }))
                }
                break;

            default: break;
        }
    };

    //Q31,32,33 Bispecifics Loop
    const HandleRepeaterFieldChange = (e) => {
        const { name, id, value } = e.target;
        const value_int = Number(value);

        let updatedArray = [...bispecificsRptList];

        switch (name) {
            //Q31
            case "q31_rbl_A" + id:
                {
                    updatedArray[id] = {
                        ...updatedArray[id],
                        q31_rbl_A: value_int,
                        q31_A_empty_err: false,
                        //Reset Dependent fields (B, C, D)
                        q31_date_B: null,
                        q31_rbl_B: null,
                        q31_B_empty_err: false,
                        q31_B_beyond_eof_err: false,
                        q31_B_before_index_err: false,
                        q31_rbl_C: null,
                        q31_C_empty_err: false,
                        q31_D_selectedPrescribedTmtList: [],
                        q31_D_rb_unk: false,
                        q31_D_empty_err: false,
                        q31_D_txt_empty_err: false,

                        //Reset Q32
                        q32_rbl_B: null,
                        q32_B_empty_err: false,
                        q32_date_C: null,
                        q32_rbl_C: null,
                        q32_C_before_q31_B_err: false,
                        q32_C_beyond_eof_err: false,
                        q32_C_empty_err: false,
                        q32_rbl_D: null,
                        q32_D_empty_err: false,
                        q32_chk_E_1: false,
                        q32_chk_E_2: false,
                        q32_chk_E_3: false,
                        q32_chk_E_4: false,
                        q32_chk_E_5: false,
                        q32_rb_E_unk: false,
                        q32_E_empty_err: false,
                        q32_rbl_F: null,
                        q32_F_empty_err: false,
                        // q32_F_invalid_combo1_err: false,
                        // q32_F_invalid_combo2_err: false,

                        //Reset Q33
                        q33_chk_1: false,
                        q33_chk_2: false,
                        q33_chk_3: false,
                        q33_chk_4: false,
                        q33_chk_5: false,
                        q33_chk_6: false,
                        q33_chk_7: false,
                        q33_rb_unk: false,
                        q33_empty_err: false,
                    };
                }
                break;
            case "q31_rbl_B" + id:
                {
                    updatedArray[id].q31_rbl_B = value_int;
                    updatedArray[id].q31_date_B = null;

                    updatedArray[id].q31_B_empty_err = false;
                    updatedArray[id].q31_B_beyond_eof_err = false;
                    updatedArray[id].q31_B_before_index_err = false;
                }
                break;
            case "q31_rbl_C" + id:
                {
                    updatedArray[id].q31_rbl_C = value_int;
                    updatedArray[id].q31_C_empty_err = false;
                }
                break;
            case "q31_D_rb_unk" + id:
                {
                    updatedArray[id].q31_D_rb_unk = !updatedArray[id].rb_prescribed_tmt_unk;
                    updatedArray[id].q31_D_selectedPrescribedTmtList = [];

                    updatedArray[id].q31_D_empty_err = false;
                    updatedArray[id].q31_D_txt_empty_err = false;
                }
                break;
            case "q31_chk_prescribed_tmt" + id:
                {
                    const index = id.split('_')[0];
                    const tmtID = id.split('_').pop(); // Extract the selected prescribed tmt id
                    const selTmtList = updatedArray[index].q31_D_selectedPrescribedTmtList;
                    // const tmtIndex = selTmtList.indexOf(Number(tmtID));
                    const tmtIndex = selTmtList.findIndex(item => item.prescribedTreatmentId === Number(tmtID));
                    // const selTmt = selTmtList.find(item => item.prescribedTreatmentId === Number(tmtID));
                    // const isOthSelected = selTmt?.name === "Other";
                    const isOthSelected = tmtID == prescribedTmtOtherID;

                    if (tmtIndex >= 0) {
                        // Checkbox unselect
                        selTmtList.splice(tmtIndex, 1);
                    } else {
                        // Checkbox select
                        selTmtList.push({ prescribedTreatmentId: Number(tmtID) });
                    }

                    updatedArray[index].q31_D_selectedPrescribedTmtList = selTmtList;
                    updatedArray[index].q31_D_rb_unk = false;

                    // if (isOthSelected) {
                        updatedArray[index].q31_D_txt_empty_err = false;
                    // }

                    updatedArray[index].q31_D_empty_err = false;
                }
                break;
            case "q31_txt_prescribed_tmt_oth":
                {
                    const index = id.split('_')[0];
                    const tmtID = id.split('_').pop();
                    const selTmtList = updatedArray[index].q31_D_selectedPrescribedTmtList;

                    // Check if the item with the same prescribedTreatmentId already exists
                    const existingItemIndex = selTmtList.findIndex(item => item.prescribedTreatmentId === Number(tmtID));

                    if (existingItemIndex >= 0) {
                        selTmtList[existingItemIndex] = {
                            ...selTmtList[existingItemIndex],
                            prescribedTreatmentOthTxt: value
                        };
                    } else {
                        selTmtList.push({ prescribedTreatmentId: Number(tmtID), prescribedTreatmentOthTxt: value });
                    }
                    updatedArray[index].q31_D_selectedPrescribedTmtList = selTmtList;

                    updatedArray[index].q31_D_rb_unk = false;

                    updatedArray[index].q31_D_empty_err = false;
                    updatedArray[index].q31_D_txt_empty_err = false;
                }
                break;
            //Q32     
            case "q32_rbl_C" + id:
                {
                    updatedArray[id].q32_rbl_C = value_int;
                    updatedArray[id].q32_date_C = null;

                    updatedArray[id].q32_C_empty_err = false;
                    updatedArray[id].q32_C_before_q31_B_err = false;
                    updatedArray[id].q32_C_beyond_eof_err = false;
                    // updatedArray[id] = {
                    //     ...updatedArray[id],
                    //     q32_rbl_C: value_int,
                    //     q32_date_C: null,
                    //     q32_C_before_q31_B_err: false,
                    //     q32_C_beyond_eof_err: false,
                    //     q32_C_empty_err: false,
                    // };
                }
                break;
            case "q32_rbl_B" + id:
                {

                    // updatedArray[id].q32_rbl_B = value_int;
                    // updatedArray[id].q32_B_empty_err = false;
                    // //Reset col C
                    // updatedArray[id].q32_date_C = null;
                    // updatedArray[id].q32_rbl_C = null;
                    // updatedArray[id].q32_C_before_q31_B_err = false;
                    // updatedArray[id].q32_C_beyond_eof_err = false;
                    // updatedArray[id].q32_C_empty_err = false;
                    // //Set F eror states
                    // updatedArray[id].q32_F_invalid_combo1_err = Validate_F_B_Combo(updatedArray[id].q32_rbl_F, value_int, 1);
                    // updatedArray[id].q32_F_invalid_combo2_err = Validate_F_B_Combo(updatedArray[id].q32_rbl_F, value_int, 2);

                    // const q32_F_invalid_combo1_err = Validate_F_B_Combo(updatedArray[id].q32_rbl_F, value_int, 1);
                    // const q32_F_invalid_combo2_err = Validate_F_B_Combo(updatedArray[id].q32_rbl_F, value_int, 2);
                    updatedArray[id] = {
                        ...updatedArray[id],
                        q32_rbl_B: value_int,
                        q32_B_empty_err: false,
                        //Reset col C
                        q32_date_C: null,
                        q32_rbl_C: null,
                        q32_C_before_q31_B_err: false,
                        q32_C_beyond_eof_err: false,
                        q32_C_empty_err: false,
                        //Set F states
                        q32_rbl_F: null,
                        q32_F_empty_err: false,
                        // q32_F_invalid_combo1_err: false,
                        // q32_F_invalid_combo2_err: false,
                    }
                }
                break;
            case "q32_rbl_D" + id:
                {
                    updatedArray[id] = {
                        ...updatedArray[id],
                        q32_rbl_D: value_int,
                        q32_D_empty_err: false,
                        //Reset col E
                        q32_chk_E_1: false,
                        q32_chk_E_2: false,
                        q32_chk_E_3: false,
                        q32_chk_E_4: false,
                        q32_chk_E_5: false,
                        q32_rb_E_unk: false,
                        q32_E_empty_err: false,
                    };

                    // updatedArray[id].q32_rbl_D = value_int;
                    // updatedArray[id].q32_D_empty_err = false;
                    // //Reset col E
                    // updatedArray[id].q32_chk_E_1 = false;
                    // updatedArray[id].q32_chk_E_2 = false;
                    // updatedArray[id].q32_chk_E_3 = false;
                    // updatedArray[id].q32_chk_E_4 = false;
                    // updatedArray[id].q32_chk_E_5 = false;
                    // updatedArray[id].q32_rb_E_unk = false;
                    // updatedArray[id].q32_E_empty_err = false;
                }
                break;
            case "q32_chk_E_1_" + id:
            case "q32_chk_E_2_" + id:
            case "q32_chk_E_3_" + id:
            case "q32_chk_E_4_" + id:
            case "q32_chk_E_5_" + id:
                {
                    // Extract the state name
                    const lastUnderscoreIndex = name.lastIndexOf('_')
                    const ctrlId = name.substring(0, lastUnderscoreIndex);

                    updatedArray[id] = {
                        ...updatedArray[id],
                        [ctrlId]: !updatedArray[id][ctrlId],
                        q32_rb_E_unk: false,
                        q32_E_empty_err: false,
                    };
                }
                break;
            case "q32_rb_E_unk_" + id:
                {
                    updatedArray[id] = {
                        ...updatedArray[id],
                        q32_rb_E_unk: true,
                        q32_chk_E_1: false,
                        q32_chk_E_2: false,
                        q32_chk_E_3: false,
                        q32_chk_E_4: false,
                        q32_chk_E_5: false,
                        q32_E_empty_err: false,
                    };
                }
                break;
            case "q32_rbl_F" + id:
                {
                    // const q32_F_invalid_combo1_err = Validate_F_B_Combo(value_int, updatedArray[id].q32_rbl_B, 1);
                    // const q32_F_invalid_combo2_err = Validate_F_B_Combo(value_int, updatedArray[id].q32_rbl_B, 2);

                    updatedArray[id] = {
                        ...updatedArray[id],
                        q32_rbl_F: value_int,
                        q32_F_empty_err: false,
                        // q32_F_invalid_combo1_err,
                        // q32_F_invalid_combo2_err,
                        //Reset Q33                        
                        q33_chk_1: false,
                        q33_chk_2: false,
                        q33_chk_3: false,
                        q33_chk_4: false,
                        q33_chk_5: false,
                        q33_chk_6: false,
                        q33_chk_7: false,
                        q33_rb_unk: false,
                        q33_empty_err: false,
                    };
                }
                break;
            //Q33
            case "q33_chk_1_" + id:
            case "q33_chk_2_" + id:
            case "q33_chk_3_" + id:
            case "q33_chk_4_" + id:
            case "q33_chk_5_" + id:
            case "q33_chk_6_" + id:
            case "q33_chk_7_" + id:
                {
                    // Extract the state name
                    const lastUnderscoreIndex = name.lastIndexOf('_')
                    const ctrlId = name.substring(0, lastUnderscoreIndex);

                    updatedArray[id] = {
                        ...updatedArray[id],
                        [ctrlId]: !updatedArray[id][ctrlId],
                        q33_rb_unk: false,
                        q33_empty_err: false,
                    };
                }
                break;
            case "q33_rb_unk" + id:
                {
                    updatedArray[id] = {
                        ...updatedArray[id],
                        q33_rb_unk: true,
                        q33_chk_1: false,
                        q33_chk_2: false,
                        q33_chk_3: false,
                        q33_chk_4: false,
                        q33_chk_5: false,
                        q33_chk_6: false,
                        q33_chk_7: false,
                        q33_empty_err: false,
                    };
                }
                break;

            default: break;
        }
        setBispecificsRptList(updatedArray);

    }
    const HandleRepeaterDateChange = (name, date, id) => {

        let updatedArray = [...bispecificsRptList];
        switch (name) {
            case "q32_date_C" + id:
                {
                    // let isDateBeyondEof = false;
                    // let isDateBeforeNxtLotStart = false;
                    // const nxtLotStartDt = updatedArray[id].q31_rbl_A === 1 ? updatedArray[id].q31_date_B : null;

                    // if (!isStringEmpty(endOfFollowUpDate)) {
                    //     isDateBeyondEof = IsDateAfter(date, endOfFollowUpDate)
                    // }
                    // if (!isStringEmpty(nxtLotStartDt)) {
                    //     isDateBeforeNxtLotStart = IsDateAfter(nxtLotStartDt, date)
                    // }

                    updatedArray[id].q32_date_C = date;
                    updatedArray[id].q32_rbl_C = 1;

                    updatedArray[id].q32_C_empty_err = false;
                    updatedArray[id].q32_C_beyond_eof_err = false;//isDateBeyondEof;
                    updatedArray[id].q32_C_before_q31_B_err = false;//isDateBeforeNxtLotStart;
                }
                break;
            case "q31_date_B" + id:
                {
                    // let isDateBeforeNxtLotStart = false;
                    // const nxtLotStartDt = updatedArray[id].q31_rbl_A === 1 ? updatedArray[id].q31_date_B : null;

                    // if (!isStringEmpty(nxtLotStartDt)) {
                    //     isDateBeforeNxtLotStart = IsDateAfter(date, nxtLotStartDt)
                    // }

                    updatedArray[id].q31_date_B = date;
                    updatedArray[id].q31_rbl_B = 1;

                    updatedArray[id].q31_B_empty_err = false;
                    updatedArray[id].q31_B_beyond_eof_err = false;
                    updatedArray[id].q31_B_before_index_err = false;

                    updatedArray[id].q32_C_before_q31_B_err = false;//isDateBeforeNxtLotStart;
                }
                break;

            default: break;
        }
        setBispecificsRptList(updatedArray);
    };



    
    const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");

    const toggleNav = () => {

    
      if (isSideMenuActive === true  ) { 
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
        setIsSideMenuActive(false)
      } else if( isSideMenuActive===false ){
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
        setIsSideMenuActive(true)
      }
  }

  useEffect(() => {
 const   sidebar = document.getElementById("mySidebar");
  const  main = document.getElementById("main");


    if (isSideMenuActive === true) {
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
      } else {
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
      }
  });
    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                    </div>
                </div>
            </section>
            <section className="main-survey-section">
                <div >
                    <SideBarMenu activePageId={SideMenuItems.NonCohortDefiningTreatment} />

                    <>
                    <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
                        </>
                    <div className="col-pad-left" id='main'>
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>NON-COHORT-DEFINING TREATMENT CHARACTERISTICS</h4>
                            </div>
                            {showTopErrMsg && <div className="clean-error ">
                                <ErrorField show={showTopErrMsg} message={CommonError_Msgs.Hard_Empty} />
                            </div>
                            }
                            <div className="survey-question-section">
                                <div className="survey-question-content">
                                    <div className="question-answer">

                                        {isNdmmSelected && (
                                            <>
                                                <div className="question-bot-sapce">
                                                    <div className='cohort-header'>
                                                        <span>
                                                            {NDMM.cohortTreatmentName}
                                                        </span>
                                                    </div>
                                                    <div className="question question-weight">

                                                        <span>Q27.</span>
                                                        <span className="quest-text-pad">
                                                            Provide additional information on <u>the second (2L) and third (3L)
                                                            {" "}
                                  <span
                                    data-tooltip-id="encounterTip"
                                    className="modal-popup"
                                    onClick={() =>
                                      setShowHideHyperlinkModal(true)
                                    }
                                  >
                                    lines of therapy (LOT)
                                  </span>
                                </u>{" "}
                                                                
                                                                
                                                                
                                                                that were received after first-line (1L)
                                                            treatment with{" "}
                                                            <span style={{ color: PageDesignConstants.fontColor }}>
                                                                {NDMM.cohortTreatmentName}
                                                                {!isStringEmpty(NDMM.superScriptText) && (
                                                                    <>
                                                                        <sup className="supscript">{NDMM.superScriptText.slice(0, 2)}</sup>
                                                                        {NDMM.superScriptText.slice(2)}
                                                                    </>
                                                                )}
                                                            </span>.
                                                        </span>
                                                    </div>
                                                    <div className="sub-question">
                                                        <table
                                                            style={{
                                                                border: "1px solid black",
                                                                borderCollapse: "collapse",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        textAlign: "center", width: "25%"
                                                                    }}>
                                                                        LOT number
                                                                    </th>
                                                                    <th style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        textAlign: "center", width: "15%"
                                                                    }}>
                                                                        Start date of LOT
                                                                    </th>
                                                                    <th style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        textAlign: "center", width: "20%"
                                                                    }}>
                                                                        Received in clinical trial or expanded access program (EAP)
                                                                    </th>
                                                                    <th style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        textAlign: "center", width: "40%"
                                                                    }}>
                                                                        Name of each prescribed treatment
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {q27AddInfoDetails && q27AddInfoDetails.length > 0 && (
                                                                    q27AddInfoDetails.map((row, idx) => (
                                                                        <tr key={idx}>
                                                                            <td
                                                                                style={{
                                                                                    border: "1px solid black",
                                                                                    padding: "8px",
                                                                                    alignContent: "baseline",
                                                                                    // width: "18%"
                                                                                }}
                                                                            >
                                                                                <div className=""> {/* double-dig-answer */}
                                                                                    <div className="">
                                                                                        <label>
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={idx}
                                                                                                name={"rbl_lot_num_select" + idx}
                                                                                                value={1}
                                                                                                checked={row.rbl_lot_num_select === 1}
                                                                                                onChange={HandleQ27FieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">
                                                                                                {
                                                                                                    // !isStringEmpty(row.lotName)
                                                                                                    //     ? row.lotName
                                                                                                    //     :
                                                                                                    <>
                                                                                                        {GetOrdinal(row.lotId)} LOT
                                                                                                    </>
                                                                                                }
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="">
                                                                                        <label className="long-text-option">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={idx}
                                                                                                name={"rbl_lot_num_select" + idx}
                                                                                                value={2}
                                                                                                checked={row.rbl_lot_num_select === 2}
                                                                                                onChange={HandleQ27FieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">The patient has not received a{" "}
                                                                                                {
                                                                                                    // !isStringEmpty(row.lotName)
                                                                                                    //     ? row.lotName
                                                                                                    //     :
                                                                                                    <>
                                                                                                        {GetOrdinal(row.lotId)} LOT
                                                                                                    </>
                                                                                                }
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <ErrorField show={row.A_empty} message={NonCohortTreatment_Msgs.Q27a} />
                                                                            </td>

                                                                            {(row.rbl_lot_num_select === 1)?
                                                                            (<td
                                                                                style={{
                                                                                    border: "1px solid black",
                                                                                    padding: "8px",
                                                                                    alignContent: "baseline"
                                                                                }}
                                                                            >
                                                                                    <>
                                                                                        <div className="">{/* double-dig-answer */}
                                                                                            <div className="long-text-option">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id={idx}
                                                                                                    name={"rbl_lot_start_date" + idx}
                                                                                                    value={1}
                                                                                                    checked={row.rbl_lot_start_date === 1}
                                                                                                    onChange={HandleQ27FieldChange}
                                                                                                />
                                                                                                <span className="date-field-padding">
                                                                                                    <DateControl
                                                                                                        ctrlId={`date_lot_start${idx}`}
                                                                                                        HandleDateChange={HandleQ27DateChange}
                                                                                                        optionId={idx}
                                                                                                        date={row.date_lot_start}
                                                                                                    />
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="long-text-option">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={idx}
                                                                                                        name={"rbl_lot_start_date" + idx}
                                                                                                        value={99}
                                                                                                        checked={row.rbl_lot_start_date === 99}
                                                                                                        onChange={HandleQ27FieldChange}
                                                                                                    />
                                                                                                    <span className="radio-text-padding">Start date unknown</span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <ErrorField show={row.B_empty} message={NonCohortTreatment_Msgs.Q27a} />
                                                                                        <ErrorField show={row.B_DateBeforeLot2} message={NonCohortTreatment_Msgs.q27b_LessThan2ndLot} />

                                                                                        <ErrorField show={row.B_DateBeforeIndex} message={NonCohortTreatment_Msgs.Q27b_DateBeforeIndex} />

                                                                                        <ErrorField show={row.B_DateBeyondEOF} message={NonCohortTreatment_Msgs.Q27b_LessThanEOF} />
                                                                                    </>
                                                                                
                                                                            </td>)
                                                                            : (
                                                                            <td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                            </td>)
                                                                            }
                                                                            
                                                                            {(row.rbl_lot_num_select === 1)?
                                                                           ( <td
                                                                                style={{
                                                                                    border: "1px solid black",
                                                                                    padding: "8px",
                                                                                    alignContent: "baseline"
                                                                                }}
                                                                            >
                                                                                
                                                                                    <>
                                                                                        <div className="">{/* double-dig-answer */}
                                                                                            <div>
                                                                                                <label>
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={idx}
                                                                                                        name={"rbl_received_clinical_trial" + idx}
                                                                                                        value={1}
                                                                                                        checked={row.rbl_received_clinical_trial === 1}
                                                                                                        onChange={HandleQ27FieldChange}
                                                                                                    />
                                                                                                    <span className="radio-text-padding">Yes</span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label>
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={idx}
                                                                                                        name={"rbl_received_clinical_trial" + idx}
                                                                                                        value={0}
                                                                                                        checked={row.rbl_received_clinical_trial === 0}
                                                                                                        onChange={HandleQ27FieldChange}
                                                                                                    />
                                                                                                    <span className="radio-text-padding">No</span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label>
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={idx}
                                                                                                        name={"rbl_received_clinical_trial" + idx}
                                                                                                        value={99}
                                                                                                        checked={row.rbl_received_clinical_trial === 99}
                                                                                                        onChange={HandleQ27FieldChange}
                                                                                                    />
                                                                                                    <span className="radio-text-padding">Unknown</span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <ErrorField show={row.C_empty} message={NonCohortTreatment_Msgs.Q27c} />
                                                                                    </>
                                                                                
                                                                            </td>) :
                                                                            (
                                                                            <td
                                                                                style={{
                                                                                    border: "1px solid black",
                                                                                    // alignContent: "flex-start",
                                                                                    textAlign: "center",
                                                                                    padding: "8px",
                                                                                    //textAlign: 'start'
                                                                                }}
                                                                            >
                                                                                <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>
    
                                                                        </td>)
                                                                            }

                                                                            {(row.rbl_lot_num_select === 1 )?  
                                                                            (<td
                                                                                style={{
                                                                                    border: "1px solid black",
                                                                                    padding: "8px",
                                                                                }}
                                                                            >
                                                                                
                                                                                    <>
                                                                                        <div className="">
                                                                                            {
                                                                                                prescribedTreatments && prescribedTreatments.length > 0 && (
                                                                                                    prescribedTreatments.map((category, index) => (
                                                                                                        <div key={index} style={{ paddingBottom: "10px" }}>
                                                                                                            <h6>{category?.category}</h6>
                                                                                                            {category?.treatments?.map(item => (
                                                                                                                <div key={item.prescribedTreatmentId}>
                                                                                                                    <label className={`long-text-option ${item.name === "Other, please specify" ? undefined : "radio-text-padding"} `} >
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            id={idx + "_" + item.prescribedTreatmentId}
                                                                                                                            name={"chk_q27_prescribed_tmt" + idx + "_" + item.prescribedTreatmentId}

                                                                                                                            checked={
                                                                                                                                row.selectedPrescribedTmtList &&
                                                                                                                                row.selectedPrescribedTmtList.length > 0 &&
                                                                                                                                row.selectedPrescribedTmtList.some(
                                                                                                                                    a => a.prescribedTreatmentId === item.prescribedTreatmentId
                                                                                                                                )
                                                                                                                            }
                                                                                                                            onChange={HandleQ27FieldChange}
                                                                                                                        />
                                                                                                                        {item.name === "Other, please specify" ?
                                                                                                                            (<span className="radio-text-padding">
                                                                                                                                {item.name}:{" "}
                                                                                                                                <textarea style={{ width: '264px' }}
                                                                                                                                    id={idx + "_" + item.prescribedTreatmentId}
                                                                                                                                    name="txt_prescribed_tmt_oth"
                                                                                                                                    type="text"
                                                                                                                                    value={row.selectedPrescribedTmtList?.find(a => a.prescribedTreatmentId === item.prescribedTreatmentId)
                                                                                                                                        ?.prescribedTreatmentOthTxt ?? ""}
                                                                                                                                    onChange={HandleQ27FieldChange}
                                                                                                                                    maxLength={300}
                                                                                                                                    className="option-fbox"
                                                                                                                                />
                                                                                                                                {/* <input
                                                                                                                                    id={idx + "_" + item.prescribedTreatmentId}
                                                                                                                                    name="txt_prescribed_tmt_oth"
                                                                                                                                    type="text"
                                                                                                                                    value={row.selectedPrescribedTmtList?.find(a => a.prescribedTreatmentId === item.prescribedTreatmentId)
                                                                                                                                        ?.prescribedTreatmentOthTxt ?? ""}
                                                                                                                                    onChange={HandleQ27FieldChange}
                                                                                                                                    maxLength={300}
                                                                                                                                    className="input-dash"
                                                                                                                                    style={{ width: '200px' }}
                                                                                                                                /> */}
                                                                                                                            </span>)
                                                                                                                            : (<span className="radio-text-padding">
                                                                                                                                {item.name}
                                                                                                                                {/* {!isStringEmpty(item.superScriptText) && (
                                                                                                                                    <>
                                                                                                                                        <sup className="supscript">{item.superScriptText.slice(0, 2)}</sup>
                                                                                                                                        {item.superScriptText.slice(2)}
                                                                                                                                    </>
                                                                                                                                )} */}
                                                                                                                            </span>)
                                                                                                                        }
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    )))
                                                                                            }
                                                                                            <div>
                                                                                                <label className="long-text-option">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={idx}
                                                                                                        name={"rb_prescribed_tmt_unk" + idx}
                                                                                                        checked={row.rb_prescribed_tmt_unk}
                                                                                                        onChange={HandleQ27FieldChange}
                                                                                                    />
                                                                                                    <span className="radio-text-padding">Unknown</span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <ErrorField show={row.D_empty} message={NonCohortTreatment_Msgs.Q27d} />
                                                                                        <ErrorField show={row.D_txt_empty} message={NonCohortTreatment_Msgs.Q27d_txt} />
                                                                                    </>
                                                                                
                                                                            </td>): (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)
                                                                    }
                                                                        </tr>
                                                                    )))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {q27AddInfoDetails?.find(a => a.lotId == LOT.LOT2)?.rbl_lot_num_select === 1 && (
                                                    <div className="question-bot-sapce">
                                                        <div className="question question-weight">
                                                            <span>Q28.</span>
                                                            <span className="quest-text-pad">
                                                                Describe additional information on <u><i>treatment discontinuation</i></u> and <u><i>refractory agents</i></u>
                                                                {" "}for the patient’s 2<sup>nd</sup> line of treatment.
                                                            </span>
                                                        </div>

                                                        <div className="sub-question">
                                                            <table
                                                                style={{
                                                                    border: "1px solid black",
                                                                    borderCollapse: "collapse",
                                                                    width: "100%",
                                                                }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            textAlign: "center",
                                                                            // width: "10%"
                                                                        }}>
                                                                            LOT number
                                                                        </th>
                                                                        <th style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            textAlign: "center",
                                                                            // width: "10%"
                                                                        }}>
                                                                            Discontinued treatment for this LOT?
                                                                        </th>
                                                                        <th style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            textAlign: "center",
                                                                            // width: "10%"
                                                                        }}>
                                                                            Date of LOT discontinuation
                                                                        </th>
                                                                        <th style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            textAlign: "center",
                                                                            //  width: "10%"
                                                                        }}>
                                                                            Refractory to any treatment agents?
                                                                        </th>
                                                                        <th style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            textAlign: "center",
                                                                            // width: "20%"
                                                                        }}>
                                                                            Which agents was the patient refractory to?
                                                                        </th>
                                                                        <th style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            textAlign: "center",
                                                                            // width: "40%"
                                                                        }}>
                                                                            Reason for advancement to the next LOT
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            // width: "10%"
                                                                        }}>
                                                                            <div>  {/*  className="double-dig-answer" */}
                                                                                <div className="">
                                                                                    <label>
                                                                                        <span className="radio-text-padding">{GetOrdinal(2)} LOT </span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            // width: "10%"
                                                                        }}>
                                                                            <div className="">{/* double-dig-answer */}
                                                                                <div>
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="q28_rbl_B"
                                                                                            name="q28_rbl_B"
                                                                                            value={1}
                                                                                            checked={nonCohortDetails.q28_rbl_B === 1}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">Yes</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="q28_rbl_B"
                                                                                            name="q28_rbl_B"
                                                                                            value={0}
                                                                                            checked={nonCohortDetails.q28_rbl_B === 0}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">No</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="q28_rbl_B"
                                                                                            name="q28_rbl_B"
                                                                                            value={99}
                                                                                            checked={nonCohortDetails.q28_rbl_B === 99}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">Unknown</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <ErrorField show={nonCohortDetails.q28_B_empty_err} message={NonCohortTreatment_Msgs.Q32b} style={{ paddingLeft: '0px' }} />
                                                                            <ErrorField show={nonCohortDetails.q28_B_invalid_combo_err} message={NonCohortTreatment_Msgs.q28b_InvalidCombo} style={{ paddingLeft: '0px' }} />
                                                                        </td>
                                                                        {(nonCohortDetails.q28_rbl_B === 1)?
                                                                        (<td style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            // width: "10%"
                                                                        }}>
                                                                            
                                                                                <>
                                                                                    <div className="">{/* double-dig-answer */}
                                                                                        <div className="long-text-option">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id="q28_rbl_C"
                                                                                                name="q28_rbl_C"
                                                                                                value={1}
                                                                                                checked={nonCohortDetails.q28_rbl_C === 1}
                                                                                                onChange={HandleFieldChange}
                                                                                            />
                                                                                            <span className="date-field-padding">
                                                                                                <DateControl
                                                                                                    ctrlId={"q28_date_C"}
                                                                                                    HandleDateChange={HandleDateChange}
                                                                                                    date={nonCohortDetails.q28_date_C}
                                                                                                />
                                                                                            </span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id="q28_rbl_C"
                                                                                                    name="q28_rbl_C"
                                                                                                    value={99}
                                                                                                    checked={nonCohortDetails.q28_rbl_C === 99}
                                                                                                    onChange={HandleFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">Date of discontinuation unknown</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ErrorField show={nonCohortDetails.q28_C_empty_err} message={NonCohortTreatment_Msgs.Q32c} style={{ paddingLeft: '0px' }} />
                                                                                    <ErrorField show={nonCohortDetails.q28_C_before_q27_B_err} message={NonCohortTreatment_Msgs.q28_C_before_q27_B_err} style={{ paddingLeft: '0px' }} />
                                                                                    <ErrorField show={nonCohortDetails.q28_C_beyond_ndmm_3rd_LOT_err} message={NonCohortTreatment_Msgs.q28_C_beyond_ndmm_3rd_LOT_err} style={{ paddingLeft: '0px' }} />
                                                                                    <ErrorField show={nonCohortDetails.q28_C_beyond_eof_err} message={NonCohortTreatment_Msgs.Q32c_LessThanEOF} style={{ paddingLeft: '0px' }} />
                                                                                </>
                                                                            
                                                                        </td>): (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)
                                                                        }
                                                                        <td style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            // width: "10%"
                                                                        }}>
                                                                            <div className="">{/* double-dig-answer */}
                                                                                <div>
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="q28_rbl_D"
                                                                                            name="q28_rbl_D"
                                                                                            value={1}
                                                                                            checked={nonCohortDetails.q28_rbl_D === 1}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">Yes</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="q28_rbl_D"
                                                                                            name="q28_rbl_D"
                                                                                            value={0}
                                                                                            checked={nonCohortDetails.q28_rbl_D === 0}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">No</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="q28_rbl_D"
                                                                                            name="q28_rbl_D"
                                                                                            value={99}
                                                                                            checked={nonCohortDetails.q28_rbl_D === 99}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">Unknown</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <ErrorField show={nonCohortDetails.q28_D_empty_err} message={NonCohortTreatment_Msgs.Q32d} style={{ paddingLeft: '0px' }} />
                                                                        </td>

                                                                        {(nonCohortDetails.q28_rbl_D === 1)?
                                                                        (<td style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            // width: "10%"
                                                                        }}>
                                                                            
                                                                                <>
                                                                                    <div className="">
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="q28_chk_E_1"
                                                                                                    name="q28_chk_E_1"
                                                                                                    checked={nonCohortDetails.q28_chk_E_1}
                                                                                                    onChange={HandleFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">
                                                                                                    Revlimid
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="q28_chk_E_2"
                                                                                                    name="q28_chk_E_2"
                                                                                                    checked={nonCohortDetails.q28_chk_E_2}
                                                                                                    onChange={HandleFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">
                                                                                                    PI
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="q28_chk_E_3"
                                                                                                    name="q28_chk_E_3"
                                                                                                    checked={nonCohortDetails.q28_chk_E_3}
                                                                                                    onChange={HandleFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">
                                                                                                    Mabs
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="q28_chk_E_4"
                                                                                                    name="q28_chk_E_4"
                                                                                                    checked={nonCohortDetails.q28_chk_E_4}
                                                                                                    onChange={HandleFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">
                                                                                                    BCMA
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="q28_chk_E_5"
                                                                                                    name="q28_chk_E_5"
                                                                                                    checked={nonCohortDetails.q28_chk_E_5}
                                                                                                    onChange={HandleFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">
                                                                                                    Other
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id="q28_rb_E_unk"
                                                                                                    name="q28_rb_E_unk"
                                                                                                    checked={nonCohortDetails.q28_rb_E_unk}
                                                                                                    onChange={HandleFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">Unknown</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ErrorField show={nonCohortDetails.q28_E_empty_err} message={NonCohortTreatment_Msgs.Q32e} style={{ paddingLeft: '0px' }} />
                                                                                </>
                                                                            
                                                                        </td>): (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)
                                                                        }

                                                                        {(q27AddInfoDetails?.find(a => a.lotId == LOT.LOT3)?.rbl_lot_num_select === 1)?
                                                                        (<td style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            // width: "40%"
                                                                        }}>
                                                                            <div className="">
                                                                                <div>
                                                                                    <label className="long-text-option">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="q28_chk_F_1"
                                                                                            name="q28_chk_F_1"
                                                                                            checked={nonCohortDetails.q28_chk_F_1}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">
                                                                                            Adverse event
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label className="long-text-option">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="q28_chk_F_2"
                                                                                            name="q28_chk_F_2"
                                                                                            checked={nonCohortDetails.q28_chk_F_2}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">
                                                                                            Disease progression
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label className="long-text-option">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="q28_chk_F_3"
                                                                                            name="q28_chk_F_3"
                                                                                            checked={nonCohortDetails.q28_chk_F_3}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">
                                                                                            Insufficient response without progression
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label className="long-text-option">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="q28_chk_F_4"
                                                                                            name="q28_chk_F_4"
                                                                                            checked={nonCohortDetails.q28_chk_F_4}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">
                                                                                            Cost or insurance coverage
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label className="long-text-option">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="q28_chk_F_5"
                                                                                            name="q28_chk_F_5"
                                                                                            checked={nonCohortDetails.q28_chk_F_5}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">
                                                                                            Patient's decision (if none of the above)
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label className="long-text-option">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="q28_chk_F_6"
                                                                                            name="q28_chk_F_6"
                                                                                            checked={nonCohortDetails.q28_chk_F_6}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">
                                                                                            Physician's decision (if none of the above)
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label className="long-text-option">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="q28_chk_F_7"
                                                                                            name="q28_chk_F_7"
                                                                                            checked={nonCohortDetails.q28_chk_F_7}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">
                                                                                            Other
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label className="long-text-option">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="q28_rb_F_unk"
                                                                                            name="q28_rb_F_unk"
                                                                                            checked={nonCohortDetails.q28_rb_F_unk}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">Unknown</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <ErrorField show={nonCohortDetails.q28_F_empty_err} message={NonCohortTreatment_Msgs.Q33} style={{ paddingLeft: '0px' }} />

                                                                        </td>): (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)
                                                                        }
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                )}
                                                {q27AddInfoDetails?.find(a => a.lotId == LOT.LOT3)?.rbl_lot_num_select === 1 && (
                                                    <>
                                                        <div className="question-bot-sapce">
                                                            <div className="question question-weight">
                                                                <span>Q29.</span>
                                                                <span className="quest-text-pad">
                                                                    Describe additional information on  <u><i>treatment discontinuation</i></u> and <u><i>refractory agents</i></u>
                                                                    {" "}for the patient’s {GetOrdinal(3)} line of treatment.
                                                                </span>
                                                            </div>

                                                            <div className="sub-question">
                                                                <table
                                                                    style={{
                                                                        border: "1px solid black",
                                                                        borderCollapse: "collapse",
                                                                        width: "100%",
                                                                    }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                                textAlign: "center",
                                                                                // width: "10%"
                                                                            }}>
                                                                                LOT number
                                                                            </th>
                                                                            <th style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                                textAlign: "center",
                                                                                // width: "10%"
                                                                            }}>
                                                                                Discontinued treatment for this LOT?
                                                                            </th>
                                                                            <th style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                                textAlign: "center",
                                                                                // width: "10%"
                                                                            }}>
                                                                                Date of LOT discontinuation
                                                                            </th>
                                                                            <th style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                                textAlign: "center",
                                                                                // width: "10%"
                                                                            }}>
                                                                                Refractory to any treatment agents?
                                                                            </th>
                                                                            <th style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                                textAlign: "center",
                                                                                // width: "10%"
                                                                            }}>
                                                                                Which agents was the patient refractory to?
                                                                            </th>
                                                                            <th style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                                textAlign: "center",
                                                                                // width: "50%"
                                                                            }}>
                                                                                Did patient advance to a next 
                                                                                {" "}
                                  <span
                                    data-tooltip-id="encounterTip"
                                    className="modal-popup"
                                    onClick={() =>
                                      setShowHideHyperlinkModal(true)
                                    }
                                  >
                                    line of therapy
                                  </span>?
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                                // width: "10%"
                                                                            }}>
                                                                                <div>  {/*  className="double-dig-answer" */}
                                                                                    <div className="">
                                                                                        <label>
                                                                                            <span className="radio-text-padding">{GetOrdinal(3)} LOT </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                                // width: "10%"
                                                                            }}>
                                                                                <div className="">{/* double-dig-answer */}
                                                                                    <div>
                                                                                        <label>
                                                                                            <input
                                                                                                type="radio"
                                                                                                id="q29_rbl_B"
                                                                                                name="q29_rbl_B"
                                                                                                value={1}
                                                                                                checked={nonCohortDetails.q29_rbl_B === 1}
                                                                                                onChange={HandleFieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">Yes</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label>
                                                                                            <input
                                                                                                type="radio"
                                                                                                id="q29_rbl_B"
                                                                                                name="q29_rbl_B"
                                                                                                value={0}
                                                                                                checked={nonCohortDetails.q29_rbl_B === 0}
                                                                                                onChange={HandleFieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">No</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label>
                                                                                            <input
                                                                                                type="radio"
                                                                                                id="q29_rbl_B"
                                                                                                name="q29_rbl_B"
                                                                                                value={99}
                                                                                                checked={nonCohortDetails.q29_rbl_B === 99}
                                                                                                onChange={HandleFieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">Unknown</span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <ErrorField show={nonCohortDetails.q29_B_empty_err} message={NonCohortTreatment_Msgs.Q32b} style={{ paddingLeft: '0px' }} />
                                                                            </td>
                                                                            {(nonCohortDetails.q29_rbl_B === 1)?
                                                                            (<td style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                                // width: "10%"
                                                                            }}>
                                                                                
                                                                                    <>
                                                                                        <div className="">{/* double-dig-answer */}
                                                                                            <div className="long-text-option">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id="q29_rbl_C"
                                                                                                    name="q29_rbl_C"
                                                                                                    value={1}
                                                                                                    checked={nonCohortDetails.q29_rbl_C === 1}
                                                                                                    onChange={HandleFieldChange}
                                                                                                />
                                                                                                <span className="date-field-padding">
                                                                                                    <DateControl
                                                                                                        ctrlId={"q29_date_C"}
                                                                                                        HandleDateChange={HandleDateChange}
                                                                                                        date={nonCohortDetails.q29_date_C}
                                                                                                    />
                                                                                                </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="long-text-option">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id="q29_rbl_C"
                                                                                                        name="q29_rbl_C"
                                                                                                        value={99}
                                                                                                        checked={nonCohortDetails.q29_rbl_C === 99}
                                                                                                        onChange={HandleFieldChange}
                                                                                                    />
                                                                                                    <span className="radio-text-padding">Date of discontinuation unknown</span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <ErrorField show={nonCohortDetails.q29_C_empty_err} message={NonCohortTreatment_Msgs.Q32c} style={{ paddingLeft: '0px' }} />
                                                                                        <ErrorField show={nonCohortDetails.q29_C_before_q27_B_err} message={NonCohortTreatment_Msgs.q29_C_before_q27_B_err} style={{ paddingLeft: '0px' }} />
                                                                                        <ErrorField show={nonCohortDetails.q29_C_beyond_eof_err} message={NonCohortTreatment_Msgs.Q32c_LessThanEOF} style={{ paddingLeft: '0px' }} />
                                                                                    </>
                                                                                
                                                                            </td>): (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)
                                                                            }
                                                                            <td style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                                // width: "10%"
                                                                            }}>
                                                                                <div className="">{/* double-dig-answer */}
                                                                                    <div>
                                                                                        <label>
                                                                                            <input
                                                                                                type="radio"
                                                                                                id="q29_rbl_D"
                                                                                                name="q29_rbl_D"
                                                                                                value={1}
                                                                                                checked={nonCohortDetails.q29_rbl_D === 1}
                                                                                                onChange={HandleFieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">Yes</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label>
                                                                                            <input
                                                                                                type="radio"
                                                                                                id="q29_rbl_D"
                                                                                                name="q29_rbl_D"
                                                                                                value={0}
                                                                                                checked={nonCohortDetails.q29_rbl_D === 0}
                                                                                                onChange={HandleFieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">No</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label>
                                                                                            <input
                                                                                                type="radio"
                                                                                                id="q29_rbl_D"
                                                                                                name="q29_rbl_D"
                                                                                                value={99}
                                                                                                checked={nonCohortDetails.q29_rbl_D === 99}
                                                                                                onChange={HandleFieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">Unknown</span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <ErrorField show={nonCohortDetails.q29_D_empty_err} message={NonCohortTreatment_Msgs.Q32d} style={{ paddingLeft: '0px' }} />
                                                                            </td>

                                                                            {(nonCohortDetails.q29_rbl_D === 1)?
                                                                            (<td style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                                // width: "10%"
                                                                            }}>
                                                                                
                                                                                    <>
                                                                                        <div className="">
                                                                                            <div>
                                                                                                <label className="long-text-option">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        id="q29_chk_E_1"
                                                                                                        name="q29_chk_E_1"
                                                                                                        checked={nonCohortDetails.q29_chk_E_1}
                                                                                                        onChange={HandleFieldChange}
                                                                                                    />
                                                                                                    <span className="radio-text-padding">
                                                                                                        Revlimid
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="long-text-option">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        id="q29_chk_E_2"
                                                                                                        name="q29_chk_E_2"
                                                                                                        checked={nonCohortDetails.q29_chk_E_2}
                                                                                                        onChange={HandleFieldChange}
                                                                                                    />
                                                                                                    <span className="radio-text-padding">
                                                                                                        PI
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="long-text-option">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        id="q29_chk_E_3"
                                                                                                        name="q29_chk_E_3"
                                                                                                        checked={nonCohortDetails.q29_chk_E_3}
                                                                                                        onChange={HandleFieldChange}
                                                                                                    />
                                                                                                    <span className="radio-text-padding">
                                                                                                        Mabs
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="long-text-option">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        id="q29_chk_E_4"
                                                                                                        name="q29_chk_E_4"
                                                                                                        checked={nonCohortDetails.q29_chk_E_4}
                                                                                                        onChange={HandleFieldChange}
                                                                                                    />
                                                                                                    <span className="radio-text-padding">
                                                                                                        BCMA
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="long-text-option">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        id="q29_chk_E_5"
                                                                                                        name="q29_chk_E_5"
                                                                                                        checked={nonCohortDetails.q29_chk_E_5}
                                                                                                        onChange={HandleFieldChange}
                                                                                                    />
                                                                                                    <span className="radio-text-padding">
                                                                                                        Other
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="long-text-option">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id="q29_rb_E_unk"
                                                                                                        name="q29_rb_E_unk"
                                                                                                        checked={nonCohortDetails.q29_rb_E_unk}
                                                                                                        onChange={HandleFieldChange}
                                                                                                    />
                                                                                                    <span className="radio-text-padding">Unknown</span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <ErrorField show={nonCohortDetails.q29_E_empty_err} message={NonCohortTreatment_Msgs.Q32e} style={{ paddingLeft: '0px' }} />
                                                                                    </>
                                                                                
                                                                            </td>): (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)
                                                                            }

                                                                            <td style={{
                                                                                border: "1px solid black",
                                                                                padding: "8px",
                                                                                // alignContent: "flex-start",
                                                                                // width: "50%"
                                                                            }}>
                                                                                <div className="">
                                                                                    {nonCohortDetails.q29_rbl_B === 0 &&
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id="q29_rbl_F"
                                                                                                    name="q29_rbl_F"
                                                                                                    value={1}
                                                                                                    checked={nonCohortDetails.q29_rbl_F === 1}
                                                                                                    onChange={HandleFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">3<sup>rd</sup> LOT still ongoing</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    }
                                                                                    {nonCohortDetails.q29_rbl_B === 1 &&
                                                                                        <>
                                                                                            <div>
                                                                                                <label className="long-text-option">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id="q29_rbl_F"
                                                                                                        name="q29_rbl_F"
                                                                                                        value={2}
                                                                                                        checked={nonCohortDetails.q29_rbl_F === 2}
                                                                                                        onChange={HandleFieldChange}
                                                                                                    />
                                                                                                    <span className="radio-text-padding">Yes, patient had a 4<sup>th</sup> LOT</span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="long-text-option">
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id="q29_rbl_F"
                                                                                                        name="q29_rbl_F"
                                                                                                        value={3}
                                                                                                        checked={nonCohortDetails.q29_rbl_F === 3}
                                                                                                        onChange={HandleFieldChange}
                                                                                                    />
                                                                                                    <span className="radio-text-padding">No, patient did not have a 4<sup>th</sup> LOT</span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    {(nonCohortDetails.q29_rbl_B === 0 || nonCohortDetails.q29_rbl_B === 1) &&
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id="q29_rbl_F"
                                                                                                    name="q29_rbl_F"
                                                                                                    value={99}
                                                                                                    checked={nonCohortDetails.q29_rbl_F === 99}
                                                                                                    onChange={HandleFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">Unknown</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    }
                                                                                    {(nonCohortDetails.q29_rbl_B == null || nonCohortDetails.q29_rbl_B === 99) &&
                                                                                        <div style={{ textAlign: "center" }}>
                                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>    
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                <ErrorField show={nonCohortDetails.q29_F_empty_err} message={NonCohortTreatment_Msgs.Q32f} style={{ paddingLeft: '0px' }} />
                                                                                {/* <ErrorField show={nonCohortDetails.q29_F_invalid_combo1_err} message={NonCohortTreatment_Msgs.Q32f_Invalid1} style={{ paddingLeft: '0px' }} /> */}
                                                                                {/* <ErrorField show={nonCohortDetails.q29_F_invalid_combo2_err} message={NonCohortTreatment_Msgs.Q32f_Invalid2} style={{ paddingLeft: '0px' }} /> */}

                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        {/* } */}
                                                        {
                                                            nonCohortDetails.q29_rbl_F === 2 &&
                                                            <div className="question-bot-sapce">
                                                                <div className="question question-weight">
                                                                    <span>Q30.</span>
                                                                    <span className="quest-text-pad">
                                                                        Provide the reason for advancement from the 3<sup>rd</sup> to the 4<sup>th</sup> LOT.
                                                                    </span>
                                                                </div>
                                                                <div className="double-dig-answer">
                                                                    <div>
                                                                        <label className="long-text-option">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="q30_chk_1"
                                                                                name="q30_chk_1"
                                                                                checked={nonCohortDetails.q30_chk_1}
                                                                                onChange={HandleFieldChange}
                                                                            />
                                                                            <span className="radio-text-padding">
                                                                                Adverse event
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <label className="long-text-option">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="q30_chk_2"
                                                                                name="q30_chk_2"
                                                                                checked={nonCohortDetails.q30_chk_2}
                                                                                onChange={HandleFieldChange}
                                                                            />
                                                                            <span className="radio-text-padding">
                                                                                Disease progression
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <label className="long-text-option">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="q30_chk_3"
                                                                                name="q30_chk_3"
                                                                                checked={nonCohortDetails.q30_chk_3}
                                                                                onChange={HandleFieldChange}
                                                                            />
                                                                            <span className="radio-text-padding">
                                                                                Insufficient response without progression
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <label className="long-text-option">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="q30_chk_4"
                                                                                name="q30_chk_4"
                                                                                checked={nonCohortDetails.q30_chk_4}
                                                                                onChange={HandleFieldChange}
                                                                            />
                                                                            <span className="radio-text-padding">
                                                                                Cost or insurance coverage
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <label className="long-text-option">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="q30_chk_5"
                                                                                name="q30_chk_5"
                                                                                checked={nonCohortDetails.q30_chk_5}
                                                                                onChange={HandleFieldChange}
                                                                            />
                                                                            <span className="radio-text-padding">
                                                                                Patient's decision (if none of the above)
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <label className="long-text-option">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="q30_chk_6"
                                                                                name="q30_chk_6"
                                                                                checked={nonCohortDetails.q30_chk_6}
                                                                                onChange={HandleFieldChange}
                                                                            />
                                                                            <span className="radio-text-padding">
                                                                                Physician's decision (if none of the above)
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <label className="long-text-option">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="q30_chk_7"
                                                                                name="q30_chk_7"
                                                                                checked={nonCohortDetails.q30_chk_7}
                                                                                onChange={HandleFieldChange}
                                                                            />
                                                                            <span className="radio-text-padding">
                                                                                Other
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <label className="long-text-option">
                                                                            <input
                                                                                type="radio"
                                                                                id="q30_rb_unk"
                                                                                name="q30_rb_unk"
                                                                                checked={nonCohortDetails.q30_rb_unk}
                                                                                onChange={HandleFieldChange}
                                                                            />
                                                                            <span className="radio-text-padding">Unknown</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <ErrorField show={nonCohortDetails.q30_empty_err} message={NonCohortTreatment_Msgs.Q30} style={{ paddingLeft: '0px' }} />
                                                            </div>
                                                        }
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {/* LOOP Q31-Q33 */}
                                        {bispecificsRptList.map((row, idx) => (
                                            <div className="dependent-section">
                                                <div className='cohort-header'>
                                                    <span>
                                                        {row.cohortTreatmentName}
                                                        {!isStringEmpty(row.superScriptText) && (
                                                            <>
                                                                <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                                                {row.superScriptText.slice(2)}
                                                            </>
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="question-bot-sapce">
                                                    <div className="question question-weight">
                                                        <span>Q31.</span>
                                                        <span className="quest-text-pad">
                                                            Provide additional information on <u>the subsequent 
                                                            {" "}
                                  <span
                                    data-tooltip-id="encounterTip"
                                    className="modal-popup"
                                    onClick={() =>
                                      setShowHideHyperlinkModal(true)
                                    }
                                  >
                                    line of therapy (LOT)
                                  </span>
                                </u>{" "}
                                 that was received after treatment
                                                            with <span style={{ color: PageDesignConstants.fontColor }}>
                                                                {row.cohortTreatmentName}
                                                                {!isStringEmpty(row.superScriptText) && (
                                                                    <>
                                                                        <sup className="supscript">{row.superScriptText.slice(0, 2)}</sup>
                                                                        {row.superScriptText.slice(2)}
                                                                    </>
                                                                )}
                                                            </span>.
                                                        </span>
                                                    </div>
                                                    <div className="sub-question">
                                                        <table
                                                            style={{
                                                                border: "1px solid black",
                                                                borderCollapse: "collapse",
                                                                width: "100%",
                                                            }}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        textAlign: "center",
                                                                        width: "25%"
                                                                    }}>
                                                                        LOT number
                                                                    </th>
                                                                    <th style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        textAlign: "center",
                                                                        width: "15%"
                                                                    }}>
                                                                        Start date of LOT
                                                                    </th>
                                                                    <th style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        textAlign: "center",
                                                                        width: "20%"
                                                                    }}>
                                                                        Received in clinical trial or expanded access program (EAP)
                                                                    </th>
                                                                    <th style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        textAlign: "center",
                                                                        width: "40%"
                                                                    }}>
                                                                        Name of each prescribed treatment
                                                                    </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        // width: "10%"
                                                                        alignContent: "baseline",
                                                                    }}>
                                                                        <div className=""> {/* double-dig-answer */}
                                                                            <div className="">
                                                                                <label className="long-text-option">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id={idx}
                                                                                        name={"q31_rbl_A" + idx}
                                                                                        value={1}
                                                                                        checked={row.q31_rbl_A === 1}
                                                                                        onChange={HandleRepeaterFieldChange}
                                                                                    />
                                                                                    <span className="radio-text-padding">
                                                                                        <span style={{ color: PageDesignConstants.fontColor }}>
                                                                                            {
                                                                                                !isStringEmpty(row.bispecificsLotPlus1)
                                                                                                    ? GetOrdinal(row.bispecificsLotPlus1)
                                                                                                    :
                                                                                                    <>
                                                                                                        [Bispecifics/CAR-T LOT + 1]
                                                                                                    </>
                                                                                            }
                                                                                        </span>{""} LOT
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="">
                                                                                <label className="long-text-option">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id={idx}
                                                                                        name={"q31_rbl_A" + idx}
                                                                                        value={2}
                                                                                        checked={row.q31_rbl_A === 2}
                                                                                        onChange={HandleRepeaterFieldChange}
                                                                                    />
                                                                                    <span className="radio-text-padding">The patient has not received a subsequent LOT
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorField show={row.q31_A_empty_err} message={NonCohortTreatment_Msgs.Q31a} />
                                                                    </td>
                                                                    {(row.q31_rbl_A === 1)?
                                                                    (<td style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        // width: "10%"
                                                                        alignContent: "baseline",
                                                                    }}>
                                                                        
                                                                            <>
                                                                                <div className="">{/* double-dig-answer */}
                                                                                    <div className="long-text-option">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id={idx}
                                                                                            name={"q31_rbl_B" + idx}
                                                                                            value={1}
                                                                                            checked={row.q31_rbl_B === 1}
                                                                                            onChange={HandleRepeaterFieldChange}
                                                                                        />
                                                                                        <span className="date-field-padding">
                                                                                            <DateControl
                                                                                                ctrlId={"q31_date_B" + idx}
                                                                                                HandleDateChange={HandleRepeaterDateChange}
                                                                                                optionId={idx}
                                                                                                date={row.q31_date_B}
                                                                                            />
                                                                                        </span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label className="long-text-option">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={idx}
                                                                                                name={"q31_rbl_B" + idx}
                                                                                                value={99}
                                                                                                checked={row.q31_rbl_B === 99}
                                                                                                onChange={HandleRepeaterFieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">Start date unknown</span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <ErrorField show={row.q31_B_empty_err} message={NonCohortTreatment_Msgs.Q31b} />
                                                                                <ErrorField show={row.q31_B_beyond_eof_err} message={NonCohortTreatment_Msgs.q31_B_beyond_eof_err} />
                                                                                <ErrorField show={row.q31_B_before_index_err} message={NonCohortTreatment_Msgs.q31_B_before_index_err} />
                                                                            </>
                                                                        
                                                                    </td>): (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)
                                                                        }

                                                                    {(row.q31_rbl_A === 1)?
                                                                    (<td style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        alignContent: "baseline",
                                                                        // width: "10%"
                                                                    }}>
                                                                        
                                                                            <>
                                                                                <div className="">{/* double-dig-answer */}
                                                                                    <div>
                                                                                        <label>
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={idx}
                                                                                                name={"q31_rbl_C" + idx}
                                                                                                value={1}
                                                                                                checked={row.q31_rbl_C === 1}
                                                                                                onChange={HandleRepeaterFieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">Yes</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label>
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={idx}
                                                                                                name={"q31_rbl_C" + idx}
                                                                                                value={0}
                                                                                                checked={row.q31_rbl_C === 0}
                                                                                                onChange={HandleRepeaterFieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">No</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label>
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={idx}
                                                                                                name={"q31_rbl_C" + idx}
                                                                                                value={99}
                                                                                                checked={row.q31_rbl_C === 99}
                                                                                                onChange={HandleRepeaterFieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">Unknown</span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <ErrorField show={row.q31_C_empty_err} message={NonCohortTreatment_Msgs.Q31c} />
                                                                            </>
                                                                        
                                                                    </td>): (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)
                                                                    }
                                                                    {(row.q31_rbl_A === 1)?
                                                                    (<td style={{
                                                                        border: "1px solid black",
                                                                        padding: "8px",
                                                                        // width: "10%"
                                                                    }}
                                                                    >
                                                                        
                                                                            <>
                                                                                <div className="">
                                                                                    {
                                                                                        prescribedTreatments && prescribedTreatments.length > 0 && (
                                                                                            prescribedTreatments.map((category, index) => (
                                                                                                <div key={index} style={{ paddingBottom: "10px" }}>
                                                                                                    <h6>{category?.category}</h6>{/*  style={{ marginBottom: "0px" }} */}
                                                                                                    {category?.treatments?.map(item => (
                                                                                                        <div key={item.prescribedTreatmentId}>
                                                                                                            <label className={`long-text-option ${item.name === "Other, please specify" ? undefined : "radio-text-padding"} `} >
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    id={idx + "_" + item.prescribedTreatmentId}
                                                                                                                    name={"q31_chk_prescribed_tmt" + idx + "_" + item.prescribedTreatmentId}

                                                                                                                    checked={
                                                                                                                        row.q31_D_selectedPrescribedTmtList &&
                                                                                                                        row.q31_D_selectedPrescribedTmtList.length > 0 &&
                                                                                                                        row.q31_D_selectedPrescribedTmtList.some(
                                                                                                                            a => a.prescribedTreatmentId === item.prescribedTreatmentId
                                                                                                                        )
                                                                                                                    }
                                                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                                                />
                                                                                                                {item.name === "Other, please specify" ?
                                                                                                                    (<span className="radio-text-padding">
                                                                                                                        {item.name}:{" "}
                                                                                                                        <textarea style={{ width: '264px' }}
                                                                                                                            id={idx + "_" + item.prescribedTreatmentId}
                                                                                                                            name="q31_txt_prescribed_tmt_oth"
                                                                                                                            type="text"
                                                                                                                            value={row.q31_D_selectedPrescribedTmtList?.find(a => a.prescribedTreatmentId === item.prescribedTreatmentId)
                                                                                                                                ?.prescribedTreatmentOthTxt ?? ""}
                                                                                                                            onChange={HandleRepeaterFieldChange}
                                                                                                                            maxLength={300}
                                                                                                                            className="option-fbox"
                                                                                                                        />
                                                                                                                        {/* <input
                                                                                                                            id={idx + "_" + item.prescribedTreatmentId}
                                                                                                                            name="q31_txt_prescribed_tmt_oth"
                                                                                                                            type="text"
                                                                                                                            value={row.q31_D_selectedPrescribedTmtList?.find(a => a.prescribedTreatmentId === item.prescribedTreatmentId)
                                                                                                                                ?.prescribedTreatmentOthTxt ?? ""}
                                                                                                                            onChange={HandleRepeaterFieldChange}
                                                                                                                            maxLength={300}
                                                                                                                            className="input-dash"
                                                                                                                            style={{ width: '200px' }}
                                                                                                                        /> */}
                                                                                                                    </span>)
                                                                                                                    : (<span className="radio-text-padding">
                                                                                                                        {item.name}
                                                                                                                        {/* {!isStringEmpty(item.superScriptText) && (
                                                                                                                            <>
                                                                                                                                <sup className="supscript">{item.superScriptText.slice(0, 2)}</sup>
                                                                                                                                {item.superScriptText.slice(2)}
                                                                                                                            </>
                                                                                                                        )} */}
                                                                                                                    </span>)
                                                                                                                }
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            )))
                                                                                    }
                                                                                    <div>
                                                                                        <label className="long-text-option">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={idx}
                                                                                                name={"q31_D_rb_unk" + idx}
                                                                                                checked={row.q31_D_rb_unk}
                                                                                                onChange={HandleRepeaterFieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">Unknown</span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <ErrorField show={row.q31_D_empty_err} message={NonCohortTreatment_Msgs.Q31d} />
                                                                                <ErrorField show={row.q31_D_txt_empty_err} message={NonCohortTreatment_Msgs.Q31d_txt} />
                                                                            </>
                                                                        
                                                                    </td>): (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)
                                                                    }
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {row.q31_rbl_A === 1 &&
                                                    <div className="question-bot-sapce">
                                                        <div className="question question-weight">
                                                            <span>Q32.</span>
                                                            <span className="quest-text-pad">
                                                                Describe additional information on <u><i>treatment discontinuation</i></u> and <u><i>refractory agents</i></u>
                                                                {" "}for the patient’s subsequent line of treatment.

                                                            </span>
                                                        </div>
                                                        <div className="sub-question">
                                                            <table
                                                                style={{
                                                                    border: "1px solid black",
                                                                    borderCollapse: "collapse",
                                                                    width: "100%",
                                                                }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            textAlign: "center",
                                                                            //width: "10%" 
                                                                        }}>
                                                                            LOT number
                                                                        </th>
                                                                        <th style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            textAlign: "center",
                                                                            //width: "10%"
                                                                        }}>
                                                                            Discontinued treatment for this LOT?
                                                                        </th>
                                                                        <th style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            textAlign: "center",
                                                                            //width: "10%" 
                                                                        }}>
                                                                            Date of LOT discontinuation
                                                                        </th>
                                                                        <th style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            textAlign: "center",
                                                                            //width: "10%"
                                                                        }}>
                                                                            Refractory to any treatment agents?
                                                                        </th>
                                                                        <th style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            textAlign: "center",
                                                                            //width: "10%"
                                                                        }}>
                                                                            Which agents was the patient refractory to?
                                                                        </th>
                                                                        <th style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            textAlign: "center",
                                                                            //width: "50%"
                                                                        }}>
                                                                            Did patient advance to a next 
                                                                            {" "}
                                  <span
                                    data-tooltip-id="encounterTip"
                                    className="modal-popup"
                                    onClick={() =>
                                      setShowHideHyperlinkModal(true)
                                    }
                                  >
                                    line of therapy
                                  </span>?
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            // width: "10%"
                                                                        }}>
                                                                            <div>  {/*  className="double-dig-answer" */}
                                                                                <div className="">
                                                                                    <label>
                                                                                        <span className="radio-text-padding">
                                                                                            <span style={{ color: PageDesignConstants.fontColor }}>
                                                                                                {
                                                                                                    !isStringEmpty(row.bispecificsLotPlus1)
                                                                                                        ? GetOrdinal(row.bispecificsLotPlus1)
                                                                                                        :
                                                                                                        <>
                                                                                                            [Bispecifics/CAR-T LOT + 1]
                                                                                                        </>
                                                                                                }{""} LOT
                                                                                            </span>
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            // width: "10%"
                                                                        }}>
                                                                            <div className="">{/* double-dig-answer */}
                                                                                <div>
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            id={idx}
                                                                                            name={"q32_rbl_B" + idx}
                                                                                            value={1}
                                                                                            checked={row.q32_rbl_B === 1}
                                                                                            onChange={HandleRepeaterFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">Yes</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            id={idx}
                                                                                            name={"q32_rbl_B" + idx}
                                                                                            value={0}
                                                                                            checked={row.q32_rbl_B === 0}
                                                                                            onChange={HandleRepeaterFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">No</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            id={idx}
                                                                                            name={"q32_rbl_B" + idx}
                                                                                            value={99}
                                                                                            checked={row.q32_rbl_B === 99}
                                                                                            onChange={HandleRepeaterFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">Unknown</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <ErrorField show={row.q32_B_empty_err} message={NonCohortTreatment_Msgs.Q32b} style={{ paddingLeft: '0px' }} />
                                                                        </td>

                                                                        {(row.q32_rbl_B === 1)?
                                                                        (<td style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            // width: "10%"
                                                                        }}>
                                                                            
                                                                                <>
                                                                                    <div className="">{/* double-dig-answer */}
                                                                                        <div className="long-text-option">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={idx}
                                                                                                name={"q32_rbl_C" + idx}
                                                                                                value={1}
                                                                                                checked={row.q32_rbl_C === 1}
                                                                                                onChange={HandleRepeaterFieldChange}
                                                                                            />
                                                                                            <span className="date-field-padding">
                                                                                                <DateControl
                                                                                                    ctrlId={"q32_date_C" + idx}
                                                                                                    HandleDateChange={HandleRepeaterDateChange}
                                                                                                    optionId={idx}
                                                                                                    date={row.q32_date_C}
                                                                                                />
                                                                                            </span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id={idx}
                                                                                                    name={"q32_rbl_C" + idx}
                                                                                                    value={99}
                                                                                                    checked={row.q32_rbl_C === 99}
                                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">Date of discontinuation unknown</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ErrorField show={row.q32_C_empty_err} message={NonCohortTreatment_Msgs.Q32c} style={{ paddingLeft: '0px' }} />
                                                                                    <ErrorField show={row.q32_C_before_q31_B_err} message={NonCohortTreatment_Msgs.q32_MoreThanNxtLotStart} style={{ paddingLeft: '0px' }} />
                                                                                    <ErrorField show={row.q32_C_beyond_eof_err} message={NonCohortTreatment_Msgs.Q32c_LessThanEOF} style={{ paddingLeft: '0px' }} />
                                                                                </>
                                                                            
                                                                        </td>): (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)
                                                                        }

                                                                        <td style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            // width: "10%"
                                                                        }}>
                                                                            <div className="">{/* double-dig-answer */}
                                                                                <div>
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            id={idx}
                                                                                            name={"q32_rbl_D" + idx}
                                                                                            value={1}
                                                                                            checked={row.q32_rbl_D === 1}
                                                                                            onChange={HandleRepeaterFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">Yes</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            id={idx}
                                                                                            name={"q32_rbl_D" + idx}
                                                                                            value={0}
                                                                                            checked={row.q32_rbl_D === 0}
                                                                                            onChange={HandleRepeaterFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">No</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <label>
                                                                                        <input
                                                                                            type="radio"
                                                                                            id={idx}
                                                                                            name={"q32_rbl_D" + idx}
                                                                                            value={99}
                                                                                            checked={row.q32_rbl_D === 99}
                                                                                            onChange={HandleRepeaterFieldChange}
                                                                                        />
                                                                                        <span className="radio-text-padding">Unknown</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <ErrorField show={row.q32_D_empty_err} message={NonCohortTreatment_Msgs.Q32d} style={{ paddingLeft: '0px' }} />
                                                                        </td>

                                                                        {(row.q32_rbl_D === 1)?
                                                                        (<td style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            // width: "10%"
                                                                        }}>
                                                                            
                                                                                <>
                                                                                    <div className="">
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={idx}
                                                                                                    name={"q32_chk_E_1_" + idx}
                                                                                                    checked={row.q32_chk_E_1}
                                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">
                                                                                                    Revlimid
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={idx}
                                                                                                    name={"q32_chk_E_2_" + idx}
                                                                                                    checked={row.q32_chk_E_2}
                                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">
                                                                                                    PI
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={idx}
                                                                                                    name={"q32_chk_E_3_" + idx}
                                                                                                    checked={row.q32_chk_E_3}
                                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">
                                                                                                    Mabs
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={idx}
                                                                                                    name={"q32_chk_E_4_" + idx}
                                                                                                    checked={row.q32_chk_E_4}
                                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">
                                                                                                    BCMA
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={idx}
                                                                                                    name={"q32_chk_E_5_" + idx}
                                                                                                    checked={row.q32_chk_E_5}
                                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">
                                                                                                    Other
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id={idx}
                                                                                                    name={"q32_rb_E_unk_" + idx}
                                                                                                    checked={row.q32_rb_E_unk}
                                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">Unknown</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ErrorField show={row.q32_E_empty_err} message={NonCohortTreatment_Msgs.Q32e} style={{ paddingLeft: '0px' }} />
                                                                                </>
                                                                            
                                                                        </td>): (<td
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)
                                                                        }

                                                                        <td style={{
                                                                            border: "1px solid black",
                                                                            padding: "8px",
                                                                            // alignContent: "flex-start",
                                                                            // width: "10%"
                                                                        }}>
                                                                            <div className="">
                                                                                {row.q32_rbl_B === 0 &&
                                                                                    <div>
                                                                                        <label className="long-text-option">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={idx}
                                                                                                name={"q32_rbl_F" + idx}
                                                                                                value={1}
                                                                                                checked={row.q32_rbl_F === 1}
                                                                                                onChange={HandleRepeaterFieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">This LOT is still ongoing</span>
                                                                                        </label>
                                                                                    </div>
                                                                                }
                                                                                {(row.q32_rbl_B === 1) &&
                                                                                    <>
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id={idx}
                                                                                                    name={"q32_rbl_F" + idx}
                                                                                                    value={2}
                                                                                                    checked={row.q32_rbl_F === 2}
                                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">Yes, patient had a subsequent LOT</span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="long-text-option">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id={idx}
                                                                                                    name={"q32_rbl_F" + idx}
                                                                                                    value={3}
                                                                                                    checked={row.q32_rbl_F === 3}
                                                                                                    onChange={HandleRepeaterFieldChange}
                                                                                                />
                                                                                                <span className="radio-text-padding">No, patient did not have a subsequent LOT</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                                {(row.q32_rbl_B === 0 || row.q32_rbl_B === 1) &&
                                                                                    <div>
                                                                                        <label className="long-text-option">
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={idx}
                                                                                                name={"q32_rbl_F" + idx}
                                                                                                value={99}
                                                                                                checked={row.q32_rbl_F === 99}
                                                                                                onChange={HandleRepeaterFieldChange}
                                                                                            />
                                                                                            <span className="radio-text-padding">Unknown</span>
                                                                                        </label>
                                                                                    </div>
                                                                                }
                                                                                {(row.q32_rbl_B == null || row.q32_rbl_B === 99) &&
                                                                                    <div style={{ textAlign: "center" }}>
                                                                                        <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <ErrorField show={row.q32_F_empty_err} message={NonCohortTreatment_Msgs.Q32f} style={{ paddingLeft: '0px' }} />
                                                                            {/* <ErrorField show={row.q32_F_invalid_combo1_err} message={NonCohortTreatment_Msgs.Q32f_Invalid1} style={{ paddingLeft: '0px' }} /> */}
                                                                            {/* <ErrorField show={row.q32_F_invalid_combo2_err} message={NonCohortTreatment_Msgs.Q32f_Invalid2} style={{ paddingLeft: '0px' }} /> */}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                }
                                                {row.q32_rbl_F === 2 &&
                                                    <div className="question-bot-sapce">
                                                        <div className="question question-weight">
                                                            <span>Q33.</span>
                                                            <span className="quest-text-pad">
                                                                Provide the reason for advancement from the {""}
                                                                <span style={{ color: PageDesignConstants.fontColor }}>
                                                                    {
                                                                        !isStringEmpty(row.bispecificsLotPlus1)
                                                                            ? GetOrdinal(row.bispecificsLotPlus1)
                                                                            :
                                                                            <>
                                                                                [Bispecifics/CAR-T LOT + 1]
                                                                            </>

                                                                    }
                                                                </span>{" "}to{" "}
                                                                <span style={{ color: PageDesignConstants.fontColor }}>
                                                                    {
                                                                        !isStringEmpty(row.bispecificsLotPlus2)
                                                                            ? GetOrdinal(row.bispecificsLotPlus2)
                                                                            :
                                                                            <>
                                                                                [Bispecifics/CAR-T LOT + 2]
                                                                            </>

                                                                    }.{/* {" "}LOT. */}
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="double-dig-answer">
                                                            <div>
                                                                <label className="long-text-option">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={idx}
                                                                        name={"q33_chk_1_" + idx}
                                                                        checked={row.q33_chk_1}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                    />
                                                                    <span className="radio-text-padding">
                                                                        Adverse event
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label className="long-text-option">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={idx}
                                                                        name={"q33_chk_2_" + idx}
                                                                        checked={row.q33_chk_2}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                    />
                                                                    <span className="radio-text-padding">
                                                                        Disease progression
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label className="long-text-option">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={idx}
                                                                        name={"q33_chk_3_" + idx}
                                                                        checked={row.q33_chk_3}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                    />
                                                                    <span className="radio-text-padding">
                                                                        Insufficient response without progression
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label className="long-text-option">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={idx}
                                                                        name={"q33_chk_4_" + idx}
                                                                        checked={row.q33_chk_4}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                    />
                                                                    <span className="radio-text-padding">
                                                                        Cost or insurance coverage
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label className="long-text-option">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={idx}
                                                                        name={"q33_chk_5_" + idx}
                                                                        checked={row.q33_chk_5}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                    />
                                                                    <span className="radio-text-padding">
                                                                        Patient's decision (if none of the above)
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label className="long-text-option">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={idx}
                                                                        name={"q33_chk_6_" + idx}
                                                                        checked={row.q33_chk_6}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                    />
                                                                    <span className="radio-text-padding">
                                                                        Physician's decision (if none of the above)
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label className="long-text-option">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={idx}
                                                                        name={"q33_chk_7_" + idx}
                                                                        checked={row.q33_chk_7}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                    />
                                                                    <span className="radio-text-padding">
                                                                        Other
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label className="long-text-option">
                                                                    <input
                                                                        type="radio"
                                                                        id={idx}
                                                                        name={"q33_rb_unk" + idx}
                                                                        checked={row.q33_rb_unk}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                    />
                                                                    <span className="radio-text-padding">Unknown</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <ErrorField show={row.q33_empty_err} message={NonCohortTreatment_Msgs.Q33} style={{ paddingLeft: '0px' }} />
                                                    </div>
                                                }
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showHideHyperlinkModal && (
          <InfoPopUp
            description={hyperlinkTextLineOfTherapy}
            OkClick={() => setShowHideHyperlinkModal(false)}
          />
        )}
        </>

    )
}

export default NonCohortTreatment