const ErrorField = ({
    show = true,
    message,
    style
}) => {
    if (!show) {
        return <></>;
    }
    
 //   const defaultStyle = { color: "red", fontSize: "14px", paddingLeft: "25px"};
    const defaultStyle = { color: "red", fontSize: "14px", paddingLeft: "25px", display: 'inline-block', textAlign:'left'  };
    const style1 = style ? { ...defaultStyle, ...style } : defaultStyle;
    return (
        <div className="error-input">
            <span style={style1}>{message} </span>
        </div>
    );
};
export default ErrorField;

