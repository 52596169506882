import WebServiceWrapper from "./WebServiceWrapper";

export const AdverseEventsService = {
    SaveAdverseEventsData,
    GetAdverseEventsData,
};

function SaveAdverseEventsData(payload) {
    return WebServiceWrapper.Post("/adverseEvents/SaveAdverseEventsData", payload);
}

function GetAdverseEventsData(patientId) {
    return WebServiceWrapper.Get(`/adverseEvents/GetAdverseEventsData?patientId=${patientId}`);
}
