import React, { useContext, useEffect, useState } from "react";
import SideBarMenu from "../../Components/SideBarMenu";
import { RRMMCohortTreatment, SideMenuItems } from "../../Helpers/Enums";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import PatientDetails from "../../Components/PatientInformation";
import { GetLocalStorageData } from "../../Helpers/Utilities";
import { AppContext } from "../../Contexts/AppContextProvider";

const IMWGReporting = () => {
  const [navigatePaths, setNavigatePaths] = useState({
    prevPage: "/CarTCharacteristics",
    currPage: "/IMWGReporting",
    nextPage: "/TreatmentResponseProgression",
  });

  const { isSideMenuActive,setIsSideMenuActive } = useContext(AppContext);


  const SetNavPaths = () => {
    const storedData = GetLocalStorageData("CohortTreatment");
    let cohortTreatmentModelListt = [];
    let newPaths = { ...navigatePaths };

    if (storedData) {
      cohortTreatmentModelListt = JSON.parse(storedData);
    }

    let eligibleCohorts = cohortTreatmentModelListt.filter(
      (c) => c.isEligible === true
    );
    let ndmmCohorts = eligibleCohorts.filter((c) => c.ndmm);
    let rrmmCohorts = eligibleCohorts.filter((c) => !c.ndmm);

    const isAbeOrCar = rrmmCohorts.some(
      (a) =>
        a.cohortTreatmentID === RRMMCohortTreatment.ABECMA ||
        a.cohortTreatmentID === RRMMCohortTreatment.CARVYKTI
    );

    const isTecOrTal = rrmmCohorts.some(
      (a) =>
        a.cohortTreatmentID === RRMMCohortTreatment.TECVAYLI ||
        a.cohortTreatmentID === RRMMCohortTreatment.TALVEY
    );

    if (isAbeOrCar) {
      // CAR-T Page
      newPaths.prevPage = "/CarTCharacteristics";
    } else if (isTecOrTal) {
      // TECVAYLI / TALVEY Page
      newPaths.prevPage = "/TTCharacteristics";
    } else if (ndmmCohorts.length > 0) {
      // NDMM Page
      newPaths.prevPage = "/NDMMSCTCharacteristics";
    } else {
      // Default to Non-Cohort Page
      newPaths.prevPage = "/NonCohortDefiningTreatment";
    }
    setNavigatePaths(newPaths);
  };

  const SavePage = () => {
    // SetNavPaths();
    return true;
  };
  
  const sidebar = document.getElementById("mySidebar");
  const main = document.getElementById("main");

  const toggleNav = () => {
  
debugger
  
    if (isSideMenuActive === true  ) { 
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
      setIsSideMenuActive(false)
    } else if( isSideMenuActive===false ){
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
      setIsSideMenuActive(true)
    }
}

useEffect(() => {
  SetNavPaths();
const   sidebar = document.getElementById("mySidebar");
const  main = document.getElementById("main");


  if (isSideMenuActive === true) {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
    } else {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
    }
});

    
  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div >
          <SideBarMenu activePageId={SideMenuItems.IMWGReporting} />
          <>
          <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
         </>

          <div className="col-pad-left" id="main">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>Standard IMWG Reporting Criteria</h4>
              </div>

              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div className="question-answer">
                    <div className="question-bot-space">
                      <div className="question question-weight">
                        {/* static content */}
                        <div>
                          <table
                            border="1"
                            style={{
                              width: "100%",
                              borderCollapse: "collapse",
                              tableLayout: "fixed",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Response
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Definition
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Stringent complete response (sCR)
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Complete response as defined below plus normal
                                  free light chains (FLC) ratio and absence of
                                  clonal cells in bone marrow biopsy by
                                  immunohistochemistry (κ/λ ratio ≤ 4:1 or ≥ 1:2
                                  for κ and λ patients respectively, after
                                  counting ≥ 100 plasma cells)
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Complete response (CR)
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Negative immunofixation on the serum and urine
                                  and disappearance of any soft tissue
                                  plasmacytomas and &lt; 5% plasma cells in bone
                                  marrow aspirates
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Very good partial response (VGPR)
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Serum and urine M-protein detectable by
                                  immunofixation but not on electrophoresis or ≥
                                  90% reduction in serum M-protein plus urine
                                  M-protein level &lt; 100 mg per 24 h
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Partial response (PR)
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  ≥ 50% reduction of serum M-protein plus
                                  reduction in 24-h urinary M-protein by ≥ 90%
                                  or to &lt; 200mg per 24h. If the serum and
                                  urine M-protein are unmeasurable, a ≥ 50%
                                  decrease in the difference between involved
                                  and uninvolved FLC levels is required in place
                                  of the M-protein criteria. If serum and urine
                                  M-protein are unmeasurable, and serum-free
                                  light assay is also unmeasurable, ≥ 50%
                                  reduction in plasma cells is required in place
                                  of M-protein, provided baseline bone marrow
                                  plasma-cell percentage was ≥ 30%. In addition
                                  to these criteria, if present at baseline, a ≥
                                  50% reduction in the size (sum of the products
                                  of the maximal perpendicular diameters [SPD]
                                  of measured lesions) of soft tissue
                                  plasmacytomas is also required.
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Minimal response (MR)
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  ≥ 25% but ≤ 49% reduction of serum M-protein
                                  and reduction in 24-h urine M-protein by
                                  50%-89%. In addition to the above listed
                                  criteria, if present at baseline, a 25%-49%
                                  reduction in SPD of soft tissue plasmacytomas
                                  is also required.
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Stable disease (StD)
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Stability of disease is described by providing
                                  the time-to-progression estimates. Not meeting
                                  criteria for complete response, very good
                                  partial response, partial response, minimal
                                  response, or progressive disease.
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Progressive disease (PD)
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    padding: "10px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  Any one or more of the following criteria:
                                  <ul>
                                    <li
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "normal",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      Increase of 25% from lowest confirmed
                                      response value on or more of the following
                                      criteria:
                                      <ul
                                        style={{
                                          listStyleType: "circle",
                                          paddingLeft: "20px",
                                        }}
                                      >
                                        <li
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "normal",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          Serum M-protein (absolute increase
                                          must be ≥ 0.5g/dL)
                                        </li>
                                        <li
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "normal",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          Serum M-protein (increase ≥ 1 g/dL, if
                                          the lowest M component was ≥ 5 g/dL)
                                        </li>
                                        <li
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "normal",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          Urine M-protein (absolute increase
                                          must be ≥ 200 mg/24 h)
                                        </li>
                                      </ul>
                                    </li>
                                    <li
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "normal",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      In patients without measurable serum and
                                      urine M-protein levels, the difference
                                      between involved and uninvolved FLC levels
                                      (absolute increase must be &gt; 10 mg/dL)
                                    </li>
                                    <li
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "normal",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      In patients without measurable serum and
                                      urine M-protein levels and without
                                      measurable involved FLC levels, bone
                                      marrow plasma-cell percentage irrespective
                                      of baseline status (absolute increase must
                                      be ≥ 10%)
                                    </li>
                                    <li
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "normal",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      Appearance of a new lesion(s), ≥ 50%
                                      increase from nadir in SPD of &gt;1
                                      lesion, or ≥ 50% in the longest diameter
                                      of a previous lesion &gt; 1 cm in short
                                      axis
                                    </li>
                                    <li
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "normal",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      ≥ 50% increase in circulating plasma cells
                                      (minimum of 200 cells per μL) if this is
                                      the only measure of disease
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <p style={{ fontWeight: "normal" }}>
                            FLC: Free light chain; IMWG: International Myeloma
                            Working Group.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IMWGReporting;