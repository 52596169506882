import WebServiceWrapper from "./WebServiceWrapper";

export const CombordityService = {
  SaveComorbidityData,
  LoadComorbidityData,
};

function SaveComorbidityData(payload) {

  return WebServiceWrapper.Post("/comorbidities/SaveComorbidityData", payload);
}

function LoadComorbidityData(pid) {
  return WebServiceWrapper.Get(`/comorbidities/LoadComorbidityData?pid=${pid}`);
}
