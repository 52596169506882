import React, { useContext, useState } from 'react'
import { HTTPResponse, ToastMessageType } from '../../Helpers/Enums';
import { Center_Msgs, CommonError_Msgs } from '../../Helpers/HelperText';
import { CenterService } from '../../WebApiServices/Center.service';
import { AddLocalStorageData, isStringEmpty } from '../../Helpers/Utilities';
import { AppContext } from '../../Contexts/AppContextProvider';
import { GetLoggedInUserID } from '../../Helpers/Utilities';
import ErrorField from '../../Components/ErrorField';
import { useNavigate } from 'react-router-dom';

const CenterModal = ({ CloseModal }) => {
    const { ShowToast, ToggleLoader, HandleSessionTimeout } = useContext(AppContext);
    const navigate = useNavigate();

    const [nameErr, setNameErr] = useState(false);
    const [nameExists, setNameExists] = useState(false);
    const [errorOnSave, setErrorOnSave] = useState("");

    const [centerDetails, setCenterDetails] = useState({
        centerId: 0,
        name: ""
    })
    const HandleFieldChange = (e) => {
        const { name } = e.target
        setCenterDetails((prev) => ({
            ...prev,
            [name]: e.target.value
        }));
        setNameErr(false);
        setNameExists(false);
        setErrorOnSave(false);
    }

    const HandleSave = async () => {
        try {
            setErrorOnSave("");
            setNameErr(false);
            setNameExists(false);

            if (!ValidateForm()) {
                return;
            }

            const payload = {
                ...centerDetails,
                editedBy: GetLoggedInUserID(),
            }
            ToggleLoader(true)
            const response = await CenterService.SaveCenter(payload);
            ToggleLoader(false)
            const { status } = response;

            if (status === HTTPResponse.OK) {
                ShowToast(Center_Msgs.ADD_SUCCESS, ToastMessageType.Success);

                AddLocalStorageData("editCenterId", response?.data?.centerId)//Required for round and center info                
                return navigate("/ManageRounds");
            }
            else if (status === HTTPResponse.Conflict) {
                setNameExists(true);
            }
            else {
                if (status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else
                    setErrorOnSave(CommonError_Msgs.SAVE_ERR);
            }
        } catch (error) {
            setErrorOnSave(CommonError_Msgs.SAVE_ERR)
        }
    }
    const ValidateForm = () => {
        const isEmpty = isStringEmpty(centerDetails.name);
        setNameErr(isEmpty);
        return !isEmpty;
    }
    return (
        <>
            <section className="edit-modal">
                <div
                    className="modal"
                    id="AddCenterModal"
                    tabIndex={-1}
                    aria-labelledby="ModalFormLabel"
                    aria-hidden="true"
                    style={{ display: "block", paddingRight: "17px", backgroundColor: "#00000094" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row modal-headerr">
                                        <div className="col-10 col-md-10 col-lg-10">
                                            <label htmlFor="">
                                                Add New Center
                                            </label>
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-2 btn-align">
                                            <button
                                                type="button"
                                                className="btn-close btn-close-white"
                                                data-bs-dismiss="modal"
                                                aria-label="Close" onClick={CloseModal}
                                            />
                                        </div>
                                    </div>
                                    <div className="edit-modal-main">
                                        <div className="text-input-main">
                                            <div className="input-details">
                                                <div className="row">
                                                    <ErrorField show={!isStringEmpty(errorOnSave)} message={errorOnSave} style={{ paddingLeft: '0px' }} />
                                                    <div className="row edit-center-name-div">
                                                        <div className="col-md-4 col-lg-4">
                                                            <label htmlFor="centerName">Center Name: </label>
                                                        </div>

                                                        <div className="col-md-8 col-lg-8">
                                                            <input
                                                                id="centerName"
                                                                name="name"
                                                                className="form-control common-text-input"
                                                                type="text"
                                                                placeholder="Center"
                                                                value={centerDetails.name}
                                                                onChange={HandleFieldChange}
                                                                maxLength={100}
                                                            />
                                                            <ErrorField show={nameErr} message={Center_Msgs.NAME_EMPTY} style={{ paddingLeft: '0px' }} />
                                                            <ErrorField show={nameExists} message={Center_Msgs.NAME_EXISTS} style={{ paddingLeft: '0px' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="save-btn-div">
                                            <button className="modal-save-btn" onClick={HandleSave}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>)
};

export default CenterModal