import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import {
  CommonError_Msgs,
  EligibilityCriteria_Msgs,
  hyperlinkTextS5,
  hyperlinkTextS5Radio,
  hyperlinkTextS7,
  hyperlinkTextS9,
} from "../../Helpers/HelperText";

import {
  SideMenuItems,
  HTTPResponse,
  ToastMessageType,
  ToastMessages,
  PageDesignConstants,
} from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import ErrorField from "../../Components/ErrorField";

import { AppContext } from "../../Contexts/AppContextProvider";
import {
  GetLocalStorageData,
  GetLoggedInUserID,
  calculateDaysDifference,
  formatDateMMDDYYYY,
  isStringEmpty,
  AddLocalStorageData,
  checkEligibileCohortTreatment,
  CompareEndofFollowUpDate,
} from "../../Helpers/Utilities";
import DateControl from "../../Components/DateControl";
import { NDMM_Criteria_Msgs } from "../../Helpers/HelperText";
import { EligibilityCriteriaService } from "../../WebApiServices/EligibilityCriteria.service";
import InfoPopUp from "../../Components/InfoPopUp";

const NDMMCriteria = () => {
  const navigatePaths = {
    prevPage: "/GeneralInformation",
    currPage: "/NDMMCriteria",
    nextPage: "/RRMMCriteria",
  };

  const [initialMM_s6, setInitialMM_s6] = useState();

  const [showHideHyperlinkModalS5, setShowHideHyperlinkModalS5] =
    useState(false);
  const [showHideHyperlinkModalS5DR, setShowHideHyperlinkModalS5DR] =
    useState(false);
  const [showHideHyperlinkModalS5DVR, setShowHideHyperlinkModalS5DVR] =
    useState(false);
  const [showHideHyperlinkModalS5DKR, setShowHideHyperlinkModalS5DKR] =
    useState(false);
  const [showHideHyperlinkModalS5VR, setShowHideHyperlinkModalS5VR] =
    useState(false);
  const [showHideHyperlinkModalS5IsaVR, setShowHideHyperlinkModalS5IsaVR] =
    useState(false);
  const [showHideHyperlinkModalS7, setShowHideHyperlinkModalS7] =
    useState(false);
  const [showHideHyperlinkModalS9, setShowHideHyperlinkModalS9] =
    useState(false);

  const pid = GetLocalStorageData("patientId") ?? 0;
  let patientData = GetLocalStorageData("patient");
  patientData = patientData && JSON.parse(patientData);
  const centerId = patientData?.ptCenterId ?? 0;
  const surveyDate = patientData?.surveyDate ?? "";

  const [treatment1L, setTreatment1L] = useState("[1L Treatment]");
  const [sct, setSCT] = useState("");
  const [ndmm, setNDMM] = useState("[NDMM]");

    const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout,
      isSideMenuActive,
      setIsSideMenuActive, } =
    useContext(AppContext);

  useEffect(() => {
    async function FetchData() {
      if (!isNaN(pid) && Number(pid) !== 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);

  const [criteriaNDMM, setCriteriaNDMM] = useState({
    userId: GetLoggedInUserID(),
    endofFollowUpDate: "",
    lastPatientEncounter: "",
    pt_S5_NDMM: null,
    pt_S6_1L_MM: null,
    pt_S7_NDMM: null,
    pt_S8_NDMM: null,
    pt_S9_NDMM: null,
    pt_S10_NDMM_Amyloid: null,
    pt_S10_NDMM_Invasive: null,
    pt_S10_NDMM_NOTA: null,
    s6_initial_1L_MM: "",
    pt_s4_RD_MM_Date: null,
    s4_birth_date: null,
    pt_S51_NDMM:null,
   // s6FollowUpDateValid: false,
  });
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);
  const [showNDMM, setShowNDMM] = useState(false);
  const [showS8, setshowS8] = useState(false);

  const InitialHardErrList = {
    s5Empty: false,
    s6Empty: false,
    s6EmptyDate: false,
    s7Empty: false,
    s8Empty: false,
    s9Empty: false,
      s10Empty: false,
      s51Empty: false,
  };
  const [hardErrList, setHardErrList] = useState(InitialHardErrList);

  const InitialSoftErrList = {
    s5Terminate: false,
    s6Terminate: false,
    s7Terminate: false,
    s8Terminate: false,
    s9Terminate: false,
      s10Terminate: false,
    s51Terminate:false,
  };
  const [softErrList, setSoftErrList] = useState(InitialSoftErrList);

  async function LoadData() {
    try {
      if (pid > 0) {
        // Fetch data from the service
        const response = await EligibilityCriteriaService.LoadNDMMCriteria(pid);
        if (response?.status === HTTPResponse.OK) {
          const data = response.data;


          
          // Update the state with the fetched data
          setCriteriaNDMM((prev) => ({
            ...prev,
          //  s6FollowUpDateValid: false,
            endofFollowUpDate: data.endofFollowUpDate,
            lastPatientEncounter: data.lastPatientEncounterDate,
            pt_S5_NDMM: data.mM1LTreatment,
            s6_initial_1L_MM: data.initiationDate1LTreatment
              ? data.initiationDate1LTreatment
              : null,
            pt_S6_1L_MM:
              data.initiationDate1LTreatmentUnk === null
                ? null
                : data.initiationDate1LTreatmentUnk === true
                  ? 99
                  : 1,
              pt_S7_NDMM: data.sctEligibilityStatus,
            pt_S51_NDMM:data.cybordcycles,
            pt_S8_NDMM:
              data.sctPriorTreatment != null
                ? data.sctPriorTreatment == true
                  ? 1
                  : 0
                : "",
            pt_S9_NDMM:
              data.mmTreatmentPriorNDMM != null
                ? data.mmTreatmentPriorNDMM == true
                  ? 1
                  : 0
                : "",
            pt_S10_NDMM_Amyloid:
              data.diagnosisPriorNDMMAmyloid != null
                ? data.diagnosisPriorNDMMAmyloid == true
                  ? 1
                  : 0
                : false,
            pt_S10_NDMM_Invasive:
              data.diagnosisPriorNDMMmalignancy != null
                ? data.diagnosisPriorNDMMmalignancy == true
                  ? 1
                  : 0
                : false,
            pt_S10_NDMM_NOTA:
              data.diagnosisPriorNDMMnone != null
                ? data.diagnosisPriorNDMMnone == true
                  ? 1
                  : 0
                : false,

            s4_birth_date: !isStringEmpty(data.birthDate)
              ? data.birthDate
              : null,
            pt_s4_RD_MM_Date: data.birthDateUnk
              ? 99
              : !isStringEmpty(data.birthDate)
                ? 1
                : null,
          }));

          switch (data.mM1LTreatment) {
            case 1:
              setTreatment1L("DR/DRd");
              break;
            case 2:
              setTreatment1L("DVR/DVRd");
              break;
            case 3:
              setTreatment1L("DKR/DKRd");
              break;
            case 4:
              setTreatment1L("VR/VRd");
              break;
            case 5:
              setTreatment1L("IsaVR/IsaVRd");
              break;
            default:
              break;
          }

          switch (true) {
            case data.mM1LTreatment === 1 && data.sctEligibilityStatus === 1:
              setSCT("[NDMM]");
              setNDMM("[NDMM]");
              break;
            case data.mM1LTreatment === 1 && data.sctEligibilityStatus === 2:
              setSCT("TIE");
              setNDMM("DR/DRd TIE");
              break;
            case data.mM1LTreatment === 2 && data.sctEligibilityStatus === 1:
              setSCT("TE");
              setNDMM("DVR/DVRd TE");
              break;
            case data.mM1LTreatment === 2 && data.sctEligibilityStatus === 2:
              setSCT("TIE");
              setNDMM("DVR/DVRd TIE");
              break;
            case data.mM1LTreatment === 3 && data.sctEligibilityStatus === 1:
              setNDMM("DKR/DKRd TE");
              break;
            case data.mM1LTreatment === 3 && data.sctEligibilityStatus === 2:
              setSCT("TIE");
              setNDMM("DKR/DKRd TIE");
              break;
            case data.mM1LTreatment === 4 && data.sctEligibilityStatus === 1:
              setSCT("TE");
              setNDMM("VR/VRd TE");
              break;
            case data.mM1LTreatment === 4 && data.sctEligibilityStatus === 2:
              setSCT("TIE");
              setNDMM("VR/VRd TIE");
              break;
            case data.mM1LTreatment === 5 && data.sctEligibilityStatus === 1:
              setSCT("TE");
              setNDMM("IsaVR/IsaVRd TE");
              break;
            case data.mM1LTreatment === 5 && data.sctEligibilityStatus === 2:
              setSCT("TIE");
              setNDMM("IsaVR/IsaVRd TIE");
              break;
            default:
              // Handle default case if needed
              break;
          }

          if (data.cohortTreatmentModelList.length > 0) {
            AddLocalStorageData(
              "CohortTreatment",
              JSON.stringify(data.cohortTreatmentModelList)
            );
          }
          else AddLocalStorageData("CohortTreatment", JSON.stringify([]));

          //display ineligible cohort message on load
          /*S6*/
          var S6CohortIneligible = false;
          if (
            data.initiationDate1LTreatmentUnk !== null &&
            data.initiationDate1LTreatmentUnk == false &&
            data.initiationDate1LTreatment != ""
          ) {
            //const maxDays = 6575;
            //const daysDifference = calculateDaysDifference(
            //  criteriaNDMM.s4_birth_date,
            //  data.initiationDate1LTreatment
            //);
              //if (daysDifference < maxDays) {

              let IndexDateYear = (new Date(data.initiationDate1LTreatment).getFullYear());
              let BirthYear = (new Date(data.birthDate).getFullYear());
              let totalYears = IndexDateYear - parseInt(BirthYear);
              if (totalYears < 18) {
              S6CohortIneligible = true;
              setShowNDMM(true);
            } else if (
              data.mM1LTreatment === 1 &&
              checkEligibileCohortTreatment(
                data.initiationDate1LTreatment,
                "2019/06/27"
              )
            ) {
              S6CohortIneligible = true;
              setShowNDMM(true);
            } else if (
              (data.mM1LTreatment === 2 ||
                data.mM1LTreatment === 3 ||
                data.mM1LTreatment === 4) &&
              checkEligibileCohortTreatment(
                data.initiationDate1LTreatment,
                "2020/01/01"
              )
            ) {
              S6CohortIneligible = true;
              setShowNDMM(true);
            }
            //FDA Approval date validation
            //else if (
            //  data.mM1LTreatment === 5 &&
            //  checkEligibileCohortTreatment(
            //    data.initiationDate1LTreatment,
            //    surveyDate
            //  )
            //) {
            //  S6CohortIneligible = true;
            //  setShowNDMM(true);
            //}


            else {
              S6CohortIneligible = false;
              setShowNDMM(false);
            }
          } else if (
            data.initiationDate1LTreatmentUnk !== null &&
            data.initiationDate1LTreatmentUnk == true
          ) {
            S6CohortIneligible = true;
            }



            //S51
            var S51CohortIneligible = false;
            if (
                data.cybordcycles == 3
            ) {
                S51CohortIneligible = true;
                setshowS8(false);
            } else setshowS8(true);



          //S7
          var S7CohortIneligible = false;
          if (
            (data.mM1LTreatment === 1 && data.sctEligibilityStatus === 1) ||
            data.sctEligibilityStatus == 99
          ) {
            S7CohortIneligible = true;
            setshowS8(false);
          } else setshowS8(true);

          //S8
          var S8CohortIneligible = false;
          if (data.sctPriorTreatment == true) {
            S8CohortIneligible = true;
          }
          //S9
          var S9CohortIneligible = false;
          if (data.mmTreatmentPriorNDMM == true) S9CohortIneligible = true;

          //S10
          var S10CohortIneligible = false;
          if (
            data.diagnosisPriorNDMMAmyloid == true ||
            data.diagnosisPriorNDMMmalignancy == true
          )
            S10CohortIneligible = true;

          setSoftErrList((prev) => ({
            ...prev,
            s6Terminate: S6CohortIneligible,
            s7Terminate: S7CohortIneligible,
            s8Terminate: S8CohortIneligible,
            s9Terminate: S9CohortIneligible,
              s10Terminate: S10CohortIneligible,
              s51Terminate: S51CohortIneligible,
          }));
        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  function checkDateValidation() {
    setShowNDMM(true);
    setSoftErrList((prev) => ({
      ...prev,
      s6Terminate: true,
    }));
    setshowS8(false);
    setCriteriaNDMM((prev) => ({
      ...prev,
      pt_S7_NDMM: null,
      pt_S8_NDMM: null,
      pt_S9_NDMM: null,
    }));
  }


  const HandleFieldChange = (e, treatment1L, sct) => {


    const { name, id, value } = e.target;
    switch (name) {
      case "pt_S5_NDMM": {
        const value_int = Number(value);
        setCriteriaNDMM((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
        }));
            setTreatment1L(treatment1L);

            if (criteriaNDMM.pt_S5_NDMM != value_int) {
                setCriteriaNDMM((prev) => ({
                    ...prev,
                    pt_S6_1L_MM: null,
                    pt_S51_NDMM:null,
                    pt_S7_NDMM: null,
                    pt_S8_NDMM: null,
                    pt_S9_NDMM: null,
                    pt_S10_NDMM_Amyloid: null,
                    pt_S10_NDMM_Invasive: null,
                    pt_S10_NDMM_NOTA: null,
                    s6_initial_1L_MM: "",

                }));
                setHardErrList((prev) => ({
                    ...prev,
                    s51Empty: false,
                    s6Empty: false,
                    s6EmptyDate:false,
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    s51Terminate: false,
                    s6Terminate: false,
                }));
            }

        if (value_int === 1 && criteriaNDMM.pt_S7_NDMM === 1) {
          setNDMM("[NDMM]");
          setSoftErrList((prev) => ({
            ...prev,
            s7Terminate: true,
          }));
          setshowS8(false);
          setCriteriaNDMM((prev) => ({
            ...prev,
            pt_S8_NDMM: null,
            pt_S9_NDMM: null,
          }));
        } else if (
          value_int === 1 &&
          checkEligibileCohortTreatment(
            criteriaNDMM.s6_initial_1L_MM,
            "2019/06/27"
          )
        ) {
          checkDateValidation();
        } else if (
          (value_int === 2 || value_int === 3 || value_int === 4) &&
          checkEligibileCohortTreatment(
            criteriaNDMM.s6_initial_1L_MM,
            "2020/01/01"
          )
        ) {
          checkDateValidation();
        }

        //FDA Approval Date Validation
        //else if (
        //  value_int === 5 &&
        //  checkEligibileCohortTreatment(
        //    criteriaNDMM.s6_initial_1L_MM,
        //    surveyDate
        //  )
        //) {
        //  checkDateValidation();
        //}


        else {
          setNDMM(`${treatment1L} ${sct}`);
          setSoftErrList((prev) => ({
            ...prev,

            s7Terminate: false,
          }));
        }

        if (value_int === 6) {
          setCriteriaNDMM((prev) => ({
            ...prev,
              pt_S6_1L_MM: null,
            pt_S51_NDMM:null,
            pt_S7_NDMM: null,
            pt_S8_NDMM: null,
            pt_S9_NDMM: null,
            pt_S10_NDMM_Amyloid: null,
            pt_S10_NDMM_Invasive: null,
            pt_S10_NDMM_NOTA: null,
            s6_initial_1L_MM: "",

          }));
          setHardErrList((prev) => ({
            ...prev,
            s51Empty: false,
              s6Empty: false,
              s6EmptyDate:false,
          }));

            setSoftErrList((prev) => ({
                ...prev,
                s51Terminate: false,
                s6Terminate:false,
            }));
            }




        var S6CohortIneligible = false;
        if (
          criteriaNDMM.pt_S6_1L_MM !== null &&
          criteriaNDMM.pt_S6_1L_MM == 1 &&
          criteriaNDMM.s6_initial_1L_MM != ""
        ) {
          //const maxDays = 6575;
          //const daysDifference = calculateDaysDifference(
          //  criteriaNDMM.s4_birth_date,
          //  criteriaNDMM.s6_initial_1L_MM
          //);
          //  if (daysDifference < maxDays) {

            let IndexDateYear = (new Date(criteriaNDMM.s6_initial_1L_MM).getFullYear());
                let BirthYear = (new Date(criteriaNDMM.s4_birth_date).getFullYear());
                let totalYears = IndexDateYear - parseInt(BirthYear);
                if (totalYears < 18) {
            S6CohortIneligible = true;
            setShowNDMM(true);
          } else if (
            value_int === 1 &&
            checkEligibileCohortTreatment(
              criteriaNDMM.s6_initial_1L_MM,
              "2019/06/27"
            )
          ) {
            S6CohortIneligible = true;
            setShowNDMM(true);
          } else if (
            (value_int === 2 || value_int === 3 || value_int === 4) &&
            checkEligibileCohortTreatment(
              criteriaNDMM.s6_initial_1L_MM,
              "2020/01/01"
            )
          ) {
            S6CohortIneligible = true;
            setShowNDMM(true);
          }

          //FDA Approval Date Validation
          //else if (
          //  value_int === 5 &&
          //  checkEligibileCohortTreatment(
          //    criteriaNDMM.s6_initial_1L_MM,
          //    surveyDate
          //  )
          //) {
          //  S6CohortIneligible = true;
          //  setShowNDMM(true);
          //}


          else {
            S6CohortIneligible = false;
            setShowNDMM(false);
          }
        } else if (
          criteriaNDMM.pt_S6_1L_MM !== null &&
          criteriaNDMM.pt_S6_1L_MM == 99
        ) {
          S6CohortIneligible = true;
          setShowNDMM(false);
        }
        setSoftErrList((prev) => ({
          ...prev,
          s6Terminate: S6CohortIneligible,
        }));

        setHardErrList((prev) => ({
          ...prev,
          s5Empty: false,
        }));

        break;
        }
        case "pt_S51_NDMM": {
            const value_int = Number(value);
            setCriteriaNDMM((prev) => ({
                ...prev,
                [name]: parseInt(value), //value,
                pt_S6_1L_MM: null,
                pt_S7_NDMM: null,
                pt_S8_NDMM: null,
                pt_S9_NDMM: null,
                pt_S10_NDMM_Amyloid: null,
                pt_S10_NDMM_Invasive: null,
                pt_S10_NDMM_NOTA: null,
                s6_initial_1L_MM: "",
                s51Empty: false,
            }));

            setHardErrList((prev) => ({
                ...prev,
                s51Empty:false,
                s6Empty: false,
                s6EmptyDate: false,
                s7Empty:false,
                s8Empty: false,
                s9Empty: false,
                s10Empty: false,
            }));

            setSoftErrList((prev) => ({
                ...prev,
                s51Terminate: value_int === 3,
                s6Terminate: false,
                s7Terminate: false,
                s8Terminate: false,
                s9Terminate: false,
                s10Terminate: false,
            }));
            break;
        }


      case "pt_S6_1L_MM": {
        setShowNDMM(true);

        const value_int = Number(value);
        setCriteriaNDMM((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,

          pt_S7_NDMM: null,
          pt_S8_NDMM: null,
          pt_S9_NDMM: null,
          pt_S10_NDMM_Amyloid: null,
          pt_S10_NDMM_Invasive: null,
          pt_S10_NDMM_NOTA: null,
         // s6FollowUpDateValid: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          s6Empty: false,
          s6EmptyDate: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          s6Terminate: value_int === 99,
          s7Terminate: false,
        }));

        setCriteriaNDMM((prev) => ({
          ...prev,
          s6_initial_1L_MM: "",

          // Clear the date field
        }));

        setInitialMM_s6(true);

        break;
      }
      case "pt_S7_NDMM": {
        const value_int = Number(value);
        setCriteriaNDMM((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
        }));

        setSCT(sct);
        var showmsg = false;
        if (
          (criteriaNDMM.pt_S5_NDMM === 1 && value_int === 1) ||
          value_int == 99
        ) {
          setNDMM("[NDMM]");
          showmsg = true;
          setshowS8(false);
          setCriteriaNDMM((prev) => ({
            ...prev,
            pt_S8_NDMM: null,
            pt_S9_NDMM: null,
          }));
        } else {
          setNDMM(`${treatment1L} ${sct}`);
          setshowS8(true);
        }

        if (value_int === 99) {
          setCriteriaNDMM((prev) => ({
            ...prev,
            pt_S8_NDMM: null,
            pt_S9_NDMM: null,
            pt_S10_NDMM_Amyloid: null,
            pt_S10_NDMM_Invasive: null,
            pt_S10_NDMM_NOTA: null,
          }));
        }

        setHardErrList((prev) => ({
          ...prev,
          s7Empty: false,
          s8Empty: false,
          s9Empty: false,
          s10Empty: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          s7Terminate: showmsg,
          s8Terminate: false,
          s9Terminate: false,
          s10Terminate: false,
        }));

        break;
      }
      case "pt_S8_NDMM": {
        const value_int = Number(value);
        setCriteriaNDMM((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          pt_S9_NDMM: null,
          pt_S10_NDMM_Amyloid: null,
          pt_S10_NDMM_Invasive: null,
          pt_S10_NDMM_NOTA: null,
          s8Empty: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          s8Empty: false,
          s9Empty: false,
          s10Empty: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          s8Terminate: value_int === 1,
          s9Terminate: false,
          s10Terminate: false,
        }));
        break;
      }

      case "pt_S9_NDMM": {
        const value_int = Number(value);
        setCriteriaNDMM((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          pt_S10_NDMM_Amyloid: null,
          pt_S10_NDMM_Invasive: null,
          pt_S10_NDMM_NOTA: null,
          s9Empty: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          s9Empty: false,
          s10Empty: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          s9Terminate: value_int === 1,
          s10Terminate: false,
        }));
        break;
      }

      case "pt_S10_NDMM_NOTA": {
        const value_int = Number(value);

        setCriteriaNDMM((prev) => ({
          ...prev,
          [name]: Number(value), //value,
          pt_S10_NDMM_Amyloid: false,
          pt_S10_NDMM_Invasive: false,
          s10Empty: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          s10Empty: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          s10Terminate: false,
        }));

        break;
      }

      case "pt_S10_NDMM_Amyloid":
      case "pt_S10_NDMM_Invasive": {
        const value = e.target.checked;

        setCriteriaNDMM((prev) => ({
          ...prev,
          [name]: value, // Use the actual value from the checkbox
          pt_S10_NDMM_NOTA: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          s10Empty: false,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          s10Terminate:
            value === true ||
            (name === "pt_S10_NDMM_Amyloid" &&
              criteriaNDMM.pt_S10_NDMM_Invasive) ||
            (name === "pt_S10_NDMM_Invasive" &&
              criteriaNDMM.pt_S10_NDMM_Amyloid),
        }));

        break;
      }

      default:
        break;
    }
  };

    const ValidateForm = () => {
    let isValid = true;
    //S5.
    if (criteriaNDMM.pt_S5_NDMM === null) {
      setHardErrList((prev) => ({
        ...prev,
        s5Empty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        s5Empty: false,
      }));
      }

      //S51
        if (criteriaNDMM.pt_S5_NDMM !== 6 && criteriaNDMM.pt_S5_NDMM !== 3 &&criteriaNDMM.pt_S51_NDMM === null) {
          setHardErrList((prev) => ({
              ...prev,
              s51Empty: true,
          }));
          isValid = false;
      } else {
          setHardErrList((prev) => ({
              ...prev,
              s51Empty: false,
          }));
      }

    // S6 Validation
        if ((criteriaNDMM.pt_S5_NDMM !== 6 && criteriaNDMM.pt_S5_NDMM === 3 && criteriaNDMM.pt_S6_1L_MM === null) ||
            (criteriaNDMM.pt_S51_NDMM !== 3 && criteriaNDMM.pt_S5_NDMM !== 6 && criteriaNDMM.pt_S6_1L_MM === null)) {
      setHardErrList((prev) => ({
        ...prev,
        s6Empty: true,
      }));

      isValid = false;
    } else if (
      criteriaNDMM.pt_S6_1L_MM === 1 &&
      !criteriaNDMM.s6_initial_1L_MM
    ) {
      setHardErrList((prev) => ({
        ...prev,
        s6EmptyDate: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        s6Empty: false,
      }));

      if (
        criteriaNDMM.pt_S6_1L_MM !== null &&
        criteriaNDMM.pt_S6_1L_MM == 1 &&
        criteriaNDMM.s6_initial_1L_MM != ""
      ) {
        //const maxDays = 6575;
        //const daysDifference = calculateDaysDifference(
        //  criteriaNDMM.s4_birth_date,
        //  criteriaNDMM.s6_initial_1L_MM
        //);
        //if (criteriaNDMM.s6FollowUpDateValid) {
        //  setCriteriaNDMM((prev) => ({
        //    ...prev,
        //    s6FollowUpDateValid: true,
        //  }));
        //  isValid = false;
        //}

          /*if (daysDifference < maxDays) {*/

          let IndexDateYear = (new Date(criteriaNDMM.s6_initial_1L_MM).getFullYear());
          let BirthYear = (new Date(criteriaNDMM.s4_birth_date).getFullYear());
          let totalYears = IndexDateYear - parseInt(BirthYear);
          if (totalYears < 18) {
          isValid = true;
        } else if (
          criteriaNDMM.pt_S5_NDMM === 1 &&
          checkEligibileCohortTreatment(
            criteriaNDMM.s6_initial_1L_MM,
            "2019/06/27"
          )
        ) {
          isValid = true;
        } else if (
          (criteriaNDMM.pt_S5_NDMM === 2 ||
            criteriaNDMM.pt_S5_NDMM === 3 ||
            criteriaNDMM.pt_S5_NDMM === 4) &&
          checkEligibileCohortTreatment(
            criteriaNDMM.s6_initial_1L_MM,
            "2020/01/01"
          )
        ) {
          isValid = true;
        }

        //FDA Approval Date Validation
        //else if (
        //  criteriaNDMM.pt_S5_NDMM === 5 &&
        //  checkEligibileCohortTreatment(
        //    criteriaNDMM.s6_initial_1L_MM,
        //    surveyDate
        //  )
        //) {
        //  isValid = true;
        //}


        else {
          //S7.
          if (
            criteriaNDMM.pt_S6_1L_MM === 1 &&
            criteriaNDMM.pt_S7_NDMM === null
          ) {
            setHardErrList((prev) => ({
              ...prev,
              s7Empty: true,
            }));
            isValid = false;
          }

          //else if (criteriaNDMM.pt_S6_1L_MM === 1 && criteriaNDMM.pt_S7_NDMM == 1) {
          //    setHardErrList((prev) => ({
          //        ...prev,
          //        s7Empty: false,
          //    }));
          //    isValid = true;
          //}
          else {
            //S8.
            if (criteriaNDMM.pt_S7_NDMM === 2) {
              if (criteriaNDMM.pt_S8_NDMM === null) {
                setHardErrList((prev) => ({
                  ...prev,
                  s8Empty: true,
                }));
                isValid = false;
              } else {
                setHardErrList((prev) => ({
                  ...prev,
                  s8Empty: false,
                }));
              }
            }

            //S9.
            if (criteriaNDMM.pt_S8_NDMM === 0) {
              if (criteriaNDMM.pt_S9_NDMM === null) {
                setHardErrList((prev) => ({
                  ...prev,
                  s9Empty: true,
                }));
                isValid = false;
              } else {
                setHardErrList((prev) => ({
                  ...prev,
                  s9Empty: false,
                }));
              }
            }

            //S10.
            if (criteriaNDMM.pt_S9_NDMM === 0) {
              if (
                !criteriaNDMM.pt_S10_NDMM_Amyloid &&
                !criteriaNDMM.pt_S10_NDMM_Invasive &&
                !criteriaNDMM.pt_S10_NDMM_NOTA
              ) {
                setHardErrList((prev) => ({
                  ...prev,
                  s10Empty: true,
                }));
                isValid = false;
              } else {
                setHardErrList((prev) => ({
                  ...prev,
                  s10Empty: false,
                }));
              }
            }
          }
        }
      }
    }

    return isValid;
  };

  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);
      setSoftErrList(InitialSoftErrList);
      setHardErrList(InitialHardErrList);

      let isValid = validate ? ValidateForm() : true;

      if (!isValid) {
        setShowTopErrMsg(true);
        ToggleLoader(false);
        return false;
      }
      setShowTopErrMsg(false);

      const eligibilityCriteriademographicModel = {
        patientId: pid,
        centerId: centerId,
        userId: GetLoggedInUserID(),
        mm1LTreatment: criteriaNDMM.pt_S5_NDMM,
        initiationDate1LTreatment: criteriaNDMM.s6_initial_1L_MM
          ? criteriaNDMM.s6_initial_1L_MM
          : null,
        initiationDate1LTreatmentUnk:
          criteriaNDMM.pt_S6_1L_MM === null
            ? null
            : criteriaNDMM.pt_S6_1L_MM === 99
              ? 1
              : 0,
        sctEligibilityStatus: criteriaNDMM.pt_S7_NDMM,
        sctPriorTreatment: criteriaNDMM.pt_S8_NDMM,
        mmTreatmentPriorNDMM: criteriaNDMM.pt_S9_NDMM,
        diagnosisPriorNDMMAmyloid: !!criteriaNDMM.pt_S10_NDMM_Amyloid, // Converts value to boolean
        diagnosisPriorNDMMmalignancy: !!criteriaNDMM.pt_S10_NDMM_Invasive, // Converts value to boolean
          diagnosisPriorNDMMnone: criteriaNDMM.pt_S10_NDMM_NOTA,
          cybordcycles: criteriaNDMM.pt_S51_NDMM,
        isValidated: validate,
        cohortTreatmentModelList: [],
      };

      if (pid > 0) {
        const response = await EligibilityCriteriaService.SaveNDMMCriteria(
          eligibilityCriteriademographicModel
        );
        ToggleLoader(false);
        if (response.status !== HTTPResponse.OK) {
          if (response?.status === HTTPResponse.Unauthorized) {
            HandleSessionTimeout();
          } else throw response.error;
        } else {
          ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
          await LoadData();
            ToggleLoader(false);

            if (response?.data?.showDateChangeAffectAlert) {
                ShowAlertBox();
            }
        }
      }
      return true;
    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };


    /* <summary>
      date: 24-08-2024
      Name: BV
      description: Show an alert message when other page(index date) could be affected
      <summary>*/
    const ShowAlertBox = async () => {
        const choice = await ShowActionPopUp(
            "Alert", //title
            CommonError_Msgs.DateChangeAlert, //message
            "Ok", //Confirm button text
            true
        );
    };


  const HandleDateChange = (name, date, optionId, monthOrYearSelect) => {

    setShowNDMM(true);

      switch (name) {     
      case "s6_initial_1L_MM":
        {
          setCriteriaNDMM((prev) => ({
            ...prev,
            [name]: date,
            pt_S6_1L_MM: 1,
          }));
          setInitialMM_s6(false);

          setSoftErrList((prev) => ({
            ...prev,
            s6Terminate: false,
          }));
          setHardErrList((prev) => ({
            ...prev,
              s6EmptyDate: false,
              s6Empty: false,
          }));

          //const maxDays = 6575;
          //const daysDifference = calculateDaysDifference(
          //  criteriaNDMM.s4_birth_date,
          //  date
          //);

          //if (daysDifference < maxDays) {

                let IndexDateYear = (new Date(date).getFullYear());
                let BirthYear = (new Date(criteriaNDMM.s4_birth_date).getFullYear());
                let totalYears = IndexDateYear - parseInt(BirthYear);
                if (totalYears < 18) {
            checkDateValidation();
          } else if (
            criteriaNDMM.pt_S5_NDMM === 1 &&
            checkEligibileCohortTreatment(date, "2019/06/27")
          ) {
            checkDateValidation();
          } else if (
            (criteriaNDMM.pt_S5_NDMM === 2 ||
              criteriaNDMM.pt_S5_NDMM === 3 ||
              criteriaNDMM.pt_S5_NDMM === 4) &&
            checkEligibileCohortTreatment(date, "2020/01/01")
          ) {
            checkDateValidation();
          }

          //FDA Approval date validation commented
          //else if (
          //  criteriaNDMM.pt_S5_NDMM === 5 &&
          //  checkEligibileCohortTreatment(date, surveyDate)
          //) {
          //  checkDateValidation();
          //}


          else {
            setShowNDMM(false);
            setSoftErrList((prev) => ({
              ...prev,
              s6Terminate: false,
            }));
          }
          const dateValid = CompareEndofFollowUpDate(
            date,
            criteriaNDMM.endofFollowUpDate,
            criteriaNDMM.lastPatientEncounter,
          );

          //if (!dateValid) {
          //  setCriteriaNDMM((prev) => ({
          //    ...prev,
          //    s6FollowUpDateValid: true,
          //  }));
          //} else {
          //  setCriteriaNDMM((prev) => ({
          //    ...prev,
          //    s6FollowUpDateValid: false,
          //  }));
          //}
        }
        break;

      default:
        break;
    }
  };


  const sidebar = document.getElementById("mySidebar");
  const main = document.getElementById("main");

  const toggleNav = () => {
    if (isSideMenuActive === true  ) { 
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
      setIsSideMenuActive(false)
    } else if( isSideMenuActive===false ){
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
      setIsSideMenuActive(true)
    }
}

useEffect(() => {
const   sidebar = document.getElementById("mySidebar");
const  main = document.getElementById("main");


  if (isSideMenuActive === true) {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
    } else {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
    }
});
  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div >
          <SideBarMenu activePageId={SideMenuItems.NDMMCriteria} />
          <>
          <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
         </>

          <div className="col-pad-left" id="main">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>1L NDMM CRITERIA</h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  {/* <div style={PageDesignConstants.PageDescriptionStyle}><i>This section will ask you about your patient’s demographic information.</i></div> */}
                  <div className="question-answer">
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>S5.</span>
                        <span className="quest-text-pad">
                          <span>Which, if any, of the following </span>{" "}
                          <span
                            data-tooltip-id="encounterTip"
                            className="modal-popup"
                            onClick={() => setShowHideHyperlinkModalS5(true)}
                          >
                            first-line (1L) treatments
                          </span>
                          {showHideHyperlinkModalS5 && (
                            <InfoPopUp
                              description={hyperlinkTextS5}
                              OkClick={() => setShowHideHyperlinkModalS5(false)}
                            />
                          )}{" "}
                                                  did this patient initiate for the treatment of MM at 
                                                  <u><b> your medical center</b></u>?
                                                  <br /> Please note that:
                                                  <br />
                                                  <br/>
                                                  <ul>
                                                      <li className="font-style-spacing">
                                                          Patients <u><b>do not have</b></u> to have completed the 1L
                          treatment to be eligible (i.e., the treatment regimen
                          can be ongoing by the time of data abstraction or last
                                                          visit).</li> 
                          <br/>
                                                      <li className="font-style-spacing"> The patient <u><b>should not receive</b></u> any part of their
                          1L treatment (e.g., induction, transplantation,
                          consolidation, or maintenance) as part of a clinical
                          trial or expanded access program (EAP) by the time of data abstraction. For example, a
                          patient having received a DVRd induction followed by a
                                                          maintenance therapy in a clinical trial is considered
                                                          ineligible.</li></ul>
                                                          
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-fbox">
                          <input style={{marginTop: "6px"}}
                            type="radio"
                            id="S5_NDMM_DR"
                            name="pt_S5_NDMM"
                            value={1}
                            checked={
                              criteriaNDMM.pt_S5_NDMM === 1 //"1"
                            }
                            onChange={(e) => {
                              HandleFieldChange(e, "DR/DRd", sct);
                            }}
                          />
                          <label htmlFor="S5_NDMM_DR">
                            <span className="radio-text-padding">
                              <span>
                                <b>DR or DRd</b> (daratumumab + lenalidomide ±{" "}
                              </span>{" "}
                              <span
                                className="hyperlinkText modal-popup"
                                data-tooltip-id="encounterTip"
                                onClick={() =>
                                  setShowHideHyperlinkModalS5DR(true)
                                }
                              >
                                {" "}
                                dexamethasone/prednisone/prednisolone)
                              </span>
                              {showHideHyperlinkModalS5DR && (
                                <InfoPopUp
                                  description={hyperlinkTextS5Radio}
                                  OkClick={() =>
                                    setShowHideHyperlinkModalS5DR(false)
                                  }
                                />
                              )}{" "}
                            </span>
                          </label>
                        </div>

                        <div className="option-fbox">
                          <input style={{marginTop: "6px"}}
                            type="radio"
                            id="S5_NDMM_DVR"
                            name="pt_S5_NDMM"
                            value={2}
                            checked={criteriaNDMM.pt_S5_NDMM === 2}
                            onChange={(e) => {
                              HandleFieldChange(e, "DVR/DVRd", sct);
                            }}
                          />
                          <label htmlFor="S5_NDMM_DVR">
                            <span className="radio-text-padding">
                              <span>
                                <b>DVR or DVRd</b> (daratumumab + lenalidomide ±{" "}
                              </span>{" "}
                              <span
                                className="hyperlinkText modal-popup"
                                data-tooltip-id="encounterTip"
                                onClick={() =>
                                  setShowHideHyperlinkModalS5DVR(true)
                                }
                              >
                                {" "}
                                dexamethasone/prednisone/prednisolone)
                              </span>
                              {showHideHyperlinkModalS5DVR && (
                                <InfoPopUp
                                  description={hyperlinkTextS5Radio}
                                  OkClick={() =>
                                    setShowHideHyperlinkModalS5DVR(false)
                                  }
                                />
                              )}{" "}
                            </span>
                          </label>
                        </div>
                        <div className="option-fbox">
                          <input style={{marginTop: "6px"}}
                            type="radio"
                            id="S5_NDMM_DKR"
                            name="pt_S5_NDMM"
                            value={3}
                            checked={criteriaNDMM.pt_S5_NDMM === 3}
                            onChange={(e) => {
                              HandleFieldChange(e, "DKR/DKRd", sct);
                            }}
                          />
                          <label htmlFor="S5_NDMM_DKR">
                            <span className="radio-text-padding">
                              <span>
                                <b>DKR or DKRd</b> (daratumumab + carfilzomib +
                                lenalidomide ±
                              </span>{" "}
                              <span
                                className="hyperlinkText modal-popup"
                                data-tooltip-id="encounterTip"
                                onClick={() =>
                                  setShowHideHyperlinkModalS5DKR(true)
                                }
                              >
                                {" "}
                                dexamethasone/prednisone/prednisolone)
                              </span>
                              {showHideHyperlinkModalS5DKR && (
                                <InfoPopUp
                                  description={hyperlinkTextS5Radio}
                                  OkClick={() =>
                                    setShowHideHyperlinkModalS5DKR(false)
                                  }
                                />
                              )}{" "}
                            </span>
                          </label>
                        </div>
                        <div className="option-fbox">
                          <input style={{marginTop: "6px"}}
                            type="radio"
                            id="S5_NDMM_VR"
                            name="pt_S5_NDMM"
                            value={4}
                            checked={criteriaNDMM.pt_S5_NDMM === 4}
                            onChange={(e) => {
                              HandleFieldChange(e, "VR/VRd", sct);
                            }}
                          />
                          <label htmlFor="S5_NDMM_VR">
                            <span className="radio-text-padding">
                              <span>
                                <b>VR or VRd</b> (bortezomib + lenalidomide ±
                              </span>{" "}
                              <span
                                className="hyperlinkText modal-popup"
                                data-tooltip-id="encounterTip"
                                onClick={() =>
                                  setShowHideHyperlinkModalS5VR(true)
                                }
                              >
                                {" "}
                                dexamethasone/prednisone/prednisolone)
                              </span>
                              {showHideHyperlinkModalS5VR && (
                                <InfoPopUp
                                  description={hyperlinkTextS5Radio}
                                  OkClick={() =>
                                    setShowHideHyperlinkModalS5VR(false)
                                  }
                                />
                              )}{" "}
                            </span>
                          </label>
                        </div>
                        <div className="option-fbox">
                          <input style={{marginTop: "6px"}}
                            type="radio"
                            id="S5_NDMM_IsaVR"
                            name="pt_S5_NDMM"
                            value={5}
                            checked={criteriaNDMM.pt_S5_NDMM === 5}
                            onChange={(e) => {
                              HandleFieldChange(e, "IsaVR/IsaVRd", sct);
                            }}
                          />
                          <label htmlFor="S5_NDMM_IsaVR">
                            <span className="radio-text-padding">
                              <span>
                                <b>IsaVR or IsaVRd</b> (isatuximab + bortezomib +
                                lenalidomide ±
                              </span>{" "}
                              <span
                                className="hyperlinkText modal-popup"
                                data-tooltip-id="encounterTip"
                                onClick={() =>
                                  setShowHideHyperlinkModalS5IsaVR(true)
                                }
                              >
                                {" "}
                                dexamethasone/prednisone/prednisolone)
                              </span>
                              {showHideHyperlinkModalS5IsaVR && (
                                <InfoPopUp
                                  description={hyperlinkTextS5Radio}
                                  OkClick={() =>
                                    setShowHideHyperlinkModalS5IsaVR(false)
                                  }
                                />
                              )}{" "}
                            </span>
                          </label>
                        </div>
                        <div className="option-fbox">
                          <input style={{marginTop: "6px"}}
                            type="radio"
                            id="S5_NDMM_NOTA"
                            name="pt_S5_NDMM"
                            value={6}
                            checked={criteriaNDMM.pt_S5_NDMM === 6}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="S5_NDMM_NOTA">
                            <span className="radio-text-padding">
                              None of the above treatments
                            </span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.s5Empty}
                        message={EligibilityCriteria_Msgs.S5}
                      />
                    </div>



                                      {criteriaNDMM.pt_S5_NDMM !== null &&
                                          criteriaNDMM.pt_S5_NDMM !== 6 && criteriaNDMM.pt_S5_NDMM !== 3 &&  (
                                              <div className="question-bot-sapce">
                                                  <div className="question question-weight">
                                                      <span>S5.1</span>
                                                      <span className="quest-text-pad">
                                                      How many cycles, if any, of <b>CyBorD</b> (cyclophosphamide + bortezomib + dexamethasone) were received prior to initiating {""}
                                                          <span
                                                              style={{
                                                                  color: PageDesignConstants.fontColor,
                                                                  fontWeight: "bolder",
                                                              }}
                                                          >
                                                              {treatment1L}
                                                          </span>{" "}
                                                          {""}
                                                          ?
                                                      </span>

                                                  </div>
                                              <div className=" double-dig-answer">
                                                  <div className="">
                                                      <input
                                                          type="radio"
                                                          id="S51_Cycle_NotReceive"
                                                          name="pt_S51_NDMM"
                                                          value={1}
                                                          checked={criteriaNDMM.pt_S51_NDMM === 1}
                                                          onChange={(e) => {
                                                              HandleFieldChange(e, treatment1L);
                                                          }}
                                                      />
                                                      <label htmlFor="S51_Cycle_NotReceive">
                                                          <span className="radio-text-padding">
                                                              Patient did not receive CyBorD{" "}
                                                          </span>
                                                      </label>
                                                  </div>
                                                  <div className="">
                                                      <input
                                                          type="radio"
                                                          id="S51_Cycle_received"
                                                          name="pt_S51_NDMM"
                                                          value={2}
                                                          checked={criteriaNDMM.pt_S51_NDMM === 2}
                                                          onChange={(e) => {
                                                              HandleFieldChange(e, treatment1L);
                                                          }}
                                                      />
                                                      <label htmlFor="S51_Cycle_received">
                                                          <span className="radio-text-padding">
                                                              1 cycle of CyBorD received{" "}
                                                          </span>
                                                      </label>
                                                  </div>
                                                  <div className="">
                                                      <input
                                                          type="radio"
                                                          id="S51_Cycle_More"
                                                          name="pt_S51_NDMM"
                                                          value={3}
                                                          checked={criteriaNDMM.pt_S51_NDMM === 3}
                                                          onChange={(e) => {
                                                              HandleFieldChange(e, treatment1L);
                                                          }}
                                                      />
                                                      <label htmlFor="S51_Cycle_More">
                                                          <span className="radio-text-padding">
                                                              2 or more cycles of CyBorD received 
                                                          </span>
                                                      </label>
                                                  </div>
                                              </div>
                                              <ErrorField
                                                  show={hardErrList.s51Empty}
                                                  message={EligibilityCriteria_Msgs.S51}
                                              />
                                              <ErrorField
                                                  show={softErrList.s51Terminate}
                                                  message={EligibilityCriteria_Msgs.CohortIneligible}
                                              />
                                              </div>
                                          )}





                    {((criteriaNDMM.pt_S51_NDMM !== null &&
                                          criteriaNDMM.pt_S51_NDMM !== 3) || (criteriaNDMM.pt_S5_NDMM !== null&& criteriaNDMM.pt_S5_NDMM==3)) && (
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>S6.</span>
                            <span className="quest-text-pad">
                              Date of initiation of 1L treatment with {""}
                              <span
                                style={{
                                  color: PageDesignConstants.fontColor,
                                  fontWeight: "bolder",
                                }}
                              >
                                {treatment1L}
                              </span>{" "}
                              {""}
                                                      for MM
                                                      <br/>Please note that, for patients receiving one cycle of CyBorD, the date of initiation of IL treatment begins with the first dose of the DR/DRd, DVR/DVRd, VR/VRd, or IsaVR/IsaVRd regimen.
                                                  </span>

                          </div>
                          <div className="double-dig-answer">
                            <div className="option-without-input">
                              <input
                                type="radio"
                                id="S6_1L_MM_Date"
                                name="pt_S6_1L_MM"
                                value={1}
                                checked={criteriaNDMM.pt_S6_1L_MM === 1}
                                onChange={HandleFieldChange}
                              />

                              <span className="radio-text-padding">
                                <DateControl
                                  ctrlId={"s6_initial_1L_MM"}
                                  HandleDateChange={HandleDateChange}
                                  defDate={initialMM_s6}
                                  optionId={1}
                                  date={criteriaNDMM.s6_initial_1L_MM}
                                // onBlur={HandleFormFieldBlur}
                                />
                              </span>
                            </div>
                            <div className="option-without-input">
                              <input
                                type="radio"
                                id="S6_1L_MM_NoDate"
                                name="pt_S6_1L_MM"
                                value={99}
                                checked={criteriaNDMM.pt_S6_1L_MM === 99}
                                onChange={HandleFieldChange}
                              />
                              <label htmlFor="S6_1L_MM_NoDate">
                                <span className="radio-text-padding">
                                  Date of initiation unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={hardErrList.s6Empty}
                            message={EligibilityCriteria_Msgs.S6}
                          />
                          <ErrorField
                            show={hardErrList.s6EmptyDate}
                            message={EligibilityCriteria_Msgs.S6Date}
                          />
                          <ErrorField
                            show={softErrList.s6Terminate}
                            message={EligibilityCriteria_Msgs.CohortIneligible}
                          />
                          {/*<ErrorField*/}
                          {/*  show={criteriaNDMM.s6FollowUpDateValid}*/}
                          {/*  message={"Date of initiation of 1L treatment should be less or equal to end of follow up date"}*/}
                          {/*/>*/}
                        </div>
                      )}

                    {criteriaNDMM.pt_S6_1L_MM !== null &&
                      criteriaNDMM.pt_S6_1L_MM !== 99 &&
                      showNDMM === false && (
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>S7.</span>
                            <span className="quest-text-pad">
                              <span>Patient’s autologous or allogeneic</span>{" "}
                              <span
                                className="modal-popup"
                                data-tooltip-id="encounterTip"
                                onClick={() =>
                                  setShowHideHyperlinkModalS7(true)
                                }
                              >
                              stem cell transplant (SCT) eligibility status
                              </span>
                              {showHideHyperlinkModalS7 && (
                                <InfoPopUp
                                  description={hyperlinkTextS7}
                                  OkClick={() =>
                                    setShowHideHyperlinkModalS7(false)
                                  }
                                />
                              )}{" "}
                              while on {""}
                              <span
                                style={{
                                  color: PageDesignConstants.fontColor,
                                  fontWeight: "bolder",
                                }}
                              >
                                {treatment1L}
                              </span>{" "}
                              {""}
                            </span>
                          </div>
                          <div className=" double-dig-answer">
                            <div className="">
                              <input
                                type="radio"
                                id="S7_SCT_TE"
                                name="pt_S7_NDMM"
                                value={1}
                                checked={criteriaNDMM.pt_S7_NDMM === 1}
                                onChange={(e) => {
                                  HandleFieldChange(e, treatment1L, "TE");
                                }}
                              />
                              <label htmlFor="S7_SCT_TE">
                                <span className="radio-text-padding">
                                  Transplant eligible (TE){" "}
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id="S7_SCT_TIE"
                                name="pt_S7_NDMM"
                                value={2}
                                checked={criteriaNDMM.pt_S7_NDMM === 2}
                                onChange={(e) => {
                                  HandleFieldChange(e, treatment1L, "TIE");
                                }}
                              />
                              <label htmlFor="S7_SCT_TIE">
                                <span className="radio-text-padding">
                                  Transplant ineligible (TIE){" "}
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id="S7_SCT_Unknown"
                                name="pt_S7_NDMM"
                                value={99}
                                checked={criteriaNDMM.pt_S7_NDMM === 99}
                                onChange={(e) => {
                                  HandleFieldChange(e, treatment1L, sct);
                                }}
                              />
                              <label htmlFor="S7_SCT_Unknown">
                                <span className="radio-text-padding">
                                  Unknown eligibility status
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={hardErrList.s7Empty}
                            message={EligibilityCriteria_Msgs.S7}
                          />
                          <ErrorField
                            show={softErrList.s7Terminate}
                            message={EligibilityCriteria_Msgs.CohortIneligible}
                          />
                        </div>
                      )}

                    {criteriaNDMM.pt_S7_NDMM !== null &&
                      criteriaNDMM.pt_S7_NDMM !== 99 &&
                      showS8 == true && (
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>S8.</span>
                            <span className="quest-text-pad">
                              Patient received autologous or allogeneic SCT
                              prior to initiating treatment with{" "}
                              <span
                                style={{
                                  color: PageDesignConstants.fontColor,
                                  fontWeight: "bolder",
                                }}
                              >
                                {ndmm}
                              </span>{" "}
                              on{" "}
                              <span
                                style={{
                                  color: PageDesignConstants.fontColor,
                                  fontWeight: "bolder",
                                }}
                              >
                                {criteriaNDMM.s6_initial_1L_MM}
                              </span>{" "}
                              ?
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="option-without-input">
                              <input
                                type="radio"
                                id="S8_NDMM_Yes"
                                name="pt_S8_NDMM"
                                value={1}
                                checked={criteriaNDMM.pt_S8_NDMM === 1}
                                onChange={HandleFieldChange}
                              />
                              <label htmlFor="S8_NDMM_Yes">
                                <span className="radio-text-padding">Yes</span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <input
                                type="radio"
                                id="S8_NDMM_No"
                                name="pt_S8_NDMM"
                                value={0}
                                checked={criteriaNDMM.pt_S8_NDMM === 0}
                                onChange={HandleFieldChange}
                              />
                              <label htmlFor="S8_NDMM_No">
                                <span className="radio-text-padding">No</span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={hardErrList.s8Empty}
                            message={EligibilityCriteria_Msgs.S8}
                          />
                          <ErrorField
                            show={softErrList.s8Terminate}
                            message={EligibilityCriteria_Msgs.CohortIneligible}
                          />
                        </div>
                      )}

                    {criteriaNDMM.pt_S8_NDMM !== "" &&
                      criteriaNDMM.pt_S8_NDMM !== null &&
                      criteriaNDMM.pt_S8_NDMM !== 1 && (
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>S9.</span>
                            <span className="quest-text-pad">
                              <span>Patient received </span>{" "}
                              <span
                                className="modal-popup"
                                data-tooltip-id="encounterTip"
                                onClick={() =>
                                  setShowHideHyperlinkModalS9(true)
                                }
                              >
                                                          systemic treatments
                              </span>
                              {showHideHyperlinkModalS9 && (
                                <InfoPopUp
                                  description={hyperlinkTextS9}
                                  OkClick={() =>
                                    setShowHideHyperlinkModalS9(false)
                                  }
                                />
                              )}{" "}
                                                      for MM within 6 months <u>prior to</u> treatment with{" "}
                              <span
                                style={{
                                  color: PageDesignConstants.fontColor,
                                  fontWeight: "bolder",
                                }}
                              >
                                {" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                  }}
                                >
                                  {ndmm}
                                </span>{" "}
                              </span>{" "}
                              (i.e., from{" "}
                              <span
                                style={{
                                  color: PageDesignConstants.fontColor,
                                  fontWeight: "bolder",
                                }}
                              >
                                {formatDateMMDDYYYY(
                                  criteriaNDMM.s6_initial_1L_MM
                                )}
                              </span>{" "}
                              to{" "}
                              <span
                                style={{
                                  color: PageDesignConstants.fontColor,
                                  fontWeight: "bolder",
                                }}
                              >
                                {criteriaNDMM.s6_initial_1L_MM}
                              </span>{" "}
                              )?
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="option-without-input">
                              <input
                                type="radio"
                                id="S9_NDMM_Yes"
                                name="pt_S9_NDMM"
                                value={1}
                                checked={criteriaNDMM.pt_S9_NDMM === 1}
                                onChange={HandleFieldChange}
                              />
                              <label htmlFor="S9_NDMM_Yes">
                                <span className="radio-text-padding">Yes</span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <input
                                type="radio"
                                id="S9_NDMM_No"
                                name="pt_S9_NDMM"
                                value={0}
                                checked={criteriaNDMM.pt_S9_NDMM === 0}
                                onChange={HandleFieldChange}
                              />
                              <label htmlFor="S9_NDMM_No">
                                <span className="radio-text-padding">No</span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={hardErrList.s9Empty}
                            message={EligibilityCriteria_Msgs.S9}
                          />
                          <ErrorField
                            show={softErrList.s9Terminate}
                            message={EligibilityCriteria_Msgs.CohortIneligible}
                          />
                        </div>
                      )}

                    {criteriaNDMM.pt_S9_NDMM !== "" &&
                      criteriaNDMM.pt_S9_NDMM !== null &&
                      criteriaNDMM.pt_S9_NDMM !== 1 && (
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>S10.</span>
                            <span className="quest-text-pad">
                              Was the patient diagnosed with any of the
                              following prior to initiating{" "}
                              <span
                                style={{
                                  color: PageDesignConstants.fontColor,
                                  fontWeight: "bolder",
                                }}
                              >
                                {ndmm}
                              </span>{" "}
                            </span>
                          </div>
                          <div className=" double-dig-answer">
                            <div className="">
                              <input
                                type="checkbox"
                                id="S10_NDMM_Amyloid"
                                name="pt_S10_NDMM_Amyloid"
                                checked={criteriaNDMM.pt_S10_NDMM_Amyloid}
                                onChange={HandleFieldChange}
                              />
                              <label htmlFor="race_white">
                                <span className="radio-text-padding">
                                  Amyloid light-chain amyloidosis{" "}
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <label className="long-text-option">
                                <input
                                  type="checkbox"
                                  id="S10_NDMM_Invasive"
                                  name="pt_S10_NDMM_Invasive"
                                  checked={criteriaNDMM.pt_S10_NDMM_Invasive}
                                  onChange={HandleFieldChange}
                                />

                                <span className="radio-text-padding">
                                  Invasive malignancy (except basal and squamous
                                  carcinomas as well as carcinomas in situ
                                  [e.g., breast, cervical, prostate, etc.])
                                  other than MM
                                </span>
                              </label>
                            </div>

                            <div className="">
                              <input
                                type="radio"
                                id="S10_NDMM_NOTA"
                                name="pt_S10_NDMM_NOTA"
                                value={1}
                                checked={criteriaNDMM.pt_S10_NDMM_NOTA === 1}
                                onChange={HandleFieldChange}
                              />
                              <label htmlFor="S10_NDMM_NOTA">
                                <span className="radio-text-padding">
                                  None of the above{" "}
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={hardErrList.s10Empty}
                            message={EligibilityCriteria_Msgs.S10}
                          />
                          <ErrorField
                            show={softErrList.s10Terminate}
                            message={EligibilityCriteria_Msgs.CohortIneligible}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NDMMCriteria;
