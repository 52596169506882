export const AppReducerActions = {
  TOGGLE_LOADER: "TOGGLE_LOADER",
  SHOW_TOAST: "SHOW_TOAST",
  SHOW_ACTIONPOPUP: "SHOW_ACTIONPOPUP",
  CLOSE_ACTIONPOPUP: "CLOSE_ACTIONPOPUP",
  PAGE_DATA_CHANGED: "PAGE_DATA_CHANGED",
  SHOW_TOPMENU: "SHOW_TOPMENU",
  SET_ACTIVETAB: "SET_ACTIVETAB",
  SET_PATIENT_CONFIG_DATA: "SET_PATIENT_CONFIG_DATA",
  SET_ISACTIVE_SIDEMENU:"SET_ISACTIVE_SIDEMENU",
  //SHOW_ABSTRACTOR_POPUP: "SHOW_ABSTRACTOR_POPUP"
  //CLOSE_ABSTRACTOR_POPUP: "CLOSE_ABSTRACTOR_POPUP"
};
