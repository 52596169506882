import React from "react";
import { useNavigate } from "react-router";
import { AddLocalStorageData, GetLocalStorageData, isStringEmpty } from "../Helpers/Utilities";
import { SideMenuItems } from "../Helpers/Enums";

const SaveAndNavigate = (props) => {
  const navigate = useNavigate();
  let patientData = GetLocalStorageData("patient")
  patientData = patientData && JSON.parse(patientData);

  const locked = patientData?.locked ?? false;
  //const isEligible = props?.isEligible??true;
  const eligibilityCriteriaLocked = patientData?.eligibilityCriteriaLocked ?? false;
  const canEdit = patientData?.canEdit ?? false;

  //Validate and save
  const HandlePreviousClick = async () => {
    const eligibilityCriteriaTabs = ["/generalinformation", "/ndmmcriteria", "/rrmmcriteria", "/exclusioncriteria", "/transitiontochartabstraction"];

    // if (props.paths.prevPage === "/Introduction") {
    //   if (!locked)
    //     await props.Save(true);
    //   navigate(props.paths.prevPage);
    // }
    // else 
    if (props.paths.prevPage === "/PatientScreening") {
      navigate(props.paths.prevPage);
    }
    else
      if (!canEdit ||
        (eligibilityCriteriaLocked && !isStringEmpty(props.paths.currPage) && eligibilityCriteriaTabs.includes(props.paths.currPage.toLowerCase())) ||
        await props.Save(true)) {
        navigate(props.paths.prevPage);
      }
    window.scrollTo(0, 0);
    LoadConfig()
  }
  //Validate and save
  const HandleNextClick = async () => {
    const eligibilityCriteriaTabs = ["/generalinformation", "/ndmmcriteria", "/rrmmcriteria", "/exclusioncriteria", "/transitiontochartabstraction"];

    if (!canEdit ||
      (eligibilityCriteriaLocked && !isStringEmpty(props.paths.currPage) && eligibilityCriteriaTabs.includes(props.paths.currPage.toLowerCase())) ||
      await props.Save(true)) {
      navigate(props.paths.nextPage);
    }
    else {
      if (props.paths.currPage === "/PatientScreening")
        navigate(props.paths.nextPage);
    }
    window.scrollTo(0, 0);
    LoadConfig()
  };
  //save without validating
  const HandleSaveClick = async () => {
    locked || await props.Save(false)
    window.scrollTo(0, 0);
    LoadConfig()
  }
  //Validate and save
  const HandleSubmitClick = async () => {
    locked || await props.Save(true)
    window.scrollTo(0, 0);
    LoadConfig()
  }
  const LoadConfig = () => {
    AddLocalStorageData("loadConfig", true)
  }
  return (
    <>
      {props.paths.prevPage && (
        <a onClick={HandlePreviousClick}>
          <img
            className="previous-btn-padding"
            src="../Assets/images/previous.png"
            alt="Previous"
            title="Previous"
          />
        </a>
      )}
      {canEdit &&
        (props.paths.currPage !== "/PatientScreening" &&
          props.paths.currPage !== "/GeneralInformation" &&
          props.paths.currPage !== "/ChartSelection" &&
          props.paths.currPage !== "/NDMMCriteria" &&
          props.paths.currPage !== "/RRMMCriteria" &&
          props.paths.currPage !== "/ExclusionCriteria" &&
          props.paths.currPage !== "/TransitionToChartAbstraction" &&
          props.paths.currPage !== "/AEReporting" &&
          props.paths.currPage !== "/IMWGReporting" &&
          props.paths.currPage !== "/LineOfTherapy") &&
        <a
          className="btn save-btn"
          onClick={HandleSaveClick}
          style={{ fontWeight: "600", fontSize: "large", marginRight: "20px" }}
        >
          Save
        </a>
      }
      {canEdit &&
        (
          props.paths.currPage !== "/PatientScreening" &&
          props.paths.currPage !== "/ChartSelection" &&
          props.paths.currPage !== "/AEReporting" &&
          props.paths.currPage !== "/IMWGReporting" &&
          props.paths.currPage !== "/LineOfTherapy") &&
        (!eligibilityCriteriaLocked ||
          (eligibilityCriteriaLocked &&
            !isStringEmpty(props.paths.currPage) &&
            !["/generalinformation", "/ndmmcriteria", "/rrmmcriteria", "/exclusioncriteria", "/transitiontochartabstraction"].includes(props.paths.currPage.toLowerCase()))) &&
        <a
          className="btn save-btn"
          onClick={HandleSubmitClick}
          style={{ fontWeight: "600", fontSize: "large" }}
        >
          Validate
        </a>
      }
      {props.paths.nextPage && (
        <a onClick={HandleNextClick}>
          <img
            className="next-btn-padding"
            src="../Assets/images/next.png"
            alt="Next"
            title="Next"
          />
        </a>
      )}
    </>
  );
};

export default SaveAndNavigate;
