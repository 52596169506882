import { AppContextIntialState } from "../Helpers/Enums";
import { AppReducerActions } from "../ReducerActions/AppReducerActions";

const InitialState = {
  ...AppContextIntialState,
};

export const AppReducer = (state = InitialState, action) => {
  switch (action.type) {
    case AppReducerActions.PAGE_DATA_CHANGED: {
      const { pageDataChanged } = action;
      return { ...state, pageDataChanged };
    }

    case AppReducerActions.TOGGLE_LOADER: {
      const { value } = action;
      return { ...state, isLoading: value };
    }

    case AppReducerActions.SHOW_TOAST: {
      const { message, toastType } = action;
      return {
        ...state,
        showToast: !state.showToast,
        toastMessage: message,
        toastType,
      };
    }

    case AppReducerActions.SHOW_ACTIONPOPUP: {
      const { title, description, confirmButtonLabel, disableCancelBtn } =
        action;
      return {
        ...state,
        actionPopUp: {
          isOpen: true,
          title,
          description,
          confirmButtonLabel,
          disableCancelBtn,
        },
      };
    }
    case AppReducerActions.CLOSE_ACTIONPOPUP: {
      return {
        ...state,
        actionPopUp: {
          isOpen: false,
          ...AppContextIntialState.actionPopUp,
        },
      };
    }
    case AppReducerActions.SHOW_TOPMENU: {
      const { value } = action
      return {
        ...state,
        showTopMenu: value
      };
    }
    case AppReducerActions.SET_ACTIVETAB: {
      const { value } = action
      return {
        ...state,
        activeTab: value
      };
    }

    case AppReducerActions.SET_PATIENT_CONFIG_DATA: {
      const { data } = action;
      return { ...state, patientConfigData: data };
    }
    

    case AppReducerActions.SET_ISACTIVE_SIDEMENU: {
      const { value } = action;

      return { ...state, isSideMenuActive: value };
    }
    // case AppReducerActions.SHOW_ABSTRACTOR_POPUP: {
    //   const {value} = action
    //   return{
    //     ...state,
    //     abstractorPopUp: {
    //       ...state.abstractorPopUp,
    //       isOpen: value
    //     }
    //   }
    // }
    // case AppReducerActions.CLOSE_ABSTRACTOR_POPUP: {
    //   return{
    //     ...state,
    //     isAbstractorPopupOpen: false
    //   }
    // }

    default:
      return { ...state };
  }
};
