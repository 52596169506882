import React, { useContext, useEffect, useState } from "react";
import ErrorField from "../../../Components/ErrorField";
import {
  HTTPResponse,
  PageDesignConstants,
  RRMMCohortTreatment,
  SideMenuItems,
  ToastMessageType,
  ToastMessages,
} from "../../../Helpers/Enums";
import SideBarMenu from "../../../Components/SideBarMenu";
import {
  AddLocalStorageData,
  CheckDateValidation,
  GetLocalStorageData,
  GetLoggedInUserID,
  GetOrdinal,
  IsDateAfter,
  checkCARTDate,
  isStringEmpty,
} from "../../../Helpers/Utilities";
import DateControl from "../../../Components/DateControl";
import PatientDetails from "../../../Components/PatientInformation";
import SaveAndNavigate from "../../../Components/SaveAndNavigate";
import {
  CommonError_Msgs,
  TTCharacteristics_MSGS,
  hyperlinkTextQ36Tecvayli,
  hyperlinkTextQ36Talvey,
  hyperlinkTextLineOfTherapy,
} from "../../../Helpers/HelperText";
import { AppContext } from "../../../Contexts/AppContextProvider";
import {
  AdditionalTreatment,
  AdditionalTreatmentService,
} from "../../../WebApiServices/AdditionalTreatment.service";

import InfoPopUp from "../../../Components/InfoPopUp";
import { forEach } from "jszip";

const TTCharacteristics = () => {
  const storedData = GetLocalStorageData("CohortTreatment");
  const pid = GetLocalStorageData("patientId") ?? 0;

  const [ndmmAvailable, setNdmmAvailable] = useState(false);

  //   let ModelListt =[];
  //   if (storedData) {
  //     const parseData = JSON.parse(storedData);
  //    ModelListt = parseData.filter(
  //       (item) => item.ndmm === true && item.isEligible
  //     );
  //   }
  // if(ModelListt && ModelListt.length>0)
  // {
  //   setNdmmAvailable(true);
  // }

  const [isTechvayliSelected, setTechvayliSelected] = useState(false);
  const [isTalveySelected, setTalveySelected] = useState(false);

  const [Q39TechConditionMet, setQ39TechConditionMet] = useState(false);
  const [Q39TalConditionMet, setQ39TalConditionMet] = useState(false);

  // const [Q36TechConditionMet,setQ36TechConditionMet] = useState(false);
  // const [Q36TalConditionMet,setQ36TalConditionMet] = useState(false);

  const [indexDateRRMM_techHard, setIndexDateRRMM_techHard] = useState(false);
  const [indexDateRRMM_talHard, setIndexDateRRMM_talHard] = useState(false);

  const [tecvayliDateRRMM, setTecvayliDateRRMM] = useState(
    "[Bispecifics/CAR-T end of follow up for Tec]"
  );
  const [talveyDateRRMM, setTalveyDateRRMM] = useState(
    "[Bispecifics/CAR-T end of follow up for Tal]"
  );

  const [rrmmAvailable, setRRMMAvailable] = useState(false);

  const [techIndexDate, setTechIndexDate] = useState("");
  const [talIndexDate, setTalIndexDate] = useState("");
  const [endOfFollowUpDates, setEndOfFollowUpDates] = useState(
    "[Bispecifics/CAR-T end of follow up for Tec or Tal]"
  );
  const [
    showHideHyperlinkModalQ36Tecvayli,
    setShowHideHyperlinkModalQ36Tecvayli,
  ] = useState(false);
  const [showHideHyperlinkModalQ36Talvey, setShowHideHyperlinkModalQ36Talvey] =
    useState(false);

  const [showLineOfTherapyModal, setShowLineOfTherapyModal] = useState(false);

  const {
    ShowToast,
    ToggleLoader,
    HandleSessionTimeout,
    isSideMenuActive,
    setIsSideMenuActive,
  } = useContext(AppContext);

  const [showTopErrMsg, setShowTopErrMsg] = useState(false);

  const navigatePaths = {
    prevPage: ndmmAvailable
      ? "/NDMMSCTCharacteristics"
      : "/NonCohortDefiningTreatment",
    currPage: "/TTCharacteristics",
    nextPage: rrmmAvailable ? "/CarTCharacteristics" : "/IMWGReporting",
  };

  const TechvayliRow = {
    teclistamatalquetamabdosagebridgeid: 0,
    cohorttreatmentid: GetLocalStorageData("techvayliID") || 0,

    //TECHVAYLI
    dose_administration_tech: null,
    dosing_phase_tech: null,
    siteofcare_tech: null,
    // dt_admission_tech: null,
    //dt_discharge_tech: null,

    dose_administration_tech_dt: "",
    dt_admission_tech_dt: "",
    dt_discharge_tech_dt: "",

    dose_administration_techHard: false,
    dosing_phase_techHard: false,
    siteofcare_techHard: false,
    dt_admission_techHard: false,
    dt_discharge_techHard: false,

    dose_administration_techHard_dt: false,
    dt_admission_techHard_dt: false,
    dt_discharge_techHard_dt: false,
    dt_admission_techHard_dt_check: false,
    dt_discharge_techHard_dt_check: false,

    dt_tech_greater_prevCheck: false,

    Q36TechConditionMet: false,
  };

  const TalveyRow = {
    teclistamatalquetamabdosagebridgeid: 0,
    cohorttreatmentid: GetLocalStorageData("talveyID") || 0,

    //TALVEY

    dose_administration_tal: null,
    dosing_phase_tal: null,
    siteofcare_tal: null,
    //dt_admission_tal: null,
    // dt_discharge_tal: null,

    dose_administration_tal_dt: "",
    dt_admission_tal_dt: "",
    dt_discharge_tal_dt: "",

    dose_administration_talHard: false,
    dosing_phase_talHard: false,
    siteofcare_talHard: false,
    dt_admission_talHard: false,
    dt_discharge_talHard: false,

    dose_administration_talHard_dt: false,
    dt_admission_talHard_dt: false,
    dt_discharge_talHard_dt: false,

    dt_admission_talHard_dt_check: false,
    dt_discharge_talHard_dt_check: false,

    dt_tal_greater_prevCheck: false,

    //Q36TalConditionMet: false,
  };
  //36.1
  const HospitalizationRow = {
    teclistamatalquetamabhospitalizationbridgeid: 0,
    cohorttreatmentid: 0,
    //B
    dt_admission: null,
    dt_admission_dt: "",
    dt_admission_Hard: false,
    dt_admission_Hard_dt: false,
    // dt_admission_Hard_dt_check: false,

    //C
    dt_discharge: null,
    dt_discharge_dt: "",
    dt_discharge_Hard: false,
    dt_discharge_Hard_dt: false,
    dt_discharge_Hard_dt_check: false,

    //D
    hospReason1: false,
    hospReason2: false,
    hospReason3: false,
    hospReason4: false,
    hospReason5: false,
    hospReason6: false,
    hosp_reason_oth_txt: "",
    hospReasonUnk: false,
    hospReasonEmptyHard: false,
    hospReasonTxtEmpty: false,
  };

  // const[ttcharDetails, setTTcharDetails] =  useState({
  //   teclistamabcharacteristicsid:0,
  //   cohorttreatmentid: 0,

  //   Q37TechvayliRd:null,
  //   Q37TalveyRd:null,

  // });

  // const InitialHardErrList = {
  //   q37TechvayliEmpty: false,
  //   q37TalveyEmpty: false,
  // };
  //const [showDateRedFreqTec,setShowDateRedFreqTec] =  useState(false);
  //const [showDateRedFreqTal,setShowDateRedFreqTal] =  useState(false);
  const Q37TechRow = {
    teclistamabcharacteristicsid: 0,
    cohorttreatmentid: GetLocalStorageData("techvayliID") || 0,
    Q37TechvayliRd: null,

    q37TechvayliEmpty: false,

    q37_Tec_Dose_week_frquencyRd: null,
    q37_Tec_Dose_week_frquency_txt: "",
    q37_Tec_Dose_week_frquencyRd_Empty: false,
    q37_Tec_Dose_week_frquency_txt_Empty: false,

    //40
    Q40dosingfreqRd: null,
    Q40IgILevelRd: null,
    Q40init_dt_Rd: null,
    Q40init_dt: "",
    primaryProf: false,
    secondaryProf: false,
    otherReson: false,

    dosing_freq_oth_txt: "",
    showDateRedFreqTec: false,

    //40 validations state
    Q40DosingFreqEmptyHard: false,
    Q40DosingFreqTxtEmptyHard: false,
    Q40IgGEmptyHard: false,
    Q40Init_dtHard: false,
    Q40Init_RdHard: false,
    Q40ReasonEmptyHard: false,

    //39
    tocilizumab_tech: false,
    Granulocyte: false,
    noneoftheabove_tech: null,
    SteroidsTech: false,

    //39 - 1.1
    cytokin_tech: false,
    crs_tech: false,
    neurotoxicity_tech: false,
    tmt_neurotoxicity_tech: false,
    tmt_tech_unknown: false,

    // New Phase
    steroid_pre_tmt_tech: false,
    cytokin_tech_steroids: false,
    crs_tech_steroids: false,
    neurotoxicity_tech_steroids: false,
    tmt_neurotoxicity_tech_steroids: false,
    tmt_steroids_tech_unknown: false,
    steroids_cytokin_tech: false,

    //Tecvayli New column
    betamethasone_tech: false,
    betamethasone_techNum: "",

    betamethasone_tech_txtEmpty: false,

    dexamethasone_tech: false,
    dexamethasone_techNum: "",

    dexamethasone_tech_txtEmpty: false,

    hydrocortisone_tech: false,
    hydrocortisone_techNum: "",

    hydrocortisone_tech_txtEmpty: false,

    methylprednisolone: false,
    methylprednisoloneNum: "",
    methylprednisolone_txtEmpty: false,

    prednisolone_tech: false,
    prednisolone_techNum: "",
    prednisolone_tech_txtEmpty: false,

    prednisone_tech: false,
    prednisone_techNum: "",

    prednisone_tech_txtEmpty: false,

    triamcinolone_tech: false,
    triamcinolone_techNum: "",
    triamcinolone_tech_txtEmpty: false,

    dose_name_tech_unknown: false,

    name_dose_steroidsHardEmpty: false,
    steroids_tech_txtEmpty: false,

    //1.3
    prophylactic_use_tech: false,
    oth_use_tech: false,
    unknown_tech: null,

    Q39TechEmptyHard: false,
    Q39techReasonHard: false,
    Q39techGranduloEmptyHard: false,

    Q39techSteroidsEmptyHard: false,
  };

  const Q37TalRow = {
    teclistamabcharacteristicsid: 0,
    cohorttreatmentid: GetLocalStorageData("talveyID") || 0,
    Q37TalveyRd: null,

    q37TalveyEmpty: false,

    showDateRedFreqTal: false,

    //New Column Q37
    q37_Tal_Dose_week_frquencyRd: null,
    q37_Tal_Dose_week_frquency_txt: "",
    q37_Tal_Dosage_Rd: null,
    q37_Tal_Dosage_OtherTxt: "",

    q37_Tal_Dose_week_frquencyRd_Empty: false,
    q37_Tal_Dose_week_frquency_txt_Empty: false,
    q37_Tal_Dosage_Rd_Empty: false,
    q37_Tal_Dosage_OtherTxt_Empty: false,

    //talvey new column
    betamethasone_tal: false,
    betamethasone_talNum: "",

    dexamethasone_tal: false,
    dexamethasone_talNum: "",

    hydrocortisone_tal: false,
    hydrocortisone_talNum: "",

    methylprednisolone_tal: false,
    methylprednisolone_talNum: "",

    prednisolone_tal: false,
    prednisolone_talNum: "",

    prednisone_tal: false,
    prednisone_talNum: "",

    triamcinolone_tal: false,
    triamcinolone_talNum: "",

    dose_name_tal_unknown: false,

    name_dose_steroids_talHardEmpty: false,
    steroids_tal_txtEmpty: false,

    //39
    tocilizumab_tal: false,
    Steroids: false,
    noneoftheabove_tal: null,

    //1.1
    cytokin_tal: false,
    crs_tal: false,
    neurotoxicity_tal: false,
    tmt_neurotoxicity_tal: false,
    tmt_talvey_unknown: false,

    //1.2
    cytokin_tal_steroids: false,
    crs_tal_steroids: false,
    neurotoxicity_tal_steroids: false,
    tmt_neurotoxicity_tal_steroids: false,
    tmt_tal_unknown: false,

    steroid_pre_tmt_tal: false,

    Q39TalEmptyHard: false,
    Q39talReasonHard: false,
    Q39talSteroidsEmptyHard: false,
  };

  const Q38TechRow = {
    teclistamatalquetamabdosefrequencybridgeid: 0,
    cohorttreatmentid: GetLocalStorageData("techvayliID") || 0,

    Q38TechvayliRd: null,
    dt_dosefreq_tech_dt: "",

    Q38TechEmptyHard: false,
    Q38DateEmpty: false,
    Q38TechDateInvalid: false,

    q38_Tec_Dose_week_frquencyRd: null,
    q38_Tec_Dose_week_frquency_txt: "",

    q38_Tec_Dose_week_frquencyRd_Empty: false,
    q38_Tec_Dose_week_frquency_txt_Empty: false,
  };

  const Q38TalRow = {
    teclistamatalquetamabdosefrequencybridgeid: 0,
    cohorttreatmentid: GetLocalStorageData("talveyID") || 0,

    Q38TalveyRd: null,
    dt_dosefreq_tal_dt: "",

    Q39TalEmptyHard: false,
    Q39TalDateInvalid: false,

    Q38TalEmptyHard: false,
    Q38TalDateEmpty: false,
    Q38TalDateInvalid: false,

    q38_Tal_Dose_week_frquencyRd: null,
    q38_Tal_Dose_week_frquency_txt: "",
    q38_Tal_Dosage_Rd: null,
    q38_Tal_Dosage_OtherTxt: "",

    q38_Tal_Dose_week_frquencyRd_Empty: false,
    q38_Tal_Dose_week_frquency_txt_Empty: false,
    q38_Tal_Dosage_Rd_Empty: false,
    q38_Tal_Dosage_OtherTxt_Empty: false,
  };

  const [Q37TechDetails, setQ37TechDetails] = useState([Q37TechRow]);
  const [Q37TalDetails, setQ37TalDetails] = useState([Q37TalRow]);

  const [Q38TechDetails, setQ38TechDetails] = useState([Q38TechRow]);
  const [Q38TalDetails, setQ38TalDetails] = useState([Q38TalRow]);

  const [techvayliDetails, setTechvayliDetails] = useState([TechvayliRow]);
  const [talveyDetails, setTalveyDetails] = useState([TalveyRow]);
  // const [hardErrList, setHardErrList] = useState(InitialHardErrList);
  //36.1
  const [tecHospDetails, setTecHospDetails] = useState([HospitalizationRow]);
  const [talHospDetails, setTalHospDetails] = useState([HospitalizationRow]);

  // ADD/DELETE TECHVAYLI ROWS
  const DeleteTechvayliRow = (id) => {
    const newArray = [...techvayliDetails];
    newArray.splice(id, 1);
    setTechvayliDetails(newArray);
  };
  const AddTechvayliRow = () => {
    setTechvayliDetails((prev) => [
      ...prev,
      {
        ...TechvayliRow,
      },
    ]);
  };
  //36.1 Tec
  const DeleteTecHospRow = (id) => {
    const newArray = [...tecHospDetails];
    newArray.splice(id, 1);
    setTecHospDetails(newArray);
  };
  const AddTecHospRow = () => {
    setTecHospDetails((prev) => [
      ...prev,
      {
        ...HospitalizationRow,
        cohorttreatmentid: GetLocalStorageData("techvayliID") || 0,
      },
    ]);
  };

  // ADD/DELETE TALVEY ROWS
  const DeleteTalveyRow = (id) => {
    const newArray = [...talveyDetails];
    newArray.splice(id, 1);
    setTalveyDetails(newArray);
  };
  const AddTalveyRow = () => {
    setTalveyDetails((prev) => [
      ...prev,
      {
        ...TalveyRow,
      },
    ]);
  };
  //36.1 Tal
  const DeleteTalHospRow = (id) => {
    const newArray = [...talHospDetails];
    newArray.splice(id, 1);
    setTalHospDetails(newArray);
  };
  const AddTalHospRow = () => {
    setTalHospDetails((prev) => [
      ...prev,
      {
        ...HospitalizationRow,
        cohorttreatmentid: GetLocalStorageData("talveyID") || 0,
      },
    ]);
  };

  // Q38

  // ADD/DELETE TECHVAYLI ROWS
  const DeleteQ38TechvayliRow = (id) => {
    const newArray = [...Q38TechDetails];
    newArray.splice(id, 1);
    setQ38TechDetails(newArray);
  };
  const AddQ38TechvayliRow = () => {
    setQ38TechDetails((prev) => [
      ...prev,
      {
        ...Q38TechRow,
      },
    ]);
  };

  // ADD/DELETE TALVEY ROWS
  const DeleteQ38TalveyRow = (id) => {
    const newArray = [...Q38TalDetails];
    newArray.splice(id, 1);
    setQ38TalDetails(newArray);
  };
  const AddQ38TalveyRow = () => {
    setQ38TalDetails((prev) => [
      ...prev,
      {
        ...Q38TalRow,
      },
    ]);
  };

  //   const SavePage = () => {};

  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);

      let isValid = validate ? ValidateForm() : true;

      if (isValid) {
        setShowTopErrMsg(false);

        const TechvayliData = techvayliDetails.map((test) => ({
          Teclistamatalquetamabdosagebridgeid:
            test.teclistamatalquetamabdosagebridgeid,
          Cohorttreatmentid: test.cohorttreatmentid,
          DoseAdministrationDate: test.dose_administration_tech_dt
            ? test.dose_administration_tech_dt
            : "",
          DoseAdministrationDateUnk:
            test.dose_administration_tech === 99 ? true : null,
          DosePhase: test.dosing_phase_tech,
          SiteOfCare: test.siteofcare_tech,

          InpatientAdmissionDate: test.dt_admission_tech_dt
            ? test.dt_admission_tech_dt
            : "",
          // InpatientAdmissionDateUnk:
          //   test.dt_admission_tech === 99 ? true : null,

          InpatientDischargeDate: test.dt_discharge_tech_dt
            ? test.dt_discharge_tech_dt
            : "",
          // InpatientDischargeUnk: test.dt_discharge_tech === 99 ? true : null,
          // PatientStillHospitalized: test.dt_discharge_tech,
        }));
        //36.1 Tec
        const TechvayliHospitalData = tecHospDetails.map((test) => ({
          Teclistamatalquetamabhospitalizationbridgeid:
            test.teclistamatalquetamabhospitalizationbridgeid,
          Cohorttreatmentid: test.cohorttreatmentid,
          // Teclistamatalquetamabdosagebridgeid

          HospitalizationAdmissionDate: test.dt_admission_dt,
          HospitalizationAdmissionDateUnk: test.dt_admission === 99,
          NoHospitalizationDosage: test.dt_admission === 2,
          HospitalizationDischargeDate: test.dt_discharge_dt,
          HospitalizationDischargeUnk: test.dt_discharge === 99,
          PatientStillHospitalized: test.dt_discharge === 2,
          HospitalizationReasonAdministrationTeclistamab: test.hospReason1,
          HospitalizationReasonMonitorAe: test.hospReason2,
          HospitalizationReasonCrs: test.hospReason3,
          HospitalizationReasonIcans: test.hospReason4,
          HospitalizationReasonOtherae: test.hospReason5,
          HospitalizationReasonOtnernonae: test.hospReason6,
          HospitalizationReasonUnknown: test.hospReasonUnk,
          HospitalizationReasonOthernonaeTxt: test.hosp_reason_oth_txt,
        }));
        const TalveyData = talveyDetails.map((test) => ({
          Teclistamatalquetamabdosagebridgeid:
            test.teclistamatalquetamabdosagebridgeid,
          Cohorttreatmentid: test.cohorttreatmentid,

          DoseAdministrationDate: test.dose_administration_tal_dt
            ? test.dose_administration_tal_dt
            : "",
          DoseAdministrationDateUnk:
            test.dose_administration_tal === 99 ? true : null,
          DosePhase: test.dosing_phase_tal,
          SiteOfCare: test.siteofcare_tal,

          InpatientAdmissionDate: test.dt_admission_tal_dt
            ? test.dt_admission_tal_dt
            : "",
          // InpatientAdmissionDateUnk: test.dt_admission_tal === 99 ? true : null,

          InpatientDischargeDate: test.dt_discharge_tal_dt
            ? test.dt_discharge_tal_dt
            : "",
          // InpatientDischargeUnk: test.dt_discharge_tal === 99 ? true : null,
          // PatientStillHospitalized: test.dt_discharge_tal,
        }));
        //36.1 Tal
        const TalveyHospitalData = talHospDetails.map((test) => ({
          Teclistamatalquetamabhospitalizationbridgeid:
            test.teclistamatalquetamabhospitalizationbridgeid,
          Cohorttreatmentid: test.cohorttreatmentid,
          // Teclistamatalquetamabdosagebridgeid

          HospitalizationAdmissionDate: test.dt_admission_dt,
          HospitalizationAdmissionDateUnk: test.dt_admission === 99,
          NoHospitalizationDosage: test.dt_admission === 2,

          HospitalizationDischargeDate: test.dt_discharge_dt,
          HospitalizationDischargeUnk: test.dt_discharge === 99,
          PatientStillHospitalized: test.dt_discharge === 2,

          HospitalizationReasonAdministrationTeclistamab: test.hospReason1,
          HospitalizationReasonMonitorAe: test.hospReason2,
          HospitalizationReasonCrs: test.hospReason3,
          HospitalizationReasonIcans: test.hospReason4,
          HospitalizationReasonOtherae: test.hospReason5,
          HospitalizationReasonOtnernonae: test.hospReason6,
          HospitalizationReasonUnknown: test.hospReasonUnk,
          HospitalizationReasonOthernonaeTxt: test.hosp_reason_oth_txt,
        }));

        //Q37 and Q40 Payload
        const Q37TechDetail = Q37TechDetails.map((test) => ({
          Teclistamabcharacteristicsid: test.teclistamabcharacteristicsid,

          Cohorttreatmentid: test.cohorttreatmentid,

          Dosefrequencyreduced:
            test.Q37TechvayliRd,


          TreatmentInitialDoseFrequency: test.q37_Tec_Dose_week_frquencyRd,
          TreatmentInitialDoseFrequencyText:
            test.q37_Tec_Dose_week_frquency_txt,
          // TreatmentInitialDosage:
          // TreatmentInitialDosageOthtext:

          //40

          IvigDosageFrequency: test.Q40dosingfreqRd,
          LastIgglevel: test.Q40IgILevelRd,
          IvigInitiationDateUnk: test.Q40init_dt_Rd === 99 ? true : null,
          IvigInitiationDate: test.Q40init_dt,
          MedicationReasonPrimaryProphylactic: test.primaryProf,
          MedicationReasonSecondaryProphylactic: test.secondaryProf,
          MedicationReasonOth: test.otherReson,

          IvigDosageFrequencyOthTxt: test.dosing_freq_oth_txt,

          //39

          MedicationTocilizumab: test.tocilizumab_tech,
          MedicationGcsf: test.Granulocyte,
          MedicationNone: test.noneoftheabove_tech,
          MedicationSteroids: test.SteroidsTech,
          //39 - 1.1
          DosageusageReasonProphylacticCrs: test.cytokin_tech,
          DosagusageReasonTreatmentsupportiveCrs: test.crs_tech,
          DosagusageReasonProphylacticneurotoxicity: test.neurotoxicity_tech,
          DosagusageReasonTreatmentsupportiveNeurotoxicity:
            test.tmt_neurotoxicity_tech,
          DosagusageReasonTreatmentUnknown: test.tmt_tech_unknown,
          //39 - 1.3
          GcsfUsageReasonProphylactic: test.prophylactic_use_tech,
          GcsfUsageReasonOth: test.oth_use_tech,
          GcsfUsageReasonUnk: test.unknown_tech === 99 ? true : null,

          SteroidUsageReasonTreatmentRecommendation: test.steroid_pre_tmt_tech,

          SteroidsUsageReasonProphylacticCrs: test.cytokin_tech_steroids,
          SteroidsUsageReasonTreatmentsupportiveCrs: test.crs_tech_steroids,
          SteroidsUsageReasonProphylacticneurotoxicity:
            test.neurotoxicity_tech_steroids,
          SteroidsUsageReasonTreatmentsupportiveNeurotoxicity:
            test.tmt_neurotoxicity_tech_steroids,

          SteroidUsageReasonUnknown: test.tmt_steroids_tech_unknown,

          SteroidNameBetamethasone: test.betamethasone_tech,
          SteroidDoseBetamethasone: test.betamethasone_techNum,

          SteroidNameDexamethasone: test.dexamethasone_tech,
          SteroidDoseDexamethasone: test.dexamethasone_techNum,

          SteroidNameHydrocortisone: test.hydrocortisone_tech,
          SteroidDoseHydrocortisone: test.hydrocortisone_techNum,

          SteroidNameMethylprednisolone: test.methylprednisolone,
          SteroidDoseMethylprednisolone: test.methylprednisoloneNum,

          SteroidNamePrednisolone: test.prednisolone_tech,
          SteroidDosePrednisolone: test.prednisolone_techNum,

          SteroidNamePrednisone: test.prednisone_tech,
          SteroidDosePrednisone: test.prednisone_techNum,

          SteroidNameTriamcinolone: test.triamcinolone_tech,
          SteroidDoseTriamcinolone: test.triamcinolone_techNum,

          SteroidDoseNameUnknown: test.dose_name_tech_unknown,
        }));

        const Q37TalDetail = Q37TalDetails.map((test) => ({
          Teclistamabcharacteristicsid: test.teclistamabcharacteristicsid,

          Cohorttreatmentid: test.cohorttreatmentid,

          Dosefrequencyreduced:
            test.Q37TalveyRd,

          TreatmentInitialDoseFrequency: test.q37_Tal_Dose_week_frquencyRd,

          TreatmentInitialDoseFrequencyText:
            test.q37_Tal_Dose_week_frquency_txt,
          TreatmentInitialDosage: test.q37_Tal_Dosage_Rd,
          TreatmentInitialDosageOthtext: test.q37_Tal_Dosage_OtherTxt,

          //39

          MedicationTocilizumab: test.tocilizumab_tal,
          MedicationSteroids: test.Steroids,
          MedicationNone: test.noneoftheabove_tal,

          //1.1
          DosageusageReasonProphylacticCrs: test.cytokin_tal,
          DosagusageReasonTreatmentsupportiveCrs: test.crs_tal,
          DosagusageReasonProphylacticneurotoxicity: test.neurotoxicity_tal,
          DosagusageReasonTreatmentsupportiveNeurotoxicity:
            test.tmt_neurotoxicity_tal,
          DosagusageReasonTreatmentUnknown: test.tmt_talvey_unknown,
          // //1.2
          SteroidUsageReasonTreatmentRecommendation: test.steroid_pre_tmt_tal,
          SteroidsUsageReasonProphylacticCrs: test.cytokin_tal_steroids,
          SteroidsUsageReasonTreatmentsupportiveCrs: test.crs_tal_steroids,
          SteroidsUsageReasonProphylacticneurotoxicity:
            test.neurotoxicity_tal_steroids,
          SteroidsUsageReasonTreatmentsupportiveNeurotoxicity:
            test.tmt_neurotoxicity_tal_steroids,
          SteroidUsageReasonUnknown: test.tmt_tal_unknown,

          SteroidNameBetamethasone: test.betamethasone_tal,
          SteroidDoseBetamethasone: test.betamethasone_talNum,

          SteroidNameDexamethasone: test.dexamethasone_tal,
          SteroidDoseDexamethasone: test.dexamethasone_talNum,

          SteroidNameHydrocortisone: test.hydrocortisone_tal,
          SteroidDoseHydrocortisone: test.hydrocortisone_talNum,

          SteroidNameMethylprednisolone: test.methylprednisolone_tal,
          SteroidDoseMethylprednisolone: test.methylprednisolone_talNum,

          SteroidNamePrednisolone: test.prednisolone_tal,
          SteroidDosePrednisolone: test.prednisolone_talNum,

          SteroidNamePrednisone: test.prednisone_tal,
          SteroidDosePrednisone: test.prednisone_talNum,

          SteroidNameTriamcinolone: test.triamcinolone_tal,
          SteroidDoseTriamcinolone: test.triamcinolone_talNum,

          SteroidDoseNameUnknown: test.dose_name_tal_unknown,
        }));

        // Q38Tech payload
        const q38TechvayliData = Q38TechDetails.map((test) => ({
          Teclistamatalquetamabdosefrequencybridgeid:
            test.teclistamatalquetamabdosefrequencybridgeid,
          Cohorttreatmentid: test.cohorttreatmentid,

          DosefreqReductionDateUnk: test.Q38TechvayliRd ,
          DosefreqReductionDate: test.dt_dosefreq_tech_dt,

          TreatmentInitialDoseFrequency:
          test.q38_Tec_Dose_week_frquencyRd,

          TreatmentInitialDoseFrequencyText:
            test.q38_Tec_Dose_week_frquency_txt,
        }));

        // Q38talveyData
        const q38TalveyData = Q38TalDetails.map((test) => ({
          Teclistamatalquetamabdosefrequencybridgeid:
            test.teclistamatalquetamabdosefrequencybridgeid,
          Cohorttreatmentid: test.cohorttreatmentid,

          DosefreqReductionDateUnk: test.Q38TalveyRd ,
          DosefreqReductionDate: test.dt_dosefreq_tal_dt,

          TreatmentInitialDoseFrequency:
          test.q38_Tal_Dose_week_frquencyRd,
          TreatmentInitialDoseFrequencyText:
            test.q38_Tal_Dose_week_frquency_txt,
          TreatmentInitialDosageOthtext: test.q38_Tal_Dosage_OtherTxt,
          TreatmentInitialDosage: test.q38_Tal_Dosage_Rd,
        }));

        const TTCharModel = {
          Patientid: pid,
          UserId: GetLoggedInUserID(),
          isValidated: validate,
          //36
          TechvayliData: TechvayliData,
          TalveyData: TalveyData,
          //36.1
          TechvayliHospitalData,
          TalveyHospitalData,

          //37
          TTcharTechvayliModelList: Q37TechDetail,
          TTcharTalveyliModelList: Q37TalDetail,

          //38
          Q38TechvayliData: q38TechvayliData,
          Q38TalveyData: q38TalveyData,
        };

        if (pid > 0) {
          let response =
            await AdditionalTreatmentService.SaveTTCharacteristicData(
              TTCharModel
            );

          if (response.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            } else throw response.error;
          } else {
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            await LoadData(pid);
          }

          ToggleLoader(false);
        }
        return true;
      } else {
        ToggleLoader(false);
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  const LoadData = async () => {
    debugger
    try {
      if (pid > 0) {
        const response =
          await AdditionalTreatmentService.LoadTTCharacteristicData(pid);
console.log(response.data)

        if (response?.status === HTTPResponse.OK) {
          const { data } = response;

          setTecvayliDateRRMM(
            data.rrmmTecvayliDate !=="" 
              ? data.rrmmTecvayliDate :
              data.rrmmTecvayliDate ==="" && data.endofFollowUpDate !=""?  
            data.endofFollowUpDate
              : "[Bispecifics/CAR-T end of follow up for Tec]"
          );
          console.log(tecvayliDateRRMM)
          setTalveyDateRRMM(
            data.rrmmTalveyDate !=="" 
              ? data.rrmmTalveyDate :
              data.rrmmTalveyDate ==="" && data.endofFollowUpDate !=""? data.endofFollowUpDate :

               "[Bispecifics/CAR-T end of follow up for Tal]"
          );

          // setEndOfFollowUpDates(
          //   data.endofFollowUpDate
          //     ? data.endofFollowUpDate 
          //     : "[Bispecifics/CAR-T end of follow up for Tec or Tal]"
          // );
          setIndexDateRRMM_techHard(false);

          let cohortTreatmentModelListt = [];
          if (storedData) {
            const parsedData = JSON.parse(storedData);
            cohortTreatmentModelListt = parsedData.filter(
              (item) => item.ndmm === false && item.isEligible
            );
          }

          let modelList = [];
          if (storedData) {
            const parseData = JSON.parse(storedData);
            modelList = parseData.filter(
              (item) => item.ndmm === true && item.isEligible
            );
          }
          if (modelList.length > 0) {
            setNdmmAvailable(true);
          }

          if (cohortTreatmentModelListt.length > 0) {
            const isAbeOrCar = cohortTreatmentModelListt.some(
              (a) =>
                a.cohortTreatmentID === RRMMCohortTreatment.ABECMA ||
                a.cohortTreatmentID === RRMMCohortTreatment.CARVYKTI
            );
            if (isAbeOrCar) {
              setRRMMAvailable(true);
            }
          }

          const techCohort = cohortTreatmentModelListt.find(
            (item) => item.cohortTreatmentID === RRMMCohortTreatment.TECVAYLI
          );
          let isTACSelected = false;
          // let filteredTech = {};
          // let filteredTal = [];

          //   // Set the state based on the filtered data
          if (
            // cohortTreatmentModelListt.some(
            //   (item) => item.cohortTreatmentID === 9
            // )
            techCohort
          ) {
            isTACSelected = true;
            setTechvayliSelected(true);
            setTechIndexDate(techCohort.cohortIndexDate);
            // filteredTech = cohortTreatmentModelListt.find(
            //   (a) => a.cohortTreatmentID === 9
            // );
          }

          const talCohort = cohortTreatmentModelListt.find(
            (item) => item.cohortTreatmentID === RRMMCohortTreatment.TALVEY
          );
          let isTALSelected = false;
          if (
            // cohortTreatmentModelListt.some(
            //   (item) => item.cohortTreatmentID === 10
            // )
            talCohort
          ) {
            isTALSelected = true;
            setTalveySelected(true);

            setTalIndexDate(talCohort.cohortIndexDate);
            // filteredTal = cohortTreatmentModelListt.find(
            //   (a) => a.cohortTreatmentID === 10
            // );
          }

          const cohortTreatmentMap = new Map();
          if (cohortTreatmentModelListt) {
            cohortTreatmentModelListt.forEach((item) => {
              AddLocalStorageData("cohorttreatmentid", item.cohortTreatmentID);

              if (item.cohortTreatmentID === 9) {
                AddLocalStorageData("techvayliID", item.cohortTreatmentID);
              }

              if (item.cohortTreatmentID === 10) {
                AddLocalStorageData("talveyID", item.cohortTreatmentID);
              }
            });
          }

          let Q39TechConditionMet = false;

          if (data.techvayliData && data.techvayliData.length > 0) {
            const techvayliData = data.techvayliData.map((item) => {
              const cohortTreatment =
                cohortTreatmentMap.get(item.cohorttreatmentid) || {};

              Q39TechConditionMet =
                item.dosePhase === 1 ||
                item.dosePhase === 2 ||
                item.dosePhase === 3
                  ? true
                  : false;
              return {
                cohorttreatmentid: item.cohorttreatmentid,

                teclistamatalquetamabdosagebridgeid:
                  item.teclistamatalquetamabdosagebridgeid,

                dose_administration_tech_dt: item.doseAdministrationDate,
                dose_administration_tech:
                  item.doseAdministrationDateUnk === true
                    ? 99
                    : item.doseAdministrationDate
                    ? 1
                    : null,

                dosing_phase_tech: item.dosePhase,
                siteofcare_tech: item.siteOfCare,

                dt_admission_tech_dt: item.inpatientAdmissionDate,
                // dt_admission_tech:
                //   item.inpatientAdmissionDateUnk === true
                //     ? 99
                //     : item.inpatientAdmissionDate
                //     ? 1
                //     : null,

                dt_discharge_tech_dt: item.inpatientDischargeDate,
                // dt_discharge_tech: item.inpatientDischargeDate
                //   ? 1
                //   : item.pnpatientDischargeUnk
                //   ? 99
                //   : item.patientStillHospitalized
                //   ? 3
                //   : null,

                Q36TechConditionMet:
                  (item.dosePhase === 1 ||
                    item.dosePhase === 2 ||
                    item.dosePhase === 3) &&
                  (item.siteOfCare === 1 || item.siteOfCare === 3)
                    ? true
                    : false,

                // //Q38
                // Q38TechvayliRd:item.dosefreqReductionDateUnk === true?99:item.dosefreqReductionDate?1:null,
                // dt_dosefreq_tech_dt: item.dosefreqReductionDate,

                dose_administration_techHard: false,
                dosing_phase_techHard: false,
                siteofcare_techHard: false,
                dt_admission_techHard: false,
                dt_discharge_techHard: false,

                dose_administration_techHard_dt: false,
                dt_admission_techHard_dt: false,
                dt_discharge_techHard_dt: false,
                dt_admission_techHard_dt_check: false,
                dt_discharge_techHard_dt_check: false,
                dt_tech_greater_prevCheck: false,
              };
            });
            setQ39TechConditionMet(Q39TechConditionMet);
            //   setQ36TechConditionMet(Q36TechConditionMet);
            setTechvayliDetails(techvayliData);
          } else {
            const cohortTreatmentid = isTACSelected
              ? GetLocalStorageData("techvayliID")
              : 0;

            // Update TechvayliRow with cohorttreatmentid only
            TechvayliRow.cohorttreatmentid = cohortTreatmentid || 0;

            // Retain the remaining fields as is
            TechvayliRow.teclistamatalquetamabdosagebridgeid =
              TechvayliRow.teclistamatalquetamabdosagebridgeid || 0;
            TechvayliRow.dose_administration_tech =
              TechvayliRow.dose_administration_tech || null;
            TechvayliRow.dosing_phase_tech =
              TechvayliRow.dosing_phase_tech || null;
            TechvayliRow.siteofcare_tech = TechvayliRow.siteofcare_tech || null;
            // TechvayliRow.dt_admission_tech =
            //   TechvayliRow.dt_admission_tech || null;
            // TechvayliRow.dt_discharge_tech =
            //   TechvayliRow.dt_discharge_tech || null;
            TechvayliRow.dose_administration_tech_dt =
              TechvayliRow.dose_administration_tech_dt || "";
            TechvayliRow.dt_admission_tech_dt =
              TechvayliRow.dt_admission_tech_dt || "";
            TechvayliRow.dt_discharge_tech_dt =
              TechvayliRow.dt_discharge_tech_dt || "";

            TechvayliRow.dose_administration_techHard =
              TechvayliRow.dose_administration_techHard || false;
            TechvayliRow.dosing_phase_techHard =
              TechvayliRow.dosing_phase_techHard || false;
            TechvayliRow.siteofcare_techHard =
              TechvayliRow.siteofcare_techHard || false;
            TechvayliRow.dt_admission_techHard =
              TechvayliRow.dt_admission_techHard || false;
            TechvayliRow.dt_discharge_techHard =
              TechvayliRow.dt_discharge_techHard || false;

            // TechvayliRow.dt_tech_greater_prevCheck = TechvayliRow.dt_tech_greater_prevCheck  ||false;
            // //Q38
            // TechvayliRow.Q38TechvayliRd = TechvayliRow.Q38TechvayliRd || null;
            // TechvayliRow.dt_dosefreq_tech_dt = TechvayliRow.dt_dosefreq_tech_dt || "";
          }
          //36.1 Tec start
          if (
            data.techvayliHospitalData &&
            data.techvayliHospitalData.length > 0
          ) {
            const tecHospData = data.techvayliHospitalData.map((item) => {
              return {
                ...HospitalizationRow,
                teclistamatalquetamabhospitalizationbridgeid:
                  item.teclistamatalquetamabhospitalizationbridgeid,
                cohorttreatmentid: item.cohorttreatmentid,
                //B
                dt_admission: item.hospitalizationAdmissionDateUnk
                  ? 99
                  : item.noHospitalizationDosage
                  ? 2
                  : item.hospitalizationAdmissionDate
                  ? 1
                  : null,
                dt_admission_dt: item.hospitalizationAdmissionDate,
                //C
                dt_discharge: item.hospitalizationDischargeUnk
                  ? 99
                  : item.patientStillHospitalized
                  ? 2
                  : item.hospitalizationDischargeDate
                  ? 1
                  : null,
                dt_discharge_dt: item.hospitalizationDischargeDate,
                //D
                hospReason1:
                  item.hospitalizationReasonAdministrationTeclistamab,
                hospReason2: item.hospitalizationReasonMonitorAe,
                hospReason3: item.hospitalizationReasonCrs,
                hospReason4: item.hospitalizationReasonIcans,
                hospReason5: item.hospitalizationReasonOtherae,
                hospReason6: item.hospitalizationReasonOtnernonae,
                hosp_reason_oth_txt: item.hospitalizationReasonOthernonaeTxt,
                hospReasonUnk: item.hospitalizationReasonUnknown,
              };
            });

            setTecHospDetails(tecHospData);
          } else {
            const cohorttreatmentid = isTACSelected
              ? GetLocalStorageData("techvayliID")
              : 0;

            setTecHospDetails([
              {
                ...HospitalizationRow,
                cohorttreatmentid,
              },
            ]);
          }
          //end

          // Process TalveyData
          let Q39TalConditionMet = false;
          // let Q36TalConditionMet = false;
          if (data.talveyData && data.talveyData.length > 0) {
            const talveyData = data.talveyData.map((item) => {
              const cohortTreatment =
                cohortTreatmentMap.get(item.cohorttreatmentid) || {};
              Q39TalConditionMet =
                item.dosePhase === 1 ||
                item.dosePhase === 2 ||
                item.dosePhase === 3 ||
                item.dosePhase === 4
                  ? true
                  : false;

              return {
                cohorttreatmentid: item.cohorttreatmentid,

                teclistamatalquetamabdosagebridgeid:
                  item.teclistamatalquetamabdosagebridgeid,

                dose_administration_tal_dt: item.doseAdministrationDate,
                dose_administration_tal:
                  item.doseAdministrationDateUnk === true
                    ? 99
                    : item.doseAdministrationDate
                    ? 1
                    : null,

                dosing_phase_tal: item.dosePhase,
                siteofcare_tal: item.siteOfCare,

                dt_admission_tal_dt: item.inpatientAdmissionDate,
                // dt_admission_tal:
                //   item.inpatientAdmissionDateUnk === true
                //     ? 99
                //     : item.inpatientAdmissionDate
                //     ? 1
                //     : null,

                dt_discharge_tal_dt: item.inpatientDischargeDate,
                //  dt_discharge_tal : item.inpatientDischargeDate
                //     ? 1
                //     : item.pnpatientDischargeUnk
                //     ? 99
                //     : item.patientStillHospitalized
                //     ? 3
                //     : null,

                // Q36TalConditionMet:
                //   (item.dosePhase === 1 ||
                //     item.dosePhase === 2 ||
                //     item.dosePhase === 3 ||
                //     item.dosePhase === 4) &&
                //   (item.siteOfCare === 1 || item.siteOfCare === 3)
                //     ? true
                //     : false,

                dose_administration_talHard: false,
                dosing_phase_talHard: false,
                siteofcare_talHard: false,
                dt_admission_talHard: false,
                dt_discharge_talHard: false,

                dose_administration_talHard_dt: false,
                dt_admission_talHard_dt: false,
                dt_discharge_talHard_dt: false,

                dt_admission_talHard_dt_check: false,
                dt_discharge_talHard_dt_check: false,

                dt_tal_greater_prevCheck: false,
              };
            });
            setQ39TalConditionMet(Q39TalConditionMet);
            // setQ36TalConditionMet(Q36TalConditionMet);

            setTalveyDetails(talveyData);
          } else {
            const cohortTreatmentid = isTALSelected
              ? GetLocalStorageData("talveyID")
              : 0;

            // Update TechvayliRow with cohorttreatmentid only
            TalveyRow.cohorttreatmentid = cohortTreatmentid || 0;

            // Retain the remaining fields as is
            TalveyRow.teclistamatalquetamabdosagebridgeid =
              TalveyRow.teclistamatalquetamabdosagebridgeid || 0;
            TalveyRow.dose_administration_tal =
              TalveyRow.dose_administration_tal || null;
            TalveyRow.dosing_phase_tal = TalveyRow.dosing_phase_tal || null;
            TalveyRow.siteofcare_tal = TalveyRow.siteofcare_tal || null;
            //TalveyRow.dt_admission_tal = TalveyRow.dt_admission_tal || null;
            // TalveyRow.dt_discharge_tal = TalveyRow.dt_discharge_tal || null;
            TalveyRow.dose_administration_tal_dt =
              TalveyRow.dose_administration_tal_dt || "";
            TalveyRow.dt_admission_tal_dt = TalveyRow.dt_admission_tal_dt || "";
            TalveyRow.dt_discharge_tal_dt = TalveyRow.dt_discharge_tal_dt || "";
            TalveyRow.dose_administration_talHard =
              TalveyRow.dose_administration_talHard || false;
            TalveyRow.dosing_phase_talHard =
              TalveyRow.dosing_phase_talHard || false;
            TalveyRow.siteofcare_talHard =
              TalveyRow.siteofcare_talHard || false;
            TalveyRow.dt_admission_talHard =
              TalveyRow.dt_admission_talHard || false;
            TalveyRow.dt_discharge_talHard =
              TalveyRow.dt_discharge_talHard || false;

            //  //Q38
            //  TalveyRow.Q38T = TalveyRow.Q38TalveyRd || null;
            //  TalveyRow.dt_dosefreq_tal_dt = TalveyRow.dt_dosefreq_tal_dt || "";
          }
          //36.1 Tal start
          if (data.talveyHospitalData && data.talveyHospitalData.length > 0) {
            const talHospData = data.talveyHospitalData.map((item) => {
              return {
                ...HospitalizationRow,
                teclistamatalquetamabhospitalizationbridgeid:
                  item.teclistamatalquetamabhospitalizationbridgeid,
                cohorttreatmentid: item.cohorttreatmentid,
                //B
                dt_admission: item.hospitalizationAdmissionDateUnk
                  ? 99
                  : item.noHospitalizationDosage
                  ? 2
                  : item.hospitalizationAdmissionDate
                  ? 1
                  : null,
                dt_admission_dt: item.hospitalizationAdmissionDate,
                //C
                dt_discharge: item.hospitalizationDischargeUnk
                  ? 99
                  : item.patientStillHospitalized
                  ? 2
                  : item.hospitalizationDischargeDate
                  ? 1
                  : null,
                dt_discharge_dt: item.hospitalizationDischargeDate,
                //D
                hospReason1:
                  item.hospitalizationReasonAdministrationTeclistamab,
                hospReason2: item.hospitalizationReasonMonitorAe,
                hospReason3: item.hospitalizationReasonCrs,
                hospReason4: item.hospitalizationReasonIcans,
                hospReason5: item.hospitalizationReasonOtherae,
                hospReason6: item.hospitalizationReasonOtnernonae,
                hosp_reason_oth_txt: item.hospitalizationReasonOthernonaeTxt,
                hospReasonUnk: item.hospitalizationReasonUnknown,
              };
            });

            setTalHospDetails(talHospData);
          } else {
            const cohorttreatmentid = isTALSelected
              ? GetLocalStorageData("talveyID")
              : 0;
            setTalHospDetails([
              {
                ...HospitalizationRow,
                cohorttreatmentid,
              },
            ]);
          }
          //end

          //37 LoadData -Techvayli

          if (
            data.tTcharTechvayliModelList &&
            data.tTcharTechvayliModelList.length > 0
          ) {
            const Q37TechDetails = data.tTcharTechvayliModelList.map((item) => {
              const cohortTreatment =
                cohortTreatmentMap.get(item.cohorttreatmentid) || {};

              return {
                // Q39TalConditionMet: item.ivigDosageFrequency === 1 || item.ivigDosageFrequency === 2 || item.ivigDosageFrequency === 3 ,
                cohorttreatmentid: item.cohorttreatmentid,
                // cohortTreatmentName: cohortTreatment.cohortTreatmentName || null,
                // cohortIndexDate: cohortTreatment.cohortIndexDate || null,

                teclistamabcharacteristicsid: item.teclistamabcharacteristicsid,

                Q37TechvayliRd:
                  item.dosefrequencyreduced === 1
                    ? 1
                    : item.dosefrequencyreduced === 0
                    ? 0
                    : null,

                q37_Tec_Dose_week_frquency_txt:
                  item.treatmentInitialDoseFrequencyText,
                q37_Tec_Dose_week_frquencyRd:
                  item.treatmentInitialDoseFrequency,

                showDateRedFreqTec: item.dosefrequencyreduced === 1 ? true : false,

                Q40dosingfreqRd: item.ivigDosageFrequency,
                dosing_freq_oth_txt: item.ivigDosageFrequencyOthTxt,

                Q40IgILevelRd: item.lastIgglevel,

                Q40init_dt: item.ivigInitiationDate,
                Q40init_dt_Rd:
                  item.ivigInitiationDateUnk === true
                    ? 99
                    : item.ivigInitiationDate
                    ? 1
                    : null,
                primaryProf: item.medicationReasonPrimaryProphylactic,
                secondaryProf: item.medicationReasonSecondaryProphylactic,
                otherReson: item.medicationReasonOth,

                //39

                tocilizumab_tech: item.medicationTocilizumab,
                Granulocyte: item.medicationGcsf,
                noneoftheabove_tech: item.medicationNone === true ? 99 : null,
                SteroidsTech: item.medicationSteroids,

                //39 - 1.1
                cytokin_tech: item.dosageusageReasonProphylacticCrs,
                crs_tech: item.dosagusageReasonTreatmentsupportiveCrs,
                neurotoxicity_tech:
                  item.dosagusageReasonProphylacticneurotoxicity,
                tmt_neurotoxicity_tech:
                  item.dosagusageReasonTreatmentsupportiveNeurotoxicity,
                tmt_tech_unknown: item.dosagusageReasonTreatmentUnknown,
                //39 - 1.3
                prophylactic_use_tech: item.gcsfUsageReasonProphylactic,
                oth_use_tech: item.gcsfUsageReasonOth,
                unknown_tech: item.gcsfUsageReasonUnk === true ? 99 : null,

                // Steroids Usage Reasons
                steroid_pre_tmt_tech:
                  item.steroidUsageReasonTreatmentRecommendation,

                cytokin_tech_steroids: item.steroidsUsageReasonProphylacticCrs,
                crs_tech_steroids:
                  item.steroidsUsageReasonTreatmentsupportiveCrs,
                neurotoxicity_tech_steroids:
                  item.steroidsUsageReasonProphylacticneurotoxicity,
                tmt_neurotoxicity_tech_steroids:
                  item.steroidsUsageReasonTreatmentsupportiveNeurotoxicity,
                tmt_steroids_tech_unknown: item.steroidUsageReasonUnknown,

                betamethasone_tech: item.steroidNameBetamethasone,
                betamethasone_techNum: item.steroidDoseBetamethasone,

                dexamethasone_tech: item.steroidNameDexamethasone,
                dexamethasone_techNum: item.steroidDoseDexamethasone,

                hydrocortisone_tech: item.steroidNameHydrocortisone,
                hydrocortisone_techNum: item.steroidDoseHydrocortisone,

                methylprednisolone: item.steroidNameMethylprednisolone,
                methylprednisoloneNum: item.steroidDoseMethylprednisolone,

                prednisolone_tech: item.steroidNamePrednisolone,
                prednisolone_techNum: item.steroidDosePrednisolone,

                prednisone_tech: item.steroidNamePrednisone,
                prednisone_techNum: item.steroidDosePrednisone,

                triamcinolone_tech: item.steroidNameTriamcinolone,
                triamcinolone_techNum: item.steroidDoseTriamcinolone,

                dose_name_tech_unknown: item.steroidDoseNameUnknown,

                name_dose_steroidsHardEmpty: false,
                // name_dose_steroidsHardEmpty : false,
                // betamethasone_tech_txtEmpty : false,
                // dexamethasone_tech_txtEmpty : false,
                // hydrocortisone_tech_txtEmpty : false,
                // methylprednisolone_txtEmpty : false,
                // prednisolone_tech_txtEmpty : false,
                // prednisone_tech_txtEmpty : false,
                // triamcinolone_tech_txtEmpty : false,

                steroids_tech_txtEmpty: false,
                //ConditionMet

                Q39TechEmptyHard: false,

                q37TechvayliEmpty: false,
                Q40DosingFreqEmptyHard: false,
                Q40DosingFreqTxtEmptyHard: false,
                Q40IgGEmptyHard: false,
                Q40Init_dtHard: false,
                Q40Init_RdHard: false,
                Q40ReasonEmptyHard: false,
                q37_Tec_Dose_week_frquency_txt_Empty: false,
                q37_Tec_Dose_week_frquencyRd_Empty: false,

                Q39techReasonHard: false,
                Q39techGranduloEmptyHard: false,

                Q39techSteroidsEmptyHard: false,
              };
            });

            setQ37TechDetails(Q37TechDetails);
          }

          //37 LoadData -Talvey

          if (
            data.tTcharTalveyliModelList &&
            data.tTcharTalveyliModelList.length > 0
          ) {
            const Q37TalDetails = data.tTcharTalveyliModelList.map((item) => {
              const cohortTreatment =
                cohortTreatmentMap.get(item.cohorttreatmentid) || {};
              return {
                cohorttreatmentid: item.cohorttreatmentid,
                // cohortTreatmentName: cohortTreatment.cohortTreatmentName || null,
                // cohortIndexDate: cohortTreatment.cohortIndexDate || null,

                teclistamabcharacteristicsid: item.teclistamabcharacteristicsid,

                Q37TalveyRd:
                  item.dosefrequencyreduced === 1
                    ? 1
                    : item.dosefrequencyreduced === 0
                    ? 0
                    : null,

                showDateRedFreqTal: item.dosefrequencyreduced === 1? true : false,

                //37 Talvey
                q37_Tal_Dose_week_frquency_txt:
                  item.treatmentInitialDoseFrequencyText,
                q37_Tal_Dose_week_frquencyRd:
                item.treatmentInitialDoseFrequency,
                q37_Tal_Dosage_Rd: item.treatmentInitialDosage,
                q37_Tal_Dosage_OtherTxt: item.treatmentInitialDosageOthtext,

                //39 Talvey data

                // Medications
                tocilizumab_tal: item.medicationTocilizumab,
                Steroids: item.medicationSteroids,
                noneoftheabove_tal: item.medicationNone === true ? 99 : null,

                // Dosage Usage Reasons
                cytokin_tal: item.dosageusageReasonProphylacticCrs,
                crs_tal: item.dosagusageReasonTreatmentsupportiveCrs,
                neurotoxicity_tal:
                  item.dosagusageReasonProphylacticneurotoxicity,
                tmt_neurotoxicity_tal:
                  item.dosagusageReasonTreatmentsupportiveNeurotoxicity,
                tmt_talvey_unknown: item.dosagusageReasonTreatmentUnknown,

                // Steroids Usage Reasons
                cytokin_tal_steroids: item.steroidsUsageReasonProphylacticCrs,
                crs_tal_steroids:
                  item.steroidsUsageReasonTreatmentsupportiveCrs,
                neurotoxicity_tal_steroids:
                  item.steroidsUsageReasonProphylacticneurotoxicity,
                tmt_neurotoxicity_tal_steroids:
                  item.steroidsUsageReasonTreatmentsupportiveNeurotoxicity,
                tmt_tal_unknown: item.steroidUsageReasonUnknown,
                steroid_pre_tmt_tal:
                  item.steroidUsageReasonTreatmentRecommendation,

                betamethasone_tal: item.steroidNameBetamethasone,
                betamethasone_talNum: item.steroidDoseBetamethasone,

                dexamethasone_tal: item.steroidNameDexamethasone,
                dexamethasone_talNum: item.steroidDoseDexamethasone,

                hydrocortisone_tal: item.steroidNameHydrocortisone,
                hydrocortisone_talNum: item.steroidDoseHydrocortisone,

                methylprednisolone_tal: item.steroidNameMethylprednisolone,
                methylprednisolone_talNum: item.steroidDoseMethylprednisolone,

                prednisolone_tal: item.steroidNamePrednisolone,
                prednisolone_talNum: item.steroidDosePrednisolone,

                prednisone_tal: item.steroidNamePrednisone,
                prednisone_talNum: item.steroidDosePrednisone,

                triamcinolone_tal: item.steroidNameTriamcinolone,
                triamcinolone_talNum: item.steroidDoseTriamcinolone,

                dose_name_tal_unknown: item.steroidDoseNameUnknown,

                name_dose_steroids_talHardEmpty: false,
                steroids_tal_txtEmpty: false,

                q37TalveyEmpty: false,
                Q39TalEmptyHard: false,
                Q39talReasonHard: false,
                Q39talSteroidsEmptyHard: false,
                q37_Tal_Dose_week_frquencyRd_Empty: false,
                q37_Tal_Dose_week_frquency_txt_Empty: false,
                q37_Tal_Dosage_Rd_Empty: false,
                q37_Tal_Dosage_OtherTxt_Empty: false,
                // dt_admission_techHard: false,
                // dt_discharge_techHard: false,
              };
            });

            setQ37TalDetails(Q37TalDetails);
          }

          // Q38
          // Process TalveyData
          if (data.q38TalveyData && data.q38TalveyData.length > 0) {
            const Q38TalDetails = data.q38TalveyData.map((item) => {
              const cohortTreatment =
                cohortTreatmentMap.get(item.cohorttreatmentid) || {};

              return {
                cohorttreatmentid: item.cohorttreatmentid,

                teclistamatalquetamabdosefrequencybridgeid:
                  item.teclistamatalquetamabdosefrequencybridgeid,

                Q38TalveyRd: item.dosefreqReductionDate
                  ? 1
                  : item.dosefreqReductionDateUnk === true
                  ? 99
                  : null,
                dt_dosefreq_tal_dt: item.dosefreqReductionDate,

                Q38TalEmptyHard: false,
                Q38TalDateEmpty: false,
                Q38TalDateInvalid: false,

                q38_Tal_Dose_week_frquencyRd_Empty: false,
                q38_Tal_Dose_week_frquency_txt_Empty: false,
                q38_Tal_Dosage_Rd_Empty: false,
                q38_Tal_Dosage_OtherTxt_Empty: false,

                q38_Tal_Dose_week_frquency_txt:
                  item.treatmentInitialDoseFrequencyText,

                q38_Tal_Dose_week_frquencyRd:
                  item.treatmentInitialDoseFrequency ,

                q38_Tal_Dosage_OtherTxt: item.treatmentInitialDosageOthtext,
                q38_Tal_Dosage_Rd: item.treatmentInitialDosage,
              };
            });

            setQ38TalDetails(Q38TalDetails);
          } else {
            const cohortTreatmentid = isTALSelected
              ? GetLocalStorageData("talveyID")
              : 0;

            // Update TechvayliRow with cohorttreatmentid only
            Q38TalRow.cohorttreatmentid = cohortTreatmentid || 0;

            // Retain the remaining fields as is
            Q38TalRow.teclistamatalquetamabdosefrequencybridgeid =
              Q38TalRow.teclistamatalquetamabdosefrequencybridgeid || 0;

            Q38TalRow.Q38TalveyRd = Q38TalRow.Q38TalveyRd || null;
            Q38TalRow.dt_dosefreq_tal_dt = Q38TalRow.dt_dosefreq_tal_dt || "";
          }

          //38
          // Process Techvaley Data
          if (data.q38TechvayliData && data.q38TechvayliData.length > 0) {
            const Q38TechDetails = data.q38TechvayliData.map((item) => {
              const cohortTreatment =
                cohortTreatmentMap.get(item.cohorttreatmentid) || {};

              return {
                cohorttreatmentid: item.cohorttreatmentid,

                teclistamatalquetamabdosagebridgeid:
                  item.teclistamatalquetamabdosagebridgeid,

                Q38TechvayliRd: item.dosefreqReductionDate
                  ? 1
                  : item.dosefreqReductionDateUnk === true
                  ? 99
                  : null,
                dt_dosefreq_tech_dt: item.dosefreqReductionDate,

                Q38TechEmptyHard: false,
                Q38DateEmpty: false,
                Q38TechDateInvalid: false,

                q38_Tec_Dose_week_frquencyRd_Empty: false,
                q38_Tec_Dose_week_frquency_txt_Empty: false,

                q38_Tec_Dose_week_frquency_txt:
                  item.treatmentInitialDoseFrequencyText,
                q38_Tec_Dose_week_frquencyRd:
                  item.treatmentInitialDoseFrequency,
              };
            });

            setQ38TechDetails(Q38TechDetails);
          } else {
            const cohortTreatmentid = isTACSelected
              ? GetLocalStorageData("techvayliID")
              : 0;

            // Update TechvayliRow with cohorttreatmentid only
            Q38TechRow.cohorttreatmentid = cohortTreatmentid || 0;

            // Retain the remaining fields as is
            Q38TechRow.teclistamatalquetamabdosefrequencybridgeid =
              Q38TechRow.teclistamatalquetamabdosefrequencybridgeid || 0;

            Q38TechRow.Q38TechvayliRd = Q38TechRow.Q38TechvayliRd || null;
            Q38TechRow.dt_dosefreq_tech_dt =
              Q38TechRow.dt_dosefreq_tech_dt || "";
          }
        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  };

  useEffect(() => {
    async function FetchData() {
      if (!isNaN(pid) && Number(pid) !== 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);

  const HandleRepeaterFieldChange = (e) => {
    const { name, id, value } = e.target;
    const value_int = Number(value);
    const index = Number(id);

    switch (name) {
      // Techvayli

      case "dose_administration_tech" + id: {
        let updatedArray = [...techvayliDetails];

        updatedArray[id].dose_administration_tech = value_int;
        updatedArray[id].dose_administration_tech_dt = "";

        updatedArray[id].dose_administration_techHard = false;

        updatedArray[id].dose_administration_techHard_dt = false;

        updatedArray[id].dt_tech_greater_prevCheck = false;
        // if(updatedArray[id].dose_administration_tech===99){
        // }
        setIndexDateRRMM_techHard(false);
        setTechvayliDetails(updatedArray);
        break;
      }

      case "dosing_phase_tech" + id: {
        let updatedArray = [...techvayliDetails];
        let UpdateArrayCondition = [...Q37TechDetails];

        updatedArray[id].dosing_phase_tech = value_int;

        updatedArray[id].dosing_phase_techHard = false;

        // if (value_int === 4 || value_int === 5) {
        //   // updatedArray[id].dt_admission_tech = null;
        //  // updatedArray[id].dt_discharge_tech = null;
        //   updatedArray[id].dt_admission_tech_dt = null;
        //   updatedArray[id].dt_discharge_tech_dt = null;

        //   UpdateArrayCondition[id].tocilizumab_tech = false;
        //   UpdateArrayCondition[id].Granulocyte = false;
        //   UpdateArrayCondition[id].noneoftheabove_tech = false;

        //   UpdateArrayCondition[id].Q39TechEmptyHard = false;

        //   updatedArray[id].dt_discharge_techHard = false;
        //   updatedArray[id].dt_discharge_techHard_dt = false;
        //   updatedArray[id].dt_discharge_techHard_dt_check = false;
        //   updatedArray[id].dt_admission_techHard = false;
        //   updatedArray[id].dt_admission_techHard_dt = false;
        //   updatedArray[id].dt_admission_techHard_dt_check = false;
        // }

        if (
          (value_int === 1 || value_int === 2 || value_int === 3) &&
          (updatedArray[id].siteofcare_tech === 1 ||
            updatedArray[id].siteofcare_tech === 3)
        ) {
          updatedArray[id].Q36TechConditionMet = true;
        } else {
          updatedArray[id].Q36TechConditionMet = false;
        }

        setTechvayliDetails(updatedArray);
        setQ37TechDetails(UpdateArrayCondition);
        break;
      }

      case "siteofcare_tech" + id: {
        let updatedArray = [...techvayliDetails];

        updatedArray[id].siteofcare_tech = value_int;

        updatedArray[id].siteofcare_techHard = false;

        // if (
        //   (value_int === 1 || value_int === 3) &&
        //   (updatedArray[id].dosing_phase_tech === 1 ||
        //     updatedArray[id].dosing_phase_tech === 2 ||
        //     updatedArray[id].dosing_phase_tech === 3)
        // ) {
        //   updatedArray[id].Q36TechConditionMet = true;
        // } else {
        //   updatedArray[id].Q36TechConditionMet = false;
        // }

        if (value_int === 2) {
          // updatedArray[id].dt_admission_tech = null;
          // updatedArray[id].dt_discharge_tech = null;
          updatedArray[id].dt_admission_tech_dt = null;
          updatedArray[id].dt_discharge_tech_dt = null;

          updatedArray[id].dt_discharge_techHard = false;
          updatedArray[id].dt_discharge_techHard_dt = false;
          updatedArray[id].dt_discharge_techHard_dt_check = false;
          updatedArray[id].dt_admission_techHard = false;
          updatedArray[id].dt_admission_techHard_dt = false;
          updatedArray[id].dt_admission_techHard_dt_check = false;
        }

        setTechvayliDetails(updatedArray);
        break;
      }

      // case "dt_admission_tech" + id: {
      //   let updatedArray = [...techvayliDetails];

      //   updatedArray[id].dt_admission_tech = value_int;
      //   updatedArray[id].dt_admission_tech_dt = "";

      //   updatedArray[id].dt_admission_techHard = false;

      //   updatedArray[id].dt_admission_techHard_dt = false;
      //   // if(updatedArray[id].dt_admission_tech===99 ){
      //   // }
      //   setTechvayliDetails(updatedArray);

      //   break;
      // }

      // case "dt_discharge_tech" + id: {
      //   let updatedArray = [...techvayliDetails];

      //   updatedArray[id].dt_discharge_tech = value_int;
      //   updatedArray[id].dt_discharge_tech_dt = "";

      //   updatedArray[id].dt_discharge_techHard = false;

      //   updatedArray[id].dt_discharge_techHard_dt = false;

      //   updatedArray[id].dt_discharge_techHard_dt_check = false;
      //   // if(updatedArray[id].dt_discharge_tech===99 || updatedArray[id].dt_discharge_tech===3){
      //   // }
      //   setTechvayliDetails(updatedArray);
      //   break;
      // }
      //36.1 start
      //B
      case "dt_admission_tec" + id: {
        let updatedArray = [...tecHospDetails];

        updatedArray[id].dt_admission = value_int;
        updatedArray[id].dt_admission_dt = "";

        updatedArray[id].dt_admission_Hard = false;
        updatedArray[id].dt_admission_Hard_dt = false;
        // updatedArray[id].dt_admission_Hard_dt_check = false;

        //Delete rows if "Patient had no hospitalizations during the step-up dosing phase" is selected
        //and Reset columns C n D of current row
        if (value_int === 2) {
          updatedArray[id].dt_discharge = null;
          updatedArray[id].dt_discharge_dt = "";
          updatedArray[id].dt_discharge_Hard = false;
          updatedArray[id].dt_discharge_Hard_dt = false;
          updatedArray[id].dt_discharge_Hard_dt_check = false;

          updatedArray[id].hospReason1 = false;
          updatedArray[id].hospReason2 = false;
          updatedArray[id].hospReason3 = false;
          updatedArray[id].hospReason4 = false;
          updatedArray[id].hospReason5 = false;
          updatedArray[id].hospReason6 = false;
          updatedArray[id].hosp_reason_oth_txt = "";
          updatedArray[id].hospReasonUnk = false;
          updatedArray[id].hospReasonEmptyHard = false;
          updatedArray[id].hospReasonTxtEmpty = false;

          const deleteStartIndex = Number(id) + 1;
          if (deleteStartIndex >= 0 && deleteStartIndex < updatedArray.length) {
            updatedArray.splice(
              deleteStartIndex,
              updatedArray.length - deleteStartIndex
            );
          }
        }
        setTecHospDetails(updatedArray);
        break;
      }
      //C
      case "dt_discharge_tec" + id: {
        let updatedArray = [...tecHospDetails];

        updatedArray[id].dt_discharge = value_int;
        updatedArray[id].dt_discharge_dt = "";

        updatedArray[id].dt_discharge_Hard = false;
        updatedArray[id].dt_discharge_Hard_dt = false;
        updatedArray[id].dt_discharge_Hard_dt_check = false;

        setTecHospDetails(updatedArray);
        break;
      }
      //D
      case "hospReason1_tec" + id:
      case "hospReason2_tec" + id:
      case "hospReason3_tec" + id:
      case "hospReason4_tec" + id:
      case "hospReason5_tec" + id: {
        let updatedArray = [...tecHospDetails];

        const ctrlId = name.split("_")[0];

        updatedArray[id][ctrlId] = !updatedArray[id][ctrlId];
        updatedArray[id].hosp_reason_oth_txt = "";
        updatedArray[id].hospReasonUnk = false;

        updatedArray[id].hospReasonEmptyHard = false;
        updatedArray[id].hospReasonTxtEmpty = false;
        setTecHospDetails(updatedArray);
        break;
      }
      case "hospReason6_tec" + id: {
        let updatedArray = [...tecHospDetails];

        updatedArray[id].hospReason6 = !updatedArray[id].hospReason6;
        updatedArray[id].hosp_reason_oth_txt = "";
        updatedArray[id].hospReasonUnk = false;

        updatedArray[id].hospReasonEmptyHard = false;
        updatedArray[id].hospReasonTxtEmpty = false;
        setTecHospDetails(updatedArray);
        break;
      }
      case "hosp_reason_oth_txt_tec" + id: {
        let updatedArray = [...tecHospDetails];

        updatedArray[id].hosp_reason_oth_txt = value;
        updatedArray[id].hospReason6 = true;
        updatedArray[id].hospReasonUnk = false;

        updatedArray[id].hospReasonEmptyHard = false;
        updatedArray[id].hospReasonTxtEmpty = false;
        setTecHospDetails(updatedArray);
        break;
      }
      case "hospReasonUnk_tec" + id: {
        let updatedArray = [...tecHospDetails];

        updatedArray[id].hospReasonUnk = true;
        updatedArray[id].hospReason1 = false;
        updatedArray[id].hospReason2 = false;
        updatedArray[id].hospReason3 = false;
        updatedArray[id].hospReason4 = false;
        updatedArray[id].hospReason5 = false;
        updatedArray[id].hospReason6 = false;
        updatedArray[id].hosp_reason_oth_txt = "";

        updatedArray[id].hospReasonEmptyHard = false;
        updatedArray[id].hospReasonTxtEmpty = false;
        setTecHospDetails(updatedArray);
        break;
      }
      //end

      //TALVEY
      case "dose_administration_tal" + id: {
        let updatedArray = [...talveyDetails];

        updatedArray[id].dose_administration_tal = value_int;
        updatedArray[id].dose_administration_tal_dt = "";

        updatedArray[id].dose_administration_talHard = false;

        updatedArray[id].dose_administration_talHard_dt = false;

        updatedArray[id].dt_tal_greater_prevCheck = false;

        setIndexDateRRMM_talHard(false);
        setTalveyDetails(updatedArray);

        break;
      }

      case "dosing_phase_tal" + id: {
        let updatedArray = [...talveyDetails];
        let UpdateArrayCondition = [...Q37TalDetails];

        updatedArray[id].dosing_phase_tal = value_int;

        updatedArray[id].dosing_phase_talHard = false;

        if (value_int === 5 || value_int === 6) {
          //updatedArray[id].dt_admission_tal = null;
          // updatedArray[id].dt_discharge_tal = null;

          updatedArray[id].dt_admission_tal_dt = null;
          updatedArray[id].dt_discharge_tal_dt = null;

          UpdateArrayCondition[id].tocilizumab_tal = false;
          UpdateArrayCondition[id].Steroids = false;
          UpdateArrayCondition[id].noneoftheabove_tal = false;

          UpdateArrayCondition[id].Q39TalEmptyHard = false;

          updatedArray[id].dt_discharge_talHard = false;
          updatedArray[id].dt_discharge_talHard_dt = false;
          updatedArray[id].dt_discharge_talHard_dt_check = false;
          updatedArray[id].dt_admission_talHard = false;
          updatedArray[id].dt_admission_talHard_dt = false;
          updatedArray[id].dt_admission_talHard_dt_check = false;
        }

        // if (
        //   (value_int == 1 ||
        //     value_int === 2 ||
        //     value_int === 3 ||
        //     value_int === 4) &&
        //   (updatedArray[id].siteofcare_tal === 1 ||
        //     updatedArray[id].siteofcare_tal === 3)
        // ) {
        //   updatedArray[id].Q36TalConditionMet = true;
        // } else {
        //   updatedArray[id].Q36TalConditionMet = false;
        // }

        setTalveyDetails(updatedArray);
        setQ37TalDetails(UpdateArrayCondition);
        break;
      }

      case "siteofcare_tal" + id: {
        let updatedArray = [...talveyDetails];

        updatedArray[id].siteofcare_tal = value_int;

        updatedArray[id].siteofcare_talHard = false;

        // if (
        //   (value_int == 1 || value_int === 3) &&
        //   (updatedArray[id].dosing_phase_tal === 1 ||
        //     updatedArray[id].dosing_phase_tal === 2 ||
        //     updatedArray[id].dosing_phase_tal === 3 ||
        //     updatedArray[id].dosing_phase_tal === 4)
        // ) {
        //   updatedArray[id].Q36TalConditionMet = true;
        // } else {
        //   updatedArray[id].Q36TalConditionMet = false;
        // }

        if (value_int === 2) {
          //updatedArray[id].dt_admission_tal = null;
          //updatedArray[id].dt_discharge_tal = null;
          updatedArray[id].dt_admission_tal_dt = null;
          updatedArray[id].dt_discharge_tal_dt = null;

          updatedArray[id].dt_discharge_talHard = false;
          updatedArray[id].dt_discharge_talHard_dt = false;
          updatedArray[id].dt_discharge_talHard_dt_check = false;
          updatedArray[id].dt_admission_talHard = false;
          updatedArray[id].dt_admission_talHard_dt = false;
          updatedArray[id].dt_admission_talHard_dt_check = false;
        }

        setTalveyDetails(updatedArray);
        break;
      }

      // case "dt_admission_tal" + id: {
      //   let updatedArray = [...talveyDetails];

      //   updatedArray[id].dt_admission_tal = value_int;
      //   updatedArray[id].dt_admission_tal_dt = "";

      //   updatedArray[id].dt_admission_talHard = false;

      //   if (updatedArray[id].dt_admission_tal === 99) {
      //     updatedArray[id].dt_admission_talHard_dt = false;
      //   }
      //   setTalveyDetails(updatedArray);

      //   break;
      // }

      // case "dt_discharge_tal" + id: {
      //   let updatedArray = [...talveyDetails];

      //   updatedArray[id].dt_discharge_tal = value_int;
      //   updatedArray[id].dt_discharge_tal_dt = "";

      //   updatedArray[id].dt_discharge_talHard = false;
      //   updatedArray[id].dt_discharge_talHard_dt_check = false;

      //   if (
      //     updatedArray[id].dt_discharge_tal === 99 ||
      //     updatedArray[id].dt_discharge_tal === 3
      //   ) {
      //     updatedArray[id].dt_discharge_talHard_dt = false;
      //   }

      //   setTalveyDetails(updatedArray);
      //   break;
      // }
      //36.1 start
      //B
      case "dt_admission_tal" + id: {
        let updatedArray = [...talHospDetails];

        updatedArray[id].dt_admission = value_int;
        updatedArray[id].dt_admission_dt = "";

        updatedArray[id].dt_admission_Hard = false;
        updatedArray[id].dt_admission_Hard_dt = false;
        // updatedArray[id].dt_admission_Hard_dt_check = false;

        //Delete rows if "Patient had no hospitalizations during the step-up dosing phase" is selected
        //and Reset columns C n D of current row
        if (value_int === 2) {
          updatedArray[id].dt_discharge = null;
          updatedArray[id].dt_discharge_dt = "";
          updatedArray[id].dt_discharge_Hard = false;
          updatedArray[id].dt_discharge_Hard_dt = false;
          updatedArray[id].dt_discharge_Hard_dt_check = false;

          updatedArray[id].hospReason1 = false;
          updatedArray[id].hospReason2 = false;
          updatedArray[id].hospReason3 = false;
          updatedArray[id].hospReason4 = false;
          updatedArray[id].hospReason5 = false;
          updatedArray[id].hospReason6 = false;
          updatedArray[id].hosp_reason_oth_txt = "";
          updatedArray[id].hospReasonUnk = false;
          updatedArray[id].hospReasonEmptyHard = false;
          updatedArray[id].hospReasonTxtEmpty = false;

          const deleteStartIndex = Number(id) + 1;
          if (deleteStartIndex >= 0 && deleteStartIndex < updatedArray.length) {
            updatedArray.splice(
              deleteStartIndex,
              updatedArray.length - deleteStartIndex
            );
          }
        }
        setTalHospDetails(updatedArray);
        break;
      }
      //C
      case "dt_discharge_tal" + id: {
        let updatedArray = [...talHospDetails];

        updatedArray[id].dt_discharge = value_int;
        updatedArray[id].dt_discharge_dt = "";

        updatedArray[id].dt_discharge_Hard = false;
        updatedArray[id].dt_discharge_Hard_dt = false;
        updatedArray[id].dt_discharge_Hard_dt_check = false;

        setTalHospDetails(updatedArray);
        break;
      }
      case "hospReason1_tal" + id:
      case "hospReason2_tal" + id:
      case "hospReason3_tal" + id:
      case "hospReason4_tal" + id:
      case "hospReason5_tal" + id: {
        let updatedArray = [...talHospDetails];

        const ctrlId = name.split("_")[0];

        updatedArray[id][ctrlId] = !updatedArray[id][ctrlId];
        updatedArray[id].hosp_reason_oth_txt = "";
        updatedArray[id].hospReasonUnk = false;

        updatedArray[id].hospReasonEmptyHard = false;
        updatedArray[id].hospReasonTxtEmpty = false;
        setTalHospDetails(updatedArray);
        break;
      }
      case "hospReason6_tal" + id: {
        let updatedArray = [...talHospDetails];

        updatedArray[id].hospReason6 = !updatedArray[id].hospReason6;
        updatedArray[id].hosp_reason_oth_txt = "";
        updatedArray[id].hospReasonUnk = false;

        updatedArray[id].hospReasonEmptyHard = false;
        updatedArray[id].hospReasonTxtEmpty = false;
        setTalHospDetails(updatedArray);
        break;
      }
      case "hosp_reason_oth_txt_tal" + id: {
        let updatedArray = [...talHospDetails];

        updatedArray[id].hosp_reason_oth_txt = value;
        updatedArray[id].hospReason6 = true;
        updatedArray[id].hospReasonUnk = false;

        updatedArray[id].hospReasonEmptyHard = false;
        updatedArray[id].hospReasonTxtEmpty = false;
        setTalHospDetails(updatedArray);
        break;
      }
      case "hospReasonUnk_tal" + id: {
        let updatedArray = [...talHospDetails];

        updatedArray[id].hospReasonUnk = true;
        updatedArray[id].hospReason1 = false;
        updatedArray[id].hospReason2 = false;
        updatedArray[id].hospReason3 = false;
        updatedArray[id].hospReason4 = false;
        updatedArray[id].hospReason5 = false;
        updatedArray[id].hospReason6 = false;
        updatedArray[id].hosp_reason_oth_txt = "";

        updatedArray[id].hospReasonEmptyHard = false;
        updatedArray[id].hospReasonTxtEmpty = false;
        setTalHospDetails(updatedArray);
        break;
      }
      //end
      //Q38 Tech radio
      case "Q38TechvayliRd" + id: {
        let updatedArray = [...Q38TechDetails];

        updatedArray[id].Q38TechvayliRd = value_int;
        updatedArray[id].dt_dosefreq_tech_dt = "";

        updatedArray[id].Q38TechEmptyHard = false;
        updatedArray[id].Q38DateEmpty = false;
        updatedArray[id].Q38TechDateInvalid = false;
        setQ38TechDetails(updatedArray);
        break;
      }

      //Q38Talvey
      case "Q38TalveyRd" + id: {
        let updatedArray = [...Q38TalDetails];

        updatedArray[id].Q38TalveyRd = value_int;
        updatedArray[id].dt_dosefreq_tal_dt = "";

        // updatedArray[id].dt_discharge_talHard = false;
        updatedArray[id].Q38TalEmptyHard = false;
        updatedArray[id].Q38TalDateEmpty = false;
        updatedArray[id].Q38TalDateInvalid = false;
        setQ38TalDetails(updatedArray);
        break;
      }

      //Q38 Tech for frequency dosage
      case "q38_Tec_Dose_week_frquencyRd" + id: {
        let updatedArray = [...Q38TechDetails];

        updatedArray[id].q38_Tec_Dose_week_frquencyRd = Number(value);
        updatedArray[id].q38_Tec_Dose_week_frquencyRd_Empty = false;
        updatedArray[id].q38_Tec_Dose_week_frquency_txt_Empty = false;
        setQ38TechDetails(updatedArray);
        break;
      }

      case "q38_Tec_Dose_week_frquency_txt" + id: {
        let updatedArray = [...Q38TechDetails];

        const reqValue = value;
        updatedArray[id].q38_Tec_Dose_week_frquency_txt = reqValue;
        updatedArray[id].q38_Tec_Dose_week_frquencyRd = 1;
        updatedArray[id].q38_Tec_Dose_week_frquencyRd_Empty = false;
        updatedArray[id].q38_Tec_Dose_week_frquency_txt_Empty = false;

        setQ38TechDetails(updatedArray);
        break;
      }

      case "q38_Tal_Dose_week_frquencyRd" + id: {
        let updatedArray = [...Q38TalDetails];

        updatedArray[id].q38_Tal_Dose_week_frquencyRd = Number(value);
        updatedArray[id].q38_Tal_Dose_week_frquencyRd_Empty = false;
        updatedArray[id].q38_Tal_Dose_week_frquency_txt_Empty = false;
        setQ38TalDetails(updatedArray);
        break;
      }

      case "q38_Tal_Dose_week_frquency_txt" + id: {
        let updatedArray = [...Q38TalDetails];

        const reqValue = value;
        updatedArray[id].q38_Tal_Dose_week_frquency_txt = reqValue;
        updatedArray[id].q38_Tal_Dose_week_frquencyRd = 1;
        updatedArray[id].q38_Tal_Dose_week_frquencyRd_Empty = false;
        updatedArray[id].q38_Tal_Dose_week_frquency_txt_Empty = false;

        setQ38TalDetails(updatedArray);
        break;
      }

      case "q38_Tal_Dosage_Rd" + id: {
        let updatedArray = [...Q38TalDetails];

        updatedArray[id].q38_Tal_Dosage_Rd = Number(value);
        updatedArray[id].q38_Tal_Dosage_OtherTxt = "";
        updatedArray[id].q38_Tal_Dosage_Rd_Empty = false;
        updatedArray[id].q38_Tal_Dosage_OtherTxt_Empty = false;

        setQ38TalDetails(updatedArray);
        break;
      }

      case "q38_Tal_Dosage_OtherTxt" + id: {
        let updatedArray = [...Q38TalDetails];

        const reqValue = value;
        updatedArray[id].q38_Tal_Dosage_Rd = 3;
        updatedArray[id].q38_Tal_Dosage_OtherTxt = reqValue;
        updatedArray[id].q38_Tal_Dosage_Rd_Empty = false;
        updatedArray[id].q38_Tal_Dosage_OtherTxt_Empty = false;

        setQ38TalDetails(updatedArray);
        break;
      }

      default:
        break;
    }

    // case

    //
    let newCountTech = null;
    techvayliDetails.filter((item) => {
      if (
        item.dosing_phase_tech === 1 ||
        item.dosing_phase_tech === 2 ||
        item.dosing_phase_tech === 3
      ) {
        // newCountTech = newCountTech + 1;
        //  setQ39TechConditionMet(true);
        newCountTech = 1;
      }

      if (newCountTech > 0) {
        setQ39TechConditionMet(true);
      } else {
        setQ39TechConditionMet(false);
      }
    });

    //Talvey
    let newCountTal = null;
    talveyDetails.filter((item) => {
      if (
        item.dosing_phase_tal === 1 ||
        item.dosing_phase_tal === 2 ||
        item.dosing_phase_tal === 3 ||
        item.dosing_phase_tal === 4
      ) {
        newCountTal = 1;
      }

      if (newCountTal > 0) {
        setQ39TalConditionMet(true);
      } else {
        setQ39TalConditionMet(false);
      }
    });
  };

  // const HandleRepeaterDateChange = (name,date) =>{

  // }
  const HandleRepeaterDateChange = (name, date) => {
    const lastIndex = name.lastIndexOf("-");
    const id = name.slice(lastIndex + 1);

    switch (name) {
      //TECHVAYLI
      case "dose_administration_tech_dt-" + id: {
        let updatedArray = [...techvayliDetails];

        updatedArray[id].dose_administration_tech_dt = date;
        updatedArray[id].dose_administration_tech = 1;
        updatedArray[id].dose_administration_techHard = false;

        updatedArray[id].dose_administration_techHard_dt = false;
        updatedArray[id].dt_tech_greater_prevCheck = false;
        setIndexDateRRMM_techHard(false);
        setTechvayliDetails(updatedArray);
        break;
      }

      //case "dt_admission_tech_dt-" + id: {
      //  let updatedArray = [...techvayliDetails];
      //  updatedArray[id].dt_admission_tech_dt = date;
      //  updatedArray[id].dt_admission_tech = 1;
      //  updatedArray[id].dt_admission_techHard = false;
      //  updatedArray[id].dt_admission_techHard_dt = false;
      //  updatedArray[id].dt_admission_techHard_dt_check = false;
      //  setTechvayliDetails(updatedArray);
      //  break;
      //}

      //case "dt_discharge_tech_dt-" + id: {
      //  let updatedArray = [...techvayliDetails];
      //  updatedArray[id].dt_discharge_tech_dt = date;
      //  //updatedArray[id].dt_discharge_tech = 1;
      //  updatedArray[id].dt_discharge_techHard = false;
      //  updatedArray[id].dt_discharge_techHard_dt = false;
      //  updatedArray[id].dt_discharge_techHard_dt_check = false;
      //  setTechvayliDetails(updatedArray);
      //  break;
      //}
      //36.1 start
      //B
      case "dt_admission_dt_tec-" + id: {
        let updatedArray = [...tecHospDetails];
        updatedArray[id].dt_admission_dt = date;
        updatedArray[id].dt_admission = 1;
        updatedArray[id].dt_admission_Hard = false;
        updatedArray[id].dt_admission_Hard_dt = false;
        // updatedArray[id].dt_admission_Hard_dt_check = false;
        setTecHospDetails(updatedArray);
        break;
      }
      //C
      case "dt_discharge_dt_tec-" + id: {
        let updatedArray = [...tecHospDetails];
        updatedArray[id].dt_discharge_dt = date;
        updatedArray[id].dt_discharge = 1;
        updatedArray[id].dt_discharge_Hard = false;
        updatedArray[id].dt_discharge_Hard_dt = false;
        updatedArray[id].dt_discharge_Hard_dt_check = false;
        setTecHospDetails(updatedArray);
        break;
      }
      //end
      //TALVEY
      case "dose_administration_tal_dt-" + id: {
        let updatedArray = [...talveyDetails];

        updatedArray[id].dose_administration_tal_dt = date;
        updatedArray[id].dose_administration_tal = 1;
        updatedArray[id].dose_administration_talHard = false;

        // updatedArray[id].dt_admission_talHard_dt = false;
        // updatedArray[id].dt_admission_talHard_dt_check = false;

        updatedArray[id].dt_tal_greater_prevCheck = false;

        updatedArray[id].dt_admission_talHard_dt = false;
        setIndexDateRRMM_talHard(false);

        setTalveyDetails(updatedArray);
        break;
      }

      //case "dt_admission_tal_dt-" + id: {
      //  let updatedArray = [...talveyDetails];
      //  updatedArray[id].dt_admission_tal_dt = date;
      //  //updatedArray[id].dt_admission_tal = 1;
      //  updatedArray[id].dt_admission_talHard = false;
      //  updatedArray[id].dt_admission_talHard_dt = false;
      //  updatedArray[id].dt_admission_talHard_dt_check = false;
      //  setTalveyDetails(updatedArray);
      //  break;
      //}
      //case "dt_discharge_tal_dt-" + id: {
      //        let updatedArray = [...talveyDetails];
      //        updatedArray[id].dt_discharge_tal_dt = date;
      //       // updatedArray[id].dt_discharge_tal = 1;
      //        updatedArray[id].dt_discharge_talHard = false;
      //        updatedArray[id].dt_discharge_talHard_dt = false;
      //        updatedArray[id].dt_discharge_talHard_dt_check = false;
      //        setTalveyDetails(updatedArray);
      //        break;
      //      }
      //36.1 start
      //B
      case "dt_admission_dt_tal-" + id: {
        let updatedArray = [...talHospDetails];

        updatedArray[id].dt_admission_dt = date;
        updatedArray[id].dt_admission = 1;
        updatedArray[id].dt_admission_Hard = false;

        updatedArray[id].dt_admission_Hard_dt = false;
        // updatedArray[id].dt_admission_Hard_dt_check = false;

        setTalHospDetails(updatedArray);
        break;
      }
      //C
      case "dt_discharge_dt_tal-" + id: {
        let updatedArray = [...talHospDetails];

        updatedArray[id].dt_discharge_dt = date;
        updatedArray[id].dt_discharge = 1;

        updatedArray[id].dt_discharge_Hard = false;
        updatedArray[id].dt_discharge_Hard_dt = false;
        updatedArray[id].dt_discharge_Hard_dt_check = false;
        setTalHospDetails(updatedArray);
        break;
      }
      //end

      //Q38 Tech date change
      case "dt_dosefreq_tech_dt-" + id: {
        let updatedArray = [...Q38TechDetails];

        updatedArray[id].dt_dosefreq_tech_dt = date;
        updatedArray[id].Q38TechvayliRd = 1;

        updatedArray[id].Q38TechEmptyHard = false;
        updatedArray[id].Q38DateEmpty = false;
        updatedArray[id].Q38TechDateInvalid = false;
        setQ38TechDetails(updatedArray);
        break;
      }

      //Q38 Talvey date change
      case "dt_dosefreq_tal_dt-" + id: {
        let updatedArray = [...Q38TalDetails];

        updatedArray[id].dt_dosefreq_tal_dt = date;
        updatedArray[id].Q38TalveyRd = 1;
        //  updatedArray[id].dt_discharge_talHard = false;
        updatedArray[id].Q38TalEmptyHard = false;
        updatedArray[id].Q38TalDateEmpty = false;
        updatedArray[id].Q38TalDateInvalid = false;
        setQ38TalDetails(updatedArray);
        break;
      }

      //40
      case "Q40init_dt-" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].Q40init_dt = date;
        updatedArray[id].Q40init_dt_Rd = 1;

        updatedArray[id].Q40Init_dtHard = false;
        updatedArray[id].Q40Init_RdHard = false;

        setQ37TechDetails(updatedArray);
        break;
      }

      default:
        break;
    }
  };

  // function isValidNumber(value) {
  //   // Return false if the value is null
  //   if (value === null) {
  //     return false;
  //   }

  //   // Convert value to a number
  //   const number = parseFloat(value);

  //   // Check if the value is a number and within the range 0 to 20
  //   return !isNaN(number) && number >= 0 && number <= 50;
  // }

  function isValidNumber(value) {
    // Convert value to a number
    const number = parseFloat(value);

    // Regular expression to match numbers with up to two decimal places
    const decimalRegex = /^\d{1,2}(\.\d{1,2})?$|^50(\.0{1,2})?$/;

    // Validate that the number is within the range and matches the decimal format
    return (
      !isNaN(number) && number >= 0 && number <= 50 && decimalRegex.test(value)
    );
  }

  const HandleFieldChange = (e) => {
    const { name, id, value, checked } = e.target;
    // ;
    switch (name) {
      case "Q37TechvayliRd" + id: {
        let updatedArray = [...Q37TechDetails];
        // let updatedTechArray = [...Q38TechDetails]

        updatedArray[id].Q37TechvayliRd = Number(value);
        updatedArray[id].q37TechvayliEmpty = false;

        updatedArray[id].showDateRedFreqTec =
          Number(value) === 1 ? true : false ;

        // setQ38TechDetails(updatedTechArray);

        setQ38TechDetails([
          {
            ...Q38TechRow,
          },
        ]);
        setQ37TechDetails(updatedArray);
        break;
      }

      case "Q37TalveyRd" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].Q37TalveyRd = Number(value);
        updatedArray[id].q37TalveyEmpty = false;

        updatedArray[id].showDateRedFreqTal =
          Number(value) === 1 ? true : false ;
        //  updatedArray[id].dose_administration_techHard = false;

        setQ38TalDetails([
          {
            ...Q38TalRow,
          },
        ]);

        setQ37TalDetails(updatedArray);
        break;
      }

      case "q37_Tec_Dose_week_frquencyRd" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].q37_Tec_Dose_week_frquencyRd = Number(value);
        updatedArray[id].q37_Tec_Dose_week_frquencyRd_Empty = false;
        updatedArray[id].q37_Tec_Dose_week_frquency_txt_Empty = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "q37_Tec_Dose_week_frquency_txt" + id: {
        let updatedArray = [...Q37TechDetails];

        const reqValue = value;
        updatedArray[id].q37_Tec_Dose_week_frquency_txt = reqValue;
        updatedArray[id].q37_Tec_Dose_week_frquencyRd = 1;
        updatedArray[id].q37_Tec_Dose_week_frquencyRd_Empty = false;
        updatedArray[id].q37_Tec_Dose_week_frquency_txt_Empty = false;

        setQ37TechDetails(updatedArray);
        break;
      }

      case "q37_Tal_Dose_week_frquencyRd" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].q37_Tal_Dose_week_frquencyRd = Number(value);
        updatedArray[id].q37_Tal_Dose_week_frquencyRd_Empty = false;
        updatedArray[id].q37_Tal_Dose_week_frquency_txt_Empty = false;
        setQ37TalDetails(updatedArray);
        break;
      }

      case "q37_Tal_Dose_week_frquency_txt" + id: {
        let updatedArray = [...Q37TalDetails];

        const reqValue = value;
        updatedArray[id].q37_Tal_Dose_week_frquency_txt = reqValue;
        updatedArray[id].q37_Tal_Dose_week_frquencyRd = 1;
        updatedArray[id].q37_Tal_Dose_week_frquencyRd_Empty = false;
        updatedArray[id].q37_Tal_Dose_week_frquency_txt_Empty = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "q37_Tal_Dosage_Rd" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].q37_Tal_Dosage_Rd = Number(value);
        updatedArray[id].q37_Tal_Dosage_OtherTxt = "";
        updatedArray[id].q37_Tal_Dosage_Rd_Empty = false;
        updatedArray[id].q37_Tal_Dosage_OtherTxt_Empty = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "q37_Tal_Dosage_OtherTxt" + id: {
        let updatedArray = [...Q37TalDetails];

        const reqValue = value;
        updatedArray[id].q37_Tal_Dosage_Rd = 3;
        updatedArray[id].q37_Tal_Dosage_OtherTxt = reqValue;
        updatedArray[id].q37_Tal_Dosage_Rd_Empty = false;
        updatedArray[id].q37_Tal_Dosage_OtherTxt_Empty = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      //40

      case "Q40dosingfreqRd" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].Q40dosingfreqRd = Number(value);
        updatedArray[id].dosing_freq_oth_txt = "";

        updatedArray[id].Q40DosingFreqEmptyHard = false;
        updatedArray[id].Q40DosingFreqTxtEmptyHard = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "Q40IgILevelRd" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].Q40IgILevelRd = Number(value);

        updatedArray[id].Q40IgGEmptyHard = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "Q40init_dt_Rd" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].Q40init_dt_Rd = Number(value);

        updatedArray[id].Q40Init_RdHard = false;
        updatedArray[id].Q40Init_dtHard = false;

        setQ37TechDetails(updatedArray);
        break;
      }

      //39Tech

      case "tocilizumab_tech" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].noneoftheabove_tech = null;
        updatedArray[id].tocilizumab_tech = !updatedArray[id].tocilizumab_tech;

        updatedArray[id].Q39techReasonHard = false;
        updatedArray[id].crs_tech = false;
        updatedArray[id].neurotoxicity_tech = false;
        updatedArray[id].tmt_neurotoxicity_tech = false;
        updatedArray[id].tmt_tech_unknown = false;
        updatedArray[id].cytokin_tech = false;
        updatedArray[id].Q39TechEmptyHard = false;

        setQ37TechDetails(updatedArray);
        break;
      }

      case "Granulocyte" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].noneoftheabove_tech = null;
        updatedArray[id].Granulocyte = !updatedArray[id].Granulocyte;

        updatedArray[id].Q39TechEmptyHard = false;
        updatedArray[id].prophylactic_use_tech = false;
        updatedArray[id].oth_use_tech = false;
        updatedArray[id].unknown_tech = null;

        setQ37TechDetails(updatedArray);
        break;
      }

      case "SteroidsTech" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].SteroidsTech = !updatedArray[id].SteroidsTech;
        updatedArray[id].noneoftheabove_tech = null;

        updatedArray[id].steroid_pre_tmt_tech = false;
        updatedArray[id].cytokin_tech_steroids = false;
        updatedArray[id].crs_tech_steroids = false;
        updatedArray[id].neurotoxicity_tech_steroids = false;
        updatedArray[id].tmt_neurotoxicity_tech_steroids = false;
        updatedArray[id].tmt_steroids_tech_unknown = false;

        updatedArray[id].Q39techSteroidsEmptyHard = false;
        updatedArray[id].Q39TechEmptyHard = false;

        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].betamethasone_tech = false;
        updatedArray[id].betamethasone_techNum = "";

        updatedArray[id].dexamethasone_tech = false;
        updatedArray[id].dexamethasone_techNum = "";

        updatedArray[id].hydrocortisone_tech = false;
        updatedArray[id].hydrocortisone_techNum = "";

        updatedArray[id].methylprednisolone = false;
        updatedArray[id].methylprednisoloneNum = "";

        updatedArray[id].prednisolone_tech = false;
        updatedArray[id].prednisolone_techNum = "";

        updatedArray[id].prednisone_tech = false;
        updatedArray[id].prednisone_techNum = "";

        updatedArray[id].triamcinolone_tech = false;
        updatedArray[id].triamcinolone_techNum = "";

        setQ37TechDetails(updatedArray);
        break;
      }

      case "noneoftheabove_tech" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].noneoftheabove_tech = Number(value);
        updatedArray[id].tocilizumab_tech = false;
        updatedArray[id].Granulocyte = false;

        updatedArray[id].Q39techReasonHard = false;
        updatedArray[id].Q39techGranduloEmptyHard = false;
        updatedArray[id].Q39TechEmptyHard = false;
        updatedArray[id].SteroidsTech = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      //39Talvey

      case "tocilizumab_tal" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].tocilizumab_tal = !updatedArray[id].tocilizumab_tal;
        updatedArray[id].noneoftheabove_tal = null;

        updatedArray[id].cytokin_tal = false;
        updatedArray[id].crs_tal = false;

        updatedArray[id].neurotoxicity_tal = false;
        updatedArray[id].tmt_neurotoxicity_tal = false;
        updatedArray[id].tmt_talvey_unknown = false;

        updatedArray[id].Q39TalEmptyHard = false;

        updatedArray[id].Q39talReasonHard = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "Steroids" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].Steroids = !updatedArray[id].Steroids;
        updatedArray[id].noneoftheabove_tal = null;

        updatedArray[id].cytokin_tal_steroids = false;
        updatedArray[id].crs_tal_steroids = false;
        updatedArray[id].neurotoxicity_tal_steroids = false;
        updatedArray[id].tmt_neurotoxicity_tal_steroids = false;
        updatedArray[id].tmt_tal_unknown = false;
        updatedArray[id].steroid_pre_tmt_tal = false;

        updatedArray[id].dose_name_tal_unknown = false;
        updatedArray[id].betamethasone_tal = false;
        updatedArray[id].betamethasone_talNum = "";

        updatedArray[id].dexamethasone_tal = false;
        updatedArray[id].dexamethasone_talNum = "";

        updatedArray[id].hydrocortisone_tal = false;
        updatedArray[id].hydrocortisone_talNum = "";

        updatedArray[id].methylprednisolone_tal = false;
        updatedArray[id].methylprednisolone_talNum = "";

        updatedArray[id].prednisolone_tal = false;
        updatedArray[id].prednisolone_talNum = "";

        updatedArray[id].prednisone_tal = false;
        updatedArray[id].prednisone_talNum = "";

        updatedArray[id].triamcinolone_tal = false;
        updatedArray[id].triamcinolone_talNum = "";

        updatedArray[id].Q39talSteroidsEmptyHard = false;
        updatedArray[id].Q39TalEmptyHard = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "noneoftheabove_tal" + id: {
        let updatedArray = [...Q37TalDetails];
        updatedArray[id].Steroids = false;
        updatedArray[id].tocilizumab_tal = false;
        updatedArray[id].noneoftheabove_tal = Number(value);
        updatedArray[id].Q39TalEmptyHard = false;

        updatedArray[id].Q39talReasonHard = false;

        updatedArray[id].Q39talSteroidsEmptyHard = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "dosing_freq_oth_txt" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].Q40dosingfreqRd = 4;
        updatedArray[id].dosing_freq_oth_txt = value;

        updatedArray[id].Q40DosingFreqEmptyHard = false;
        updatedArray[id].Q40DosingFreqTxtEmptyHard = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      //checkbox - primary prof
      case "primaryProf" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].primaryProf = !updatedArray[id].primaryProf;

        updatedArray[id].Q40ReasonEmptyHard = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "secondaryProf" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].secondaryProf = !updatedArray[id].secondaryProf;
        updatedArray[id].Q40ReasonEmptyHard = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "otherReson" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].otherReson = !updatedArray[id].otherReson;
        updatedArray[id].Q40ReasonEmptyHard = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      //39- 1.1
      // cytokin_tech:false,
      // crs_tech:false,
      // neurotoxicity_tech:false,
      // tmt_neurotoxicity_tech:false
      //tmt_tech_unknown
      case "cytokin_tech" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].cytokin_tech = !updatedArray[id].cytokin_tech;

        updatedArray[id].tmt_tech_unknown = false;

        updatedArray[id].Q39techReasonHard = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "crs_tech" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].crs_tech = !updatedArray[id].crs_tech;
        updatedArray[id].Q39techReasonHard = false;
        updatedArray[id].tmt_tech_unknown = false;

        setQ37TechDetails(updatedArray);
        break;
      }

      case "neurotoxicity_tech" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].neurotoxicity_tech =
          !updatedArray[id].neurotoxicity_tech;
        updatedArray[id].Q39techReasonHard = false;
        updatedArray[id].tmt_tech_unknown = false;

        setQ37TechDetails(updatedArray);
        break;
      }

      case "tmt_neurotoxicity_tech" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].tmt_neurotoxicity_tech =
          !updatedArray[id].tmt_neurotoxicity_tech;
        updatedArray[id].Q39techReasonHard = false;
        updatedArray[id].tmt_tech_unknown = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "tmt_tech_unknown" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].tmt_tech_unknown = !updatedArray[id].tmt_tech_unknown;
        updatedArray[id].Q39techReasonHard = false;

        updatedArray[id].crs_tech = false;
        updatedArray[id].cytokin_tech = false;
        updatedArray[id].tmt_neurotoxicity_tech = false;
        updatedArray[id].neurotoxicity_tech = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      //1.3

      // prophylactic_use_tech:false
      // oth_use_tech:false,
      // unknown_tech:null

      case "prophylactic_use_tech" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].prophylactic_use_tech =
          !updatedArray[id].prophylactic_use_tech;

        updatedArray[id].Q39techGranduloEmptyHard = false;
        updatedArray[id].unknown_tech = null;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "oth_use_tech" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].oth_use_tech = !updatedArray[id].oth_use_tech;

        updatedArray[id].Q39techGranduloEmptyHard = false;
        updatedArray[id].unknown_tech = null;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "unknown_tech" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].unknown_tech = Number(value);
        updatedArray[id].oth_use_tech = false;
        updatedArray[id].prophylactic_use_tech = false;
        updatedArray[id].Q39techGranduloEmptyHard = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      //39 Tlvey
      //   cytokin_tal:false,
      // crs_tal:false,
      // neurotoxicity_tal:false,
      // tmt_neurotoxicity_tal:false,
      //tmt_tal_unknown:false,

      case "cytokin_tal" + id: {
        let updatedArray = [...Q37TalDetails];
        updatedArray[id].cytokin_tal = !updatedArray[id].cytokin_tal;

        updatedArray[id].Q39talReasonHard = false;
        updatedArray[id].tmt_talvey_unknown = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "crs_tal" + id: {
        let updatedArray = [...Q37TalDetails];
        updatedArray[id].crs_tal = !updatedArray[id].crs_tal;

        updatedArray[id].tmt_talvey_unknown = false;

        updatedArray[id].Q39talReasonHard = false;
        setQ37TalDetails(updatedArray);
        break;
      }

      case "neurotoxicity_tal" + id: {
        let updatedArray = [...Q37TalDetails];
        updatedArray[id].neurotoxicity_tal =
          !updatedArray[id].neurotoxicity_tal;
        updatedArray[id].Q39talReasonHard = false;
        updatedArray[id].tmt_talvey_unknown = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "tmt_neurotoxicity_tal" + id: {
        let updatedArray = [...Q37TalDetails];
        updatedArray[id].tmt_neurotoxicity_tal =
          !updatedArray[id].tmt_neurotoxicity_tal;
        updatedArray[id].Q39talReasonHard = false;
        updatedArray[id].tmt_talvey_unknown = false;
        setQ37TalDetails(updatedArray);
        break;
      }

      case "tmt_talvey_unknown" + id: {
        let updatedArray = [...Q37TalDetails];
        updatedArray[id].tmt_talvey_unknown =
          !updatedArray[id].tmt_talvey_unknown;
        updatedArray[id].Q39talReasonHard = false;

        updatedArray[id].tmt_neurotoxicity_tal = false;
        updatedArray[id].cytokin_tal = false;
        updatedArray[id].neurotoxicity_tal = false;
        updatedArray[id].crs_tal = false;
        setQ37TalDetails(updatedArray);
        break;
      }

      //1.2 Talvey
      // cytokin_tal_steroids:false,
      // crs_tal_steroids:false,
      // neurotoxicity_tal_steroids:false,
      // tmt_neurotoxicity_tal_steroids:false,
      case "cytokin_tal_steroids" + id: {
        let updatedArray = [...Q37TalDetails];
        updatedArray[id].cytokin_tal_steroids =
          !updatedArray[id].cytokin_tal_steroids;

        updatedArray[id].Q39talSteroidsEmptyHard = false;
        updatedArray[id].tmt_tal_unknown = false;
        setQ37TalDetails(updatedArray);
        break;
      }

      case "crs_tal_steroids" + id: {
        let updatedArray = [...Q37TalDetails];
        updatedArray[id].crs_tal_steroids = !updatedArray[id].crs_tal_steroids;
        updatedArray[id].Q39talSteroidsEmptyHard = false;
        updatedArray[id].tmt_tal_unknown = false;
        setQ37TalDetails(updatedArray);
        break;
      }

      case "neurotoxicity_tal_steroids" + id: {
        let updatedArray = [...Q37TalDetails];
        updatedArray[id].neurotoxicity_tal_steroids =
          !updatedArray[id].neurotoxicity_tal_steroids;
        updatedArray[id].Q39talSteroidsEmptyHard = false;
        updatedArray[id].tmt_tal_unknown = false;
        setQ37TalDetails(updatedArray);
        break;
      }

      case "tmt_neurotoxicity_tal_steroids" + id: {
        let updatedArray = [...Q37TalDetails];
        updatedArray[id].tmt_neurotoxicity_tal_steroids =
          !updatedArray[id].tmt_neurotoxicity_tal_steroids;
        updatedArray[id].Q39talSteroidsEmptyHard = false;
        updatedArray[id].tmt_tal_unknown = false;
        setQ37TalDetails(updatedArray);
        break;
      }

      case "tmt_tal_unknown" + id: {
        let updatedArray = [...Q37TalDetails];
        updatedArray[id].tmt_tal_unknown = !updatedArray[id].tmt_tal_unknown;

        updatedArray[id].cytokin_tal_steroids = false;

        updatedArray[id].crs_tal_steroids = false;

        updatedArray[id].neurotoxicity_tal_steroids = false;

        updatedArray[id].tmt_neurotoxicity_tal_steroids = false;
        updatedArray[id].steroid_pre_tmt_tal = false;

        updatedArray[id].Q39talSteroidsEmptyHard = false;
        setQ37TalDetails(updatedArray);
        break;
      }

      case "steroid_pre_tmt_tal" + id: {
        let updatedArray = [...Q37TalDetails];
        updatedArray[id].steroid_pre_tmt_tal =
          !updatedArray[id].steroid_pre_tmt_tal;

        updatedArray[id].Q39talSteroidsEmptyHard = false;
        updatedArray[id].tmt_tal_unknown = false;
        setQ37TalDetails(updatedArray);
        break;
      }

      case "betamethasone_tal" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].betamethasone_tal =
          !updatedArray[id].betamethasone_tal;

        updatedArray[id].betamethasone_talNum = "";
        updatedArray[id].name_dose_steroids_talHardEmpty = false;
        updatedArray[id].dose_name_tal_unknown = false;

        updatedArray[id].steroids_tal_txtEmpty = false;
        setQ37TalDetails(updatedArray);
        break;
      }

      case "betamethasone_talNum" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].betamethasone_talNum = value;

        updatedArray[id].betamethasone_tal = true;

        updatedArray[id].name_dose_steroids_talHardEmpty = false;
        updatedArray[id].steroids_tal_txtEmpty = false;
        updatedArray[id].dose_name_tal_unknown = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "dexamethasone_tal" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].dexamethasone_tal =
          !updatedArray[id].dexamethasone_tal;

        updatedArray[id].dexamethasone_talNum = "";
        updatedArray[id].name_dose_steroids_talHardEmpty = false;
        updatedArray[id].steroids_tal_txtEmpty = false;

        updatedArray[id].dose_name_tal_unknown = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "dexamethasone_talNum" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].dexamethasone_talNum = value;

        updatedArray[id].dexamethasone_tal = true;

        updatedArray[id].name_dose_steroids_talHardEmpty = false;
        updatedArray[id].steroids_tal_txtEmpty = false;
        updatedArray[id].dose_name_tal_unknown = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "hydrocortisone_tal" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].hydrocortisone_tal =
          !updatedArray[id].hydrocortisone_tal;

        updatedArray[id].hydrocortisone_talNum = "";

        updatedArray[id].name_dose_steroids_talHardEmpty = false;
        updatedArray[id].steroids_tal_txtEmpty = false;
        updatedArray[id].dose_name_tal_unknown = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "hydrocortisone_talNum" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].hydrocortisone_talNum = value;

        updatedArray[id].hydrocortisone_tal = true;

        updatedArray[id].name_dose_steroids_talHardEmpty = false;
        updatedArray[id].steroids_tal_txtEmpty = false;
        updatedArray[id].dose_name_tal_unknown = false;
        setQ37TalDetails(updatedArray);
        break;
      }

      //

      case "methylprednisolone_tal" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].methylprednisolone_tal =
          !updatedArray[id].methylprednisolone_tal;

        updatedArray[id].methylprednisolone_talNum = "";

        updatedArray[id].name_dose_steroids_talHardEmpty = false;
        updatedArray[id].steroids_tal_txtEmpty = false;
        updatedArray[id].dose_name_tal_unknown = false;
        setQ37TalDetails(updatedArray);
        break;
      }

      case "methylprednisolone_talNum" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].methylprednisolone_talNum = value;

        updatedArray[id].methylprednisolone_tal = true;

        updatedArray[id].name_dose_steroids_talHardEmpty = false;
        updatedArray[id].steroids_tal_txtEmpty = false;
        updatedArray[id].dose_name_tal_unknown = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "prednisolone_tal" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].prednisolone_tal = !updatedArray[id].prednisolone_tal;

        updatedArray[id].prednisolone_talNum = "";

        updatedArray[id].name_dose_steroids_talHardEmpty = false;
        updatedArray[id].steroids_tal_txtEmpty = false;
        updatedArray[id].dose_name_tal_unknown = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "prednisolone_talNum" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].prednisolone_talNum = value;

        updatedArray[id].prednisolone_tal = true;

        updatedArray[id].name_dose_steroids_talHardEmpty = false;
        updatedArray[id].steroids_tal_txtEmpty = false;
        updatedArray[id].dose_name_tal_unknown = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "prednisone_tal" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].prednisone_tal = !updatedArray[id].prednisone_tal;

        updatedArray[id].prednisone_talNum = "";

        updatedArray[id].name_dose_steroids_talHardEmpty = false;
        updatedArray[id].steroids_tal_txtEmpty = false;
        updatedArray[id].dose_name_tal_unknown = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "prednisone_talNum" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].prednisone_talNum = value;

        updatedArray[id].prednisone_tal = true;

        updatedArray[id].name_dose_steroids_talHardEmpty = false;
        updatedArray[id].steroids_tal_txtEmpty = false;
        updatedArray[id].dose_name_tal_unknown = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "triamcinolone_tal" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].triamcinolone_tal =
          !updatedArray[id].triamcinolone_tal;

        updatedArray[id].triamcinolone_talNum = "";

        updatedArray[id].name_dose_steroids_talHardEmpty = false;
        updatedArray[id].steroids_tal_txtEmpty = false;
        updatedArray[id].dose_name_tal_unknown = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "triamcinolone_talNum" + id: {
        let updatedArray = [...Q37TalDetails];

        updatedArray[id].triamcinolone_talNum = value;

        updatedArray[id].triamcinolone_tal = true;

        updatedArray[id].name_dose_steroids_talHardEmpty = false;
        updatedArray[id].steroids_tal_txtEmpty = false;
        updatedArray[id].dose_name_tal_unknown = false;

        setQ37TalDetails(updatedArray);
        break;
      }

      case "dose_name_tal_unknown" + id: {
        let updatedArray = [...Q37TalDetails];
        updatedArray[id].dose_name_tal_unknown =
          !updatedArray[id].dose_name_tal_unknown;

        updatedArray[id].name_dose_steroids_talHardEmpty = false;

        updatedArray[id].steroids_tal_txtEmpty = false;

        updatedArray[id].betamethasone_tal = false;
        updatedArray[id].betamethasone_talNum = "";

        updatedArray[id].dexamethasone_tal = false;
        updatedArray[id].dexamethasone_talNum = "";

        updatedArray[id].hydrocortisone_tal = false;
        updatedArray[id].hydrocortisone_talNum = "";

        updatedArray[id].methylprednisolone_tal = false;
        updatedArray[id].methylprednisolone_talNum = "";

        updatedArray[id].prednisolone_tal = false;
        updatedArray[id].prednisolone_talNum = "";

        updatedArray[id].prednisone_tal = false;
        updatedArray[id].prednisone_talNum = "";

        updatedArray[id].triamcinolone_tal = false;
        updatedArray[id].triamcinolone_talNum = "";

        setQ37TalDetails(updatedArray);
        break;
      }

      // New phase
      case "steroid_pre_tmt_tech" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].steroid_pre_tmt_tech =
          !updatedArray[id].steroid_pre_tmt_tech;

        updatedArray[id].Q39techSteroidsEmptyHard = false;
        updatedArray[id].tmt_steroids_tech_unknown = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      //New tech column handlechange

      case "betamethasone_tech" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].betamethasone_tech =
          !updatedArray[id].betamethasone_tech;

        updatedArray[id].betamethasone_techNum = "";
        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].betamethasone_tech_txtEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "betamethasone_techNum" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].betamethasone_techNum = value;

        updatedArray[id].betamethasone_tech = true;

        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].betamethasone_tech_txtEmpty = false;

        setQ37TechDetails(updatedArray);
        break;
      }

      case "dexamethasone_tech" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].dexamethasone_tech =
          !updatedArray[id].dexamethasone_tech;

        updatedArray[id].dexamethasone_techNum = "";
        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].dexamethasone_tech_txtEmpty = false;

        setQ37TechDetails(updatedArray);
        break;
      }

      case "dexamethasone_techNum" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].dexamethasone_techNum = value;

        updatedArray[id].dexamethasone_tech = true;

        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].dexamethasone_tech_txtEmpty = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "hydrocortisone_tech" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].hydrocortisone_tech =
          !updatedArray[id].hydrocortisone_tech;

        updatedArray[id].hydrocortisone_techNum = "";

        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].hydrocortisone_tech_txtEmpty = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "hydrocortisone_techNum" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].hydrocortisone_techNum = value;

        updatedArray[id].hydrocortisone_tech = true;

        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].hydrocortisone_tech_txtEmpty = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      //

      case "methylprednisolone" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].methylprednisolone =
          !updatedArray[id].methylprednisolone;

        updatedArray[id].methylprednisoloneNum = "";

        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].methylprednisolone_txtEmpty = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "methylprednisoloneNum" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].methylprednisoloneNum = value;

        updatedArray[id].methylprednisolone = true;

        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].methylprednisolone_txtEmpty = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "prednisolone_tech" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].prednisolone_tech =
          !updatedArray[id].prednisolone_tech;

        updatedArray[id].prednisolone_techNum = "";

        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].prednisolone_tech_txtEmpty = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "prednisolone_techNum" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].prednisolone_techNum = value;

        updatedArray[id].prednisolone_tech = true;

        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].prednisolone_tech_txtEmpty = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "prednisone_tech" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].prednisone_tech = !updatedArray[id].prednisone_tech;

        updatedArray[id].prednisone_techNum = "";

        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].prednisone_tech_txtEmpty = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "prednisone_techNum" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].prednisone_techNum = value;

        updatedArray[id].prednisone_tech = true;

        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].prednisone_tech_txtEmpty = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "triamcinolone_tech" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].triamcinolone_tech =
          !updatedArray[id].triamcinolone_tech;

        updatedArray[id].triamcinolone_techNum = "";

        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].triamcinolone_tech_txtEmpty = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "triamcinolone_techNum" + id: {
        let updatedArray = [...Q37TechDetails];

        updatedArray[id].triamcinolone_techNum = value;

        updatedArray[id].triamcinolone_tech = true;

        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].dose_name_tech_unknown = false;
        updatedArray[id].triamcinolone_tech_txtEmpty = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "dose_name_tech_unknown" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].dose_name_tech_unknown =
          !updatedArray[id].dose_name_tech_unknown;

        updatedArray[id].name_dose_steroidsHardEmpty = false;
        updatedArray[id].steroids_tech_txtEmpty = false;
        updatedArray[id].betamethasone_tech_txtEmpty = false;
        updatedArray[id].dexamethasone_tech_txtEmpty = false;
        updatedArray[id].hydrocortisone_tech_txtEmpty = false;
        updatedArray[id].methylprednisolone_txtEmpty = false;
        updatedArray[id].prednisolone_tech_txtEmpty = false;
        updatedArray[id].prednisone_tech_txtEmpty = false;
        updatedArray[id].triamcinolone_tech_txtEmpty = false;

        updatedArray[id].betamethasone_tech = false;
        updatedArray[id].betamethasone_techNum = "";

        updatedArray[id].dexamethasone_tech = false;
        updatedArray[id].dexamethasone_techNum = "";

        updatedArray[id].hydrocortisone_tech = false;
        updatedArray[id].hydrocortisone_techNum = "";

        updatedArray[id].methylprednisolone = false;
        updatedArray[id].methylprednisoloneNum = "";

        updatedArray[id].prednisolone_tech = false;
        updatedArray[id].prednisolone_techNum = "";

        updatedArray[id].prednisone_tech = false;
        updatedArray[id].prednisone_techNum = "";

        updatedArray[id].triamcinolone_tech = false;
        updatedArray[id].triamcinolone_techNum = "";

        setQ37TechDetails(updatedArray);
        break;
      }

      case "cytokin_tech_steroids" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].cytokin_tech_steroids =
          !updatedArray[id].cytokin_tech_steroids;

        updatedArray[id].Q39techSteroidsEmptyHard = false;
        updatedArray[id].tmt_steroids_tech_unknown = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "crs_tech_steroids" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].crs_tech_steroids =
          !updatedArray[id].crs_tech_steroids;
        updatedArray[id].Q39techSteroidsEmptyHard = false;
        updatedArray[id].tmt_steroids_tech_unknown = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "neurotoxicity_tech_steroids" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].neurotoxicity_tech_steroids =
          !updatedArray[id].neurotoxicity_tech_steroids;
        updatedArray[id].Q39techSteroidsEmptyHard = false;
        updatedArray[id].tmt_steroids_tech_unknown = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "tmt_neurotoxicity_tech_steroids" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].tmt_neurotoxicity_tech_steroids =
          !updatedArray[id].tmt_neurotoxicity_tech_steroids;
        updatedArray[id].Q39techSteroidsEmptyHard = false;
        updatedArray[id].tmt_steroids_tech_unknown = false;
        setQ37TechDetails(updatedArray);
        break;
      }

      case "tmt_steroids_tech_unknown" + id: {
        let updatedArray = [...Q37TechDetails];
        updatedArray[id].tmt_steroids_tech_unknown =
          !updatedArray[id].tmt_steroids_tech_unknown;

        updatedArray[id].cytokin_tech_steroids = false;

        updatedArray[id].steroid_pre_tmt_tech = false;
        updatedArray[id].crs_tech_steroids = false;

        updatedArray[id].neurotoxicity_tech_steroids = false;

        updatedArray[id].tmt_neurotoxicity_tech_steroids = false;

        updatedArray[id].Q39techSteroidsEmptyHard = false;
        setQ37TechDetails(updatedArray);
      }

      default:
        break;
    }
    //
  };

    const ValidateForm = () => {
     let isValid = true;

    let count = 0;
    let count_tal = 0;

    //36 Techvayli
    if (
      isTechvayliSelected &&
      techvayliDetails &&
      techvayliDetails.length > 0
    ) {
      const updatedArray = techvayliDetails.map((item, index) => {
        let dose_administration_techHard = false;
        if (item.dose_administration_tech == null) {
          dose_administration_techHard = true;
        }

        let dose_administration_techHard_dt = false;
        if (
          item.dose_administration_tech === 1 &&
          item.dose_administration_tech_dt === ""
        ) {
          dose_administration_techHard_dt = true;
        }

        let dosing_phase_techHard = false;
        if (item.dosing_phase_tech == null) {
          dosing_phase_techHard = true;
        }

        let siteofcare_techHard = false;
        if (item.siteofcare_tech == null) {
          siteofcare_techHard = true;
        }

        // let dt_admission_techHard = false;
        // if (item.Q36TechConditionMet && item.dt_admission_tech == null) {
        //   dt_admission_techHard = true;
        // }

        //let dt_admission_techHard_dt = false;
        // if (
        //   item.Q36TechConditionMet &&
        //   item.dt_admission_tech === 1 &&
        //   item.dt_admission_tech_dt === ""
        // ) {
        //   dt_admission_techHard_dt = true;
        // }

        //let dt_discharge_techHard = false;
        // if (item.Q36TechConditionMet && item.dt_discharge_tech == null) {
        //   dt_discharge_techHard = true;
        // }

        // let dt_discharge_techHard_dt = false;
        // if (
        // //  item.Q36TechConditionMet &&
        //   item.dt_discharge_tech === 1 &&
        //   item.dt_discharge_tech_dt === ""
        // ) {
        //   dt_discharge_techHard_dt = true;
        // }

        // //
        // let dt_admission_techHard_dt_check = false;
        // let dt_discharge_techHard_dt_check = false;

        // if (
        //   // item.Q36TechConditionMet &&
        //   item.dt_admission_tech_dt &&
        //   item.dt_discharge_tech_dt &&
        //   item.dt_discharge_tech_dt < item.dt_admission_tech_dt
        // ) {
        //   dt_discharge_techHard_dt_check = true;
        // }
        const i = index - 1;

        // if (  item.Q36TechConditionMet && index > 0 && item.dt_admission_tech_dt < techvayliDetails[i].dt_discharge_tech_dt) {

        //   dt_admission_techHard_dt_check = true;
        // }
          debugger;
          if (item.dose_administration_tech_dt != "") {
              if (techIndexDate === item.dose_administration_tech_dt) {
                  count++;
              }

              //if (count === 0) {     
              //  setIndexDateRRMM_techHard(true);
              //  isValid = false;
              //} else {
              //  setIndexDateRRMM_techHard(false);
              //}
          } else if (item.dose_administration_tech == 99) {
              count++;
          }

        //let dt_tech_greater_prevCheck = false;

        // if (index > 0 &&  item.dose_administration_tech_dt  &&  techvayliDetails[i].dose_administration_tech_dt &&   item.dose_administration_tech_dt  <= techvayliDetails[i].dose_administration_tech_dt) {
        //   dt_tech_greater_prevCheck = true;
        //   //dt_discharge_techHard_dt_check = true;
        // }

        let dt_tech_greater_prevCheck = false;

        if (index > 0 && item.dose_administration_tech_dt) {
          // Filter out the current index and get only the previous rows that have a date
          const previousDates = techvayliDetails
            .slice(0, index) // Exclude the current index by slicing up to it
            .filter(
              (_, idx) =>
                idx !== index &&
                techvayliDetails[idx].dose_administration_tech_dt
            ) // Ensure that previous indices have a date
            .map((detail) => detail.dose_administration_tech_dt); // Map to get the dates

          // Get the most recent previous date if any
          if (previousDates.length > 0) {
            const mostRecentPrevDate = previousDates[previousDates.length - 1];

            // Check if the current date is less than or equal to the most recent previous date
            if (item.dose_administration_tech_dt <= mostRecentPrevDate) {
              dt_tech_greater_prevCheck = true;
            }
          }
        }

        if (
          dose_administration_techHard ||
          dose_administration_techHard_dt ||
          dosing_phase_techHard ||
          siteofcare_techHard ||
          //dt_admission_techHard ||
          // dt_admission_techHard_dt ||
          // dt_discharge_techHard ||
          // dt_discharge_techHard_dt ||
          // dt_admission_techHard_dt_check ||
          // dt_discharge_techHard_dt_check ||
          dt_tech_greater_prevCheck
        ) {
          isValid = false;
        }

        return {
          ...item,

          dose_administration_techHard,
          dose_administration_techHard_dt,
          dosing_phase_techHard,
          siteofcare_techHard,
          // dt_admission_techHard,
          // dt_admission_techHard_dt,
          // dt_discharge_techHard,
          // dt_discharge_techHard_dt,
          // dt_admission_techHard_dt_check,
          // dt_discharge_techHard_dt_check,
          dt_tech_greater_prevCheck,
        };
      });
        if (count === 0) {
          setIndexDateRRMM_techHard(true);
          isValid = false;
        } else {
          setIndexDateRRMM_techHard(false);
        }
      setTechvayliDetails(updatedArray);
    }
    //36.1 Techvayli
    if (isTechvayliSelected && tecHospDetails && tecHospDetails.length > 0) {
      const updatedArray = tecHospDetails.map((item, index) => {
        let dt_admission_Hard = false;
        let dt_admission_Hard_dt = false;
        // let dt_admission_Hard_dt_check = false;

        let dt_discharge_Hard = false;
        let dt_discharge_Hard_dt = false;
        let dt_discharge_Hard_dt_check = false;

        let hospReasonEmptyHard = false;
        let hospReasonTxtEmpty = false;

        dt_admission_Hard = item.dt_admission == null;
        dt_admission_Hard_dt =
          item.dt_admission == 1 && isStringEmpty(item.dt_admission_dt);

        if (item.dt_admission != null && item.dt_admission !== 2) {
          dt_discharge_Hard = item.dt_discharge == null;
          dt_discharge_Hard_dt =
            item.dt_discharge == 1 && isStringEmpty(item.dt_discharge_dt);
          if (
            item.dt_admission_dt &&
            item.dt_discharge_dt &&
            item.dt_admission_dt > item.dt_discharge_dt
          ) {
            dt_discharge_Hard_dt_check = true;
          }
          hospReasonEmptyHard =
            !item.hospReason1 &&
            !item.hospReason2 &&
            !item.hospReason3 &&
            !item.hospReason4 &&
            !item.hospReason5 &&
            !item.hospReason6 &&
            !item.hospReasonUnk;
          hospReasonTxtEmpty =
            item.hospReason6 && isStringEmpty(item.hosp_reason_oth_txt);
        }

        if (
          dt_admission_Hard ||
          dt_admission_Hard_dt ||
          // dt_admission_Hard_dt_check ||
          dt_discharge_Hard ||
          dt_discharge_Hard_dt ||
          dt_discharge_Hard_dt_check ||
          hospReasonEmptyHard ||
          hospReasonTxtEmpty
        ) {
          isValid = false;
        }

        return {
          ...item,
          dt_admission_Hard,
          dt_admission_Hard_dt,
          // dt_admission_Hard_dt_check,
          dt_discharge_Hard,
          dt_discharge_Hard_dt,
          dt_discharge_Hard_dt_check,
          hospReasonEmptyHard,
          hospReasonTxtEmpty,
        };
      });

      setTecHospDetails(updatedArray);
    }
    //end
    //36 Talvey
    if (isTalveySelected && talveyDetails && talveyDetails.length > 0) {
      //

      const updatedArray = talveyDetails.map((item, index) => {
        let dose_administration_talHard = false;
        if (item.dose_administration_tal == null) {
          dose_administration_talHard = true;
        }

        let dose_administration_talHard_dt = false;
        if (
          item.dose_administration_tal === 1 &&
          item.dose_administration_tal_dt === ""
        ) {
          dose_administration_talHard_dt = true;
        }

        let dosing_phase_talHard = false;
        if (item.dosing_phase_tal == null) {
          dosing_phase_talHard = true;
        }

        let siteofcare_talHard = false;
        if (item.siteofcare_tal == null) {
          siteofcare_talHard = true;
        }

        // let dt_admission_talHard = false;
        // if (item.Q36TalConditionMet && item.dt_admission_tal == null) {
        //   dt_admission_talHard = true;
        // }

        // let dt_admission_talHard_dt = false;
        // if (
        //   item.Q36TalConditionMet &&
        //   item.dt_admission_tal === 1 &&
        //   item.dt_admission_tal_dt === ""
        // ) {
        //   dt_admission_talHard_dt = true;
        // }

        // let dt_discharge_talHard = false;
        // if (item.Q36TalConditionMet && item.dt_discharge_tal == null) {
        //   dt_discharge_talHard = true;
        // }

        // let dt_discharge_talHard_dt = false;
        // if (
        //   item.Q36TalConditionMet &&
        //   item.dt_discharge_tal === 1 &&
        //   item.dt_discharge_tal_dt === ""
        // ) {
        //   dt_discharge_talHard_dt = true;
        // }

        // let dt_admission_talHard_dt_check = false;
        // let dt_discharge_talHard_dt_check = false;

        // if (
        //   item.Q36TalConditionMet &&
        //   item.dt_admission_tal_dt &&
        //   item.dt_discharge_tal_dt &&
        //   item.dt_discharge_tal_dt < item.dt_admission_tal_dt
        // ) {
        //   dt_discharge_talHard_dt_check = true;
        // }
        const i = index - 1;

        // if (item.Q36TalConditionMet && index > 0 && item.dt_admission_tal_dt < talveyDetails[i].dt_discharge_tal_dt) {

        //   dt_admission_talHard_dt_check = true;
        // }
          debugger;
          debugger;
          if (item.dose_administration_tal_dt != "") {
              if (talIndexDate === item.dose_administration_tal_dt) {
                  count_tal++;
              }

              //if (count_tal === 0) {
              //  setIndexDateRRMM_talHard(true);
              //  isValid = false;
              //} else {
              //  setIndexDateRRMM_talHard(false);
              //}
          } else if (item.dose_administration_tal == 99) {
              count_tal++;
          }

        let dt_tal_greater_prevCheck = false;

        // const i =  index-1

        // if (index > 0 &&  item.dose_administration_tal_dt  &&  talveyDetails[i].dose_administration_tal_dt &&   item.dose_administration_tal_dt  <= talveyDetails[i].dose_administration_tal_dt) {
        //   dt_tal_greater_prevCheck = true;
        //   //dt_discharge_techHard_dt_check = true;
        // }

        if (index > 0 && item.dose_administration_tal_dt) {
          // Filter out the current index and get only the previous rows that have a date
          const previousDates = talveyDetails
            .slice(0, index) // Exclude the current index by slicing up to it
            .filter(
              (_, idx) =>
                idx !== index && talveyDetails[idx].dose_administration_tal_dt
            ) // Ensure that previous indices have a date
            .map((detail) => detail.dose_administration_tal_dt); // Map to get the dates

          // Get the most recent previous date if any
          if (previousDates.length > 0) {
            const mostRecentPrevDate = previousDates[previousDates.length - 1];

            // Check if the current date is less than or equal to the most recent previous date
            if (item.dose_administration_tal_dt <= mostRecentPrevDate) {
              dt_tal_greater_prevCheck = true;
            }
          }
        }

        if (
          dose_administration_talHard ||
          dose_administration_talHard_dt ||
          dosing_phase_talHard ||
          siteofcare_talHard ||
          // dt_admission_talHard ||
          // dt_admission_talHard_dt ||
          // dt_discharge_talHard ||
          // dt_discharge_talHard_dt ||
          // dt_admission_talHard_dt_check ||
          // dt_discharge_talHard_dt_check ||
          dt_tal_greater_prevCheck
        ) {
          isValid = false;
        }

        return {
          ...item,

          dose_administration_talHard,
          dose_administration_talHard_dt,
          dosing_phase_talHard,
          siteofcare_talHard,
          // dt_admission_talHard,
          // dt_admission_talHard_dt,
          // dt_discharge_talHard,
          // dt_discharge_talHard_dt,
          // dt_admission_talHard_dt_check,
          // dt_discharge_talHard_dt_check,
          dt_tal_greater_prevCheck,
        };
      });
        debugger;
        if (count_tal === 0) {
            setIndexDateRRMM_talHard(true);
            isValid = false;
        } else {
            setIndexDateRRMM_talHard(false);
        }
      setTalveyDetails(updatedArray);
    }
    //36.1 Talvey
    if (isTalveySelected && talHospDetails && talHospDetails.length > 0) {
      const updatedArray = talHospDetails.map((item, index) => {
        let dt_admission_Hard = false;
        let dt_admission_Hard_dt = false;
        // let dt_admission_Hard_dt_check = false;

        let dt_discharge_Hard = false;
        let dt_discharge_Hard_dt = false;
        let dt_discharge_Hard_dt_check = false;

        let hospReasonEmptyHard = false;
        let hospReasonTxtEmpty = false;

        dt_admission_Hard = item.dt_admission == null;
        dt_admission_Hard_dt =
          item.dt_admission == 1 && isStringEmpty(item.dt_admission_dt);

        if (item.dt_admission != null && item.dt_admission !== 2) {
          dt_discharge_Hard = item.dt_discharge == null;
          dt_discharge_Hard_dt =
            item.dt_discharge == 1 && isStringEmpty(item.dt_discharge_dt);
          if (
            item.dt_admission_dt &&
            item.dt_discharge_dt &&
            item.dt_admission_dt > item.dt_discharge_dt
          ) {
            dt_discharge_Hard_dt_check = true;
          }
          hospReasonEmptyHard =
            !item.hospReason1 &&
            !item.hospReason2 &&
            !item.hospReason3 &&
            !item.hospReason4 &&
            !item.hospReason5 &&
            !item.hospReason6 &&
            !item.hospReasonUnk;
          hospReasonTxtEmpty =
            item.hospReason6 && isStringEmpty(item.hosp_reason_oth_txt);
        }

        if (
          dt_admission_Hard ||
          dt_admission_Hard_dt ||
          // dt_admission_Hard_dt_check ||
          dt_discharge_Hard ||
          dt_discharge_Hard_dt ||
          dt_discharge_Hard_dt_check ||
          hospReasonEmptyHard ||
          hospReasonTxtEmpty
        ) {
          isValid = false;
        }

        return {
          ...item,
          dt_admission_Hard,
          dt_admission_Hard_dt,
          // dt_admission_Hard_dt_check,
          dt_discharge_Hard,
          dt_discharge_Hard_dt,
          dt_discharge_Hard_dt_check,
          hospReasonEmptyHard,
          hospReasonTxtEmpty,
        };
      });

      setTalHospDetails(updatedArray);
    }
    //end

    debugger;
    if (isTechvayliSelected && Q37TechDetails && Q37TechDetails.length > 0) {
      const updatedArray = Q37TechDetails.map((item) => {
        let Q40DosingFreqEmptyHard = false;
        if (item.Q40dosingfreqRd == null) {
          Q40DosingFreqEmptyHard = true;
        }

        let Q40DosingFreqTxtEmptyHard = false;
        if (item.Q40dosingfreqRd === 4 && item.dosing_freq_oth_txt === "") {
          Q40DosingFreqTxtEmptyHard = true;
        }

        let Q40IgGEmptyHard = false;
        if (item.Q40IgILevelRd === null) {
          Q40IgGEmptyHard = true;
        }

        let Q40ReasonEmptyHard = false;
        if (
          item.primaryProf === false &&
          item.secondaryProf === false &&
          item.otherReson === false
        ) {
          Q40ReasonEmptyHard = true;
        }

        let Q40Init_RdHard = false;
        if (item.Q40init_dt_Rd === null) {
          Q40Init_RdHard = true;
        }

        let Q40Init_dtHard = false;

        if (item.Q40init_dt_Rd === 1 && item.Q40init_dt === "") {
          Q40Init_dtHard = true;
        }

        //37Tech
        let q37TechvayliEmpty = false;
        if (item.Q37TechvayliRd === null) {
          q37TechvayliEmpty = true;
        }

        let q37_Tec_Dose_week_frquencyRd_Empty = false;
        if (item.q37_Tec_Dose_week_frquencyRd == null) {
          q37_Tec_Dose_week_frquencyRd_Empty = true;
        }

        debugger;

        let q37_Tec_Dose_week_frquency_txt_Empty = false;
        if (
          item.q37_Tec_Dose_week_frquencyRd === 1 &&
          (item.q37_Tec_Dose_week_frquency_txt === "" ||
            item.q37_Tec_Dose_week_frquency_txt === null)
        ) {
          q37_Tec_Dose_week_frquency_txt_Empty = true;
        }

        //invalid Number
        if (
          item.q37_Tec_Dose_week_frquencyRd == 1 &&
          (item.q37_Tec_Dose_week_frquency_txt !== "" ||
            item.q37_Tec_Dose_week_frquency_txt !== null)
        ) {
          const checkValue = isValidNumber(item.q37_Tec_Dose_week_frquency_txt);
          if (item.q37_Tec_Dose_week_frquencyRd_Empty == false && !checkValue) {
            q37_Tec_Dose_week_frquency_txt_Empty = true;
          }
        }

        let Q39TechEmptyHard = false;
        let Q39techReasonHard = false;
        let Q39techGranduloEmptyHard = false;
        let Q39techSteroidsEmptyHard = false;

        // let betamethasone_tech_txtEmpty = false;
        // let dexamethasone_tech_txtEmpty = false;
        // let hydrocortisone_tech_txtEmpty = false;
        // let methylprednisolone_txtEmpty = false;
        // let prednisolone_tech_txtEmpty = false;
        // let prednisone_tech_txtEmpty = false;
        // let triamcinolone_tech_txtEmpty = false;
        let name_dose_steroidsHardEmpty = false;
        let steroids_tech_txtEmpty = false;

        if (Q39TechConditionMet) {
          if (
            item.tocilizumab_tech === false &&
            item.Granulocyte === false &&
            item.SteroidsTech === false &&
            item.noneoftheabove_tech !== 99
          ) {
            Q39TechEmptyHard = true;
          }

          if (item.tocilizumab_tech === true) {
            if (
              item.cytokin_tech === false &&
              item.crs_tech === false &&
              item.neurotoxicity_tech === false &&
              item.tmt_neurotoxicity_tech === false &&
              item.tmt_tech_unknown === false
            ) {
              Q39techReasonHard = true;
            }
          }

          if (item.Granulocyte === true) {
            if (
              item.prophylactic_use_tech === false &&
              item.oth_use_tech === false &&
              item.unknown_tech === null
            ) {
              Q39techGranduloEmptyHard = true;
            }
          }

          if (item.SteroidsTech === true) {
            if (
              item.cytokin_tech_steroids === false &&
              item.crs_tech_steroids === false &&
              item.neurotoxicity_tech_steroids === false &&
              item.tmt_neurotoxicity_tech_steroids === false &&
              item.tmt_steroids_tech_unknown === false &&
              item.steroid_pre_tmt_tech === false
            ) {
              Q39techSteroidsEmptyHard = true;
            }
          }

          if (item.SteroidsTech === true) {
            if (
              item.betamethasone_tech === false &&
              item.dexamethasone_tech === false &&
              item.hydrocortisone_tech === false &&
              item.methylprednisolone === false &&
              item.prednisolone_tech === false &&
              item.prednisone_tech === false &&
              item.triamcinolone_tech === false &&
              item.dose_name_tech_unknown === false
            ) {
              name_dose_steroidsHardEmpty = true;
            }

            if (
              (item.betamethasone_tech === true &&
                item.betamethasone_techNum === "") ||
              (item.dexamethasone_tech === true &&
                item.dexamethasone_techNum === "") ||
              (item.hydrocortisone_tech === true &&
                item.hydrocortisone_techNum === "") ||
              (item.methylprednisolone === true &&
                item.methylprednisoloneNum === "") ||
              (item.prednisolone_tech === true &&
                item.prednisolone_techNum === "") ||
              (item.prednisone_tech === true &&
                item.prednisone_techNum === "") ||
              (item.triamcinolone_tech === true &&
                item.triamcinolone_techNum === "")
            ) {
              steroids_tech_txtEmpty = true;
            }

            // if (
            //   item.betamethasone_tech === true &&
            //   item.betamethasone_techNum === ""
            // ) {
            //   betamethasone_tech_txtEmpty = true;
            // }

            // if (
            //   item.dexamethasone_tech === true &&
            //   item.dexamethasone_techNum === ""
            // ) {
            //   dexamethasone_tech_txtEmpty = true;
            // }

            // if (
            //   item.hydrocortisone_tech === true &&
            //   item.hydrocortisone_techNum === ""
            // ) {
            //   hydrocortisone_tech_txtEmpty = true;
            // }

            // if (
            //   item.methylprednisolone === true &&
            //   item.methylprednisoloneNum === ""
            // ) {
            //   methylprednisolone_txtEmpty = true;
            // }

            // if (
            //   item.prednisolone_tech === true &&
            //   item.prednisolone_techNum === ""
            // ) {
            //   prednisolone_tech_txtEmpty = true;
            // }

            // if (
            //   item.prednisone_tech === true &&
            //   item.prednisone_techNum === ""
            // ) {
            //   prednisone_tech_txtEmpty = true;
            // }

            // if (
            //   item.triamcinolone_tech === true &&
            //   item.triamcinolone_techNum === ""
            // ) {
            //   triamcinolone_tech_txtEmpty = true;
            // }
          }
        }

        if (
          Q40DosingFreqEmptyHard ||
          Q40DosingFreqTxtEmptyHard ||
          Q40IgGEmptyHard ||
          Q40ReasonEmptyHard ||
          Q40Init_RdHard ||
          Q40Init_dtHard ||
          q37TechvayliEmpty ||
          Q39TechEmptyHard ||
          Q39techReasonHard ||
          Q39techGranduloEmptyHard ||
          Q39techSteroidsEmptyHard ||
          name_dose_steroidsHardEmpty ||
          // betamethasone_tech_txtEmpty ||
          // dexamethasone_tech_txtEmpty ||
          // hydrocortisone_tech_txtEmpty ||
          // prednisolone_tech_txtEmpty ||
          // triamcinolone_tech_txtEmpty ||
          // prednisone_tech_txtEmpty ||
          steroids_tech_txtEmpty ||
          q37_Tec_Dose_week_frquencyRd_Empty ||
          q37_Tec_Dose_week_frquency_txt_Empty
        ) {
          isValid = false;
        }
        return {
          ...item,
          Q40DosingFreqEmptyHard,
          Q40DosingFreqTxtEmptyHard,
          Q40IgGEmptyHard,
          Q40ReasonEmptyHard,
          Q40Init_RdHard,
          Q40Init_dtHard,
          q37TechvayliEmpty,
          Q39TechEmptyHard,
          Q39techReasonHard,
          Q39techGranduloEmptyHard,
          Q39techSteroidsEmptyHard,
          name_dose_steroidsHardEmpty,
          // betamethasone_tech_txtEmpty,
          // dexamethasone_tech_txtEmpty,
          // hydrocortisone_tech_txtEmpty,
          // prednisolone_tech_txtEmpty,
          // prednisone_tech_txtEmpty,
          // triamcinolone_tech_txtEmpty,
          steroids_tech_txtEmpty,
          q37_Tec_Dose_week_frquencyRd_Empty,
          q37_Tec_Dose_week_frquency_txt_Empty,
        };
      });
      setQ37TechDetails(updatedArray);
    }

    //37tal
    if (isTalveySelected) {
      if (Q37TalDetails && Q37TalDetails.length > 0) {
        const updatedArray = Q37TalDetails.map((item) => {
          let Q39TalEmptyHard = false;

          let Q39talSteroidsEmptyHard = false;
          let name_dose_steroids_talHardEmpty = false;
          let steroids_tal_txtEmpty = false;

          let Q39talReasonHard = false;

          let q37TalveyEmpty = false;
          if (item.Q37TalveyRd === null) {
            q37TalveyEmpty = true;
          }

          if (q37TalveyEmpty) {
            isValid = false;
          }

          debugger;
          let q37_Tal_Dose_week_frquencyRd_Empty = false;
          if (item.q37_Tal_Dose_week_frquencyRd == null) {
            q37_Tal_Dose_week_frquencyRd_Empty = true;
          }

          let q37_Tal_Dose_week_frquency_txt_Empty = false;
          if (
            item.q37_Tal_Dose_week_frquencyRd == 1 &&
            (item.q37_Tal_Dose_week_frquency_txt === "" ||
              item.q37_Tal_Dose_week_frquency_txt === null)
          ) {
            q37_Tal_Dose_week_frquency_txt_Empty = true;
          }

          if (
            item.q37_Tal_Dose_week_frquencyRd == 1 &&
            (item.q37_Tal_Dose_week_frquency_txt !== "" ||
              item.q37_Tal_Dose_week_frquency_txt !== null)
          ) {
            const checkValue = isValidNumber(
              item.q37_Tal_Dose_week_frquency_txt
            );
            if (
              item.q37_Tal_Dose_week_frquencyRd_Empty == false &&
              !checkValue
            ) {
              q37_Tal_Dose_week_frquency_txt_Empty = true;
            }
          }

          let q37_Tal_Dosage_Rd_Empty = false;
          if (item.q37_Tal_Dosage_Rd == null) {
            q37_Tal_Dosage_Rd_Empty = true;
          }

          let q37_Tal_Dosage_OtherTxt_Empty = false;
          if (
            item.q37_Tal_Dosage_Rd == 3 &&
            (item.q37_Tal_Dosage_OtherTxt == "" ||
              item.q37_Tal_Dosage_OtherTxt == null)
          ) {
            q37_Tal_Dosage_OtherTxt_Empty = true;
          }

          //39tal
          if (Q39TalConditionMet) {
            if (
              item.tocilizumab_tal === false &&
              item.Steroids === false &&
              item.noneoftheabove_tal !== 99
            ) {
              Q39TalEmptyHard = true;
            }

            if (item.tocilizumab_tal === true) {
              if (
                item.cytokin_tal === false &&
                item.crs_tal === false &&
                item.neurotoxicity_tal === false &&
                item.tmt_neurotoxicity_tal === false &&
                item.tmt_talvey_unknown === false
              ) {
                Q39talReasonHard = true;
              }
            }

            if (item.Steroids === true) {
              if (
                item.cytokin_tal_steroids === false &&
                item.crs_tal_steroids === false &&
                item.neurotoxicity_tal_steroids === false &&
                item.tmt_neurotoxicity_tal_steroids === false &&
                item.tmt_tal_unknown === false &&
                item.steroid_pre_tmt_tal === false
              ) {
                Q39talSteroidsEmptyHard = true;
              }
            }

            if (item.Steroids === true) {
              if (
                item.betamethasone_tal === false &&
                item.dexamethasone_tal === false &&
                item.hydrocortisone_tal === false &&
                item.methylprednisolone_tal === false &&
                item.prednisolone_tal === false &&
                item.prednisone_tal === false &&
                item.triamcinolone_tal === false &&
                item.dose_name_tal_unknown === false
              ) {
                name_dose_steroids_talHardEmpty = true;
              }

              if (
                (item.betamethasone_tal === true &&
                  item.betamethasone_talNum === "") ||
                (item.dexamethasone_tal === true &&
                  item.dexamethasone_talNum === "") ||
                (item.hydrocortisone_tal === true &&
                  item.hydrocortisone_talNum === "") ||
                (item.methylprednisolone_tal === true &&
                  item.methylprednisolone_talNum === "") ||
                (item.prednisolone_tal === true &&
                  item.prednisolone_talNum === "") ||
                (item.prednisone_tal === true &&
                  item.prednisone_talNum === "") ||
                (item.triamcinolone_tal === true &&
                  item.triamcinolone_talNum === "")
              ) {
                steroids_tal_txtEmpty = true;
              }
            }
          }

          if (
            Q39TalEmptyHard ||
            Q39talReasonHard ||
            Q39talSteroidsEmptyHard ||
            name_dose_steroids_talHardEmpty ||
            steroids_tal_txtEmpty ||
            q37TalveyEmpty ||
            q37_Tal_Dose_week_frquencyRd_Empty ||
            q37_Tal_Dose_week_frquency_txt_Empty ||
            q37_Tal_Dosage_Rd_Empty ||
            q37_Tal_Dosage_OtherTxt_Empty
          ) {
            isValid = false;
          }

          return {
            ...item,
            Q39TalEmptyHard,
            Q39talReasonHard,
            Q39talSteroidsEmptyHard,
            name_dose_steroids_talHardEmpty,
            steroids_tal_txtEmpty,
            q37TalveyEmpty,
            q37_Tal_Dosage_OtherTxt_Empty,
            q37_Tal_Dosage_Rd_Empty,
            q37_Tal_Dose_week_frquency_txt_Empty,
            q37_Tal_Dose_week_frquencyRd_Empty,
          };
        });
        setQ37TalDetails(updatedArray);
      }
    }

    //38tech

    if (
      isTechvayliSelected &&
      Q37TechDetails[0].Q37TechvayliRd === 1 &&
      Q38TechDetails &&
      Q38TechDetails.length > 0
    ) {
      const updatedArray = Q38TechDetails.map((item, index) => {
        let Q38TechEmptyHard = false;
        if (item.Q38TechvayliRd === null) {
          Q38TechEmptyHard = true;
        }

        let Q38DateEmpty = false;
        if (item.Q38TechvayliRd === 1 && item.dt_dosefreq_tech_dt === "") {
          Q38DateEmpty = true;
        }

        let Q38TechDateInvalid = false;
        if (
          item.Q38TechvayliRd === 1 &&
          item.dt_dosefreq_tech_dt !== "" &&
          techIndexDate != "" &&
          tecvayliDateRRMM != "[Bispecifics/CAR-T end of follow up for Tec]"
        ) {
          Q38TechDateInvalid = !CheckDateValidation(
            techIndexDate,
            item.dt_dosefreq_tech_dt,
            tecvayliDateRRMM
          );
        }

        // let Q38TechDateValidationcheck1 =false;

        //Q38Tech New Column Validation

        let q38TechvayliEmpty = false;
        if (item.Q38TechvayliRd === null) {
          q38TechvayliEmpty = true;
        }

        let q38_Tec_Dose_week_frquencyRd_Empty = false;
        if (item.q38_Tec_Dose_week_frquencyRd == null) {
          q38_Tec_Dose_week_frquencyRd_Empty = true;
        }

        let q38_Tec_Dose_week_frquency_txt_Empty = false;
        if (
          item.q38_Tec_Dose_week_frquencyRd == 1 &&
          (item.q38_Tec_Dose_week_frquency_txt === "" ||
            item.q38_Tec_Dose_week_frquency_txt === null)
        ) {
          q38_Tec_Dose_week_frquency_txt_Empty = true;
        }

        //invalid Number
        if (
          item.q38_Tec_Dose_week_frquencyRd == 1 &&
          (item.q38_Tec_Dose_week_frquency_txt !== "" ||
            item.q38_Tec_Dose_week_frquency_txt !== null)
        ) {
          const checkValue = isValidNumber(item.q38_Tec_Dose_week_frquency_txt);
          if (item.q38_Tec_Dose_week_frquencyRd_Empty == false && !checkValue) {
            q38_Tec_Dose_week_frquency_txt_Empty = true;
          }
        }

        if (
          Q38TechEmptyHard ||
          Q38DateEmpty ||
          Q38TechDateInvalid ||
          q38TechvayliEmpty ||
          q38_Tec_Dose_week_frquencyRd_Empty ||
          q38_Tec_Dose_week_frquency_txt_Empty
        ) {
          isValid = false;
        }

        return {
          ...item,

          Q38TechEmptyHard,
          Q38DateEmpty,
          Q38TechDateInvalid,
          q38TechvayliEmpty,
          q38_Tec_Dose_week_frquencyRd_Empty,
          q38_Tec_Dose_week_frquency_txt_Empty,
        };
      });

      setQ38TechDetails(updatedArray);
    }

    //38 tal
    if (
      isTalveySelected &&
      Q37TalDetails[0].Q37TalveyRd === 1 &&
      Q38TalDetails &&
      Q38TalDetails.length > 0
    ) {
      const updatedArray = Q38TalDetails.map((item, index) => {
        let Q38TalEmptyHard = false;
        if (item.Q38TalveyRd === null) {
          Q38TalEmptyHard = true;
        }

        let Q38TalDateEmpty = false;
        if (item.Q38TalveyRd === 1 && item.dt_dosefreq_tal_dt === "") {
          Q38TalDateEmpty = true;
        }

        let Q38TalDateInvalid = false;
        if (
          item.Q38TalveyRd === 1 &&
          item.dt_dosefreq_tal_dt !== "" &&
          talIndexDate != "" &&
          talveyDateRRMM != "[Bispecifics/CAR-T end of follow up for Tal]"
        ) {
          Q38TalDateInvalid = !CheckDateValidation(
            talIndexDate,
            item.dt_dosefreq_tal_dt,
            talveyDateRRMM
          );
        }

        let q38_Tal_Dose_week_frquencyRd_Empty = false;
        if (item.q38_Tal_Dose_week_frquencyRd == null) {
          q38_Tal_Dose_week_frquencyRd_Empty = true;
        }

        let q38_Tal_Dose_week_frquency_txt_Empty = false;
        if (
          item.q38_Tal_Dose_week_frquencyRd == 1 &&
          (item.q38_Tal_Dose_week_frquency_txt === "" ||
            item.q38_Tal_Dose_week_frquency_txt === null)
        ) {
          q38_Tal_Dose_week_frquency_txt_Empty = true;
        }

        if (
          item.q38_Tal_Dose_week_frquencyRd == 1 &&
          (item.q38_Tal_Dose_week_frquency_txt !== "" ||
            item.q38_Tal_Dose_week_frquency_txt !== null)
        ) {
          const checkValue = isValidNumber(item.q38_Tal_Dose_week_frquency_txt);
          if (item.q38_Tal_Dose_week_frquencyRd_Empty == false && !checkValue) {
            q38_Tal_Dose_week_frquency_txt_Empty = true;
          }
        }

        let q38_Tal_Dosage_Rd_Empty = false;
        if (item.q38_Tal_Dosage_Rd == null) {
          q38_Tal_Dosage_Rd_Empty = true;
        }

        let q38_Tal_Dosage_OtherTxt_Empty = false;
        if (
          item.q38_Tal_Dosage_Rd == 3 &&
          (item.q38_Tal_Dosage_OtherTxt == "" ||
            item.q38_Tal_Dosage_OtherTxt == null)
        ) {
          q38_Tal_Dosage_OtherTxt_Empty = true;
        }

        if (
          Q38TalEmptyHard ||
          Q38TalDateEmpty ||
          Q38TalDateInvalid ||
          q38_Tal_Dosage_OtherTxt_Empty ||
          q38_Tal_Dosage_Rd_Empty ||
          q38_Tal_Dose_week_frquency_txt_Empty ||
          q38_Tal_Dose_week_frquencyRd_Empty
        ) {
          isValid = false;
        }

        return {
          ...item,

          Q38TalEmptyHard,
          Q38TalDateEmpty,
          Q38TalDateInvalid,
          q38_Tal_Dosage_Rd_Empty,
          q38_Tal_Dose_week_frquency_txt_Empty,
          q38_Tal_Dose_week_frquencyRd_Empty,
          q38_Tal_Dosage_OtherTxt_Empty,
        };
      });

      setQ38TalDetails(updatedArray);
    }

    return isValid;
  };

  const sidebar = document.getElementById("mySidebar");
  const main = document.getElementById("main");

  const toggleNav = () => {
    if (isSideMenuActive === true) {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
      setIsSideMenuActive(false);
    } else if (isSideMenuActive === false) {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
      setIsSideMenuActive(true);
    }
  };

  useEffect(() => {
    const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");

    if (isSideMenuActive === true) {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
    } else {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
    }
  });


  const showTalDate =()=>{
        debugger
        let varTalIndexDate = "";
    if(talIndexDate === null || "")
    {
            varTalIndexDate = "[Bispecifics/CAR-T index date for Tec or Tal]";
        }
        else {
            varTalIndexDate = talIndexDate
        }

        return varTalIndexDate;
    }


  const showTechDate =()=>{
        debugger
        let varTechIndexDate = "";
    if(techIndexDate === null || "")
    {
            varTechIndexDate = "[Bispecifics/CAR-T index date for Tec or Tal]";
        }
        else {
            varTechIndexDate = talIndexDate
        }

        return varTechIndexDate;
    }



  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div>
          <SideBarMenu activePageId={SideMenuItems.TTCharacteristics} />
          <div id="main" className="col-pad-left">
            <>
              <button
                className={isSideMenuActive ? "openbtn" : "openbtn active"}
                // onclick="toggleNav()"
                onClick={() => toggleNav()}
              >
                <i
                  id="arrowNavigation"
                  class="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>
              </button>
            </>

            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>TECLISTAMAB AND TALQUETAMAB CHARACTERISTICS </h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}

              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div className="question-answer">
                    {/* {cohortTreatmentModelListt.cohortTreatmentID == 9 || 10 && ( */}

                    {(isTalveySelected || isTechvayliSelected) && (
                      <>
                        <div>
                          <div className="question question-weight">
                            <span>Q36.</span>
                            {/* <span className="quest-text-pad">
                          Provide dosing information for each administration of
                          [Tec=” TECVAYLI® (teclistamab-cqyv)” and/or Tal =
                          “TALVEYTM (talquetamab-tgvs)”] from the initiation of
                          treatment on [Bispecifics/CAR-T index date for Tec or
                          Tal] until [Bispecifics/CAR-T end of follow up for Tec
                          or Tal].
                        </span> */}
                            <span className="quest-text-pad">
                              Provide dosing information for each administration
                              of
                              <>
                                {" "}
                                {isTechvayliSelected && isTalveySelected ? (
                                  <>
                                    <span
                                      style={{
                                        color: PageDesignConstants.fontColor,
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      TECVAYLI® (teclistamab-cqyv)
                                    </span>{" "}
                                    from the initiation of treatment on
                                    <span
                                      style={{
                                        color: PageDesignConstants.fontColor,
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {" "}
                                      {techIndexDate} until {tecvayliDateRRMM}{" "}
                                    </span>
                                    <span
                                      style={{
                                        color: PageDesignConstants.fontColor,
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      and TALVEY
                                      <sup className="supscript">TM</sup>{" "}
                                      (talquetamab-tgvs)
                                    </span>{" "}
                                    from the initiation of treatment on
                                    <span
                                      style={{
                                        color: PageDesignConstants.fontColor,
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {" "}
                                      {talIndexDate} until {talveyDateRRMM}{" "}
                                    </span>
                                  </>
                                ) : isTechvayliSelected ? (
                                  <>
                                    <span
                                      style={{
                                        color: PageDesignConstants.fontColor,
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      TECVAYLI® (teclistamab-cqyv)
                                    </span>{" "}
                                    from the initiation of treatment on
                                    <span
                                      style={{
                                        color: PageDesignConstants.fontColor,
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {" "}
                                      {/* {techIndexDate} until {tecvayliDateRRMM}{" "} */}
                                     {showTechDate()}  until {tecvayliDateRRMM}{" "}
                                    </span>
                                  </>
                                ) : isTalveySelected ? (
                                  <>
                                    <span
                                      style={{
                                        color: PageDesignConstants.fontColor,
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      TALVEY<sup className="supscript">TM</sup>{" "}
                                      (talquetamab-tgvs)
                                    </span>{" "}
                                    from the initiation of treatment on
                                    <span
                                      style={{
                                        color: PageDesignConstants.fontColor,
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {" "}
                                      {/* {talIndexDate } until {talveyDateRRMM}{" "} */}
                                      {showTalDate()}  until {talveyDateRRMM}{" "}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}{" "}
                              </>
                              <b>
                                Please add additional rows to enter the date of
                                each dose administration.
                              </b>
                              <br />
                              <br />
                              <b>
                                For all Step-up or 1st treatment dose
                                administrations where patients were hospitalized
                                (i.e., inpatient site of care)
                              </b>
                              , please also provide the date of inpatient
                              admission and discharge. If a single
                              hospitalization covered multiple dose
                              administrations, please enter the same admission
                              and discharge dates of that hospitalization for
                              each of the corresponding administrations.
                            </span>
                          </div>
                          <ErrorField
                            show={indexDateRRMM_techHard}
                            message={
                              TTCharacteristics_MSGS.indexDateRRMMNotMatchingTech
                            }
                            style={{ paddingLeft: "0px" }}
                          />
                          <ErrorField
                            show={indexDateRRMM_talHard}
                            message={
                              TTCharacteristics_MSGS.indexDateRRMMNotMatchingTal
                            }
                            style={{ paddingLeft: "0px" }}
                          />
                          {isTechvayliSelected && (
                            <>
                              <div className="dependent-section">
                                {techvayliDetails &&
                                  techvayliDetails.length > 0 &&
                                  techvayliDetails.map((data, idx) => (
                                    <div className="loop-section-ul">
                                      <div className="loop-head">
                                        <span
                                          className="question-weight"
                                          style={{ float: "inline-start" }}
                                        >
                                          {GetOrdinal(idx + 1)} TECVAYLI®
                                        </span>
                                        {idx != 0 && (
                                          <div
                                            className="delete-btn-div"
                                            style={{ float: "inline-end" }}
                                          >
                                            <img
                                              width="16px"
                                              src="../Assets/images/Icon-material-delete.png"
                                              alt=""
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                DeleteTechvayliRow(idx)
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>

                                      {/* TECVAYLI® */}
                                      <div className="question-bot-sapce">
                                        <div className="sub-question">
                                          <table
                                            className="date-picker-ttchar"
                                            style={{
                                              border: "1px solid black",
                                              borderCollapse: "collapse",
                                              width: "100%",
                                            }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Treatment
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Date of dose administration
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {" "}
                                                  <span
                                                    data-tooltip-id="encounterTip"
                                                    className="modal-popup"
                                                    onClick={() =>
                                                      setShowHideHyperlinkModalQ36Tecvayli(
                                                        true
                                                      )
                                                    }
                                                  >
                                                    Dosing phase
                                                  </span>
                                                  {showHideHyperlinkModalQ36Tecvayli && (
                                                    <InfoPopUp
                                                      description={
                                                        hyperlinkTextQ36Tecvayli
                                                      }
                                                      OkClick={() =>
                                                        setShowHideHyperlinkModalQ36Tecvayli(
                                                          false
                                                        )
                                                      }
                                                    />
                                                  )}{" "}
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Site of care
                                                </th>
                                                {/* {data.Q36TechConditionMet && (
                                                <>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Date of inpatient admission
                                                    for this administration
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Date of inpatient discharge
                                                    for this administration
                                                  </th>
                                                </>
                                              )} */}
                                              </tr>
                                            </thead>

                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <span className="quest-text-pad">
                                                    TECVAYLI® (teclistamab-cqyv)
                                                  </span>
                                                </td>

                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <input
                                                        type="radio"
                                                        id={idx}
                                                        name={
                                                          "dose_administration_tech" +
                                                          idx
                                                        }
                                                        value={1}
                                                        checked={
                                                          data.dose_administration_tech ===
                                                          1
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                          marginLeft: "-5px",
                                                        }}
                                                      >
                                                        <DateControl
                                                          ctrlId={
                                                            "dose_administration_tech_dt-" +
                                                            idx
                                                          }
                                                          HandleDateChange={
                                                            HandleRepeaterDateChange
                                                          }
                                                          date={
                                                            data.dose_administration_tech_dt
                                                          }
                                                        />
                                                      </span>
                                                    </div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "dose_administration_tech" +
                                                            idx
                                                          }
                                                          value={99}
                                                          checked={
                                                            data.dose_administration_tech ===
                                                            99
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />

                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Date of dose
                                                          administration unknown
                                                        </span>
                                                      </label>
                                                    </div>

                                                    <ErrorField
                                                      show={
                                                        data.dose_administration_techHard
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.administrationEmpty
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                    <ErrorField
                                                      show={
                                                        data.dose_administration_techHard_dt
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.administrationEmptyDate
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />

                                                    <ErrorField
                                                      show={
                                                        data.dt_tech_greater_prevCheck
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.Q36DateGreaterThanPrevious
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={
                                                            "dosing_phase_tech" +
                                                            idx
                                                          }
                                                          value={1}
                                                          checked={
                                                            data.dosing_phase_tech ===
                                                            1
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Step-up dose 1{" "}
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "dosing_phase_tech" +
                                                            idx
                                                          }
                                                          value={2}
                                                          checked={
                                                            data.dosing_phase_tech ===
                                                            2
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />

                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Step-up dose 2
                                                        </span>
                                                      </label>
                                                    </div>

                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "dosing_phase_tech" +
                                                            idx
                                                          }
                                                          value={3}
                                                          checked={
                                                            data.dosing_phase_tech ===
                                                            3
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />

                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          1<sup>st</sup>{" "}
                                                          treatment dose
                                                        </span>
                                                      </label>
                                                    </div>

                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "dosing_phase_tech" +
                                                            idx
                                                          }
                                                          value={4}
                                                          checked={
                                                            data.dosing_phase_tech ===
                                                            4
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />

                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Subsequent treatment
                                                          doses
                                                        </span>
                                                      </label>
                                                    </div>

                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "dosing_phase_tech" +
                                                            idx
                                                          }
                                                          value={5}
                                                          checked={
                                                            data.dosing_phase_tech ===
                                                            5
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />

                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Other
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <ErrorField
                                                      show={
                                                        data.dosing_phase_techHard
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.dosingphaseEmpty
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                  </div>
                                                </td>

                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={
                                                            "siteofcare_tech" +
                                                            idx
                                                          }
                                                          value={1}
                                                          checked={
                                                            data.siteofcare_tech ===
                                                            1
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Inpatient{" "}
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "siteofcare_tech" +
                                                            idx
                                                          }
                                                          value={2}
                                                          checked={
                                                            data.siteofcare_tech ===
                                                            2
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />

                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Outpatient
                                                        </span>
                                                      </label>
                                                    </div>

                                                    {/* <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={idx}
                                                        name={
                                                          "siteofcare_tech" +
                                                          idx
                                                        }
                                                        value={3}
                                                        checked={
                                                          data.siteofcare_tech ===
                                                          3
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />

                                                      <span
                                                        // className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        Both inpatient and
                                                        outpatient
                                                      </span>
                                                    </label>
                                                  </div> */}
                                                    <ErrorField
                                                      show={
                                                        data.siteofcare_techHard
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.siteofcareEmpty
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                  </div>
                                                </td>
                                                {/* {data.Q36TechConditionMet && (
                                                <>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    <div>
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={
                                                            "dt_admission_tech" +
                                                            idx
                                                          }
                                                          value={1}
                                                          checked={
                                                            data.dt_admission_tech ===
                                                            1
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                            marginLeft: "-5px",
                                                          }}
                                                        >
                                                          <DateControl
                                                            ctrlId={
                                                              "dt_admission_tech_dt-" +
                                                              idx
                                                            }
                                                            HandleDateChange={
                                                              HandleRepeaterDateChange
                                                            }
                                                            date={
                                                              data.dt_admission_tech_dt
                                                            }
                                                          />
                                                        </span>
                                                      </div>
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="option-fbox">
                                                          <input
                                                            type="radio"
                                                            style={{
                                                              marginTop: "6px",
                                                            }}
                                                            id={idx}
                                                            name={
                                                              "dt_admission_tech" +
                                                              idx
                                                            }
                                                            value={99}
                                                            checked={
                                                              data.dt_admission_tech ===
                                                              99
                                                            }
                                                            onChange={
                                                              HandleRepeaterFieldChange
                                                            }
                                                          />

                                                          <span
                                                            // className="radio-text-padding"
                                                            style={{
                                                              paddingLeft:
                                                                "10px",
                                                            }}
                                                          >
                                                            Date of admission
                                                            unknown
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ErrorField
                                                        show={
                                                          data.dt_admission_techHard
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.admissionEmpty
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />

                                                      <ErrorField
                                                        show={
                                                          data.dt_admission_techHard_dt
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.admissionEmptyDate
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />
                                                      <ErrorField
                                                        show={
                                                          data.dt_admission_techHard_dt_check
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.admissionDateCheck
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    <div>
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={
                                                            "dt_discharge_tech" +
                                                            idx
                                                          }
                                                          value={1}
                                                          checked={
                                                            data.dt_discharge_tech ===
                                                            1
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                            marginLeft: "-5px",
                                                          }}
                                                        >
                                                          <DateControl
                                                            ctrlId={
                                                              "dt_discharge_tech_dt-" +
                                                              idx
                                                            }
                                                            HandleDateChange={
                                                              HandleRepeaterDateChange
                                                            }
                                                            date={
                                                              data.dt_discharge_tech_dt
                                                            }
                                                          />
                                                        </span>
                                                      </div>
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="option-fbox">
                                                          <input
                                                            type="radio"
                                                            style={{
                                                              marginTop: "6px",
                                                            }}
                                                            id={idx}
                                                            name={
                                                              "dt_discharge_tech" +
                                                              idx
                                                            }
                                                            value={99}
                                                            checked={
                                                              data.dt_discharge_tech ===
                                                              99
                                                            }
                                                            onChange={
                                                              HandleRepeaterFieldChange
                                                            }
                                                          />

                                                          <span
                                                            // className="radio-text-padding"
                                                            style={{
                                                              paddingLeft:
                                                                "10px",
                                                            }}
                                                          >
                                                            Date of discharge
                                                            unknown
                                                          </span>
                                                        </label>
                                                      </div>

                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="option-fbox">
                                                          <input
                                                            type="radio"
                                                            style={{
                                                              marginTop: "6px",
                                                            }}
                                                            id={idx}
                                                            name={
                                                              "dt_discharge_tech" +
                                                              idx
                                                            }
                                                            value={3}
                                                            checked={
                                                              data.dt_discharge_tech ===
                                                              3
                                                            }
                                                            onChange={
                                                              HandleRepeaterFieldChange
                                                            }
                                                          />

                                                          <span
                                                            // className="radio-text-padding"
                                                            style={{
                                                              paddingLeft:
                                                                "10px",
                                                            }}
                                                          >
                                                            Patient is still
                                                            hospitalized
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ErrorField
                                                        show={
                                                          data.dt_discharge_techHard
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.dischargeEmpty
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />
                                                      <ErrorField
                                                        show={
                                                          data.dt_discharge_techHard_dt
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.dischargeEmptyDate
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />
                                                      <ErrorField
                                                        show={
                                                          data.dt_discharge_techHard_dt_check
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.dischargeDateCheck
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />
                                                    </div>
                                                  </td>
                                                </>
                                              )} */}
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                {techvayliDetails &&
                                  techvayliDetails.length > 0 &&
                                  techvayliDetails.length < 550 && (
                                    <div className="sub-question-bot-sapce-ul mt-2">
                                      <div
                                        className="answer-list-text"
                                        onClick={AddTechvayliRow}
                                      >
                                        <a>
                                          <img
                                            width="18px"
                                            src="../Assets/images/plus.png"
                                            alt=""
                                          />
                                        </a>
                                        <a className="radio-text-padding">
                                          Click here to add another dose
                                          administration of Tecvayli
                                        </a>
                                      </div>
                                    </div>
                                  )}
                              </div>
                              {/* 36.1 Tecvayli start */}
                              <div>
                                <div className="dependent-section sub-question marginleft15">
                                  <div
                                    className="question question-weight"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <span>Q36.1.</span>
                                    <span className="quest-text-pad">
                                      Please list all hospitalizations that
                                      occurred during the step-up dosing phase.
                                      Please specify the date of admission, date
                                      of discharge, and reason for
                                      hospitalization.{" "}
                                    </span>
                                  </div>
                                  {tecHospDetails &&
                                    tecHospDetails.length > 0 &&
                                    tecHospDetails.map((data, idx) => (
                                      <div className="loop-section-ul">
                                        <div className="loop-head">
                                          <span
                                            className="question-weight"
                                            style={{ float: "inline-start" }}
                                          >
                                            {GetOrdinal(idx + 1)} TECVAYLI®{" "}
                                            Hospitalization
                                          </span>

                                          {idx != 0 && (
                                            <div
                                              className="delete-btn-div"
                                              style={{ float: "inline-end" }}
                                            >
                                              <img
                                                width="16px"
                                                src="../Assets/images/Icon-material-delete.png"
                                                alt=""
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  DeleteTecHospRow(idx)
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>

                                        {/* TECVAYLI® */}
                                        <div className="question-bot-sapce">
                                          <div className="sub-question">
                                            <table
                                              style={{
                                                border: "1px solid black",
                                                borderCollapse: "collapse",
                                                width: "100%",
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                      textAlign: "center",
                                                      // width: "25%"
                                                    }}
                                                  >
                                                    Treatment
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                      textAlign: "center",
                                                      // width: "20%"
                                                    }}
                                                  >
                                                    Date of admission
                                                  </th>
                                                  {data.dt_admission != null &&
                                                    data.dt_admission !== 2 && (
                                                      <>
                                                        <th
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                            textAlign: "center",
                                                            // width: "20%"
                                                          }}
                                                        >
                                                          Date of discharge
                                                        </th>
                                                        <th
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                            textAlign: "center",
                                                            // width: "35%"
                                                          }}
                                                        >
                                                          Reason of
                                                          hospitalization
                                                        </th>
                                                      </>
                                                    )}
                                                </tr>
                                              </thead>

                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    <span className="quest-text-pad">
                                                      {GetOrdinal(idx + 1)}{" "}
                                                      TECVAYLI®
                                                    </span>
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    <div>
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={
                                                            "dt_admission_tec" +
                                                            idx
                                                          }
                                                          value={1}
                                                          checked={
                                                            data.dt_admission ===
                                                            1
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                            marginLeft: "-5px",
                                                          }}
                                                        >
                                                          <DateControl
                                                            ctrlId={
                                                              "dt_admission_dt_tec-" +
                                                              idx
                                                            }
                                                            HandleDateChange={
                                                              HandleRepeaterDateChange
                                                            }
                                                            date={
                                                              data.dt_admission_dt
                                                            }
                                                          />
                                                        </span>
                                                      </div>
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="option-fbox">
                                                          <input
                                                            type="radio"
                                                            style={{
                                                              marginTop: "6px",
                                                            }}
                                                            id={idx}
                                                            name={
                                                              "dt_admission_tec" +
                                                              idx
                                                            }
                                                            value={99}
                                                            checked={
                                                              data.dt_admission ===
                                                              99
                                                            }
                                                            onChange={
                                                              HandleRepeaterFieldChange
                                                            }
                                                          />
                                                          <span
                                                            className="radio-text-padding"
                                                            style={{
                                                              paddingLeft:
                                                                "10px",
                                                            }}
                                                          >
                                                            Date of admission
                                                            unknown
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="option-fbox">
                                                          <input
                                                            type="radio"
                                                            style={{
                                                              marginTop: "6px",
                                                            }}
                                                            id={idx}
                                                            name={
                                                              "dt_admission_tec" +
                                                              idx
                                                            }
                                                            value={2}
                                                            checked={
                                                              data.dt_admission ===
                                                              2
                                                            }
                                                            onChange={
                                                              HandleRepeaterFieldChange
                                                            }
                                                          />
                                                          <span
                                                            className="radio-text-padding"
                                                            style={{
                                                              paddingLeft:
                                                                "10px",
                                                            }}
                                                          >
                                                            Patient had no
                                                            hospitalizations
                                                            during the step-up
                                                            dosing phase
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ErrorField
                                                        show={
                                                          data.dt_admission_Hard
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.admissionEmpty_tal
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />

                                                      <ErrorField
                                                        show={
                                                          data.dt_admission_Hard_dt
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.admissionEmptyDate
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />
                                                      {/* <ErrorField
                                show={
                                    data.dt_admission_Hard_dt_check
                                }
                                message={
                                    TTCharacteristics_MSGS.admissionDateCheck
                                }
                                style={{
                                    paddingLeft: "0px",
                                }}
                                /> */}
                                                    </div>
                                                  </td>
                                                  {data.dt_admission != null &&
                                                    data.dt_admission !== 2 && (
                                                      <>
                                                        <td
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                          }}
                                                        >
                                                          <div>
                                                            <div
                                                              className="option-without-input"
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <input
                                                                type="radio"
                                                                id={idx}
                                                                name={
                                                                  "dt_discharge_tec" +
                                                                  idx
                                                                }
                                                                value={1}
                                                                checked={
                                                                  data.dt_discharge ===
                                                                  1
                                                                }
                                                                onChange={
                                                                  HandleRepeaterFieldChange
                                                                }
                                                              />
                                                              <span
                                                                className="radio-text-padding"
                                                                style={{
                                                                  paddingLeft:
                                                                    "10px",
                                                                  marginLeft:
                                                                    "-5px",
                                                                }}
                                                              >
                                                                <DateControl
                                                                  ctrlId={
                                                                    "dt_discharge_dt_tec-" +
                                                                    idx
                                                                  }
                                                                  HandleDateChange={
                                                                    HandleRepeaterDateChange
                                                                  }
                                                                  date={
                                                                    data.dt_discharge_dt
                                                                  }
                                                                />
                                                              </span>
                                                            </div>
                                                            <div
                                                              className="option-without-input"
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "flex-start",
                                                              }}
                                                            >
                                                              <label className="option-fbox">
                                                                <input
                                                                  type="radio"
                                                                  style={{
                                                                    marginTop:
                                                                      "6px",
                                                                  }}
                                                                  id={idx}
                                                                  name={
                                                                    "dt_discharge_tec" +
                                                                    idx
                                                                  }
                                                                  value={99}
                                                                  checked={
                                                                    data.dt_discharge ===
                                                                    99
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />

                                                                <span
                                                                  className="radio-text-padding"
                                                                  style={{
                                                                    paddingLeft:
                                                                      "10px",
                                                                  }}
                                                                >
                                                                  Date of
                                                                  discharge
                                                                  unknown
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div
                                                              className="option-without-input"
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "flex-start",
                                                              }}
                                                            >
                                                              <label className="option-fbox">
                                                                <input
                                                                  type="radio"
                                                                  style={{
                                                                    marginTop:
                                                                      "6px",
                                                                  }}
                                                                  id={idx}
                                                                  name={
                                                                    "dt_discharge_tec" +
                                                                    idx
                                                                  }
                                                                  value={2}
                                                                  checked={
                                                                    data.dt_discharge ===
                                                                    2
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />

                                                                <span
                                                                  className="radio-text-padding"
                                                                  style={{
                                                                    paddingLeft:
                                                                      "10px",
                                                                  }}
                                                                >
                                                                  Patient is
                                                                  still
                                                                  hospitalized
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <ErrorField
                                                              show={
                                                                data.dt_discharge_Hard
                                                              }
                                                              message={
                                                                TTCharacteristics_MSGS.dischargeEmpty_tal
                                                              }
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                              }}
                                                            />
                                                            <ErrorField
                                                              show={
                                                                data.dt_discharge_Hard_dt
                                                              }
                                                              message={
                                                                TTCharacteristics_MSGS.dischargeEmptyDate
                                                              }
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                              }}
                                                            />

                                                            <ErrorField
                                                              show={
                                                                data.dt_discharge_Hard_dt_check
                                                              }
                                                              message={
                                                                TTCharacteristics_MSGS.dischargeDateCheck
                                                              }
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                          }}
                                                        >
                                                          <div>
                                                            <div className="">
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "hospReason1_tec" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hospReason1
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "6px",
                                                                  }}
                                                                >
                                                                  Administration
                                                                  of teclistamab
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div className="">
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "hospReason2_tec" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hospReason2
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "6px",
                                                                  }}
                                                                >
                                                                  Monitoring of
                                                                  adverse event
                                                                  (AE) as part
                                                                  of the
                                                                  treatment plan{" "}
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div className="">
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "hospReason3_tec" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hospReason3
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "6px",
                                                                  }}
                                                                >
                                                                  Management of
                                                                  cytokine
                                                                  release
                                                                  syndrome (CRS)
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div className="">
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "hospReason4_tec" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hospReason4
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "6px",
                                                                  }}
                                                                >
                                                                  Management of
                                                                  immune
                                                                  effector
                                                                  cell-associated
                                                                  neurotoxicity
                                                                  syndrome
                                                                  (ICANS)
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div className="">
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "hospReason5_tec" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hospReason5
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "6px",
                                                                  }}
                                                                >
                                                                  Management of
                                                                  other AE
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div
                                                              className="option-without-input"
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <label className="option-fbox">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "hospReason6_tec" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hospReason6
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "4px",
                                                                    paddingRight:
                                                                      "15px",
                                                                    marginBottom:
                                                                      "0px",
                                                                    padding:
                                                                      "0",
                                                                  }}
                                                                >
                                                                  Other non-AE
                                                                  related
                                                                  reasons,
                                                                  please
                                                                  specify:
                                                                  <div
                                                                    style={{
                                                                      paddingLeft:
                                                                        "15px",
                                                                      marginBottom:
                                                                        "0px",
                                                                      padding:
                                                                        "0",
                                                                    }}
                                                                  >
                                                                    <textarea
                                                                      name={
                                                                        "hosp_reason_oth_txt_tec" +
                                                                        idx
                                                                      }
                                                                      className="input-dash"
                                                                      type="text"
                                                                      value={
                                                                        data.hosp_reason_oth_txt
                                                                      }
                                                                      onChange={
                                                                        HandleRepeaterFieldChange
                                                                      }
                                                                      maxLength={
                                                                        300
                                                                      }
                                                                      id={idx}
                                                                    />
                                                                  </div>
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div className="">
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={idx}
                                                                  name={
                                                                    "hospReasonUnk_tec" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hospReasonUnk
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "6px",
                                                                  }}
                                                                >
                                                                  Unknown
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <ErrorField
                                                              show={
                                                                data.hospReasonEmptyHard
                                                              }
                                                              message={
                                                                TTCharacteristics_MSGS.Q40ReasonEmptyHard
                                                              }
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                              }}
                                                            />
                                                            <ErrorField
                                                              show={
                                                                data.hospReasonTxtEmpty
                                                              }
                                                              message={
                                                                TTCharacteristics_MSGS.Q36Point1ReasonTxtEmpty
                                                              }
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                      </>
                                                    )}
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    ))}

                                  {tecHospDetails &&
                                    tecHospDetails.length > 0 &&
                                    tecHospDetails.length < 550 &&
                                    tecHospDetails[tecHospDetails.length - 1]
                                      .dt_admission != null &&
                                    tecHospDetails[tecHospDetails.length - 1]
                                      .dt_admission !== 2 && (
                                      <div className="sub-question-bot-sapce-ul mt-2">
                                        <div
                                          className="answer-list-text"
                                          onClick={AddTecHospRow}
                                        >
                                          <a>
                                            <img
                                              width="18px"
                                              src="../Assets/images/plus.png"
                                              alt=""
                                            />
                                          </a>
                                          <a className="radio-text-padding">
                                            Click here to add another
                                            hospitalization of Tecvayli
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                              {/* 36.1 Tecvayli end */}
                            </>
                          )}
                          {/* Table 2 Talvey */}

                          {isTalveySelected && (
                            <>
                              <div className="dependent-section">
                                {talveyDetails &&
                                  talveyDetails.length > 0 &&
                                  talveyDetails.map((data, idx) => (
                                    <div className="loop-section-ul">
                                      <div className="loop-head">
                                        <span
                                          className="question-weight"
                                          style={{ float: "inline-start" }}
                                        >
                                          {GetOrdinal(idx + 1)} TALVEY{" "}
                                          <sup className="supscript">TM</sup>
                                        </span>

                                        {idx != 0 && (
                                          <div
                                            className="delete-btn-div"
                                            style={{ float: "inline-end" }}
                                          >
                                            <img
                                              width="16px"
                                              src="../Assets/images/Icon-material-delete.png"
                                              alt=""
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                DeleteTalveyRow(idx)
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>

                                      {/* TECVAYLI® */}
                                      <div className="question-bot-sapce">
                                        <div className="sub-question">
                                          <table
                                            style={{
                                              border: "1px solid black",
                                              borderCollapse: "collapse",
                                              width: "100%",
                                            }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Treatment
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Date of dose administration
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {" "}
                                                  <span
                                                    data-tooltip-id="encounterTip"
                                                    className="modal-popup"
                                                    onClick={() =>
                                                      setShowHideHyperlinkModalQ36Talvey(
                                                        true
                                                      )
                                                    }
                                                  >
                                                    Dosing phase
                                                  </span>
                                                  {showHideHyperlinkModalQ36Talvey && (
                                                    <InfoPopUp
                                                      description={
                                                        hyperlinkTextQ36Talvey
                                                      }
                                                      OkClick={() =>
                                                        setShowHideHyperlinkModalQ36Talvey(
                                                          false
                                                        )
                                                      }
                                                    />
                                                  )}{" "}
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Site of care
                                                </th>
                                                {/* {data.Q36TalConditionMet && (
                                                <>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Date of inpatient admission
                                                    for this administration
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Date of inpatient discharge
                                                    for this administration
                                                  </th>
                                                </>
                                              )} */}
                                              </tr>
                                            </thead>

                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <span className="quest-text-pad">
                                                    TALVEY<sup>TM</sup>{" "}
                                                    (talquetamab-tgvs)
                                                  </span>
                                                </td>

                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <input
                                                        type="radio"
                                                        id={idx}
                                                        name={
                                                          "dose_administration_tal" +
                                                          idx
                                                        }
                                                        value={1}
                                                        checked={
                                                          data.dose_administration_tal ===
                                                          1
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                          marginLeft: "-5px",
                                                        }}
                                                      >
                                                        <DateControl
                                                          ctrlId={
                                                            "dose_administration_tal_dt-" +
                                                            idx
                                                          }
                                                          HandleDateChange={
                                                            HandleRepeaterDateChange
                                                          }
                                                          date={
                                                            data.dose_administration_tal_dt
                                                          }
                                                        />
                                                      </span>
                                                    </div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "dose_administration_tal" +
                                                            idx
                                                          }
                                                          value={99}
                                                          checked={
                                                            data.dose_administration_tal ===
                                                            99
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Date of dose
                                                          administration unknown
                                                        </span>
                                                      </label>
                                                    </div>

                                                    <ErrorField
                                                      show={
                                                        data.dose_administration_talHard
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.administrationEmpty_tal
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                    <ErrorField
                                                      show={
                                                        data.dose_administration_talHard_dt
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.administrationEmptyDate
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />

                                                    <ErrorField
                                                      show={
                                                        data.dt_tal_greater_prevCheck
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.Q36DateGreaterThanPrevious
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={
                                                            "dosing_phase_tal" +
                                                            idx
                                                          }
                                                          value={1}
                                                          checked={
                                                            data.dosing_phase_tal ===
                                                            1
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Step-up dose 1{" "}
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "dosing_phase_tal" +
                                                            idx
                                                          }
                                                          value={2}
                                                          checked={
                                                            data.dosing_phase_tal ===
                                                            2
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />

                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Step-up dose 2
                                                        </span>
                                                      </label>
                                                    </div>

                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={
                                                            "dosing_phase_tal" +
                                                            idx
                                                          }
                                                          value={3}
                                                          checked={
                                                            data.dosing_phase_tal ===
                                                            3
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Step-up dose 3{" "}
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "dosing_phase_tal" +
                                                            idx
                                                          }
                                                          value={4}
                                                          checked={
                                                            data.dosing_phase_tal ===
                                                            4
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />

                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          1<sup>st</sup>{" "}
                                                          treatment dose
                                                        </span>
                                                      </label>
                                                    </div>

                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "dosing_phase_tal" +
                                                            idx
                                                          }
                                                          value={5}
                                                          checked={
                                                            data.dosing_phase_tal ===
                                                            5
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />

                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Subsequent treatment
                                                          doses
                                                        </span>
                                                      </label>
                                                    </div>

                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "dosing_phase_tal" +
                                                            idx
                                                          }
                                                          value={6}
                                                          checked={
                                                            data.dosing_phase_tal ===
                                                            6
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />

                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Other
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <ErrorField
                                                      show={
                                                        data.dosing_phase_talHard
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.dosingphaseEmpty_tal
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                  </div>
                                                </td>

                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={
                                                            "siteofcare_tal" +
                                                            idx
                                                          }
                                                          value={1}
                                                          checked={
                                                            data.siteofcare_tal ===
                                                            1
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Inpatient{" "}
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "siteofcare_tal" +
                                                            idx
                                                          }
                                                          value={2}
                                                          checked={
                                                            data.siteofcare_tal ===
                                                            2
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />

                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Outpatient
                                                        </span>
                                                      </label>
                                                    </div>

                                                    {/* <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={idx}
                                                        name={
                                                          "siteofcare_tal" + idx
                                                        }
                                                        value={3}
                                                        checked={
                                                          data.siteofcare_tal === 3
                                                          
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                      <span
                                                        // className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        Both inpatient and
                                                        outpatient
                                                      </span>
                                                    </label>
                                                  </div> */}
                                                    <ErrorField
                                                      show={
                                                        data.siteofcare_talHard
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.siteofcareEmpty_tal
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                  </div>
                                                </td>
                                                {/* {data.Q36TalConditionMet && (
                                                <>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    <div>
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={
                                                            "dt_admission_tal" +
                                                            idx
                                                          }
                                                          value={1}
                                                          checked={
                                                            data.dt_admission_tal ===
                                                            1
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                            marginLeft: "-5px",
                                                          }}
                                                        >
                                                          <DateControl
                                                            ctrlId={
                                                              "dt_admission_tal_dt-" +
                                                              idx
                                                            }
                                                            HandleDateChange={
                                                              HandleRepeaterDateChange
                                                            }
                                                            date={
                                                              data.dt_admission_tal_dt
                                                            }
                                                          />
                                                        </span>
                                                      </div>
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "dt_admission_tal" +
                                                            idx
                                                          }
                                                          value={99}
                                                          checked={
                                                            data.dt_admission_tal ===
                                                            99
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <label className="option-fbox">
                                                          <span
                                                            className="radio-text-padding"
                                                            style={{
                                                              paddingLeft:
                                                                "10px",
                                                            }}
                                                          >
                                                            Date of admission
                                                            unknown
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ErrorField
                                                        show={
                                                          data.dt_admission_talHard
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.admissionEmpty_tal
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />

                                                      <ErrorField
                                                        show={
                                                          data.dt_admission_talHard_dt
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.admissionEmptyDate
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />
                                                      <ErrorField
                                                        show={
                                                          data.dt_admission_talHard_dt_check
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.admissionDateCheck
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    <div>
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={
                                                            "dt_discharge_tal" +
                                                            idx
                                                          }
                                                          value={1}
                                                          checked={
                                                            data.dt_discharge_tal ===
                                                            1
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                            marginLeft: "-5px",
                                                          }}
                                                        >
                                                          <DateControl
                                                            ctrlId={
                                                              "dt_discharge_tal_dt-" +
                                                              idx
                                                            }
                                                            HandleDateChange={
                                                              HandleRepeaterDateChange
                                                            }
                                                            date={
                                                              data.dt_discharge_tal_dt
                                                            }
                                                          />
                                                        </span>
                                                      </div>
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "dt_discharge_tal" +
                                                            idx
                                                          }
                                                          value={2}
                                                          checked={
                                                            data.dt_discharge_tal ===
                                                            2
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <label className="option-fbox">
                                                          <span
                                                            className="radio-text-padding"
                                                            style={{
                                                              paddingLeft:
                                                                "10px",
                                                            }}
                                                          >
                                                            Date of discharge
                                                            unknown
                                                          </span>
                                                        </label>
                                                      </div>

                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "dt_discharge_tal" +
                                                            idx
                                                          }
                                                          value={3}
                                                          checked={
                                                            data.dt_discharge_tal ===
                                                            3
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <label>
                                                          <span
                                                            className="radio-text-padding"
                                                            style={{
                                                              paddingLeft:
                                                                "10px",
                                                            }}
                                                          >
                                                            Patient is still
                                                            hospitalized
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ErrorField
                                                        show={
                                                          data.dt_discharge_talHard
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.dischargeEmpty_tal
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />
                                                      <ErrorField
                                                        show={
                                                          data.dt_discharge_talHard_dt
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.dischargeEmptyDate
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />

                                                      <ErrorField
                                                        show={
                                                          data.dt_discharge_talHard_dt_check
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.dischargeDateCheck
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />
                                                    </div>
                                                  </td>
                                                </>
                                              )} */}
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                {talveyDetails &&
                                  talveyDetails.length > 0 &&
                                  talveyDetails.length < 550 && (
                                    <div className="sub-question-bot-sapce-ul mt-2">
                                      <div
                                        className="answer-list-text"
                                        onClick={AddTalveyRow}
                                      >
                                        <a>
                                          <img
                                            width="18px"
                                            src="../Assets/images/plus.png"
                                            alt=""
                                          />
                                        </a>
                                        <a className="radio-text-padding">
                                          Click here to add another dose
                                          administration of Talvey
                                        </a>
                                      </div>
                                    </div>
                                  )}
                              </div>
                              {/* 36.1 Talvey start */}
                              <div>
                                <div className="dependent-section sub-question marginleft15">
                                  <div
                                    className="question question-weight"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <span>Q36.1.</span>
                                    <span className="quest-text-pad">
                                      Please list all hospitalizations that
                                      occurred during the step-up dosing phase.
                                      Please specify the date of admission, date
                                      of discharge, and reason for
                                      hospitalization.{" "}
                                    </span>
                                  </div>
                                  {talHospDetails &&
                                    talHospDetails.length > 0 &&
                                    talHospDetails.map((data, idx) => (
                                      <div className="loop-section-ul">
                                        <div className="loop-head">
                                          <span
                                            className="question-weight"
                                            style={{ float: "inline-start" }}
                                          >
                                            {GetOrdinal(idx + 1)} TALVEY{" "}
                                            <sup className="supscript">TM</sup>{" "}
                                            Hospitalization
                                          </span>

                                          {idx != 0 && (
                                            <div
                                              className="delete-btn-div"
                                              style={{ float: "inline-end" }}
                                            >
                                              <img
                                                width="16px"
                                                src="../Assets/images/Icon-material-delete.png"
                                                alt=""
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  DeleteTalHospRow(idx)
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>

                                        {/* TECVAYLI® */}
                                        <div className="question-bot-sapce">
                                          <div className="sub-question">
                                            <table
                                              style={{
                                                border: "1px solid black",
                                                borderCollapse: "collapse",
                                                width: "100%",
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                      textAlign: "center",
                                                      // width: "25%"
                                                    }}
                                                  >
                                                    Treatment
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                      textAlign: "center",
                                                      // width: "20%"
                                                    }}
                                                  >
                                                    Date of admission
                                                  </th>
                                                  {data.dt_admission != null &&
                                                    data.dt_admission !== 2 && (
                                                      <>
                                                        <th
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                            textAlign: "center",
                                                            // width: "20%"
                                                          }}
                                                        >
                                                          Date of discharge
                                                        </th>
                                                        <th
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                            textAlign: "center",
                                                            // width: "35%"
                                                          }}
                                                        >
                                                          Reason of
                                                          hospitalization
                                                        </th>
                                                      </>
                                                    )}
                                                </tr>
                                              </thead>

                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    <span className="quest-text-pad">
                                                      TALVEY<sup>TM</sup>{" "}
                                                      (talquetamab-tgvs)
                                                    </span>
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    <div>
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={
                                                            "dt_admission_tal" +
                                                            idx
                                                          }
                                                          value={1}
                                                          checked={
                                                            data.dt_admission ===
                                                            1
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                            marginLeft: "-5px",
                                                          }}
                                                        >
                                                          <DateControl
                                                            ctrlId={
                                                              "dt_admission_dt_tal-" +
                                                              idx
                                                            }
                                                            HandleDateChange={
                                                              HandleRepeaterDateChange
                                                            }
                                                            date={
                                                              data.dt_admission_dt
                                                            }
                                                          />
                                                        </span>
                                                      </div>
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="option-fbox">
                                                          <input
                                                            type="radio"
                                                            style={{
                                                              marginTop: "6px",
                                                            }}
                                                            id={idx}
                                                            name={
                                                              "dt_admission_tal" +
                                                              idx
                                                            }
                                                            value={99}
                                                            checked={
                                                              data.dt_admission ===
                                                              99
                                                            }
                                                            onChange={
                                                              HandleRepeaterFieldChange
                                                            }
                                                          />
                                                          <span
                                                            className="radio-text-padding"
                                                            style={{
                                                              paddingLeft:
                                                                "10px",
                                                            }}
                                                          >
                                                            Date of admission
                                                            unknown
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="option-fbox">
                                                          <input
                                                            type="radio"
                                                            style={{
                                                              marginTop: "6px",
                                                            }}
                                                            id={idx}
                                                            name={
                                                              "dt_admission_tal" +
                                                              idx
                                                            }
                                                            value={2}
                                                            checked={
                                                              data.dt_admission ===
                                                              2
                                                            }
                                                            onChange={
                                                              HandleRepeaterFieldChange
                                                            }
                                                          />
                                                          <span
                                                            className="radio-text-padding"
                                                            style={{
                                                              paddingLeft:
                                                                "10px",
                                                            }}
                                                          >
                                                            Patient had no
                                                            hospitalizations
                                                            during the step-up
                                                            dosing phase
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ErrorField
                                                        show={
                                                          data.dt_admission_Hard
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.admissionEmpty_tal
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />

                                                      <ErrorField
                                                        show={
                                                          data.dt_admission_Hard_dt
                                                        }
                                                        message={
                                                          TTCharacteristics_MSGS.admissionEmptyDate
                                                        }
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      />
                                                      {/* <ErrorField
                                show={
                                    data.dt_admission_Hard_dt_check
                                }
                                message={
                                    TTCharacteristics_MSGS.admissionDateCheck
                                }
                                style={{
                                    paddingLeft: "0px",
                                }}
                                /> */}
                                                    </div>
                                                  </td>
                                                  {data.dt_admission != null &&
                                                    data.dt_admission !== 2 && (
                                                      <>
                                                        <td
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                          }}
                                                        >
                                                          <div>
                                                            <div
                                                              className="option-without-input"
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <input
                                                                type="radio"
                                                                id={idx}
                                                                name={
                                                                  "dt_discharge_tal" +
                                                                  idx
                                                                }
                                                                value={1}
                                                                checked={
                                                                  data.dt_discharge ===
                                                                  1
                                                                }
                                                                onChange={
                                                                  HandleRepeaterFieldChange
                                                                }
                                                              />
                                                              <span
                                                                className="radio-text-padding"
                                                                style={{
                                                                  paddingLeft:
                                                                    "10px",
                                                                  marginLeft:
                                                                    "-5px",
                                                                }}
                                                              >
                                                                <DateControl
                                                                  ctrlId={
                                                                    "dt_discharge_dt_tal-" +
                                                                    idx
                                                                  }
                                                                  HandleDateChange={
                                                                    HandleRepeaterDateChange
                                                                  }
                                                                  date={
                                                                    data.dt_discharge_dt
                                                                  }
                                                                />
                                                              </span>
                                                            </div>
                                                            <div
                                                              className="option-without-input"
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "flex-start",
                                                              }}
                                                            >
                                                              <label className="option-fbox">
                                                                <input
                                                                  type="radio"
                                                                  style={{
                                                                    marginTop:
                                                                      "6px",
                                                                  }}
                                                                  id={idx}
                                                                  name={
                                                                    "dt_discharge_tal" +
                                                                    idx
                                                                  }
                                                                  value={99}
                                                                  checked={
                                                                    data.dt_discharge ===
                                                                    99
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />

                                                                <span
                                                                  className="radio-text-padding"
                                                                  style={{
                                                                    paddingLeft:
                                                                      "10px",
                                                                  }}
                                                                >
                                                                  Date of
                                                                  discharge
                                                                  unknown
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div
                                                              className="option-without-input"
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "flex-start",
                                                              }}
                                                            >
                                                              <label className="option-fbox">
                                                                <input
                                                                  type="radio"
                                                                  style={{
                                                                    marginTop:
                                                                      "6px",
                                                                  }}
                                                                  id={idx}
                                                                  name={
                                                                    "dt_discharge_tal" +
                                                                    idx
                                                                  }
                                                                  value={2}
                                                                  checked={
                                                                    data.dt_discharge ===
                                                                    2
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />

                                                                <span
                                                                  className="radio-text-padding"
                                                                  style={{
                                                                    paddingLeft:
                                                                      "10px",
                                                                  }}
                                                                >
                                                                  Patient is
                                                                  still
                                                                  hospitalized
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <ErrorField
                                                              show={
                                                                data.dt_discharge_Hard
                                                              }
                                                              message={
                                                                TTCharacteristics_MSGS.dischargeEmpty_tal
                                                              }
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                              }}
                                                            />
                                                            <ErrorField
                                                              show={
                                                                data.dt_discharge_Hard_dt
                                                              }
                                                              message={
                                                                TTCharacteristics_MSGS.dischargeEmptyDate
                                                              }
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                              }}
                                                            />

                                                            <ErrorField
                                                              show={
                                                                data.dt_discharge_Hard_dt_check
                                                              }
                                                              message={
                                                                TTCharacteristics_MSGS.dischargeDateCheck
                                                              }
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                        <td
                                                          style={{
                                                            border:
                                                              "1px solid black",
                                                            padding: "8px",
                                                          }}
                                                        >
                                                          <div>
                                                            <div className="">
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "hospReason1_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hospReason1
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "6px",
                                                                  }}
                                                                >
                                                                  Administration
                                                                  of talquetamab
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div className="">
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "hospReason2_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hospReason2
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "6px",
                                                                  }}
                                                                >
                                                                  Monitoring of
                                                                  adverse event
                                                                  (AE) as part
                                                                  of the
                                                                  treatment plan{" "}
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div className="">
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "hospReason3_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hospReason3
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "6px",
                                                                  }}
                                                                >
                                                                  Management of
                                                                  cytokine
                                                                  release
                                                                  syndrome (CRS)
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div className="">
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "hospReason4_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hospReason4
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "6px",
                                                                  }}
                                                                >
                                                                  Management of
                                                                  immune
                                                                  effector
                                                                  cell-associated
                                                                  neurotoxicity
                                                                  syndrome
                                                                  (ICANS)
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div className="">
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "hospReason5_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hospReason5
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "6px",
                                                                  }}
                                                                >
                                                                  Management of
                                                                  other AE
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div
                                                              className="option-without-input"
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <label className="option-fbox">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "hospReason6_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hospReason6
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "4px",
                                                                    paddingRight:
                                                                      "15px",
                                                                    marginBottom:
                                                                      "0px",
                                                                    padding:
                                                                      "0",
                                                                  }}
                                                                >
                                                                  Other non-AE
                                                                  related
                                                                  reasons,
                                                                  please
                                                                  specify:
                                                                  <div
                                                                    style={{
                                                                      paddingLeft:
                                                                        "15px",
                                                                      marginBottom:
                                                                        "0px",
                                                                      padding:
                                                                        "0",
                                                                    }}
                                                                  >
                                                                    <textarea
                                                                      name={
                                                                        "hosp_reason_oth_txt_tal" +
                                                                        idx
                                                                      }
                                                                      className="input-dash"
                                                                      type="text"
                                                                      value={
                                                                        data.hosp_reason_oth_txt
                                                                      }
                                                                      onChange={
                                                                        HandleRepeaterFieldChange
                                                                      }
                                                                      maxLength={
                                                                        300
                                                                      }
                                                                      id={idx}
                                                                    />
                                                                  </div>
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div className="">
                                                              <label className="long-text-option">
                                                                <input
                                                                  type="radio"
                                                                  id={idx}
                                                                  name={
                                                                    "hospReasonUnk_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hospReasonUnk
                                                                  }
                                                                  onChange={
                                                                    HandleRepeaterFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "6px",
                                                                  }}
                                                                >
                                                                  Unknown
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <ErrorField
                                                              show={
                                                                data.hospReasonEmptyHard
                                                              }
                                                              message={
                                                                TTCharacteristics_MSGS.Q40ReasonEmptyHard
                                                              }
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                              }}
                                                            />
                                                            <ErrorField
                                                              show={
                                                                data.hospReasonTxtEmpty
                                                              }
                                                              message={
                                                                TTCharacteristics_MSGS.Q36Point1ReasonTxtEmpty
                                                              }
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                      </>
                                                    )}
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  {talHospDetails &&
                                    talHospDetails.length > 0 &&
                                    talHospDetails.length < 550 &&
                                    talHospDetails[talHospDetails.length - 1]
                                      .dt_admission != null &&
                                    talHospDetails[talHospDetails.length - 1]
                                      .dt_admission !== 2 && (
                                      <div className="sub-question-bot-sapce-ul mt-2">
                                        <div
                                          className="answer-list-text"
                                          onClick={AddTalHospRow}
                                        >
                                          <a>
                                            <img
                                              width="18px"
                                              src="../Assets/images/plus.png"
                                              alt=""
                                            />
                                          </a>
                                          <a className="radio-text-padding">
                                            Click here to add another
                                            hospitalization of Talvey
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                              {/* 36.1 Talvey end */}
                            </>
                          )}
                        </div>

                        {/* Q37 start --------------------------------------- */}
                        <div>
                          <div className="question question-weight">
                            <span>Q37.</span>
                            <span className="quest-text-pad">
                              Please indicate if any medication(s) had dose
                              frequency reductions during treatment with{" "}
                              <span
                                style={{
                                  color: PageDesignConstants.fontColor,
                                  fontWeight: "bolder",
                                }}
                              >
                                {isTechvayliSelected && isTalveySelected ? (
                                  <>
                                    TECVAYLI® (teclistamab-cqyv) and TALVEY
                                    <sup className="supscript">TM</sup>{" "}
                                    (talquetamab-tgvs).
                                  </>
                                ) : isTechvayliSelected ? (
                                  "TECVAYLI® (teclistamab-cqyv)."
                                ) : isTalveySelected ? (
                                  <>
                                    TALVEY<sup className="supscript">TM</sup>{" "}
                                    (talquetamab-tgvs).
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>{" "}
                            </span>
                          </div>

                          <div className="question-bot-sapce">
                            <div className="sub-question">
                              <table
                                style={{
                                  border: "1px solid black",
                                  borderCollapse: "collapse",
                                  width: "100%",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        border: "1px solid black",
                                        padding: "8px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Treatment
                                    </th>
                                    <th
                                      style={{
                                        border: "1px solid black",
                                        padding: "8px",
                                        textAlign: "center",
                                        width: "25%",
                                      }}
                                    >
                                      Initial dosing frequency after the step-up
                                      dose is completed
                                    </th>
                                    <th
                                      style={{
                                        border: "1px solid black",
                                        padding: "8px",
                                        textAlign: "center",
                                        width: "25%",
                                      }}
                                    >
                                      Was the dosing frequency reduced for this
                                      medication at any point during the
                                      {/*                                   
                                   line of
                                  therapy */}
                                      <span
                                        data-tooltip-id="encounterTip"
                                        className="modal-popup"
                                        onClick={() =>
                                          setShowLineOfTherapyModal(true)
                                        }
                                      >
                                        {" "}
                                        {""} line of therapy
                                      </span>
                                      {showLineOfTherapyModal && (
                                        <InfoPopUp
                                          description={
                                            hyperlinkTextLineOfTherapy
                                          }
                                          OkClick={() =>
                                            setShowLineOfTherapyModal(false)
                                          }
                                        />
                                      )}{" "}
                                      ?
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {/* Tecvayli */}
                                  {isTechvayliSelected && (
                                    <tr>
                                      {Q37TechDetails.length > 0 &&
                                        Q37TechDetails.map((data, idx) => (
                                          <>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <span className="quest-text-pad">
                                                TECVAYLI® (teclistamab-cqyv)
                                              </span>
                                            </td>

                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div
                                                className="option-without-input"
                                                style={{ display: "flex" }}
                                              >
                                                <label className="option-fbox">
                                                  <input
                                                    type="radio"
                                                    id={idx}
                                                    name={
                                                      "q37_Tec_Dose_week_frquencyRd" +
                                                      idx
                                                    }
                                                    value={1}
                                                    checked={
                                                      data.q37_Tec_Dose_week_frquencyRd ===
                                                      1
                                                    }
                                                    onChange={HandleFieldChange}
                                                  />
                                                  <span
                                                    style={{
                                                      paddingLeft: "10px",
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <span>
                                                      Once every &nbsp;
                                                    </span>
                                                    <span>
                                                      <input
                                                        className="input-dash-custom"
                                                        id={idx}
                                                        name={
                                                          "q37_Tec_Dose_week_frquency_txt" +
                                                          idx
                                                        }
                                                        value={
                                                          data.q37_Tec_Dose_week_frquency_txt
                                                        }
                                                        type="text"
                                                        onKeyDown={(e) => {
                                                          // Allow backspace, arrow keys, and delete
                                                          if (
                                                            [
                                                              "Backspace",
                                                              "ArrowLeft",
                                                              "ArrowRight",
                                                              "Delete",
                                                              "Tab",
                                                            ].includes(e.key)
                                                          ) {
                                                            return;
                                                          }

                                                          // Prevent non-numeric characters, except '.'
                                                          if (
                                                            !/[0-9.]/.test(
                                                              e.key
                                                            )
                                                          ) {
                                                            e.preventDefault();
                                                            return;
                                                          }

                                                          const value =
                                                            e.target.value;
                                                          const selectionStart =
                                                            e.target
                                                              .selectionStart;
                                                          const newValue =
                                                            value.slice(
                                                              0,
                                                              selectionStart
                                                            ) +
                                                            e.key +
                                                            value.slice(
                                                              e.target
                                                                .selectionEnd
                                                            );

                                                          // Prevent multiple '.'
                                                          if (
                                                            e.key === "." &&
                                                            value.includes(".")
                                                          ) {
                                                            e.preventDefault();
                                                            return;
                                                          }

                                                          // Prevent '.' as the first character
                                                          if (
                                                            e.key === "." &&
                                                            value === ""
                                                          ) {
                                                            e.preventDefault();
                                                            return;
                                                          }

                                                          // Allow only one digit after decimal point
                                                          if (
                                                            newValue.includes(
                                                              "."
                                                            ) &&
                                                            newValue.split(
                                                              "."
                                                            )[1].length > 2
                                                          ) {
                                                            e.preventDefault();
                                                            return;
                                                          }
                                                        }}
                                                        onChange={
                                                          HandleFieldChange
                                                        }
                                                      />
                                                    </span>
                                                    week
                                                  </span>
                                                </label>
                                              </div>
                                              {/* new Unknown */}
                                              <div
                                                className="option-without-input"
                                                style={{ display: "flex" }}
                                              >
                                                <label className="option-fbox">
                                                <input
                                                    type="radio"
                                                    id={idx}
                                                    name={
                                                      "q37_Tec_Dose_week_frquencyRd" +
                                                      idx
                                                    }
                                                    value={99}
                                                    checked={
                                                      data.q37_Tec_Dose_week_frquencyRd ===
                                                      99
                                                    }
                                                    onChange={HandleFieldChange}
                                                  />
                                                
                                                <span
                                                    style={{
                                                      paddingLeft: "10px",
                                                      display: "flex",
                                                    }}
                                                  >Unknown
                                                </span>
                                                </label>
                                              </div>


                                              <ErrorField
                                                show={
                                                  data.q37_Tec_Dose_week_frquencyRd_Empty
                                                }
                                                message={
                                                  TTCharacteristics_MSGS.q37TechvayliEmpty
                                                }
                                                style={{
                                                  paddingLeft: "0px",
                                                }}
                                              />

                                              <ErrorField
                                                show={
                                                  data.q37_Tec_Dose_week_frquency_txt_Empty
                                                }
                                                message={
                                                  TTCharacteristics_MSGS.specifyDosageFrequency
                                                }
                                                style={{
                                                  paddingLeft: "0px",
                                                }}
                                              />
                                            </td>

                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div>
                                                  <div
                                                    className="option-without-input"
                                                    style={{ display: "flex" }}
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        // id="Q37Techvayli_select_1"

                                                        id={idx}
                                                        //name="Q37TechvayliRd"
                                                        name={
                                                          "Q37TechvayliRd" + idx
                                                        }
                                                        value={1}
                                                        checked={
                                                          data.Q37TechvayliRd ===
                                                          1
                                                        }
                                                        onChange={
                                                          HandleFieldChange
                                                        }
                                                      />
                                                      <span
                                                        // className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                          marginLeft: "-5px",
                                                        }}
                                                      >
                                                        Yes
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        // id="Q37Techvayli_select_0"
                                                        // name="Q37TechvayliRd"
                                                        id={idx}
                                                        name={
                                                          "Q37TechvayliRd" + idx
                                                        }
                                                        value={2}
                                                        checked={
                                                          data.Q37TechvayliRd ===
                                                          2
                                                        }
                                                        onChange={
                                                          HandleFieldChange
                                                        }
                                                      />

                                                      <span
                                                        // className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        No
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        // id="Q37Techvayli_select_0"
                                                        // name="Q37TechvayliRd"
                                                        id={idx}
                                                        name={
                                                          "Q37TechvayliRd" + idx
                                                        }
                                                        value={99}
                                                        checked={
                                                          data.Q37TechvayliRd ===
                                                          99
                                                        }
                                                        onChange={
                                                          HandleFieldChange
                                                        }
                                                      />

                                                      <span
                                                        // className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        Unknown
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <ErrorField
                                                    show={
                                                      data.q37TechvayliEmpty
                                                    }
                                                    message={
                                                      TTCharacteristics_MSGS.q37TechvayliEmpty
                                                    }
                                                    style={{
                                                      paddingLeft: "0px",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          </>
                                        ))}
                                    </tr>
                                  )}

                                  {/* Talvey */}
                                  {isTalveySelected && (
                                    <tr>
                                      {Q37TalDetails.length > 0 &&
                                        Q37TalDetails.map((data, idx) => (
                                          <>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <span className="quest-text-pad">
                                                TALVEY
                                                <sup className="supscript">
                                                  TM
                                                </sup>{" "}
                                                (talquetamab-tgvs)
                                              </span>
                                            </td>

                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div
                                                className="option-without-input"
                                                style={{ display: "flex" }}
                                              >
                                                <label className="option-fbox">
                                                  <input
                                                    type="radio"
                                                    id={idx}
                                                    name={
                                                      "q37_Tal_Dose_week_frquencyRd" +
                                                      idx
                                                    }
                                                    value={1}
                                                    checked={
                                                      data.q37_Tal_Dose_week_frquencyRd ===
                                                      1
                                                    }
                                                    onChange={HandleFieldChange}
                                                  />
                                                  <span
                                                    style={{
                                                      paddingLeft: "10px",
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <span>
                                                      Once every &nbsp;
                                                    </span>
                                                    <span>
                                                      <input
                                                        className="input-dash-custom"
                                                        id={idx}
                                                        name={
                                                          "q37_Tal_Dose_week_frquency_txt" +
                                                          idx
                                                        }
                                                        type="text"
                                                        onChange={
                                                          HandleFieldChange
                                                        }
                                                        value={
                                                          data.q37_Tal_Dose_week_frquency_txt
                                                        }
                                                        onKeyDown={(e) => {
                                                          // Allow backspace, arrow keys, and delete
                                                          if (
                                                            [
                                                              "Backspace",
                                                              "ArrowLeft",
                                                              "ArrowRight",
                                                              "Delete",
                                                              "Tab",
                                                            ].includes(e.key)
                                                          ) {
                                                            return;
                                                          }

                                                          // Prevent non-numeric characters, except '.'
                                                          if (
                                                            !/[0-9.]/.test(
                                                              e.key
                                                            )
                                                          ) {
                                                            e.preventDefault();
                                                            return;
                                                          }

                                                          const value =
                                                            e.target.value;
                                                          const selectionStart =
                                                            e.target
                                                              .selectionStart;
                                                          const newValue =
                                                            value.slice(
                                                              0,
                                                              selectionStart
                                                            ) +
                                                            e.key +
                                                            value.slice(
                                                              e.target
                                                                .selectionEnd
                                                            );

                                                          // Prevent multiple '.'
                                                          if (
                                                            e.key === "." &&
                                                            value.includes(".")
                                                          ) {
                                                            e.preventDefault();
                                                            return;
                                                          }

                                                          // Prevent '.' as the first character
                                                          if (
                                                            e.key === "." &&
                                                            value === ""
                                                          ) {
                                                            e.preventDefault();
                                                            return;
                                                          }

                                                          // Allow only one digit after decimal point
                                                          if (
                                                            newValue.includes(
                                                              "."
                                                            ) &&
                                                            newValue.split(
                                                              "."
                                                            )[1].length > 2
                                                          ) {
                                                            e.preventDefault();
                                                            return;
                                                          }
                                                        }}
                                                      />
                                                    </span>
                                                    week
                                                  </span>
                                                </label>
                                              </div>
                                               {/* new Unknown */}
                                               <div
                                                className="option-without-input"
                                                style={{ display: "flex" }}
                                              >
                                                <label className="option-fbox">
                                                  <input
                                                    type="radio"
                                                    id={idx}
                                                    name={
                                                      "q37_Tal_Dose_week_frquencyRd" +
                                                      idx
                                                    }
                                                    value={99}
                                                    checked={
                                                      data.q37_Tal_Dose_week_frquencyRd ===
                                                      99
                                                    }
                                                    onChange={HandleFieldChange}
                                                  />
                                                
                                                <span
                                                    style={{
                                                      paddingLeft: "10px",
                                                      display: "flex",
                                                    }}
                                                  >Unknown
                                                </span>
                                                </label>
                                              </div>
                                              <div
                                              //style={{paddingLeft:"25px"}}
                                              >
                                                <ErrorField
                                                  show={
                                                    data.q37_Tal_Dose_week_frquencyRd_Empty
                                                  }
                                                  message={
                                                    TTCharacteristics_MSGS.admissionEmpty
                                                  }
                                                  style={{
                                                    paddingLeft: "0px",
                                                  }}
                                                />

                                                <ErrorField
                                                  show={
                                                    data.q37_Tal_Dose_week_frquency_txt_Empty
                                                  }
                                                  message={
                                                    TTCharacteristics_MSGS.specifyDosageFrequency
                                                  }
                                                  style={{
                                                    paddingLeft: "0px",
                                                  }}
                                                />
                                              </div>

                                              <div className="option-without-input">
                                                <strong>Dosage</strong>

                                                <div
                                                  style={{
                                                    paddingLeft: "15px",
                                                  }}
                                                >
                                                  <div
                                                    className="option-without-input"
                                                    //style={{ display: "flex" }}
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        id={idx}
                                                        name={
                                                          "q37_Tal_Dosage_Rd" +
                                                          idx
                                                        }
                                                        value={1}
                                                        checked={
                                                          data.q37_Tal_Dosage_Rd ===
                                                          1
                                                        }
                                                        onChange={
                                                          HandleFieldChange
                                                        }
                                                      />
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        0.40 mg/kg{" "}
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div
                                                    className="option-without-input"
                                                    style={
                                                      {
                                                        // display: "flex",
                                                        // alignItems: "flex-start",
                                                      }
                                                    }
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={idx}
                                                        name={
                                                          "q37_Tal_Dosage_Rd" +
                                                          idx
                                                        }
                                                        value={2}
                                                        checked={
                                                          data.q37_Tal_Dosage_Rd ===
                                                          2
                                                        }
                                                        onChange={
                                                          HandleFieldChange
                                                        }
                                                      />

                                                      <span
                                                        // className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        0.80 mg/kg
                                                      </span>
                                                    </label>
                                                  </div>

                                                  <div
                                                    className="option-without-input"
                                                    style={
                                                      {
                                                        //display: "flex",
                                                        //alignItems: "flex-start",
                                                      }
                                                    }
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={idx}
                                                        name={
                                                          "q37_Tal_Dosage_Rd" +
                                                          idx
                                                        }
                                                        value={3}
                                                        checked={
                                                          data.q37_Tal_Dosage_Rd ===
                                                          3
                                                        }
                                                        onChange={
                                                          HandleFieldChange
                                                        }
                                                      />

                                                      <span
                                                        // className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        Other, please specify
                                                        <span>
                                                          <input
                                                            className="input-dash"
                                                            id={idx}
                                                            name={
                                                              "q37_Tal_Dosage_OtherTxt" +
                                                              idx
                                                            }
                                                            type="text"
                                                            onChange={
                                                              HandleFieldChange
                                                            }
                                                            value={
                                                              data.q37_Tal_Dosage_OtherTxt
                                                            }
                                                          />
                                                        </span>
                                                      </span>
                                                    </label>
                                                  </div>

                                                  <div
                                                    className="option-without-input"
                                                    style={
                                                      {
                                                        // display: "flex",
                                                        // alignItems: "flex-start",
                                                      }
                                                    }
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={idx}
                                                        name={
                                                          "q37_Tal_Dosage_Rd" +
                                                          idx
                                                        }
                                                        value={99}
                                                        checked={
                                                          data.q37_Tal_Dosage_Rd ===
                                                          99
                                                        }
                                                        onChange={
                                                          HandleFieldChange
                                                        }
                                                      />

                                                      <span
                                                        // className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        Unknown
                                                      </span>
                                                    </label>
                                                  </div>

                                                  <ErrorField
                                                    show={
                                                      data.q37_Tal_Dosage_Rd_Empty
                                                    }
                                                    message={
                                                      TTCharacteristics_MSGS.admissionEmpty
                                                    }
                                                    style={{
                                                      paddingLeft: "0px",
                                                    }}
                                                  />

                                                  <ErrorField
                                                    show={
                                                      data.q37_Tal_Dosage_OtherTxt_Empty
                                                    }
                                                    message={
                                                      TTCharacteristics_MSGS.specifyOtherText
                                                    }
                                                    style={{
                                                      paddingLeft: "0px",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </td>

                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div>
                                                  <div
                                                    className="option-without-input"
                                                    style={{ display: "flex" }}
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        // id="Q37Talvey_select_1"
                                                        // name="Q37TalveyRd"

                                                        id={idx}
                                                        name={
                                                          "Q37TalveyRd" + idx
                                                        }
                                                        value={1}
                                                        checked={
                                                          data.Q37TalveyRd === 1
                                                        }
                                                        onChange={
                                                          HandleFieldChange
                                                        }
                                                      />
                                                      <span
                                                        // className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                          marginLeft: "-5px",
                                                        }}
                                                      >
                                                        Yes
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        // id="Q37Talvey_select_0"
                                                        // name="Q37TalveyRd"

                                                        id={idx}
                                                        name={
                                                          "Q37TalveyRd" + idx
                                                        }
                                                        value={2}
                                                        checked={
                                                          data.Q37TalveyRd === 2
                                                        }
                                                        onChange={
                                                          HandleFieldChange
                                                        }
                                                      />

                                                      <span
                                                        // className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        No
                                                      </span>
                                                    </label>
                                                  </div>
                                                
                                                  <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        // id="Q37Techvayli_select_0"
                                                        // name="Q37TechvayliRd"
                                                        id={idx}
                                                        name={
                                                          "Q37TalveyRd" + idx
                                                        }
                                                        value={99}
                                                        checked={
                                                          data.Q37TalveyRd === 99
                                                        }
                                                        onChange={
                                                          HandleFieldChange
                                                        }
                                                      />

                                                      <span
                                                        // className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        Unknown
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <ErrorField
                                                    show={data.q37TalveyEmpty}
                                                    message={
                                                      TTCharacteristics_MSGS.q37TalveyEmpty
                                                    }
                                                    style={{
                                                      paddingLeft: "0px",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          </>
                                        ))}
                                    </tr>
                                  )}
                                  {/* Talvey */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {/* Q37 end---------------------------------------------------------- */}

                        {/* Q38 start */}
                        {(Q37TechDetails[0].Q37TechvayliRd === 1 ||
                          Q37TalDetails[0].Q37TalveyRd === 1) && (
                          <>
                            <div className="question question-weight">
                              <span>Q38.</span>
                              <span className="quest-text-pad">
                                Please provide all dates of dose frequency
                                reductions during treatment with{" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {isTechvayliSelected &&
                                  Q37TechDetails[0].Q37TechvayliRd === 1 &&
                                  isTalveySelected &&
                                  Q37TalDetails[0].Q37TalveyRd === 1 ? (
                                    <>
                                      TECVAYLI® (teclistamab-cqyv) and TALVEY
                                      <sup className="supscript">TM</sup>{" "}
                                      (talquetamab-tgvs).
                                    </>
                                  ) : isTechvayliSelected &&
                                    Q37TechDetails[0].Q37TechvayliRd === 1 ? (
                                    "TECVAYLI® (teclistamab-cqyv)."
                                  ) : isTalveySelected &&
                                    Q37TalDetails[0].Q37TalveyRd === 1 ? (
                                    <>
                                      TALVEY<sup className="supscript">TM</sup>{" "}
                                      (talquetamab-tgvs).
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>{" "}
                              </span>
                            </div>
                          </>
                        )}
                        {isTechvayliSelected &&
                          Q37TechDetails[0].showDateRedFreqTec  && (
                            <div className="dependent-section">
                              {Q38TechDetails &&
                                Q38TechDetails.length > 0 &&
                                Q38TechDetails.map((data, idx) => (
                                  <div className="loop-section-ul">
                                    <div className="loop-head">
                                      <span
                                        className="question-weight"
                                        style={{ float: "inline-start" }}
                                      >
                                        {GetOrdinal(idx + 1)} Dose frequency
                                        reductions
                                      </span>
                                      {idx != 0 && (
                                        <div
                                          className="delete-btn-div"
                                          style={{ float: "inline-end" }}
                                        >
                                          <img
                                            width="16px"
                                            src="../Assets/images/Icon-material-delete.png"
                                            alt=""
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              DeleteQ38TechvayliRow(idx)
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>

                                    {/* TECVAYLI® */}
                                    <div className="question-bot-sapce">
                                      <div className="sub-question">
                                        <table
                                          style={{
                                            border: "1px solid black",
                                            borderCollapse: "collapse",
                                            width: "100%",
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                Treatment
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                  textAlign: "center",
                                                  width: "15%",
                                                }}
                                              >
                                                Date of dose frequency reduction
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                Dose frequency after reduction
                                              </th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {/* Tecvayli */}
                                            {/* {isTechvayliSelected && ( */}
                                            <tr>
                                              {/* {techvayliDetails.length > 0 &&
                                    techvayliDetails.map((data, idx) => (
                                      <> */}
                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                }}
                                              >
                                                <span className="quest-text-pad">
                                                  TECVAYLI® (teclistamab-cqyv)
                                                </span>
                                              </td>

                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                }}
                                              >
                                                <div>
                                                  <div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <input
                                                        type="radio"
                                                        id={idx}
                                                        name={
                                                          "Q38TechvayliRd" + idx
                                                        }
                                                        value={1}
                                                        checked={
                                                          data.Q38TechvayliRd ===
                                                          1
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                          marginLeft: "-5px",
                                                        }}
                                                      >
                                                        <DateControl
                                                          ctrlId={
                                                            "dt_dosefreq_tech_dt-" +
                                                            idx
                                                          }
                                                          HandleDateChange={
                                                            HandleRepeaterDateChange
                                                          }
                                                          date={
                                                            data.dt_dosefreq_tech_dt
                                                          }
                                                        />
                                                      </span>
                                                    </div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          // id="Q37Techvayli_select_0"
                                                          // name="Q37TechvayliRd"
                                                          id={idx}
                                                          name={
                                                            "Q38TechvayliRd" +
                                                            idx
                                                          }
                                                          value={99}
                                                          checked={
                                                            data.Q38TechvayliRd ===
                                                            99
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />

                                                        <span
                                                          // className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Date of dose frequency
                                                          reduction unknown
                                                        </span>
                                                      </label>
                                                    </div>
                                            
                                                    <ErrorField
                                                      show={
                                                        data.Q38TechEmptyHard
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.Q38TechEmptyHard
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                    <ErrorField
                                                      show={
                                                        data.Q38TechDateInvalid
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.Q38TechDateInvalid
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                    <ErrorField
                                                      show={data.Q38DateEmpty}
                                                      message={
                                                        TTCharacteristics_MSGS.Q38DateEmpty
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>

                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                }}
                                              >
                                                <div
                                                  className="option-without-input"
                                                  style={{ display: "flex" }}
                                                >
                                                  <label className="option-fbox">
                                                    <input
                                                      type="radio"
                                                      id={idx}
                                                      name={
                                                        "q38_Tec_Dose_week_frquencyRd" +
                                                        idx
                                                      }
                                                      value={1}
                                                      checked={
                                                        data.q38_Tec_Dose_week_frquencyRd ===
                                                        1
                                                      }
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    <span
                                                      style={{
                                                        paddingLeft: "10px",
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                      }}
                                                    >
                                                      <span>
                                                        Once every &nbsp;
                                                      </span>
                                                      <span>
                                                        <input
                                                          className="input-dash-custom"
                                                          id={idx}
                                                          name={
                                                            "q38_Tec_Dose_week_frquency_txt" +
                                                            idx
                                                          }
                                                          value={
                                                            data.q38_Tec_Dose_week_frquency_txt
                                                          }
                                                          type="text"
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                          onKeyDown={(e) => {
                                                            // Allow backspace, arrow keys, and delete
                                                            if (
                                                              [
                                                                "Backspace",
                                                                "ArrowLeft",
                                                                "ArrowRight",
                                                                "Delete",
                                                                "Tab",
                                                              ].includes(e.key)
                                                            ) {
                                                              return;
                                                            }

                                                            // Prevent non-numeric characters, except '.'
                                                            if (
                                                              !/[0-9.]/.test(
                                                                e.key
                                                              )
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }

                                                            const value =
                                                              e.target.value;
                                                            const selectionStart =
                                                              e.target
                                                                .selectionStart;
                                                            const newValue =
                                                              value.slice(
                                                                0,
                                                                selectionStart
                                                              ) +
                                                              e.key +
                                                              value.slice(
                                                                e.target
                                                                  .selectionEnd
                                                              );

                                                            // Prevent multiple '.'
                                                            if (
                                                              e.key === "." &&
                                                              value.includes(
                                                                "."
                                                              )
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }

                                                            // Prevent '.' as the first character
                                                            if (
                                                              e.key === "." &&
                                                              value === ""
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }

                                                            // Allow only one digit after decimal point
                                                            if (
                                                              newValue.includes(
                                                                "."
                                                              ) &&
                                                              newValue.split(
                                                                "."
                                                              )[1].length > 2
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }
                                                          }}
                                                        />
                                                      </span>
                                                      week
                                                    </span>
                                                  </label>
                                                </div>
                                                 {/* new Unknown */}
                                               <div
                                                className="option-without-input"
                                                style={{ display: "flex" }}
                                              >
                                                <label className="option-fbox">
                                                  <input
                                                    type="radio"
                                                    id={idx}
                                                    name={
                                                      "q38_Tec_Dose_week_frquencyRd" +
                                                      idx
                                                    }
                                                    value={99}
                                                    checked={
                                                      data.q38_Tec_Dose_week_frquencyRd ===
                                                      99
                                                    }
                                                    onChange={HandleRepeaterFieldChange}
                                                  />
                                                
                                                <span
                                                    style={{
                                                      paddingLeft: "10px",
                                                      display: "flex",
                                                    }}
                                                  >Unknown
                                                </span>
                                                </label>
                                              </div>

                                                <ErrorField
                                                  show={
                                                    data.q38_Tec_Dose_week_frquencyRd_Empty
                                                  }
                                                  message={
                                                    TTCharacteristics_MSGS.q37TechvayliEmpty
                                                  }
                                                  style={{
                                                    paddingLeft: "0px",
                                                  }}
                                                />

                                                <ErrorField
                                                  show={
                                                    data.q38_Tec_Dose_week_frquency_txt_Empty
                                                  }
                                                  message={
                                                    TTCharacteristics_MSGS.specifyDosageFrequency
                                                  }
                                                  style={{
                                                    paddingLeft: "0px",
                                                  }}
                                                />
                                              </td>

                                              {/* </>
                                    ))} */}
                                            </tr>
                                            {/* )} */}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                              {Q38TechDetails &&
                                Q38TechDetails.length > 0 &&
                                Q38TechDetails.length < 550 && (
                                  <div className="sub-question-bot-sapce-ul mt-2">
                                    <div
                                      className="answer-list-text"
                                      onClick={AddQ38TechvayliRow}
                                    >
                                      <a>
                                        <img
                                          width="18px"
                                          src="../Assets/images/plus.png"
                                          alt=""
                                        />
                                      </a>
                                      <a className="radio-text-padding">
                                        Click here to add another Dose of
                                        frequency
                                      </a>
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}

                        {/* Q38 end  */}

                        {/* Q38 Talvey */}
                        {isTalveySelected &&
                          Q37TalDetails[0].showDateRedFreqTal && (
                            <div className="dependent-section">
                              {Q38TalDetails &&
                                Q38TalDetails.length > 0 &&
                                Q38TalDetails.map((data, idx) => (
                                  <div className="loop-section-ul">
                                    <div className="loop-head">
                                      <span
                                        className="question-weight"
                                        style={{ float: "inline-start" }}
                                      >
                                        {GetOrdinal(idx + 1)} Dose frequency
                                        reductions
                                      </span>
                                      {idx != 0 && (
                                        <div
                                          className="delete-btn-div"
                                          style={{ float: "inline-end" }}
                                        >
                                          <img
                                            width="16px"
                                            src="../Assets/images/Icon-material-delete.png"
                                            alt=""
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              DeleteQ38TalveyRow(idx)
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>

                                    {/* TALVEY */}
                                    <div className="question-bot-sapce">
                                      <div className="sub-question">
                                        <table
                                          style={{
                                            border: "1px solid black",
                                            borderCollapse: "collapse",
                                            width: "100%",
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                Treatment
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                  textAlign: "center",
                                                  width: "15%",
                                                }}
                                              >
                                                Date of dose frequency reduction
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                Dose frequency after reduction
                                              </th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {/* Talvey */}
                                            {/* {isTalveySelected && ( */}
                                            <tr>
                                              {/* {talveyDetails.length > 0 &&
                                    talveyDetails.map((data, idx) => (
                                      <> */}
                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                }}
                                              >
                                                <span className="quest-text-pad">
                                                  TALVEY
                                                  <sup className="supscript">
                                                    TM
                                                  </sup>{" "}
                                                  (talquetamab-tgvs)
                                                </span>
                                              </td>

                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                }}
                                              >
                                                <div>
                                                  <div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <input
                                                        type="radio"
                                                        id={idx}
                                                        name={
                                                          "Q38TalveyRd" + idx
                                                        }
                                                        value={1}
                                                        checked={
                                                          data.Q38TalveyRd === 1
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                          marginLeft: "-5px",
                                                        }}
                                                      >
                                                        <DateControl
                                                          ctrlId={
                                                            "dt_dosefreq_tal_dt-" +
                                                            idx
                                                          }
                                                          HandleDateChange={
                                                            HandleRepeaterDateChange
                                                          }
                                                          date={
                                                            data.dt_dosefreq_tal_dt
                                                          }
                                                        />
                                                      </span>
                                                    </div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="option-fbox">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "Q38TalveyRd" + idx
                                                          }
                                                          value={99}
                                                          checked={
                                                            data.Q38TalveyRd ===
                                                            99
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />

                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Date of dose frequency
                                                          reduction unknown
                                                        </span>
                                                      </label>
                                                    </div>
                                         
                                           
                                                    <ErrorField
                                                      show={
                                                        data.Q38TalEmptyHard
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.Q38TalEmptyHard
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                    <ErrorField
                                                      show={
                                                        data.Q38TalDateEmpty
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.Q38TalDateEmpty
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                    <ErrorField
                                                      show={
                                                        data.Q38TalDateInvalid
                                                      }
                                                      message={
                                                        TTCharacteristics_MSGS.Q38TalDateInvalid
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                              {/* </> */}
                                              {/* ))} */}

                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                }}
                                              >
                                                <div
                                                  className="option-without-input"
                                                  style={{ display: "flex" }}
                                                >
                                                  <label className="option-fbox">
                                                    <input
                                                      type="radio"
                                                      id={idx}
                                                      name={
                                                        "q38_Tal_Dose_week_frquencyRd" +
                                                        idx
                                                      }
                                                      value={1}
                                                      checked={
                                                        data.q38_Tal_Dose_week_frquencyRd ===
                                                        1
                                                      }
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    <span
                                                      style={{
                                                        paddingLeft: "10px",
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <span>
                                                        Once every &nbsp;
                                                      </span>
                                                      <span>
                                                        <input
                                                          className="input-dash-custom"
                                                          id={idx}
                                                          name={
                                                            "q38_Tal_Dose_week_frquency_txt" +
                                                            idx
                                                          }
                                                          type="text"
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                          value={
                                                            data.q38_Tal_Dose_week_frquency_txt
                                                          }
                                                          onKeyDown={(e) => {
                                                            // Allow backspace, arrow keys, and delete
                                                            if (
                                                              [
                                                                "Backspace",
                                                                "ArrowLeft",
                                                                "ArrowRight",
                                                                "Delete",
                                                                "Tab",
                                                              ].includes(e.key)
                                                            ) {
                                                              return;
                                                            }

                                                            // Prevent non-numeric characters, except '.'
                                                            if (
                                                              !/[0-9.]/.test(
                                                                e.key
                                                              )
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }

                                                            const value =
                                                              e.target.value;
                                                            const selectionStart =
                                                              e.target
                                                                .selectionStart;
                                                            const newValue =
                                                              value.slice(
                                                                0,
                                                                selectionStart
                                                              ) +
                                                              e.key +
                                                              value.slice(
                                                                e.target
                                                                  .selectionEnd
                                                              );

                                                            // Prevent multiple '.'
                                                            if (
                                                              e.key === "." &&
                                                              value.includes(
                                                                "."
                                                              )
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }

                                                            // Prevent '.' as the first character
                                                            if (
                                                              e.key === "." &&
                                                              value === ""
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }

                                                            // Allow only one digit after decimal point
                                                            if (
                                                              newValue.includes(
                                                                "."
                                                              ) &&
                                                              newValue.split(
                                                                "."
                                                              )[1].length > 2
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }
                                                          }}
                                                        />
                                                      </span>
                                                      week
                                                    </span>
                                                  </label>
                                                </div>
                                                 {/* new Unknown */}
                                               <div
                                                className="option-without-input"
                                                style={{ display: "flex" }}
                                              >
                                                <label className="option-fbox">
                                                  <input
                                                    type="radio"
                                                    id={idx}
                                                    name={
                                                      "q38_Tal_Dose_week_frquencyRd" +
                                                      idx
                                                    }
                                                    value={99}
                                                    checked={
                                                      data.q38_Tal_Dose_week_frquencyRd ===
                                                      99
                                                    }
                                                    onChange={HandleRepeaterFieldChange}
                                                  />
                                                
                                                <span
                                                    style={{
                                                      paddingLeft: "10px",
                                                      display: "flex",
                                                    }}
                                                  >Unknown
                                                </span>
                                                </label>
                                              </div>
                                                <div>
                                                  <ErrorField
                                                    show={
                                                      data.q38_Tal_Dose_week_frquencyRd_Empty
                                                    }
                                                    message={
                                                      TTCharacteristics_MSGS.admissionEmpty
                                                    }
                                                    style={{
                                                      paddingLeft: "0px",
                                                    }}
                                                  />

                                                  <ErrorField
                                                    show={
                                                      data.q38_Tal_Dose_week_frquency_txt_Empty
                                                    }
                                                    message={
                                                      TTCharacteristics_MSGS.specifyDosageFrequency
                                                    }
                                                    style={{
                                                      paddingLeft: "0px",
                                                    }}
                                                  />
                                                </div>

                                                <strong>Dosage</strong>

                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    paddingLeft: "15px",
                                                  }}
                                                >
                                                  <div
                                                    className="option-without-input"
                                                    //style={{ display: "flex" }}
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        id={idx}
                                                        name={
                                                          "q38_Tal_Dosage_Rd" +
                                                          idx
                                                        }
                                                        value={1}
                                                        checked={
                                                          data.q38_Tal_Dosage_Rd ===
                                                          1
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        0.40 mg/kg{" "}
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div
                                                    className="option-without-input"
                                                    style={
                                                      {
                                                        // display: "flex",
                                                        // alignItems: "flex-start",
                                                      }
                                                    }
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={idx}
                                                        name={
                                                          "q38_Tal_Dosage_Rd" +
                                                          idx
                                                        }
                                                        value={2}
                                                        checked={
                                                          data.q38_Tal_Dosage_Rd ===
                                                          2
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />

                                                      <span
                                                        // className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        0.80 mg/kg
                                                      </span>
                                                    </label>
                                                  </div>

                                                  <div
                                                    className="option-without-input"
                                                    style={
                                                      {
                                                        //display: "flex",
                                                        //alignItems: "flex-start",
                                                      }
                                                    }
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={idx}
                                                        name={
                                                          "q38_Tal_Dosage_Rd" +
                                                          idx
                                                        }
                                                        value={3}
                                                        checked={
                                                          data.q38_Tal_Dosage_Rd ===
                                                          3
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />

                                                      <span
                                                        // className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        Other, please specify
                                                        <span>
                                                          <input
                                                            className="input-dash"
                                                            id={idx}
                                                            name={
                                                              "q38_Tal_Dosage_OtherTxt" +
                                                              idx
                                                            }
                                                            type="text"
                                                            onChange={
                                                              HandleRepeaterFieldChange
                                                            }
                                                            value={
                                                              data.q38_Tal_Dosage_OtherTxt
                                                            }
                                                          />
                                                        </span>
                                                      </span>
                                                    </label>
                                                  </div>

                                                  <div
                                                    className="option-without-input"
                                                    style={
                                                      {
                                                        // display: "flex",
                                                        // alignItems: "flex-start",
                                                      }
                                                    }
                                                  >
                                                    <label className="option-fbox">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={idx}
                                                        name={
                                                          "q38_Tal_Dosage_Rd" +
                                                          idx
                                                        }
                                                        value={99}
                                                        checked={
                                                          data.q38_Tal_Dosage_Rd ===
                                                          99
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />

                                                      <span
                                                        // className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        Unknown
                                                      </span>
                                                    </label>
                                                  </div>

                                                  <ErrorField
                                                    show={
                                                      data.q38_Tal_Dosage_Rd_Empty
                                                    }
                                                    message={
                                                      TTCharacteristics_MSGS.admissionEmpty
                                                    }
                                                    style={{
                                                      paddingLeft: "0px",
                                                    }}
                                                  />

                                                  <ErrorField
                                                    show={
                                                      data.q38_Tal_Dosage_OtherTxt_Empty
                                                    }
                                                    message={
                                                      TTCharacteristics_MSGS.specifyOtherText
                                                    }
                                                    style={{
                                                      paddingLeft: "0px",
                                                    }}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          
                                            {/* Talvey */}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                              {Q38TalDetails &&
                                Q38TalDetails.length > 0 &&
                                Q38TalDetails.length < 550 && (
                                  <div className="sub-question-bot-sapce-ul mt-2">
                                    <div
                                      className="answer-list-text"
                                      onClick={AddQ38TalveyRow}
                                    >
                                      <a>
                                        <img
                                          width="18px"
                                          src="../Assets/images/plus.png"
                                          alt=""
                                        />
                                      </a>
                                      <a className="radio-text-padding">
                                        Click here to add another Dose of
                                        frequency
                                      </a>
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}
                        {/* Q38Talvey */}

                        {/* Question 39  start*/}

                        {/* show if Techvayli selected */}
                        {Q39TechConditionMet && (
                          <>
                            <br />
                            <div className="">
                              {Q37TechDetails &&
                                Q37TechDetails.length > 0 &&
                                Q37TechDetails.map((data, idx) => (
                                  <>
                                    <div className="sub-question-bot-sapce ">
                                      <div className="question question-weight">
                                        <span>Q39.</span>
                                        <span className="quest-text-pad">
                                          Which medications were used, if any,
                                          during the step-up dosing phase of{" "}
                                          <span
                                            style={{
                                              color:
                                                PageDesignConstants.fontColor,
                                              fontWeight: "bolder",
                                            }}
                                          >
                                            TECVAYLI® (teclistamab-cqyv)
                                          </span>{" "}
                                          <i>Select all that apply.</i>
                                        </span>
                                      </div>
                                      <br />

                                      <div className="double-dig-answer">
                                        <div className="">
                                          <label>
                                            <input
                                              type="checkbox"
                                              id={idx}
                                              name={"tocilizumab_tech" + idx}
                                              checked={data.tocilizumab_tech}
                                              onChange={HandleFieldChange}
                                            />
                                            <span className="radio-text-padding">
                                              Tocilizumab
                                            </span>
                                          </label>
                                        </div>

                                        <div className="">
                                          <label>
                                            <input
                                              type="checkbox"
                                              id={idx}
                                              name={"Granulocyte" + idx}
                                              checked={data.Granulocyte}
                                              onChange={HandleFieldChange}
                                            />
                                            <span className="radio-text-padding">
                                              Granulocyte colony-stimulating
                                              factor (G-CSF)
                                            </span>
                                          </label>
                                        </div>
                                        {/* Phase 2  */}
                                        <div className="">
                                          <label>
                                            <input
                                              type="checkbox"
                                              id={idx}
                                              name={"SteroidsTech" + idx}
                                              checked={data.SteroidsTech}
                                              onChange={HandleFieldChange}
                                            />
                                            <span className="radio-text-padding">
                                              Steroids
                                            </span>
                                          </label>
                                        </div>

                                        <div className="option-without-input">
                                          <label className="option-fbox">
                                            <input
                                              type="radio"
                                              id={idx}
                                              name={"noneoftheabove_tech" + idx}
                                              value={99}
                                              checked={
                                                data.noneoftheabove_tech === 99
                                              }
                                              onChange={HandleFieldChange}
                                            />

                                            <span className="radio-text-padding">
                                              None of the above{" "}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <ErrorField
                                        show={data.Q39TechEmptyHard}
                                        message={
                                          TTCharacteristics_MSGS.Q39TechEmptyHard
                                        }
                                      />
                                    </div>

                                    {/* Q1.1 */}
                                    {data.tocilizumab_tech && (
                                      <>
                                        <div
                                          className="question question-weight"
                                          style={{
                                            // backgroundColor: '#F6F6F6',
                                            marginLeft: "28px",
                                            marginBlock: "6px",
                                            padding: "20px 28px",
                                          }}
                                        >
                                          <span>Q39.1.</span>
                                          <span className="quest-text-pad">
                                            Summarize the reasons why
                                            Tocilizumab was used and dosing
                                            information while receiving
                                            treatment with{" "}
                                            <span
                                              style={{
                                                color:
                                                  PageDesignConstants.fontColor,
                                                fontWeight: "bolder",
                                              }}
                                            >
                                              TECVAYLI® (teclistamab-cqyv)
                                            </span>{" "}
                                            <i>Select all that apply.</i>
                                          </span>
                                        </div>

                                        <div className="dependent-sub-section">
                                          {/* TECVAYLI® */}
                                          <div className="question-bot-sapce">
                                            <div className="sub-question">
                                              <table
                                                style={{
                                                  border: "1px solid black",
                                                  borderCollapse: "collapse",
                                                  width: "100%",
                                                }}
                                              >
                                                <thead>
                                                  <tr>
                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                        textAlign: "center",
                                                        width: "20%",
                                                      }}
                                                    >
                                                      Treatment
                                                    </th>
                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                        textAlign: "center",
                                                        width: "15%",
                                                      }}
                                                    >
                                                      Medication
                                                    </th>
                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                        textAlign: "center",
                                                        width: "45%",
                                                      }}
                                                    >
                                                      Reason for receiving
                                                      medication
                                                    </th>
                                                  </tr>
                                                </thead>

                                                <tbody>
                                                  {/* Tecvayli */}

                                                  <tr>
                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <span className="quest-text-pad">
                                                        TECVAYLI®
                                                        (teclistamab-cqyv)
                                                      </span>
                                                    </td>

                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div>
                                                        <div>
                                                          <div
                                                            className="option-without-input"
                                                            style={{
                                                              display: "flex",
                                                              alignItems:
                                                                "flex-start",
                                                            }}
                                                          >
                                                            <label className="option-fbox">
                                                              <span
                                                                className="radio-text-padding"
                                                                style={{
                                                                  paddingLeft:
                                                                    "10px",
                                                                }}
                                                              >
                                                                Tocilizumab
                                                              </span>
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>

                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div>
                                                        <div>
                                                          <div className="">
                                                            <label className="long-text-option">
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "cytokin_tech" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.cytokin_tech
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span className="radio-text-padding">
                                                                Prophylactic for
                                                                cytokine release
                                                                syndrome (CRS)
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "crs_tech" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.crs_tech
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span className="radio-text-padding">
                                                                Treatment or
                                                                supportive care
                                                                for CRS
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "neurotoxicity_tech" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.neurotoxicity_tech
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span className="radio-text-padding">
                                                                Prophylactic for
                                                                neurotoxicity
                                                                events
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "tmt_neurotoxicity_tech" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.tmt_neurotoxicity_tech
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span className="radio-text-padding">
                                                                Treatment or
                                                                supportive care
                                                                for
                                                                neurotoxicity
                                                                events
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="radio"
                                                                id={idx}
                                                                name={
                                                                  "tmt_tech_unknown" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.tmt_tech_unknown
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span className="radio-text-padding">
                                                                Unknown
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <ErrorField
                                                            show={
                                                              data.Q39techReasonHard
                                                            }
                                                            message={
                                                              TTCharacteristics_MSGS.Q39techReasonHard
                                                            }
                                                            style={{
                                                              paddingLeft:
                                                                "0px",
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    {/* 1.3 */}

                                    {data.Granulocyte && (
                                      <>
                                        <div
                                          className="question question-weight"
                                          style={{
                                            // backgroundColor: '#F6F6F6',
                                            marginLeft: "28px",
                                            marginBlock: "6px",
                                            padding: "20px 28px",
                                          }}
                                        >
                                          <span>Q39.2.</span>
                                          <span className="quest-text-pad">
                                            Why was G-CSF used while receiving
                                            treatment with{" "}
                                            <span
                                              style={{
                                                color:
                                                  PageDesignConstants.fontColor,
                                                fontWeight: "bolder",
                                              }}
                                            >
                                              TECVAYLI® (teclistamab-cqyv)
                                            </span>{" "}
                                            <i>Select all that apply.</i>
                                          </span>
                                        </div>

                                        <div className="dependent-sub-section">
                                          {/* TECVAYLI® */}
                                          <div className="question-bot-sapce">
                                            <div className="sub-question">
                                              <table
                                                style={{
                                                  border: "1px solid black",
                                                  borderCollapse: "collapse",
                                                  width: "100%",
                                                }}
                                              >
                                                <thead>
                                                  <tr>
                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                        textAlign: "center",
                                                        width: "25%",
                                                      }}
                                                    >
                                                      Treatment
                                                    </th>
                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                        textAlign: "center",
                                                        width: "25%",
                                                      }}
                                                    >
                                                      Medication
                                                    </th>

                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                        textAlign: "center",
                                                        width: "45%",
                                                      }}
                                                    >
                                                      Reason for receiving
                                                      medication
                                                    </th>
                                                  </tr>
                                                </thead>

                                                <tbody>
                                                  {/* Tecvayli */}

                                                  <tr>
                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <span className="quest-text-pad">
                                                        TECVAYLI®
                                                        (teclistamab-cqyv)
                                                      </span>
                                                    </td>

                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div>
                                                        <div>
                                                          <div
                                                            className="option-without-input"
                                                            style={{
                                                              display: "flex",
                                                              alignItems:
                                                                "flex-start",
                                                            }}
                                                          >
                                                            <label>
                                                              <span
                                                                className="radio-text-padding"
                                                                style={{
                                                                  paddingLeft:
                                                                    "10px",
                                                                }}
                                                              >
                                                                Granulocyte
                                                                colony-stimulating
                                                                factor (G-CSF)
                                                              </span>
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>

                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div>
                                                        <div>
                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "prophylactic_use_tech" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.prophylactic_use_tech
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span className="radio-text-padding">
                                                                Prophylactic use
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "oth_use_tech" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.oth_use_tech
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span className="radio-text-padding">
                                                                Other use
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="radio"
                                                                id={idx}
                                                                value={99}
                                                                name={
                                                                  "unknown_tech" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.unknown_tech ===
                                                                  99
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span className="radio-text-padding">
                                                                Unknown
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <ErrorField
                                                            show={
                                                              data.Q39techGranduloEmptyHard
                                                            }
                                                            message={
                                                              TTCharacteristics_MSGS.Q39techGranduloEmptyHard
                                                            }
                                                            style={{
                                                              paddingLeft:
                                                                "0px",
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    {/* New Phase  */}
                                    {data.SteroidsTech && (
                                      <>
                                        <div
                                          className="question question-weight"
                                          style={{
                                            // backgroundColor: '#F6F6F6',
                                            marginLeft: "28px",
                                            marginBlock: "6px",
                                            padding: "20px 28px",
                                          }}
                                        >
                                          <span>Q39.3.</span>
                                          <span className="quest-text-pad">
                                            Summarize the reasons why steroids
                                            were used and dosing information
                                            while receiving treatment with{" "}
                                            <span
                                              style={{
                                                color:
                                                  PageDesignConstants.fontColor,
                                                fontWeight: "bolder",
                                              }}
                                            >
                                              TECVAYLI®
                                            </span>{" "}
                                            <i>Select all that apply.</i>
                                          </span>
                                        </div>

                                        <div className="dependent-sub-section">
                                          {/* TECVAYLI® */}
                                          <div className="question-bot-sapce">
                                            <div className="sub-question">
                                              <table
                                                style={{
                                                  border: "1px solid black",
                                                  borderCollapse: "collapse",
                                                  width: "100%",
                                                }}
                                              >
                                                <thead>
                                                  <tr>
                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                        textAlign: "center",
                                                        width: "5%",
                                                      }}
                                                    >
                                                      Treatment
                                                    </th>
                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                        textAlign: "center",
                                                        width: "5%",
                                                      }}
                                                    >
                                                      Medication
                                                    </th>

                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                        textAlign: "center",
                                                        width: "35%",
                                                      }}
                                                    >
                                                      Name and dose of the
                                                      steroid(s) used
                                                    </th>

                                                    <th
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                        textAlign: "center",
                                                        width: "50%",
                                                      }}
                                                    >
                                                      Reason for receiving
                                                      medication
                                                    </th>
                                                  </tr>
                                                </thead>

                                                <tbody>
                                                  {/* Tecvayli */}

                                                  <tr>
                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <span className="quest-text-pad">
                                                        TECVAYLI
                                                      </span>
                                                    </td>

                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div>
                                                        <div>
                                                          <div
                                                            className="option-without-input"
                                                            style={{
                                                              display: "flex",
                                                              alignItems:
                                                                "flex-start",
                                                            }}
                                                          >
                                                            <label>
                                                              <span
                                                                className="radio-text-padding"
                                                                style={{
                                                                  paddingLeft:
                                                                    "10px",
                                                                }}
                                                              >
                                                                Steroids
                                                              </span>
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>

                                                    {/* Start */}

                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div>
                                                        <div>
                                                          <div className="option-fbox">
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "betamethasone_tech" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.betamethasone_tech
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span
                                                                style={{
                                                                  paddingLeft:
                                                                    "15px",
                                                                }}
                                                              >
                                                                Betamethasone
                                                                (total dose:
                                                                <input
                                                                  className="sub-input-dash-num"
                                                                  id={idx}
                                                                  name={
                                                                    "betamethasone_techNum" +
                                                                    idx
                                                                  }
                                                                  type="text"
                                                                  value={
                                                                    data.betamethasone_techNum
                                                                  }
                                                                  onKeyDown={(
                                                                    e
                                                                  ) => {
                                                                    // Allow backspace, arrow keys, delete, and tab
                                                                    if (
                                                                      [
                                                                        "Backspace",
                                                                        "ArrowLeft",
                                                                        "ArrowRight",
                                                                        "Delete",
                                                                        "Tab",
                                                                      ].includes(
                                                                        e.key
                                                                      )
                                                                    ) {
                                                                      return;
                                                                    }

                                                                    // Prevent non-numeric characters
                                                                    if (
                                                                      !/[0-9]/.test(
                                                                        e.key
                                                                      )
                                                                    ) {
                                                                      e.preventDefault();
                                                                      return;
                                                                    }

                                                                    const value =
                                                                      e.target
                                                                        .value;
                                                                    const selectionStart =
                                                                      e.target
                                                                        .selectionStart;
                                                                    const newValue =
                                                                      value.slice(
                                                                        0,
                                                                        selectionStart
                                                                      ) +
                                                                      e.key +
                                                                      value.slice(
                                                                        e.target
                                                                          .selectionEnd
                                                                      );

                                                                    // Prevent leading zeros only if more than one digit is present
                                                                    if (
                                                                      newValue.length >
                                                                        1 &&
                                                                      newValue[0] ===
                                                                        "0"
                                                                    ) {
                                                                      e.preventDefault();
                                                                      return;
                                                                    }
                                                                  }}
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                  maxLength={10}
                                                                />
                                                                mg)
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "dexamethasone_tech" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.dexamethasone_tech
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span
                                                                style={{
                                                                  paddingLeft:
                                                                    "15px",
                                                                }}
                                                              >
                                                                Dexamethasone
                                                                (total dose:
                                                                <input
                                                                  className="sub-input-dash-num"
                                                                  id={idx}
                                                                  name={
                                                                    "dexamethasone_techNum" +
                                                                    idx
                                                                  }
                                                                  type="text"
                                                                  value={
                                                                    data.dexamethasone_techNum
                                                                  }
                                                                  onKeyDown={(
                                                                    e
                                                                  ) => {
                                                                    // Allow backspace, arrow keys, delete, and tab
                                                                    if (
                                                                      [
                                                                        "Backspace",
                                                                        "ArrowLeft",
                                                                        "ArrowRight",
                                                                        "Delete",
                                                                        "Tab",
                                                                      ].includes(
                                                                        e.key
                                                                      )
                                                                    ) {
                                                                      return;
                                                                    }

                                                                    // Prevent non-numeric characters
                                                                    if (
                                                                      !/[0-9]/.test(
                                                                        e.key
                                                                      )
                                                                    ) {
                                                                      e.preventDefault();
                                                                      return;
                                                                    }

                                                                    const value =
                                                                      e.target
                                                                        .value;
                                                                    const selectionStart =
                                                                      e.target
                                                                        .selectionStart;
                                                                    const newValue =
                                                                      value.slice(
                                                                        0,
                                                                        selectionStart
                                                                      ) +
                                                                      e.key +
                                                                      value.slice(
                                                                        e.target
                                                                          .selectionEnd
                                                                      );

                                                                    // Prevent leading zeros only if more than one digit is present
                                                                    if (
                                                                      newValue.length >
                                                                        1 &&
                                                                      newValue[0] ===
                                                                        "0"
                                                                    ) {
                                                                      e.preventDefault();
                                                                      return;
                                                                    }
                                                                  }}
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                  maxLength={10}
                                                                />
                                                                mg)
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="">
                                                            <label className="option-fbox">
                                                              <input
                                                                type="checkbox"
                                                                style={{
                                                                  marginTop:
                                                                    "6px",
                                                                }}
                                                                id={idx}
                                                                name={
                                                                  "hydrocortisone_tech" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.hydrocortisone_tech
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span
                                                                style={{
                                                                  paddingLeft:
                                                                    "15px",
                                                                }}
                                                              >
                                                                Hydrocortisone
                                                                (oral or
                                                                intravenous
                                                                only)
                                                                <br></br>(total
                                                                dose:
                                                                <input
                                                                  className="sub-input-dash-num"
                                                                  id={idx}
                                                                  name={
                                                                    "hydrocortisone_techNum" +
                                                                    idx
                                                                  }
                                                                  type="text"
                                                                  value={
                                                                    data.hydrocortisone_techNum
                                                                  }
                                                                  onKeyDown={(
                                                                    e
                                                                  ) => {
                                                                    // Allow backspace, arrow keys, delete, and tab
                                                                    if (
                                                                      [
                                                                        "Backspace",
                                                                        "ArrowLeft",
                                                                        "ArrowRight",
                                                                        "Delete",
                                                                        "Tab",
                                                                      ].includes(
                                                                        e.key
                                                                      )
                                                                    ) {
                                                                      return;
                                                                    }

                                                                    // Prevent non-numeric characters
                                                                    if (
                                                                      !/[0-9]/.test(
                                                                        e.key
                                                                      )
                                                                    ) {
                                                                      e.preventDefault();
                                                                      return;
                                                                    }

                                                                    const value =
                                                                      e.target
                                                                        .value;
                                                                    const selectionStart =
                                                                      e.target
                                                                        .selectionStart;
                                                                    const newValue =
                                                                      value.slice(
                                                                        0,
                                                                        selectionStart
                                                                      ) +
                                                                      e.key +
                                                                      value.slice(
                                                                        e.target
                                                                          .selectionEnd
                                                                      );

                                                                    // Prevent leading zeros only if more than one digit is present
                                                                    if (
                                                                      newValue.length >
                                                                        1 &&
                                                                      newValue[0] ===
                                                                        "0"
                                                                    ) {
                                                                      e.preventDefault();
                                                                      return;
                                                                    }
                                                                  }}
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                  maxLength={10}
                                                                />
                                                                mg)
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "methylprednisolone" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.methylprednisolone
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span
                                                                style={{
                                                                  paddingLeft:
                                                                    "15px",
                                                                }}
                                                              >
                                                                Methylprednisolone
                                                                (total dose:
                                                                <input
                                                                  className="sub-input-dash-num"
                                                                  id={idx}
                                                                  name={
                                                                    "methylprednisoloneNum" +
                                                                    idx
                                                                  }
                                                                  type="text"
                                                                  value={
                                                                    data.methylprednisoloneNum
                                                                  }
                                                                  onKeyDown={(
                                                                    e
                                                                  ) => {
                                                                    // Allow backspace, arrow keys, delete, and tab
                                                                    if (
                                                                      [
                                                                        "Backspace",
                                                                        "ArrowLeft",
                                                                        "ArrowRight",
                                                                        "Delete",
                                                                        "Tab",
                                                                      ].includes(
                                                                        e.key
                                                                      )
                                                                    ) {
                                                                      return;
                                                                    }

                                                                    // Prevent non-numeric characters
                                                                    if (
                                                                      !/[0-9]/.test(
                                                                        e.key
                                                                      )
                                                                    ) {
                                                                      e.preventDefault();
                                                                      return;
                                                                    }

                                                                    const value =
                                                                      e.target
                                                                        .value;
                                                                    const selectionStart =
                                                                      e.target
                                                                        .selectionStart;
                                                                    const newValue =
                                                                      value.slice(
                                                                        0,
                                                                        selectionStart
                                                                      ) +
                                                                      e.key +
                                                                      value.slice(
                                                                        e.target
                                                                          .selectionEnd
                                                                      );

                                                                    // Prevent leading zeros only if more than one digit is present
                                                                    if (
                                                                      newValue.length >
                                                                        1 &&
                                                                      newValue[0] ===
                                                                        "0"
                                                                    ) {
                                                                      e.preventDefault();
                                                                      return;
                                                                    }
                                                                  }}
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                  maxLength={10}
                                                                />
                                                                mg)
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "prednisolone_tech" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.prednisolone_tech
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span
                                                                style={{
                                                                  paddingLeft:
                                                                    "15px",
                                                                }}
                                                              >
                                                                Prednisolone
                                                                (total dose:
                                                                <input
                                                                  className="sub-input-dash-num"
                                                                  id={idx}
                                                                  name={
                                                                    "prednisolone_techNum" +
                                                                    idx
                                                                  }
                                                                  type="text"
                                                                  value={
                                                                    data.prednisolone_techNum
                                                                  }
                                                                  onKeyDown={(
                                                                    e
                                                                  ) => {
                                                                    // Allow backspace, arrow keys, delete, and tab
                                                                    if (
                                                                      [
                                                                        "Backspace",
                                                                        "ArrowLeft",
                                                                        "ArrowRight",
                                                                        "Delete",
                                                                        "Tab",
                                                                      ].includes(
                                                                        e.key
                                                                      )
                                                                    ) {
                                                                      return;
                                                                    }

                                                                    // Prevent non-numeric characters
                                                                    if (
                                                                      !/[0-9]/.test(
                                                                        e.key
                                                                      )
                                                                    ) {
                                                                      e.preventDefault();
                                                                      return;
                                                                    }

                                                                    const value =
                                                                      e.target
                                                                        .value;
                                                                    const selectionStart =
                                                                      e.target
                                                                        .selectionStart;
                                                                    const newValue =
                                                                      value.slice(
                                                                        0,
                                                                        selectionStart
                                                                      ) +
                                                                      e.key +
                                                                      value.slice(
                                                                        e.target
                                                                          .selectionEnd
                                                                      );

                                                                    // Prevent leading zeros only if more than one digit is present
                                                                    if (
                                                                      newValue.length >
                                                                        1 &&
                                                                      newValue[0] ===
                                                                        "0"
                                                                    ) {
                                                                      e.preventDefault();
                                                                      return;
                                                                    }
                                                                  }}
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                  maxLength={10}
                                                                />
                                                                mg)
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "prednisone_tech" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.prednisone_tech
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span
                                                                style={{
                                                                  paddingLeft:
                                                                    "15px",
                                                                }}
                                                              >
                                                                Prednisone
                                                                (total dose:
                                                                <input
                                                                  className="sub-input-dash-num"
                                                                  id={idx}
                                                                  name={
                                                                    "prednisone_techNum" +
                                                                    idx
                                                                  }
                                                                  type="text"
                                                                  value={
                                                                    data.prednisone_techNum
                                                                  }
                                                                  onKeyDown={(
                                                                    e
                                                                  ) => {
                                                                    // Allow backspace, arrow keys, delete, and tab
                                                                    if (
                                                                      [
                                                                        "Backspace",
                                                                        "ArrowLeft",
                                                                        "ArrowRight",
                                                                        "Delete",
                                                                        "Tab",
                                                                      ].includes(
                                                                        e.key
                                                                      )
                                                                    ) {
                                                                      return;
                                                                    }

                                                                    // Prevent non-numeric characters
                                                                    if (
                                                                      !/[0-9]/.test(
                                                                        e.key
                                                                      )
                                                                    ) {
                                                                      e.preventDefault();
                                                                      return;
                                                                    }

                                                                    const value =
                                                                      e.target
                                                                        .value;
                                                                    const selectionStart =
                                                                      e.target
                                                                        .selectionStart;
                                                                    const newValue =
                                                                      value.slice(
                                                                        0,
                                                                        selectionStart
                                                                      ) +
                                                                      e.key +
                                                                      value.slice(
                                                                        e.target
                                                                          .selectionEnd
                                                                      );

                                                                    // Prevent leading zeros only if more than one digit is present
                                                                    if (
                                                                      newValue.length >
                                                                        1 &&
                                                                      newValue[0] ===
                                                                        "0"
                                                                    ) {
                                                                      e.preventDefault();
                                                                      return;
                                                                    }
                                                                  }}
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                  maxLength={10}
                                                                />
                                                                mg)
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "triamcinolone_tech" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.triamcinolone_tech
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span
                                                                style={{
                                                                  paddingLeft:
                                                                    "15px",
                                                                }}
                                                              >
                                                                Triamcinolone
                                                                (total dose:
                                                                <input
                                                                  className="sub-input-dash-num"
                                                                  id={idx}
                                                                  name={
                                                                    "triamcinolone_techNum" +
                                                                    idx
                                                                  }
                                                                  type="text"
                                                                  value={
                                                                    data.triamcinolone_techNum
                                                                  }
                                                                  onKeyDown={(
                                                                    e
                                                                  ) => {
                                                                    // Allow backspace, arrow keys, delete, and tab
                                                                    if (
                                                                      [
                                                                        "Backspace",
                                                                        "ArrowLeft",
                                                                        "ArrowRight",
                                                                        "Delete",
                                                                        "Tab",
                                                                      ].includes(
                                                                        e.key
                                                                      )
                                                                    ) {
                                                                      return;
                                                                    }

                                                                    // Prevent non-numeric characters
                                                                    if (
                                                                      !/[0-9]/.test(
                                                                        e.key
                                                                      )
                                                                    ) {
                                                                      e.preventDefault();
                                                                      return;
                                                                    }

                                                                    const value =
                                                                      e.target
                                                                        .value;
                                                                    const selectionStart =
                                                                      e.target
                                                                        .selectionStart;
                                                                    const newValue =
                                                                      value.slice(
                                                                        0,
                                                                        selectionStart
                                                                      ) +
                                                                      e.key +
                                                                      value.slice(
                                                                        e.target
                                                                          .selectionEnd
                                                                      );

                                                                    // Prevent leading zeros only if more than one digit is present
                                                                    if (
                                                                      newValue.length >
                                                                        1 &&
                                                                      newValue[0] ===
                                                                        "0"
                                                                    ) {
                                                                      e.preventDefault();
                                                                      return;
                                                                    }
                                                                  }}
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                  maxLength={10}
                                                                />
                                                                mg)
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="radio"
                                                                id={idx}
                                                                name={
                                                                  "dose_name_tech_unknown" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.dose_name_tech_unknown
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span className="radio-text-padding">
                                                                Unknown
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <ErrorField
                                                            show={
                                                              data.name_dose_steroidsHardEmpty
                                                            }
                                                            message={
                                                              TTCharacteristics_MSGS.name_dose_steroidsHardEmpty
                                                            }
                                                            style={{
                                                              paddingLeft:
                                                                "0px",
                                                            }}
                                                          />

                                                          <ErrorField
                                                            show={
                                                              data.steroids_tech_txtEmpty
                                                            }
                                                            message={
                                                              TTCharacteristics_MSGS.steroids_tech_txtEmpty
                                                            }
                                                            style={{
                                                              paddingLeft:
                                                                "0px",
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </td>

                                                    {/* End */}

                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div>
                                                        <div>
                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "steroid_pre_tmt_tech" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.steroid_pre_tmt_tech
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span className="radio-text-padding">
                                                                As a
                                                                pre-treatment
                                                                per label
                                                                recommendation
                                                              </span>
                                                            </label>
                                                          </div>

                                                                                    <div className="">
                                                                                        <label className="option-fbox">
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "cytokin_tech_steroids" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.cytokin_tech_steroids
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span
                                                                style={{
                                                                  paddingLeft:
                                                                    "15px",
                                                                }}
                                                              >
                                                                Prophylactic for
                                                                cytokine release
                                                                syndrome (on top
                                                                of the
                                                                pretreatment
                                                                dose)
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "crs_tech_steroids" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.crs_tech_steroids
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span className="radio-text-padding">
                                                                Treatment or
                                                                supportive care
                                                                for CRS
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="">
                                                                                        <label className="option-fbox">
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "neurotoxicity_tech_steroids" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.neurotoxicity_tech_steroids
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span
                                                                style={{
                                                                  paddingLeft:
                                                                    "15px",
                                                                }}
                                                              >
                                                                Prophylactic for
                                                                neurotoxicity
                                                                events (on top
                                                                of the
                                                                pretreatment
                                                                dose)
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <div className="">
                                                                                        <label className="option-fbox">
                                                              <input
                                                                type="checkbox"
                                                                id={idx}
                                                                name={
                                                                  "tmt_neurotoxicity_tech_steroids" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.tmt_neurotoxicity_tech_steroids
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span
                                                                style={{
                                                                  paddingLeft:
                                                                    "15px",
                                                                }}
                                                              >
                                                                Treatment or
                                                                supportive care
                                                                for
                                                                neurotoxicity
                                                                events
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <div className="">
                                                            <label>
                                                              <input
                                                                type="radio"
                                                                id={idx}
                                                                name={
                                                                  "tmt_steroids_tech_unknown" +
                                                                  idx
                                                                }
                                                                checked={
                                                                  data.tmt_steroids_tech_unknown
                                                                }
                                                                onChange={
                                                                  HandleFieldChange
                                                                }
                                                              />
                                                              <span className="radio-text-padding">
                                                                Unknown
                                                              </span>
                                                            </label>
                                                          </div>

                                                          <ErrorField
                                                            show={
                                                              data.Q39techSteroidsEmptyHard
                                                            }
                                                            message={
                                                              TTCharacteristics_MSGS.Q39techSteroidsEmptyHard
                                                            }
                                                            style={{
                                                              paddingLeft:
                                                                "0px",
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                ))}
                            </div>
                          </>
                        )}
                        {/* -------------------- */}
                        {/*Show if Talvey selected  */}

                        {Q39TalConditionMet && (
                          <>
                            <br />
                            <div className="">
                              {Q37TalDetails &&
                                Q37TalDetails.length > 0 &&
                                Q37TalDetails.map((data, idx) => (
                                  <div className="sub-question-bot-sapce ">
                                    <div className="question question-weight">
                                      <span>Q39.</span>
                                      <span className="quest-text-pad">
                                        Which medications were used, if any,
                                        during the step-up dosing phase of{" "}
                                        <span
                                          style={{
                                            color:
                                              PageDesignConstants.fontColor,
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          TALVEY<sup>TM</sup> (talquetamab-tgvs)
                                        </span>{" "}
                                        <i>Select all that apply.</i>
                                      </span>
                                    </div>
                                    <br />

                                    <div className="double-dig-answer">
                                      <div className="">
                                        <label>
                                          <input
                                            type="checkbox"
                                            id={idx}
                                            name={"tocilizumab_tal" + idx}
                                            checked={data.tocilizumab_tal}
                                            onChange={HandleFieldChange}
                                          />
                                          <span className="radio-text-padding">
                                            Tocilizumab
                                          </span>
                                        </label>
                                      </div>

                                      <div className="">
                                        <label>
                                          <input
                                            type="checkbox"
                                            id={idx}
                                            name={"Steroids" + idx}
                                            checked={data.Steroids}
                                            onChange={HandleFieldChange}
                                          />
                                          <span className="radio-text-padding">
                                            Steroids
                                          </span>
                                        </label>
                                      </div>

                                      <div className="option-without-input">
                                        <label className="option-fbox">
                                          <input
                                            type="radio"
                                            id={idx}
                                            name={"noneoftheabove_tal" + idx}
                                            value={99}
                                            checked={
                                              data.noneoftheabove_tal === 99
                                            }
                                            onChange={HandleFieldChange}
                                          />

                                          <span className="radio-text-padding">
                                            None of the above{" "}
                                          </span>
                                        </label>
                                      </div>
                                      <ErrorField
                                        show={data.Q39TalEmptyHard}
                                        message={
                                          TTCharacteristics_MSGS.Q39TalEmptyHard
                                        }
                                        style={{
                                          paddingLeft: "0px",
                                        }}
                                      />

                                      {/* Q1.2 */}
                                      {data.tocilizumab_tal && (
                                        <>
                                          <div
                                            className="question question-weight"
                                            style={{
                                              // backgroundColor: '#F6F6F6',
                                              marginLeft: "28px",
                                              marginBlock: "6px",
                                              padding: "20px 28px",
                                            }}
                                          >
                                            <span>Q39.1.</span>
                                            <span className="quest-text-pad">
                                              Summarize the reasons why
                                              Tocilizumab was used and dosing
                                              information while receiving
                                              treatment with{" "}
                                              <span
                                                style={{
                                                  color:
                                                    PageDesignConstants.fontColor,
                                                  fontWeight: "bolder",
                                                }}
                                              >
                                                TALVEY
                                                <sup className="supscript">
                                                  TM
                                                </sup>{" "}
                                                (talquetamab-tgvs).
                                              </span>{" "}
                                              <i>Select all that apply.</i>
                                            </span>
                                          </div>

                                          <div className="dependent-sub-section">
                                            {/* TECVAYLI® */}
                                            <div className="question-bot-sapce">
                                              <div className="sub-question">
                                                <table
                                                  style={{
                                                    border: "1px solid black",
                                                    borderCollapse: "collapse",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                          width: "20%",
                                                        }}
                                                      >
                                                        Treatment
                                                      </th>
                                                      <th
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                          width: "15%",
                                                        }}
                                                      >
                                                        Medication
                                                      </th>

                                                      <th
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                          width: "45%",
                                                        }}
                                                      >
                                                        Reason for receiving
                                                        medication
                                                      </th>
                                                    </tr>
                                                  </thead>

                                                  <tbody>
                                                    {/* Tecvayli */}

                                                    <tr>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        <span className="quest-text-pad">
                                                          TALVEY<sup>TM</sup>{" "}
                                                          (talquetamab-tgvs)
                                                        </span>
                                                      </td>

                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        <div>
                                                          <div>
                                                            <div
                                                              className="option-without-input"
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "flex-start",
                                                              }}
                                                            >
                                                              <label>
                                                                <span
                                                                  className="radio-text-padding"
                                                                  style={{
                                                                    paddingLeft:
                                                                      "10px",
                                                                  }}
                                                                >
                                                                  Tocilizumab
                                                                </span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>

                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        <div>
                                                          <div>
                                                            <div className="">
                                                                                            <label className="option-fbox">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "cytokin_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.cytokin_tal
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Prophylactic
                                                                  for cytokine
                                                                  release
                                                                  syndrome (CRS)
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div className="">
                                                              <label>
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "crs_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.crs_tal
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Treatment or
                                                                  supportive
                                                                  care for CRS
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div className="">
                                                              <label>
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "neurotoxicity_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.neurotoxicity_tal
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Prophylactic
                                                                  for
                                                                  neurotoxicity
                                                                  events
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div className="">
                                                                                            <label className="option-fbox">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "tmt_neurotoxicity_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.tmt_neurotoxicity_tal
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Treatment or
                                                                  supportive
                                                                  care for
                                                                  neurotoxicity
                                                                  events
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div className="">
                                                              <label>
                                                                <input
                                                                  type="radio"
                                                                  id={idx}
                                                                  name={
                                                                    "tmt_talvey_unknown" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.tmt_talvey_unknown
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Unknown
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <ErrorField
                                                              show={
                                                                data.Q39talReasonHard
                                                              }
                                                              message={
                                                                TTCharacteristics_MSGS.Q39talReasonHard
                                                              }
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}

                                      {/* 1.2 */}

                                      {data.Steroids && (
                                        <>
                                          <div
                                            className="question question-weight"
                                            style={{
                                              // backgroundColor: '#F6F6F6',
                                              marginLeft: "28px",
                                              marginBlock: "6px",
                                              padding: "20px 28px",
                                            }}
                                          >
                                            <span>Q39.2.</span>
                                            <span className="quest-text-pad">
                                              Summarize the reasons why steroids
                                              were used and dosing information
                                              while receiving treatment with{" "}
                                              <span
                                                style={{
                                                  color:
                                                    PageDesignConstants.fontColor,
                                                  fontWeight: "bolder",
                                                }}
                                              >
                                                TALVEY
                                                <sup className="supscript">
                                                  TM
                                                </sup>{" "}
                                                (talquetamab-tgvs).
                                              </span>{" "}
                                              <i>Select all that apply.</i>
                                            </span>
                                          </div>

                                          <div className="dependent-sub-section">
                                            {/* TECVAYLI® */}
                                            <div className="question-bot-sapce">
                                              <div className="sub-question">
                                                <table
                                                  style={{
                                                    border: "1px solid black",
                                                    borderCollapse: "collapse",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                          width: "5%",
                                                        }}
                                                      >
                                                        Treatment
                                                      </th>
                                                      <th
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                          width: "5%",
                                                        }}
                                                      >
                                                        Medication
                                                      </th>

                                                      <th
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                          width: "35%",
                                                        }}
                                                      >
                                                        Name and dose of the
                                                        steroid(s) used
                                                      </th>

                                                      <th
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                          width: "50%",
                                                        }}
                                                      >
                                                        Reason for receiving
                                                        medication
                                                      </th>
                                                    </tr>
                                                  </thead>

                                                  <tbody>
                                                    {/* Tecvayli */}

                                                    <tr>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        <span className="quest-text-pad">
                                                          TALVEY<sup>TM</sup>{" "}
                                                          (talquetamab-tgvs)
                                                        </span>
                                                      </td>

                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        <div>
                                                          <div>
                                                            <div
                                                              className="option-without-input"
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "flex-start",
                                                              }}
                                                            >
                                                              <label>
                                                                <span
                                                                  className="radio-text-padding"
                                                                  style={{
                                                                    paddingLeft:
                                                                      "10px",
                                                                  }}
                                                                >
                                                                  Steroids
                                                                </span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>

                                                      {/* Start */}

                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        <div>
                                                          <div>
                                                            <div className="option-fbox">
                                                              <label>
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "betamethasone_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.betamethasone_tal
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    paddingLeft:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  Betamethasone
                                                                  (total dose:
                                                                  <input
                                                                    className="sub-input-dash-num"
                                                                    id={idx}
                                                                    name={
                                                                      "betamethasone_talNum" +
                                                                      idx
                                                                    }
                                                                    type="text"
                                                                    value={
                                                                      data.betamethasone_talNum
                                                                    }
                                                                    onKeyDown={(
                                                                      e
                                                                    ) => {
                                                                      // Allow backspace, arrow keys, delete, and tab
                                                                      if (
                                                                        [
                                                                          "Backspace",
                                                                          "ArrowLeft",
                                                                          "ArrowRight",
                                                                          "Delete",
                                                                          "Tab",
                                                                        ].includes(
                                                                          e.key
                                                                        )
                                                                      ) {
                                                                        return;
                                                                      }

                                                                      // Prevent non-numeric characters
                                                                      if (
                                                                        !/[0-9]/.test(
                                                                          e.key
                                                                        )
                                                                      ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                      }

                                                                      const value =
                                                                        e.target
                                                                          .value;
                                                                      const selectionStart =
                                                                        e.target
                                                                          .selectionStart;
                                                                      const newValue =
                                                                        value.slice(
                                                                          0,
                                                                          selectionStart
                                                                        ) +
                                                                        e.key +
                                                                        value.slice(
                                                                          e
                                                                            .target
                                                                            .selectionEnd
                                                                        );

                                                                      // Prevent leading zeros only if more than one digit is present
                                                                      if (
                                                                        newValue.length >
                                                                          1 &&
                                                                        newValue[0] ===
                                                                          "0"
                                                                      ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                      }
                                                                    }}
                                                                    onChange={
                                                                      HandleFieldChange
                                                                    }
                                                                    maxLength={10}
                                                                  />
                                                                  mg)
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div className="">
                                                              <label>
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "dexamethasone_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.dexamethasone_tal
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    paddingLeft:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  Dexamethasone
                                                                  (total dose:
                                                                  <input
                                                                    className="sub-input-dash-num"
                                                                    id={idx}
                                                                    name={
                                                                      "dexamethasone_talNum" +
                                                                      idx
                                                                    }
                                                                    type="text"
                                                                    value={
                                                                      data.dexamethasone_talNum
                                                                    }
                                                                    onKeyDown={(
                                                                      e
                                                                    ) => {
                                                                      // Allow backspace, arrow keys, delete, and tab
                                                                      if (
                                                                        [
                                                                          "Backspace",
                                                                          "ArrowLeft",
                                                                          "ArrowRight",
                                                                          "Delete",
                                                                          "Tab",
                                                                        ].includes(
                                                                          e.key
                                                                        )
                                                                      ) {
                                                                        return;
                                                                      }

                                                                      // Prevent non-numeric characters
                                                                      if (
                                                                        !/[0-9]/.test(
                                                                          e.key
                                                                        )
                                                                      ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                      }

                                                                      const value =
                                                                        e.target
                                                                          .value;
                                                                      const selectionStart =
                                                                        e.target
                                                                          .selectionStart;
                                                                      const newValue =
                                                                        value.slice(
                                                                          0,
                                                                          selectionStart
                                                                        ) +
                                                                        e.key +
                                                                        value.slice(
                                                                          e
                                                                            .target
                                                                            .selectionEnd
                                                                        );

                                                                      // Prevent leading zeros only if more than one digit is present
                                                                      if (
                                                                        newValue.length >
                                                                          1 &&
                                                                        newValue[0] ===
                                                                          "0"
                                                                      ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                      }
                                                                    }}
                                                                    onChange={
                                                                      HandleFieldChange
                                                                    }
                                                                    maxLength={10}
                                                                  />
                                                                  mg)
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div className="">
                                                              <label className="option-fbox">
                                                                <input
                                                                  style={{
                                                                    marginTop:
                                                                      "6px",
                                                                  }}
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "hydrocortisone_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.hydrocortisone_tal
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    paddingLeft:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  Hydrocortisone
                                                                  (oral or
                                                                  intravenous
                                                                  only)
                                                                  <br></br>
                                                                  (total dose:
                                                                  <input
                                                                    className="sub-input-dash-num"
                                                                    id={idx}
                                                                    name={
                                                                      "hydrocortisone_talNum" +
                                                                      idx
                                                                    }
                                                                    type="text"
                                                                    value={
                                                                      data.hydrocortisone_talNum
                                                                    }
                                                                    onKeyDown={(
                                                                      e
                                                                    ) => {
                                                                      // Allow backspace, arrow keys, delete, and tab
                                                                      if (
                                                                        [
                                                                          "Backspace",
                                                                          "ArrowLeft",
                                                                          "ArrowRight",
                                                                          "Delete",
                                                                          "Tab",
                                                                        ].includes(
                                                                          e.key
                                                                        )
                                                                      ) {
                                                                        return;
                                                                      }

                                                                      // Prevent non-numeric characters
                                                                      if (
                                                                        !/[0-9]/.test(
                                                                          e.key
                                                                        )
                                                                      ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                      }

                                                                      const value =
                                                                        e.target
                                                                          .value;
                                                                      const selectionStart =
                                                                        e.target
                                                                          .selectionStart;
                                                                      const newValue =
                                                                        value.slice(
                                                                          0,
                                                                          selectionStart
                                                                        ) +
                                                                        e.key +
                                                                        value.slice(
                                                                          e
                                                                            .target
                                                                            .selectionEnd
                                                                        );

                                                                      // Prevent leading zeros only if more than one digit is present
                                                                      if (
                                                                        newValue.length >
                                                                          1 &&
                                                                        newValue[0] ===
                                                                          "0"
                                                                      ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                      }
                                                                    }}
                                                                    onChange={
                                                                      HandleFieldChange
                                                                    }
                                                                    maxLength={10}
                                                                  />
                                                                  mg)
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div className="">
                                                              <label>
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "methylprednisolone_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.methylprednisolone_tal
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    paddingLeft:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  Methylprednisolone
                                                                  (total dose:
                                                                  <input
                                                                    className="sub-input-dash-num"
                                                                    id={idx}
                                                                    name={
                                                                      "methylprednisolone_talNum" +
                                                                      idx
                                                                    }
                                                                    type="text"
                                                                    value={
                                                                      data.methylprednisolone_talNum
                                                                    }
                                                                    onKeyDown={(
                                                                      e
                                                                    ) => {
                                                                      // Allow backspace, arrow keys, delete, and tab
                                                                      if (
                                                                        [
                                                                          "Backspace",
                                                                          "ArrowLeft",
                                                                          "ArrowRight",
                                                                          "Delete",
                                                                          "Tab",
                                                                        ].includes(
                                                                          e.key
                                                                        )
                                                                      ) {
                                                                        return;
                                                                      }

                                                                      // Prevent non-numeric characters
                                                                      if (
                                                                        !/[0-9]/.test(
                                                                          e.key
                                                                        )
                                                                      ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                      }

                                                                      const value =
                                                                        e.target
                                                                          .value;
                                                                      const selectionStart =
                                                                        e.target
                                                                          .selectionStart;
                                                                      const newValue =
                                                                        value.slice(
                                                                          0,
                                                                          selectionStart
                                                                        ) +
                                                                        e.key +
                                                                        value.slice(
                                                                          e
                                                                            .target
                                                                            .selectionEnd
                                                                        );

                                                                      // Prevent leading zeros only if more than one digit is present
                                                                      if (
                                                                        newValue.length >
                                                                          1 &&
                                                                        newValue[0] ===
                                                                          "0"
                                                                      ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                      }
                                                                    }}
                                                                    onChange={
                                                                      HandleFieldChange
                                                                    }
                                                                    maxLength={10}
                                                                  />
                                                                  mg)
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div className="">
                                                              <label>
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "prednisolone_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.prednisolone_tal
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    paddingLeft:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  Prednisolone
                                                                  (total dose:
                                                                  <input
                                                                    className="sub-input-dash-num"
                                                                    id={idx}
                                                                    name={
                                                                      "prednisolone_talNum" +
                                                                      idx
                                                                    }
                                                                    type="text"
                                                                    value={
                                                                      data.prednisolone_talNum
                                                                    }
                                                                    onKeyDown={(
                                                                      e
                                                                    ) => {
                                                                      // Allow backspace, arrow keys, delete, and tab
                                                                      if (
                                                                        [
                                                                          "Backspace",
                                                                          "ArrowLeft",
                                                                          "ArrowRight",
                                                                          "Delete",
                                                                          "Tab",
                                                                        ].includes(
                                                                          e.key
                                                                        )
                                                                      ) {
                                                                        return;
                                                                      }

                                                                      // Prevent non-numeric characters
                                                                      if (
                                                                        !/[0-9]/.test(
                                                                          e.key
                                                                        )
                                                                      ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                      }

                                                                      const value =
                                                                        e.target
                                                                          .value;
                                                                      const selectionStart =
                                                                        e.target
                                                                          .selectionStart;
                                                                      const newValue =
                                                                        value.slice(
                                                                          0,
                                                                          selectionStart
                                                                        ) +
                                                                        e.key +
                                                                        value.slice(
                                                                          e
                                                                            .target
                                                                            .selectionEnd
                                                                        );

                                                                      // Prevent leading zeros only if more than one digit is present
                                                                      if (
                                                                        newValue.length >
                                                                          1 &&
                                                                        newValue[0] ===
                                                                          "0"
                                                                      ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                      }
                                                                    }}
                                                                    onChange={
                                                                      HandleFieldChange
                                                                    }
                                                                    maxLength={10}
                                                                  />
                                                                  mg)
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div className="">
                                                              <label>
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "prednisone_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.prednisone_tal
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    paddingLeft:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  Prednisone
                                                                  (total dose:
                                                                  <input
                                                                    className="sub-input-dash-num"
                                                                    id={idx}
                                                                    name={
                                                                      "prednisone_talNum" +
                                                                      idx
                                                                    }
                                                                    type="text"
                                                                    value={
                                                                      data.prednisone_talNum
                                                                    }
                                                                    onKeyDown={(
                                                                      e
                                                                    ) => {
                                                                      // Allow backspace, arrow keys, delete, and tab
                                                                      if (
                                                                        [
                                                                          "Backspace",
                                                                          "ArrowLeft",
                                                                          "ArrowRight",
                                                                          "Delete",
                                                                          "Tab",
                                                                        ].includes(
                                                                          e.key
                                                                        )
                                                                      ) {
                                                                        return;
                                                                      }

                                                                      // Prevent non-numeric characters
                                                                      if (
                                                                        !/[0-9]/.test(
                                                                          e.key
                                                                        )
                                                                      ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                      }

                                                                      const value =
                                                                        e.target
                                                                          .value;
                                                                      const selectionStart =
                                                                        e.target
                                                                          .selectionStart;
                                                                      const newValue =
                                                                        value.slice(
                                                                          0,
                                                                          selectionStart
                                                                        ) +
                                                                        e.key +
                                                                        value.slice(
                                                                          e
                                                                            .target
                                                                            .selectionEnd
                                                                        );

                                                                      // Prevent leading zeros only if more than one digit is present
                                                                      if (
                                                                        newValue.length >
                                                                          1 &&
                                                                        newValue[0] ===
                                                                          "0"
                                                                      ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                      }
                                                                    }}
                                                                    onChange={
                                                                      HandleFieldChange
                                                                    }
                                                                    maxLength={10}
                                                                  />
                                                                  mg)
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div className="">
                                                              <label>
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "triamcinolone_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.triamcinolone_tal
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    paddingLeft:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  Triamcinolone
                                                                  (total dose:
                                                                  <input
                                                                    className="sub-input-dash-num"
                                                                    id={idx}
                                                                    name={
                                                                      "triamcinolone_talNum" +
                                                                      idx
                                                                    }
                                                                    type="text"
                                                                    value={
                                                                      data.triamcinolone_talNum
                                                                    }
                                                                    onKeyDown={(
                                                                      e
                                                                    ) => {
                                                                      // Allow backspace, arrow keys, delete, and tab
                                                                      if (
                                                                        [
                                                                          "Backspace",
                                                                          "ArrowLeft",
                                                                          "ArrowRight",
                                                                          "Delete",
                                                                          "Tab",
                                                                        ].includes(
                                                                          e.key
                                                                        )
                                                                      ) {
                                                                        return;
                                                                      }

                                                                      // Prevent non-numeric characters
                                                                      if (
                                                                        !/[0-9]/.test(
                                                                          e.key
                                                                        )
                                                                      ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                      }

                                                                      const value =
                                                                        e.target
                                                                          .value;
                                                                      const selectionStart =
                                                                        e.target
                                                                          .selectionStart;
                                                                      const newValue =
                                                                        value.slice(
                                                                          0,
                                                                          selectionStart
                                                                        ) +
                                                                        e.key +
                                                                        value.slice(
                                                                          e
                                                                            .target
                                                                            .selectionEnd
                                                                        );

                                                                      // Prevent leading zeros only if more than one digit is present
                                                                      if (
                                                                        newValue.length >
                                                                          1 &&
                                                                        newValue[0] ===
                                                                          "0"
                                                                      ) {
                                                                        e.preventDefault();
                                                                        return;
                                                                      }
                                                                    }}
                                                                    onChange={
                                                                      HandleFieldChange
                                                                    }
                                                                    maxLength={10}
                                                                  />
                                                                  mg)
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div className="">
                                                              <label>
                                                                <input
                                                                  type="radio"
                                                                  id={idx}
                                                                  name={
                                                                    "dose_name_tal_unknown" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.dose_name_tal_unknown
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Unknown
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <ErrorField
                                                              show={
                                                                data.name_dose_steroids_talHardEmpty
                                                              }
                                                              message={
                                                                TTCharacteristics_MSGS.name_dose_steroidstalHardEmpty
                                                              }
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                              }}
                                                            />

                                                            <ErrorField
                                                              show={
                                                                data.steroids_tal_txtEmpty
                                                              }
                                                              message={
                                                                TTCharacteristics_MSGS.steroids_tal_txtEmpty
                                                              }
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </td>

                                                      {/* End */}

                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                        }}
                                                      >
                                                        <div>
                                                          <div>
                                                            <div className="">
                                                              <label>
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "steroid_pre_tmt_tal" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.steroid_pre_tmt_tal
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    paddingLeft:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  As a
                                                                  pre-treatment
                                                                  per label
                                                                  recommendation
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div className="">
                                                                                            <label className="option-fbox">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "cytokin_tal_steroids" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.cytokin_tal_steroids
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    paddingLeft:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  Prophylactic
                                                                  for cytokine
                                                                  release
                                                                  syndrome (CRS)
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div className="">
                                                              <label>
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "crs_tal_steroids" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.crs_tal_steroids
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    paddingLeft:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  Treatment or
                                                                  supportive
                                                                  care for CRS
                                                                </span>
                                                              </label>
                                                            </div>
                                                            <div className="">
                                                              <label>
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "neurotoxicity_tal_steroids" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.neurotoxicity_tal_steroids
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    paddingLeft:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  Prophylactic
                                                                  for
                                                                  neurotoxicity
                                                                  events
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div className="">
                                                                                            <label className="option-fbox">
                                                                <input
                                                                  type="checkbox"
                                                                  id={idx}
                                                                  name={
                                                                    "tmt_neurotoxicity_tal_steroids" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.tmt_neurotoxicity_tal_steroids
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span
                                                                  style={{
                                                                    paddingLeft:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  Treatment or
                                                                  supportive
                                                                  care for
                                                                  neurotoxicity
                                                                  events
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <div className="">
                                                              <label>
                                                                <input
                                                                  type="radio"
                                                                  id={idx}
                                                                  name={
                                                                    "tmt_tal_unknown" +
                                                                    idx
                                                                  }
                                                                  checked={
                                                                    data.tmt_tal_unknown
                                                                  }
                                                                  onChange={
                                                                    HandleFieldChange
                                                                  }
                                                                />
                                                                <span className="radio-text-padding">
                                                                  Unknown
                                                                </span>
                                                              </label>
                                                            </div>

                                                            <ErrorField
                                                              show={
                                                                data.Q39talSteroidsEmptyHard
                                                              }
                                                              message={
                                                                TTCharacteristics_MSGS.Q39talSteroidsEmptyHard
                                                              }
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    {/* <ErrorField show={test.q18EmptyHard} message={ComborbidityErrorMsg.q18EmptyHard} />  */}
                                  </div>
                                ))}
                            </div>
                          </>
                        )}

                        {/* Question 39 end */}
                      </>
                    )}
                    <br />

                    {/* 40 start */}
                    {isTechvayliSelected && (
                      <div>
                        <div className="question question-weight">
                          <span>Q40.</span>
                          <span className="quest-text-pad">
                            Summarize information related to the usage of
                            intravenous immune globulin (IVIG) during the
                            patient’s treatment with{" "}
                            <span
                              style={{
                                color: PageDesignConstants.fontColor,
                                fontWeight: "bolder",
                              }}
                            >
                              Tec=” TECVAYLI® (teclistamab-cqyv)”.
                            </span>
                          </span>
                        </div>

                        <div className="question-bot-sapce">
                          <div className="sub-question">
                            <table
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                width: "100%",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      border: "1px solid black",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Treatment
                                  </th>
                                  <th
                                    style={{
                                      border: "1px solid black",
                                      padding: "8px",
                                      textAlign: "center",
                                      width: "33%",
                                    }}
                                  >
                                    Dosing frequency
                                  </th>

                                  <th
                                    style={{
                                      border: "1px solid black",
                                      padding: "8px",
                                      textAlign: "center",
                                      width: "16%",
                                    }}
                                  >
                                    Last IgG level prior to most
                                    <br /> recent IVIG administration
                                  </th>

                                  <th
                                    style={{
                                      border: "1px solid black",
                                      padding: "8px",
                                      textAlign: "center",
                                      width: "15%",
                                    }}
                                  >
                                    Initiation date of IVIG
                                  </th>

                                  <th
                                    style={{
                                      border: "1px solid black",
                                      padding: "8px",
                                      textAlign: "center",
                                      width: "45%",
                                    }}
                                  >
                                    Reason for receiving medication Select all
                                    that apply.
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {/* Tecvayli */}

                                <tr>
                                  {Q37TechDetails.length > 0 &&
                                    Q37TechDetails.map((data, idx) => (
                                      <>
                                        <td
                                          style={{
                                            border: "1px solid black",
                                            padding: "8px",
                                          }}
                                        >
                                          <span className="quest-text-pad">
                                            TECVAYLI® (teclistamab-cqyv)
                                          </span>
                                        </td>

                                        {/* Dosing freq column */}
                                        <td
                                          style={{
                                            border: "1px solid black",
                                            padding: "8px",
                                          }}
                                        >
                                          <div>
                                            <div
                                              className="option-without-input"
                                              style={{ display: "flex" }}
                                            >
                                              <label className="option-fbox">
                                                <input
                                                  type="radio"
                                                  id={idx}
                                                  name={"Q40dosingfreqRd" + idx}
                                                  value={1}
                                                  checked={
                                                    data.Q40dosingfreqRd === 1
                                                  }
                                                  onChange={HandleFieldChange}
                                                />
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                    marginLeft: "-5px",
                                                  }}
                                                >
                                                  Biweekly (every 2 weeks)
                                                </span>
                                              </label>
                                            </div>
                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <label className="option-fbox">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={"Q40dosingfreqRd" + idx}
                                                  value={2}
                                                  checked={
                                                    data.Q40dosingfreqRd === 2
                                                  }
                                                  onChange={HandleFieldChange}
                                                />

                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                    marginLeft: "-5px",
                                                  }}
                                                >
                                                  Monthly
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{ display: "flex" }}
                                            >
                                              <label className="option-fbox">
                                                <input
                                                  type="radio"
                                                  id={idx}
                                                  name={"Q40dosingfreqRd" + idx}
                                                  value={3}
                                                  checked={
                                                    data.Q40dosingfreqRd === 3
                                                  }
                                                  onChange={HandleFieldChange}
                                                />
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                    marginLeft: "-5px",
                                                  }}
                                                >
                                                  Bimonthly (every 2 months)
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{ display: "flex" }}
                                            >
                                              <label className="option-fbox">
                                                <input
                                                  type="radio"
                                                  id={idx}
                                                  name={"Q40dosingfreqRd" + idx}
                                                  value={4}
                                                  checked={
                                                    data.Q40dosingfreqRd === 4
                                                  }
                                                  onChange={HandleFieldChange}
                                                />

                                                <span
                                                  style={{
                                                    marginLeft: "4px",
                                                    paddingRight: "15px",
                                                    marginBottom: "0px",
                                                    padding: "0",
                                                  }}
                                                >
                                                  Other frequency, please
                                                  specify:
                                                  <div
                                                    style={{
                                                      paddingLeft: "15px",
                                                      marginBottom: "0px",
                                                      padding: "0",
                                                    }}
                                                  >
                                                    <textarea
                                                      name={
                                                        "dosing_freq_oth_txt" +
                                                        idx
                                                      }
                                                      className="input-dash"
                                                      type="text"
                                                      value={
                                                        data.dosing_freq_oth_txt
                                                      }
                                                      onChange={
                                                        HandleFieldChange
                                                      }
                                                      maxLength={300}
                                                      id={idx}
                                                    />
                                                  </div>
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{ display: "flex" }}
                                            >
                                              <label className="option-fbox">
                                                <input
                                                  type="radio"
                                                  id={idx}
                                                  name={"Q40dosingfreqRd" + idx}
                                                  value={99}
                                                  checked={
                                                    data.Q40dosingfreqRd === 99
                                                  }
                                                  onChange={HandleFieldChange}
                                                />
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                    marginLeft: "-5px",
                                                  }}
                                                >
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>

                                            <ErrorField
                                              show={data.Q40DosingFreqEmptyHard}
                                              message={
                                                TTCharacteristics_MSGS.Q40DosingFreqEmptyHard
                                              }
                                              style={{ paddingLeft: "0px" }}
                                            />

                                            <ErrorField
                                              show={
                                                data.Q40DosingFreqTxtEmptyHard
                                              }
                                              message={
                                                TTCharacteristics_MSGS.Q40DosingFreqTxtEmptyHard
                                              }
                                              style={{ paddingLeft: "0px" }}
                                            />
                                          </div>
                                        </td>

                                        {/* Last  IgG Level */}
                                        <td
                                          style={{
                                            border: "1px solid black",
                                            padding: "8px",
                                          }}
                                        >
                                          <div>
                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <label className="option-fbox">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={"Q40IgILevelRd" + idx}
                                                  value={1}
                                                  checked={
                                                    data.Q40IgILevelRd === 1
                                                  }
                                                  onChange={HandleFieldChange}
                                                />
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                    marginLeft: "-5px",
                                                  }}
                                                >
                                                  {"<"} 400 mg/dL
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{ display: "flex" }}
                                            >
                                              <label className="option-fbox">
                                                <input
                                                  type="radio"
                                                  id={idx}
                                                  name={"Q40IgILevelRd" + idx}
                                                  value={2}
                                                  checked={
                                                    data.Q40IgILevelRd === 2
                                                  }
                                                  onChange={HandleFieldChange}
                                                />
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                    marginLeft: "-5px",
                                                  }}
                                                >
                                                  400-500 mg/dL
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{ display: "flex" }}
                                            >
                                              <label className="option-fbox">
                                                <input
                                                  type="radio"
                                                  id={idx}
                                                  name={"Q40IgILevelRd" + idx}
                                                  value={3}
                                                  checked={
                                                    data.Q40IgILevelRd === 3
                                                  }
                                                  onChange={HandleFieldChange}
                                                />
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                    marginLeft: "-5px",
                                                  }}
                                                >
                                                  {">"} 500 mg/dL
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{ display: "flex" }}
                                            >
                                              <label className="option-fbox">
                                                <input
                                                  type="radio"
                                                  id={idx}
                                                  name={"Q40IgILevelRd" + idx}
                                                  value={99}
                                                  checked={
                                                    data.Q40IgILevelRd === 99
                                                  }
                                                  onChange={HandleFieldChange}
                                                />
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                    marginLeft: "-5px",
                                                  }}
                                                >
                                                  Unknown
                                                </span>
                                              </label>
                                            </div>

                                            <ErrorField
                                              show={data.Q40IgGEmptyHard}
                                              message={
                                                TTCharacteristics_MSGS.Q40IgGEmptyHard
                                              }
                                              style={{ paddingLeft: "0px" }}
                                            />
                                          </div>
                                        </td>

                                        {/* IVIG */}

                                        <td
                                          style={{
                                            border: "1px solid black",
                                            padding: "8px",
                                          }}
                                        >
                                          <div>
                                            <div
                                              className="option-without-input"
                                              style={{ display: "flex" }}
                                            >
                                              <input
                                                type="radio"
                                                id={idx}
                                                name={"Q40init_dt_Rd" + idx}
                                                value={1}
                                                checked={
                                                  data.Q40init_dt_Rd === 1
                                                }
                                                onChange={HandleFieldChange}
                                              />
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                  marginLeft: "-5px",
                                                }}
                                              >
                                                <DateControl
                                                  ctrlId={"Q40init_dt-" + idx}
                                                  HandleDateChange={
                                                    HandleRepeaterDateChange
                                                  }
                                                  date={data.Q40init_dt}
                                                />
                                              </span>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{ display: "flex" }}
                                            >
                                              <label className="option-fbox">
                                                <input
                                                  type="radio"
                                                  id={idx}
                                                  name={"Q40init_dt_Rd" + idx}
                                                  value={99}
                                                  checked={
                                                    data.Q40init_dt_Rd === 99
                                                  }
                                                  onChange={HandleFieldChange}
                                                />
                                                <span
                                                  // className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                    marginLeft: "-5px",
                                                  }}
                                                >
                                                  Date of initiation unknown
                                                </span>
                                              </label>
                                            </div>

                                            <ErrorField
                                              show={data.Q40Init_RdHard}
                                              message={
                                                TTCharacteristics_MSGS.Q40Init_RdHard
                                              }
                                              style={{ paddingLeft: "0px" }}
                                            />

                                            <ErrorField
                                              show={data.Q40Init_dtHard}
                                              message={
                                                TTCharacteristics_MSGS.Q40Init_dtHard
                                              }
                                              style={{ paddingLeft: "0px" }}
                                            />
                                          </div>
                                        </td>

                                        {/* Medication Reason */}

                                        <td
                                          style={{
                                            border: "1px solid black",
                                            padding: "8px",
                                          }}
                                        >
                                          <div>
                                            <div className="">
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  id={idx}
                                                  name={"primaryProf" + idx}
                                                  checked={data.primaryProf}
                                                  onChange={HandleFieldChange}
                                                />
                                                <span
                                                  style={{ marginLeft: "6px" }}
                                                >
                                                  Primary prophylactic for
                                                  <span className="radio-text-padding">
                                                    infections
                                                  </span>
                                                </span>
                                              </label>
                                            </div>

                                            <div className="">
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  id={idx}
                                                  name={"secondaryProf" + idx}
                                                  checked={data.secondaryProf}
                                                  onChange={HandleFieldChange}
                                                />
                                                <span
                                                  style={{ marginLeft: "6px" }}
                                                >
                                                  Secondary prophylactic{" "}
                                                  <span className="radio-text-padding">
                                                    for infections
                                                  </span>
                                                </span>
                                              </label>
                                            </div>

                                            <div className="">
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  id={idx}
                                                  name={"otherReson" + idx}
                                                  checked={data.otherReson}
                                                  onChange={HandleFieldChange}
                                                />
                                                <span
                                                  style={{ marginLeft: "6px" }}
                                                >
                                                  Other reason
                                                </span>
                                              </label>
                                            </div>

                                            <ErrorField
                                              show={data.Q40ReasonEmptyHard}
                                              message={
                                                TTCharacteristics_MSGS.Q40ReasonEmptyHard
                                              }
                                              style={{ paddingLeft: "0px" }}
                                            />
                                          </div>
                                        </td>
                                      </>
                                    ))}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* 40 end */}
                  </div>
                </div>
              </div>

              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TTCharacteristics;
