import React from 'react'

const InfoPopUp = ({ OkClick, description }) => {
  const OnOkClick = () => {
    OkClick()
  }

  const closeModal = () => {
    OkClick();
  };

  return (
    <>
      <section className="delete-modal">
        <div
          className="modal"
          id="infoModal"
          tabIndex={-1}
          aria-labelledby="ModalFormLabel"
          aria-hidden="true"
          style={{
            display: "block",
            paddingRight: "17px",
            backgroundColor: "#00000094",
          }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "850px", width: "100%" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  onClick={closeModal}
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    color: "red",
                    fontSize: "2rem",
                    fontWeight: "bold",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: "1px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="delete-modal-main">
                    <div className="text-input-main">
                      <div
                        className="modal-body"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                      <div className="confirm-btn-div">
                        <button className="modal-close-btn" onClick={OnOkClick}>
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InfoPopUp;
