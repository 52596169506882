import React from "react";
import { Navigate, useLocation, useOutlet } from "react-router-dom";
import { GetLocalStorageData, GetLoggedInUserID } from "../Helpers/Utilities";
import { AdminOnlyRoutes, Role } from "../Helpers/Enums";

const PrivateRoute = () => {
  const outlet = useOutlet();
  const { pathname } = useLocation();

  let userDetails = GetLocalStorageData("user")
  userDetails = userDetails && JSON.parse(userDetails);
  const roleId = userDetails?.userRole ?? 0;
  const isAdmin = roleId === Role.Admin ?? false;

  return GetLoggedInUserID()
    ? !isAdmin && AdminOnlyRoutes.includes(pathname.toLowerCase()) ? <Navigate to="/AccessDenied" /> : outlet
    : <Navigate to="/" />;
};

export default PrivateRoute;
