import React from "react";

const Header = () => {
  return (
    <section className="header-section">
      <div className="container">
        <h5>           
        LONGITUDINAL MULTI-CENTER CHART REVIEW STUDY AND CONSORTIUM FOR MULTIPLE MYELOMA (EMMPOWER) IN THE UNITED STATES
        </h5>       
        <h4>Case Report Form (CRF)</h4>
      </div>
    </section>
  );
};

export default Header;
