import WebServiceWrapper from "./WebServiceWrapper";

export const NonCohortTreatmentService = {
  SaveNonCohortTreatmentData,
  GetNonCohortTreatmentData,
};

function SaveNonCohortTreatmentData(payload) {
  return WebServiceWrapper.Post("/nonCohortTreatment/SaveNonCohortTreatmentData", payload);
}

function GetNonCohortTreatmentData(patientId) {
  return WebServiceWrapper.Get(`/nonCohortTreatment/GetNonCohortTreatmentData?patientId=${patientId}`);
}
