import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { CenterMenuItems, Role, TopMenuItems } from "../Helpers/Enums";
import { GetLocalStorageData } from "../Helpers/Utilities";

const CenterMenu = ({ activeTab }) => {

    const navigate = useNavigate();
    let userDetails = GetLocalStorageData("user")
    userDetails = userDetails && JSON.parse(userDetails);
    const roleId = userDetails?.userRole ?? 0;
    const isAdmin = roleId === Role.Admin ?? false;

    return (
        <>
            <section className="menu-section">
                <div className="navbar">
                    {isAdmin && (
                        <a onClick={() => { navigate("/ManageRounds"); }}
                            className={activeTab == CenterMenuItems.Round && "active"}
                        >
                            Round
                        </a>
                    )}
                    <a onClick={() => { navigate("/CenterInformation"); }}
                        className={activeTab == CenterMenuItems.CenterInfo && "active"}
                    >
                        Center Information
                    </a>
                </div>
            </section>
        </>
    );
};

export default CenterMenu;
