import React from 'react'
import { GetLocalStorageData } from '../Helpers/Utilities';

const PatientInformation = () => {

  let patientData = GetLocalStorageData("patient")
  patientData = patientData && JSON.parse(patientData);

  let userDetails = GetLocalStorageData("user")
  userDetails = userDetails && JSON.parse(userDetails);

  const uniquePatientId = patientData?.uniqueEncryptedPatientId ?? 0//GetLocalStorageData("uniqueEncryptedPatientId") ?? 0;
  const surveyDate = patientData?.surveyDate ?? "";//GetLocalStorageData("surveyDate") ?? 0;
  const center = userDetails?.centerName ?? "";//GetLocalStorageData("centerName");
  const empName =userDetails?.empName ?? "";// GetLocalStorageData("empName");
  const activeRound=userDetails?.activeRoundId ?? "";
  return (
    <div className="col-12 col-sm-7 col-md-7 col-lg-6">
      <div>
        <p>Center Name: </p>
        <span>{center}</span>
          </div>

      <div>
        <p>Abstractor Creating Chart: </p>
        <span>{empName}</span>
      </div>

      {uniquePatientId !== 0 &&
        <div>
          <p>Patient ID: </p>
          <span>{uniquePatientId}</span>
        </div>
      }
      <div>
        <p>Active Round: </p>
        <span>Round {activeRound}</span>
      </div>
      {
        uniquePatientId !== 0 &&
        <div>
          <p>Date of Chart Abstraction Initiation: </p>
          <span>{surveyDate}</span>
        </div>
      }
    </div>
  )
}

export default PatientInformation