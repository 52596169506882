import React, { useState, useContext, useEffect } from "react";
import PatientDetails from "../../Components/PatientInformation";
import {
  HTTPResponse,
  SideMenuItems,
  ToastMessageType,
} from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import { CommonError_Msgs, CommonMsgs } from "../../Helpers/HelperText";
import { PatientService } from "../../WebApiServices/Patient.service";
import {
  AddLocalStorageData,
  GetLocalStorageData,
  RemoveLocalStorageData,
} from "../../Helpers/Utilities";
import { AppContext } from "../../Contexts/AppContextProvider";
import { useNavigate } from "react-router-dom";

const ValidateSubmit = () => {
  const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout,patientConfigData,isSideMenuActive,setIsSideMenuActive  } = useContext(AppContext);
  const pid = GetLocalStorageData("patientId") ?? 0;
  const userId = GetLocalStorageData("userId") ?? 0;

  


  let patientData = GetLocalStorageData("patient");
  
  patientData = patientData && JSON.parse(patientData);
  const upid = patientData.uniqueEncryptedPatientId ?? 0;


  const locked = patientData?.locked ?? false;
  const submit = patientData?.submit ?? 0;

  const [showSubmitBtn, setShowSubmitBtn] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  /* <summary>
    date: 07-11-2023
    Name: AP
    description: Display submit button if patient ready to be locked
    <summary>*/
  useEffect(() => { 
    const FetchData = async () => {
      //if (isEligible) //not reqd as eligiblity check is done in service

      // ToggleLoader(true)
      if (!locked) {
        await SetPatientSubmitValue();
        setIsLoading(false);
      } else {
        setShowSubmitBtn(false);
        setIsLoading(false);
      }
      // ToggleLoader(false)
      //setIsLoading(false)
      //await AreAllFormsComplete();
    };
    if(patientConfigData) FetchData();

    //if (submit === 1) {
    //FetchData();
    // } else
    //    setShowSubmitBtn(false);
  }, [submit, locked, patientConfigData]);

  /* <summary>
    date: 09-11-2023
    Name: AP
    description: Sets the patient submit value to 1 only if Eligible
    <summary>*/
  const SetPatientSubmitValue = async () => { 
    try {
      const payload = {
        pid: pid,
        userId: userId,
      };
      ToggleLoader(true);
      const response = await PatientService.SetPatientSubmitValue(payload);
      ToggleLoader(false);
      if (response?.status === HTTPResponse.OK) {
        // Set Patient Submit Value in LS
        const ptArr = { ...patientData };
        ptArr.submit = response?.data;

        AddLocalStorageData("patient", JSON.stringify(ptArr));

        if (locked && response?.data === 2) setShowSubmitBtn(false);
        if (response?.data === 1) await AreAllFormsComplete();
        else setShowSubmitBtn(false);
      } else {
        if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        } else throw response.error;
      }
    } catch {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };
  const AreAllFormsComplete = async () => {

    try {
        const incompletePageIdsArr = []
        // if (submit === 1) {
        ToggleLoader(true)
        // const validMenuItems = await PatientService.GetPatientConfigData(pid);//patient config data
        ToggleLoader(false)
        const {status}=patientConfigData;
        if(status !== HTTPResponse.OK){
            if (status === HTTPResponse.Unauthorized) {
                HandleSessionTimeout();
            }
        }
        else{
            const { data } = patientConfigData;

            if (patientConfigData != null) {
                if (!data.demographics
                     || data.demographics !== 1) {
                    incompletePageIdsArr.push(SideMenuItems.Demographics);
                }


                if (!data.diseasehistory
                    || data.diseasehistory !== 1) {
                   incompletePageIdsArr.push(SideMenuItems.DiseaseHistory);
               }

               if (!data.comorbidities 
                || data.comorbidities !== 1) {
               incompletePageIdsArr.push(SideMenuItems.Comorbidities);
           }

           if (!data.cohortcharacteristics
            || data.cohortcharacteristics !== 1) {
           incompletePageIdsArr.push(SideMenuItems.CohortDefiningTreatment);
       }
           if (!data.ndmmtreatmentcohort || data.ndmmtreatmentcohort !== 1) {
            incompletePageIdsArr.push(SideMenuItems.NDMMTreatmentCohort);
        }


        if (!data.bispecifictreatmentcohort || data.bispecifictreatmentcohort !== 1) {
            incompletePageIdsArr.push(SideMenuItems.BispecificsCartTreatmentCohort);
        }
        if (!data.noncohortcharacteristics
          || data.noncohortcharacteristics !== 1) {
         incompletePageIdsArr.push(SideMenuItems.NonCohortDefiningTreatment);
     }
        if (!data.ndmmsctcharacteristics || data.ndmmsctcharacteristics !== 1) {
            incompletePageIdsArr.push(SideMenuItems.NDMMSCTCharacteristics);
        }

        if (!data.teclistamabcharacteristics || data.teclistamabcharacteristics
            !== 1) {
            incompletePageIdsArr.push(SideMenuItems.TTCharacteristics);
        }

        if (!data.cartcharacteristics || data.cartcharacteristics !== 1) {
            incompletePageIdsArr.push(SideMenuItems.CartCharacteristics);
        }



        if (!data.adverseevents || data.adverseevents !== 1) {
            incompletePageIdsArr.push(SideMenuItems.AdverseEvents);
        }
     

        if (!data.treatmentresponseprogression || data.treatmentresponseprogression !== 1) {
            incompletePageIdsArr.push(SideMenuItems.TreatmentResponseProgression);
        }



                if (incompletePageIdsArr?.length === 0)
                    setShowSubmitBtn(true)
            }
        // } else
        //    setShowSubmitBtn(false)
        }
    } catch {

    }
}


  const HandleSubmit = async () => {
    try {
      const payload = {
        pid: pid,
        userId: userId,
      };

      ToggleLoader(true);
      const response = await PatientService.LockPatient(payload);
      ToggleLoader(false);
      if (response?.status === HTTPResponse.OK) {
        // Set Patient Locked Status in LS
        //submit=2 when patient is locked
        const ptArr = { ...patientData, locked: response?.data, submit: 2, canEdit: false };
        AddLocalStorageData("patient", JSON.stringify(ptArr));
        ShowToast(CommonMsgs.SubmitSuccess, ToastMessageType.Success);
      } else {
        if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        } else throw response.error;
      }
    } catch {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };
  const ShowAlertBox = async () => {
    const choice = await ShowActionPopUp(
      "Alert",
      "Submitting this patient chart will prevent you from making further changes to the records.",
      "Ok" //Confirm button text
    );
    if (!choice) {
      return;
    } else {
      HandleSubmit();
    }
  };
  const AddNew = () => {
    RemoveLocalStorageData("patientId");
    RemoveLocalStorageData("patient");
    return navigate("/EligibilityCriteria");
  };
  const NavigateToDashboard = () => {
    navigate("/ManagePatients");
  };
  
  const sidebar = document.getElementById("mySidebar");
  const main = document.getElementById("main");

  const toggleNav = () => {
  

  
    if (isSideMenuActive === true  ) { 
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
      setIsSideMenuActive(false)
    } else if( isSideMenuActive===false ){
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
      setIsSideMenuActive(true)
    }
}

useEffect(() => {
const   sidebar = document.getElementById("mySidebar");
const  main = document.getElementById("main");


  if (isSideMenuActive === true) {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
    } else {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
    }
});
  return (
    <>
      <section className="survey-patient-details-section ">
        <div className="row">
          <PatientDetails />
        </div>
      </section>
      <section className="main-survey-section short-page">
        <div >
          <SideBarMenu activePageId={SideMenuItems.ValidateSubmit} />
          <>
          <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
         </>
          <div className="col-pad-left" id="main">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                {isLoading ? (
                  <></>
                ) : //showSubmitBtn === true ? <h4>Submit</h4> :
                locked ? (
                  <h4>SUBMITTED</h4>
                ) : (
                  <h4>VALIDATE & SUBMIT</h4>
                )}
              </div>

              <div
                className={
                  locked
                    ? "survey-introduction-section Survey-footer-section"
                    : "survey-introduction-section Survey-footer-sectionSave"
                }
              >
                <div className="survey-introduction-content survey_Validate_submit" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <div className="instructions">
                    {isLoading ? (
                      <></>
                    ) : showSubmitBtn === true ? ( //Patient is ready to be submitted n locked
                      <center
                        style={{ 
                          // paddingTop: "100px", 
                          fontSize: "large" }}
                      >
                       Please confirm that you have completed the abstraction or update of this patient. 
                       Please also confirm that you have saved <b><u>in your site’s secured system</u></b> a crosswalk between this patient’s ID for eMMpower (<b>{upid}</b>) and the patient’s ID in your site’s secured system. This crosswalk is critical and will be used to update this patient’s charts in the future.
                      </center>
                    ) : locked ? ( //&& submit === 2 //Patient is already submitted
                      <center
                        style={{ 
                          // paddingTop: "100px", 
                          fontSize: "large" }}
                      >
                        Thank you for your participation. Patient has been
                        successfully submitted. To  return to the
                        patients, click ‘Go to Patients’.
                        {/* add a new patient, please
                        click on ‘Add new patient’ and to */}
                      </center>
                    ) : (
                      //Patient has some incomplete forms to be filled
                      <center
                        style={{ 
                          // paddingTop: "100px", 
                          fontSize: "large" }}
                      >
                        There is {"≥1"} data entry error requiring attention. Please check before returning to this page.<br/>
                        Please visit each page highlighted in{" "}
                        <span style={{ color: "red" }}>red</span> to the left,
                        and click Save.
                      </center>
                    )}
                  </div>
                </div>
              </div>

              <div className="survey-section-content-foot validate-and-submit-footer">
                {showSubmitBtn && (
                  <a
                    className="btn save-btn"
                    onClick={ShowAlertBox}
                    style={{ fontWeight: "600", fontSize: "large" }}
                  >
                   Validate & Submit
                  </a>
                )}
                {locked && (
                  <>
                    {/* <a
                      className="btn save-btn"
                      onClick={AddNew}
                      style={{
                        fontWeight: "600",
                        fontSize: "large",
                        marginRight: "22px",
                      }}
                    >
                      Add new patient
                    </a> */}
                    <a
                      className="btn save-btn"
                      onClick={NavigateToDashboard}
                      style={{ fontWeight: "600", fontSize: "large" }}
                    >
                      Go to Patients
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ValidateSubmit;
