import WebServiceWrapper from "./WebServiceWrapper";

export const CohortDefiningTreatmentService = {
  SaveCohortDefiningTreatment,
  LoadCohortDefiningTreatment,
};

function SaveCohortDefiningTreatment(payload) {
  return WebServiceWrapper.Post(
    "/cohortDefiningTreatment/SaveCohortDefiningTreatment",
    payload
  );
}

function LoadCohortDefiningTreatment(pid) {
  return WebServiceWrapper.Get(
    `/cohortDefiningTreatment/LoadCohortDefiningTreatment?pid=${pid}`
  );
}
