import React, { useContext, useEffect, useState } from 'react'
import SaveAndNavigate from '../../Components/SaveAndNavigate';
import { RRMMCohortTreatment, SideMenuItems } from '../../Helpers/Enums';
import SideBarMenu from '../../Components/SideBarMenu';
import PatientDetails from '../../Components/PatientInformation';
import { GetLocalStorageData } from '../../Helpers/Utilities';
import { AppContext } from '../../Contexts/AppContextProvider';


const AEReporting = () => {
    
    const [navigatePaths, setNavigatePaths] = useState({
        prevPage: "/AdverseEvents",
        currPage: "/AEReporting",
        nextPage: "/ValidateSubmit",

    })

    const { isSideMenuActive,setIsSideMenuActive } = useContext(AppContext);
    
    const SetNavPaths = () => {
        const storedData = GetLocalStorageData("CohortTreatment");
        let cohortTreatmentModelListt = [];
        let newPaths = { ...navigatePaths };
    
        if (storedData) {
          cohortTreatmentModelListt = JSON.parse(storedData);
        }
    
        let eligibleCohorts = cohortTreatmentModelListt.filter(
          (c) => c.isEligible === true
        );
        let ndmmCohorts = eligibleCohorts.filter((c) => c.ndmm);
        let rrmmCohorts = eligibleCohorts.filter((c) => !c.ndmm);
    
        if (rrmmCohorts.length > 0) {
            newPaths.prevPage = "/AdverseEvents";
        } else {
            newPaths.prevPage = "/TreatmentResponseProgression";
        }
        setNavigatePaths(newPaths);
    };

    const SavePage  =  () => {
        //SetNavPaths();
        return true;
    }
    const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");

    const toggleNav = () => {
    

    
      if (isSideMenuActive === true  ) { 
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
        setIsSideMenuActive(false)
      } else if( isSideMenuActive===false ){
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
        setIsSideMenuActive(true)
      }
  }

  useEffect(() => {
    SetNavPaths();
 const   sidebar = document.getElementById("mySidebar");
  const  main = document.getElementById("main");


    if (isSideMenuActive === true) {
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
      } else {
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
      }
  });
    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} /> 
                    </div>
                </div>
            </section>
            <section className="main-survey-section">
                <div >
                    <SideBarMenu activePageId={SideMenuItems.AEReporting} />
                    <>
                    <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
                    </>
                    <div className="col-pad-left" id='main'>
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>AE Reporting</h4>
                            </div>
                            <div className="survey-question-section">
                                <div className="survey-question-content">

                                    <div className="question-answer">
                                        <div className="question-bot-sapce">
                                        <div className="question question-weight">
                                            <p style={{ textAlign: 'left' }}>
                                                If applicable, please report safety information for this patient according to the study protocol Section 5 at&nbsp;
                                                <a
                                                    href="http://www.janssensafety.com"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    www.janssensafety.com
                                                </a>.
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SavePage} /> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AEReporting