import React, { useContext, useEffect, useState } from 'react'
import { HTTPResponse, ToastMessages, ToastMessageType } from '../../Helpers/Enums';
import { CommonError_Msgs, Round_Msgs } from '../../Helpers/HelperText';
import { CenterService } from '../../WebApiServices/Center.service';
import { AllowOnlyIntegers, GetLocalStorageData, isStringEmpty } from '../../Helpers/Utilities';
import { AppContext } from '../../Contexts/AppContextProvider';
import { GetLoggedInUserID } from '../../Helpers/Utilities';
import ErrorField from '../../Components/ErrorField';

export const RoundFormModal = ({ centerRoundId, roundId, CloseModal, isRoundComplete }) => {
    const { ShowToast, ToggleLoader, HandleSessionTimeout } = useContext(AppContext);

    const [roundName, setRoundName] = useState("");
    const [ctrList, setCtrList] = useState([]);
    const [cntErrList, setCntErrList] = useState([]);
    const [errorOnSave, setErrorOnSave] = useState("");

    const CloseModalPopUp = (id) => {
        CloseModal(id);
    }
    useEffect(() => {
        try {
            async function FetchData() {

                ToggleLoader(true);
                const response = await CenterService.GetRoundData(roundId, centerRoundId, 0);
                ToggleLoader(false);

                if (response?.status === HTTPResponse.OK) {
                    const { data } = response;
                    if (data != null) {
                        setRoundName(data.roundName);
                        if (data.centerTreatmentRoundList != null && data.centerTreatmentRoundList.length > 0) {

                            const { centerTreatmentRoundList } = data;
                            const array = centerTreatmentRoundList.map(item => {

                                return {
                                    ctrId: item.centerTreatmentRoundId != null ? item.centerTreatmentRoundId : 0,
                                    cohortTreatmentID: item.cohortTreatmentID,
                                    cohortTreatmentName: item.cohortTreatmentName,
                                    cohortTreatmentNameSuperscript: item.cohortTreatmentNameSuperscript,
                                    target_new_charts: item.newCharts?.toString() ?? "",
                                    chkStatus: !isStringEmpty(item.newCharts),
                                    minCount: item.eligibilityChartUpdates?.toString() ?? "",//minimum count below which value is not allowed 
                                    error: false,
                                };
                            });
                            setCtrList(array);
                        }
                    }
                } else {
                    if (response?.status === HTTPResponse.Unauthorized)
                        HandleSessionTimeout();
                    else
                        throw response.error;
                }
            }
            FetchData();
        }
        catch (e) {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    }, [])
    const Validate = () => {
        let isValid = true;

        setErrorOnSave("");

        if (ctrList && ctrList.length > 0) {
            isValid = !ctrList.some(a => a.chkStatus && isStringEmpty(a.target_new_charts));
            if (!isValid)
                setErrorOnSave(Round_Msgs.TARGET_CHARTS_EMPTY);

            const errArray = ctrList
                .filter(a => !isStringEmpty(a.minCount))
                .filter(a => Number(a.target_new_charts) < Number(a.minCount))
                .map(a => ({
                    message: Round_Msgs.TARGET_CHARTS_MINIMUM
                        .replace("{min}", a.minCount)
                        .replace("{tx}", a.cohortTreatmentName)
                }));

            setCntErrList(errArray);

            return isValid && errArray.length === 0;
        }
        return true;
    }
    const HandleSave = async () => {
        try {
            // setErrorOnSave("");
            if (!Validate()) {
                return;
            }
            const roundModel = {
                centerRoundId: centerRoundId,
                roundId: roundId,
                centerId: GetLocalStorageData("editCenterId") ?? 0,
                CenterTreatmentRoundList: [],
                UserId: GetLoggedInUserID()
            }
            if (ctrList && ctrList.length > 0) {
                roundModel.CenterTreatmentRoundList = ctrList.map((a) => ({
                    CenterTreatmentRoundId: a.ctrId,
                    CohortTreatmentID: a.cohortTreatmentID,
                    NewCharts: a.target_new_charts
                }));
            }
            ToggleLoader(true);
            const response = await CenterService.SaveRoundData(roundModel);
            ToggleLoader(false);
            const { status } = response;
            if (status === HTTPResponse.OK) {
                ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                CloseModalPopUp(roundId);
            } else {
                if (status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else {
                    throw response.error;
                }
            }
        } catch (error) {
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
            setErrorOnSave(CommonError_Msgs.SAVE_ERR)
        }
    }
    const HandleFieldChange = (e) => {
        const { name, id, value } = e.target;
        switch (name) {
            case "target_new_charts" + id:
                {
                    if (value !== "" && !AllowOnlyIntegers(value)) {
                        break;
                    }
                    let updatedArray = [...ctrList];
                    updatedArray[id].target_new_charts = value;
                    updatedArray[id].chkStatus = true;
                    setCtrList(updatedArray);
                    
                        Validate();
                    }
                break;
            case "chkStatus" + id:
                {
                    let updatedArray = [...ctrList];
                    updatedArray[id].chkStatus = !updatedArray[id].chkStatus;
                    updatedArray[id].target_new_charts = "";
                    setCtrList(updatedArray);
                    //remove the top error if theres no row with checkbox set n target not entered
                    // if (Validate()) {
                    //     setErrorOnSave("");
                    // }
                        Validate();
                    }
                break;
            default: break;
        }
    }
    return (
        <>
            <section className="edit-modal">
                <div
                    className="modal"
                    id="RoundModal"
                    tabIndex={-1}
                    aria-labelledby="ModalFormLabel"
                    aria-hidden="true"
                    style={{ display: "block", paddingRight: "17px", backgroundColor: "#00000094" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row modal-headerr">
                                        <div className="col-10 col-md-10 col-lg-10">
                                            <label htmlFor="">
                                                {roundName}
                                            </label>
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-2 btn-align">
                                            <button
                                                type="button"
                                                className="btn-close btn-close-white"
                                                data-bs-dismiss="modal"
                                                aria-label="Close" onClick={() => { CloseModalPopUp(0) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="round-form edit-modal-main">
                                        <div className="text-input-main scroll">
                                            <div className="input-details" disabled={isRoundComplete}>
                                                <div>
                                                    <ErrorField show={!isStringEmpty(errorOnSave)} message={errorOnSave} style={{ paddingLeft: '0px' }} />
                                                    {cntErrList && cntErrList.length > 0 && (
                                                        cntErrList.map(e => (
                                                            <ErrorField message={e.message} style={{ paddingLeft: '0px' }} />
                                                        )))
                                                    }
                                                    <div className='round-form'>
                                                        <div className="table-top">
                                                            <table>
                                                                <tbody>
                                                                    <tr className="">
                                                                        <th className="tab-cell-th tab-cell-border tab-row-border" width="5%"></th>
                                                                        <th className="tab-cell-th tab-cell-border"
                                                                            width="50%">
                                                                            Treatment Regimen
                                                                        </th>
                                                                        <th className="tab-cell-th tab-cell-border" width="20%">
                                                                            Target number of new charts
                                                                        </th>
                                                                    </tr>
                                                                    {ctrList && ctrList.length > 0 && (
                                                                        ctrList.map((data, idx) => (
                                                                            <tr key={idx}>
                                                                                <td className="tab-cell-left tab-cell-border tab-row-border">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name={`chkStatus${idx}`}
                                                                                        id={idx}
                                                                                        checked={data.chkStatus}
                                                                                        onChange={HandleFieldChange}
                                                                                    />
                                                                                </td>
                                                                                <td className="tab-cell-left tab-cell-border tab-row-border">
                                                                                    {data.cohortTreatmentName}
                                                                                    {data.cohortTreatmentNameSuperscript == null ? (
                                                                                        ''
                                                                                    ) : (
                                                                                        <>
                                                                                            <sup className="supscript">{data.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                                                                            {data.cohortTreatmentNameSuperscript.slice(2)}
                                                                                        </>
                                                                                    )}
                                                                                </td>
                                                                                <td
                                                                                    className="tab-cell-right tab-cell-border"
                                                                                    style={{
                                                                                        verticalAlign: "top",
                                                                                        paddingTop: "7px",
                                                                                    }}
                                                                                >
                                                                                    <span className="radio-text-padding">
                                                                                        <input
                                                                                            className="sub-input-dash"
                                                                                            //type="number"
                                                                                            name={`target_new_charts${idx}`}
                                                                                            id={idx}
                                                                                            value={data.target_new_charts}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            !isRoundComplete &&
                                            <div className="save-btn-div">
                                                <button className="modal-save-btn" onClick={HandleSave}>Save</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}; 