import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { isStringEmpty } from "../Helpers/Utilities";
import { now } from "moment";

const DateControl = ({
  hideDay,
  hideMonth,
  marginLeft = true,
  ctrlId,
  HandleDateChange,
  optionId,

  date,
}) => {
  const defaultDate = "MM/DD/YYYY";

  const [selectedDate, setSelectedDate] = useState(defaultDate);

  const preventManualInput = (e) => {
    e.preventDefault();
  };
  let inputProps = {
    readOnly: true,
    onBeforeInput: preventManualInput,
    value: date != null && date != "" ? date : selectedDate,
    style: {
      width: "130px",
      marginRight: "5px",
      height: "25px",
      marginLeft: marginLeft === true ? "5px" : "0",
    },
  };
  const HandleFullDateChange = (e) => {
    if (e.isValid()) {
      setSelectedDate(e.format("MM/DD/yyyy"));
      HandleDateChange(ctrlId, e.format("MM/DD/yyyy"), optionId);
    }
  };

  /* <summary>
  date: 13-10-2023
  Name: AP
  description: Set date to defaut value
  <summary>*/
  useEffect(() => {
    setSelectedDate(isStringEmpty(date) ? defaultDate : date);
  }, [date]);

  /* <summary>
  date: 31-10-2023
  Name: AP
  description: Set the min and max dates for the calendar
  <summary>*/
  const SetDateRange = (current) => {
    return current.isSameOrAfter("01/01/1920") && current.isSameOrBefore(now());
  };
  return (
    <>
      {!hideDay && !hideMonth && (
        <Datetime
          inputProps={inputProps}
          dateFormat="MM/DD/yyyy"
          timeFormat={false}
          className="datepicker"
          onChange={HandleFullDateChange}
          closeOnSelect="true"
          isValidDate={SetDateRange}
          //wrapperClassName="datePickerCalendar"
        />
      )}
    </>
  );
};

export default DateControl;
