import WebServiceWrapper from "./WebServiceWrapper";

export const DemographicService = {
    GetUSStates, 
    GetDemographicData, 
    SaveDemographicData,
};

function GetUSStates() {
  return WebServiceWrapper.Get(`/demographic/GetUSStates`);
}

function GetDemographicData(patientId) {
  return WebServiceWrapper.Get(`/demographic/GetDemographicData?patientId=${patientId}`);
}

function SaveDemographicData(payload) {
  return WebServiceWrapper.Post("/demographic/SaveDemographicData", payload);
}