import SaveAndNavigate from "../../Components/SaveAndNavigate";
import SideBarMenu from "../../Components/SideBarMenu";
import PatientDetails from "../../Components/PatientInformation";
import React, { useContext, useEffect, useState } from 'react'
import { SideMenuItems, ToastMessageType, HTTPResponse, ToastMessages, PageDesignConstants} from '../../Helpers/Enums';
import ErrorField from "../../Components/ErrorField";
import { CommonError_Msgs, demographicScreen_Msgs, EligibilityCriteria_Msgs,hyperlinkTextAEReporting } from "../../Helpers/HelperText";
import { AppContext } from "../../Contexts/AppContextProvider";
import { AddLocalStorageData, GetLocalStorageData, GetLoggedInUserID, isStringEmpty } from "../../Helpers/Utilities";
import { EligibilityCriteriaService } from '../../WebApiServices/EligibilityCriteria.service';
import InfoPopUp from "../../Components/InfoPopUp";


const TransitionToChartAbstraction = () => {
    const [showelibilitymatchtarget, setshowelibilitymatchtarget] = useState(false);
    const [cohortTreatment, setCohortTreatment] = useState("");
    const [showelibilitysingletarget, setshowelibilitysingletarget] = useState(false);
    const [showeligibletarget, setshoweligibletarget] = useState(false);

    const [selectedCohortTreatmentID, setSelectedCohortTreatmentID] = useState(null);
    const [cohortTreatmentS16, setCohortTreatmentS16] = useState([]);
    const [cohortTreatmentS15, setCohortTreatmentS15] = useState([]);
    const [isEligible, setIsEligible] = useState(false);
    const [isAllDataFilled, setIsAllDataFilled] = useState(false);
    const [showTopErrMsg, setShowTopErrMsg] = useState(false);
    const [showHideHyperlinkModalAEReporting, setShowHideHyperlinkModalAEReporting] = useState(false)




    const pid = GetLocalStorageData("patientId") ?? 0;
    let patientData = GetLocalStorageData("patient")
    patientData = patientData && JSON.parse(patientData);
    const eligibilityCriteriaLocked = patientData?.eligibilityCriteriaLocked ?? false;


    const navigatePaths = {
        prevPage: "/ExclusionCriteria",
        currPage: "/TransitionToChartAbstraction",
        nextPage: eligibilityCriteriaLocked ? (isEligible ? "/Demographics" : "/ManagePatients" ):
            (isAllDataFilled && isEligible) ? "/Demographics" : (isAllDataFilled && !isEligible) ? "/ManagePatients" : "/TransitionToChartAbstraction",
    };

    const centerId = patientData?.ptCenterId ?? 0;
    const surveyDate = patientData?.surveyDate ?? "";

    ////Cohorttreament
    const cohortTreatmentModelList = []


    //ChartSelection
    const chartSelection = GetLocalStorageData("ChartSelection");
    let chartSelectionList = JSON.parse(chartSelection);

    const { ShowToast, ToggleLoader, HandleSessionTimeout,
        isSideMenuActive,
        setIsSideMenuActive } = useContext(AppContext);


    const RepeaterRow = {
        PatientEligibilityId: 0,
        s15cohortTreatment: null,
        s16NoCohortTreatment: null,
        metInclusionCriteria: null,
        eligibilityChartUpdates: 0,
        s15Empty: false,
        s16Empty: false,
        unmatchedOneTreatmentId: 0,
        matchedCohortId:0,
        s16CohortTreatmentAdditionalPriority: [
            {
                patientCohortTreatmentAdditionalPriorityId: 0,
                s16additionCohort: false,
                cohortTreatmentID: 0,
                cohortTreatmentName: "",
                isEligible: true,
                cohortTreatmentNameSuperscript:"",
            }
        ],

        cohortTreatmentMatchedTargets: [
            {
                patientCohortTreatmentMatchedTargetId: 0,
                cohortTreatmentID: 0,
                cohortTreatmentName: "",
                isEligible: false,
                cohortTreatmentNameSuperscript: "",
            }
        ],
    };



    const [additionalPriority, setadditionalPriority] = useState([RepeaterRow]);

    useEffect(() => {


        async function FetchData() {
            if (!isNaN(pid) && Number(pid) !== 0) {

                ToggleLoader(true);
                await LoadData();
                ToggleLoader(false);
            }
        }
        FetchData();
    }, [])



    async function LoadData() {
        try {
            const response = await EligibilityCriteriaService.LoadAdditionalCohortTreatment(pid);//,pid
            if (response?.status === HTTPResponse.OK) {
                const { data } = response;
                 setIsEligible(data.metInclusionCriteria);//12108 fix 
                 setadditionalPriority((prev) => ({
                    ...prev,
                    s15Empty: false,
                    s16Empty: false,
                    metInclusionCriteria: data.metInclusionCriteria,
                    PatientEligibilityId: data.patientEligibilityId

                }));

               
                data.cohortTreatmentModelList.forEach((item) => {
                    cohortTreatmentModelList.push(item)
                });
                setCohortTreatmentS15(cohortTreatmentModelList.filter(item=>item.isEligible==true))
                let ineligibleArray = [];
                let eligibleArray = [];
                let matchingcount = 0, unmatchedcount = 0;

                if (data.metInclusionCriteria == true) {
                    if (data.unmatchedOneTreatmentId != null && data.unmatchedOneTreatmentId != 0) {
                        const matchingItem = cohortTreatmentModelList.find(item => item.cohortTreatmentID === data.unmatchedOneTreatmentId);
                        eligibleArray.push(matchingItem)
                        unmatchedcount = 1;
                    }
                    else if ((data.unmatchedonecohorttreatmentid == null || data.unmatchedonecohorttreatmentid == 0 || data.unmatchedonecohorttreatmentid == undefined) && (data.cohortTreatmentPriorityID == null || data.cohortTreatmentPriorityID == 0) &&
                        (data.cohortTreatmentAdditionalPriorityList.length > 0)) {
                        if (cohortTreatmentModelList.length > 0 && chartSelectionList.length > 0) {



                            cohortTreatmentModelList.forEach((item) => {
  
                                if (item.isEligible != false) {
                                    const matchingItem = chartSelectionList.find(item2 => item2.cohortTreatmentID === item.cohortTreatmentID);
                                    let newCharts = matchingItem.newCharts ?? 0;
                                    if (newCharts != null) {
                                        let updates = matchingItem.eligibilityChartUpdates ?? 0;

                                        if (updates == newCharts) {
                                            matchingcount = matchingcount + 1;
                                            ineligibleArray.push(item);
                                        }
                                        else {
                                            unmatchedcount = unmatchedcount + 1;
                                            eligibleArray.push(item)
                                        }

                                        item.isEligible = updates == newCharts ? false : true
                                    }
                                }
                                else {
                                    matchingcount = matchingcount + 1;
                                    ineligibleArray.push(item);
                                }


                            });

                            if (data.cohortTreatmentModelList.length > 0)
                                AddLocalStorageData("CohortTreatment", JSON.stringify(cohortTreatmentModelList))
                            else
                                AddLocalStorageData("CohortTreatment", JSON.stringify([]))
                            // AddLocalStorageData("ChartSelection", JSON.stringify(data.roundModels[0].centerTreatmentRoundList))

                        }
                    }
                    else if ((data.cohortTreatmentPriorityID != null || data.cohortTreatmentPriorityID != 0) && (data.noAdditionalCohortTreatmentPriorities == null || data.noAdditionalCohortTreatmentPriorities == true || data.noAdditionalCohortTreatmentPriorities==false)) {
                        unmatchedcount = 2;
                    }
                }
                else if (data.metInclusionCriteria == false) {

                    if (data.cohortTreatmentMatchedTargetList.length > 0) {
                        for (let j = 0; j < data.cohortTreatmentMatchedTargetList.length; j++) {
                            let childData = data.cohortTreatmentMatchedTargetList[j];
                            const matchingItem = cohortTreatmentModelList.find(item => item.cohortTreatmentID === childData.cohortTreatmentId);
                            ineligibleArray.push(matchingItem);
                            matchingcount = 2;
                        }
                    }
                }





                if (matchingcount > 0 && unmatchedcount == 0) {

                    const formattedString = ineligibleArray.map((part) => {
                        let formattedName = part.cohortTreatmentName;

                        if (part.cohortTreatmentNameSuperscript) {
                            const superscript = part.cohortTreatmentNameSuperscript.slice(0, 2);
                            const remainingText = part.cohortTreatmentNameSuperscript.slice(2);
                            formattedName += `<sup class="superscript">${superscript}</sup>${remainingText}`;
                        }


                        return formattedName;
                    }).join(", ");
                    setCohortTreatment(formattedString);
                    setshowelibilitymatchtarget(true);
                    let dataarray = [];
                    let datacomponent = {
                        PatientEligibilityId: data.patientEligibilityId,
                        s15cohortTreatment: data.cohortTreatmentPriorityID,
                        s16NoCohortTreatment: data.noAdditionalCohortTreatmentPriorities == true ? 1 : 0 ?? null,
                        s16CohortTreatmentAdditionalPriority: [],
                        metInclusionCriteria: data.metInclusionCriteria,
                        cohortTreatmentMatchedTargets:[],
                        s16Empty: false,
                        s15Empty: false
                    }

                    for (let j = 0; j < ineligibleArray.length; j++) {
                        let childData = ineligibleArray[j];
                        const matchingItem = data.cohortTreatmentMatchedTargetList.find(item => item.cohortTreatmentId === childData.cohortTreatmentID);


                        let datasubcomponent = {
                            patientCohortTreatmentMatchedTargetId: matchingItem.patientCohortTreatmentMatchedTargetId != null ? matchingItem.patientCohortTreatmentMatchedTargetId : 0,
                            cohortTreatmentID: matchingItem.cohortTreatmentId != null ? matchingItem.cohortTreatmentId : 0,
                        }
                        dataarray.push(datasubcomponent);
                    }


                    datacomponent.cohortTreatmentMatchedTargets.push(dataarray)
                    setadditionalPriority(datacomponent)

                }

                else if (unmatchedcount == 1) {
                    const formattedString = eligibleArray.map((part) => {
                        let formattedName = part.cohortTreatmentName;

                        if (part.cohortTreatmentNameSuperscript) {
                            const superscript = part.cohortTreatmentNameSuperscript.slice(0, 2);
                            const remainingText = part.cohortTreatmentNameSuperscript.slice(2);
                            formattedName += `<sup class="superscript">${superscript}</sup>${remainingText}`;
                        }


                        return formattedName;
                    }).join(", ");

                    setCohortTreatment(formattedString);
                    setshowelibilitysingletarget(true);

                    setadditionalPriority((prev) => ({
                        ...prev,
                        unmatchedOneTreatmentId: eligibleArray[0].cohortTreatmentID,
                        cohortTreatmentMatchedTargets: []
                    }));

                }

                else if (unmatchedcount > 1) {
                    setshoweligibletarget(true);
                    let dataarray = [];
                    let datacomponent = {
                        PatientEligibilityId: data.patientEligibilityId,
                        s15cohortTreatment: data.cohortTreatmentPriorityID,
                            s16NoCohortTreatment: data.noAdditionalCohortTreatmentPriorities==true?1:0??null,
                        s16CohortTreatmentAdditionalPriority: [],
                        metInclusionCriteria: data.metInclusionCriteria,
                        cohortTreatmentMatchedTargets:[],
                        s16Empty: false,
                        s15Empty:false
                    }

                    if (data.cohortTreatmentAdditionalPriorityList.filter(item => item.isEligible == true).length > 0) {
                        var eligibleCohortTreatmentAdditionalPriorityList = data.cohortTreatmentAdditionalPriorityList.filter(item => item.isEligible == true);
                        for (let j = 0; j < eligibleCohortTreatmentAdditionalPriorityList.length; j++) {
                            let childData = eligibleCohortTreatmentAdditionalPriorityList[j];
                            let datasubcomponent = {
                                s16additionCohort: childData.additionalCohortTreatmentId != null ? childData.additionalCohortTreatmentId:false,
                                patientCohortTreatmentAdditionalPriorityId: childData.patientCohortTreatmentAdditionalPriorityId != null ? childData.patientCohortTreatmentAdditionalPriorityId : 0,
                                cohortTreatmentID:childData.cohortTreatmentId != null ? childData.cohortTreatmentId : 0,
                                cohortTreatmentName: childData.cohortTreatmentName != null ? childData.cohortTreatmentName : 0,
                                isEligible: childData.isEligible != null ? childData.isEligible : false,
                                cohortTreatmentNameSuperscript: childData != null ? childData.cohortTreatmentNameSuperscript:""
                            }
                            dataarray.push(datasubcomponent);
                        }
                    }

                    datacomponent.s16CohortTreatmentAdditionalPriority.push(dataarray)
                    setadditionalPriority(datacomponent)
                    const updatelist = datacomponent.s16CohortTreatmentAdditionalPriority[0].filter(item => item.cohortTreatmentID !== data.cohortTreatmentPriorityID);
                    setSelectedCohortTreatmentID(data.cohortTreatmentPriorityID);
                    setCohortTreatmentS16(updatelist)


                    const formattedString = updatelist.filter(part => {
                        return part.s16additionCohort == true;
                    }).map((part) => {
                        let formattedName = part.cohortTreatmentName;

                        if (part.cohortTreatmentNameSuperscript) {
                            const superscript = part.cohortTreatmentNameSuperscript.slice(0, 2);
                            const remainingText = part.cohortTreatmentNameSuperscript.slice(2);
                            formattedName += `<sup class="superscript">${superscript}</sup>${remainingText}`;
                        }


                        return formattedName;
                    }).join(", ");

                    const name = cohortTreatmentModelList.filter(item => item.cohortTreatmentID == data.cohortTreatmentPriorityID);
                        const treatmentNames = name.length==0 && formattedString == "" ? "" : name[0].cohortTreatmentName + ", " + formattedString
                    setCohortTreatment(treatmentNames);

                }



            }
        } catch (error) {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    }



    const Handlechange = (event) => {

        setSelectedCohortTreatmentID(event.target.value);
        let id = parseInt(event.target.value)
        let updatedList = [];
        const filteredList = additionalPriority.s16CohortTreatmentAdditionalPriority[0].filter(item => item.cohortTreatmentID !== id);
        filteredList.forEach((x) => {

            const matchingItem = cohortTreatmentS16.find(item => item.cohortTreatmentID === x.cohortTreatmentID);
            if (matchingItem != null) {
                updatedList.push(matchingItem)
            }
            else {
                updatedList.push(x)
            }

        });

        setCohortTreatmentS16(updatedList);
        setadditionalPriority((prev) => ({
            ...prev,
            s15cohortTreatment: id,
            s15Empty:false
        }));

        const treatmentname = cohortTreatmentS15.filter(item => item.cohortTreatmentID == id);
        const treatmentcheckedlist = cohortTreatmentS16.filter(item => item.s16additionCohort == true);

        const formattedString = treatmentcheckedlist.filter(part => {
            return part.s16additionCohort == true;
        }).map((part) => {
            let formattedName = part.cohortTreatmentName;

            if (part.cohortTreatmentNameSuperscript) {
                const superscript = part.cohortTreatmentNameSuperscript.slice(0, 2);
                const remainingText = part.cohortTreatmentNameSuperscript.slice(2);
                formattedName += `<sup class="superscript">${superscript}</sup>${remainingText}`;
            }


            return formattedName;
        }).join(", ");

        setCohortTreatment(cohortTreatment == "" ? treatmentname[0].cohortTreatmentName : (treatmentcheckedlist.length == 0 ? treatmentname[0].cohortTreatmentName : treatmentname[0].cohortTreatmentName + ", " + formattedString));

    };

    const HandleCheckboxchange = (event) => {
        const {  value } = event.target;


        const updatedList = cohortTreatmentS16.map(
            (item) => {
                if (item.cohortTreatmentID === parseInt(value)) {
                    // Update the specific properties of the item
                    return {
                        ...item,
                        s16additionCohort: !item.s16additionCohort, // Example of updating a specific property
                    };
                }
                return item; // Return the unmodified item
            }
        );

        const treatmentList = GetLocalStorageData("CohortTreatment");
        let cohortTreatment = JSON.parse(treatmentList);
        cohortTreatment.forEach((item) => {
            const record = updatedList.find(item2 => item2.cohortTreatmentID === item.cohortTreatmentID);
            if (record != null) {
                item.isEligible = record.s16additionCohort == true ? true : false
            }
        });


        if (cohortTreatment.length > 0)
            AddLocalStorageData("CohortTreatment", JSON.stringify(cohortTreatment))
        else
            AddLocalStorageData("CohortTreatment", JSON.stringify([]))




        setCohortTreatmentS16(updatedList)

        setadditionalPriority((prev) => ({
            ...prev,
            s16NoCohortTreatment: null,
            s16Empty:false
        }));



        const treatmentname = cohortTreatmentS15.filter(item => item.cohortTreatmentID ==additionalPriority.s15cohortTreatment);
        const treatmentcheckedlist = updatedList.filter(item => item.s16additionCohort == true);

        const formattedString = updatedList.filter(part => {
            return part.s16additionCohort == true;
        }).map((part) => {
            let formattedName = part.cohortTreatmentName;

            if (part.cohortTreatmentNameSuperscript) {
                const superscript = part.cohortTreatmentNameSuperscript.slice(0, 2);
                const remainingText = part.cohortTreatmentNameSuperscript.slice(2);
                formattedName += `<sup class="superscript">${superscript}</sup>${remainingText}`;
            }


            return formattedName;
        }).join(", ");

        setCohortTreatment(cohortTreatment == "" ? formattedString : (treatmentname.length == 0 ? formattedString : treatmentname[0].cohortTreatmentName + ", " + formattedString));


    }

    const HandleChangeNoTreatment = (event) => {
        const { name, id, value } = event.target;
        const value_int = Number(value);

        if (value_int === 1) {
            setadditionalPriority((prev) => ({
                ...prev,
                [name]: Number(value), //value,
                s16Empty:false
            }));

            const updatedList = cohortTreatmentS16.map(
                (item) => {

                    // Update the specific properties of the item
                    return {
                        ...item,
                        s16additionCohort: false, // Example of updating a specific property
                    };

                    return item; // Return the unmodified item
                }
            );


            const treatmentList = GetLocalStorageData("CohortTreatment");
            let cohortTreatment = JSON.parse(treatmentList);
            cohortTreatment.forEach((item) => {
                const record = updatedList.find(item2 => item2.cohortTreatmentID === item.cohortTreatmentID );
                if (record != null) {
                    item.isEligible = record.s16additionCohort==true ? true : false
                }
            });

            if (cohortTreatment.length > 0)
                AddLocalStorageData("CohortTreatment", JSON.stringify(cohortTreatment))
            else
                AddLocalStorageData("CohortTreatment", JSON.stringify([]))

            setCohortTreatmentS16(updatedList)
        }
    }

    const SavePage = async () => {
        try {
            ToggleLoader(true);
            if (showelibilitymatchtarget == false && showelibilitysingletarget == false) {
                if (!ValidateForm()) {
                    setShowTopErrMsg(true)
                    ToggleLoader(false);
                    return false;
                }
            }
            else setIsAllDataFilled(true);

            setShowTopErrMsg(false);

            const additionalCohortData = cohortTreatmentS16.filter(item => item.s16additionCohort === true) // Filter to include only items where s16additionCohort is true
                .map((item) => ({

                    patientCohortTreatmentAdditionalPriorityId: item.PatientCohortTreatmentAdditionalPriorityId ?? 0,
                additionalCohortTreatmentId: item.cohortTreatmentID ,
                    cohortTreatmentID: item.cohortTreatmentID
                }));

            const treatmentList = GetLocalStorageData("CohortTreatment");
            let cohortTreatment = JSON.parse(treatmentList);


            const roundModel = {
                centerTreatmentRoundList: chartSelectionList
            }
            const AdditionalPriorityModel = {
                patientId: pid,
                CenterId: centerId,
                PatientEligibilityId: additionalPriority.patientEligibilityId,
                UserId: GetLoggedInUserID(),
                cohortTreatmentPriorityID: additionalPriority.s15cohortTreatment,
                noAdditionalCohortTreatmentPriorities: additionalPriority.s16NoCohortTreatment,
                cohortTreatmentAdditionalPriorityList: additionalCohortData,
                cohortTreatmentModelList: cohortTreatment,
                metInclusionCriteria: showelibilitymatchtarget == true ? false : true,
                unmatchedOneTreatmentId: additionalPriority.unmatchedOneTreatmentId,
                roundModel: roundModel,
                cohortTreatmentMatchedTargetList: additionalPriority.cohortTreatmentMatchedTargets[0]
            };

            setIsEligible(AdditionalPriorityModel.metInclusionCriteria)


            if (pid > 0) {
                let response = await EligibilityCriteriaService.SaveAdditionalCohortTreatment(AdditionalPriorityModel);

                if (response.status !== HTTPResponse.OK) {
                    if (response?.status === HTTPResponse.Unauthorized) {
                        HandleSessionTimeout();
                    } else {
                        throw response.error;
                    }
                } else {
                    ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                    await LoadData();
                    if (AdditionalPriorityModel.metInclusionCriteria) {
                        navigatePaths.nextPage = '/Demographics'
                    }
                    //Set eligibilityCriteriaLocked property of patient object                   
                    const ptArr = { ...patientData, eligibilityCriteriaLocked: response?.data ?? false }
                    AddLocalStorageData("patient", JSON.stringify(ptArr))
                }

               ToggleLoader(false);
            }
            return true;


        } catch (error) {
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
            ToggleLoader(false);

        }
    }


    const ValidateForm = () => {
        let isValid = true;
        if (additionalPriority.s15cohortTreatment === null) {
            setadditionalPriority((prev) => ({
                ...prev,
                s15Empty: true,
            }));
            isValid = false;
        }
        const additionalCohortData = cohortTreatmentS16.filter(item => item.s16additionCohort === true)

        if ((additionalPriority.s16NoCohortTreatment == null || additionalPriority.s16NoCohortTreatment==0) && additionalCohortData.length == 0) {

            setadditionalPriority((prev) => ({
                ...prev,
                s16Empty: true,
            }));
            isValid = false;
        }

        setIsAllDataFilled(isValid);

        return isValid;
    }

    const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");

    const toggleNav = () => {
      if (isSideMenuActive === true  ) { 
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
        setIsSideMenuActive(false)
      } else if( isSideMenuActive===false ){
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
        setIsSideMenuActive(true)
      }
  }

  useEffect(() => {
 const   sidebar = document.getElementById("mySidebar");
  const  main = document.getElementById("main");


    if (isSideMenuActive === true) {
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
      } else {
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
      }
  });
    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                    </div>
                </div>
            </section>
            <section className="main-survey-section">
                <div >
                    <SideBarMenu activePageId={SideMenuItems.TransitionToChartAbstraction} />
                    
                        <>
                        <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
                                </>

                    <div className="col-pad-left" id="main">
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>TRANSITION TO CHART ABSTRACTION</h4>
                            </div>
                            {!showTopErrMsg && pid != 0 && additionalPriority.metInclusionCriteria != null &&(
                                (additionalPriority.metInclusionCriteria) ?
                                    (<div className="survey-section-content-message">
                                        <p>
                                            {EligibilityCriteria_Msgs.EligiblePatient}
                                        </p>
                                    </div>)
                                    : (additionalPriority.metInclusionCriteria==false)?
                                        (<div className="clean-error">
                                            <p>
                                                {EligibilityCriteria_Msgs.IneligiblePatient}
                                            </p>
                                        </div>)
                                    :(<></>)
                            )
                            }

                            {showTopErrMsg && (
                                <div className="clean-error ">
                                    <ErrorField
                                        show={showTopErrMsg}
                                        message={CommonError_Msgs.Hard_Empty}
                                    />
                                </div>
                            )}
                            <div className="survey-introduction-section">
                                <div className="survey-introduction-content">
                                    <div >
                                    {(showelibilitymatchtarget==true) &&
                                            <div>
                                                <p>
                                                    <b style={{ color: 'red' }}>The target number of new charts for{" "}<span dangerouslySetInnerHTML={{ __html: cohortTreatment }} style={{ color: PageDesignConstants.fontColor, fontWeight: "bolder" }} />{" "}have been met. Please select another patient for screening.</b>
                                                    <br /> Click on next arrow to proceed with entering a new patient chart.
                                                    <br /><br />
                                                </p>
                                            </div>
                                        }


                                        {(showelibilitysingletarget == true) &&
                                            <div>
                                                <p>
                                                    You will now proceed to completing the chart abstraction for this patient for{" "}<span dangerouslySetInnerHTML={{ __html: cohortTreatment }} style={{ color: PageDesignConstants.fontColor, fontWeight: "bolder" }} />. As you review and abstract the patient's chart, please remember the requirements on AE reporting according to the Study Protocol, Section 5. See <span style={{ color: 'blue' }}> <u>


                                                        {" "}
                                                        <span
                                                            data-tooltip-id="encounterTip"
                                                            style={{ color: "blue" }}
                                                            onClick={() => setShowHideHyperlinkModalAEReporting(true)}
                                                        >AE Reporting</span>
                                                        {showHideHyperlinkModalAEReporting && (
                                                            <InfoPopUp description={hyperlinkTextAEReporting}
                                                                OkClick={() => setShowHideHyperlinkModalAEReporting(false)} />)}
                                                        {" "}


                                                    </u></span> for more information. <br /> Please click on  Next Arrow to proceed to the next section and complete data extraction.
                                                    <br /><br />
                                                </p>
                                            </div>
                                        }

                                        {(showeligibletarget == true) &&
                                            <div>
                                                <p>
                                                    This patient qualifies for chart data abstraction for more than 1 treatment cohort. We would like to prioritize extracting data for the treatment cohort(s) that your site <u>has not yet met or anticipates will be difficult to meet the target number for abstraction.</u> {" "}<b>Please do not contribute patients to a given cohort if the target for that cohort is already met for this round of data collection.</b>{" "} From our records, the following treatment cohorts have not yet met the target for this round of data abstraction:{" "}<br /><br />
                                                </p>



                                                <div className="survey-question-section">
                                                    <div className="survey-question-content">
                                                        <div className="question-answer">

                                                            <div className="question-bot-sapce">
                                                                <div className="question question-weight">
                                                                    <span>S15.</span>
                                                                    <span className="quest-text-pad">
                                                                        <span>
                                                                            We would like to prioritize cohorts that has the smallest number treated at your site.{" "} Which of the above treatment cohorts is least likely to meet the quota for this round of data collection?{" "}
                                                                            <b>This will be the treatment cohort selected for this patient to contribute data.</b> </span></span>
                                                                </div>
                                                                <div className=" double-dig-answer">

                                                                    {cohortTreatmentS15?.map((i) => {
                                                                        return (
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <label >   <input
                                                                                    type="radio"
                                                                                    id={i}
                                                                                    key={`cohorttreatment-${i.cohortTreatmentID}`}
                                                                                    value={i.cohortTreatmentID}
                                                                                    name={"cohorttreatment-" + i}
                                                                                    checked={
                                                                                        selectedCohortTreatmentID == i.cohortTreatmentID
                                                                                    }
                                                                                    onChange={Handlechange}
                                                                                />

                                                                                    <span className="radio-text-padding">
                                                                                        {i.cohortTreatmentName}
                                                                                        {i.cohortTreatmentNameSuperscript == null ? (
                                                                                            ''
                                                                                        ) : (
                                                                                            <>
                                                                                                <sup className="supscript">{i.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                                                                                {i.cohortTreatmentNameSuperscript.slice(2)}
                                                                                            </>
                                                                                        )}
                                                                                    </span></label>
                                                                            </div>
                                                                        );
                                                                    })}

                                                                </div>
                                                                <ErrorField show={additionalPriority.s15Empty} message={EligibilityCriteria_Msgs.s15} />

                                                            </div>

                                                            <br/>
                                                            <div className="question-bot-sapce">
                                                                <div className="question question-weight">
                                                                    <span>S16.</span>
                                                                    <span className="quest-text-pad">
                                                                        <span>
                                                                            To maximize the independence across patients between cohorts, we would like to <u>minimize the number of patients contributing data to &gt;1 cohort.</u>{" "} Thus, we do not wish to have this patient contribute to &gt;1 cohort unless your site <u>must</u> do so to meet the target number for a given cohort.{" "} Do you also <u>have to</u> abstract the data of this patient for another treatment cohort due to insufficient number of eligible patients for that cohort to meet the quota for this round of data collection? {" "}  </span></span>
                                                                </div>
                                                                <div className=" double-dig-answer">
                                                                    <div className="answer-list-text answer-pad-left">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                id="s16NoCohortTreatment"
                                                                                name="s16NoCohortTreatment"
                                                                                value={1}
                                                                                checked={additionalPriority.s16NoCohortTreatment === 1}
                                                                                onChange={HandleChangeNoTreatment}
                                                                            />
                                                                            <span className="radio-text-padding">
                                                                                No additional treatment cohorts are needed for abstraction {" "}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        {cohortTreatmentS16?.map((childdata, childidx) => (

                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <label > <input
                                                                                    type="checkbox"

                                                                                    key={childidx}
                                                                                    value={childdata.cohortTreatmentID}
                                                                                    name={"cohorttreatment-" + childidx}
                                                                                    checked={
                                                                                        childdata.s16additionCohort
                                                                                    }
                                                                                    onChange={HandleCheckboxchange}
                                                                                />


                                                                                    <span className="radio-text-padding">
                                                                                        {childdata.cohortTreatmentName}
                                                                                        {childdata.cohortTreatmentNameSuperscript == null ? (
                                                                                            ''
                                                                                        ) : (
                                                                                            <>
                                                                                                <sup className="supscript">{childdata.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                                                                                {childdata.cohortTreatmentNameSuperscript.slice(2)}
                                                                                            </>
                                                                                        )}
                                                                                    </span></label>
                                                                            </div>

                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <ErrorField show={additionalPriority.s16Empty} message={EligibilityCriteria_Msgs.s16} />

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="survey-question-section">
                                                    <div className="survey-question-content">

                                                        <p>
                                                            Based on the responses above, data will be abstracted for{" "}<span dangerouslySetInnerHTML={{ __html: cohortTreatment }} style={{ color: PageDesignConstants.fontColor, fontWeight: "bolder" }} />. You will now proceed to completing the chart abstraction for this patient for{" "}<span dangerouslySetInnerHTML={{ __html: cohortTreatment }} style={{ color: PageDesignConstants.fontColor, fontWeight: "bolder" }} />. {" "}
                                                            As you review and abstract the patient's chart, please remember the requirements on AE reporting according to the Study Protocol, Section 5. See
                                                            {" "}
                                                            <span
                                                                data-tooltip-id="encounterTip"
                                                                className="modal-popup"
                                                                onClick={() => setShowHideHyperlinkModalAEReporting(true)}
                                                            >AE Reporting</span>
                                                            {showHideHyperlinkModalAEReporting && (
                                                                <InfoPopUp description={hyperlinkTextAEReporting}
                                                                    OkClick={() => setShowHideHyperlinkModalAEReporting(false)} />)}
                                                            {" "} for more information.                                                   </p>
                                                        <br />
                                                        <p> Please click on the Next Arrow to proceed to the next section and complete data extraction.</p>
                                                        <br />

                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    <div className="survey-section-content-foot">
                                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TransitionToChartAbstraction;