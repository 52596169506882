import SaveAndNavigate from "../../../Components/SaveAndNavigate";
import SideBarMenu from "../../../Components/SideBarMenu";
import {
  HTTPResponse,
  PageDesignConstants,
  RRMMCohortTreatment,
  SideMenuItems,
  ToastMessageType,
  ToastMessages,
} from "../../../Helpers/Enums";
import PatientDetails from "../../../Components/PatientInformation";
import {
  ComborbidityErrorMsg,
  CommonError_Msgs,
  hyperlinkTextLineOfTherapy,
  hyperlinkTextStandardIMWGReportingCriteria,
  TreatmentResponseProgression_Msgs,
} from "../../../Helpers/HelperText";
import { useContext, useEffect, useState } from "react";
import ErrorField from "../../../Components/ErrorField";
import {
  GetLocalStorageData,
  GetLoggedInUserID,
  GetOrdinal,
  formatDate,
  isStringEmpty,
} from "../../../Helpers/Utilities";
import { CombordityService } from "../../../WebApiServices/Comorbidity.service";
import { AppContext } from "../../../Contexts/AppContextProvider";
import DateControl from "../../../Components/DateControl";
import { ClinicalOutcomes } from "../../../WebApiServices/ClinicalOutcomes.service";
import InfoPopUp from "../../../Components/InfoPopUp";

const TreatmentResponseProgression = () => {
  const [navigatePaths, setNavigatePaths] = useState({
    prevPage: "/IMWGReporting",
    currPage: "/TreatmentResponseProgression",
    nextPage: "/AdverseEvents",
  });

  const { ShowToast, ToggleLoader, HandleSessionTimeout ,isSideMenuActive,setIsSideMenuActive  } =
    useContext(AppContext);

  const [showTopErrMsg, setShowTopErrMsg] = useState(false);

  const [showHideHyperlinkModal, setShowHideHyperlinkModal] = useState(false);
  const [showHideHyperlinkModalIMWG, setShowHideHyperlinkModalIMWG] = useState(false);
  const [assessmentMethods, setAssessmentMethods] = useState([]);

  const [ndmmEndofFollowupDate, setNdmmEndofFollowupDate] = useState(
    "[NDMM End of Follow up Date]"
  );
  const [tecvayliEndofFollowUpDate, setTecvayliEndofFollowUpDate] = useState(
    "[Tecvayli End of Follow up Date]"
  );
  const [talveyEndofFollowUpDate, setTalveyEndofFollowUpDate] = useState(
    "[Talvey End of Follow up Date]"
  );
  const [elrexfioEndofFollowUpDate, setElrexfioEndofFollowUpDate] = useState(
    "[Elrexfio End of Follow up Date]"
  );
  const [linvoseltamabEndofFollowUpDate, setLinvoseltamabEndofFollowUpDate] =
    useState("[Linvoseltamab End of Follow up Date]");
  const [carvyktiEndofFollowUpDate, setCarvyktiEndofFollowUpDate] = useState(
    "[Carvykti End of Follow up Date]"
  );
  const [abecmaEndofFollowUpDate, setAbecmaEndofFollowUpDate] = useState(
    "[Abecma End of Follow up Date]"
  );

  const TmtResponseProgressionRow = {
    // comorbiditiesid: 0, // pk of comorbidities table
    cohorttreatmentid: 0,
    assessmentmethodid: 0,
    lineoftreatmentid: 0,
    treatmentmrdassessmentbridgeid: 0,

    assessmentMethod: [],

    treatmentresponseprogressionid1: 0,
    treatmentresponseprogressionid2: 0,
    treatmentresponseprogressionid3: 0,
    treatmentresponseprogressionid4: 0,

    cohortTreatmentName: "",
    cohortIndexDate: "",
    cohortTreatmentNameSuperscript:"",

    endofFollowupDate: "",

    //44
    Q44dateOfProgressionRd: null,
    Q44dateOfProgression_dt: "",
    Q44serum: false,
    Q44uninary: false,
    Q44freelight: false,
    Q44plasma: false,
    Q44bonelesion: false,
    Q44plasmacytomas: false,
    Q44ReasonOfProgression_oth: false,
    Q44ReasonOfProgression_txt: "",
    Q44ReasonOfProgression: null,

    Q44assessmentMethodRd: null,

    Q44dateOfProgressionEmpty: false,
    Q44dateOfProgression_dtEmpty: false,
    Q44ReasonOfProgressionEmpty: false,
    Q44ReasonOfProgressionTxtEmpty: false,
    Q44assessmentMethodEmpty: false,

    Q44DateValidation1: false,
    Q44DateValidation2: false,
    Q44DateValidation3: false,

    Q44ConditionMet: false,
    //45
    Q45FR_tmtResponseStatus: null,
    Q45FR_dateOfResponse_assessRd: null,
    Q45FR_dateOfResponse_assess_dt: "",
    Q45FR_assessmentMethodRd: null,

    Q45BR_tmtResponseStatus: null,
    Q45BR_dateOfResponse_assessRd: null,
    Q45BR_dateOfResponse_assess_dt: "",
    Q45BR_assessmentMethodRd: null,

    Q45FR_tmtResponseStatusEmpty: false,
    Q45FR_dateOfResponse_assessEmpty: false,
    Q45FR_dateOfResponse_assess_dtEmpty: false,
    Q45FR_assessmentMethodEmpty: false,

    Q45BR_tmtResponseStatusEmpty: false,
    Q45BR_dateOfResponse_assessEmpty: false,
    Q45BR_dateOfResponse_assess_dtEmpty: false,
    Q45BR_assessmentMethodEmpty: false,

    Q45FRDateValidation1: false,
    Q45FRDateValidation2: false,
    Q45FRDateValidation3: false,

    Q45BRDateValidation1: false,
    Q45BRDateValidation2: false,
    Q45BRDateValidation3: false,

    //46
    q46Row: [
      {
        treatmentmrdassessmentbridgeid: 0,
        Q46dateofassessmentRd: null,
        Q46dateofassessment_dt: "",
        Q46assessmentmethodRd: null,
        Q46sensitivityRd: null,
        Q46mrdstatusRd: null,

        Q46dateofassessmentEmpty: false,
        Q46dateofassessment_dtEmpty: false,
        Q46assessmentmethodEmpty: false,
        Q46sensitivityEmpty: false,
        Q46mrdstatusEmpty: false,


        Q46DateValidation1: false,
        Q46DateValidation2: false,
        Q46DateValidation3: false,
       // showQ46Columns:false,
      },
    ],

    //47

    Q47ConditionMet: false,

    Q47dateOfProgressionRd: null,
    Q47dateOfProgression_dt: "",
    Q47serum: false,
    Q47uninary: false,
    Q47freelight: false,
    Q47plasma: false,
    Q47bonelesion: false,
    Q47plasmacytomas: false,
    Q47ReasonOfProgression_oth: false,
    Q47ReasonOfProgression_txt: "",
    Q47ReasonOfProgression: null,

    Q47assessmentMethodRd: null,

    Q47dateOfProgressionEmpty: false,
    Q47dateOfProgression_dtEmpty: false,
    Q47ReasonOfProgressionEmpty: false,
    Q47ReasonOfProgressionTxtEmpty: false,
    Q47assessmentMethodEmpty: false,

    Q47DateValidation1: false,
    Q47DateValidation2: false,
    Q47DateValidation3: false,

    //48
    Q48FR_tmtResponseStatus: null,
    Q48FR_dateOfResponse_assessRd: null,
    Q48FR_dateOfResponse_assess_dt: "",
    Q48FR_assessmentMethodRd: null,

    Q48BR_tmtResponseStatus: null,
    Q48BR_dateOfResponse_assessRd: null,
    Q48BR_dateOfResponse_assess_dt: "",
    Q48BR_assessmentMethodRd: null,

    Q48FR_tmtResponseStatusEmpty: false,
    Q48FR_dateOfResponse_assessEmpty: false,
    Q48FR_dateOfResponse_assess_dtEmpty: false,
    Q48FR_assessmentMethodEmpty: false,

    Q48BR_tmtResponseStatusEmpty: false,
    Q48BR_dateOfResponse_assessEmpty: false,
    Q48BR_dateOfResponse_assess_dtEmpty: false,
    Q48BR_assessmentMethodEmpty: false,

    Q48BRDateValidation1: false,
    Q48BRDateValidation2: false,
    Q48BRDateValidation3: false,

    Q48FRDateValidation1: false,
    Q48FRDateValidation2: false,
    Q48FRDateValidation3: false,

    //49
    Q49ConditionMet: false,

    Q49dateOfProgressionRd: null,
    Q49dateOfProgression_dt: "",
    Q49serum: false,
    Q49uninary: false,
    Q49freelight: false,
    Q49plasma: false,
    Q49bonelesion: false,
    Q49plasmacytomas: false,
    Q49ReasonOfProgression_oth: false,
    Q49ReasonOfProgression_txt: "",
    Q49ReasonOfProgression: null,

    Q49assessmentMethodRd: null,

    Q49dateOfProgressionEmpty: false,
    Q49dateOfProgression_dtEmpty: false,
    Q49ReasonOfProgressionEmpty: false,
    Q49ReasonOfProgressionTxtEmpty: false,
    Q49assessmentMethodEmpty: false,

    Q49DateValidation1: false,
    Q49DateValidation2: false,
    Q49DateValidation3: false,

    //50
    Q50FR_tmtResponseStatus: null,
    Q50FR_dateOfResponse_assessRd: null,
    Q50FR_dateOfResponse_assess_dt: "",
    Q50FR_assessmentMethodRd: null,

    Q50BR_tmtResponseStatus: null,
    Q50BR_dateOfResponse_assessRd: null,
    Q50BR_dateOfResponse_assess_dt: "",
    Q50BR_assessmentMethodRd: null,

    Q50FR_tmtResponseStatusEmpty: false,
    Q50FR_dateOfResponse_assessEmpty: false,
    Q50FR_dateOfResponse_assess_dtEmpty: false,
    Q50FR_assessmentMethodEmpty: false,

    Q50BR_tmtResponseStatusEmpty: false,
    Q50BR_dateOfResponse_assessEmpty: false,
    Q50BR_dateOfResponse_assess_dtEmpty: false,
    Q50BR_assessmentMethodEmpty: false,

    Q50FRDateValidation1: false,
    Q50FRDateValidation2: false,
    Q50FRDateValidation3: false,

    Q50BRDateValidation1: false,
    Q50BRDateValidation2: false,
    Q50BRDateValidation3: false,

    //
    //51
    showQ51_52:false,
    Q51ConditionMet: false,
    Q51dateOfProgressionRd: null,
    Q51dateOfProgression_dt: "",
    Q51serum: false,
    Q51uninary: false,
    Q51freelight: false,
    Q51plasma: false,
    Q51bonelesion: false,
    Q51plasmacytomas: false,
    Q51ReasonOfProgression_oth: false,
    Q51ReasonOfProgression_txt: "",
    Q51ReasonOfProgression: null,

    Q51assessmentMethodRd: null,

    Q51dateOfProgressionEmpty: false,
    Q51dateOfProgression_dtEmpty: false,
    Q51ReasonOfProgressionEmpty: false,
    Q51ReasonOfProgressionTxtEmpty: false,
    Q51assessmentMethodEmpty: false,

    Q51DateValidation1: false,
    Q51DateValidation2: false,
    Q51DateValidation3: false,

    //52
    Q52FR_tmtResponseStatus: null,
    Q52FR_dateOfResponse_assessRd: null,
    Q52FR_dateOfResponse_assess_dt: "",
    Q52FR_assessmentMethodRd: null,

    Q52BR_tmtResponseStatus: null,
    Q52BR_dateOfResponse_assessRd: null,
    Q52BR_dateOfResponse_assess_dt: "",
    Q52BR_assessmentMethodRd: null,

    Q52FR_tmtResponseStatusEmpty: false,
    Q52FR_dateOfResponse_assessEmpty: false,
    Q52FR_dateOfResponse_assess_dtEmpty: false,
    Q52FR_assessmentMethodEmpty: false,

    Q52BR_tmtResponseStatusEmpty: false,
    Q52BR_dateOfResponse_assessEmpty: false,
    Q52BR_dateOfResponse_assess_dtEmpty: false,
    Q52BR_assessmentMethodEmpty: false,

    Q52FRDateValidation1: false,
    Q52FRDateValidation2: false,
    Q52FRDateValidation3: false,

    Q52BRDateValidation1: false,
    Q52BRDateValidation2: false,
    Q52BRDateValidation3: false,
  };
  const [tmtResponseProgression, setTmtResponseProgression] = useState([
    TmtResponseProgressionRow,
  ]);
  const pid = GetLocalStorageData("patientId") ?? 0;
  const storedData = GetLocalStorageData("CohortTreatment");

  const [showQ47_48, setShowQ47_48] = useState(false);
  const [showQ49_50, setShowQ49_50] = useState(false);
  const[showQ46Columns,setShowQ46columns] = useState(false);

  let cohortTreatmentModelListt = [];
  if (storedData) {
    cohortTreatmentModelListt = JSON.parse(storedData);
    cohortTreatmentModelListt = cohortTreatmentModelListt.filter(
      (c) => c.isEligible
    );
  }

  const HandleRepeaterFieldChange = (e) => {
    
    const { name, id, value, checked, entryIndex, rowIndex } = e.target;
    const value_int = Number(value);
    const index = Number(id);
    const parentIndex = name.substring(name.lastIndexOf("-") + 1);

    switch (name) {
      //radio - Q44dateOfProgression
      case "Q44dateOfProgressionRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q44dateOfProgressionRd = value_int;
        updatedArray[id].Q44dateOfProgression_dt = "";

        updatedArray[id].Q44dateOfProgressionEmpty = false;
        updatedArray[id].Q44dateOfProgression_dtEmpty = false;

        updatedArray[id].Q44DateValidation1 = false;
        updatedArray[id].Q44DateValidation2 = false;
        updatedArray[id].Q44DateValidation3 = false;

        if (value_int === 1) {
          updatedArray[id].Q44ConditionMet = false;
          updatedArray[id].Q44serum = false;
          updatedArray[id].Q44uninary = false;
          updatedArray[id].Q44freelight = false;
          updatedArray[id].Q44plasma = false;
          updatedArray[id].Q44bonelesion = false;
          updatedArray[id].Q44plasmacytomas = false;
          updatedArray[id].Q44ReasonOfProgression_oth = false;
          updatedArray[id].Q44ReasonOfProgression_txt = "";
          updatedArray[id].Q44ReasonOfProgression = null;
          updatedArray[id].Q44assessmentMethodRd = null;
          updatedArray[id].Q44ReasonOfProgressionEmpty =false;
          updatedArray[id].Q44ReasonOfProgressionTxtEmpty =false;
          updatedArray[id].Q44assessmentMethodEmpty = false;
        }

        if (value_int === 2 || value_int === 99) {
          updatedArray[id].Q44ConditionMet = true;
        } else {
          updatedArray[id].Q44ConditionMet = false;
        }

        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Q44 Reason of Progression
      case "Q44serum" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q44serum = !updatedArray[id].Q44serum;
        updatedArray[id].Q44ReasonOfProgression = null;

        updatedArray[id].Q44ReasonOfProgressionEmpty = false;
        updatedArray[id].Q44ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Uninary

      case "Q44uninary" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q44uninary = !updatedArray[id].Q44uninary;
        updatedArray[id].Q44ReasonOfProgression = null;

        updatedArray[id].Q44ReasonOfProgressionEmpty = false;
        updatedArray[id].Q44ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Free light

      case "Q44freelight" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q44freelight = !updatedArray[id].Q44freelight;
        updatedArray[id].Q44ReasonOfProgression = null;

        updatedArray[id].Q44ReasonOfProgressionEmpty = false;
        updatedArray[id].Q44ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Plasma

      case "Q44plasma" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q44plasma = !updatedArray[id].Q44plasma;
        updatedArray[id].Q44ReasonOfProgression = null;

        updatedArray[id].Q44ReasonOfProgressionEmpty = false;
        updatedArray[id].Q44ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Bone lesion

      case "Q44bonelesion" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q44bonelesion = !updatedArray[id].Q44bonelesion;
        updatedArray[id].Q44ReasonOfProgression = null;

        updatedArray[id].Q44ReasonOfProgressionEmpty = false;
        updatedArray[id].Q44ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - plasmacytomas

      case "Q44plasmacytomas" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q44plasmacytomas = !updatedArray[id].Q44plasmacytomas;
        updatedArray[id].Q44ReasonOfProgression = null;

        updatedArray[id].Q44ReasonOfProgressionEmpty = false;
        updatedArray[id].Q44ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - ReasonOfProgression_oth

      case "Q44ReasonOfProgression_oth" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q44ReasonOfProgression_oth =
          !updatedArray[id].Q44ReasonOfProgression_oth;
        updatedArray[id].Q44ReasonOfProgression = null;
        updatedArray[id].Q44ReasonOfProgression_txt = "";

        updatedArray[id].Q44ReasonOfProgressionEmpty = false;
        updatedArray[id].Q44ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - ReasonOfProgression_oth

      case "Q44ReasonOfProgression_txt" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q44ReasonOfProgression_txt = value;
        updatedArray[id].Q44ReasonOfProgression = null;
        updatedArray[id].Q44ReasonOfProgression_oth = true;

        updatedArray[id].Q44ReasonOfProgressionEmpty = false;
        updatedArray[id].Q44ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Unknown
      case "Q44ReasonOfProgression" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q44ReasonOfProgression = value_int;

        updatedArray[id].Q44serum = false;
        updatedArray[id].Q44uninary = false;
        updatedArray[id].Q44freelight = false;
        updatedArray[id].Q44plasma = false;
        updatedArray[id].Q44bonelesion = false;
        updatedArray[id].Q44plasmacytomas = false;
        updatedArray[id].Q44ReasonOfProgression_oth = false;
        updatedArray[id].Q44ReasonOfProgression_txt = "";

        updatedArray[id].Q44ReasonOfProgressionEmpty = false;
        updatedArray[id].Q44ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Method of Assessment
      case "Q44assessmentMethodRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q44assessmentMethodRd = value_int;

        updatedArray[id].Q44assessmentMethodEmpty = false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //Q45
      //radio - Treatment Response status
      case "Q45FR_tmtResponseStatus" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q45FR_tmtResponseStatus = value_int;

        updatedArray[id].Q45FR_tmtResponseStatusEmpty = false;

        if(value_int == 8 || value_int === 9) {

          updatedArray[id].Q45FR_dateOfResponse_assessRd = null;
          updatedArray[id].Q45FR_dateOfResponse_assess_dt ="";
          updatedArray[id].Q45FR_assessmentMethodRd =null;

          updatedArray[id].Q45FR_dateOfResponse_assessEmpty =false;
          updatedArray[id].Q45FR_dateOfResponse_assess_dtEmpty =false;
          updatedArray[id].Q45FR_assessmentMethodEmpty =false;

          updatedArray[id].Q45FRDateValidation1 = false;
          updatedArray[id].Q45FRDateValidation2 = false;
          updatedArray[id].Q45FRDateValidation3 = false;
        }

        setTmtResponseProgression(updatedArray);
        break;
      }

      // Date of response assessment

      case "Q45FR_dateOfResponse_assessRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q45FR_dateOfResponse_assessRd = value_int;
        updatedArray[id].Q45FR_dateOfResponse_assess_dt = "";

        updatedArray[id].Q45FR_dateOfResponse_assessEmpty = false;

        updatedArray[id].Q45FRDateValidation1 = false;
        updatedArray[id].Q45FRDateValidation2 = false;
        updatedArray[id].Q45FRDateValidation3 = false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Method of assessment
      case "Q45FR_assessmentMethodRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q45FR_assessmentMethodRd = value_int;

        updatedArray[id].Q45FR_assessmentMethodEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //Best Response
      //radio - Treatment Response status
      case "Q45BR_tmtResponseStatus" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q45BR_tmtResponseStatus = value_int;

        updatedArray[id].Q45BR_tmtResponseStatusEmpty = false;



        if(value_int == 8 || value_int === 9) {

          updatedArray[id].Q45BR_dateOfResponse_assessRd = null;
          updatedArray[id].Q45BR_dateOfResponse_assess_dt ="";
          updatedArray[id].Q45BR_assessmentMethodRd =null;

          updatedArray[id].Q45BR_dateOfResponse_assessEmpty =false;
          updatedArray[id].Q45BR_dateOfResponse_assess_dtEmpty =false;
          updatedArray[id].Q45BR_assessmentMethodEmpty =false;

          updatedArray[id].Q45BRDateValidation1 = false;
          updatedArray[id].Q45BRDateValidation2 = false;
          updatedArray[id].Q45BRDateValidation3 = false;
        }


        setTmtResponseProgression(updatedArray);
        break;
      }

      // Date of response assessment

      case "Q45BR_dateOfResponse_assessRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q45BR_dateOfResponse_assessRd = value_int;
        updatedArray[id].Q45BR_dateOfResponse_assess_dt = "";

        updatedArray[id].Q45BR_dateOfResponse_assessEmpty = false;

        updatedArray[id].Q45BRDateValidation1 = false;
        updatedArray[id].Q45BRDateValidation2 = false;
        updatedArray[id].Q45BRDateValidation3 = false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Method of assessment
      case "Q45BR_assessmentMethodRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q45BR_assessmentMethodRd = value_int;

        updatedArray[id].Q45BR_assessmentMethodEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //46 States
      // Function to handle the change of Q46dateofassessmentRd field
      case "Q46dateofassessmentRd" + id + "-" + parentIndex: {
        const temp = [...tmtResponseProgression];

        let innerArr = [...temp[parentIndex].q46Row];

        

        // Update the specific item in the inner array
        innerArr[id] = {
          ...innerArr[id],
          Q46dateofassessmentRd: value_int,
          Q46dateofassessment_dt: "",

          Q46dateofassessment_dtEmpty: false,
          Q46dateofassessmentEmpty: false,

          
        Q46DateValidation1 : false,
        Q46DateValidation2 : false,
        Q46DateValidation3 : false,
        };


        if (value_int === 1) {
        //   setShowQ46columns(true);
        // } else {
        //   setShowQ46columns(false);
            // Update all other validation states to false
            (innerArr[id] = {
              ...innerArr[id],
              Q46dateofassessment_dtEmpty: false,
              Q46dateofassessmentEmpty: false,
              Q46DateValidation1: false,
              Q46DateValidation2: false,
              Q46DateValidation3: false,
              Q46assessmentmethodRd: null,
              Q46sensitivityRd: null,
              Q46mrdstatusRd: null,

              Q46assessmentmethodEmpty:false,
              Q46sensitivityEmpty:false,
              Q46mrdstatusEmpty:false,

            });
        }

        // If value_int === 1, delete all rows below the current one
        if (value_int === 1) {
          // DeleteQ46RowsBelow(parentIndex, id);  // Call the function to delete rows below

            const deleteStartIndex = Number(id) + 1;
            if (deleteStartIndex >= 0 && deleteStartIndex < innerArr.length) {
              innerArr.splice(deleteStartIndex, innerArr.length - deleteStartIndex);
            }
          

        }
  
        
        // Assign the updated inner array back to the outer array
        temp[parentIndex] = {
          ...temp[parentIndex],
          q46Row: innerArr,
        };

        

        
       


        setTmtResponseProgression(temp);

        break;
      }

      case "Q46assessmentmethodRd" + id + "-" + parentIndex: {
        const temp = [...tmtResponseProgression];

        let innerArr = [...temp[parentIndex].q46Row];

        // Update the specific item in the inner array
        innerArr[id] = {
          ...innerArr[id],
          Q46assessmentmethodRd: value_int,
          Q46assessmentmethodEmpty: false,
        };

        // Assign the updated inner array back to the outer array
        temp[parentIndex] = {
          ...temp[parentIndex],
          q46Row: innerArr,
        };

        setTmtResponseProgression(temp);

        break;
      }

      case "Q46sensitivityRd" + id + "-" + parentIndex: {
        const temp = [...tmtResponseProgression];

        let innerArr = [...temp[parentIndex].q46Row];

        // Update the specific item in the inner array
        innerArr[id] = {
          ...innerArr[id],
          Q46sensitivityRd: value_int,
          Q46sensitivityEmpty: false,
        };

        // Assign the updated inner array back to the outer array
        temp[parentIndex] = {
          ...temp[parentIndex],
          q46Row: innerArr,
        };

        // temp[parentIndex].q46Row[id].Q46sensitivityRd = value_int;

        // temp[parentIndex].q46Row[id].Q46sensitivity = false;

        setTmtResponseProgression(temp);

        break;
      }

      case "Q46mrdstatusRd" + id + "-" + parentIndex: {
        const temp = [...tmtResponseProgression];

        let innerArr = [...temp[parentIndex].q46Row];

        // Update the specific item in the inner array
        innerArr[id] = {
          ...innerArr[id],
          Q46mrdstatusRd: value_int,
          Q46mrdstatusEmpty: false,
        };

        // Assign the updated inner array back to the outer array
        temp[parentIndex] = {
          ...temp[parentIndex],
          q46Row: innerArr,
        };

        // temp[parentIndex].q46Row[id].Q46mrdstatusRd = value_int;

        // temp[parentIndex].q46Row[id].Q46mrdstatusEmpty = false;

        setTmtResponseProgression(temp);

        break;
      }

      // 47

      //radio - Q44dateOfProgression
      case "Q47dateOfProgressionRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q47dateOfProgressionRd = value_int;
        updatedArray[id].Q47dateOfProgression_dt = "";

        updatedArray[id].Q47dateOfProgressionEmpty = false;
        updatedArray[id].Q47dateOfProgression_dtEmpty = false;

        updatedArray[id].Q47DateValidation1 = false;
        updatedArray[id].Q47DateValidation2 = false;
        updatedArray[id].Q47DateValidation3 = false;

        if (value_int === 1) {
          updatedArray[id].Q47ConditionMet = false;
          updatedArray[id].Q47serum = false;
          updatedArray[id].Q47uninary = false;
          updatedArray[id].Q47freelight = false;
          updatedArray[id].Q47plasma = false;
          updatedArray[id].Q47bonelesion = false;
          updatedArray[id].Q47plasmacytomas = false;
          updatedArray[id].Q47ReasonOfProgression_oth = false;
          updatedArray[id].Q47ReasonOfProgression_txt = "";
          updatedArray[id].Q47ReasonOfProgression = null;
          updatedArray[id].Q47assessmentMethodRd = null;

          updatedArray[id].Q47ReasonOfProgressionEmpty =false;
          updatedArray[id].Q47ReasonOfProgressionTxtEmpty =false;
          updatedArray[id].Q47assessmentMethodEmpty =false;
        }

        if (value_int === 2 || value_int === 99) {
          updatedArray[id].Q47ConditionMet = true;
        } else {
          updatedArray[id].Q47ConditionMet = false;
        }

        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Q44 Reason of Progression
      case "Q47serum" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q47serum = !updatedArray[id].Q47serum;
        updatedArray[id].Q47ReasonOfProgression = null;

        updatedArray[id].Q47ReasonOfProgressionEmpty = false;
        updatedArray[id].Q47ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Uninary

      case "Q47uninary" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q47uninary = !updatedArray[id].Q47uninary;
        updatedArray[id].Q47ReasonOfProgression = null;

        updatedArray[id].Q47ReasonOfProgressionEmpty = false;
        updatedArray[id].Q47ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Free light

      case "Q47freelight" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q47freelight = !updatedArray[id].Q47freelight;
        updatedArray[id].Q47ReasonOfProgression = null;

        updatedArray[id].Q47ReasonOfProgressionEmpty = false;
        updatedArray[id].Q47ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Plasma

      case "Q47plasma" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q47plasma = !updatedArray[id].Q47plasma;
        updatedArray[id].Q47ReasonOfProgression = null;

        updatedArray[id].Q47ReasonOfProgressionEmpty = false;
        updatedArray[id].Q47ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Bone lesion

      case "Q47bonelesion" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q47bonelesion = !updatedArray[id].Q47bonelesion;
        updatedArray[id].Q47ReasonOfProgression = null;

        updatedArray[id].Q47ReasonOfProgressionEmpty = false;
        updatedArray[id].Q47ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - plasmacytomas

      case "Q47plasmacytomas" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q47plasmacytomas = !updatedArray[id].Q47plasmacytomas;
        updatedArray[id].Q47ReasonOfProgression = null;

        updatedArray[id].Q47ReasonOfProgressionEmpty = false;
        updatedArray[id].Q47ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - ReasonOfProgression_oth

      case "Q47ReasonOfProgression_oth" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q47ReasonOfProgression_oth =
          !updatedArray[id].Q47ReasonOfProgression_oth;
        updatedArray[id].Q47ReasonOfProgression = null;
        updatedArray[id].Q47ReasonOfProgression_txt = "";

        updatedArray[id].Q47ReasonOfProgressionEmpty = false;
        updatedArray[id].Q47ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - ReasonOfProgression_oth

      case "Q47ReasonOfProgression_txt" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q47ReasonOfProgression_txt = value;
        updatedArray[id].Q47ReasonOfProgression = null;
        updatedArray[id].Q47ReasonOfProgression_oth = true;

        updatedArray[id].Q47ReasonOfProgressionEmpty = false;
        updatedArray[id].Q47ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Unknown
      case "Q47ReasonOfProgression" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q47ReasonOfProgression = value_int;

        updatedArray[id].Q47serum = false;
        updatedArray[id].Q47uninary = false;
        updatedArray[id].Q47freelight = false;
        updatedArray[id].Q47plasma = false;
        updatedArray[id].Q47bonelesion = false;
        updatedArray[id].Q47plasmacytomas = false;
        updatedArray[id].Q47ReasonOfProgression_oth = false;
        updatedArray[id].Q47ReasonOfProgression_txt = "";

        updatedArray[id].Q47ReasonOfProgressionEmpty = false;
        updatedArray[id].Q47ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Method of Assessment
      case "Q47assessmentMethodRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q47assessmentMethodRd = value_int;

        updatedArray[id].Q47assessmentMethodEmpty = false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //48
      //radio - Treatment Response status
      case "Q48FR_tmtResponseStatus" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q48FR_tmtResponseStatus = value_int;

        updatedArray[id].Q48FR_tmtResponseStatusEmpty = false;

        if(value_int == 8 || value_int === 9) {

          updatedArray[id].Q48FR_dateOfResponse_assessRd = null;
          updatedArray[id].Q48FR_dateOfResponse_assess_dt ="";
          updatedArray[id].Q48FR_assessmentMethodRd =null;

          updatedArray[id].Q48FR_dateOfResponse_assessEmpty =false;
          updatedArray[id].Q48FR_dateOfResponse_assess_dtEmpty =false;
          updatedArray[id].Q48FR_assessmentMethodEmpty =false;

          updatedArray[id].Q48FRDateValidation1 = false;
          updatedArray[id].Q48FRDateValidation2 = false;
          updatedArray[id].Q48FRDateValidation3 = false;
        }
        setTmtResponseProgression(updatedArray);
        break;
      }

      // Date of response assessment

      case "Q48FR_dateOfResponse_assessRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q48FR_dateOfResponse_assessRd = value_int;
        updatedArray[id].Q48FR_dateOfResponse_assess_dt = "";

        updatedArray[id].Q48FR_dateOfResponse_assessEmpty = false;

        updatedArray[id].Q48FRDateValidation1 = false;
        updatedArray[id].Q48FRDateValidation2 = false;
        updatedArray[id].Q48FRDateValidation3 = false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Method of assessment
      case "Q48FR_assessmentMethodRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q48FR_assessmentMethodRd = value_int;

        updatedArray[id].Q48FR_assessmentMethodEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //Best Response
      //radio - Treatment Response status
      case "Q48BR_tmtResponseStatus" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q48BR_tmtResponseStatus = value_int;

        updatedArray[id].Q48BR_tmtResponseStatusEmpty = false;



        if(value_int == 8 || value_int === 9) {

          updatedArray[id].Q48BR_dateOfResponse_assessRd = null;
          updatedArray[id].Q48BR_dateOfResponse_assess_dt ="";
          updatedArray[id].Q48BR_assessmentMethodRd =null;

          updatedArray[id].Q48BR_dateOfResponse_assessEmpty =false;
          updatedArray[id].Q48BR_dateOfResponse_assess_dtEmpty =false;
          updatedArray[id].Q48BR_assessmentMethodEmpty =false;

          updatedArray[id].Q48BRDateValidation1 = false;
          updatedArray[id].Q48BRDateValidation2 = false;
          updatedArray[id].Q48BRDateValidation3 = false;


        }
        setTmtResponseProgression(updatedArray);
        break;
      }

      // Date of response assessment

      case "Q48BR_dateOfResponse_assessRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q48BR_dateOfResponse_assessRd = value_int;
        updatedArray[id].Q48BR_dateOfResponse_assess_dt = "";

        updatedArray[id].Q48BR_dateOfResponse_assessEmpty = false;

        updatedArray[id].Q48BRDateValidation1 = false;
        updatedArray[id].Q48BRDateValidation2 = false;
        updatedArray[id].Q48BRDateValidation3 = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Method of assessment
      case "Q48BR_assessmentMethodRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q48BR_assessmentMethodRd = value_int;

        updatedArray[id].Q48BR_assessmentMethodEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //49,50
      //radio -
      case "Q49dateOfProgressionRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q49dateOfProgressionRd = value_int;
        updatedArray[id].Q49dateOfProgression_dt = "";

        updatedArray[id].Q49dateOfProgressionEmpty = false;
        updatedArray[id].Q49dateOfProgression_dtEmpty = false;

        updatedArray[id].Q49DateValidation1 = false;
        updatedArray[id].Q49DateValidation2 = false;
        updatedArray[id].Q49DateValidation3 = false;

        if (value_int === 1) {
          updatedArray[id].Q49ConditionMet = false;
          updatedArray[id].Q49serum = false;
          updatedArray[id].Q49uninary = false;
          updatedArray[id].Q49freelight = false;
          updatedArray[id].Q49plasma = false;
          updatedArray[id].Q49bonelesion = false;
          updatedArray[id].Q49plasmacytomas = false;
          updatedArray[id].Q49ReasonOfProgression_oth = false;
          updatedArray[id].Q49ReasonOfProgression_txt = "";
          updatedArray[id].Q49ReasonOfProgression = null;
          updatedArray[id].Q49assessmentMethodRd = null;


          
          updatedArray[id].Q49ReasonOfProgressionEmpty =false;
          updatedArray[id].Q49ReasonOfProgressionTxtEmpty =false;
          updatedArray[id].Q49assessmentMethodEmpty =false;
        }

        if (value_int === 2 || value_int === 99) {
          updatedArray[id].Q49ConditionMet = true;
        } else {
          updatedArray[id].Q49ConditionMet = false;
        }

        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Q49 Reason of Progression
      case "Q49serum" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q49serum = !updatedArray[id].Q47serum;
        updatedArray[id].Q49ReasonOfProgression = null;

        updatedArray[id].Q49ReasonOfProgressionEmpty = false;
        updatedArray[id].Q49ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Uninary

      case "Q49uninary" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q49uninary = !updatedArray[id].Q47uninary;
        updatedArray[id].Q49ReasonOfProgression = null;

        updatedArray[id].Q49ReasonOfProgressionEmpty = false;
        updatedArray[id].Q49ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Free light

      case "Q49freelight" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q49freelight = !updatedArray[id].Q49freelight;
        updatedArray[id].Q49ReasonOfProgression = null;

        updatedArray[id].Q49ReasonOfProgressionEmpty = false;
        updatedArray[id].Q49ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Plasma

      case "Q49plasma" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q49plasma = !updatedArray[id].Q49plasma;
        updatedArray[id].Q49ReasonOfProgression = null;

        updatedArray[id].Q49ReasonOfProgressionEmpty = false;
        updatedArray[id].Q49ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Bone lesion

      case "Q49bonelesion" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q49bonelesion = !updatedArray[id].Q49bonelesion;
        updatedArray[id].Q49ReasonOfProgression = null;

        updatedArray[id].Q49ReasonOfProgressionEmpty = false;
        updatedArray[id].Q49ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - plasmacytomas

      case "Q49plasmacytomas" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q49plasmacytomas = !updatedArray[id].Q49plasmacytomas;
        updatedArray[id].Q49ReasonOfProgression = null;

        updatedArray[id].Q49ReasonOfProgressionEmpty = false;
        updatedArray[id].Q49ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - ReasonOfProgression_oth

      case "Q49ReasonOfProgression_oth" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q49ReasonOfProgression_oth =
          !updatedArray[id].Q49ReasonOfProgression_oth;
        updatedArray[id].Q49ReasonOfProgression = null;
        updatedArray[id].Q49ReasonOfProgression_txt = "";

        updatedArray[id].Q49ReasonOfProgressionEmpty = false;
        updatedArray[id].Q49ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - ReasonOfProgression_oth

      case "Q49ReasonOfProgression_txt" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q49ReasonOfProgression_txt = value;
        updatedArray[id].Q49ReasonOfProgression = null;
        updatedArray[id].Q49ReasonOfProgression_oth = true;

        updatedArray[id].Q49ReasonOfProgressionEmpty = false;
        updatedArray[id].Q49ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Unknown
      case "Q49ReasonOfProgression" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q49ReasonOfProgression = value_int;

        updatedArray[id].Q49serum = false;
        updatedArray[id].Q49uninary = false;
        updatedArray[id].Q49freelight = false;
        updatedArray[id].Q49plasma = false;
        updatedArray[id].Q49bonelesion = false;
        updatedArray[id].Q49plasmacytomas = false;
        updatedArray[id].Q49ReasonOfProgression_oth = false;
        updatedArray[id].Q49ReasonOfProgression_txt = "";

        updatedArray[id].Q49ReasonOfProgressionEmpty = false;
        updatedArray[id].Q49ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Method of Assessment
      case "Q49assessmentMethodRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q49assessmentMethodRd = value_int;

        updatedArray[id].Q49assessmentMethodEmpty = false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //50
      //radio - Treatment Response status
      case "Q50FR_tmtResponseStatus" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q50FR_tmtResponseStatus = value_int;

        updatedArray[id].Q50FR_tmtResponseStatusEmpty = false;


        if(value_int == 8 || value_int === 9) {

          updatedArray[id].Q50FR_dateOfResponse_assessRd = null;
          updatedArray[id].Q50FR_dateOfResponse_assess_dt ="";
          updatedArray[id].Q50FR_assessmentMethodRd =null;


          updatedArray[id].Q50FR_dateOfResponse_assessEmpty =false;
          updatedArray[id].Q50FR_dateOfResponse_assess_dtEmpty =false;
          updatedArray[id].Q50FR_assessmentMethodEmpty =false;


          updatedArray[id].Q50FRDateValidation1 = false;
          updatedArray[id].Q50FRDateValidation2 = false;
          updatedArray[id].Q50FRDateValidation3 = false;
        }
        
        setTmtResponseProgression(updatedArray);
        break;
      }

      // Date of response assessment

      case "Q50FR_dateOfResponse_assessRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q50FR_dateOfResponse_assessRd = value_int;
        updatedArray[id].Q50FR_dateOfResponse_assess_dt = "";

        updatedArray[id].Q50FR_dateOfResponse_assessEmpty = false;

        updatedArray[id].Q50FRDateValidation1 = false;
        updatedArray[id].Q50FRDateValidation2 = false;
        updatedArray[id].Q50FRDateValidation3 = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Method of assessment
      case "Q50FR_assessmentMethodRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q50FR_assessmentMethodRd = value_int;

        updatedArray[id].Q50FR_assessmentMethodEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //Best Response
      //radio - Treatment Response status
      case "Q50BR_tmtResponseStatus" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q50BR_tmtResponseStatus = value_int;

        updatedArray[id].Q50BR_tmtResponseStatusEmpty = false;


        if(value_int == 8 || value_int === 9) {

          updatedArray[id].Q50BR_dateOfResponse_assessRd = null;
          updatedArray[id].Q50BR_dateOfResponse_assess_dt ="";
          updatedArray[id].Q50BR_assessmentMethodRd =null;

          updatedArray[id].Q50BR_dateOfResponse_assessEmpty =false;
          updatedArray[id].Q50BR_dateOfResponse_assess_dtEmpty =false;
          updatedArray[id].Q50BR_assessmentMethodEmpty =false;

          updatedArray[id].Q50BRDateValidation1 = false;
          updatedArray[id].Q50BRDateValidation2 = false;
          updatedArray[id].Q50BRDateValidation3 = false;
        }

        setTmtResponseProgression(updatedArray);
        break;
      }

      // Date of response assessment

      case "Q50BR_dateOfResponse_assessRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q50BR_dateOfResponse_assessRd = value_int;
        updatedArray[id].Q50BR_dateOfResponse_assess_dt = "";

        updatedArray[id].Q50BR_dateOfResponse_assessEmpty = false;

        
        updatedArray[id].Q50BRDateValidation1 = false;
        updatedArray[id].Q50BRDateValidation2 = false;
        updatedArray[id].Q50BRDateValidation3 = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Method of assessment
      case "Q50BR_assessmentMethodRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q50BR_assessmentMethodRd = value_int;

        updatedArray[id].Q50BR_assessmentMethodEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      // 51,52

      case "Q51dateOfProgressionRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q51dateOfProgressionRd = value_int;
        updatedArray[id].Q51dateOfProgression_dt = "";

        updatedArray[id].Q51dateOfProgressionEmpty = false;
        updatedArray[id].Q51dateOfProgression_dtEmpty = false;

        updatedArray[id].Q51DateValidation1 = false;
        updatedArray[id].Q51DateValidation2 = false;
        updatedArray[id].Q51DateValidation3 = false;

        if (value_int === 1) {
          updatedArray[id].Q51ConditionMet = false;
          updatedArray[id].Q51serum = false;
          updatedArray[id].Q51uninary = false;
          updatedArray[id].Q51freelight = false;
          updatedArray[id].Q51plasma = false;
          updatedArray[id].Q51bonelesion = false;
          updatedArray[id].Q51plasmacytomas = false;
          updatedArray[id].Q51ReasonOfProgression_oth = false;
          updatedArray[id].Q51ReasonOfProgression_txt = "";
          updatedArray[id].Q51ReasonOfProgression = null;
          updatedArray[id].Q51assessmentMethodRd = null;

          updatedArray[id].Q51ReasonOfProgressionEmpty =false;
          updatedArray[id].Q51ReasonOfProgressionTxtEmpty =false;
          updatedArray[id].Q51assessmentMethodEmpty =false;
        }

        if (value_int === 2 || value_int === 99) {
          updatedArray[id].Q51ConditionMet = true;
        } else {
          updatedArray[id].Q51ConditionMet = false;
        }

        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Q49 Reason of Progression
      case "Q51serum" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q51serum = !updatedArray[id].Q47serum;
        updatedArray[id].Q51ReasonOfProgression = null;

        updatedArray[id].Q51ReasonOfProgressionEmpty = false;
        updatedArray[id].Q51ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Uninary

      case "Q51uninary" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q51uninary = !updatedArray[id].Q47uninary;
        updatedArray[id].Q51ReasonOfProgression = null;

        updatedArray[id].Q51ReasonOfProgressionEmpty = false;
        updatedArray[id].Q51ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Free light

      case "Q51freelight" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q51freelight = !updatedArray[id].Q51freelight;
        updatedArray[id].Q51ReasonOfProgression = null;

        updatedArray[id].Q51ReasonOfProgressionEmpty = false;
        updatedArray[id].Q51ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Plasma

      case "Q51plasma" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q51plasma = !updatedArray[id].Q49plasma;
        updatedArray[id].Q51ReasonOfProgression = null;

        updatedArray[id].Q51ReasonOfProgressionEmpty = false;
        updatedArray[id].Q51ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - Bone lesion

      case "Q51bonelesion" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q51bonelesion = !updatedArray[id].Q51bonelesion;
        updatedArray[id].Q51ReasonOfProgression = null;

        updatedArray[id].Q51ReasonOfProgressionEmpty = false;
        updatedArray[id].Q51ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - plasmacytomas

      case "Q51plasmacytomas" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q51plasmacytomas = !updatedArray[id].Q51plasmacytomas;
        updatedArray[id].Q51ReasonOfProgression = null;

        updatedArray[id].Q51ReasonOfProgressionEmpty = false;
        updatedArray[id].Q51ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - ReasonOfProgression_oth

      case "Q51ReasonOfProgression_oth" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q51ReasonOfProgression_oth =
          !updatedArray[id].Q51ReasonOfProgression_oth;
        updatedArray[id].Q51ReasonOfProgression = null;
        updatedArray[id].Q51ReasonOfProgression_txt = "";

        updatedArray[id].Q51ReasonOfProgressionEmpty = false;
        updatedArray[id].Q51ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //checkbox - ReasonOfProgression_oth

      case "Q51ReasonOfProgression_txt" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q51ReasonOfProgression_txt = value;
        updatedArray[id].Q51ReasonOfProgression = null;
        updatedArray[id].Q51ReasonOfProgression_oth = true;

        updatedArray[id].Q51ReasonOfProgressionEmpty = false;
        updatedArray[id].Q51ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Unknown
      case "Q51ReasonOfProgression" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q51ReasonOfProgression = value_int;

        updatedArray[id].Q51serum = false;
        updatedArray[id].Q51uninary = false;
        updatedArray[id].Q51freelight = false;
        updatedArray[id].Q51plasma = false;
        updatedArray[id].Q51bonelesion = false;
        updatedArray[id].Q51plasmacytomas = false;
        updatedArray[id].Q51ReasonOfProgression_oth = false;
        updatedArray[id].Q51ReasonOfProgression_txt = "";

        updatedArray[id].Q51ReasonOfProgressionEmpty = false;
        updatedArray[id].Q51ReasonOfProgressionTxtEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Method of Assessment
      case "Q51assessmentMethodRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q51assessmentMethodRd = value_int;

        updatedArray[id].Q51assessmentMethodEmpty = false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //52
      //radio - Treatment Response status
      case "Q52FR_tmtResponseStatus" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q52FR_tmtResponseStatus = value_int;

        updatedArray[id].Q52FR_tmtResponseStatusEmpty = false;


        if(value_int == 8 || value_int === 9) {

          updatedArray[id].Q52FR_dateOfResponse_assessRd = null;
          updatedArray[id].Q52FR_dateOfResponse_assess_dt ="";
          updatedArray[id].Q52FR_assessmentMethodRd =null;

          updatedArray[id].Q52FR_dateOfResponse_assessEmpty =false;
          updatedArray[id].Q52FR_dateOfResponse_assess_dtEmpty =false;
          updatedArray[id].Q52FR_assessmentMethodEmpty =false;

          updatedArray[id].Q52FRDateValidation1 = false;
          updatedArray[id].Q52FRDateValidation2 = false;
          updatedArray[id].Q52FRDateValidation3 = false;
        }
        setTmtResponseProgression(updatedArray);
        break;
      }

      // Date of response assessment

      case "Q52FR_dateOfResponse_assessRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q52FR_dateOfResponse_assessRd = value_int;
        updatedArray[id].Q52FR_dateOfResponse_assess_dt = "";

        updatedArray[id].Q52FR_dateOfResponse_assessEmpty = false;

        updatedArray[id].Q52FRDateValidation1 = false;
        updatedArray[id].Q52FRDateValidation2 = false;
        updatedArray[id].Q52FRDateValidation3 = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Method of assessment
      case "Q52FR_assessmentMethodRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q52FR_assessmentMethodRd = value_int;

        updatedArray[id].Q52FR_assessmentMethodEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //Best Response
      //radio - Treatment Response status
      case "Q52BR_tmtResponseStatus" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q52BR_tmtResponseStatus = value_int;

        updatedArray[id].Q52BR_tmtResponseStatusEmpty = false;

        if(value_int == 8 || value_int === 9) {

          updatedArray[id].Q52BR_dateOfResponse_assessRd = null;
          updatedArray[id].Q52BR_dateOfResponse_assess_dt ="";
          updatedArray[id].Q52BR_assessmentMethodRd =null;

          updatedArray[id].Q52BR_dateOfResponse_assessEmpty =false;
          updatedArray[id].Q52BR_dateOfResponse_assess_dtEmpty =false;
          updatedArray[id].Q52BR_assessmentMethodEmpty =false;

          updatedArray[id].Q52BRDateValidation1 = false;
          updatedArray[id].Q52BRDateValidation2 = false;
          updatedArray[id].Q52BRDateValidation3 = false;
        }

        setTmtResponseProgression(updatedArray);
        break;
      }

      // Date of response assessment

      case "Q52BR_dateOfResponse_assessRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q52BR_dateOfResponse_assessRd = value_int;
        updatedArray[id].Q52BR_dateOfResponse_assess_dt = "";

        updatedArray[id].Q52BR_dateOfResponse_assessEmpty = false;

        updatedArray[id].Q52BRDateValidation1 = false;
        updatedArray[id].Q52BRDateValidation2 = false;
        updatedArray[id].Q52BRDateValidation3 = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //radio - Method of assessment
      case "Q52BR_assessmentMethodRd" + id: {
        let updatedArray = [...tmtResponseProgression];
        updatedArray[id].Q52BR_assessmentMethodRd = value_int;

        updatedArray[id].Q52BR_assessmentMethodEmpty = false;
        setTmtResponseProgression(updatedArray);
        break;
      }
      default:
        break;
    }
  };

  const HandleRepeaterDateChange = (name, date, q46Id) => {
    
    const lastIndex = name.lastIndexOf("-");
    const id = name.slice(lastIndex + 1);
    const parentIndex = name.substring(name.lastIndexOf("-") + 1);
    switch (name) {
      //46Date

      case "Q46dateofassessment_dt-" + q46Id + "-" + parentIndex: {
        let updatedArray = [...tmtResponseProgression];

        let innerArr = [...updatedArray[parentIndex].q46Row];

        // Update the specific item in the inner array
        innerArr[q46Id] = {
          ...innerArr[q46Id],
          Q46dateofassessment_dt: date,
          Q46dateofassessmentRd: 2,

          Q46dateofassessmentEmpty: false,
          Q46dateofassessment_dtEmpty: false,

          Q46DateValidation1 : false,
          Q46DateValidation2 : false,
          Q46DateValidation3 : false,
        };

        //setShowQ46columns(true)

        // Assign the updated inner array back to the outer array
        updatedArray[parentIndex] = {
          ...updatedArray[parentIndex],
          q46Row: innerArr,
        };

        // updatedArray[parentIndex].q46Row[id].Q46dateofassessment_dt = date;
        // updatedArray[parentIndex].q46Row[id].Q46dateofassessmentRd = 2;

        // updatedArray[id].q46Row[id].Q46dateofassessmentEmpty = false;
        // updatedArray[id].q46Row[id].Q46dateofassessment_dtEmpty = false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //44 Date of progression assessment
      case "Q44dateOfProgression_dt-" + id: {
        let updatedArray = [...tmtResponseProgression];

        updatedArray[id].Q44dateOfProgression_dt = date;
        updatedArray[id].Q44dateOfProgressionRd = 2;

        updatedArray[id].Q44dateOfProgressionEmpty = false;
        updatedArray[id].Q44dateOfProgression_dtEmpty = false;


        updatedArray[id].Q44ConditionMet = true
        // updatedArray[id].Q44ConditionMet = false;
        // updatedArray[id].Q44serum = false;
        // updatedArray[id].Q44uninary = false;
        // updatedArray[id].Q44freelight = false;
        // updatedArray[id].Q44plasma = false;
        // updatedArray[id].Q44bonelesion = false;
        // updatedArray[id].Q44plasmacytomas = false;
        // updatedArray[id].Q44ReasonOfProgression_oth = false;
        // updatedArray[id].Q44ReasonOfProgression_txt = "";
        // updatedArray[id].Q44ReasonOfProgression = null;
        // updatedArray[id].Q44assessmentMethodRd = null;

        updatedArray[id].Q44DateValidation1 =false;
        updatedArray[id].Q44DateValidation2 =false;
        updatedArray[id].Q44DateValidation3 =false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //45 date of progression FR
      case "Q45FR_dateOfResponse_assess_dt-" + id: {
        let updatedArray = [...tmtResponseProgression];

        updatedArray[id].Q45FR_dateOfResponse_assess_dt = date;
        updatedArray[id].Q45FR_dateOfResponse_assessRd = 1;

        updatedArray[id].Q45FR_dateOfResponse_assessEmpty = false;
        updatedArray[id].Q45FR_dateOfResponse_assess_dtEmpty = false;


        updatedArray[id].Q45FRDateValidation1 =false;
        updatedArray[id].Q45FRDateValidation2 =false;
        updatedArray[id].Q45FRDateValidation3 =false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //BR
      case "Q45BR_dateOfResponse_assess_dt-" + id: {
        let updatedArray = [...tmtResponseProgression];

        updatedArray[id].Q45BR_dateOfResponse_assess_dt = date;
        updatedArray[id].Q45BR_dateOfResponse_assessRd = 1;

        updatedArray[id].Q45BR_dateOfResponse_assessEmpty = false;
        updatedArray[id].Q45BR_dateOfResponse_assess_dtEmpty = false;


        
        updatedArray[id].Q45BRDateValidation1 =false;
        updatedArray[id].Q45BRDateValidation2 =false;
        updatedArray[id].Q45BRDateValidation3 =false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //47

      case "Q47dateOfProgression_dt-" + id: {
        let updatedArray = [...tmtResponseProgression];

        updatedArray[id].Q47dateOfProgression_dt = date;
        updatedArray[id].Q47dateOfProgressionRd = 2;

        updatedArray[id].Q47ConditionMet = true;

        updatedArray[id].Q47dateOfProgressionEmpty = false;
        updatedArray[id].Q47dateOfProgression_dtEmpty = false;


        updatedArray[id].Q47DateValidation1 = false;
        updatedArray[id].Q47DateValidation2 = false;
        updatedArray[id].Q47DateValidation3 = false;
        
        setTmtResponseProgression(updatedArray);
        break;
      }

      //48

      case "Q48FR_dateOfResponse_assess_dt-" + id: {
        let updatedArray = [...tmtResponseProgression];

        updatedArray[id].Q48FR_dateOfResponse_assess_dt = date;
        updatedArray[id].Q48FR_dateOfResponse_assessRd = 1;

        updatedArray[id].Q48FR_dateOfResponse_assessEmpty = false;
        updatedArray[id].Q48FR_dateOfResponse_assess_dtEmpty = false;

        updatedArray[id].Q48FRDateValidation1 = false;
        updatedArray[id].Q48FRDateValidation2 = false;
        updatedArray[id].Q48FRDateValidation3 = false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //BR
      case "Q48BR_dateOfResponse_assess_dt-" + id: {
        let updatedArray = [...tmtResponseProgression];

        updatedArray[id].Q48BR_dateOfResponse_assess_dt = date;
        updatedArray[id].Q48BR_dateOfResponse_assessRd = 1;

        updatedArray[id].Q48BR_dateOfResponse_assessEmpty = false;
        updatedArray[id].Q48BR_dateOfResponse_assess_dtEmpty = false;

        updatedArray[id].Q48BRDateValidation1 = false;
        updatedArray[id].Q48BRDateValidation2 = false;
        updatedArray[id].Q48BRDateValidation3 = false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //49
      case "Q49dateOfProgression_dt-" + id: {
        let updatedArray = [...tmtResponseProgression];

        updatedArray[id].Q49dateOfProgression_dt = date;
        updatedArray[id].Q49dateOfProgressionRd = 2;

        updatedArray[id].Q49ConditionMet = true;


        updatedArray[id].Q49dateOfProgressionEmpty = false;
        updatedArray[id].Q49dateOfProgression_dtEmpty = false;


        updatedArray[id].Q49DateValidation1 =false;
        updatedArray[id].Q49DateValidation2 =false;
        updatedArray[id].Q49DateValidation3 =false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //50

      case "Q50FR_dateOfResponse_assess_dt-" + id: {
        let updatedArray = [...tmtResponseProgression];

        updatedArray[id].Q50FR_dateOfResponse_assess_dt = date;
        updatedArray[id].Q50FR_dateOfResponse_assessRd = 1;

        updatedArray[id].Q50FR_dateOfResponse_assessEmpty = false;
        updatedArray[id].Q50FR_dateOfResponse_assess_dtEmpty = false;

        
        updatedArray[id].Q50FRDateValidation1 = false;
        updatedArray[id].Q50FRDateValidation2 = false;
        updatedArray[id].Q50FRDateValidation3 = false;
        setTmtResponseProgression(updatedArray);
        break;
      }

      //BR
      case "Q50BR_dateOfResponse_assess_dt-" + id: {
        let updatedArray = [...tmtResponseProgression];

        updatedArray[id].Q50BR_dateOfResponse_assess_dt = date;
        updatedArray[id].Q50BR_dateOfResponse_assessRd = 1;

        updatedArray[id].Q50BR_dateOfResponse_assessEmpty = false;
        updatedArray[id].Q50BR_dateOfResponse_assess_dtEmpty = false;

        
        updatedArray[id].Q50BRDateValidation1 = false;
        updatedArray[id].Q50BRDateValidation2 = false;
        updatedArray[id].Q50BRDateValidation3 = false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //51
      case "Q51dateOfProgression_dt-" + id: {
        let updatedArray = [...tmtResponseProgression];

        updatedArray[id].Q51dateOfProgression_dt = date;
        updatedArray[id].Q51dateOfProgressionRd = 2;
        updatedArray[id].Q51ConditionMet = true;


        updatedArray[id].Q51dateOfProgressionEmpty = false;
        updatedArray[id].Q51dateOfProgression_dtEmpty = false;

        updatedArray[id].Q51DateValidation1 =false;
        updatedArray[id].Q51DateValidation2 =false;
        updatedArray[id].Q51DateValidation3 =false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //52

      case "Q52FR_dateOfResponse_assess_dt-" + id: {
        let updatedArray = [...tmtResponseProgression];

        updatedArray[id].Q52FR_dateOfResponse_assess_dt = date;
        updatedArray[id].Q52FR_dateOfResponse_assessRd = 1;

        updatedArray[id].Q52FR_dateOfResponse_assessEmpty = false;
        updatedArray[id].Q52FR_dateOfResponse_assess_dtEmpty = false;


        updatedArray[id].Q52FRDateValidation1 = false;
        updatedArray[id].Q52FRDateValidation2 = false;
        updatedArray[id].Q52FRDateValidation3 = false;

        setTmtResponseProgression(updatedArray);
        break;
      }

      //BR
      case "Q52BR_dateOfResponse_assess_dt-" + id: {
        let updatedArray = [...tmtResponseProgression];

        updatedArray[id].Q52BR_dateOfResponse_assess_dt = date;
        updatedArray[id].Q52BR_dateOfResponse_assessRd = 1;

        updatedArray[id].Q52BR_dateOfResponse_assessEmpty = false;
        updatedArray[id].Q52BR_dateOfResponse_assess_dtEmpty = false;


        updatedArray[id].Q52BRDateValidation1 = false;
        updatedArray[id].Q52BRDateValidation2 = false;
        updatedArray[id].Q52BRDateValidation3 = false;

        setTmtResponseProgression(updatedArray);
        break;
      }
      default:
        break;
    }
  };

  const LoadData = async () => {
    debugger
    try {
      if (pid > 0) {
        const response = await ClinicalOutcomes.LoadTreatmentResponseData(pid);
        if (response?.status === HTTPResponse.OK) {
          const { data } = response;
          console.log(data.cohortLotNumberListRRMM)
          setAssessmentMethods(data.assessmentMethods);

          setNdmmEndofFollowupDate(
            data.ndmmEndOfFollowUpDate ==null|| data.ndmmEndOfFollowUpDate==""? (
            data.ndmmEndOfFollowUpDateCohort ==null|| data.ndmmEndOfFollowUpDateCohort==""?
            "[NDMM End of Follow up Date]":data.ndmmEndOfFollowUpDateCohort):data.ndmmEndOfFollowUpDate
          );

          if (
            data.rrmmEndOfFollowUpDates &&
            data.rrmmEndOfFollowUpDates.length > 0
          ) {
            data.rrmmEndOfFollowUpDates.forEach((a) => {
              if (a.cohorttreatmentid === RRMMCohortTreatment.TECVAYLI) {
                setTecvayliEndofFollowUpDate(
                  a.bispecificsEndOfFollowUp ==null|| a.bispecificsEndOfFollowUp==""? (
                    data.ndmmEndOfFollowUpDateCohort ==null|| data.ndmmEndOfFollowUpDateCohort==""?
                    "[Tecvayli End of Follow up Date]":data.ndmmEndOfFollowUpDateCohort): a.bispecificsEndOfFollowUp

                 
                );
              }
              if (a.cohorttreatmentid === RRMMCohortTreatment.TALVEY) {
                setTalveyEndofFollowUpDate(
                  a.bispecificsEndOfFollowUp || data.ndmmEndOfFollowUpDateCohort || "[Talvey End of Follow up Date]"
                );
              }

              if (a.cohorttreatmentid === RRMMCohortTreatment.ELREXFIO) {
                setElrexfioEndofFollowUpDate(
                  a.bispecificsEndOfFollowUp ||  data.ndmmEndOfFollowUpDateCohort ||
                    "[Elrexfio End of Follow up Date]"
                );
              }

              if (a.cohorttreatmentid === RRMMCohortTreatment.Linvoseltamab) {
                setLinvoseltamabEndofFollowUpDate(
                  a.bispecificsEndOfFollowUp ||  data.ndmmEndOfFollowUpDateCohort ||
                    "[Linvoseltamab End of Follow up Date]"
                );
              }
              if (a.cohorttreatmentid === RRMMCohortTreatment.CARVYKTI) {
                setCarvyktiEndofFollowUpDate(
                  a.bispecificsEndOfFollowUp ||  data.ndmmEndOfFollowUpDateCohort ||
                    "[Carvykti End of Follow up Date]"
                );
              }
              if (a.cohorttreatmentid === RRMMCohortTreatment.ABECMA) {
                setAbecmaEndofFollowUpDate(
                  a.bispecificsEndOfFollowUp ||  data.ndmmEndOfFollowUpDateCohort || "[Abecma End of Follow up Date]"
                );
              }
            });
          }

          const cohortTreatmentMap = new Map();
          if (cohortTreatmentModelListt) {
            cohortTreatmentModelListt.forEach((item) => {
              if (item.isEligible) {
                cohortTreatmentMap.set(item.cohortTreatmentID, {
                  cohortTreatmentName: item.cohortTreatmentName,
                  cohortIndexDate: item.cohortIndexDate,
                  cohortTreatmentNameSuperscript: item.cohortTreatmentNameSuperscript,
                });
              }
            });
          }
          let repeaterData = [];
          // // Handling Treatment Response Data
          if (
            data.treatmentResponseData &&
            data.treatmentResponseData.length !== 0
          ) {
            const cohortTreatmentDataMap = new Map();

            // Populate the map with data grouped by cohortTreatmentId
            data.treatmentResponseData.forEach((item) => {
              const cohortTreatmentId = item.cohorttreatmentid;
              if (!cohortTreatmentDataMap.has(cohortTreatmentId)) {
                cohortTreatmentDataMap.set(cohortTreatmentId, []);
              }
              cohortTreatmentDataMap.get(cohortTreatmentId).push(item);
            });

            // Iterate over the map to create response items
            cohortTreatmentDataMap.forEach((items, cohortTreatmentId) => {
              // Fetch cohort treatment details
              const cohortTreatment =
                cohortTreatmentMap.get(cohortTreatmentId) || {};

              // Determine the lines of treatment present for this cohort
              const lotOne = items.find((a) => a.lineoftreatmentid === 1);
              const lotTwo = items.find((a) => a.lineoftreatmentid === 2);
              const lotThree = items.find((a) => a.lineoftreatmentid === 3);
              const subsequentLot = items.find(
                (a) => a.lineoftreatmentid === 4
              );

              // Initialize the object with common properties
              const responseItem = {
                cohorttreatmentid: cohortTreatmentId,
                cohortTreatmentName: cohortTreatment.cohortTreatmentName || null,
                cohortIndexDate: cohortTreatment.cohortIndexDate || null,
                cohortTreatmentNameSuperscript: cohortTreatment.cohortTreatmentNameSuperscript || null,

                // treatmentresponseprogressionid: item.treatmentresponseprogressionid,

                // lineoftreatmentid: item.lineoftreatmentid,
              };

              // Conditionally add questions based on line of treatment
              if (lotOne) {
                Object.assign(responseItem, {
                  // Questions 44-45
                  treatmentresponseprogressionid1:
                    lotOne.treatmentresponseprogressionid,
                  Q44dateOfProgressionRd:
                    lotOne.progressionDidnotoccur === true
                      ? 1
                      : lotOne.progressionDate
                      ? 2
                      : lotOne.progressionDateUnk === true
                      ? 99
                      : null,

                  Q44ConditionMet:
                    (lotOne.progressionDate !== "" || lotOne.progressionDateUnk === true) ? true : false,

                  Q44dateOfProgression_dt: lotOne.progressionDate,

                  Q44serum: lotOne.progressionReasonSerum,
                  Q44uninary: lotOne.progressionReasonUrinary,
                  Q44freelight: lotOne.progressionReasonFreelight,
                  Q44plasma: lotOne.progressionReasonPlasmacell,
                  Q44bonelesion: lotOne.progressionReasonIncreasesizeBone,
                  Q44plasmacytomas:
                    lotOne.progressionReasonIncreasesizePlasmacytomas,
                  Q44ReasonOfProgression_oth: lotOne.progressionReasonOth,
                  Q44ReasonOfProgression_txt: lotOne.progressionReasonOthTxt,
                  Q44ReasonOfProgression:
                    lotOne.progressionReasonUnk === true ? 99 : null,
                  Q44assessmentMethodRd: lotOne.assessmentmethodid || null,

                  Q45FR_tmtResponseStatus:
                    lotOne.firstTreatmentresponseStatus || null,

                  Q45FR_dateOfResponse_assessRd:
                    lotOne.firstResponseAssessmentDateUnk === true
                      ? 99
                      : lotOne.firstResponseAssessmentDate
                      ? 1
                      : null,

                  Q45FR_dateOfResponse_assess_dt:
                    lotOne.firstResponseAssessmentDate || "",
                  Q45FR_assessmentMethodRd:
                    lotOne.firstAssessmentmethodid || null,

                  Q45BR_tmtResponseStatus:
                    lotOne.bestTreatmentresponseStatus || null,

                  Q45BR_dateOfResponse_assessRd:
                    lotOne.bestResponseAssessmentDateUnk === true
                      ? 99
                      : lotOne.bestResponseAssessmentDate
                      ? 1
                      : null,

                  Q45BR_dateOfResponse_assess_dt:
                    lotOne.bestResponseAssessmentDate || "",
                  Q45BR_assessmentMethodRd:
                    lotOne.bestAssessmentmethodid || null,

                  Q44dateOfProgressionEmpty: false,
                  Q44dateOfProgression_dtEmpty: false,
                  Q44ReasonOfProgressionEmpty: false,
                  Q44ReasonOfProgressionTxtEmpty: false,
                  Q44assessmentMethodEmpty: false,

                  Q45FR_tmtResponseStatusEmpty: false,
                  Q45FR_dateOfResponse_assessEmpty: false,
                  Q45FR_dateOfResponse_assess_dtEmpty: false,
                  Q45FR_assessmentMethodEmpty: false,

                  Q45BR_tmtResponseStatusEmpty: false,
                  Q45BR_dateOfResponse_assessEmpty: false,
                  Q45BR_dateOfResponse_assess_dtEmpty: false,
                  Q45BR_assessmentMethodEmpty: false,

                  Q44DateValidation1: false,
                  Q44DateValidation2: false,
                  Q44DateValidation3: false,

                  Q45FRDateValidation1: false,
                  Q45FRDateValidation2: false,
                  Q45FRDateValidation3: false,

                  Q45BRDateValidation1: false,
                  Q45BRDateValidation2: false,
                  Q45BRDateValidation3: false,
                });

                // Handle q46Row (MRD Assessment) for lotOne
                const filteredMRDData =
                  data.tmtResponseRptData?.filter(
                    (mrd) => mrd.cohorttreatmentid === cohortTreatmentId
                  ) || [];
                responseItem.q46Row =
                  filteredMRDData.length > 0
                    ? filteredMRDData.map((mrd) => ({
                        treatmentmrdassessmentbridgeid:
                          mrd.treatmentmrdassessmentbridgeid,
                        Q46dateofassessmentRd:
                          mrd.mrdNotAssessed === true
                            ? 1
                            : mrd.mrdAssessesDate
                            ? 2
                            : mrd.mrdAssessesDateUnk == true
                            ? 99
                            : null,
                        Q46dateofassessment_dt: mrd.mrdAssessesDate,
                        Q46assessmentmethodRd: mrd.mrdAssessmentmethod,
                        Q46sensitivityRd: mrd.mrdAssessmentSensitivity,
                        Q46mrdstatusRd: mrd.mrdStatus,

                   //   setShowQ46Columns:(isStringEmpty(mrd.mrdAssessesDate) ||  mrd.mrdAssessesDateUnk===true )?true:false,


                        

                        Q46dateofassessmentEmpty: false,
                        Q46dateofassessment_dtEmpty: false,
                        Q46assessmentmethodEmpty: false,
                        Q46sensitivityEmpty: false,
                        Q46mrdstatusEmpty: false,

                        Q46DateValidation1 : false,
                        Q46DateValidation2 : false,
                        Q46DateValidation3 : false,
                      }))
                    : [
                        {
                          treatmentmrdassessmentbridgeid: 0,
                          Q46dateofassessmentRd: null,
                          Q46dateofassessment_dt: "",
                          Q46assessmentmethodRd: null,
                          Q46sensitivityRd: null,
                          Q46mrdstatusRd: null,

                          Q46dateofassessmentEmpty: false,
                          Q46dateofassessment_dtEmpty: false,
                          Q46assessmentmethodEmpty: false,
                          Q46sensitivityEmpty: false,
                          Q46mrdstatusEmpty: false,

                          Q46DateValidation1 : false,
                          Q46DateValidation2 : false,
                          Q46DateValidation3 : false,
                        },
                      ];
              }

              if (lotTwo) {
                Object.assign(responseItem, {
                  // Questions 47-48
                  treatmentresponseprogressionid2:
                    lotTwo.treatmentresponseprogressionid,
                  Q47dateOfProgressionRd:
                    lotTwo.progressionDidnotoccur === true
                      ? 1
                      : lotTwo.progressionDate
                      ? 2
                      : lotTwo.progressionDateUnk === true
                      ? 99
                      : null,
                  Q47dateOfProgression_dt: lotTwo.progressionDate,
                  Q47serum: lotTwo.progressionReasonSerum,
                  Q47uninary: lotTwo.progressionReasonUrinary,
                  Q47freelight: lotTwo.progressionReasonFreelight,
                  Q47plasma: lotTwo.progressionReasonPlasmacell,
                  Q47bonelesion: lotTwo.progressionReasonIncreasesizeBone,
                  Q47plasmacytomas:
                    lotTwo.progressionReasonIncreasesizePlasmacytomas,

                  Q47ReasonOfProgression_oth: lotTwo.progressionReasonOth,

                  Q47ReasonOfProgression_txt: lotTwo.progressionReasonOthTxt,
                  Q47ReasonOfProgression:
                    lotTwo.progressionReasonUnk === true ? 99 : null,
                  Q47assessmentMethodRd: lotTwo.assessmentmethodid,

                  // Q47ConditionMet:
                  //   lotTwo.progressionDidnotoccur === true ? false : true,
                    Q47ConditionMet:
                    (lotTwo.progressionDate !== "" || lotTwo.progressionDateUnk === true) ? true : false,


                  Q48FR_tmtResponseStatus: lotTwo.firstTreatmentresponseStatus,
                  Q48FR_dateOfResponse_assessRd:
                    lotTwo.firstResponseAssessmentDateUnk === true
                      ? 99
                      : lotTwo.firstResponseAssessmentDate
                      ? 1
                      : null,
                  Q48FR_dateOfResponse_assess_dt:
                    lotTwo.firstResponseAssessmentDate,
                  Q48FR_assessmentMethodRd: lotTwo.firstAssessmentmethodid,

                  Q48BR_tmtResponseStatus: lotTwo.bestTreatmentresponseStatus,
                  Q48BR_dateOfResponse_assessRd:
                    lotTwo.bestResponseAssessmentDateUnk === true
                      ? 99
                      : lotTwo.bestResponseAssessmentDate
                      ? 1
                      : null,
                  Q48BR_dateOfResponse_assess_dt:
                    lotTwo.bestResponseAssessmentDate,
                  Q48BR_assessmentMethodRd: lotTwo.bestAssessmentmethodid,

                  // Q47ConditionMet:false,
                  Q47dateOfProgressionEmpty: false,
                  Q47dateOfProgression_dtEmpty: false,
                  Q47ReasonOfProgressionEmpty: false,
                  Q47ReasonOfProgressionTxtEmpty: false,
                  Q47assessmentMethodEmpty: false,



                  Q47DateValidation1: false,
                  Q47DateValidation2: false,
                  Q47DateValidation3: false,

                  Q48FR_tmtResponseStatusEmpty: false,
                  Q48FR_dateOfResponse_assessEmpty: false,
                  Q48FR_dateOfResponse_assess_dtEmpty: false,
                  Q48FR_assessmentMethodEmpty: false,


                  Q48FRDateValidation1 : false,
                  Q48FRDateValidation2 : false,
                  Q48FRDateValidation3 : false,

                  Q48BR_tmtResponseStatusEmpty: false,
                  Q48BR_dateOfResponse_assessEmpty: false,
                  Q48BR_dateOfResponse_assess_dtEmpty: false,
                  Q48BR_assessmentMethodEmpty: false,

                  Q48BRDateValidation1 : false,
                  Q48BRDateValidation2 : false,
                  Q48BRDateValidation3 : false,
                });
              }

              if (lotThree) {
                Object.assign(responseItem, {
                  // Questions 49-50
                  treatmentresponseprogressionid3:
                    lotThree.treatmentresponseprogressionid,

                  Q49dateOfProgressionRd:
                    lotThree.progressionDidnotoccur === true
                      ? 1
                      : lotThree.progressionDate
                      ? 2
                      : lotThree.progressionDateUnk === true
                      ? 99
                      : null,
                  Q49dateOfProgression_dt: lotThree.progressionDate,
                  Q49serum: lotThree.progressionReasonSerum,
                  Q49uninary: lotThree.progressionReasonUrinary,
                  Q49freelight: lotThree.progressionReasonFreelight,
                  Q49plasma: lotThree.progressionReasonPlasmacell,
                  Q49bonelesion: lotThree.progressionReasonIncreasesizeBone,
                  Q49plasmacytomas:
                    lotThree.progressionReasonIncreasesizePlasmacytomas,
                  Q49ReasonOfProgression_oth: lotThree.progressionReasonOth,
                  Q49ReasonOfProgression_txt: lotThree.progressionReasonOthTxt,
                  Q49ReasonOfProgression: lotThree.progressionReasonUnk
                    ? 99
                    : null,
                  Q49assessmentMethodRd: lotThree.assessmentmethodid,

                  // Q49ConditionMet:
                  //   lotThree.progressionDidnotoccur === true ? false : true,

                    Q49ConditionMet:
                    (lotThree.progressionDate !== "" || lotThree.progressionDateUnk === true) ? true : false,


                  Q50FR_tmtResponseStatus:
                    lotThree.firstTreatmentresponseStatus,
                  Q50FR_dateOfResponse_assessRd:
                    lotThree.firstResponseAssessmentDateUnk === true
                      ? 99
                      : lotThree.firstResponseAssessmentDate
                      ? 1
                      : null,
                  Q50FR_dateOfResponse_assess_dt:
                    lotThree.firstResponseAssessmentDate,
                  Q50FR_assessmentMethodRd: lotThree.firstAssessmentmethodid,

                  Q50BR_tmtResponseStatus: lotThree.bestTreatmentresponseStatus,
                  Q50BR_dateOfResponse_assessRd:
                    lotThree.bestResponseAssessmentDateUnk === true
                      ? 99
                      : lotThree.bestResponseAssessmentDate
                      ? 1
                      : null,
                  Q50BR_dateOfResponse_assess_dt:
                    lotThree.bestResponseAssessmentDate,
                  Q50BR_assessmentMethodRd: lotThree.bestAssessmentmethodid,

                  // Q49dateOfProgressionEmpty :false,
                  // Q49dateOfProgression_dtEmpty:false,
                  // Q49ReasonOfProgressionEmpty:false,
                  // Q49ReasonOfProgressionTxtEmpty:false,
                  // Q49assessmentMethodEmpty:false,

                  // Q50FR_tmtResponseStatus:null,
                  // Q50FR_dateOfResponse_assessRd:null,
                  // Q50FR_dateOfResponse_assess_dt:"",
                  // Q50FR_assessmentMethodRd:null,

                  // Q50BR_tmtResponseStatus:null,
                  // Q50BR_dateOfResponse_assessRd:null,
                  // Q50BR_dateOfResponse_assess_dt:"",
                  // Q50BR_assessmentMethodRd:null,

                  Q49dateOfProgressionEmpty: false,
                  Q49dateOfProgression_dtEmpty: false,
                  Q49ReasonOfProgressionEmpty: false,
                  Q49ReasonOfProgressionTxtEmpty: false,
                  Q49assessmentMethodEmpty: false,

                  Q49DateValidation1 :false,
                  Q49DateValidation2 :false,
                  Q49DateValidation3 :false,

                  Q50FR_tmtResponseStatusEmpty: false,
                  Q50FR_dateOfResponse_assessEmpty: false,
                  Q50FR_dateOfResponse_assess_dtEmpty: false,
                  Q50FR_assessmentMethodEmpty: false,

                  Q50FRDateValidation1 : false,
                  Q50FRDateValidation2 : false,
                  Q50FRDateValidation3 : false,

                  Q50BR_tmtResponseStatusEmpty: false,
                  Q50BR_dateOfResponse_assessEmpty: false,
                  Q50BR_dateOfResponse_assess_dtEmpty: false,
                  Q50BR_assessmentMethodEmpty: false,

                  Q50BRDateValidation1 : false,
                  Q50BRDateValidation2 : false,
                  Q50BRDateValidation3 : false,
                });
              }

              if (subsequentLot) {

                // let showQ51_52 = false;
                // if (
                //   data.cohortLotNumberListRRMM &&
                //   data.cohortLotNumberListRRMM.length > 0
                // ) {
                //   data.cohortLotNumberListRRMM.forEach((item) => {
                //     if (item.rrmmLotnumberids === true && item.lotStartdate !=null ) {
                //       showQ51_52 = true;
                //     }
                //   });
                // }
                 const rrmmLot = data.cohortLotNumberListRRMM?.find(rrmmItem => rrmmItem.cohorttreatmentid === responseItem.cohorttreatmentid);
                const showQ51_52=rrmmLot?.rrmmLotnumberids === true; // && rrmmLot?.lotStartdate !=null


                Object.assign(responseItem, {
                  // Questions 51-52
                  treatmentresponseprogressionid4:
                    subsequentLot.treatmentresponseprogressionid,
                    showQ51_52,
                  Q51dateOfProgressionRd:
                    subsequentLot.progressionDidnotoccur === true
                      ? 1
                      : subsequentLot.progressionDate
                      ? 2
                      : subsequentLot.progressionDateUnk === true
                      ? 99
                      : null,
                  Q51dateOfProgression_dt: subsequentLot.progressionDate,
                  Q51serum: subsequentLot.progressionReasonSerum,
                  Q51uninary: subsequentLot.progressionReasonUrinary,
                  Q51freelight: subsequentLot.progressionReasonFreelight,
                  Q51plasma: subsequentLot.progressionReasonPlasmacell,
                  Q51bonelesion:
                    subsequentLot.progressionReasonIncreasesizeBone,
                  Q51plasmacytomas:
                    subsequentLot.progressionReasonIncreasesizePlasmacytomas,
                  Q51ReasonOfProgression_oth:
                    subsequentLot.progressionReasonOth,
                  Q51ReasonOfProgression_txt:
                    subsequentLot.progressionReasonOthTxt,
                  Q51ReasonOfProgression:
                    subsequentLot.progressionReasonUnk === true ? 99 : null,
                  Q51assessmentMethodRd: subsequentLot.assessmentmethodid,

                  Q51ConditionMet:(subsequentLot.progressionDate !== "" || subsequentLot.progressionDateUnk === true) && subsequentLot.progressionDidnotoccur !== true
                   ? true : false,


                    // subsequentLot.progressionDidnotoccur === true
                    //   ? false
                    //   : true,
                  Q52FR_tmtResponseStatus:
                    subsequentLot.firstTreatmentresponseStatus,
                  Q52FR_dateOfResponse_assessRd:
                    subsequentLot.firstResponseAssessmentDateUnk === true
                      ? 99
                      : subsequentLot.firstResponseAssessmentDate
                      ? 1
                      : null,
                  Q52FR_dateOfResponse_assess_dt:
                    subsequentLot.firstResponseAssessmentDate,
                  Q52FR_assessmentMethodRd:
                    subsequentLot.firstAssessmentmethodid,

                  Q52BR_tmtResponseStatus:
                    subsequentLot.bestTreatmentresponseStatus,
                  Q52BR_dateOfResponse_assessRd:
                    subsequentLot.bestResponseAssessmentDateUnk === true
                      ? 99
                      : subsequentLot.bestResponseAssessmentDate
                      ? 1
                      : null,
                  Q52BR_dateOfResponse_assess_dt:
                    subsequentLot.bestResponseAssessmentDate,
                  Q52BR_assessmentMethodRd:
                    subsequentLot.bestAssessmentmethodid,

                  Q51dateOfProgressionEmpty: false,
                  Q51dateOfProgression_dtEmpty: false,

                  Q51ReasonOfProgressionEmpty: false,
                  Q51ReasonOfProgressionTxtEmpty: false,
                  Q51assessmentMethodEmpty: false,


                  Q51DateValidation1 : false,
                  Q51DateValidation2 : false,
                  Q51DateValidation3 : false,

                  Q52FR_tmtResponseStatusEmpty: false,
                  Q52FR_dateOfResponse_assessEmpty: false,
                  Q52FR_dateOfResponse_assess_dtEmpty: false,
                  Q52FR_assessmentMethodEmpty: false,

                  Q52FRDateValidation1 : false,
                  Q52FRDateValidation2 : false,
                  Q52FRDateValidation3 : false,

                  Q52BR_tmtResponseStatusEmpty: false,
                  Q52BR_dateOfResponse_assessEmpty: false,
                  Q52BR_dateOfResponse_assess_dtEmpty: false,
                  Q52BR_assessmentMethodEmpty: false,

                  Q52BRDateValidation1 : false,
                  Q52BRDateValidation2 : false,
                  Q52BRDateValidation3 : false,
                });
              }

              let showQ47_48 = false;
              let showQ49_50 = false;
              //let showQ51_52 = false;

              if (
                data.cohortLotNumberListNDMM &&
                data.cohortLotNumberListNDMM.length > 0
              ) {
                data.cohortLotNumberListNDMM.forEach((item) => {
                  if (item.lotnumberid === 2 && item.notReceived === false) {
                    showQ47_48 = true;
                  }

                  if (item.lotnumberid === 3 && item.notReceived === false) {
                    showQ49_50 = true;
                  }
                });
              }
              setShowQ47_48(showQ47_48);
              setShowQ49_50(showQ49_50);

              // if (
              //   data.cohortLotNumberListRRMM &&
              //   data.cohortLotNumberListRRMM.length > 0
              // ) {
              //   data.cohortLotNumberListRRMM.forEach((item) => {
              //     if (item.rrmmLotnumberids === true && item.lotStartdate !=null) {
              //       showQ51_52 = true;
              //     }
              //   });
              // }

              // setShowQ51_52(showQ51_52);

              if (data.assessmentMethods && data.assessmentMethods.length > 0) {
                data.assessmentMethods.map((a) => {
                  TmtResponseProgressionRow.assessmentMethod.push({
                    assessmentMethodName: a.name,
                  });
                });
              }

              repeaterData.push(responseItem);
            });
     

            setTmtResponseProgression(repeaterData);

            //   //   // Merge with cohortTreatmentModelListt
            cohortTreatmentModelListt.forEach((cohort) => {
              if (
                !repeaterData.some(
                  (item) => item.cohorttreatmentid === cohort.cohortTreatmentID
                )
              ) {
                repeaterData.push({
                  ...TmtResponseProgressionRow,
                  cohorttreatmentid: cohort.cohortTreatmentID,
                  cohortTreatmentName: cohort.cohortTreatmentName,
                  cohortIndexDate: cohort.cohortIndexDate,
                  cohortTreatmentNameSuperscript:cohort.cohortTreatmentNameSuperscript || null,

                });
              }
            });

            setTmtResponseProgression(repeaterData);
          } else {

            //let showQ51_52 =false;
            // if (
            //   data.cohortLotNumberListRRMM &&
            //   data.cohortLotNumberListRRMM.length > 0
            // ) {
            //   data.cohortLotNumberListRRMM.forEach((item) => {
            //     if (item.rrmmLotnumberids === true && item.lotStartdate !=null) {
            //       showQ51_52 = true;
            //     }
            //   });
            // }
            // No comorbidity data, use cohortTreatmentModelListt
            if (cohortTreatmentModelListt != null) {
              repeaterData = cohortTreatmentModelListt.map((item, index) => {
              
                const rrmmLot = data.cohortLotNumberListRRMM?.find(rrmmItem => rrmmItem.cohorttreatmentid === item.cohortTreatmentID);
                const showQ51_52=rrmmLot?.rrmmLotnumberids === true; // && rrmmLot?.lotStartdate !=null

                return{
                ...TmtResponseProgressionRow,
                cohorttreatmentid: item.cohortTreatmentID,
                cohortTreatmentName: item.cohortTreatmentName,
                cohortIndexDate: item.cohortIndexDate,
                cohortTreatmentNameSuperscript:item.cohortTreatmentNameSuperscript || null,
                showQ51_52
                }
              });



             if (data.cohortLotNumberListNDMM && data.cohortLotNumberListNDMM.length > 0) {
               data.cohortLotNumberListNDMM.forEach((item) => {
                 if (item.lotnumberid === 2 && item.notReceived === false) {
                   setShowQ47_48(true);
                 }
                 if (item.lotnumberid === 3 && item.notReceived === false) {
                   setShowQ49_50(true);
                 }
               });
             }

            //  if (data.cohortLotNumberListRRMM && data.cohortLotNumberListRRMM.length > 0) {
            //    data.cohortLotNumberListRRMM.forEach((item) => {
            //      if (item.rrmmLotnumberids === true) {
            //       //  setShowQ51_52(true);
            //      }
            //    });
            //  }



              setTmtResponseProgression(repeaterData);
            }
          }
        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  };

  


  const SetNavPaths = () => {
    const storedData = GetLocalStorageData("CohortTreatment");
    let cohortTreatmentModelListt = [];
    let newPaths = { ...navigatePaths };
    
    if (storedData) {
      cohortTreatmentModelListt = JSON.parse(storedData);
    }

    let eligibleCohorts = cohortTreatmentModelListt.filter(
      (c) => c.isEligible === true
    );

    let rrmmCohorts = eligibleCohorts.filter((c) => !c.ndmm);

    if (rrmmCohorts.length > 0) {
      newPaths.nextPage = "/AdverseEvents";
    } else {
      newPaths.nextPage = "/AEReporting";
    }
    setNavigatePaths(newPaths);
};

  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);
      
      let isValid = validate ? ValidateForm() : true;

      if (isValid) {
        setShowTopErrMsg(false);

        // Prepare TreatmentResponseData
        const TreatmentResponseData = tmtResponseProgression.flatMap((row) => [
          // Question 44-45
          {
            Treatmentresponseprogressionid:
              row.treatmentresponseprogressionid1 || 0,
            Lineoftreatmentid: 1, // Set specific line of treatment id for 44-45
            Cohorttreatmentid: row.cohorttreatmentid,

            // Question 44
            ProgressionDidnotoccur:
              row.Q44dateOfProgressionRd === 1 ? true : null,
            ProgressionDate: row.Q44dateOfProgression_dt,
            ProgressionDateUnk: row.Q44dateOfProgressionRd === 99 ? true : null,

            ProgressionReasonSerum: row.Q44serum,
            ProgressionReasonUrinary: row.Q44uninary,
            ProgressionReasonFreelight: row.Q44freelight,
            ProgressionReasonPlasmacell: row.Q44plasma,
            ProgressionReasonIncreasesizeBone: row.Q44bonelesion,
            ProgressionReasonIncreasesizePlasmacytomas: row.Q44plasmacytomas,
            ProgressionReasonOth: row.Q44ReasonOfProgression_oth,
            ProgressionReasonOthTxt: row.Q44ReasonOfProgression_txt,

            ProgressionReasonUnk:
              row.Q44ReasonOfProgression === 99 ? true : null,
            Assessmentmethodid: row.Q44assessmentMethodRd,

            // Question 45
            FirstTreatmentresponseStatus: row.Q45FR_tmtResponseStatus,
            FirstResponseAssessmentDate: row.Q45FR_dateOfResponse_assess_dt,

            FirstResponseAssessmentDateUnk:
              row.Q45FR_dateOfResponse_assessRd === 99 ? true : null,

            FirstAssessmentmethodid: row.Q45FR_assessmentMethodRd,
            BestTreatmentresponseStatus: row.Q45BR_tmtResponseStatus,

            BestResponseAssessmentDate: row.Q45BR_dateOfResponse_assess_dt,

            BestResponseAssessmentDateUnk:
              row.Q45BR_dateOfResponse_assessRd === 99 ? true : null,
            BestAssessmentmethodid: row.Q45BR_assessmentMethodRd,
             
          },
          // Question 47-48
          {
            Treatmentresponseprogressionid:
              row.treatmentresponseprogressionid2 || 0,
            Lineoftreatmentid: 2, // Set specific line of treatment id for 47-48
            Cohorttreatmentid: row.cohorttreatmentid,

            // Question 47
            ProgressionDidnotoccur:
              row.Q47dateOfProgressionRd === 1 ? true : null,
            ProgressionDate: row.Q47dateOfProgression_dt,
            ProgressionDateUnk: row.Q47dateOfProgressionRd === 99 ? true : null,
            ProgressionReasonSerum: row.Q47serum,
            ProgressionReasonUrinary: row.Q47uninary,
            ProgressionReasonFreelight: row.Q47freelight,
            ProgressionReasonPlasmacell: row.Q47plasma,
            ProgressionReasonIncreasesizeBone: row.Q47bonelesion,
            ProgressionReasonIncreasesizePlasmacytomas: row.Q47plasmacytomas,
            ProgressionReasonOth: row.Q47ReasonOfProgression_oth,
            ProgressionReasonOthTxt: row.Q47ReasonOfProgression_txt,
            ProgressionReasonUnk:
              row.Q47ReasonOfProgression === 99 ? true : null, 
            Assessmentmethodid: row.Q47assessmentMethodRd,

            // Question 48
            FirstTreatmentresponseStatus: row.Q48FR_tmtResponseStatus,
            FirstResponseAssessmentDate: row.Q48FR_dateOfResponse_assess_dt,
            FirstResponseAssessmentDateUnk:
              row.Q48FR_dateOfResponse_assessRd === 99 ? true : null,
            FirstAssessmentmethodid: row.Q48FR_assessmentMethodRd,
            BestTreatmentresponseStatus: row.Q48BR_tmtResponseStatus,
            BestResponseAssessmentDate: row.Q48BR_dateOfResponse_assess_dt,
            BestResponseAssessmentDateUnk:
              row.Q48BR_dateOfResponse_assessRd === 99 ? true : null,
            BestAssessmentmethodid: row.Q48BR_assessmentMethodRd,
          },
          // Question 49-50
          {
            Treatmentresponseprogressionid:
              row.treatmentresponseprogressionid3 || 0,
            Lineoftreatmentid: 3, // Set specific line of treatment id for 49-50
            Cohorttreatmentid: row.cohorttreatmentid,

            // Question 49
            ProgressionDidnotoccur:
              row.Q49dateOfProgressionRd === 1 ? true : null,
            ProgressionDate: row.Q49dateOfProgression_dt || null,
            ProgressionDateUnk: row.Q49dateOfProgressionRd === 99 ? true : null,
            ProgressionReasonSerum: row.Q49serum || false,
            ProgressionReasonUrinary: row.Q49uninary || false,
            ProgressionReasonFreelight: row.Q49freelight || false,
            ProgressionReasonPlasmacell: row.Q49plasma || false,
            ProgressionReasonIncreasesizeBone: row.Q49bonelesion || false,
            ProgressionReasonIncreasesizePlasmacytomas:
              row.Q49plasmacytomas || false,
            ProgressionReasonOth: row.Q49ReasonOfProgression_oth || false,
            ProgressionReasonOthTxt: row.Q49ReasonOfProgression_txt || null,
            ProgressionReasonUnk:
              row.Q49ReasonOfProgression === 99 ? true : null,
            Assessmentmethodid: row.Q49assessmentMethodRd,

            // Question 50
            FirstTreatmentresponseStatus: row.Q50FR_tmtResponseStatus || null,
            FirstResponseAssessmentDate:
              row.Q50FR_dateOfResponse_assess_dt || null,
            FirstResponseAssessmentDateUnk:
              row.Q50FR_dateOfResponse_assessRd === 99 ? true : null,
            FirstAssessmentmethodid: row.Q50FR_assessmentMethodRd || null,
            BestTreatmentresponseStatus: row.Q50BR_tmtResponseStatus || null,
            BestResponseAssessmentDate:
              row.Q50BR_dateOfResponse_assess_dt || null,
            BestResponseAssessmentDateUnk:
              row.Q50BR_dateOfResponse_assessRd === 99 ? true : null,
            BestAssessmentmethodid: row.Q50BR_assessmentMethodRd || null,
          },
          // Question 51-52
          {
            Treatmentresponseprogressionid:
              row.treatmentresponseprogressionid4 || 0,
            Lineoftreatmentid: 4, // Set specific line of treatment id for 51-52
            Cohorttreatmentid: row.cohorttreatmentid,

            // Question 51
            ProgressionDidnotoccur:
              row.Q51dateOfProgressionRd === 1 ? true : null,
            ProgressionDate: row.Q51dateOfProgression_dt,
              ProgressionDateUnk: row.Q51dateOfProgressionRd === 99 ? true : null,
            ProgressionReasonSerum: row.Q51serum,
            ProgressionReasonUrinary: row.Q51uninary,
            ProgressionReasonFreelight: row.Q51freelight,
            ProgressionReasonPlasmacell: row.Q51plasma,
            ProgressionReasonIncreasesizeBone: row.Q51bonelesion,
            ProgressionReasonIncreasesizePlasmacytomas: row.Q51plasmacytomas,
            ProgressionReasonOth: row.Q51ReasonOfProgression_oth,
            ProgressionReasonOthTxt: row.Q51ReasonOfProgression_txt,
            ProgressionReasonUnk:
              row.Q51ReasonOfProgression === 99 ? true : null,
            Assessmentmethodid: row.Q51assessmentMethodRd,

            // Question 52
            FirstTreatmentresponseStatus: row.Q52FR_tmtResponseStatus,
            FirstResponseAssessmentDate: row.Q52FR_dateOfResponse_assess_dt,
            FirstResponseAssessmentDateUnk:
              row.Q52FR_dateOfResponse_assessRd === 99 ? true : null,
            FirstAssessmentmethodid: row.Q52FR_assessmentMethodRd,
            BestTreatmentresponseStatus: row.Q52BR_tmtResponseStatus,
            BestResponseAssessmentDate: row.Q52BR_dateOfResponse_assess_dt,
            BestResponseAssessmentDateUnk:
              row.Q52BR_dateOfResponse_assessRd === 99 ? true : null,
            BestAssessmentmethodid: row.Q52BR_assessmentMethodRd,
            IsVisibleQ51_Q52:row.showQ51_52
          },
        ]);

        // TmtResponseRptData

        const TmtResponseRptData = tmtResponseProgression.flatMap((row) =>
          row.q46Row.map((assessment) => ({
            Lineoftreatmentid: 1, // Set specific line of treatment id for 51-52
            Treatmentmrdassessmentbridgeid:
              assessment.treatmentmrdassessmentbridgeid,
            Cohorttreatmentid: row.cohorttreatmentid,

            // DateOfAssessment: assessment.Q46dateofassessment_dt ,
            MrdNotAssessed:
              assessment.Q46dateofassessmentRd === 1 ? true : null,
            MrdAssessesDate: assessment.Q46dateofassessment_dt,
            MrdAssessesDateUnk:
              assessment.Q46dateofassessmentRd === 99 ? true : null,

            MrdAssessmentmethod: assessment.Q46assessmentmethodRd,
            MrdAssessmentSensitivity: assessment.Q46sensitivityRd,
            MrdStatus: assessment.Q46mrdstatusRd,
          }))
        );

        const TreatmentResponseModel = {
          Patientid: pid,
          UserId: GetLoggedInUserID(),
          isValidated: validate,
          TmtResponseRptData: TmtResponseRptData,
          TreatmentResponseData: TreatmentResponseData,
          IsVisibleQ47_Q48: showQ47_48,
          IsVisibleQ49_Q50: showQ49_50,
        };

        if (pid > 0) {
          let response = await ClinicalOutcomes.SaveTreatmentResponseData(
            TreatmentResponseModel
          );

          if (response.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            } else throw response.error;
          } else {
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            await LoadData(pid);
            //SetNavPaths();
          }

          ToggleLoader(false);
        }
        return true;
      } else {
        ToggleLoader(false);
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  function DateValidation1(Date1, Date2, Date3) {
   
    const date1 = new Date(Date1);
    const date2 = new Date(Date2);
    const date3 = new Date(Date3);

    return date1 <= date2 && date2 <= date3;
  }

  function DateValidation2(Date1, Date2) {

    const date1 = new Date(Date1);
    const date2 = new Date(Date2);

    return date1 <= date2;
  }

  const   
      ValidateForm = () => {
    let isValid = true;
    if (tmtResponseProgression && tmtResponseProgression.length > 0) {
      const updatedArray = tmtResponseProgression.map((item, index) => {
        let EOFDate = "";

        if (item.cohorttreatmentid < 9) {
          EOFDate = ndmmEndofFollowupDate;
        }

        if (item.cohorttreatmentid === RRMMCohortTreatment.TECVAYLI) {
          EOFDate = tecvayliEndofFollowUpDate;
        }
        if (item.cohorttreatmentid === RRMMCohortTreatment.TALVEY) {
          EOFDate = talveyEndofFollowUpDate;
        }

        if (item.cohorttreatmentid === RRMMCohortTreatment.ELREXFIO) {
          EOFDate = elrexfioEndofFollowUpDate;
        }

        if (item.cohorttreatmentid === RRMMCohortTreatment.Linvoseltamab) {
          EOFDate = linvoseltamabEndofFollowUpDate;
        }

        if (item.cohorttreatmentid === RRMMCohortTreatment.CARVYKTI) {
          EOFDate = carvyktiEndofFollowUpDate;
        }

        if (item.cohorttreatmentid === RRMMCohortTreatment.ABECMA) {
          EOFDate = abecmaEndofFollowUpDate;
        }

        let Q44dateOfProgressionEmpty = false;
        if (item.Q44dateOfProgressionRd === null) {
          Q44dateOfProgressionEmpty = true;
        }

        let Q44dateOfProgression_dtEmpty = false;
        if (
          item.Q44dateOfProgressionRd === 2 &&
          item.Q44dateOfProgression_dt === ""
        ) {
          Q44dateOfProgression_dtEmpty = true;
        }

        let Q44DateValidation1 = false;
        let Q44DateValidation2 = false;
        let Q44DateValidation3 = false;

        if (
          item.Q44dateOfProgressionRd === 2 &&
          item.Q44dateOfProgression_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate != ""
        ) {
          Q44DateValidation1 = !DateValidation1(
            item.cohortIndexDate,
            item.Q44dateOfProgression_dt,
            EOFDate
          );
        }

        if (
          item.Q44dateOfProgressionRd === 2 &&
          item.Q44dateOfProgression_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate === ""
        ) {
          Q44DateValidation2 = !DateValidation2(
            item.cohortIndexDate,
            item.Q44dateOfProgression_dt
          );
        }

        if (
          item.Q44dateOfProgressionRd === 2 &&
          item.Q44dateOfProgression_dt !== "" &&
          item.cohortIndexDate === "" &&
          EOFDate !== ""
        ) {
          Q44DateValidation3 = !DateValidation2(
            item.Q44dateOfProgression_dt,
            EOFDate
          );
        }

        let Q44ReasonOfProgressionEmpty = false;
        let Q44ReasonOfProgressionTxtEmpty = false;
        if (item.Q44dateOfProgressionRd === 2 || item.Q44dateOfProgressionRd ===99) {
          if (
            item.Q44serum === false &&
            item.Q44uninary === false &&
            item.Q44freelight === false &&
            item.Q44plasma === false &&
            item.Q44bonelesion === false &&
            item.Q44plasmacytomas === false &&
            item.Q44ReasonOfProgression_oth === false &&
            item.Q44ReasonOfProgression === null
          ) {
            Q44ReasonOfProgressionEmpty = true;
          } else if (
            item.Q44ReasonOfProgression_oth === true &&
            item.Q44ReasonOfProgression_txt === ""
          ) {
            Q44ReasonOfProgressionTxtEmpty = true;
          }
        }

        let Q44assessmentMethodEmpty = false;
        if (
          (item.Q44dateOfProgressionRd === 2 || item.Q44dateOfProgressionRd === 99) &&
          item.Q44assessmentMethodRd === null
        ) {
          Q44assessmentMethodEmpty = true;
        }

        //45
        let Q45FR_tmtResponseStatusEmpty = false;
        if (item.Q45FR_tmtResponseStatus === null) {
          Q45FR_tmtResponseStatusEmpty = true;
        }

        let Q45FR_dateOfResponse_assessEmpty = false;
        if (
          (
            item.Q45FR_tmtResponseStatus !=8 && item.Q45FR_tmtResponseStatus !=9) &&
          item.Q45FR_dateOfResponse_assessRd === null) {
          Q45FR_dateOfResponse_assessEmpty = true;
        }

        let Q45FR_dateOfResponse_assess_dtEmpty = false;
        if (
          (item.Q45FR_tmtResponseStatus !=8 && item.Q45FR_tmtResponseStatus !=9) &&
          item.Q45FR_dateOfResponse_assessRd === 1 &&
          item.Q45FR_dateOfResponse_assess_dt === ""
        ) {
          Q45FR_dateOfResponse_assess_dtEmpty = true;
        }

        let Q45FR_assessmentMethodEmpty = false;
        if (
          
          (item.Q45FR_tmtResponseStatus !=8 && item.Q45FR_tmtResponseStatus !=9) &&
          item.Q45FR_assessmentMethodRd === null) {
          Q45FR_assessmentMethodEmpty = true;
        }

        let Q45BR_tmtResponseStatusEmpty = false;
        if (item.Q45BR_tmtResponseStatus === null) {
          Q45BR_tmtResponseStatusEmpty = true;
        }

        let Q45BR_dateOfResponse_assessEmpty = false;
        if (
          (item.Q45BR_tmtResponseStatus !=8 && item.Q45BR_tmtResponseStatus !=9) &&
          item.Q45BR_dateOfResponse_assessRd === null) {
          Q45BR_dateOfResponse_assessEmpty = true;
        }

        let Q45BR_dateOfResponse_assess_dtEmpty = false;
        if (
          (item.Q45BR_tmtResponseStatus !=8 && item.Q45BR_tmtResponseStatus !=9) &&
          item.Q45BR_dateOfResponse_assessRd === 1 &&
          item.Q45BR_dateOfResponse_assess_dt === ""
        ) {
          Q45BR_dateOfResponse_assess_dtEmpty = true;
        }

        let Q45BR_assessmentMethodEmpty = false;
        if (
          (item.Q45BR_tmtResponseStatus !=8 && item.Q45BR_tmtResponseStatus !=9) &&
          item.Q45BR_assessmentMethodRd === null) {
          Q45BR_assessmentMethodEmpty = true;
        }

        let Q45FRDateValidation1 = false;
        let Q45FRDateValidation2 = false;
        let Q45FRDateValidation3 = false;

        if (
          (item.Q45FR_tmtResponseStatus !=8 && item.Q45FR_tmtResponseStatus !=9) &&
          item.Q45FR_dateOfResponse_assessRd === 1 &&
          item.Q45FR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate != ""
        ) {
          Q45FRDateValidation1 = !DateValidation1(
            item.cohortIndexDate,
            item.Q45FR_dateOfResponse_assess_dt,
            EOFDate
          );
        }

        if (
          (item.Q45FR_tmtResponseStatus !=8 && item.Q45FR_tmtResponseStatus !=9) &&
          item.Q45FR_dateOfResponse_assessRd === 1 &&
          item.Q45FR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate === ""
        ) {
          Q45FRDateValidation2 = !DateValidation2(
            item.cohortIndexDate,
            item.Q45FR_dateOfResponse_assess_dt
          );
        }

        if (
          (item.Q45FR_tmtResponseStatus !=8 && item.Q45FR_tmtResponseStatus !=9) &&
          item.Q45FR_dateOfResponse_assessRd === 1 &&
          item.Q45FR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate === "" &&
          EOFDate !== ""
        ) {
          Q45FRDateValidation3 = !DateValidation2(
            item.Q45FR_dateOfResponse_assess_dt,
            EOFDate
          );
        }

        //BR
        let Q45BRDateValidation1 = false;
        let Q45BRDateValidation2 = false;
        let Q45BRDateValidation3 = false;

        if (
          (item.Q45BR_tmtResponseStatus !=8 && item.Q45BR_tmtResponseStatus !=9) &&
          item.Q45BR_dateOfResponse_assessRd === 1 &&
          item.Q45BR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate != ""
        ) {
          Q45BRDateValidation1 = !DateValidation1(
            item.cohortIndexDate,
            item.Q45BR_dateOfResponse_assess_dt,
            EOFDate
          );
        }

        if (
          (item.Q45BR_tmtResponseStatus !=8 && item.Q45BR_tmtResponseStatus !=9) &&
          item.Q45BR_dateOfResponse_assessRd === 1 &&
          item.Q45BR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate === ""
        ) {
          Q45BRDateValidation2 = !DateValidation2(
            item.cohortIndexDate,
            item.Q45BR_dateOfResponse_assess_dt
          );
        }

        if (
          (item.Q45BR_tmtResponseStatus !=8 && item.Q45BR_tmtResponseStatus !=9) &&
          item.Q45BR_dateOfResponse_assessRd === 1 &&
          item.Q45BR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate === "" &&
          EOFDate !== ""
        ) {
          Q45BRDateValidation3 = !DateValidation2(
            item.Q45BR_dateOfResponse_assess_dt,
            EOFDate
          );
        }

        //47 -------------------------------------------------------------------------
        let Q47dateOfProgressionEmpty = false;
        if (showQ47_48 === true && item.cohorttreatmentid < 9 &&  item.Q47dateOfProgressionRd === null) {
          Q47dateOfProgressionEmpty = true;
        }

        let Q47dateOfProgression_dtEmpty = false;
        if (
          showQ47_48 === true &&
          item.cohorttreatmentid < 9 &&
          item.Q47dateOfProgressionRd === 2 &&
          item.Q47dateOfProgression_dt === ""
        ) {
          Q47dateOfProgression_dtEmpty = true;
        }
//--------------------------
        // if(item.cohorttreatmentid <9 && item.Q44dateOfProgressionRd === 2 && item.Q44dateOfProgression_dt !== "")
        // {
        //     item.cohortIndexDate,item.Q44dateOfProgression_dt,item.
        // }

        let Q47DateValidation1 = false;
        let Q47DateValidation2 = false;
        let Q47DateValidation3 = false;

        if (
          item.Q47dateOfProgressionRd === 2 &&
          item.Q47dateOfProgression_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate != ""
        ) {
          Q47DateValidation1 = !DateValidation1(
            item.cohortIndexDate,
            item.Q47dateOfProgression_dt,
            EOFDate
          );
        }

        if (
          item.Q47dateOfProgressionRd === 2 &&
          item.Q47dateOfProgression_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate === ""
        ) {
          Q47DateValidation2 = !DateValidation2(
            item.cohortIndexDate,
            item.Q47dateOfProgression_dt
          );
        }

        if (
          item.Q47dateOfProgressionRd === 2 &&
          item.Q47dateOfProgression_dt !== "" &&
          item.cohortIndexDate === "" &&
          EOFDate !== ""
        ) {
          Q47DateValidation3 = !DateValidation2(
            item.Q47dateOfProgression_dt,
            EOFDate
          );
        }
//----------------------------
        let Q47ReasonOfProgressionEmpty = false;
        let Q47ReasonOfProgressionTxtEmpty = false;
        if (showQ47_48 === true && item.cohorttreatmentid < 9 &&  (item.Q47dateOfProgressionRd == 2 || item.Q47dateOfProgressionRd == 99)) {
          if (
            item.Q47serum === false &&
            item.Q47uninary === false &&
            item.Q47freelight === false &&
            item.Q47plasma === false &&
            item.Q47bonelesion === false &&
            item.Q47plasmacytomas === false &&
            item.Q47ReasonOfProgression_oth === false &&
            item.Q47ReasonOfProgression === null
          ) {
            Q47ReasonOfProgressionEmpty = true;
          } 
           if (
            showQ47_48 === true &&
            item.cohorttreatmentid < 9 &&
            item.Q47ReasonOfProgression_oth === true &&
            item.Q47ReasonOfProgression_txt === ""
          ) {
            Q47ReasonOfProgressionTxtEmpty = true;
          }
        }

        let Q47assessmentMethodEmpty = false;
        if (
          showQ47_48 === true &&
          item.cohorttreatmentid < 9 &&
          (item.Q47dateOfProgressionRd == 2 || item.Q47dateOfProgressionRd == 99) &&
          item.Q47assessmentMethodRd === null
        ) {
          Q47assessmentMethodEmpty = true;
        }

        //48 --------------------------------------------------------
        let Q48FR_tmtResponseStatusEmpty = false;
        if (showQ47_48 === true &&
          item.cohorttreatmentid < 9 &&
          item.Q48FR_tmtResponseStatus === null) {
          Q48FR_tmtResponseStatusEmpty = true;
        }

        let Q48FR_dateOfResponse_assessEmpty = false;
        if (
          (item.Q48FR_tmtResponseStatus !=8 && item.cohorttreatmentid < 9 && item.Q48FR_tmtResponseStatus !=9) &&
          showQ47_48 === true &&
          item.Q48FR_dateOfResponse_assessRd === null
        ) {
          Q48FR_dateOfResponse_assessEmpty = true;
        }

        let Q48FR_dateOfResponse_assess_dtEmpty = false;
        if (
          (item.Q48FR_tmtResponseStatus !=8  && item.cohorttreatmentid < 9 && item.Q48FR_tmtResponseStatus !=9) &&
          showQ47_48 === true &&
          item.Q48FR_dateOfResponse_assessRd === 1 &&
          item.Q48FR_dateOfResponse_assess_dt === ""
        ) {
          Q48FR_dateOfResponse_assess_dtEmpty = true;
        }

        let Q48FR_assessmentMethodEmpty = false;
        if (
          (item.Q48FR_tmtResponseStatus !=8 && item.cohorttreatmentid < 9 && item.Q48FR_tmtResponseStatus !=9) &&
          showQ47_48 === true && item.Q48FR_assessmentMethodRd === null) {
          Q48FR_assessmentMethodEmpty = true;
        }

        let Q48BR_tmtResponseStatusEmpty = false;
        if (showQ47_48 === true && item.cohorttreatmentid < 9 && item.Q48BR_tmtResponseStatus === null) {
          Q48BR_tmtResponseStatusEmpty = true;
        }

        let Q48BR_dateOfResponse_assessEmpty = false;
        if (
          (item.Q48BR_tmtResponseStatus !=8 && item.cohorttreatmentid < 9 && item.Q48BR_tmtResponseStatus !=9) &&
          showQ47_48 === true &&
          item.Q48BR_dateOfResponse_assessRd === null
        ) {
          Q48BR_dateOfResponse_assessEmpty = true;
        }

        let Q48BR_dateOfResponse_assess_dtEmpty = false;
        if (
          (item.Q48BR_tmtResponseStatus !=8 && item.cohorttreatmentid < 9 && item.Q48BR_tmtResponseStatus !=9) &&
          showQ47_48 === true &&
          item.Q48BR_dateOfResponse_assessRd === 1 &&
          item.Q48BR_dateOfResponse_assess_dt === ""
        ) {
          Q48BR_dateOfResponse_assess_dtEmpty = true;
        }
//-----------------
        let Q48FRDateValidation1 = false;
        let Q48FRDateValidation2 = false;
        let Q48FRDateValidation3 = false;

        if (
          item.Q48FR_dateOfResponse_assessRd === 1 &&
          item.Q48FR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate != ""
        ) {
          Q48FRDateValidation1 = !DateValidation1(
            item.cohortIndexDate,
            item.Q48FR_dateOfResponse_assess_dt,
            EOFDate
          );
        }

        if (
          item.Q48FR_dateOfResponse_assessRd === 1 &&
          item.Q48FR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate === ""
        ) {
          Q48FRDateValidation2 = !DateValidation2(
            item.cohortIndexDate,
            item.Q48FR_dateOfResponse_assess_dt
          );
        }

        if (
          item.Q48FR_dateOfResponse_assessRd === 1 &&
          item.Q48FR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate === "" &&
          EOFDate !== ""
        ) {
          Q48FRDateValidation3 = !DateValidation2(
            item.Q48FR_dateOfResponse_assess_dt,
            EOFDate
          );
        }

        //BR
        let Q48BRDateValidation1 = false;
        let Q48BRDateValidation2 = false;
        let Q48BRDateValidation3 = false;

        if (
          item.Q48BR_dateOfResponse_assessRd === 1 &&
          item.Q48BR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate != ""
        ) {
          Q48BRDateValidation1 = !DateValidation1(
            item.cohortIndexDate,
            item.Q48BR_dateOfResponse_assess_dt,
            EOFDate
          );
        }

        if (
          item.Q48BR_dateOfResponse_assessRd === 1 &&
          item.Q48BR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate === ""
        ) {
          Q48BRDateValidation2 = !DateValidation2(
            item.cohortIndexDate,
            item.Q48BR_dateOfResponse_assess_dt
          );
        }

        if (
          item.Q48BR_dateOfResponse_assessRd === 1 &&
          item.Q48BR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate === "" &&
          EOFDate !== ""
        ) {
          Q48BRDateValidation3 = !DateValidation2(
            item.Q48BR_dateOfResponse_assess_dt,
            EOFDate
          );
        }
//--------
        let Q48BR_assessmentMethodEmpty = false;
        if (
          (item.Q48BR_tmtResponseStatus !=8 && item.cohorttreatmentid < 9 && item.Q48BR_tmtResponseStatus !=9) &&
          showQ47_48 === true && item.Q48BR_assessmentMethodRd === null) {
          Q48BR_assessmentMethodEmpty = true;
        }

        //49
        let Q49dateOfProgressionEmpty = false;
        if (showQ49_50 === true && item.cohorttreatmentid < 9 && item.Q49dateOfProgressionRd === null) {
          Q49dateOfProgressionEmpty = true;
        }

        let Q49dateOfProgression_dtEmpty = false;
        if (
          showQ49_50 === true &&
          item.Q49dateOfProgressionRd === 2 &&
          item.Q49dateOfProgression_dt === ""
        ) {
          Q49dateOfProgression_dtEmpty = true;
        }

        // if(item.cohorttreatmentid <9 && item.Q44dateOfProgressionRd === 2 && item.Q44dateOfProgression_dt !== "")
        // {
        //     item.cohortIndexDate,item.Q44dateOfProgression_dt,item.
        // }
//---------
        let Q49DateValidation1 = false;
        let Q49DateValidation2 = false;
        let Q49DateValidation3 = false;

        if (
          item.Q49dateOfProgressionRd === 2 &&
          item.Q49dateOfProgression_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate != ""
        ) {
          Q49DateValidation1 = !DateValidation1(
            item.cohortIndexDate,
            item.Q49dateOfProgression_dt,
            EOFDate
          );
        }

        if (
          item.Q49dateOfProgressionRd === 2 &&
          item.Q49dateOfProgression_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate === ""
        ) {
          Q49DateValidation2 = !DateValidation2(
            item.cohortIndexDate,
            item.Q49dateOfProgression_dt
          );
        }

        if (
          item.Q49dateOfProgressionRd === 2 &&
          item.Q49dateOfProgression_dt !== "" &&
          item.cohortIndexDate === "" &&
          EOFDate !== ""
        ) {
          Q49DateValidation3 = !DateValidation2(
            item.Q49dateOfProgression_dt,
            EOFDate
          );
        }
//------------
        let Q49ReasonOfProgressionEmpty = false;
        let Q49ReasonOfProgressionTxtEmpty = false;
        if (item.Q49dateOfProgressionRd === 2 || item.Q49dateOfProgressionRd ===99) {
          if (
            item.Q49serum === false &&
            item.Q49uninary === false &&
            item.Q49freelight === false &&
            item.Q49plasma === false &&
            item.Q49bonelesion === false &&
            item.Q49plasmacytomas === false &&
            item.Q49ReasonOfProgression_oth === false &&
            item.Q49ReasonOfProgression === null
          ) {
            Q49ReasonOfProgressionEmpty = true;
          } 
          
          else if (
            showQ49_50 === true &&
            item.Q49ReasonOfProgression_oth === true &&
            item.Q49ReasonOfProgression_txt === ""
          ) {
            Q49ReasonOfProgressionTxtEmpty = true;
          }
        }

        let Q49assessmentMethodEmpty = false;
        if (
          (item.Q49dateOfProgressionRd === 2 || item.Q49dateOfProgressionRd === 99) &&
          item.Q49assessmentMethodRd === null
        ) {
          Q49assessmentMethodEmpty = true;
        }

        //50
        let Q50FR_tmtResponseStatusEmpty = false;
        if (showQ49_50 === true && item.cohorttreatmentid < 9 && item.Q50FR_tmtResponseStatus === null) {
          Q50FR_tmtResponseStatusEmpty = true;
        }

        let Q50FR_dateOfResponse_assessEmpty = false;
        if (
          (item.Q50FR_tmtResponseStatus !=8 && item.cohorttreatmentid < 9 && item.Q50FR_tmtResponseStatus !=9) &&
          showQ49_50 === true &&
          item.Q50FR_dateOfResponse_assessRd === null
        ) {
          Q50FR_dateOfResponse_assessEmpty = true;
        }

        let Q50FR_dateOfResponse_assess_dtEmpty = false;
        if (
          (item.Q50FR_tmtResponseStatus !=8 &&  item.Q50FR_tmtResponseStatus !=9) &&
          showQ49_50 === true &&
          item.cohorttreatmentid < 9 &&
          item.Q50FR_dateOfResponse_assessRd === 1 &&
          item.Q50FR_dateOfResponse_assess_dt === ""
        ) {
          Q50FR_dateOfResponse_assess_dtEmpty = true;
        }

        let Q50FR_assessmentMethodEmpty = false;
        if (
          (item.Q50FR_tmtResponseStatus !=8 && item.Q50FR_tmtResponseStatus !=9) &&
          showQ49_50 === true && item.cohorttreatmentid < 9 && item.Q50FR_assessmentMethodRd === null) {
          Q50FR_assessmentMethodEmpty = true;
        }

        let Q50BR_tmtResponseStatusEmpty = false;
        if (showQ49_50 === true && item.cohorttreatmentid < 9 && item.Q50BR_tmtResponseStatus === null) {
          Q50BR_tmtResponseStatusEmpty = true;
        }

        let Q50BR_dateOfResponse_assessEmpty = false;
        if (
          (item.Q50BR_tmtResponseStatus !=8 && item.Q50BR_tmtResponseStatus !=9) &&
          showQ49_50 === true &&
          item.cohorttreatmentid < 9 &&
          item.Q50BR_dateOfResponse_assessRd === null
        ) {
          Q50BR_dateOfResponse_assessEmpty = true;
        }

        let Q50BR_dateOfResponse_assess_dtEmpty = false;
        if (
          (item.Q50BR_tmtResponseStatus !=8 && item.Q50BR_tmtResponseStatus !=9) &&
          showQ49_50 === true &&
          item.cohorttreatmentid < 9 &&
          item.Q50BR_dateOfResponse_assessRd === 1 &&
          item.Q50BR_dateOfResponse_assess_dt === ""
        ) {
          Q50BR_dateOfResponse_assess_dtEmpty = true;
        }

        let Q50BR_assessmentMethodEmpty = false;
        if (
          (item.Q50BR_tmtResponseStatus !=8 && item.Q50BR_tmtResponseStatus !=9) &&
          showQ49_50 === true && item.cohorttreatmentid < 9 && item.Q50BR_assessmentMethodRd === null) {
          Q50BR_assessmentMethodEmpty = true;
        }
//------
        let Q50FRDateValidation1 = false;
        let Q50FRDateValidation2 = false;
        let Q50FRDateValidation3 = false;

        if (
          item.Q50FR_dateOfResponse_assessRd === 1 &&
          item.Q50FR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate != ""
        ) {
          Q50FRDateValidation1 = !DateValidation1(
            item.cohortIndexDate,
            item.Q50FR_dateOfResponse_assess_dt,
            EOFDate
          );
        }

        if (
          item.Q50FR_dateOfResponse_assessRd === 1 &&
          item.Q50FR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate === ""
        ) {
          Q50FRDateValidation2 = !DateValidation2(
            item.cohortIndexDate,
            item.Q50FR_dateOfResponse_assess_dt
          );
        }

        if (
          item.Q50FR_dateOfResponse_assessRd === 1 &&
          item.Q50FR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate === "" &&
          EOFDate !== ""
        ) {
          Q50FRDateValidation3 = !DateValidation2(
            item.Q50FR_dateOfResponse_assess_dt,
            EOFDate
          );
        }

        //BR
        let Q50BRDateValidation1 = false;
        let Q50BRDateValidation2 = false;
        let Q50BRDateValidation3 = false;

        if (
          item.Q50BR_dateOfResponse_assessRd === 1 &&
          item.Q50BR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate != ""
        ) {
          Q50BRDateValidation1 = !DateValidation1(
            item.cohortIndexDate,
            item.Q50BR_dateOfResponse_assess_dt,
            EOFDate
          );
        }

        if (
          item.Q50BR_dateOfResponse_assessRd === 1 &&
          item.Q50BR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate === ""
        ) {
          Q50BRDateValidation2 = !DateValidation2(
            item.cohortIndexDate,
            item.Q50BR_dateOfResponse_assess_dt
          );
        }

        if (
          item.Q50BR_dateOfResponse_assessRd === 1 &&
          item.Q50BR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate === "" &&
          EOFDate !== ""
        ) {
          Q50BRDateValidation3 = !DateValidation2(
            item.Q50BR_dateOfResponse_assess_dt,
            EOFDate
          );
        }
//---
        //51           ---------------------------------
        let Q51dateOfProgressionEmpty = false;
         if ( item.cohorttreatmentid >= 9 &&  item.showQ51_52 === true &&  item.Q51dateOfProgressionRd === null) {
        Q51dateOfProgressionEmpty = true;
        }

        let Q51dateOfProgression_dtEmpty = false;
        if (
           item.cohorttreatmentid >= 9 &&
           item.showQ51_52 === true &&
          item.Q51dateOfProgressionRd === 2 &&
          item.Q51dateOfProgression_dt === ""
        ) {
          Q51dateOfProgression_dtEmpty = true;
        }

        // if(item.cohorttreatmentid <9 && item.Q44dateOfProgressionRd === 2 && item.Q44dateOfProgression_dt !== "")
        // {
        //     item.cohortIndexDate,item.Q44dateOfProgression_dt,item.
        // }
//----
        let Q51DateValidation1 = false;
        let Q51DateValidation2 = false;
        let Q51DateValidation3 = false;

        if (
          item.Q51dateOfProgressionRd === 2 &&
          item.Q51dateOfProgression_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate != ""
        ) {
          Q51DateValidation1 = !DateValidation1(
            item.cohortIndexDate,
            item.Q51dateOfProgression_dt,
            EOFDate
          );
        }

        if (
          item.Q51dateOfProgressionRd === 2 &&
          item.Q51dateOfProgression_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate === ""
        ) {
          Q51DateValidation2 = !DateValidation2(
            item.cohortIndexDate,
            item.Q51dateOfProgression_dt
          );
        }

        if (
          item.Q51dateOfProgressionRd === 2 &&
          item.Q51dateOfProgression_dt !== "" &&
          item.cohortIndexDate === "" &&
          EOFDate !== ""
        ) {
          Q51DateValidation3 = !DateValidation2(
            item.Q51dateOfProgression_dt,
            EOFDate
          );
        }
//-----
        let Q51ReasonOfProgressionEmpty = false;
        let Q51ReasonOfProgressionTxtEmpty = false;
        if (
          item.cohorttreatmentid >= 9 && item.showQ51_52 === true &&  item.Q51ConditionMet && 
          item.Q51dateOfProgressionRd === 2 || item.Q51dateOfProgressionRd === 99) {
          if (
            item.Q51serum === false &&
            item.Q51uninary === false &&
            item.Q51freelight === false &&
            item.Q51plasma === false &&
            item.Q51bonelesion === false &&
            item.Q51plasmacytomas === false &&
            item.Q51ReasonOfProgression_oth === false &&
            item.Q51ReasonOfProgression === null
          ) {
            Q51ReasonOfProgressionEmpty = true;
          } 
          
          if (
             item.cohorttreatmentid >= 9 &&
             item.showQ51_52 === true && item.Q51ConditionMet &&
            item.Q51ReasonOfProgression_oth === true &&
            item.Q51ReasonOfProgression_txt === ""
          ) {
            Q51ReasonOfProgressionTxtEmpty = true;
          }
        }

        let Q51assessmentMethodEmpty = false;
        if ( 
           item.cohorttreatmentid >= 9 &&
           item.showQ51_52 === true && item.Q51ConditionMet &&
          (item.Q51dateOfProgressionRd === 2 || item.Q51dateOfProgressionRd === 99) &&
          item.Q51assessmentMethodRd === null
        ) {
          Q51assessmentMethodEmpty = true;
        }

        //52
        let Q52FR_tmtResponseStatusEmpty = false;
        if (item.cohorttreatmentid >= 9 && item.showQ51_52 === true && item.Q52FR_tmtResponseStatus === null) {
          Q52FR_tmtResponseStatusEmpty = true;
        }

        let Q52FR_dateOfResponse_assessEmpty = false;
        if (
          (item.Q52FR_tmtResponseStatus !=8 && item.Q52FR_tmtResponseStatus !=9) &&
          item.cohorttreatmentid >= 9 &&
          item.showQ51_52 === true &&
          item.Q52FR_dateOfResponse_assessRd === null
        ) {
          Q52FR_dateOfResponse_assessEmpty = true;
        }

        let Q52FR_dateOfResponse_assess_dtEmpty = false;
        if (
          (item.Q52FR_tmtResponseStatus !=8 && item.Q52FR_tmtResponseStatus !=9) &&
          item.cohorttreatmentid >= 9 &&
          item.showQ51_52 === true &&
          item.Q52FR_dateOfResponse_assessRd === 1 &&
          item.Q52FR_dateOfResponse_assess_dt === ""
        ) {
          Q52FR_dateOfResponse_assess_dtEmpty = true;
        }

        let Q52FR_assessmentMethodEmpty = false;
        if (
          (item.Q52FR_tmtResponseStatus !=8 && item.Q52FR_tmtResponseStatus !=9) &&
          item.cohorttreatmentid >= 9 && 
          item.showQ51_52 === true && item.Q52FR_assessmentMethodRd === null) {
          Q52FR_assessmentMethodEmpty = true;
        }

        let Q52BR_tmtResponseStatusEmpty = false;
        if (
          item.cohorttreatmentid >= 9 &&
          item.showQ51_52 === true && item.Q52BR_tmtResponseStatus === null) {
          Q52BR_tmtResponseStatusEmpty = true;
        }

        let Q52BR_dateOfResponse_assessEmpty = false;
        if (
          (item.Q52BR_tmtResponseStatus !=8 && item.Q52BR_tmtResponseStatus !=9) &&
          item.cohorttreatmentid >= 9 &&
          item.showQ51_52 === true &&
          item.Q52BR_dateOfResponse_assessRd === null
        ) {
          Q52BR_dateOfResponse_assessEmpty = true;
        }

        let Q52BR_dateOfResponse_assess_dtEmpty = false;
        if (
          (item.Q52BR_tmtResponseStatus !=8 && item.Q52BR_tmtResponseStatus !=9) &&
          item.cohorttreatmentid >= 9 &&
          item.showQ51_52 === true &&
          item.Q52BR_dateOfResponse_assessRd === 1 &&
          item.Q52BR_dateOfResponse_assess_dt === ""
        ) {
          Q52BR_dateOfResponse_assess_dtEmpty = true;
        }

        let Q52BR_assessmentMethodEmpty = false;
        if (
          (item.Q52BR_tmtResponseStatus !=8 && item.Q52BR_tmtResponseStatus !=9) &&
          item.cohorttreatmentid >= 9 &&
          item.showQ51_52 === true && item.Q52BR_assessmentMethodRd === null) {
          Q52BR_assessmentMethodEmpty = true;
        }
// -------
        let Q52FRDateValidation1 = false;
        let Q52FRDateValidation2 = false;
        let Q52FRDateValidation3 = false;

        if (
          item.Q52FR_dateOfResponse_assessRd === 1 &&
          item.Q52FR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate != ""
        ) {
          Q52FRDateValidation1 = !DateValidation1(
            item.cohortIndexDate,
            item.Q52FR_dateOfResponse_assess_dt,
            EOFDate
          );
        }

        if (
          item.Q52FR_dateOfResponse_assessRd === 1 &&
          item.Q52FR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate === ""
        ) {
          Q52FRDateValidation2 = !DateValidation2(
            item.cohortIndexDate,
            item.Q52FR_dateOfResponse_assess_dt
          );
        }

        if (
          item.Q52FR_dateOfResponse_assessRd === 1 &&
          item.Q52FR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate === "" &&
          EOFDate !== ""
        ) {
          Q52FRDateValidation3 = !DateValidation2(
            item.Q52FR_dateOfResponse_assess_dt,
            EOFDate
          );
        }

        //BR
        let Q52BRDateValidation1 = false;
        let Q52BRDateValidation2 = false;
        let Q52BRDateValidation3 = false;

        if (
          item.Q52BR_dateOfResponse_assessRd === 1 &&
          item.Q52BR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate != ""
        ) {
          Q52BRDateValidation1 = !DateValidation1(
            item.cohortIndexDate,
            item.Q52BR_dateOfResponse_assess_dt,
            EOFDate
          );
        }

        if (
          item.Q52BR_dateOfResponse_assessRd === 1 &&
          item.Q52BR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate != "" &&
          EOFDate === ""
        ) {
          Q52BRDateValidation2 = !DateValidation2(
            item.cohortIndexDate,
            item.Q52BR_dateOfResponse_assess_dt
          );
        }

        if (
          item.Q52BR_dateOfResponse_assessRd === 1 &&
          item.Q52BR_dateOfResponse_assess_dt !== "" &&
          item.cohortIndexDate === "" &&
          EOFDate !== ""
        ) {
          Q52BRDateValidation3 = !DateValidation2(
            item.Q52BR_dateOfResponse_assess_dt,
            EOFDate
          );
        }
//------
        if (
          Q44dateOfProgressionEmpty ||
          Q44dateOfProgression_dtEmpty ||
          Q44ReasonOfProgressionEmpty ||
          Q44ReasonOfProgressionTxtEmpty ||
          Q44assessmentMethodEmpty ||
          Q45FR_tmtResponseStatusEmpty ||
          Q45FR_dateOfResponse_assessEmpty ||
          Q45FR_dateOfResponse_assess_dtEmpty ||
          Q45FR_assessmentMethodEmpty ||
          Q45BR_tmtResponseStatusEmpty ||
          Q45BR_dateOfResponse_assessEmpty ||
          Q45BR_dateOfResponse_assess_dtEmpty ||
          Q45BR_assessmentMethodEmpty ||
          Q47dateOfProgressionEmpty ||
          Q47dateOfProgression_dtEmpty ||
          Q47ReasonOfProgressionEmpty ||
          Q47ReasonOfProgressionTxtEmpty ||
          Q48FR_tmtResponseStatusEmpty ||
          Q48FR_dateOfResponse_assessEmpty ||
          Q48FR_dateOfResponse_assess_dtEmpty ||
          Q48FR_assessmentMethodEmpty ||
          Q48BR_tmtResponseStatusEmpty ||
          Q48BR_dateOfResponse_assessEmpty ||
          Q48BR_dateOfResponse_assess_dtEmpty ||
          Q48BR_assessmentMethodEmpty ||
          Q49dateOfProgressionEmpty ||
          Q49dateOfProgression_dtEmpty ||
          Q49ReasonOfProgressionEmpty ||
          Q49ReasonOfProgressionTxtEmpty ||
          Q50FR_tmtResponseStatusEmpty ||
          Q50FR_dateOfResponse_assessEmpty ||
          Q50FR_dateOfResponse_assess_dtEmpty ||
          Q50FR_assessmentMethodEmpty ||
          Q50BR_tmtResponseStatusEmpty ||
          Q50BR_dateOfResponse_assessEmpty ||
          Q50BR_dateOfResponse_assess_dtEmpty ||
          Q50BR_assessmentMethodEmpty ||
          Q51dateOfProgressionEmpty ||
          Q51dateOfProgression_dtEmpty ||
          Q51ReasonOfProgressionEmpty ||
          Q51ReasonOfProgressionTxtEmpty ||
          Q52FR_tmtResponseStatusEmpty ||
          Q52FR_dateOfResponse_assessEmpty ||
          Q52FR_dateOfResponse_assess_dtEmpty ||
          Q52FR_assessmentMethodEmpty ||
          Q52BR_tmtResponseStatusEmpty ||
          Q52BR_dateOfResponse_assessEmpty ||
          Q52BR_dateOfResponse_assess_dtEmpty ||
          Q52BR_assessmentMethodEmpty ||
          Q44DateValidation1 ||
          Q44DateValidation2 ||
          Q44DateValidation3 ||
          Q45FRDateValidation1 ||
          Q45FRDateValidation2 ||
          Q45FRDateValidation3 ||
          Q45BRDateValidation1 ||
          Q45BRDateValidation2 ||
          Q45BRDateValidation3 ||

              Q47DateValidation1 ||
              Q47DateValidation2 ||
              Q47DateValidation3 ||

              Q48FRDateValidation1 ||
              Q48FRDateValidation2 ||
             Q48FRDateValidation3  ||
             Q48BRDateValidation1 ||
             Q48BRDateValidation2 ||
             Q48BRDateValidation3  ||

             Q49DateValidation1 ||
             Q49DateValidation2 ||
             Q49DateValidation3  ||

             Q50FRDateValidation1 ||
             Q50FRDateValidation2 ||
            Q50FRDateValidation3  ||
            Q50BRDateValidation1 ||
            Q50BRDateValidation2 ||
            Q50BRDateValidation3 ||

            Q51DateValidation1 ||
            Q51DateValidation2 ||
            Q51DateValidation3 ||

            Q52FRDateValidation1 ||
            Q52FRDateValidation2 ||
           Q52FRDateValidation3  ||
           Q52BRDateValidation1 ||
           Q52BRDateValidation2 ||
           Q52BRDateValidation3
        ) {
          isValid = false;
        }
        
        let innerArr = [];
        if (tmtResponseProgression && tmtResponseProgression.length > 0) {
          // const updatedArr = tmtResponseProgression.map((p) => {
          if (item.q46Row && item.q46Row.length > 0) {
            innerArr = item.q46Row.map((c) => {
              const Q46dateofassessmentEmpty = c.Q46dateofassessmentRd == null;
              const Q46dateofassessment_dtEmpty =
                c.Q46dateofassessmentRd === 2 &&
                isStringEmpty(c.Q46dateofassessment_dt);
              
              const Q46assessmentmethodEmpty = (c.Q46dateofassessmentRd ===2 || c.Q46dateofassessmentRd === 99) && c.Q46assessmentmethodRd === null;
              const Q46sensitivityEmpty = (c.Q46dateofassessmentRd ===2 || c.Q46dateofassessmentRd === 99) && c.Q46sensitivityRd === null;
              const Q46mrdstatusEmpty = (c.Q46dateofassessmentRd ===2 || c.Q46dateofassessmentRd === 99) && c.Q46mrdstatusRd === null;



               const Q46DateValidation1 =  c.Q46dateofassessmentRd === 2 && c.Q46dateofassessment_dt !="" && item.cohortIndexDate!=""
                                          && EOFDate !="" && !DateValidation1(item.cohortIndexDate,c.Q46dateofassessment_dt,EOFDate);


            const Q46DateValidation2 =  c.Q46dateofassessmentRd === 2 && c.Q46dateofassessment_dt !="" && item.cohortIndexDate!="" && EOFDate === ""
            && !DateValidation2(item.cohortIndexDate,c.Q46dateofassessment_dt);


            
            const Q46DateValidation3 =  c.Q46dateofassessmentRd === 2 && c.Q46dateofassessment_dt !="" && item.cohortIndexDate==="" && EOFDate != ""
            && !DateValidation2(c.Q46dateofassessment_dt,EOFDate);


              if (
                Q46dateofassessmentEmpty ||
                Q46dateofassessment_dtEmpty ||
                Q46assessmentmethodEmpty ||
                Q46sensitivityEmpty ||
                Q46mrdstatusEmpty ||
                Q46DateValidation1 ||
                Q46DateValidation2 ||
                Q46DateValidation3
              ) {
                isValid = false;
              }

              return {
                ...c,
                Q46dateofassessmentEmpty,
                Q46dateofassessment_dtEmpty,
                Q46assessmentmethodEmpty,
                Q46sensitivityEmpty,
                Q46mrdstatusEmpty,
                Q46DateValidation1,
                Q46DateValidation2,
                Q46DateValidation3,

                Q46DateValidation1,
                Q46DateValidation2,
                Q46DateValidation3
              };
            });

            // return {
            //   ...item,
            //   q46Row: innerArr,
            // };
          }
          // });
          //setTmtResponseProgression(updatedArr);
        }
        
        return {
          ...item,

          Q44dateOfProgressionEmpty,
          Q44dateOfProgression_dtEmpty,
          Q44ReasonOfProgressionEmpty,
          Q44ReasonOfProgressionTxtEmpty,
          Q44assessmentMethodEmpty,
          Q45FR_tmtResponseStatusEmpty,
          Q45FR_dateOfResponse_assessEmpty,
          Q45FR_dateOfResponse_assess_dtEmpty,
          Q45FR_assessmentMethodEmpty,

          Q45BR_tmtResponseStatusEmpty,
          Q45BR_dateOfResponse_assessEmpty,
          Q45BR_dateOfResponse_assess_dtEmpty,
          Q45BR_assessmentMethodEmpty,

          Q47dateOfProgressionEmpty,
          Q47dateOfProgression_dtEmpty,
          Q47ReasonOfProgressionEmpty,
          Q47ReasonOfProgressionTxtEmpty,
          Q47assessmentMethodEmpty,

          Q48FR_tmtResponseStatusEmpty,
          Q48FR_dateOfResponse_assessEmpty,
          Q48FR_dateOfResponse_assess_dtEmpty,
          Q48FR_assessmentMethodEmpty,

          Q48BR_tmtResponseStatusEmpty,
          Q48BR_dateOfResponse_assessEmpty,
          Q48BR_dateOfResponse_assess_dtEmpty,
          Q48BR_assessmentMethodEmpty,

          Q49dateOfProgressionEmpty,
          Q49dateOfProgression_dtEmpty,
          Q49ReasonOfProgressionEmpty,
          Q49ReasonOfProgressionTxtEmpty,
          Q49assessmentMethodEmpty,

          Q50FR_tmtResponseStatusEmpty,
          Q50FR_dateOfResponse_assessEmpty,
          Q50FR_dateOfResponse_assess_dtEmpty,
          Q50FR_assessmentMethodEmpty,

          Q50BR_tmtResponseStatusEmpty,
          Q50BR_dateOfResponse_assessEmpty,
          Q50BR_dateOfResponse_assess_dtEmpty,
          Q50BR_assessmentMethodEmpty,

          Q51dateOfProgressionEmpty,
          Q51dateOfProgression_dtEmpty,
          Q51ReasonOfProgressionEmpty,
          Q51ReasonOfProgressionTxtEmpty,
          Q51assessmentMethodEmpty,

          Q52FR_tmtResponseStatusEmpty,
          Q52FR_dateOfResponse_assessEmpty,
          Q52FR_dateOfResponse_assess_dtEmpty,
          Q52FR_assessmentMethodEmpty,

          Q52BR_tmtResponseStatusEmpty,
          Q52BR_dateOfResponse_assessEmpty,
          Q52BR_dateOfResponse_assess_dtEmpty,
          Q52BR_assessmentMethodEmpty,

          q46Row: innerArr,

          Q44DateValidation1,
          Q44DateValidation2,
          Q44DateValidation3,

          Q45FRDateValidation1,
          Q45FRDateValidation2,
          Q45FRDateValidation3,

          Q45BRDateValidation1,
          Q45BRDateValidation2,
          Q45BRDateValidation3,

                Q47DateValidation1,
                Q47DateValidation2,
                Q47DateValidation3,

                Q48FRDateValidation1,
                Q48FRDateValidation2,
               Q48FRDateValidation3,

               Q48BRDateValidation1,
               Q48BRDateValidation2,
              Q48BRDateValidation3,

              Q49DateValidation1,
              Q49DateValidation2,
              Q49DateValidation3,

              Q50FRDateValidation1,
              Q50FRDateValidation2,
             Q50FRDateValidation3,

             Q50BRDateValidation1,
             Q50BRDateValidation2,
            Q50BRDateValidation3,

            Q51DateValidation1,
            Q51DateValidation2,
            Q51DateValidation3,

            Q52FRDateValidation1,
            Q52FRDateValidation2,
           Q52FRDateValidation3,

           Q52BRDateValidation1,
           Q52BRDateValidation2,
          Q52BRDateValidation3,
        };
      });

      setTmtResponseProgression(updatedArray);
    }

    //46
    

    return isValid;
  };

  useEffect(() => {
    async function FetchData() {
      SetNavPaths();
      if (!isNaN(pid) && Number(pid) !== 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);

  const DeleteQ46RowsBelow = (parentIndex, childIndex) => {
    // Update the state by mapping through each entry
    setTmtResponseProgression((prevState) =>
      prevState.map((entry, index) => {
        if (index === parentIndex) {
          // Copy the current q46Row array for the specific entry
          const newArray = [...entry.q46Row];
  
          // Keep only rows up to the specified childIndex
          const updatedArray = newArray.slice(0, childIndex + 1);
  
          // Return the updated entry with the modified q46Row
          return {
            ...entry,
            q46Row: updatedArray,
          };
        }
        // Return the unchanged entry if it doesn't match the entryIndex
        return entry;
      })
    );
  };
  

  // Function to delete a row based on its index and the specific entry in the array
  const DeleteQ46Row = (entryIndex, rowIndex) => {
    // Update the state by mapping through each entry
    setTmtResponseProgression((prevState) =>
      prevState.map((entry, index) => {
        if (index === entryIndex) {
          // Copy the current q46Row array for the specific entry
          const newArray = [...entry.q46Row];

          // Remove the element at the specified row index
          newArray.splice(rowIndex, 1);

          // Return the updated entry with the modified q46Row
          return {
            ...entry,
            q46Row: newArray,
          };
        }
        // Return the unchanged entry if it doesn't match the entryIndex
        return entry;
      })
    );
  };
  // Function to add a new row for a specific entry based on its index
  const AddQ46Row = (entryIndex) => {
    // Create a new row with the default structure
    const newRow = {
      Q46dateofassessmentRd: null,
      Q46dateofassessment_dt: "",
      Q46assessmentmethodRd: null,
      Q46sensitivityRd: null,
      Q46mrdstatusRd: null,
      Q46dateofassessmentEmpty: false,
      Q46dateofassessment_dtEmpty: false,
      Q46assessmentmethodEmpty: false,
      Q46sensitivityEmpty: false,
      Q46mrdstatusEmpty: false,

      Q46DateValidation1:false,
      Q46DateValidation2:false,
      Q46DateValidation3:false,
    };

    // Update the state with the new row for the specific entry
    setTmtResponseProgression((prevState) => {
      // Clone the current state to avoid mutation
      const updatedState = prevState.map((entry, index) => {
        if (index === entryIndex) {
          // Add the new row only to the specified entry
          return {
            ...entry,
            q46Row: [...entry.q46Row, newRow], // Append the new row
          };
        }
        return entry; // Return unchanged entries
      });

      return updatedState;
    });
  };

  // // Function to add a new row with the initial structure
  // const AddQ46Row = () => {
  //   // Create a new row with the default structure
  //   const newRow = {
  //     Q46dateofassessmentRd: null,
  //     Q46dateofassessment_dt: "",
  //     Q46assessmentmethodRd: null,
  //     Q46sensitivityRd: null,
  //     Q46mrdstatusRd: null,
  //     Q46dateofassessmentEmpty: false,
  //     Q46dateofassessment_dtEmpty: false,
  //     Q46assessmentmethodEmpty: false,
  //     Q46sensitivity: false,
  //     Q46mrdstatus: false,
  //   };

  //   setTmtResponseProgression((prevState) => {

  //     // Clone the current state to avoid mutation
  //     const updatedState = prevState.map((entry) => {
  //       // Update only the specific entry with q46Row, e.g., based on a condition
  //       // Assuming we want to update an entry with some specific logic; here we update all entries.
  //       return {
  //         ...entry,
  //         q46Row: [...entry.q46Row, newRow], // Append the new row
  //       };
  //     });

  //     return updatedState;
  //   });
  // };
  const sidebar = document.getElementById("mySidebar");
  const main = document.getElementById("main");

  const toggleNav = () => {
  

  
    if (isSideMenuActive === true  ) { 
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
      setIsSideMenuActive(false)
    } else if( isSideMenuActive===false ){
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
      setIsSideMenuActive(true)
    }
}

useEffect(() => {
const   sidebar = document.getElementById("mySidebar");
const  main = document.getElementById("main");


  if (isSideMenuActive === true) {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
    } else {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
    }
});
  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>

      <section className="main-survey-section">
        <div>
          <SideBarMenu
            activePageId={SideMenuItems.TreatmentResponseProgression}
          />

<>
<button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
         </>

          <div className="col-pad-left" id="main">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4> Treatment Response and Progression </h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div className="question-answer">
                    {/* <div className="vertical-pad-15">
        
            </div> */}
                    {tmtResponseProgression &&
                      tmtResponseProgression.length > 0 &&
                      tmtResponseProgression.map((data, idx) => (
                        <div className="dependent-section" key={idx}>
                          <div className='cohort-header'>
                            <span>
                                                  
                              {data.cohortTreatmentName}
                              {data.cohortTreatmentNameSuperscript == null ? (
                                ''
                              ) : (
                                <>
                                <sup className="supscript">{data.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                {data.cohortTreatmentNameSuperscript.slice(2)}
                                </>
                                )}
                            </span>

                          </div>  
                          <>
                            <div className="question-bot-sapce">
                              <div className=" question-weight">
                                <span>Q44. </span>
                                Provide information for {" "}
                                <span
                                  className="modal-popup"
                                  data-tooltip-id="encounterTip"
                                  onClick={() =>
                                    setShowHideHyperlinkModalIMWG(true)
                                  }
                                >
                                  disease progression
                                </span>{" "}
                                conducted during the patient’s treatment with{" "}
                                {""}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {/* {test.cohortTreatmentName} */}

                                  {/* {data.cohortTreatmentName === "TALVEY" ||
                                  data.cohortTreatmentName === "ELREXFIO" ? (
                                    <>
                                      {data.cohortTreatmentName}
                                      <sup className="supscript">TM</sup>
                                    </>
                                  ) : (
                                    data.cohortTreatmentName
                                  )} */}
                                  {data.cohortTreatmentName}
                                  {data.cohortTreatmentNameSuperscript == null ? (
                                    ''
                                  ) : (
                                    <>
                                    <sup className="supscript">{data.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                    {data.cohortTreatmentNameSuperscript.slice(2)}
                                    </>
                                  )}
                                </span>{" "}
                                (i.e., from{" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {" "}
                                  {data.cohortIndexDate}{" "}
                                </span>{" "}
                                to{" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {data.cohorttreatmentid < 9
                                    ? ndmmEndofFollowupDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.TECVAYLI
                                    ? tecvayliEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.TALVEY
                                    ? talveyEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.ELREXFIO
                                    ? elrexfioEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.Linvoseltamab
                                    ? linvoseltamabEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.CARVYKTI
                                    ? carvyktiEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.ABECMA
                                    ? abecmaEndofFollowUpDate
                                    : "[NDMM end of follow up or Bispecifics/CAR-T end of follow up]"}
                                </span>
                                ).
                              </div>

                              <div className="sub-question">
                                <table
                                  style={{
                                    border: "1px solid black",
                                    borderCollapse: "collapse",
                                    width: "100%",
                                  }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                          textAlign: "center",
                                          // width:"4%"
                                        }}
                                      >
                                        Assessment
                                      </th>
                                      <th
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                          textAlign: "center",
                                          //   width: "29%"
                                        }}
                                      >
                                        <span style={{ textAlign: "center" }}>
                                          Date of progression assessment
                                        </span>
                                      </th>

                                      {data.Q44ConditionMet && (
                                        <>
                                          <th
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                              textAlign: "center",
                                              // width:"36%"
                                            }}
                                          >
                                            Reason for progression
                                          </th>
                                          <th
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                              textAlign: "center",
                                              //  width:"28%"
                                            }}
                                          >
                                            Method of assessment
                                          </th>
                                        </>
                                      )}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <span className="quest-text-pad">
                                          Disease progression
                                        </span>
                                      </td>

                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <div>
                                          <div
                                            className="option-without-input"
                                            
                                          >
                                            <label className="option-fbox">
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q44dateOfProgressionRd" + idx
                                                }
                                                value={1}
                                                checked={
                                                  data.Q44dateOfProgressionRd ===
                                                  1
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />

                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Disease progression did not
                                                occur
                                              </span>
                                            </label>
                                          </div>

                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            {" "}
                                            <label className="option-fbox">
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q44dateOfProgressionRd" + idx
                                                }
                                                value={2}
                                                checked={
                                                  data.Q44dateOfProgressionRd ===
                                                  2
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />

                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Disease progression occurred on:
                                              </span>
                                            </label>
                                          </div>

                                          {/* <div
                                            className="option-without-input"
                                            style={{ display: "flex" }}
                                            >
                                                */}

                                          <span
                                            className="radio-text-padding"
                                            style={{
                                              paddingLeft: "41px",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            <DateControl
                                              ctrlId={
                                                "Q44dateOfProgression_dt-" + idx
                                              }
                                              HandleDateChange={
                                                HandleRepeaterDateChange
                                              }
                                              date={
                                                data.Q44dateOfProgression_dt
                                              }
                                            />
                                          </span>
                                          {/* </div> */}
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <label className="option-fbox">
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q44dateOfProgressionRd" + idx
                                                }
                                                value={99}
                                                checked={
                                                  data.Q44dateOfProgressionRd ===
                                                  99
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />

                                              <span
                                                //className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Disease progression occurred but{" "}
                                                {""}
                                                {/* <span className="radio-text-padding"> */}
                                                date of assessment unknown
                                                {/* </span> */}
                                              </span>
                                            </label>
                                          </div>

                                          <ErrorField
                                            show={
                                              data.Q44dateOfProgressionEmpty
                                            }
                                            // message={"Answer"}
                                            message={TreatmentResponseProgression_Msgs.Q44Date}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                          <ErrorField
                                            show={
                                              data.Q44dateOfProgression_dtEmpty
                                            }
                                            message={TreatmentResponseProgression_Msgs.Q44DateEmpty}
                                            style={{ paddingLeft: "0px" }}
                                          />

                                          <ErrorField
                                            show={data.Q44DateValidation1}
                                            // message={"!<=2<=3"}
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />

                                          <ErrorField
                                            show={data.Q44DateValidation2}
                                            // message={"1(index)<=2(date user)"}
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />

                                          <ErrorField
                                            show={data.Q44DateValidation3}
                                            // message={"2(date)<=3(EOFD)"}
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                        </div>
                                      </td>

                                      {data.Q44ConditionMet && (
                                        <>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            <div>
                                              <div className="">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label>
                                                    <input
                                                      type="checkbox"
                                                      style={{
                                                        marginTop: "6px",
                                                      }}
                                                      id={idx}
                                                      name={"Q44serum" + idx}
                                                      checked={data.Q44serum}
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    <span className="radio-text-padding">
                                                      Serum M protein
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label>
                                                    <input
                                                      style={{
                                                        marginTop: "6px",
                                                      }}
                                                      type="checkbox"
                                                      id={idx}
                                                      name={"Q44uninary" + idx}
                                                      checked={data.Q44uninary}
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    <span className="radio-text-padding">
                                                      Urinary M protein
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label>
                                                    <input
                                                      style={{
                                                        marginTop: "6px",
                                                      }}
                                                      type="checkbox"
                                                      id={idx}
                                                      name={
                                                        "Q44freelight" + idx
                                                      }
                                                      checked={
                                                        data.Q44freelight
                                                      }
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    <span className="radio-text-padding">
                                                      Free light chain
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label>
                                                    <input
                                                      style={{
                                                        marginTop: "6px",
                                                      }}
                                                      type="checkbox"
                                                      id={idx}
                                                      name={"Q44plasma" + idx}
                                                      checked={data.Q44plasma}
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    <span className="radio-text-padding">
                                                      Plasma cell increase
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                    <input
                                                      style={{
                                                        marginTop: "6px",
                                                      }}
                                                      type="checkbox"
                                                      id={idx}
                                                      name={
                                                        "Q44bonelesion" + idx
                                                      }
                                                      checked={
                                                        data.Q44bonelesion
                                                      }
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    <span
                                                      // className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "15px",
                                                      }}
                                                    >
                                                      Increase in size and/or
                                                      new bone lesion
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                    <input
                                                      style={{
                                                        marginTop: "6px",
                                                      }}
                                                      type="checkbox"
                                                      id={idx}
                                                      name={
                                                        "Q44plasmacytomas" + idx
                                                      }
                                                      checked={
                                                        data.Q44plasmacytomas
                                                      }
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    <span
                                                      // className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "15px",
                                                      }}
                                                    >
                                                      Increase in size and/or
                                                      new plasmacytomas
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="">
                                                <div
                                                //   style={{display:"flex", alignItems:"flex-start"}}
                                                >
                                                  <label>
                                                    <input
                                                      style={{
                                                        marginTop: "6px",
                                                      }}
                                                      type="checkbox"
                                                      id={idx}
                                                      name={
                                                        "Q44ReasonOfProgression_oth" +
                                                        idx
                                                      }
                                                      checked={
                                                        data.Q44ReasonOfProgression_oth
                                                      }
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    <span className="radio-text-padding">
                                                      Other, please specify:
                                                    </span>
                                                  </label>

                                                  <div style={{paddingLeft:"28px"}}>    
                                                  <textarea
                                                    
                                                    name={
                                                      "Q44ReasonOfProgression_txt" +
                                                      idx
                                                    }
                                                    className="input-dash"
                                                    type="text"
                                                    value={
                                                      data.Q44ReasonOfProgression_txt
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                    maxLength={300}
                                                    id={idx}
                                                  />
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label>
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q44ReasonOfProgression" +
                                                      idx
                                                    }
                                                    value={99}
                                                    checked={
                                                      data.Q44ReasonOfProgression ===
                                                      99
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />

                                                  <span
                                                    // className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Unknown
                                                  </span>
                                                </label>
                                              </div>
                                              <ErrorField
                                                show={
                                                  data.Q44ReasonOfProgressionEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q44Reason}
                                                style={{ paddingLeft: "0px" }}
                                              />

                                              <ErrorField
                                                show={
                                                  data.Q44ReasonOfProgressionTxtEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q44Reason}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                              {/*
                                                <ErrorField
                                                show={
                                                    data.dt_admission_techHard_dt_check
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.admissionDateCheck
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            <div>
                                              <div
                                              // className="option-without-input"
                                              // style={{ display: "flex" }}
                                              >
                                                {assessmentMethods &&
                                                  assessmentMethods.length >
                                                    0 &&
                                                  assessmentMethods.map(
                                                    (item) => (
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                          <input
                                                            type="radio"
                                                            style={{
                                                              marginTop: "6px",
                                                            }}
                                                            id={idx}
                                                            name={
                                                              "Q44assessmentMethodRd" +
                                                              idx
                                                            }
                                                            value={
                                                              item.assessmentmethodid
                                                            }
                                                            checked={
                                                              data.Q44assessmentMethodRd ===
                                                              item.assessmentmethodid
                                                            }
                                                            onChange={
                                                              HandleRepeaterFieldChange
                                                            }
                                                          />

                                                          {item.assessmentmethodid ===
                                                          1 ? (
                                                            <span
                                                              className="radio-text-padding modal-popup"
                                                              data-tooltip-id="encounterTip"
                                                              onClick={() =>
                                                                setShowHideHyperlinkModalIMWG(
                                                                  true
                                                                )
                                                              }
                                                            >
                                                              {item.name}
                                                            </span>
                                                          ) : (
                                                            <span
                                                              className="radio-text-padding"
                                                              style={{
                                                                paddingLeft:
                                                                  "10px",
                                                              }}
                                                            >
                                                              {item.name}
                                                            </span>
                                                          )}
                                                        </label>
                                                      </div>
                                                    )
                                                  )}

                                                {/* <input
                                              style={{ marginTop: "-18px" }}
                                              type="radio"
                                              id={idx}
                                              name={
                                                "Q44assessmentMethodRd" + idx
                                              }
                                              value={1}
                                              checked={
                                                data.Q44assessmentMethodRd === 1
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                                marginLeft: "-5px",
                                              }}
                                            >
                                              {" "}
                                              Standard IMWG Response Criteria
                                            </span> */}
                                              </div>

                                              {/* <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q44assessmentMethodRd" + idx
                                              }
                                              value={2}
                                              checked={
                                                data.Q44assessmentMethodRd === 2
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />

                                            <label>
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Other standard response criteria
                                              </span>
                                            </label>
                                          </div> */}

                                              {/* <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q44assessmentMethodRd" + idx
                                              }
                                              value={3}
                                              checked={
                                                data.Q44assessmentMethodRd === 3
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />

                                            <label>
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                {" "}
                                                No standard criteria mentioned
                                                in chart
                                              </span>
                                            </label>
                                          </div> */}
                                              <ErrorField
                                                show={
                                                  data.Q44assessmentMethodEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q44Method}

                                                style={{ paddingLeft: "0px" }}
                                              />
                                            </div>
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="question-bot-sapce">
                              <div className=" question-weight">
                                <span>Q45. </span>
                                Provide information for{" "}
                                <span
                                  className="modal-popup"
                                  data-tooltip-id="encounterTip"
                                  onClick={() =>
                                    setShowHideHyperlinkModalIMWG(true)
                                  }
                                >
                                  assessments of treatment response
                                </span>{" "}
                                conducted during the patient’s treatment with{" "}
                                {""}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {/* {test.cohortTreatmentName} */}

                                  {/* {data.cohortTreatmentName === "TALVEY" ||
                                  data.cohortTreatmentName === "ELREXFIO" ? (
                                    <>
                                      {data.cohortTreatmentName}
                                      <sup className="supscript">TM</sup>
                                    </>
                                  ) : (
                                    data.cohortTreatmentName
                                  )} */}
                                  {data.cohortTreatmentName}
                                  {data.cohortTreatmentNameSuperscript == null ? (
                                    ''
                                  ) : (
                                    <>
                                    <sup className="supscript">{data.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                    {data.cohortTreatmentNameSuperscript.slice(2)}
                                    </>
                                  )}
                                </span>{" "}
                                (i.e., from{" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {" "}
                                  {data.cohortIndexDate}{" "}
                                </span>{" "}
                                to{" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {data.cohorttreatmentid < 9
                                    ? ndmmEndofFollowupDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.TECVAYLI
                                    ? tecvayliEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.TALVEY
                                    ? talveyEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.ELREXFIO
                                    ? elrexfioEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.Linvoseltamab
                                    ? linvoseltamabEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.CARVYKTI
                                    ? carvyktiEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.ABECMA
                                    ? abecmaEndofFollowUpDate
                                    : "[NDMM end of follow up or Bispecifics/CAR-T end of follow up]"}
                                </span>
                                ).
                              </div>

                              <div className="sub-question">
                                <table
                                  style={{
                                    border: "1px solid black",
                                    borderCollapse: "collapse",
                                    width: "100%",
                                  }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                          textAlign: "center",
                                          // width:"4%"
                                        }}
                                      >
                                        Assessment
                                      </th>
                                      <th
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                          textAlign: "center",
                                          //   width: "29%"
                                        }}
                                      >
                                        <span style={{ textAlign: "center" }}>
                                          Treatment response status
                                        </span>
                                      </th>

                                      {!((data.Q45FR_tmtResponseStatus === 8 || data.Q45FR_tmtResponseStatus === 9) &&
(data.Q45BR_tmtResponseStatus === 8 || data.Q45BR_tmtResponseStatus === 9)) && (
   <>
                                      <th
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                          textAlign: "center",
                                          // width:"36%"
                                        }}
                                      >
                                        Date of response assessment
                                      </th>
                                      <th
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                          textAlign: "center",
                                          //  width:"28%"
                                        }}
                                      >
                                        Method of assessment
                                      </th>
                                      </> )}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <span className="quest-text-pad">
                                          First response
                                        </span>
                                      </td>

                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <div>
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <label className="long-text-option">
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45FR_tmtResponseStatus" + idx
                                              }
                                              value={1}
                                              checked={
                                                data.Q45FR_tmtResponseStatus ===
                                                1
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Stringent complete response
                                                (sCR)
                                              </span>
                                            </label>
                                          </div>

                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <label className="long-text-option">
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45FR_tmtResponseStatus" + idx
                                              }
                                              value={2}
                                              checked={
                                                data.Q45FR_tmtResponseStatus ===
                                                2
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Complete response (CR)
                                              </span>
                                            </label>
                                          </div>

                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <label className="long-text-option">
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45FR_tmtResponseStatus" + idx
                                              }
                                              value={3}
                                              checked={
                                                data.Q45FR_tmtResponseStatus ===
                                                3
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Very good partial response
                                                (VGPR)
                                              </span>
                                            </label>
                                          </div>

                                          {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                          {/* </div> */}
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <label className="long-text-option">

                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45FR_tmtResponseStatus" + idx
                                              }
                                              value={4}
                                              checked={
                                                data.Q45FR_tmtResponseStatus ===
                                                4
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Partial response (PR)
                                              </span>
                                            </label>
                                          </div>
                                        </div>

                                        <div
                                          className="option-without-input"
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label className="long-text-option">
                                          <input
                                            type="radio"
                                            style={{ marginTop: "6px" }}
                                            id={idx}
                                            name={
                                              "Q45FR_tmtResponseStatus" + idx
                                            }
                                            value={5}
                                            checked={
                                              data.Q45FR_tmtResponseStatus === 5
                                            }
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              Minimal response (MR)
                                            </span>
                                          </label>
                                        </div>

                                        <div
                                          className="option-without-input"
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label className="long-text-option">
                                          <input
                                            type="radio"
                                            style={{ marginTop: "6px" }}
                                            id={idx}
                                            name={
                                              "Q45FR_tmtResponseStatus" + idx
                                            }
                                            value={6}
                                            checked={
                                              data.Q45FR_tmtResponseStatus === 6
                                            }
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              Stable disease (SD)
                                            </span>
                                          </label>
                                        </div>

                                        <div
                                          className="option-without-input"
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label className="long-text-option">
                                          <input
                                            type="radio"
                                            style={{ marginTop: "6px" }}
                                            id={idx}
                                            name={
                                              "Q45FR_tmtResponseStatus" + idx
                                            }
                                            value={7}
                                            checked={
                                              data.Q45FR_tmtResponseStatus === 7
                                            }
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              Progressive disease (PD)
                                            </span>
                                          </label>
                                        </div>

                                        <div
                                          className="option-without-input"
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label className="long-text-option">
                                        
                                          <input
                                            type="radio"
                                            style={{ marginTop: "6px" }}
                                            id={idx}
                                            name={
                                              "Q45FR_tmtResponseStatus" + idx
                                            }
                                            value={8}
                                            checked={
                                              data.Q45FR_tmtResponseStatus === 8
                                            }
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              Not evaluable
                                            </span>
                                          </label>
                                        </div>
                                        <div
                                          className="option-without-input"
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label className="long-text-option">
                                          <input
                                            type="radio"
                                            style={{ marginTop: "6px" }}
                                            id={idx}
                                            name={
                                              "Q45FR_tmtResponseStatus" + idx
                                            }
                                            value={9}
                                            checked={
                                              data.Q45FR_tmtResponseStatus === 9
                                            }
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              Not achieved
                                            </span>
                                          </label>
                                        </div>
                                        <ErrorField
                                          show={
                                            data.Q45FR_tmtResponseStatusEmpty
                                          }
                                          message={TreatmentResponseProgression_Msgs.Q45Treatment}
                                          style={{ paddingLeft: "0px" }}
                                        />
                                      </td>

                                      {!(data.Q45FR_tmtResponseStatus === 8 || data.Q45FR_tmtResponseStatus === 9)?
          
          (<>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <div>
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <label className="long-text-option">
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45FR_dateOfResponse_assessRd" +
                                                idx
                                              }
                                              value={1}
                                              checked={
                                                data.Q45FR_dateOfResponse_assessRd ===
                                                1
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                First response assessed on:
                                              </span>
                                            </label>
                                          </div>

                                          {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                          <span
                                            className="radio-text-padding"
                                            style={{
                                              paddingLeft: "41px",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            <DateControl
                                              ctrlId={
                                                "Q45FR_dateOfResponse_assess_dt-" +
                                                idx
                                              }
                                              HandleDateChange={
                                                HandleRepeaterDateChange
                                              }
                                              date={
                                                data.Q45FR_dateOfResponse_assess_dt
                                              }
                                            />
                                          </span>
                                          {/* </div> */}
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <label className="long-text-option">
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45FR_dateOfResponse_assessRd" +
                                                idx
                                              }
                                              value={99}
                                              checked={
                                                data.Q45FR_dateOfResponse_assessRd ===
                                                99
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                First response occurred but date
                                                of assessment unknown
                                              </span>
                                            </label>
                                          </div>

                                          <ErrorField
                                            show={
                                              data.Q45FR_dateOfResponse_assessEmpty
                                            }
                                            message={TreatmentResponseProgression_Msgs.Q45Date}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                          <ErrorField
                                            show={
                                              data.Q45FR_dateOfResponse_assess_dtEmpty
                                            }
                                            message={TreatmentResponseProgression_Msgs.Q45DateEmpty}
                                            style={{ paddingLeft: "0px" }}
                                          />

                                          <ErrorField
                                            show={data.Q45FRDateValidation1}
                                            message={TreatmentResponseProgression_Msgs.Q45DateInvalid}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                          <ErrorField
                                            show={data.Q45FRDateValidation2}
                                            message={TreatmentResponseProgression_Msgs.Q45DateInvalid}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                          <ErrorField
                                            show={data.Q45FRDateValidation3}
                                            message={TreatmentResponseProgression_Msgs.Q45DateInvalid}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <div>
                                          <div
                                          // className="option-without-input"
                                          // style={{ display: "flex" }}
                                          >
                                            {assessmentMethods &&
                                              assessmentMethods.length > 0 &&
                                              assessmentMethods.map((item) => (
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q45FR_assessmentMethodRd" +
                                                      idx
                                                    }
                                                    value={
                                                      item.assessmentmethodid
                                                    }
                                                    checked={
                                                      data.Q45FR_assessmentMethodRd ===
                                                      item.assessmentmethodid
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    {item.assessmentmethodid ===
                                                    1 ? (
                                                      <span
                                                        className="radio-text-padding modal-popup"
                                                        data-tooltip-id="encounterTip"
                                                        onClick={() =>
                                                          setShowHideHyperlinkModalIMWG(
                                                            true
                                                          )
                                                        }
                                                      >
                                                        {item.name}
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        {item.name}
                                                      </span>
                                                    )}
                                                  </label>
                                                </div>
                                              ))}
                                            {/* <input
                                              style={{ marginTop: "-18px" }}
                                              type="radio"
                                              id={idx}
                                              name={
                                                "Q45FR_assessmentMethodRd" + idx
                                              }
                                              value={1}
                                              checked={
                                                data.Q45FR_assessmentMethodRd ===
                                                1
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                                marginLeft: "-5px",
                                              }}
                                            >
                                              {" "}
                                              Standard IMWG Response Criteria
                                            </span> */}
                                          </div>
                                          {/* <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45FR_assessmentMethodRd" + idx
                                              }
                                              value={2}
                                              checked={
                                                data.Q45FR_assessmentMethodRd ===
                                                2
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <label>
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Other standard response criteria
                                              </span>
                                            </label>
                                          </div>

                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45FR_assessmentMethodRd" + idx
                                              }
                                              value={3}
                                              checked={
                                                data.Q45FR_assessmentMethodRd ===
                                                3
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <label>
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                {" "}
                                                No standard criteria mentioned
                                                in chart
                                              </span>
                                            </label>
                                          </div> */}
                                          <ErrorField
                                            show={
                                              data.Q45FR_assessmentMethodEmpty
                                            }
                                            message={TreatmentResponseProgression_Msgs.Q45Method}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                          {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeEmptyDate
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                          {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt_check
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeDateCheck
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                        </div>
                                      </td>
                                      </>): (<td colSpan={2}
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>
                                                                    )
                                      }
                                    </tr>

                                    <tr>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <span className="quest-text-pad">
                                          Best response
                                        </span>
                                      </td>

                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <div>
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <label className="long-text-option">
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45BR_tmtResponseStatus" + idx
                                              }
                                              value={1}
                                              checked={
                                                data.Q45BR_tmtResponseStatus ===
                                                1
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Stringent complete response
                                                (sCR)
                                              </span>
                                            </label>
                                          </div>

                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <label className="long-text-option">
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45BR_tmtResponseStatus" + idx
                                              }
                                              value={2}
                                              checked={
                                                data.Q45BR_tmtResponseStatus ===
                                                2
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Complete response (CR)
                                              </span>
                                            </label>
                                          </div>

                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <label className="long-text-option">
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45BR_tmtResponseStatus" + idx
                                              }
                                              value={3}
                                              checked={
                                                data.Q45BR_tmtResponseStatus ===
                                                3
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Very good partial response
                                                (VGPR)
                                              </span>
                                            </label>
                                          </div>

                                          {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                          {/* </div> */}
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <label className="long-text-option">
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45BR_tmtResponseStatus" + idx
                                              }
                                              value={4}
                                              checked={
                                                data.Q45BR_tmtResponseStatus ===
                                                4
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Partial response (PR)
                                              </span>
                                            </label>
                                          </div>
                                        </div>

                                        <div
                                          className="option-without-input"
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label className="long-text-option">
                                          <input
                                            type="radio"
                                            style={{ marginTop: "6px" }}
                                            id={idx}
                                            name={
                                              "Q45BR_tmtResponseStatus" + idx
                                            }
                                            value={5}
                                            checked={
                                              data.Q45BR_tmtResponseStatus === 5
                                            }
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                        
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              Minimal response (MR)
                                            </span>
                                          </label>
                                        </div>

                                        <div
                                          className="option-without-input"
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label className="long-text-option">
                                          <input
                                            type="radio"
                                            style={{ marginTop: "6px" }}
                                            id={idx}
                                            name={
                                              "Q45BR_tmtResponseStatus" + idx
                                            }
                                            value={6}
                                            checked={
                                              data.Q45BR_tmtResponseStatus === 6
                                            }
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              Stable disease (SD)
                                            </span>
                                          </label>
                                        </div>

                                        <div
                                          className="option-without-input"
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label className="long-text-option">
                                          <input
                                            type="radio"
                                            style={{ marginTop: "6px" }}
                                            id={idx}
                                            name={
                                              "Q45BR_tmtResponseStatus" + idx
                                            }
                                            value={7}
                                            checked={
                                              data.Q45BR_tmtResponseStatus === 7
                                            }
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              Progressive disease (PD)
                                            </span>
                                          </label>
                                        </div>

                                        <div
                                          className="option-without-input"
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label className="long-text-option">
                                          <input
                                            type="radio"
                                            style={{ marginTop: "6px" }}
                                            id={idx}
                                            name={
                                              "Q45BR_tmtResponseStatus" + idx
                                            }
                                            value={8}
                                            checked={
                                              data.Q45BR_tmtResponseStatus === 8
                                            }
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              Not evaluable
                                            </span>
                                          </label>
                                        </div>
                                        <div
                                          className="option-without-input"
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label className="long-text-option">
                                          <input
                                            type="radio"
                                            style={{ marginTop: "6px" }}
                                            id={idx}
                                            name={
                                              "Q45BR_tmtResponseStatus" + idx
                                            }
                                            value={9}
                                            checked={
                                              data.Q45BR_tmtResponseStatus === 9
                                            }
                                            onChange={HandleRepeaterFieldChange}
                                          />
                                          
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              Not achieved
                                            </span>
                                          </label>
                                        </div>

                                        <ErrorField
                                          show={
                                            data.Q45BR_tmtResponseStatusEmpty
                                          }
                                          message={TreatmentResponseProgression_Msgs.Q45Treatment}
                                          style={{ paddingLeft: "0px" }}
                                        />
                                        {/* <ErrorField
                                                show={
                                                data.dose_administration_techHard_dt
                                                }
                                                message={
                                                TTCharacteristics_MSGS.administrationEmptyDate
                                                }
                                                style={{ paddingLeft: "0px" }}
                                            /> */}
                                      </td>

                                      {!(data.Q45BR_tmtResponseStatus === 8 || data.Q45BR_tmtResponseStatus === 9)
          ? 
          (<>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <div>
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <label className="long-text-option">
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45BR_dateOfResponse_assessRd" +
                                                idx
                                              }
                                              value={1}
                                              checked={
                                                data.Q45BR_dateOfResponse_assessRd ===
                                                1
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Best response assessed on:
                                              </span>
                                            </label>
                                          </div>

                                          {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                          <span
                                            className="radio-text-padding"
                                            style={{
                                              paddingLeft: "41px",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            <DateControl
                                              ctrlId={
                                                "Q45BR_dateOfResponse_assess_dt-" +
                                                idx
                                              }
                                              HandleDateChange={
                                                HandleRepeaterDateChange
                                              }
                                              date={
                                                data.Q45BR_dateOfResponse_assess_dt
                                              }
                                            />
                                          </span>
                                          {/* </div> */}
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <label className="long-text-option">
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45BR_dateOfResponse_assessRd" +
                                                idx
                                              }
                                              value={99}
                                              checked={
                                                data.Q45BR_dateOfResponse_assessRd ===
                                                99
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Best response occurred but date
                                                of assessment unknown
                                              </span>
                                            </label>
                                          </div>

                                          <ErrorField
                                            show={
                                              data.Q45BR_dateOfResponse_assessEmpty
                                            }
                                            message={TreatmentResponseProgression_Msgs.Q45Date}

                                            style={{ paddingLeft: "0px" }}
                                          />
                                          <ErrorField
                                            show={
                                              data.Q45BR_dateOfResponse_assess_dtEmpty
                                            }
                                            message={TreatmentResponseProgression_Msgs.Q45DateEmpty}
                                            style={{ paddingLeft: "0px" }}
                                          />

                                          <ErrorField
                                            show={data.Q45BRDateValidation1}
                                            message={TreatmentResponseProgression_Msgs.Q45DateInvalid}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                          <ErrorField
                                            show={data.Q45BRDateValidation2}
                                            message={TreatmentResponseProgression_Msgs.Q45DateInvalid}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                          <ErrorField
                                            show={data.Q45BRDateValidation3}
                                            message={TreatmentResponseProgression_Msgs.Q45DateInvalid}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <div>
                                          <div
                                          // className="option-without-input"
                                          // style={{ display: "flex" }}
                                          >
                                            {/* <input
                                              style={{ marginTop: "-18px" }}
                                              type="radio"
                                              id={idx}
                                              name={
                                                "Q45BR_assessmentMethodRd" + idx
                                              }
                                              value={1}
                                              checked={
                                                data.Q45BR_assessmentMethodRd ===
                                                1
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                                marginLeft: "-5px",
                                              }}
                                            >
                                              {" "}
                                              Standard IMWG Response Criteria
                                            </span> */}
                                            {assessmentMethods &&
                                              assessmentMethods.length > 0 &&
                                              assessmentMethods.map((item) => (
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q45BR_assessmentMethodRd" +
                                                      idx
                                                    }
                                                    value={
                                                      item.assessmentmethodid
                                                    }
                                                    checked={
                                                      data.Q45BR_assessmentMethodRd ===
                                                      item.assessmentmethodid
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    {item.assessmentmethodid ===
                                                    1 ? (
                                                      <span
                                                        className="radio-text-padding modal-popup"
                                                        data-tooltip-id="encounterTip"
                                                        onClick={() =>
                                                          setShowHideHyperlinkModalIMWG(
                                                            true
                                                          )
                                                        }
                                                      >
                                                        {item.name}
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        {item.name}
                                                      </span>
                                                    )}
                                                  </label>
                                                </div>
                                              ))}
                                          </div>
                                          {/* <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45BR_assessmentMethodRd" + idx
                                              }
                                              value={2}
                                              checked={
                                                data.Q45BR_assessmentMethodRd ===
                                                2
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <label>
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Other standard response criteria
                                              </span>
                                            </label>
                                          </div>

                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q45BR_assessmentMethodRd" + idx
                                              }
                                              value={3}
                                              checked={
                                                data.Q45BR_assessmentMethodRd ===
                                                3
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <label>
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                {" "}
                                                No standard criteria mentioned
                                                in chart
                                              </span>
                                            </label>
                                          </div> */}
                                          <ErrorField
                                            show={
                                              data.Q45BR_assessmentMethodEmpty
                                            }
                                            message={TreatmentResponseProgression_Msgs.Q45Method}

                                            style={{ paddingLeft: "0px" }}
                                          />
                                          {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeEmptyDate
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                          {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt_check
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeDateCheck
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                        </div>
                                      </td>
                                      </>): (<td colSpan={2}
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)
                                      
                                    }
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div className="question-bot-sapce">
                              <div className=" question-weight">
                                <span>Q46. </span>
                                Provide details of each minimum residual disease
                                (MRD) assessment during treatment with{" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {/* {test.cohortTreatmentName} */}

                                  {/* {data.cohortTreatmentName === "TALVEY" ||
                                  data.cohortTreatmentName === "ELREXFIO" ? (
                                    <>
                                      {data.cohortTreatmentName}
                                      <sup className="supscript">TM</sup>
                                    </>
                                  ) : (
                                    data.cohortTreatmentName
                                  )} */}
                                  {data.cohortTreatmentName}
                                  {data.cohortTreatmentNameSuperscript == null ? (
                                    ''
                                  ) : (
                                    <>
                                    <sup className="supscript">{data.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                    {data.cohortTreatmentNameSuperscript.slice(2)}
                                    </>
                                  )}
                                </span>{" "}
                                (i.e., from{" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {" "}
                                  {data.cohortIndexDate}{" "}
                                </span>{" "}
                                to{" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {data.cohorttreatmentid < 9
                                    ? ndmmEndofFollowupDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.TECVAYLI
                                    ? tecvayliEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.TALVEY
                                    ? talveyEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.ELREXFIO
                                    ? elrexfioEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.Linvoseltamab
                                    ? linvoseltamabEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.CARVYKTI
                                    ? carvyktiEndofFollowUpDate
                                    : data.cohorttreatmentid ===
                                      RRMMCohortTreatment.ABECMA
                                    ? abecmaEndofFollowUpDate
                                    : "[NDMM end of follow up or Bispecifics/CAR-T end of follow up]"}
                                </span>
                                ).
                              </div>

                              {/* <div className="sub-question"> */}

                              {/* </div> */}

                              {/* {isTalveySelected && ( */}
                              <div className="dependent-section">
                                {data.q46Row &&
                                  data.q46Row.length > 0 &&
                                  data.q46Row.map((child, index) => (
                                    <div
                                      className="loop-section-ul"
                                      key={index}
                                    >
                                      <div className="loop-head">
                                        <span
                                          className="question-weight"
                                          style={{ float: "inline-start" }}
                                        >
                                          {GetOrdinal(index + 1)} Assessment{" "}
                                          {/* <sup className="supscript">
                                                TM
                                              </sup> */}
                                        </span>

                                        {index != 0 && (
                                          <div
                                            className="delete-btn-div"
                                            style={{ float: "inline-end" }}
                                          >
                                            <img
                                              width="16px"
                                              src="../Assets/images/Icon-material-delete.png"
                                              alt=""
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                DeleteQ46Row(idx, index)
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>

                                      {/* TECVAYLI® */}
                                      <div className="question-bot-sapce">
                                        <div className="sub-question">
                                          <table
                                            style={{
                                              border: "1px solid black",
                                              borderCollapse: "collapse",
                                              width: "100%",
                                            }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                    // width:"4%"
                                                  }}
                                                >
                                                  Date of assessment
                                                </th>
                                                {(child.Q46dateofassessmentRd===2 || child.Q46dateofassessmentRd===99) && (<>
                                                
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                    width: "20%",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Method of assessment
                                                  </span>
                                                </th>

                                                {/* {data.Q36TechConditionMet &&( <> */}
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                    // width:"36%"
                                                  }}
                                                >
                                                  Sensitivity of assessment
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                    //  width:"28%"
                                                  }}
                                                >
                                                  MRD status
                                                </th>
                                                </> )}
                                              </tr>
                                            </thead>

                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={index}
                                                        name={
                                                          "Q46dateofassessmentRd" +
                                                          index +
                                                          "-" +
                                                          idx
                                                        }
                                                        value={1}
                                                        checked={
                                                          child.Q46dateofassessmentRd ===
                                                          1
                                                        }
                                                        entryIndex={idx}
                                                        //rowIndex = {index}
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                      
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          MRD was not assessed
                                                        </span>
                                                      </label>
                                                    </div>

                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={index}
                                                        name={
                                                          "Q46dateofassessmentRd" +
                                                          index +
                                                          "-" +
                                                          idx
                                                        }
                                                        value={2}
                                                        checked={
                                                          child.Q46dateofassessmentRd ===
                                                          2
                                                        }
                                                        entryIndex={idx}
                                                        rowIndex={index}
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                     
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          MRD assessed on:
                                                        </span>
                                                      </label>
                                                    </div>

                                                    {/* <div
                                                                              className="option-without-input"
                                                                              style={{ display: "flex" }}
                                                                              >
                                                                                  */}

                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "12px",
                                                        marginLeft: "5px",
                                                      }}
                                                    >
                                                      <DateControl
                                                        ctrlId={
                                                          "Q46dateofassessment_dt-" +
                                                          index +
                                                          "-" +
                                                          idx
                                                        }
                                                        optionId={index}
                                                        HandleDateChange={
                                                          HandleRepeaterDateChange
                                                        }
                                                        date={
                                                          child.Q46dateofassessment_dt
                                                        }
                                                      />
                                                    </span>
                                                    {/* </div> */}
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={index}
                                                        name={
                                                          "Q46dateofassessmentRd" +
                                                          index +
                                                          "-" +
                                                          idx
                                                        }
                                                        value={99}
                                                        checked={
                                                          child.Q46dateofassessmentRd ===
                                                          99
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                      
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          MRD assessed but date
                                                          of assessment unknown
                                                        </span>
                                                      </label>
                                                    </div>

                                                    <ErrorField
                                                      show={
                                                        child.Q46dateofassessmentEmpty
                                                      }
                                                      message={TreatmentResponseProgression_Msgs.Q46Date}
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                    <ErrorField
                                                      show={
                                                        child.Q46dateofassessment_dtEmpty
                                                      }
                                                      message={TreatmentResponseProgression_Msgs.Q46DateEmpty}
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />



                                          <ErrorField
                                            show={child.Q46DateValidation1}
                                            message={TreatmentResponseProgression_Msgs.Q46DateInvalid}
                                            style={{ paddingLeft: "0px" }}
                                          />

                                          <ErrorField
                                            show={child.Q46DateValidation2}
                                            message={TreatmentResponseProgression_Msgs.Q46DateInvalid}
                                            style={{ paddingLeft: "0px" }}
                                          />

                                          <ErrorField
                                            show={child.Q46DateValidation3}
                                            message={TreatmentResponseProgression_Msgs.Q46DateInvalid}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                                  </div>
                                                </td>
                                                {(child.Q46dateofassessmentRd===2 || child.Q46dateofassessmentRd===99) && (<>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="long-text-option">
                                                    <input
                                                      type="radio"
                                                      style={{
                                                        marginTop: "6px",
                                                      }}
                                                      id={index}
                                                      name={
                                                        "Q46assessmentmethodRd" +
                                                        index +
                                                        "-" +
                                                        idx
                                                      }
                                                      value={1}
                                                      checked={
                                                        child.Q46assessmentmethodRd ===
                                                        1
                                                      }
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        Multiparameter flow
                                                        cytometry
                                                      </span>
                                                    </label>
                                                  </div>

                                                  <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="long-text-option">
                                                    <input
                                                      type="radio"
                                                      style={{
                                                        marginTop: "6px",
                                                      }}
                                                      id={index}
                                                      name={
                                                        "Q46assessmentmethodRd" +
                                                        index +
                                                        "-" +
                                                        idx
                                                      }
                                                      value={2}
                                                      checked={
                                                        child.Q46assessmentmethodRd ===
                                                        2
                                                      }
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        Next-generation
                                                        sequencing (NGS)
                                                      </span>
                                                    </label>
                                                  </div>

                                                  <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="long-text-option">
                                                    <input
                                                      type="radio"
                                                      style={{
                                                        marginTop: "6px",
                                                      }}
                                                      id={index}
                                                      name={
                                                        "Q46assessmentmethodRd" +
                                                        index +
                                                        "-" +
                                                        idx
                                                      }
                                                      value={3}
                                                      checked={
                                                        child.Q46assessmentmethodRd ===
                                                        3
                                                      }
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                   
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        Next-generation flow
                                                      </span>
                                                    </label>
                                                  </div>

                                                  <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="long-text-option">
                                                    <input
                                                      type="radio"
                                                      style={{
                                                        marginTop: "6px",
                                                      }}
                                                      id={index}
                                                      name={
                                                        "Q46assessmentmethodRd" +
                                                        index +
                                                        "-" +
                                                        idx
                                                        //index
                                                      }
                                                      value={4}
                                                      checked={
                                                        child.Q46assessmentmethodRd ===
                                                        4
                                                      }
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        Polymerase Chain
                                                        Reaction (PCR)
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="long-text-option">
                                                    <input
                                                      type="radio"
                                                      style={{
                                                        marginTop: "6px",
                                                      }}
                                                      id={index}
                                                      name={
                                                        "Q46assessmentmethodRd" +
                                                        index +
                                                        "-" +
                                                        idx
                                                        // index
                                                      }
                                                      value={5}
                                                      checked={
                                                        child.Q46assessmentmethodRd ===
                                                        5
                                                      }
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        Bone marrow biopsy
                                                      </span>
                                                    </label>
                                                  </div>

                                                  <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="long-text-option">
                                                    <input
                                                      type="radio"
                                                      style={{
                                                        marginTop: "6px",
                                                      }}
                                                      id={index}
                                                      name={
                                                        "Q46assessmentmethodRd" +
                                                        index +
                                                        "-" +
                                                        idx
                                                        // index
                                                      }
                                                      value={6}
                                                      checked={
                                                        child.Q46assessmentmethodRd ===
                                                        6
                                                      }
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                      >
                                                        Other
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <ErrorField
                                                    show={
                                                      child.Q46assessmentmethodEmpty
                                                    }
                                                    message={TreatmentResponseProgression_Msgs.Q46Method}
                                                    style={{
                                                      paddingLeft: "0px",
                                                    }}
                                                  />
                                                </td>

                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                      <input
                                                        type="radio"
                                                        id={index}
                                                        name={
                                                          "Q46sensitivityRd" +
                                                          index +
                                                          "-" +
                                                          idx
                                                        }
                                                        value={1}
                                                        checked={
                                                          child.Q46sensitivityRd ===
                                                          1
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                          // marginLeft: "-5px",
                                                        }}
                                                      >
                                                        {" "}
                                                        10<sup>-5</sup>
                                                      </span>
                                                      </label>
                                                    </div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={index}
                                                        name={
                                                          "Q46sensitivityRd" +
                                                          index +
                                                          "-" +
                                                          idx
                                                        }
                                                        value={2}
                                                        checked={
                                                          child.Q46sensitivityRd ===
                                                          2
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                      
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          10<sup>-6</sup>
                                                        </span>
                                                      </label>
                                                    </div>

                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={index}
                                                        name={
                                                          "Q46sensitivityRd" +
                                                          index +
                                                          "-" +
                                                          idx
                                                        }
                                                        value={3}
                                                        checked={
                                                          child.Q46sensitivityRd ===
                                                          3
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                     
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          {" "}
                                                          Unknown
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <ErrorField
                                                      show={
                                                        child.Q46sensitivityEmpty
                                                      }
                                                      message={
                                                        TreatmentResponseProgression_Msgs.Q46Sensitivity
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                  </div>
                                                </td>

                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                      <input
                                                        type="radio"
                                                        id={index}
                                                        name={
                                                          "Q46mrdstatusRd" +
                                                          index +
                                                          "-" +
                                                          idx
                                                        }
                                                        value={1}
                                                        checked={
                                                          child.Q46mrdstatusRd ===
                                                          1
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                      <span
                                                        className="radio-text-padding"
                                                        style={{
                                                          paddingLeft: "10px",
                                                          // marginLeft: "-5px",
                                                        }}
                                                      >
                                                        {" "}
                                                        Positive
                                                      </span>
                                                      </label>
                                                    </div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={index}
                                                        name={
                                                          "Q46mrdstatusRd" +
                                                          index +
                                                          "-" +
                                                          idx
                                                        }
                                                        value={2}
                                                        checked={
                                                          child.Q46mrdstatusRd ===
                                                          2
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                     
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Negative
                                                        </span>
                                                      </label>
                                                    </div>

                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={index}
                                                        name={
                                                          "Q46mrdstatusRd" +
                                                          index +
                                                          "-" +
                                                          idx
                                                        }
                                                        value={99}
                                                        checked={
                                                          child.Q46mrdstatusRd ===
                                                          99
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />

                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          {" "}
                                                          Unknown
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <ErrorField
                                                      show={
                                                        child.Q46mrdstatusEmpty
                                                      }
                                                      message={TreatmentResponseProgression_Msgs.Q46MRDStatus}
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                  </div>
                                                </td>
                                                </>)}
                                                {/* </>)} */}
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                {data.q46Row &&
                                  data.q46Row.length > 0 &&
                                  data.q46Row.length < 550 && (

                                    <div className="sub-question-bot-sapce-ul mt-2">
                                       {!data.q46Row.some(row => row?.Q46dateofassessmentRd === 1) && (
                                      <div
                                        className="answer-list-text"
                                        onClick={() => {
                                          AddQ46Row(idx);
                                        }}
                                      >
                                        <a>
                                          <img
                                            width="18px"
                                            src="../Assets/images/plus.png"
                                            alt=""
                                          />
                                        </a>
                                        <a className="radio-text-padding">
                                          Click here to add another assessment
                                        </a>
                                      </div>

) }
                                    </div>
                                  )}
                              </div>
                              {/* )} */}

                              <br />

                              {showQ47_48 && data.cohorttreatmentid <  RRMMCohortTreatment.TECVAYLI  && (
                                <>
                                  <tr>
                                    <b
                                      style={{
                                        
                                      }}
                                    >
                                      Response Assessments in 2<sup>nd</sup>{" "}
                                      Line of Treatment{" "}
                                    </b>
                                  </tr>
                                  <div className="question-bot-sapce">
                                    <div className=" question-weight">
                                      <span> Q47. </span>
                                      Provide information for{" "}
                                      <span
                                        className="modal-popup"
                                        data-tooltip-id="encounterTip"
                                        onClick={() =>
                                          setShowHideHyperlinkModalIMWG(true)
                                        }
                                      >
                                        disease progression
                                      </span>{" "}
                                      assessed during the patient’s 2
                                      <sup>nd</sup> line of treatment.
                                    </div>

                                    <div className="sub-question">
                                      <table
                                        style={{
                                          border: "1px solid black",
                                          borderCollapse: "collapse",
                                          width: "100%",
                                        }}
                                      >
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                                textAlign: "center",
                                                // width:"4%"
                                              }}
                                            >
                                              Assessment
                                            </th>
                                            <th
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                                textAlign: "center",
                                                //   width: "29%"
                                              }}
                                            >
                                              <span
                                                style={{ textAlign: "center" }}
                                              >
                                                Date of progression assessment
                                              </span>
                                            </th>

                                            {data.Q47ConditionMet && (
                                              <>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                    // width:"36%"
                                                  }}
                                                >
                                                  Reason for progression
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                    //  width:"28%"
                                                  }}
                                                >
                                                  Method of assessment
                                                </th>
                                              </>
                                            )}
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <span className="quest-text-pad">
                                                Disease progression
                                              </span>
                                            </td>

                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q47dateOfProgressionRd" +
                                                      idx
                                                    }
                                                    value={1}
                                                    checked={
                                                      data.Q47dateOfProgressionRd ===
                                                      1
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Disease progression did
                                                      not occur
                                                    </span>
                                                  </label>
                                                </div>

                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q47dateOfProgressionRd" +
                                                      idx
                                                    }
                                                    value={2}
                                                    checked={
                                                      data.Q47dateOfProgressionRd ===
                                                      2
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Disease progression
                                                      occurred on:
                                                    </span>
                                                  </label>
                                                </div>

                                                {/* <div
                                            className="option-without-input"
                                            style={{ display: "flex" }}
                                            >
                                                */}

                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "41px",
                                                    marginLeft: "5px",
                                                  }}
                                                >
                                                  <DateControl
                                                    ctrlId={
                                                      "Q47dateOfProgression_dt-" +
                                                      idx
                                                    }
                                                    HandleDateChange={
                                                      HandleRepeaterDateChange
                                                    }
                                                    date={
                                                      data.Q47dateOfProgression_dt
                                                    }
                                                  />
                                                </span>
                                                {/* </div> */}
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q47dateOfProgressionRd" +
                                                      idx
                                                    }
                                                    value={99}
                                                    checked={
                                                      data.Q47dateOfProgressionRd ===
                                                      99
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Disease progression
                                                      occurred but date of
                                                      assessment unknown
                                                    </span>
                                                  </label>
                                                </div>

                                                <ErrorField
                                                  show={
                                                    data.Q47dateOfProgressionEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q47Date}
                                                  style={{ paddingLeft: "0px" }}
                                                />
                                                <ErrorField
                                                  show={
                                                    data.Q47dateOfProgression_dtEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q47DateEmpty}
                                                  style={{ paddingLeft: "0px" }}
                                                />

                                                
                                                <ErrorField
                                                  show={
                                                    data.Q47DateValidation1
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                                  style={{ paddingLeft: "0px" }}
                                                />
                                                <ErrorField
                                                  show={
                                                    data.Q47DateValidation2
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                                  style={{ paddingLeft: "0px" }}
                                                />
                                                <ErrorField
                                                  show={
                                                    data.Q47DateValidation3
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                                  style={{ paddingLeft: "0px" }}
                                                />

                                              </div>
                                            </td>

                                            {data.Q47ConditionMet && (
                                              <>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div>
                                                    <div className="">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          type="checkbox"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "Q47serum" + idx
                                                          }
                                                          checked={
                                                            data.Q47serum
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span className="radio-text-padding">
                                                          Serum M protein
                                                        </span>
                                                        </label>
                                                      </div>
                                                    </div>

                                                    <div className="">  
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          type="checkbox"
                                                          id={idx}
                                                          name={
                                                            "Q47uninary" + idx
                                                          }
                                                          checked={
                                                            data.Q47uninary
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span className="radio-text-padding">
                                                          Urinary M protein
                                                        </span>
                                                        </label>
                                                      </div>
                                                    </div>

                                                    <div className="">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          type="checkbox"
                                                          id={idx}
                                                          name={
                                                            "Q47freelight" + idx
                                                          }
                                                          checked={
                                                            data.Q47freelight
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span className="radio-text-padding">
                                                          Free light chain
                                                        </span>
                                                        </label>
                                                      </div>
                                                    </div>

                                                    <div className="">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          type="checkbox"
                                                          id={idx}
                                                          name={
                                                            "Q47plasma" + idx
                                                          }
                                                          checked={
                                                            data.Q47plasma
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span className="radio-text-padding">
                                                          Plasma cell increase
                                                        </span>
                                                        </label>
                                                      </div>
                                                    </div>

                                                    <div className="">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          type="checkbox"
                                                          id={idx}
                                                          name={
                                                            "Q47bonelesion" +
                                                            idx
                                                          }
                                                          checked={
                                                            data.Q47bonelesion
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span className="radio-text-padding">
                                                          Increase in size
                                                          and/or new bone lesion
                                                        </span>
                                                        </label>
                                                      </div>
                                                    </div>

                                                    <div className="">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          type="checkbox"
                                                          id={idx}
                                                          name={
                                                            "Q47plasmacytomas" +
                                                            idx
                                                          }
                                                          checked={
                                                            data.Q47plasmacytomas
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span className="radio-text-padding">
                                                          Increase in size
                                                          and/or new
                                                          plasmacytomas
                                                        </span>
                                                        </label>
                                                      </div>
                                                    </div>

                                                    <div className="">
                                                      <div
                                                      //   style={{display:"flex", alignItems:"flex-start"}}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          type="checkbox"
                                                          id={idx}
                                                          name={
                                                            "Q47ReasonOfProgression_oth" +
                                                            idx
                                                          }
                                                          checked={
                                                            data.Q47ReasonOfProgression_oth
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span className="radio-text-padding">
                                                          Other, please specify:
                                                        </span>
                                                        </label>

                                                        
                                                        <div style={{paddingLeft:"15px"}}>
                                                        <textarea
                                                         
                                                          name={
                                                            "Q47ReasonOfProgression_txt" +
                                                            idx
                                                          }
                                                          className="input-dash"
                                                          type="text"
                                                          value={
                                                            data.Q47ReasonOfProgression_txt
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                          maxLength={300}
                                                          id={idx}
                                                        />
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={idx}
                                                        name={
                                                          "Q47ReasonOfProgression" +
                                                          idx
                                                        }
                                                        value={99}
                                                        checked={
                                                          data.Q47ReasonOfProgression ===
                                                          99
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                      
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Unknown
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <ErrorField
                                                      show={
                                                        data.Q47ReasonOfProgressionEmpty
                                                      }
                                                      message={TreatmentResponseProgression_Msgs.Q47Reason}
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />

                                                    <ErrorField
                                                      show={
                                                        data.Q47ReasonOfProgressionTxtEmpty
                                                      }
                                                      message={TreatmentResponseProgression_Msgs.Q47Reason}
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />

                                                    {/* <ErrorField
                                                show={
                                                    data.dt_admission_techHard_dt_check
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.admissionDateCheck
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div>
                                                    <div
                                                    // className="option-without-input"
                                                    // style={{ display: "flex" }}
                                                    >
                                                      {/* <input
                                                style={{ marginTop: "-18px" }}
                                                type="radio"
                                                id={idx}
                                                name={
                                                  "Q47assessmentMethodRd" + idx
                                                }
                                                value={1}
                                                checked={
                                                  data.Q47assessmentMethodRd ===
                                                  1
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                  marginLeft: "-5px",
                                                }}
                                              >
                                                {" "}
                                                Standard IMWG Response Criteria
                                              </span> */}
                                                      {assessmentMethods &&
                                                        assessmentMethods.length >
                                                          0 &&
                                                        assessmentMethods.map(
                                                          (item) => (
                                                            <div
                                                              className="option-without-input"
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "flex-start",
                                                              }}
                                                            >
                                                              <label className="long-text-option">
                                                              <input
                                                                type="radio"
                                                                style={{
                                                                  marginTop:
                                                                    "6px",
                                                                }}
                                                                id={idx}
                                                                name={
                                                                  "Q47assessmentMethodRd" +
                                                                  idx
                                                                }
                                                                value={
                                                                  item.assessmentmethodid
                                                                }
                                                                checked={
                                                                  data.Q47assessmentMethodRd ===
                                                                  item.assessmentmethodid
                                                                }
                                                                onChange={
                                                                  HandleRepeaterFieldChange
                                                                }
                                                              />
                                                              
                                                                {item.assessmentmethodid ===
                                                                1 ? (
                                                                  <span
                                                                    className="radio-text-padding modal-popup"
                                                                    data-tooltip-id="encounterTip"
                                                                    onClick={() =>
                                                                      setShowHideHyperlinkModalIMWG(
                                                                        true
                                                                      )
                                                                    }
                                                                  >
                                                                    {item.name}
                                                                  </span>
                                                                ) : (
                                                                  <span
                                                                    className="radio-text-padding"
                                                                    style={{
                                                                      paddingLeft:
                                                                        "10px",
                                                                    }}
                                                                  >
                                                                    {item.name}
                                                                  </span>
                                                                )}
                                                              </label>
                                                            </div>
                                                          )
                                                        )}
                                                    </div>
                                                    {/* <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q47assessmentMethodRd" + idx
                                                }
                                                value={2}
                                                checked={
                                                  data.Q47assessmentMethodRd ===
                                                  2
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <label>
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Other standard response
                                                  criteria
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q47assessmentMethodRd" + idx
                                                }
                                                value={3}
                                                checked={
                                                  data.Q47assessmentMethodRd ===
                                                  3
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <label>
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  {" "}
                                                  No standard criteria mentioned
                                                  in chart
                                                </span>
                                              </label>
                                            </div> */}
                                                    <ErrorField
                                                      show={
                                                        data.Q47assessmentMethodEmpty
                                                      }
                                                      message={
                                                        TreatmentResponseProgression_Msgs.Q47Method
                                                      }
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                    {/* <ErrorField
                                                    show={
                                                        data.dt_discharge_techHard_dt
                                                    }
                                                    message={
                                                        TTCharacteristics_MSGS.dischargeEmptyDate
                                                    }
                                                    style={{ paddingLeft: "0px" }}
                                                    /> */}
                                                    {/* <ErrorField
                                                    show={
                                                        data.dt_discharge_techHard_dt_check
                                                    }
                                                    message={
                                                        TTCharacteristics_MSGS.dischargeDateCheck
                                                    }
                                                    style={{ paddingLeft: "0px" }}
                                                    /> */}
                                                  </div>
                                                </td>
                                              </>
                                            )}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>

                                  <div className="question-bot-sapce">
                                    <div className=" question-weight">
                                      <span>Q48. </span>
                                      Provide information for{" "}
                                      <span
                                        className="modal-popup"
                                        data-tooltip-id="encounterTip"
                                        onClick={() =>
                                          setShowHideHyperlinkModalIMWG(true)
                                        }
                                      >
                                        assessments of treatment response
                                      </span>{" "}
                                      conducted during the patient’s 2nd{" "}
                                      <span
                                    data-tooltip-id="encounterTip"
                                    className="modal-popup"
                                    onClick={() =>
                                      setShowHideHyperlinkModal(true)
                                    }
                                  >
                                    line of therapy
                                  </span>.
                                    </div>

                                    <div className="sub-question">
                                      <table
                                        style={{
                                          border: "1px solid black",
                                          borderCollapse: "collapse",
                                          width: "100%",
                                        }}
                                      >
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                                textAlign: "center",
                                                // width:"4%"
                                              }}
                                            >
                                              Assessment
                                            </th>
                                            <th
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                                textAlign: "center",
                                                //   width: "29%"
                                              }}
                                            >
                                              <span
                                                style={{ textAlign: "center" }}
                                              >
                                                Treatment response status
                                              </span>
                                            </th>

                                            {!((data.Q48FR_tmtResponseStatus === 8 || data.Q48FR_tmtResponseStatus === 9) &&
(data.Q48BR_tmtResponseStatus === 8 || data.Q48BR_tmtResponseStatus === 9)) && (
   <>
                                            <th
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                                textAlign: "center",
                                                // width:"36%"
                                              }}
                                            >
                                              Date of response assessment
                                            </th>
                                            <th
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                                textAlign: "center",
                                                //  width:"28%"
                                              }}
                                            >
                                              Method of assessment
                                            </th>
                                            </> )}
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <span className="quest-text-pad">
                                                First response
                                              </span>
                                            </td>

                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q48FR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={1}
                                                    checked={
                                                      data.Q48FR_tmtResponseStatus ===
                                                      1
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Stringent complete
                                                      response (sCR)
                                                    </span>
                                                  </label>
                                                </div>

                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q48FR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={2}
                                                    checked={
                                                      data.Q48FR_tmtResponseStatus ===
                                                      2
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Complete response (CR)
                                                    </span>
                                                  </label>
                                                </div>

                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q48FR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={3}
                                                    checked={
                                                      data.Q48FR_tmtResponseStatus ===
                                                      3
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Very good partial response
                                                      (VGPR)
                                                    </span>
                                                  </label>
                                                </div>

                                                {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                                {/* </div> */}
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q48FR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={4}
                                                    checked={
                                                      data.Q48FR_tmtResponseStatus ===
                                                      4
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Partial response (PR)
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q48FR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={5}
                                                  checked={
                                                    data.Q48FR_tmtResponseStatus ===
                                                    5
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Minimal response (MR)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q48FR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={6}
                                                  checked={
                                                    data.Q48FR_tmtResponseStatus ===
                                                    6
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Stable disease (SD)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q48FR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={7}
                                                  checked={
                                                    data.Q48FR_tmtResponseStatus ===
                                                    7
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Progressive disease (PD)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q48FR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={8}
                                                  checked={
                                                    data.Q48FR_tmtResponseStatus ===
                                                    8
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Not evaluable
                                                  </span>
                                                </label>
                                              </div>
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q48FR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={9}
                                                  checked={
                                                    data.Q48FR_tmtResponseStatus ===
                                                    9
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Not achieved
                                                  </span>
                                                </label>
                                              </div>

                                              <ErrorField
                                                show={
                                                  data.Q48FR_tmtResponseStatusEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q48Treatment}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                            </td>
                                            {!(data.Q48FR_tmtResponseStatus === 8 || data.Q48FR_tmtResponseStatus === 9)
        ? (<>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q48FR_dateOfResponse_assessRd" +
                                                      idx
                                                    }
                                                    value={1}
                                                    checked={
                                                      data.Q48FR_dateOfResponse_assessRd ===
                                                      1
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      First response assessed
                                                      on:
                                                    </span>
                                                  </label>
                                                </div>

                                                {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "41px",
                                                    marginLeft: "5px",
                                                  }}
                                                >
                                                  <DateControl
                                                    ctrlId={
                                                      "Q48FR_dateOfResponse_assess_dt-" +
                                                      idx
                                                    }
                                                    HandleDateChange={
                                                      HandleRepeaterDateChange
                                                    }
                                                    date={
                                                      data.Q48FR_dateOfResponse_assess_dt
                                                    }
                                                  />
                                                </span>
                                                {/* </div> */}
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q48FR_dateOfResponse_assessRd" +
                                                      idx
                                                    }
                                                    value={99}
                                                    checked={
                                                      data.Q48FR_dateOfResponse_assessRd ===
                                                      99
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      First response occurred
                                                      but date of assessment
                                                      unknown
                                                    </span>
                                                  </label>
                                                </div>

                                                <ErrorField
                                                  show={
                                                    data.Q48FR_dateOfResponse_assessEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q48Date}
                                                  style={{ paddingLeft: "0px" }}
                                                />
                                                <ErrorField
                                                  show={
                                                    data.Q48FR_dateOfResponse_assess_dtEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q48DateEmpty}
                                                  style={{ paddingLeft: "0px" }}
                                                />

                                                <ErrorField
                                            show={
                                              data.Q48FRDateValidation1
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                       <ErrorField
                                            show={
                                              data.Q48FRDateValidation2
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                        <ErrorField
                                            show={
                                              data.Q48FRDateValidation3
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                              </div>
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                // className="option-without-input"
                                                // style={{ display: "flex" }}
                                                >
                                                  {/* <input
                                                style={{ marginTop: "-18px" }}
                                                type="radio"
                                                id={idx}
                                                name={
                                                  "Q48FR_assessmentMethodRd" +
                                                  idx
                                                }
                                                value={1}
                                                checked={
                                                  data.Q48FR_assessmentMethodRd ===
                                                  1
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                  marginLeft: "-5px",
                                                }}
                                              >
                                                {" "}
                                                Standard IMWG Response Criteria
                                              </span> */}
                                                  {assessmentMethods &&
                                                    assessmentMethods.length >
                                                      0 &&
                                                    assessmentMethods.map(
                                                      (item) => (
                                                        <div
                                                          className="option-without-input"
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "flex-start",
                                                          }}
                                                        >
                                                          <label className="long-text-option">
                                                          <input
                                                            type="radio"
                                                            style={{
                                                              marginTop: "6px",
                                                            }}
                                                            id={idx}
                                                            name={
                                                              "Q48FR_assessmentMethodRd" +
                                                              idx
                                                            }
                                                            value={
                                                              item.assessmentmethodid
                                                            }
                                                            checked={
                                                              data.Q48FR_assessmentMethodRd ===
                                                              item.assessmentmethodid
                                                            }
                                                            onChange={
                                                              HandleRepeaterFieldChange
                                                            }
                                                          />
                                                          
                                                            {item.assessmentmethodid ===
                                                            1 ? (
                                                              <span
                                                                className="radio-text-padding modal-popup"
                                                                data-tooltip-id="encounterTip"
                                                                onClick={() =>
                                                                  setShowHideHyperlinkModalIMWG(
                                                                    true
                                                                  )
                                                                }
                                                              >
                                                                {item.name}
                                                              </span>
                                                            ) : (
                                                              <span
                                                                className="radio-text-padding"
                                                                style={{
                                                                  paddingLeft:
                                                                    "10px",
                                                                }}
                                                              >
                                                                {item.name}
                                                              </span>
                                                            )}
                                                          </label>
                                                        </div>
                                                      )
                                                    )}
                                                </div>
                                                {/* <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q48FR_assessmentMethodRd" +
                                                      idx
                                                    }
                                                    value={2}
                                                    checked={
                                                      data.Q48FR_assessmentMethodRd ===
                                                      2
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Other standard response
                                                      criteria
                                                    </span>
                                                  </label>
                                                </div>

                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q48FR_assessmentMethodRd" +
                                                      idx
                                                    }
                                                    value={3}
                                                    checked={
                                                      data.Q48FR_assessmentMethodRd ===
                                                      3
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      {" "}
                                                      No standard criteria
                                                      mentioned in chart
                                                    </span>
                                                  </label>
                                                </div> */}
                                                <ErrorField
                                                  show={
                                                    data.Q48FR_assessmentMethodEmpty
                                                  }
                                                  message={
                                                    TreatmentResponseProgression_Msgs.Q48Method
                                                  }
                                                  style={{ paddingLeft: "0px" }}
                                                />
                                                {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeEmptyDate
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                                {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt_check
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeDateCheck
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                              </div>
                                            </td>
                                            </>): (<td colSpan={2}
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)}
                                          </tr>

                                          <tr>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <span className="quest-text-pad">
                                                Best response
                                              </span>
                                            </td>

                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q48BR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={1}
                                                    checked={
                                                      data.Q48BR_tmtResponseStatus ===
                                                      1
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Stringent complete
                                                      response (sCR)
                                                    </span>
                                                  </label>
                                                </div>

                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q48BR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={2}
                                                    checked={
                                                      data.Q48BR_tmtResponseStatus ===
                                                      2
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Complete response (CR)
                                                    </span>
                                                  </label>
                                                </div>

                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q48BR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={3}
                                                    checked={
                                                      data.Q48BR_tmtResponseStatus ===
                                                      3
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Very good partial response
                                                      (VGPR)
                                                    </span>
                                                  </label>
                                                </div>

                                                {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                                {/* </div> */}
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q48BR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={4}
                                                    checked={
                                                      data.Q48BR_tmtResponseStatus ===
                                                      4
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Partial response (PR)
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q48BR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={5}
                                                  checked={
                                                    data.Q48BR_tmtResponseStatus ===
                                                    5
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Minimal response (MR)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q48BR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={6}
                                                  checked={
                                                    data.Q48BR_tmtResponseStatus ===
                                                    6
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Stable disease (SD)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q48BR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={7}
                                                  checked={
                                                    data.Q48BR_tmtResponseStatus ===
                                                    7
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Progressive disease (PD)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q48BR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={8}
                                                  checked={
                                                    data.Q48BR_tmtResponseStatus ===
                                                    8
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                               
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Not evaluable
                                                  </span>
                                                </label>
                                              </div>
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q48BR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={9}
                                                  checked={
                                                    data.Q48BR_tmtResponseStatus ===
                                                    9
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Not achieved
                                                  </span>
                                                </label>
                                              </div>

                                              <ErrorField
                                                show={
                                                  data.Q48BR_tmtResponseStatusEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q48Treatment}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                              {/* <ErrorField
                                                show={
                                                data.dose_administration_techHard_dt
                                                }
                                                message={
                                                TTCharacteristics_MSGS.administrationEmptyDate
                                                }
                                                style={{ paddingLeft: "0px" }}
                                            /> */}
                                            </td>

                                            {!(data.Q48BR_tmtResponseStatus === 8 || data.Q48BR_tmtResponseStatus === 9)
        ? (<>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q48BR_dateOfResponse_assessRd" +
                                                      idx
                                                    }
                                                    value={1}
                                                    checked={
                                                      data.Q48BR_dateOfResponse_assessRd ===
                                                      1
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Best response assessed on:
                                                    </span>
                                                  </label>
                                                </div>

                                                {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "41px",
                                                    marginLeft: "5px",
                                                  }}
                                                >
                                                  <DateControl
                                                    ctrlId={
                                                      "Q48BR_dateOfResponse_assess_dt-" +
                                                      idx
                                                    }
                                                    HandleDateChange={
                                                      HandleRepeaterDateChange
                                                    }
                                                    date={
                                                      data.Q48BR_dateOfResponse_assess_dt
                                                    }
                                                  />
                                                </span>
                                                {/* </div> */}
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q48BR_dateOfResponse_assessRd" +
                                                      idx
                                                    }
                                                    value={99}
                                                    checked={
                                                      data.Q48BR_dateOfResponse_assessRd ===
                                                      99
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Best response occurred but
                                                      date of assessment unknown
                                                    </span>
                                                  </label>
                                                </div>

                                                <ErrorField
                                                  show={
                                                    data.Q48BR_dateOfResponse_assessEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q48Date}
                                                  style={{ paddingLeft: "0px" }}
                                                />
                                                <ErrorField
                                                  show={
                                                    data.Q48BR_dateOfResponse_assess_dtEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q48DateEmpty}
                                                  style={{ paddingLeft: "0px" }}
                                                />

                                                

<ErrorField
                                            show={
                                              data.Q48BRDateValidation1
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                       <ErrorField
                                            show={
                                              data.Q48BRDateValidation2
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                        <ErrorField
                                            show={
                                              data.Q48BRDateValidation3
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                              </div>
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                // className="option-without-input"
                                                // style={{ display: "flex" }}
                                                >
                                                  {/* <input
                                                style={{ marginTop: "-18px" }}
                                                type="radio"
                                                id={idx}
                                                name={
                                                  "Q48BR_assessmentMethodRd" +
                                                  idx
                                                }
                                                value={1}
                                                checked={
                                                  data.Q48BR_assessmentMethodRd ===
                                                  1
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                  marginLeft: "-5px",
                                                }}
                                              >
                                                {" "}
                                                Standard IMWG Response Criteria
                                              </span> */}
                                                  {assessmentMethods &&
                                                    assessmentMethods.length >
                                                      0 &&
                                                    assessmentMethods.map(
                                                      (item) => (
                                                        <div
                                                          className="option-without-input"
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "flex-start",
                                                          }}
                                                        >
                                                          <label className="long-text-option"> 
                                                          <input
                                                            type="radio"
                                                            style={{
                                                              marginTop: "6px",
                                                            }}
                                                            id={idx}
                                                            name={
                                                              "Q48BR_assessmentMethodRd" +
                                                              idx
                                                            }
                                                            value={
                                                              item.assessmentmethodid
                                                            }
                                                            checked={
                                                              data.Q48BR_assessmentMethodRd ===
                                                              item.assessmentmethodid
                                                            }
                                                            onChange={
                                                              HandleRepeaterFieldChange
                                                            }
                                                          />
                                                          
                                                            {item.assessmentmethodid ===
                                                            1 ? (
                                                              <span
                                                                className="radio-text-padding modal-popup"
                                                                data-tooltip-id="encounterTip"
                                                                onClick={() =>
                                                                  setShowHideHyperlinkModalIMWG(
                                                                    true
                                                                  )
                                                                }
                                                              >
                                                                {item.name}
                                                              </span>
                                                            ) : (
                                                              <span
                                                                className="radio-text-padding"
                                                                style={{
                                                                  paddingLeft:
                                                                    "10px",
                                                                }}
                                                              >
                                                                {item.name}
                                                              </span>
                                                            )}
                                                          </label>
                                                        </div>
                                                      )
                                                    )}
                                                </div>
                                                {/* <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q48BR_assessmentMethodRd" +
                                                  idx
                                                }
                                                value={2}
                                                checked={
                                                  data.Q48BR_assessmentMethodRd ===
                                                  2
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <label>
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Other standard response
                                                  criteria
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q48BR_assessmentMethodRd" +
                                                  idx
                                                }
                                                value={3}
                                                checked={
                                                  data.Q48BR_assessmentMethodRd ===
                                                  3
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <label>
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  {" "}
                                                  No standard criteria mentioned
                                                  in chart
                                                </span>
                                              </label>
                                            </div> */}
                                                <ErrorField
                                                  show={
                                                    data.Q48BR_assessmentMethodEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q48Method}
                                                  style={{ paddingLeft: "0px" }}
                                                />
                                                {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeEmptyDate
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                                {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt_check
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeDateCheck
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                              </div>
                                            </td>
                                            </>): (<td colSpan={2}
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>

                                  <br />
                                </>
                              )}

                              {showQ49_50 && data.cohorttreatmentid <  RRMMCohortTreatment.TECVAYLI  && (
                                <>
                                  <tr>
                                    <b
                                      style={{
                                     
                                      }}
                                    >
                                      Response Assessments in 3<sup>rd</sup>{" "}
                                      Line of Treatment{" "}
                                    </b>
                                  </tr>

                                  {/* 49-50 */}

                                  <div className="question-bot-sapce">
                                    <div className=" question-weight">
                                      <span> Q49. </span>
                                      Provide information for{" "}
                                      <span
                                        className="modal-popup"
                                        data-tooltip-id="encounterTip"
                                        onClick={() =>
                                          setShowHideHyperlinkModalIMWG(true)
                                        }
                                      >
                                        disease progression
                                      </span>{" "}
                                      assessed during the patient’s 3
                                      <sup>rd</sup> 
                                      {" "}<span
                                    data-tooltip-id="encounterTip"
                                    className="modal-popup"
                                    onClick={() =>
                                      setShowHideHyperlinkModal(true)
                                    }
                                  >
                                    line of therapy
                                  </span>.
                                    </div>

                                    <div className="sub-question">
                                      <table
                                        style={{
                                          border: "1px solid black",
                                          borderCollapse: "collapse",
                                          width: "100%",
                                        }}
                                      >
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                                textAlign: "center",
                                                // width:"4%"
                                              }}
                                            >
                                              Assessment
                                            </th>
                                            <th
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                                textAlign: "center",
                                                //   width: "29%"
                                              }}
                                            >
                                              <span
                                                style={{ textAlign: "center" }}
                                              >
                                                Date of progression assessment
                                              </span>
                                            </th>

                                            {data.Q49ConditionMet && (
                                              <>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                    // width:"36%"
                                                  }}
                                                >
                                                  Reason for progression
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                    //  width:"28%"
                                                  }}
                                                >
                                                  Method of assessment
                                                </th>
                                              </>
                                            )}
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <span className="quest-text-pad">
                                                Disease progression
                                              </span>
                                            </td>

                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q49dateOfProgressionRd" +
                                                      idx
                                                    }
                                                    value={1}
                                                    checked={
                                                      data.Q49dateOfProgressionRd ===
                                                      1
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Disease progression did
                                                      not occur
                                                    </span>
                                                  </label>
                                                </div>

                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q49dateOfProgressionRd" +
                                                      idx
                                                    }
                                                    value={2}
                                                    checked={
                                                      data.Q49dateOfProgressionRd ===
                                                      2
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Disease progression
                                                      occurred on:
                                                    </span>
                                                  </label>
                                                </div>

                                                {/* <div
                                            className="option-without-input"
                                            style={{ display: "flex" }}
                                            >
                                                */}

                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "41px",
                                                    marginLeft: "5px",
                                                  }}
                                                >
                                                  <DateControl
                                                    ctrlId={
                                                      "Q49dateOfProgression_dt-" +
                                                      idx
                                                    }
                                                    HandleDateChange={
                                                      HandleRepeaterDateChange
                                                    }
                                                    date={
                                                      data.Q49dateOfProgression_dt
                                                    }
                                                  />
                                                </span>
                                                {/* </div> */}
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q49dateOfProgressionRd" +
                                                      idx
                                                    }
                                                    value={99}
                                                    checked={
                                                      data.Q49dateOfProgressionRd ===
                                                      99
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Disease progression
                                                      occurred but date of
                                                      assessment unknown
                                                    </span>
                                                  </label>
                                                </div>

                                                <ErrorField
                                                  show={
                                                    data.Q49dateOfProgressionEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q49Date}
                                                  style={{ paddingLeft: "0px" }}
                                                />
                                                <ErrorField
                                                  show={
                                                    data.Q49dateOfProgression_dtEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q49DateEmpty}
                                                  style={{ paddingLeft: "0px" }}
                                                />

                                                <ErrorField
                                            show={data.Q49DateValidation1}
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />

                                          <ErrorField
                                            show={data.Q49DateValidation2}
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />

                                          <ErrorField
                                            show={data.Q49DateValidation3}
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                              </div>
                                            </td>

                                            {data.Q49ConditionMet && (
                                              <>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div>
                                                    <div className="">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          type="checkbox"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "Q49serum" + idx
                                                          }
                                                          checked={
                                                            data.Q49serum
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span className="radio-text-padding">
                                                          Serum M protein
                                                        </span>
                                                        </label>
                                                      </div>
                                                    </div>

                                                    <div className="">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          type="checkbox"
                                                          id={idx}
                                                          name={
                                                            "Q49uninary" + idx
                                                          }
                                                          checked={
                                                            data.Q49uninary
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span className="radio-text-padding">
                                                          Urinary M protein
                                                        </span>
                                                        </label>
                                                      </div>
                                                    </div>

                                                    <div className="">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          type="checkbox"
                                                          id={idx}
                                                          name={
                                                            "Q49freelight" + idx
                                                          }
                                                          checked={
                                                            data.Q49freelight
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span className="radio-text-padding">
                                                          Free light chain
                                                        </span>
                                                        </label>
                                                      </div>
                                                    </div>

                                                    <div className="">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          type="checkbox"
                                                          id={idx}
                                                          name={
                                                            "Q49plasma" + idx
                                                          }
                                                          checked={
                                                            data.Q49plasma
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span className="radio-text-padding">
                                                          Plasma cell increase
                                                        </span>
                                                        </label>
                                                      </div>
                                                    </div>

                                                    <div className="">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          type="checkbox"
                                                          id={idx}
                                                          name={
                                                            "Q49bonelesion" +
                                                            idx
                                                          }
                                                          checked={
                                                            data.Q49bonelesion
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span className="radio-text-padding">
                                                          Increase in size
                                                          and/or new bone lesion
                                                        </span>
                                                        </label>
                                                      </div>
                                                    </div>

                                                    <div className="">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          type="checkbox"
                                                          id={idx}
                                                          name={
                                                            "Q49plasmacytomas" +
                                                            idx
                                                          }
                                                          checked={
                                                            data.Q49plasmacytomas
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span className="radio-text-padding">
                                                          Increase in size
                                                          and/or new
                                                          plasmacytomas
                                                        </span>
                                                        </label>
                                                      </div>
                                                    </div>

                                                    <div className="">
                                                      <div
                                                      //   style={{display:"flex", alignItems:"flex-start"}}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          type="checkbox"
                                                          id={idx}
                                                          name={
                                                            "Q49ReasonOfProgression_oth" +
                                                            idx
                                                          }
                                                          checked={
                                                            data.Q49ReasonOfProgression_oth
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        <span className="radio-text-padding">
                                                          Other, please specify:
                                                        </span>
                                                        </label>

                                                        <div style={{paddingLeft:"15px"}}>
                                                        <textarea
                                                         
                                                          name={
                                                            "Q49ReasonOfProgression_txt" +
                                                            idx
                                                          }
                                                          className="input-dash"
                                                          type="text"
                                                          value={
                                                            data.Q49ReasonOfProgression_txt
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                          maxLength={300}
                                                          id={idx}
                                                        />
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                      <input
                                                        type="radio"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={idx}
                                                        name={
                                                          "Q49ReasonOfProgression" +
                                                          idx
                                                        }
                                                        value={99}
                                                        checked={
                                                          data.Q49ReasonOfProgression ===
                                                          99
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                      
                                                        <span
                                                          className="radio-text-padding"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          Unknown
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <ErrorField
                                                      show={
                                                        data.Q49ReasonOfProgressionEmpty
                                                      }
                                                      message={TreatmentResponseProgression_Msgs.Q49Reason}
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />

                                                    <ErrorField
                                                      show={
                                                        data.Q49ReasonOfProgressionTxtEmpty
                                                      }
                                                      message={TreatmentResponseProgression_Msgs.Q49Reason}
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                    {/*<ErrorField
                                                show={
                                                    data.dt_admission_techHard_dt_check
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.admissionDateCheck
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div>
                                                    <div
                                                    // className="option-without-input"
                                                    // style={{ display: "flex" }}
                                                    >
                                                      {/* <input
                                                style={{ marginTop: "-18px" }}
                                                type="radio"
                                                id={idx}
                                                name={
                                                  "Q49assessmentMethodRd" + idx
                                                }
                                                value={1}
                                                checked={
                                                  data.Q49assessmentMethodRd ===
                                                  1
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                  marginLeft: "-5px",
                                                }}
                                              >
                                                {" "}
                                                Standard IMWG Response Criteria
                                              </span> */}
                                                      {assessmentMethods &&
                                                        assessmentMethods.length >
                                                          0 &&
                                                        assessmentMethods.map(
                                                          (item) => (
                                                            <div
                                                              className="option-without-input"
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "flex-start",
                                                              }}
                                                            >
                                                              <label className="long-text-option">
                                                              <input
                                                                type="radio"
                                                                style={{
                                                                  marginTop:
                                                                    "6px",
                                                                }}
                                                                id={idx}
                                                                name={
                                                                  "Q49assessmentMethodRd" +
                                                                  idx
                                                                }
                                                                value={
                                                                  item.assessmentmethodid
                                                                }
                                                                checked={
                                                                  data.Q49assessmentMethodRd ===
                                                                  item.assessmentmethodid
                                                                }
                                                                onChange={
                                                                  HandleRepeaterFieldChange
                                                                }
                                                              />
                                                              
                                                                {item.assessmentmethodid ===
                                                                1 ? (
                                                                  <span
                                                                    className="radio-text-padding modal-popup"
                                                                    data-tooltip-id="encounterTip"
                                                                    onClick={() =>
                                                                      setShowHideHyperlinkModalIMWG(
                                                                        true
                                                                      )
                                                                    }
                                                                  >
                                                                    {item.name}
                                                                  </span>
                                                                ) : (
                                                                  <span
                                                                    className="radio-text-padding"
                                                                    style={{
                                                                      paddingLeft:
                                                                        "10px",
                                                                    }}
                                                                  >
                                                                    {item.name}
                                                                  </span>
                                                                )}
                                                              </label>
                                                            </div>
                                                          )
                                                        )}
                                                    </div>
                                                    {/* <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q49assessmentMethodRd" + idx
                                                }
                                                value={2}
                                                checked={
                                                  data.Q49assessmentMethodRd ===
                                                  2
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <label>
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Other standard response
                                                  criteria
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q49assessmentMethodRd" + idx
                                                }
                                                value={3}
                                                checked={
                                                  data.Q49assessmentMethodRd ===
                                                  3
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <label>
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  {" "}
                                                  No standard criteria mentioned
                                                  in chart
                                                </span>
                                              </label>
                                            </div> */}
                                                    <ErrorField
                                                      show={
                                                        data.Q49assessmentMethodEmpty
                                                      }
                                                      message={"Please choose one of the provided options"}
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    />
                                                    {/* <ErrorField
                                                    show={
                                                        data.dt_discharge_techHard_dt
                                                    }
                                                    message={
                                                        TTCharacteristics_MSGS.dischargeEmptyDate
                                                    }
                                                    style={{ paddingLeft: "0px" }}
                                                    /> */}
                                                    {/* <ErrorField
                                                    show={
                                                        data.dt_discharge_techHard_dt_check
                                                    }
                                                    message={
                                                        TTCharacteristics_MSGS.dischargeDateCheck
                                                    }
                                                    style={{ paddingLeft: "0px" }}
                                                    /> */}
                                                  </div>
                                                </td>
                                              </>
                                            )}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>

                                  <div className="question-bot-sapce">
                                    <div className=" question-weight">
                                      <span>Q50. </span>
                                      Provide information for{" "}
                                      <span
                                        className="modal-popup"
                                        data-tooltip-id="encounterTip"
                                        onClick={() =>
                                          setShowHideHyperlinkModalIMWG(true)
                                        }
                                      >
                                        assessments of treatment response
                                      </span>{" "}
                                      conducted during the patient’s 3
                                      <sup>rd </sup>
                                      {" "}<span
                                    data-tooltip-id="encounterTip"
                                    className="modal-popup"
                                    onClick={() =>
                                      setShowHideHyperlinkModal(true)
                                    }
                                  >
                                    line of therapy
                                  </span>.
                                    </div>

                                    <div className="sub-question">
                                      <table
                                        style={{
                                          border: "1px solid black",
                                          borderCollapse: "collapse",
                                          width: "100%",
                                        }}
                                      >
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                                textAlign: "center",
                                                // width:"4%"
                                              }}
                                            >
                                              Assessment
                                            </th>
                                            <th
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                                textAlign: "center",
                                                //   width: "29%"
                                              }}
                                            >
                                              <span
                                                style={{ textAlign: "center" }}
                                              >
                                                Treatment response status
                                              </span>
                                            </th>

                                            {!((data.Q50FR_tmtResponseStatus === 8 || data.Q50FR_tmtResponseStatus === 9) &&
 (data.Q50BR_tmtResponseStatus === 8 || data.Q50BR_tmtResponseStatus === 9)) && (
    <>
                                            <th
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                                textAlign: "center",
                                                // width:"36%"
                                              }}
                                            >
                                              Date of response assessment
                                            </th>
                                            <th
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                                textAlign: "center",
                                                //  width:"28%"
                                              }}
                                            >
                                              Method of assessment
                                            </th>
                                            </> )}
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <span className="quest-text-pad">
                                                First response
                                              </span>
                                            </td>

                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q50FR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={1}
                                                    checked={
                                                      data.Q50FR_tmtResponseStatus ===
                                                      1
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Stringent complete
                                                      response (sCR)
                                                    </span>
                                                  </label>
                                                </div>

                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q50FR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={2}
                                                    checked={
                                                      data.Q50FR_tmtResponseStatus ===
                                                      2
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                              
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Complete response (CR)
                                                    </span>
                                                  </label>
                                                </div>

                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q50FR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={3}
                                                    checked={
                                                      data.Q50FR_tmtResponseStatus ===
                                                      3
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                               
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Very good partial response
                                                      (VGPR)
                                                    </span>
                                                  </label>
                                                </div>

                                                {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                                {/* </div> */}
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q50FR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={4}
                                                    checked={
                                                      data.Q50FR_tmtResponseStatus ===
                                                      4
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Partial response (PR)
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q50FR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={5}
                                                  checked={
                                                    data.Q50FR_tmtResponseStatus ===
                                                    5
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                             
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Minimal response (MR)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q50FR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={6}
                                                  checked={
                                                    data.Q50FR_tmtResponseStatus ===
                                                    6
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                             
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Stable disease (SD)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q50FR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={7}
                                                  checked={
                                                    data.Q50FR_tmtResponseStatus ===
                                                    7
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Progressive disease (PD)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q50FR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={8}
                                                  checked={
                                                    data.Q50FR_tmtResponseStatus ===
                                                    8
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Not evaluable
                                                  </span>
                                                </label>
                                              </div>
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q50FR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={9}
                                                  checked={
                                                    data.Q50FR_tmtResponseStatus ===
                                                    9
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Not achieved
                                                  </span>
                                                </label>
                                              </div>
                                              <ErrorField
                                                show={
                                                  data.Q50FR_tmtResponseStatusEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q50Treatment}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                              {/* <ErrorField
                                                show={
                                                data.dose_administration_techHard_dt
                                                }
                                                message={
                                                TTCharacteristics_MSGS.administrationEmptyDate
                                                }
                                                style={{ paddingLeft: "0px" }}
                                            /> */}
                                            </td>

                                            {!(data.Q50FR_tmtResponseStatus === 8 || data.Q50FR_tmtResponseStatus === 9)
        ? (<>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q50FR_dateOfResponse_assessRd" +
                                                      idx
                                                    }
                                                    value={1}
                                                    checked={
                                                      data.Q50FR_dateOfResponse_assessRd ===
                                                      1
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      First response assessed
                                                      on:
                                                    </span>
                                                  </label>
                                                </div>

                                                {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "41px",
                                                    marginLeft: "5px",
                                                  }}
                                                >
                                                  <DateControl
                                                    ctrlId={
                                                      "Q50FR_dateOfResponse_assess_dt-" +
                                                      idx
                                                    }
                                                    HandleDateChange={
                                                      HandleRepeaterDateChange
                                                    }
                                                    date={
                                                      data.Q50FR_dateOfResponse_assess_dt
                                                    }
                                                  />
                                                </span>
                                                {/* </div> */}
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q50FR_dateOfResponse_assessRd" +
                                                      idx
                                                    }
                                                    value={99}
                                                    checked={
                                                      data.Q50FR_dateOfResponse_assessRd ===
                                                      99
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      First response occurred
                                                      but date of assessment
                                                      unknown
                                                    </span>
                                                  </label>
                                                </div>

                                                <ErrorField
                                                  show={
                                                    data.Q50FR_dateOfResponse_assessEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q50Date}
                                                  style={{ paddingLeft: "0px" }}
                                                />
                                                <ErrorField
                                                  show={
                                                    data.Q50FR_dateOfResponse_assess_dtEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q50DateEmpty}
                                                  style={{ paddingLeft: "0px" }}
                                                />

                                                <ErrorField
                                            show={
                                              data.Q50FRDateValidation1
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                       <ErrorField
                                            show={
                                              data.Q50FRDateValidation2
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                        <ErrorField
                                            show={
                                              data.Q50FRDateValidation3
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                              </div>
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  className="option-without-input"
                                                  // style={{ display: "flex" }}
                                                >
                                                  {/* <input
                                                style={{ marginTop: "-18px" }}
                                                type="radio"
                                                id={idx}
                                                name={
                                                  "Q50FR_assessmentMethodRd" +
                                                  idx
                                                }
                                                value={1}
                                                checked={
                                                  data.Q50FR_assessmentMethodRd ===
                                                  1
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                  marginLeft: "-5px",
                                                }}
                                              >
                                                {" "}
                                                Standard IMWG Response Criteria
                                              </span> */}
                                                  {assessmentMethods &&
                                                    assessmentMethods.length >
                                                      0 &&
                                                    assessmentMethods.map(
                                                      (item) => (
                                                        <div
                                                          className="option-without-input"
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "flex-start",
                                                          }}
                                                        >
                                                          <label className="long-text-option">
                                                          <input
                                                            type="radio"
                                                            style={{
                                                              marginTop: "6px",
                                                            }}
                                                            id={idx}
                                                            name={
                                                              "Q50FR_assessmentMethodRd" +
                                                              idx
                                                            }
                                                            value={
                                                              item.assessmentmethodid
                                                            }
                                                            checked={
                                                              data.Q50FR_assessmentMethodRd ===
                                                              item.assessmentmethodid
                                                            }
                                                            onChange={
                                                              HandleRepeaterFieldChange
                                                            }
                                                          />
                                                          
                                                            {item.assessmentmethodid ===
                                                            1 ? (
                                                              <span
                                                                className="radio-text-padding modal-popup"
                                                                data-tooltip-id="encounterTip"
                                                                onClick={() =>
                                                                  setShowHideHyperlinkModalIMWG(
                                                                    true
                                                                  )
                                                                }
                                                              >
                                                                {item.name}
                                                              </span>
                                                            ) : (
                                                              <span
                                                                className="radio-text-padding"
                                                                style={{
                                                                  paddingLeft:
                                                                    "10px",
                                                                }}
                                                              >
                                                                {item.name}
                                                              </span>
                                                            )}
                                                          </label>
                                                        </div>
                                                      )
                                                    )}
                                                </div>
                                                {/* <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q50FR_assessmentMethodRd" +
                                                  idx
                                                }
                                                value={2}
                                                checked={
                                                  data.Q50FR_assessmentMethodRd ===
                                                  2
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <label>
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Other standard response
                                                  criteria
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q50FR_assessmentMethodRd" +
                                                  idx
                                                }
                                                value={3}
                                                checked={
                                                  data.Q50FR_assessmentMethodRd ===
                                                  3
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <label>
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  {" "}
                                                  No standard criteria mentioned
                                                  in chart
                                                </span>
                                              </label>
                                            </div> */}
                                                <ErrorField
                                                  show={
                                                    data.Q50FR_assessmentMethodEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q50Method}
                                                  style={{ paddingLeft: "0px" }}
                                                />
                                                {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeEmptyDate
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                                {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt_check
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeDateCheck
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                              </div>
                                            </td>
                                            </>): (<td colSpan={2}
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)}
                                          </tr>

                                          <tr>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <span className="quest-text-pad">
                                                Best response
                                              </span>
                                            </td>

                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q50BR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={1}
                                                    checked={
                                                      data.Q50BR_tmtResponseStatus ===
                                                      1
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Stringent complete
                                                      response (sCR)
                                                    </span>
                                                  </label>
                                                </div>

                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q50BR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={2}
                                                    checked={
                                                      data.Q50BR_tmtResponseStatus ===
                                                      2
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Complete response (CR)
                                                    </span>
                                                  </label>
                                                </div>

                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q50BR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={3}
                                                    checked={
                                                      data.Q50BR_tmtResponseStatus ===
                                                      3
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                 
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Very good partial response
                                                      (VGPR)
                                                    </span>
                                                  </label>
                                                </div>

                                                {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                                {/* </div> */}
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q50BR_tmtResponseStatus" +
                                                      idx
                                                    }
                                                    value={4}
                                                    checked={
                                                      data.Q50BR_tmtResponseStatus ===
                                                      4
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Partial response (PR)
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q50BR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={5}
                                                  checked={
                                                    data.Q50BR_tmtResponseStatus ===
                                                    5
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Minimal response (MR)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q50BR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={6}
                                                  checked={
                                                    data.Q50BR_tmtResponseStatus ===
                                                    6
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Stable disease (SD)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q50BR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={7}
                                                  checked={
                                                    data.Q50BR_tmtResponseStatus ===
                                                    7
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Progressive disease (PD)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q50BR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={8}
                                                  checked={
                                                    data.Q50BR_tmtResponseStatus ===
                                                    8
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Not evaluable
                                                  </span>
                                                </label>
                                              </div>
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q50BR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={9}
                                                  checked={
                                                    data.Q50BR_tmtResponseStatus ===
                                                    9
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Not achieved
                                                  </span>
                                                </label>
                                              </div>

                                              <ErrorField
                                                show={
                                                  data.Q50BR_tmtResponseStatusEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q50Treatment}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                              {/* <ErrorField
                                                show={
                                                data.dose_administration_techHard_dt
                                                }
                                                message={
                                                TTCharacteristics_MSGS.administrationEmptyDate
                                                }
                                                style={{ paddingLeft: "0px" }}
                                            /> */}
                                            </td>

                                            {!(data.Q50BR_tmtResponseStatus === 8 || data.Q50BR_tmtResponseStatus === 9)
        ? (<>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q50BR_dateOfResponse_assessRd" +
                                                      idx
                                                    }
                                                    value={1}
                                                    checked={
                                                      data.Q50BR_dateOfResponse_assessRd ===
                                                      1
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Best response assessed on:
                                                    </span>
                                                  </label>
                                                </div>

                                                {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "41px",
                                                    marginLeft: "5px",
                                                  }}
                                                >
                                                  <DateControl
                                                    ctrlId={
                                                      "Q50BR_dateOfResponse_assess_dt-" +
                                                      idx
                                                    }
                                                    HandleDateChange={
                                                      HandleRepeaterDateChange
                                                    }
                                                    date={
                                                      data.Q50BR_dateOfResponse_assess_dt
                                                    }
                                                  />
                                                </span>
                                                {/* </div> */}
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    type="radio"
                                                    style={{ marginTop: "6px" }}
                                                    id={idx}
                                                    name={
                                                      "Q50BR_dateOfResponse_assessRd" +
                                                      idx
                                                    }
                                                    value={99}
                                                    checked={
                                                      data.Q50BR_dateOfResponse_assessRd ===
                                                      99
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Best response occurred but
                                                      date of assessment unknown
                                                    </span>
                                                  </label>
                                                </div>

                                                <ErrorField
                                                  show={
                                                    data.Q50BR_dateOfResponse_assessEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q50Date}
                                                  style={{ paddingLeft: "0px" }}
                                                />
                                                <ErrorField
                                                  show={
                                                    data.Q50BR_dateOfResponse_assess_dtEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q50DateEmpty}
                                                  style={{ paddingLeft: "0px" }}
                                                />

                                                <ErrorField
                                            show={
                                              data.Q50BRDateValidation1
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                       <ErrorField
                                            show={
                                              data.Q50BRDateValidation2
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                        <ErrorField
                                            show={
                                              data.Q50BRDateValidation3
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                                
                                              </div>
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                padding: "8px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                // className="option-without-input"
                                                // style={{ display: "flex" }}
                                                >
                                                  {/* <input
                                                style={{ marginTop: "-18px" }}
                                                type="radio"
                                                id={idx}
                                                name={
                                                  "Q50BR_assessmentMethodRd" +
                                                  idx
                                                }
                                                value={1}
                                                checked={
                                                  data.Q50BR_assessmentMethodRd ===
                                                  1
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                  marginLeft: "-5px",
                                                }}
                                              >
                                                {" "}
                                                Standard IMWG Response Criteria
                                              </span> */}
                                                  {assessmentMethods &&
                                                    assessmentMethods.length >
                                                      0 &&
                                                    assessmentMethods.map(
                                                      (item) => (
                                                        <div
                                                          className="option-without-input"
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "flex-start",
                                                          }}
                                                        >
                                                          <label className="long-text-option">
                                                          <input
                                                            type="radio"
                                                            style={{
                                                              marginTop: "6px",
                                                            }}
                                                            id={idx}
                                                            name={
                                                              "Q50BR_assessmentMethodRd" +
                                                              idx
                                                            }
                                                            value={
                                                              item.assessmentmethodid
                                                            }
                                                            checked={
                                                              data.Q50BR_assessmentMethodRd ===
                                                              item.assessmentmethodid
                                                            }
                                                            onChange={
                                                              HandleRepeaterFieldChange
                                                            }
                                                          />
                                                          
                                                            {item.assessmentmethodid ===
                                                            1 ? (
                                                              <span
                                                                className="radio-text-padding modal-popup"
                                                                data-tooltip-id="encounterTip"
                                                                onClick={() =>
                                                                  setShowHideHyperlinkModalIMWG(
                                                                    true
                                                                  )
                                                                }
                                                              >
                                                                {item.name}
                                                              </span>
                                                            ) : (
                                                              <span
                                                                className="radio-text-padding"
                                                                style={{
                                                                  paddingLeft:
                                                                    "10px",
                                                                }}
                                                              >
                                                                {item.name}
                                                              </span>
                                                            )}
                                                          </label>
                                                        </div>
                                                      )
                                                    )}
                                                </div>
                                                {/* <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q50BR_assessmentMethodRd" +
                                                  idx
                                                }
                                                value={2}
                                                checked={
                                                  data.Q50BR_assessmentMethodRd ===
                                                  2
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <label>
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Other standard response
                                                  criteria
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q50BR_assessmentMethodRd" +
                                                  idx
                                                }
                                                value={3}
                                                checked={
                                                  data.Q50BR_assessmentMethodRd ===
                                                  3
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <label>
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  {" "}
                                                  No standard criteria mentioned
                                                  in chart
                                                </span>
                                              </label>
                                            </div> */}
                                                <ErrorField
                                                  show={
                                                    data.Q50BR_assessmentMethodEmpty
                                                  }
                                                  message={TreatmentResponseProgression_Msgs.Q50Method}
                                                  style={{ paddingLeft: "0px" }}
                                                />
                                                {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeEmptyDate
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                                {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt_check
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeDateCheck
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                              </div>
                                            </td> 
                                            </>): (<td colSpan={2}
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            {/* 51-52 */}
                            {data.showQ51_52 && data.cohorttreatmentid >=  RRMMCohortTreatment.TECVAYLI  && (
                              <>
                                <div className="question-bot-sapce">
                                  <div className=" question-weight">
                                    <span> Q51. </span>
                                    Provide information for{" "}
                                    <span
                                      className="modal-popup"
                                      data-tooltip-id="encounterTip"
                                      onClick={() =>
                                        setShowHideHyperlinkModalIMWG(true)
                                      }
                                    >
                                      disease progression
                                    </span>{" "}
                                    assessed during the patient’s <strong><u>subsequent</u></strong> {""}
                                    {" "}<span
                                    data-tooltip-id="encounterTip"
                                    className="modal-popup"
                                    onClick={() =>
                                      setShowHideHyperlinkModal(true)
                                    }
                                  >
                                    line of therapy
                                  </span>{" "}
                                     after
                                    <span
                                      style={{
                                        color: PageDesignConstants.fontColor,
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {" "}
                                      {data.cohortIndexDate}{" "}
                                    </span>
                                    .{" "}
                                  </div>

                                  <div className="sub-question">
                                    <table
                                      style={{
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        width: "100%",
                                      }}
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                              textAlign: "center",
                                              // width:"4%"
                                            }}
                                          >
                                            Assessment
                                          </th>
                                          <th
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                              textAlign: "center",
                                              //   width: "29%"
                                            }}
                                          >
                                            <span
                                              style={{ textAlign: "center" }}
                                            >
                                              Date of progression assessment
                                            </span>
                                          </th>

                                          {data.Q51ConditionMet && (
                                            <>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                  textAlign: "center",
                                                  // width:"36%"
                                                }}
                                              >
                                                Reason for progression
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                  textAlign: "center",
                                                  //  width:"28%"
                                                }}
                                              >
                                                Method of assessment
                                              </th>
                                            </>
                                          )}
                                        </tr>
                                      </thead>

                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            <span className="quest-text-pad">
                                              Disease progression
                                            </span>
                                          </td>

                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            <div>
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q51dateOfProgressionRd" +
                                                    idx
                                                  }
                                                  value={1}
                                                  checked={
                                                    data.Q51dateOfProgressionRd ===
                                                    1
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Disease progression did not
                                                    occur
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q51dateOfProgressionRd" +
                                                    idx
                                                  }
                                                  value={2}
                                                  checked={
                                                    data.Q51dateOfProgressionRd ===
                                                    2
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Disease progression occurred
                                                    on:
                                                  </span>
                                                </label>
                                              </div>

                                              {/* <div
                                            className="option-without-input"
                                            style={{ display: "flex" }}
                                            >
                                                */}

                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "41px",
                                                  marginLeft: "5px",
                                                }}
                                              >
                                                <DateControl
                                                  ctrlId={
                                                    "Q51dateOfProgression_dt-" +
                                                    idx
                                                  }
                                                  HandleDateChange={
                                                    HandleRepeaterDateChange
                                                  }
                                                  date={
                                                    data.Q51dateOfProgression_dt
                                                  }
                                                />
                                              </span>
                                              {/* </div> */}
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q51dateOfProgressionRd" +
                                                    idx
                                                  }
                                                  value={99}
                                                  checked={
                                                    data.Q51dateOfProgressionRd ===
                                                    99
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Disease progression occurred
                                                    but date of assessment
                                                    unknown
                                                  </span>
                                                </label>
                                              </div>

                                              <ErrorField
                                                show={
                                                  data.Q51dateOfProgressionEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q51Date}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                              <ErrorField
                                                show={
                                                  data.Q51dateOfProgression_dtEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q51DateEmpty}
                                                style={{ paddingLeft: "0px" }}
                                              />

<ErrorField
                                            show={data.Q51DateValidation1}
                                            // message={"!<=2<=3"}
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />

                                          <ErrorField
                                            show={data.Q51DateValidation2}
                                            // message={"1(index)<=2(date user)"}
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />

                                          <ErrorField
                                            show={data.Q51DateValidation3}
                                            // message={"2(date)<=3(EOFD)"}
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                            </div>
                                          </td>

                                          {data.Q51ConditionMet && (
                                            <>
                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                }}
                                              >
                                                <div>
                                                  <div className="">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        alignItems:
                                                          "flex-start",
                                                      }} >
                                                   <label className="long-text-option">
                                                      <input
                                                        type="checkbox"
                                                        style={{
                                                          marginTop: "6px",
                                                        }}
                                                        id={idx}
                                                        name={"Q51serum" + idx}
                                                        checked={data.Q51serum}
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />
                                                      <span className="radio-text-padding">
                                                        Serum M protein
                                                      </span>
                                                      </label>
                                                    </div>
                                                  </div>

                                              <div className="">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    style={{ marginTop: "6px" }}
                                                    type="checkbox"
                                                    id={idx}
                                                    name={"Q51uninary" + idx}
                                                    checked={data.Q51uninary}
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span className="radio-text-padding">
                                                    Urinary M protein
                                                  </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    style={{ marginTop: "6px" }}
                                                    type="checkbox"
                                                    id={idx}
                                                    name={"Q51freelight" + idx}
                                                    checked={data.Q51freelight}
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span className="radio-text-padding">
                                                    Free light chain
                                                  </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    style={{ marginTop: "6px" }}
                                                    type="checkbox"
                                                    id={idx}
                                                    name={"Q51plasma" + idx}
                                                    checked={data.Q51plasma}
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span className="radio-text-padding">
                                                    Plasma cell increase
                                                  </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    style={{ marginTop: "6px" }}
                                                    type="checkbox"
                                                    id={idx}
                                                    name={"Q51bonelesion" + idx}
                                                    checked={data.Q51bonelesion}
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span className="radio-text-padding">
                                                    Increase in size and/or new
                                                    bone lesion
                                                  </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    style={{ marginTop: "6px" }}
                                                    type="checkbox"
                                                    id={idx}
                                                    name={
                                                      "Q51plasmacytomas" + idx
                                                    }
                                                    checked={
                                                      data.Q51plasmacytomas
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span className="radio-text-padding">
                                                    Increase in size and/or new
                                                    plasmacytomas
                                                  </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="">
                                                <div
                                                //   style={{display:"flex", alignItems:"flex-start"}}
                                                >
                                                  <label className="long-text-option">
                                                  <input
                                                    style={{ marginTop: "6px" }}
                                                    type="checkbox"
                                                    id={idx}
                                                    name={
                                                      "Q51ReasonOfProgression_oth" +
                                                      idx
                                                    }
                                                    checked={
                                                      data.Q51ReasonOfProgression_oth
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span className="radio-text-padding">
                                                    Other, please specify:
                                                  </span>
                                                  </label>

                                                      <div style={{paddingLeft:"15px"}}>
                                                      <textarea
                                                       
                                                        name={
                                                          "Q51ReasonOfProgression_txt" +
                                                          idx
                                                        }
                                                        className="input-dash"
                                                        type="text"
                                                        value={
                                                          data.Q51ReasonOfProgression_txt
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                        maxLength={300}
                                                        id={idx}
                                                      />
                                                      </div>
                                                    </div>
                                                  </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q51ReasonOfProgression" +
                                                    idx
                                                  }
                                                  value={99}
                                                  checked={
                                                    data.Q51ReasonOfProgression ===
                                                    99
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Unknown
                                                  </span>
                                                </label>
                                              </div>
                                              <ErrorField
                                                show={
                                                  data.Q51ReasonOfProgressionEmpty      
                                                }
                                                message={"Choose option"}
                                                style={{ paddingLeft: "0px" }}
                                              />

                                                  <ErrorField
                                                    show={
                                                      data.Q51ReasonOfProgressionTxtEmpty
                                                    }
                                                    message={TreatmentResponseProgression_Msgs.Q51Reason}
                                                    style={{
                                                      paddingLeft: "0px",
                                                    }}
                                                  />

                                                  {/* <ErrorField
                                                show={
                                                    data.dt_admission_techHard_dt_check
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.admissionDateCheck
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                                </div>
                                              </td>
                                              {/* {data.assessmentMethod.map((a,id)=>( */}
                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                }}
                                              >
                                                <div>
                                                  <div
                                                  // className="option-without-input"
                                                  // style={{ display: "flex" }}
                                                  >
                                                    {/* <input
                                              style={{ marginTop: "-18px" }}
                                              type="radio"
                                              id={idx}
                                              name={
                                                "Q51assessmentMethodRd" + idx
                                              }
                                              value={1}
                                              checked={
                                                data.Q51assessmentMethodRd === 1
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                                marginLeft: "-5px",
                                              }}
                                            >
                                              {" "}
                                              Standard IMWG Response Criteria
                                              
                                            </span> */}
                                                {assessmentMethods &&
                                                  assessmentMethods.length >
                                                    0 &&
                                                  assessmentMethods.map(
                                                    (item) => (
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "Q51assessmentMethodRd" +
                                                            idx
                                                          }
                                                          value={
                                                            item.assessmentmethodid
                                                          }
                                                          checked={
                                                            data.Q51assessmentMethodRd ===
                                                            item.assessmentmethodid
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        
                                                          {item.assessmentmethodid ===
                                                          1 ? (
                                                            <span
                                                              className="radio-text-padding modal-popup"
                                                              data-tooltip-id="encounterTip"
                                                              onClick={() =>
                                                                setShowHideHyperlinkModalIMWG(
                                                                  true
                                                                )
                                                              }
                                                            >
                                                              {item.name}
                                                            </span>
                                                          ) : (
                                                            <span
                                                              className="radio-text-padding"
                                                              style={{
                                                                paddingLeft:
                                                                  "10px",
                                                              }}
                                                            >
                                                              {item.name}
                                                            </span>
                                                          )}
                                                        </label>
                                                      </div>
                                                    )
                                                  )}
                                              </div>
                                              {/* <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q51assessmentMethodRd" + idx
                                              }
                                              value={2}
                                              checked={
                                                data.Q51assessmentMethodRd === 2
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <label>
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Other standard response criteria
                                           
                                              </span>
                                            </label>
                                          </div>

                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q51assessmentMethodRd" + idx
                                              }
                                              value={3}
                                              checked={
                                                data.Q51assessmentMethodRd === 3
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <label>
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                {" "}
                                                No standard criteria mentioned
                                                in chart
                                             
                                              </span>
                                            </label>
                                          </div> */}
                                                  <ErrorField
                                                    show={
                                                      data.Q51assessmentMethodEmpty
                                                    }
                                                    message={TreatmentResponseProgression_Msgs.Q51Method}
                                                    style={{
                                                      paddingLeft: "0px",
                                                    }}
                                                  />
                                                </div>
                                              </td>

                                              {/* ))} */}
                                            </>
                                          )}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <div className="question-bot-sapce">
                                  <div className=" question-weight">
                                    <span>Q52. </span>
                                    Provide information for{" "}
                                    <span
                                      className="modal-popup"
                                      data-tooltip-id="encounterTip"
                                      onClick={() =>
                                        setShowHideHyperlinkModalIMWG(true)
                                      }
                                    >
                                      assessments of treatment response
                                    </span>{" "}
                                    conducted during the patient’s <strong><u>subsequent</u></strong> {""}

                                    
                                    <span
                                    data-tooltip-id="encounterTip"
                                    className="modal-popup"
                                    onClick={() =>
                                      setShowHideHyperlinkModal(true)
                                    }
                                  >
                                    line of therapy
                                  </span>.
                                  </div>

                                  <div className="sub-question">
                                    <table
                                      style={{
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        width: "100%",
                                      }}
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                              textAlign: "center",
                                              // width:"4%"
                                            }}
                                          >
                                            Assessment
                                          </th>
                                          <th
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                              textAlign: "center",
                                              //   width: "29%"
                                            }}
                                          >
                                            <span
                                              style={{ textAlign: "center" }}
                                            >
                                              Treatment response status
                                            </span>
                                          </th>
                                          {!((data.Q52FR_tmtResponseStatus === 8 || data.Q52FR_tmtResponseStatus === 9) &&
 (data.Q52BR_tmtResponseStatus === 8 || data.Q52BR_tmtResponseStatus === 9)) && (
    <>

                                          <th
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                              textAlign: "center",
                                              // width:"36%"
                                            }}
                                          >
                                            Date of response assessment
                                          </th>
                                          <th
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                              textAlign: "center",
                                              //  width:"28%"
                                            }}
                                          >
                                            Method of assessment
                                          </th>
                                          </> )}
                                        </tr>
                                      </thead>

                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            <span className="quest-text-pad">
                                              First response
                                            </span>
                                          </td>

                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            <div>
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q52FR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={1}
                                                  checked={
                                                    data.Q52FR_tmtResponseStatus ===
                                                    1
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Stringent complete response
                                                    (sCR)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q52FR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={2}
                                                  checked={
                                                    data.Q52FR_tmtResponseStatus ===
                                                    2
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Complete response (CR)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q52FR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={3}
                                                  checked={
                                                    data.Q52FR_tmtResponseStatus ===
                                                    3
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Very good partial response
                                                    (VGPR)
                                                  </span>
                                                </label>
                                              </div>

                                              {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                              {/* </div> */}
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q52FR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={4}
                                                  checked={
                                                    data.Q52FR_tmtResponseStatus ===
                                                    4
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Partial response (PR)
                                                  </span>
                                                </label>
                                              </div>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <label className="long-text-option">
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q52FR_tmtResponseStatus" +
                                                  idx
                                                }
                                                value={5}
                                                checked={
                                                  data.Q52FR_tmtResponseStatus ===
                                                  5
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Minimal response (MR)
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <label className="long-text-option">
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q52FR_tmtResponseStatus" +
                                                  idx
                                                }
                                                value={6}
                                                checked={
                                                  data.Q52FR_tmtResponseStatus ===
                                                  6
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Stable disease (SD)
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <label className="long-text-option">
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q52FR_tmtResponseStatus" +
                                                  idx
                                                }
                                                value={7}
                                                checked={
                                                  data.Q52FR_tmtResponseStatus ===
                                                  7
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Progressive disease (PD)
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <label className="long-text-option">
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q52FR_tmtResponseStatus" +
                                                  idx
                                                }
                                                value={8}
                                                checked={
                                                  data.Q52FR_tmtResponseStatus ===
                                                  8
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Not evaluable
                                                </span>
                                              </label>
                                            </div>
                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <label className="long-text-option">
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q52FR_tmtResponseStatus" +
                                                  idx
                                                }
                                                value={9}
                                                checked={
                                                  data.Q52FR_tmtResponseStatus ===
                                                  9
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Not achieved
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={
                                                data.Q52FR_tmtResponseStatusEmpty
                                              }
                                              message={TreatmentResponseProgression_Msgs.Q52Treatment}
                                              style={{ paddingLeft: "0px" }}
                                            />
                                            {/* <ErrorField
                                                show={
                                                data.dose_administration_techHard_dt
                                                }
                                                message={
                                                TTCharacteristics_MSGS.administrationEmptyDate
                                                }
                                                style={{ paddingLeft: "0px" }}
                                            /> */}
                                          </td>

                                          {!(data.Q52FR_tmtResponseStatus === 8 || data.Q52FR_tmtResponseStatus === 9)
                                                ? (<>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >


                                            <div>
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q52FR_dateOfResponse_assessRd" +
                                                    idx
                                                  }
                                                  value={1}
                                                  checked={
                                                    data.Q52FR_dateOfResponse_assessRd ===
                                                    1
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    First response assessed on:
                                                  </span>
                                                </label>
                                              </div>

                                              {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "41px",
                                                  marginLeft: "5px",
                                                }}
                                              >
                                                <DateControl
                                                  ctrlId={
                                                    "Q52FR_dateOfResponse_assess_dt-" +
                                                    idx
                                                  }
                                                  HandleDateChange={
                                                    HandleRepeaterDateChange
                                                  }
                                                  date={
                                                    data.Q52FR_dateOfResponse_assess_dt
                                                  }
                                                />
                                              </span>
                                              {/* </div> */}
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q52FR_dateOfResponse_assessRd" +
                                                    idx
                                                  }
                                                  value={99}
                                                  checked={
                                                    data.Q52FR_dateOfResponse_assessRd ===
                                                    99
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                               
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    First response occurred but
                                                    date of assessment unknown
                                                  </span>
                                                </label>
                                              </div>

                                              <ErrorField
                                                show={
                                                  data.Q52FR_dateOfResponse_assessEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q52Date}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                              <ErrorField
                                                show={
                                                  data.Q52FR_dateOfResponse_assess_dtEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q52DateEmpty}
                                                style={{ paddingLeft: "0px" }}
                                              />




<ErrorField
                                            show={
                                              data.Q52FRDateValidation1
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                       <ErrorField
                                            show={
                                              data.Q52FRDateValidation2
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                        <ErrorField
                                            show={
                                              data.Q52FRDateValidation3
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                            </div>
                                          </td>

                                         
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            <div>
                                              <div
                                              // className="option-without-input"
                                              // style={{ display: "flex" }}
                                              >
                                                {/* <input
                                              style={{ marginTop: "-18px" }}
                                              type="radio"
                                              id={idx}
                                              name={
                                                "Q52FR_assessmentMethodRd" + idx
                                              }
                                              value={1}
                                              checked={
                                                data.Q52FR_assessmentMethodRd ===
                                                1
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                                marginLeft: "-5px",
                                              }}
                                            >
                                              {" "}
                                              Standard IMWG Response Criteria
                                            </span> */}
                                                {assessmentMethods &&
                                                  assessmentMethods.length >
                                                    0 &&
                                                  assessmentMethods.map(
                                                    (item) => (
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "Q52FR_assessmentMethodRd" +
                                                            idx
                                                          }
                                                          value={
                                                            item.assessmentmethodid
                                                          }
                                                          checked={
                                                            data.Q52FR_assessmentMethodRd ===
                                                            item.assessmentmethodid
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        
                                                          {item.assessmentmethodid ===
                                                          1 ? (
                                                            <span
                                                              className="radio-text-padding modal-popup"
                                                              data-tooltip-id="encounterTip"
                                                              onClick={() =>
                                                                setShowHideHyperlinkModalIMWG(
                                                                  true
                                                                )
                                                              }
                                                            >
                                                              {item.name}
                                                            </span>
                                                          ) : (
                                                            <span
                                                              className="radio-text-padding"
                                                              style={{
                                                                paddingLeft:
                                                                  "10px",
                                                              }}
                                                            >
                                                              {item.name}
                                                            </span>
                                                          )}
                                                        </label>
                                                      </div>
                                                    )
                                                  )}
                                              </div>
                                              {/* <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q52FR_assessmentMethodRd" + idx
                                              }
                                              value={2}
                                              checked={
                                                data.Q52FR_assessmentMethodRd ===
                                                2
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <label>
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Other standard response criteria
                                              </span>
                                            </label>
                                          </div>

                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q52FR_assessmentMethodRd" + idx
                                              }
                                              value={3}
                                              checked={
                                                data.Q52FR_assessmentMethodRd ===
                                                3
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <label>
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                {" "}
                                                No standard criteria mentioned
                                                in chart
                                              </span>
                                            </label>
                                          </div> */}
                                              <ErrorField
                                                show={
                                                  data.Q52FR_assessmentMethodEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q52Method}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                              {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeEmptyDate
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                              {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt_check
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeDateCheck
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                            </div>
                                          </td>
                                          </>): (<td colSpan={2}
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)}
                                        </tr>


                                      
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            <span className="quest-text-pad">
                                              Best response
                                            </span>
                                          </td>

                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            <div>
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q52BR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={1}
                                                  checked={
                                                    data.Q52BR_tmtResponseStatus ===
                                                    1
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Stringent complete response
                                                    (sCR)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q52BR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={2}
                                                  checked={
                                                    data.Q52BR_tmtResponseStatus ===
                                                    2
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Complete response (CR)
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q52BR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={3}
                                                  checked={
                                                    data.Q52BR_tmtResponseStatus ===
                                                    3
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Very good partial response
                                                    (VGPR)
                                                  </span>
                                                </label>
                                              </div>

                                              {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                              {/* </div> */}
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q52BR_tmtResponseStatus" +
                                                    idx
                                                  }
                                                  value={4}
                                                  checked={
                                                    data.Q52BR_tmtResponseStatus ===
                                                    4
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Partial response (PR)
                                                  </span>
                                                </label>
                                              </div>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <label className="long-text-option">
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q52BR_tmtResponseStatus" +
                                                  idx
                                                }
                                                value={5}
                                                checked={
                                                  data.Q52BR_tmtResponseStatus ===
                                                  5
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Minimal response (MR)
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <label className="long-text-option">
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q52BR_tmtResponseStatus" +
                                                  idx
                                                }
                                                value={6}
                                                checked={
                                                  data.Q52BR_tmtResponseStatus ===
                                                  6
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                             
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Stable disease (SD)
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <label className="long-text-option">
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q52BR_tmtResponseStatus" +
                                                  idx
                                                }
                                                value={7}
                                                checked={
                                                  data.Q52BR_tmtResponseStatus ===
                                                  7
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Progressive disease (PD)
                                                </span>
                                              </label>
                                            </div>

                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <label className="long-text-option">
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q52BR_tmtResponseStatus" +
                                                  idx
                                                }
                                                value={8}
                                                checked={
                                                  data.Q52BR_tmtResponseStatus ===
                                                  8
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                             
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Not evaluable
                                                </span>
                                              </label>
                                            </div>
                                            <div
                                              className="option-without-input"
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <label className="long-text-option">
                                              <input
                                                type="radio"
                                                style={{ marginTop: "6px" }}
                                                id={idx}
                                                name={
                                                  "Q52BR_tmtResponseStatus" +
                                                  idx
                                                }
                                                value={9}
                                                checked={
                                                  data.Q52BR_tmtResponseStatus ===
                                                  9
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              
                                                <span
                                                  className="radio-text-padding"
                                                  style={{
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Not achieved
                                                </span>
                                              </label>
                                            </div>
                                            <ErrorField
                                              show={
                                                data.Q52BR_tmtResponseStatusEmpty
                                              }
                                              message={TreatmentResponseProgression_Msgs.Q52Treatment}
                                              style={{ paddingLeft: "0px" }}
                                            />
                                            {/* <ErrorField
                                                show={
                                                data.dose_administration_techHard_dt
                                                }
                                                message={
                                                TTCharacteristics_MSGS.administrationEmptyDate
                                                }
                                                style={{ paddingLeft: "0px" }}
                                            /> */}
                                          </td>

                                          {!(data.Q52BR_tmtResponseStatus === 8 || data.Q52BR_tmtResponseStatus === 9)
                                                ? (<>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            <div>
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q52BR_dateOfResponse_assessRd" +
                                                    idx
                                                  }
                                                  value={1}
                                                  checked={
                                                    data.Q52BR_dateOfResponse_assessRd ===
                                                    1
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Best response assessed on:
                                                  </span>
                                                </label>
                                              </div>

                                              {/* <div
                                                            className="option-without-input"
                                                            style={{ display: "flex" }}
                                                            >
                                                                */}

                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "41px",
                                                  marginLeft: "5px",
                                                }}
                                              >
                                                <DateControl
                                                  ctrlId={
                                                    "Q52BR_dateOfResponse_assess_dt-" +
                                                    idx
                                                  }
                                                  HandleDateChange={
                                                    HandleRepeaterDateChange
                                                  }
                                                  date={
                                                    data.Q52BR_dateOfResponse_assess_dt
                                                  }
                                                />
                                              </span>
                                              {/* </div> */}
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label className="long-text-option">
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "6px" }}
                                                  id={idx}
                                                  name={
                                                    "Q52BR_dateOfResponse_assessRd" +
                                                    idx
                                                  }
                                                  value={99}
                                                  checked={
                                                    data.Q52BR_dateOfResponse_assessRd ===
                                                    99
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Best response occurred but
                                                    date of assessment unknown
                                                  </span>
                                                </label>
                                              </div>

                                              <ErrorField
                                                show={
                                                  data.Q52BR_dateOfResponse_assessEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q52Date}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                              <ErrorField
                                                show={
                                                  data.Q52BR_dateOfResponse_assess_dtEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q52DateEmpty}
                                                style={{ paddingLeft: "0px" }}
                                              />


<ErrorField
                                            show={
                                              data.Q52BRDateValidation1
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                       <ErrorField
                                            show={
                                              data.Q52BRDateValidation2
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                        <ErrorField
                                            show={
                                              data.Q52BRDateValidation3
                                            }
                                            message={TreatmentResponseProgression_Msgs.DateInvalidValidation}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            <div>
                                              <div
                                              // className="option-without-input"
                                              // style={{ display: "flex" }}
                                              >
                                                {/* <input
                                              style={{ marginTop: "-18px" }}
                                              type="radio"
                                              id={idx}
                                              name={
                                                "Q52BR_assessmentMethodRd" + idx
                                              }
                                              value={1}
                                              checked={
                                                data.Q52BR_assessmentMethodRd ===
                                                1
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <span
                                              className="radio-text-padding"
                                              style={{
                                                paddingLeft: "10px",
                                                marginLeft: "-5px",
                                              }}
                                            >
                                              {" "}
                                              Standard IMWG Response Criteria
                                            </span> */}
                                                {assessmentMethods &&
                                                  assessmentMethods.length >
                                                    0 &&
                                                  assessmentMethods.map(
                                                    (item) => (
                                                      <div
                                                        className="option-without-input"
                                                        style={{
                                                          display: "flex",
                                                          alignItems:
                                                            "flex-start",
                                                        }}
                                                      >
                                                        <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          style={{
                                                            marginTop: "6px",
                                                          }}
                                                          id={idx}
                                                          name={
                                                            "Q52BR_assessmentMethodRd" +
                                                            idx
                                                          }
                                                          value={
                                                            item.assessmentmethodid
                                                          }
                                                          checked={
                                                            data.Q52BR_assessmentMethodRd ===
                                                            item.assessmentmethodid
                                                          }
                                                          onChange={
                                                            HandleRepeaterFieldChange
                                                          }
                                                        />
                                                        
                                                          {item.assessmentmethodid ===
                                                          1 ? (
                                                            <span
                                                              className="radio-text-padding modal-popup"
                                                              data-tooltip-id="encounterTip"
                                                              onClick={() =>
                                                                setShowHideHyperlinkModalIMWG(
                                                                  true
                                                                )
                                                              }
                                                            >
                                                              {item.name}
                                                            </span>
                                                          ) : (
                                                            <span
                                                              className="radio-text-padding"
                                                              style={{
                                                                paddingLeft:
                                                                  "10px",
                                                              }}
                                                            >
                                                              {item.name}
                                                            </span>
                                                          )}
                                                        </label>
                                                      </div>
                                                    )
                                                  )}
                                              </div>
                                              {/* <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q52BR_assessmentMethodRd" + idx
                                              }
                                              value={2}
                                              checked={
                                                data.Q52BR_assessmentMethodRd ===
                                                2
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <label>
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                Other standard response criteria
                                              </span>
                                            </label>
                                          </div>

                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              style={{ marginTop: "6px" }}
                                              id={idx}
                                              name={
                                                "Q52BR_assessmentMethodRd" + idx
                                              }
                                              value={3}
                                              checked={
                                                data.Q52BR_assessmentMethodRd ===
                                                3
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <label>
                                              <span
                                                className="radio-text-padding"
                                                style={{
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                {" "}
                                                No standard criteria mentioned
                                                in chart
                                              </span>
                                            </label>
                                          </div> */}
                                              <ErrorField
                                                show={
                                                  data.Q52BR_assessmentMethodEmpty
                                                }
                                                message={TreatmentResponseProgression_Msgs.Q52Method}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                              {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeEmptyDate
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                              {/* <ErrorField
                                                show={
                                                    data.dt_discharge_techHard_dt_check
                                                }
                                                message={
                                                    TTCharacteristics_MSGS.dischargeDateCheck
                                                }
                                                style={{ paddingLeft: "0px" }}
                                                /> */}
                                            </div>
                                          </td>
                                          </>): (<td colSpan={2}
                                                                            style={{
                                                                                border: "1px solid black",
                                                                                // alignContent: "flex-start",
                                                                                textAlign: "center",
                                                                                padding: "8px",
                                                                                //textAlign: 'start'
                                                                            }}
                                                                        >
                                                                            <span style={{ textAlign: "center"}} className="not-applicable"><b>Not applicable</b></span>

                                                                    </td>)}
                                        </tr>
                                       
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {showHideHyperlinkModalIMWG && (
        <InfoPopUp
          description={hyperlinkTextStandardIMWGReportingCriteria}
          OkClick={() => setShowHideHyperlinkModalIMWG(false)}
        />
      )}

      {showHideHyperlinkModal && (
          <InfoPopUp
            description={hyperlinkTextLineOfTherapy}
            OkClick={() => setShowHideHyperlinkModal(false)}
          />
        )}
    </>
  );
};

export default TreatmentResponseProgression;
